import React from 'react';

import { Button } from 'semantic-ui-react';

import EmptyStateSegment from '../../../components/EmptyStateSegment/EmptyStateSegment';
import Page from '../../../components/Page/Page';
import { useAppContext } from '../../../hooks';

const AcceptInvitePage = () => {
  const context = useAppContext();

  return (
    <Page>
      <EmptyStateSegment
        title={
          'You are already logged in to the Nami Control Center with a different ' +
          'email address.  In order to accept this invite please logout.  ' +
          'If you wish to add this app to your current account contact a ' +
          'member of the inviting organization or Nami support.'
        }
        subtitle={'Contact support.'}
        outBoundLink={'https://learn.namiml.com/'}
      >
        <Button onClick={context.performLogout} className="nami-primary-button">
          Logout
        </Button>
      </EmptyStateSegment>
    </Page>
  );
};

export default AcceptInvitePage;
