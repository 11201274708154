import moment from 'moment-timezone';

export const endOfYesterday = moment().add(-1, 'days').endOf('day');
export const sevenDaysAgo = moment().add(-7, 'days').startOf('day');
export const oneYearAgo = moment().add(-12, 'months').startOf('day');
export const startOfToday = moment().startOf('day');
export const endOfToday = moment().endOf('day');

export function extractFieldError(errors) {
  return errors?.length ? errors[0] : false;
}
