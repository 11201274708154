import styled from 'styled-components';

import { TStack } from '../../../api/types/paywallTemplate.types';
import { ContainerWrapper } from './Container';

type StackProps = { component: TStack };

const Stack = styled(ContainerWrapper)<StackProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    position: absolute;
  }
`;

export default Stack;
