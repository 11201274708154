import React, { useEffect } from 'react';

import cx from 'classnames';
import { ReactSortable } from 'react-sortablejs';
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Header,
  Icon,
  Image,
  List,
  Popup,
  Segment,
  Sidebar,
} from 'semantic-ui-react';
import styled from 'styled-components';

import EmptyStateSegment from '../../../components/EmptyStateSegment/EmptyStateSegment';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hooks';
import {
  getPaywallMenus,
  getPaywallMenusAvailable,
  handlePaywallMenus,
  handlePaywallMenusAvailable,
  handlePaywallMenusChanges,
  handleToggleFeatured,
  updatePaywallMenus,
} from '../../../redux/actions/paywallActions';
import { platformLogo } from '../../../services/helpers/';
import history from '../../../services/history';
import { colorLightGrey } from '../../../variables';
import sharedStyles from '../SharedStyles.module.less';

const ProductList = styled(List)`
  border: 1px solid ${colorLightGrey};
  height: 28vh;
  overflow-y: scroll;
`;

export default function PaywallProductsSidebar({
  visible,
  setVisible,
  pushDown,
  platforms,
  platform,
  setPlatform,
}) {
  const { paywall, paywallMenusRoot, paywallMenus, paywallMenusAvailable } =
    useAppSelector(({ paywall }) => ({
      paywall: paywall.paywall,
      paywallMenusRoot: paywall.paywallMenusRoot,
      paywallMenus: paywall.paywallMenus,
      paywallMenusAvailable: paywall.paywallMenusAvailable,
    }));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (platforms?.length) {
      setPlatform(platforms[0]?.id);
    }
  }, [platforms, setPlatform]);

  useEffect(() => {
    if (paywall?.id && platform) {
      dispatch(getPaywallMenus(paywall.id, platform));
    }
  }, [paywall?.id, platform, dispatch]);

  useEffect(() => {
    if (platform && paywallMenusRoot?.id) {
      dispatch(
        getPaywallMenusAvailable(
          platform,
          paywall.language,
          paywallMenusRoot.id
        )
      );
    }
  }, [paywall.language, platform, dispatch, paywallMenusRoot]);

  const platformOptions = () => {
    if (platforms?.length) {
      return platforms.map((item, index) => {
        return {
          key: index,
          text: (
            <List horizontal>
              <List.Item>
                <Image src={platformLogo(item.type)} size="mini" />
              </List.Item>
              <List.Item>{item.name}</List.Item>
            </List>
          ),
          value: item.id,
          content: (
            <List horizontal>
              <List.Item>
                <Image src={platformLogo(item.type)} size="mini" />
              </List.Item>
              <List.Item>{item.name}</List.Item>
            </List>
          ),
        };
      });
    }
  };

  const renderContent = () => {
    if (!platforms.length) {
      return (
        <EmptyStateSegment
          title="This app does not have any mobile payment platforms integrated."
          subtitle="Learn more about payment platform integrations."
          outBoundLink="https://learn.namiml.com/public-docs/integrations/billing-platforms"
          icon="integration"
          border={false}
        >
          <Button
            className="nami-primary-button"
            onClick={() => history.push('/integrations/')}
          >
            <Icon name="plus" /> Manage Integrations
          </Button>
        </EmptyStateSegment>
      );
    } else if (!paywallMenus.length && !paywallMenusAvailable.length) {
      let emtpyStateTitle = `No Product SKUs for selected Platform in language ${
        paywall.language_humanized
      } - (${paywall.language.toUpperCase()})`;

      return (
        <EmptyStateSegment
          title={emtpyStateTitle}
          icon="products"
          border={false}
        >
          <Button
            className="nami-primary-button"
            onClick={() => history.push('/products/')}
          >
            <Icon name="plus" /> Manage Product SKUs
          </Button>
        </EmptyStateSegment>
      );
    } else {
      return (
        <>
          <Header as="h5">
            Enabled Products{' '}
            <Popup
              style={{ zIndex: 9999999999999 }}
              trigger={<Icon name="question circle" />}
            >
              Drag products between the Enabled and Disabled boxes to add and
              remove products from the paywall.
            </Popup>
          </Header>
          <ProductList
            as={ReactSortable}
            size="tiny"
            divided
            onChange={() => dispatch(handlePaywallMenusChanges())}
            list={paywallMenus}
            setList={(data) => dispatch(handlePaywallMenus(data))}
            group="shared-group-name"
          >
            {paywallMenus.map((item) => (
              <List.Item key={item.sku_id} style={{ padding: 15 }}>
                <List.Content floated="left">
                  <Icon name="bars" size="large" />
                </List.Content>
                <List.Content>
                  <List.Header>{item.name}</List.Header>
                  <p>{item.sku_ref_id}</p>
                  <Checkbox
                    // TODO: Hide this if there is not featured components
                    label="Featured"
                    checked={item.featured}
                    onChange={(e, { checked }) =>
                      dispatch(handleToggleFeatured(item.sku_id, checked))
                    }
                  />
                </List.Content>
              </List.Item>
            ))}
          </ProductList>
          <Header as="h5">Disabled Products</Header>
          <ProductList
            as={ReactSortable}
            size="tiny"
            divided
            onChange={() => dispatch(handlePaywallMenusChanges())}
            list={paywallMenusAvailable}
            setList={(data) => dispatch(handlePaywallMenusAvailable(data))}
            group="shared-group-name"
          >
            {paywallMenusAvailable.map((item) => (
              <List.Item key={item.sku_id} style={{ padding: 15 }}>
                <List.Content floated="left">
                  <Icon name="bars" size="large" />
                </List.Content>
                <List.Content>
                  <List.Header>{item.name}</List.Header>
                  {item.sku_ref_id}
                </List.Content>
              </List.Item>
            ))}
          </ProductList>
        </>
      );
    }
  };

  return (
    <Form>
      <Sidebar
        className={
          pushDown
            ? cx(sharedStyles.sidebar, sharedStyles.sidebarPushDown)
            : sharedStyles.sidebar
        }
        direction="right"
        animation="overlay"
        width="wide"
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <Segment>
          {platforms?.length && (
            <>
              <strong>Platform</strong>
              <Dropdown
                fluid
                placeholder="Select Platform"
                selection
                options={platformOptions()}
                value={platform || platforms[0].id}
                onChange={(e, data) => {
                  dispatch(
                    updatePaywallMenus(paywallMenusRoot.id, {
                      platform: platform,
                      ordered_items: paywallMenus,
                    })
                  ).then(() => {
                    setPlatform(data.value);
                  });
                }}
              />
            </>
          )}
          {renderContent()}
        </Segment>
        <Segment basic>
          <Button fluid onClick={() => setVisible(false)}>
            Close
          </Button>
        </Segment>
      </Sidebar>
    </Form>
  );
}
