import React from 'react';

import { TSemverObj } from 'src/utils/parsing';
import styled, { css } from 'styled-components';

import { TSpacerComponent } from '../../../api/types/paywallTemplate.types';
import { applyStyles } from '../css';
import HoverTag from './HoverTag';

type ComponentProps<T> = {
  component: T;
  minSDKVersion: TSemverObj;
};

//TODO - new fix to spacer
const SpacerWrapper = styled.div<{ component: TSpacerComponent }>`
  ${({ component }) => {
    const hasWidthOrHeight =
      component.height ||
      component.fixedHeight ||
      component.width ||
      component.fixedWidth;
    return css`
      ${hasWidthOrHeight ? '' : 'flex-grow: 1;'}
      ${applyStyles(component)}
    `;
  }}
`;

export function Spacer({
  component,
  minSDKVersion,
}: ComponentProps<TSpacerComponent>) {
  return (
    <HoverTag
      title={component.namiComponentType ? component.title || component.id : ''}
      namiComponentType={component.namiComponentType}
      id={component.id}
    >
      <SpacerWrapper component={component} />
    </HoverTag>
  );
}
