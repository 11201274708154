import React, { useEffect, useState } from 'react';

import { TDataParams } from '../../../api/analytics.api';
import {
  DataKey,
  SegmentType,
  TData,
} from '../../../api/types/analytics.types';
import { useAppSelector } from '../../../hooks/redux.hooks';
import { TInsightType } from '../insights/InsightsPage';
import InsightCard from './InsightCard';

type WildCardProps<Data extends TData> = {
  title: string;
  metric: TInsightType;
  chartKeys: Array<DataKey>;
  totalGetter: (params: TDataParams) => Promise<Data[]>;
  dataGetter: (params: TDataParams) => Promise<Data[]>;
  dataParser?(data: Data[], segment: SegmentType): Data[];
  totalDataParser?(data: Data[]): Data[];
};

export default function WildCard<Data extends TData>({
  title,
  metric,
  chartKeys,
  totalGetter,
  dataGetter,
  dataParser = (data) => data,
  totalDataParser = (data) => data,
}: WildCardProps<Data>) {
  const [data, setData] = useState<Data[]>([]);
  const [periodTotal, setPeriodTotal] = useState<Data[]>([]);
  const [loading, setLoading] = useState(true);
  const [urlMeta, setUrlMeta] = useState('');

  const { appId, dateRange, environment, timezone } = useAppSelector(
    ({ root, analytic }) => {
      const appId = root.currentApp?.id;
      const {
        environment: { value: environment },
        dateRangeInsights: dateRange,
        timezone,
      } = analytic;
      return { appId, dateRange, environment, timezone };
    }
  );

  useEffect(() => {
    setLoading(true);
    const params: TDataParams = {
      dateRange: dateRange,
      appId,
      environment,
      // TODO: "day" should be a parameter, but not sure if it is available here
      interval: 'day',
      segment: null,
      filters: {},
      timezone,
    };
    Promise.all([
      totalGetter(params).then((data) => {
        setPeriodTotal(totalDataParser(data));
      }),
      dataGetter(params).then((data) => {
        setData(dataParser(data, null));
      }),
    ]).finally(() => {
      setLoading(false);
    });

    setUrlMeta(
      `?environment=${environment}&startDate=${dateRange[0].toISOString(
        true
      )}&endDate=${dateRange[1].toISOString(true)}&timezone=${timezone}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, dateRange, environment]);

  return (
    <InsightCard
      title={title}
      metric={metric}
      loading={loading}
      data={data}
      totalData={periodTotal}
      chartKeys={chartKeys}
      segment={null}
      urlMeta={urlMeta}
    />
  );
}
