import React, { useState } from 'react';

import {
  FileImageOutlined,
  RedoOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { Form, Modal, Slider } from 'antd';
import Cropper from 'react-easy-crop';
import { Area, Point } from 'react-easy-crop/types';
import styled from 'styled-components';

import { useStepper } from '../hooks/number.hooks';
import { cropImage } from '../utils/image';

type ImageCroppingModalProps = {
  visible: boolean;
  imageUrl: string;
  aspectRatio: number;
  onCancel: () => void;
  onCrop: (file: Blob) => void;
};

const ZOOM_STEP = 0.1;
const MAX_ZOOM = 4;
const MIN_ZOOM = 1;

const ROTATION_STEP = 1;
const MAX_ROTATION = 180;
const MIN_ROTATION = -180;

const ControlsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 3rem;
`;

const ControlButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 12pt;
`;

const SliderWrapper = styled(Form.Item)`
  width: 100%;

  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const StyledSlider = styled(Slider)`
  width: 10rem;
  margin-left: 1rem;
  margin-right: 1rem;
`;

const CropperWrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 300px;

  img {
    pointer-events: none;
  }
`;

export default function ImageCroppingModal({
  imageUrl,
  visible,
  onCancel,
  onCrop,
  aspectRatio,
}: ImageCroppingModalProps) {
  const [cropPoint, setCropPoint] = useState<Point>({ x: 0, y: 0 });
  const [pixelArea, setPixelArea] = useState<Area | null>(null);
  const [zoom, increaseZoom, decreaseZoom, setZoom] = useStepper(
    ZOOM_STEP,
    MAX_ZOOM,
    MIN_ZOOM,
    MIN_ZOOM
  );
  const [rotation, increaseRotation, decreaseRotation, setRotation] =
    useStepper(ROTATION_STEP, MAX_ROTATION, MIN_ROTATION, 0);

  const title = (
    <>
      <FileImageOutlined />
      <span style={{ marginLeft: '0.5rem' }}>Image Editor</span>
    </>
  );

  return (
    <Modal open={visible} onCancel={onCancel} title={title} onOk={performCrop}>
      <CropperWrapper>
        <Cropper
          image={imageUrl}
          crop={cropPoint}
          zoom={zoom}
          rotation={rotation}
          aspect={aspectRatio}
          onCropChange={setCropPoint}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropComplete={(_, pixels) => setPixelArea(pixels)}
        />
      </CropperWrapper>

      <ControlsWrapper>
        <SliderWrapper>
          <ControlButton onClick={decreaseZoom}>
            <ZoomOutOutlined />
          </ControlButton>
          <StyledSlider
            value={zoom}
            min={MIN_ZOOM}
            max={MAX_ZOOM}
            step={ZOOM_STEP}
            onChange={setZoom}
          />
          <ControlButton onClick={increaseZoom}>
            <ZoomInOutlined />
          </ControlButton>
        </SliderWrapper>
        <SliderWrapper>
          <ControlButton onClick={decreaseRotation}>
            <UndoOutlined style={{ transform: 'rotate(100deg)' }} />
          </ControlButton>
          <StyledSlider
            value={rotation}
            min={MIN_ROTATION}
            max={MAX_ROTATION}
            step={ROTATION_STEP}
            onChange={setRotation}
          />
          <ControlButton onClick={increaseRotation}>
            <RedoOutlined style={{ transform: 'rotate(-100deg)' }} />
          </ControlButton>
        </SliderWrapper>
      </ControlsWrapper>
    </Modal>
  );

  function performCrop(): void {
    cropImage(imageUrl, pixelArea!, rotation).then(({ file }) => {
      onCrop(file);
      onCancel();
    });
  }
}
