import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';

import DateRangePicker from '../../../components/DateRangePicker';
import EnvironmentSelect from '../../../components/EnvironmentSelect';
import GettingStarted from '../../../components/GettingStarted/GettingStarted';
import Page from '../../../components/Page/Page';
import SectionTitle from '../../../components/SectionTitle';
import TimeZoneLabel from '../../../components/TimeZoneLabel/TimeZoneLabel';
import { useAppContext } from '../../../hooks';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hooks';
import { getAppStatus } from '../../../redux/actions/appActions';
import { toggleNewAppModal } from '../../../redux/actions/uiActions';
import { PAGES, TInsightType } from '../insights/InsightsPage';
import CampaignsSummaryCard from './CampaignsSummaryCard';
import PaywallSummaryCard from './PaywallSummaryCard';
import PlacementsSummaryCard from './PlacementsSummaryCard';
import WildCard from './WildCard';

export default function OverviewPage() {
  const appContext = useAppContext();
  const dispatch = useAppDispatch();
  const { hasAnApp, appId, appWarnings, timezone } = useAppSelector(
    ({ root, app, analytic }) => {
      const appId = root.currentApp?.id;
      const hasAnApp = !!root.apps?.length;
      const appWarnings = app.status?.config_warnings;
      const timezone = analytic.timezone;
      return {
        hasAnApp,
        appId,
        appWarnings,
        timezone,
      };
    }
  );

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!appId) return;
    dispatch(getAppStatus(appId));
  }, [appId, dispatch]);

  useEffect(() => {
    if (appId) {
      setShow(appWarnings?.has_no_paywalls || appWarnings?.has_no_devices);
    } else {
      setTimeout(() => setShow(true), 500);
    }
  }, [appId, appWarnings, show]);

  const overviewCards: TInsightType[] = [
    'revenue',
    'purchases',
    'sessions',
    'impressions',
    'active-users',
    'paywalls-conversion-rate',
  ];
  const overviewInsightEntitlements: string[] = [];
  overviewCards.forEach((card) => {
    overviewInsightEntitlements.push(PAGES[card].entitlement);
  });

  return (
    <Page title="Overview">
      {show && (
        <Row>
          <GettingStarted
            createdApp={hasAnApp}
            createdPaywall={hasAnApp && !appWarnings?.has_no_paywalls}
            installedSDK={hasAnApp && !appWarnings?.has_no_devices}
            openNewApp={() => dispatch(toggleNewAppModal())}
          />
        </Row>
      )}
      {hasAnApp && (
        <>
          <Row gutter={[24, 24]}>
            <Col lg={8} xs={24}>
              <SectionTitle>Overview</SectionTitle>
            </Col>
          </Row>
          <Row gutter={[24, 24]} style={{ marginTop: 18, marginBottom: 24 }}>
            <Col lg={8} md={12} xs={24}>
              <PlacementsSummaryCard />
            </Col>
            <Col lg={8} md={12} xs={24}>
              <CampaignsSummaryCard />
            </Col>
            <Col lg={8} md={12} xs={24}>
              <PaywallSummaryCard />
            </Col>
          </Row>
          <Row gutter={[24, 24]} style={{ marginBottom: 18 }}>
            <Col lg={8} xs={24}>
              <SectionTitle>Quick Stats</SectionTitle>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col lg={8} xs={24} md={12}>
              {overviewInsightEntitlements.some(
                appContext.userHasEntitlement
              ) && <EnvironmentSelect type="overview" />}
            </Col>
            <Col lg={8} xs={24} md={12}>
              {overviewInsightEntitlements.some(
                appContext.userHasEntitlement
              ) && <DateRangePicker type="overview" />}
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            {overviewCards.length &&
              overviewCards.map(
                (card) =>
                  appContext.userHasEntitlement(PAGES[card].entitlement) && (
                    <Col xs={24} md={12} lg={8} key={card}>
                      <WildCard
                        title={PAGES[card].title}
                        metric={card}
                        chartKeys={PAGES[card].chartKeys}
                        dataGetter={PAGES[card].dataFetcher}
                        totalGetter={PAGES[card].totalFetcher}
                        dataParser={PAGES[card].dataParser}
                        totalDataParser={PAGES[card].totalDataParser}
                      />
                    </Col>
                  )
              )}
          </Row>
        </>
      )}
      <TimeZoneLabel timezone={timezone} />
      <div style={{ marginBottom: 40 }} />
    </Page>
  );
}
