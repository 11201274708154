import { Input } from 'antd';
import styled, { css } from 'styled-components';

const VividPlaceholderPassword = styled(Input.Password)`
  ${({ disabled }) => {
    if (!disabled) return '';
    return css`
      &::placeholder {
        color: #000;
      }
    `;
  }}
`;

export default VividPlaceholderPassword;
