import React from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';

import { JOIN_ENABLED } from '../../config';
import { componentToFunction } from '../../utils/components';
import AcceptInvitePage from './accept-invite';
import EmailAcceptedPage from './email-accepted/EmailAcceptedPage';
import EmailVerificationPage from './email-verification/EmailVerificationPage';
import ForgotPasswordPage from './forgot-password';
import InviteSignUpPage from './invite-signup';
import JoinAppSumoPage from './join-app-sumo/JoinAppSumoPage';
import JoinPage from './join/JoinPage';
import LoginPage from './login';
import PreAuthPage from './login/PreAuthPage';
import PreAuthRecoveryPage from './login/PreAuthRecoveryPage';
import ResetPasswordPage from './reset-password';

const PublicRouter = () => {
  return (
    <Route
      render={({ location }) => (
        <div
          style={{
            WebkitOverflowScrolling: 'touch',
            overflowScrolling: 'touch',
          }}
        >
          <Switch location={location}>
            <Route
              exact
              path="/login/"
              render={componentToFunction(LoginPage)}
            />
            {JOIN_ENABLED && (
              <Route
                exact
                path="/join/"
                render={componentToFunction(JoinPage)}
              />
            )}
            {JOIN_ENABLED && (
              <Route
                exact
                path="/join/confirm/"
                render={componentToFunction(EmailVerificationPage)}
              />
            )}
            {JOIN_ENABLED && (
              <Route
                exact
                path="/join/confirm/:token/"
                render={componentToFunction(EmailAcceptedPage)}
              />
            )}
            <Route
              exact
              path="/finish-onboarding/"
              render={componentToFunction(JoinAppSumoPage)}
            />
            <Route
              exact
              path="/forgot-password/"
              render={componentToFunction(ForgotPasswordPage)}
            />
            <Route
              exact
              path="/reset/:token/"
              render={componentToFunction(ResetPasswordPage)}
            />
            <Route
              exact
              path="/invite-accepted/"
              render={componentToFunction(InviteSignUpPage)}
            />
            <Route
              exact
              path="/accept-invite/:inviteID/"
              render={componentToFunction(AcceptInvitePage)}
            />
            <Route
              exact
              path="/pre-auth/:type/"
              render={componentToFunction(PreAuthPage)}
            />
            <Route
              exact
              path="/pre-auth-recovery/:type/"
              render={componentToFunction(PreAuthRecoveryPage)}
            />
            <Redirect to="/login/" />
          </Switch>
        </div>
      )}
    />
  );
};

export default PublicRouter;
