import React from 'react';

import { DeleteOutlined, GlobalOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import Page from 'src/components/Page/Page';
import SyncStatus from 'src/components/SyncStatus';
import {
  useArchiveProductMutation,
  useProductQuery,
  useUpdateProductMutation,
} from 'src/hooks/queries/product.hooks';
import { platformLogo, platformName } from 'src/services/helpers';
import { namiPrimaryBlue } from 'src/variables';
import styled from 'styled-components';

import { useAppContext } from '../../../../hooks';
import { buildNameProps, TDRFErrors } from '../../../../utils/ant';
import ProductDetailDangerZone from './ProductDetailDangerZone';
import ProductEntitlementTable from './ProductEntitlementTable';

export const skuTypesOptions = [
  {
    key: 1,
    text: 'Subscription',
    value: 'subscription',
    label: 'Subscription',
  },
  {
    key: 2,
    text: 'One time Purchase',
    value: 'one_time_purchase',
    label: 'One Time Purchase',
  },
];

export const subscriptionDurationOptions = [
  //TODO - combine 7 days and 1 week
  { key: 0, text: '7 Days', value: 'P7D', label: '7 Days' },
  { key: 1, text: '1 Week', value: 'P1W', label: '1 Week' },
  { key: 2, text: '4 Weeks', value: 'P4W', label: '4 Weeks' },
  { key: 3, text: '1 Month', value: 'P1M', label: '1 Month' },
  { key: 4, text: '2 Months', value: 'P2M', label: '2 Months' },
  { key: 5, text: '3 Months', value: 'P3M', label: '3 Months' },
  { key: 6, text: '6 Months', value: 'P6M', label: '6 Months' },
  { key: 7, text: '1 Year', value: 'P1Y', label: '1 Year' },
];
//TODO - Android allows more granular options than this even, such as 18 months
export const trialDurationOptions = [
  { key: 1, text: '3 Days', label: '3 Days', value: 'P3D' },
  { key: 2, text: '4 Days', label: '4 Days', value: 'P4D' },
  { key: 3, text: '5 Days', label: '5 Days', value: 'P5D' },
  { key: 4, text: '6 Days', label: '6 Days', value: 'P6D' },
  { key: 5, text: '1 Week', label: '1 Week', value: 'P1W' },
  { key: 6, text: '2 Week', label: '2 Week', value: 'P2W' },
  { key: 7, text: '3 Week', label: '3 Week', value: 'P3W' },
  { key: 8, text: '4 Week', label: '4 Week', value: 'P3W' },
  { key: 9, text: '1 Month', label: '1 Month', value: 'P1M' },
  { key: 10, text: '2 Months', label: '2 Months', value: 'P2M' },
  { key: 11, text: '3 Months', label: '3 Months', value: 'P3M' },
  { key: 12, text: '4 Months', label: '4 Months', value: 'P4M' },
  { key: 13, text: '5 Months', label: '5 Months', value: 'P5M' },
  { key: 14, text: '6 Months', label: '6 Months', value: 'P6M' },
  { key: 15, text: '7 Months', label: '7 Months', value: 'P7M' },
  { key: 16, text: '8 Months', label: '8 Months', value: 'P8M' },
  { key: 17, text: '9 Months', label: '9 Months', value: 'P9M' },
  { key: 18, text: '10 Months', label: '10 Months', value: 'P10M' },
  { key: 19, text: '11 Months', label: '11 Months', value: 'P11M' },
  { key: 20, text: '1 Year', label: '1 Year', value: 'P1Y' },
  { key: 21, text: '2 Years', label: '2 Years', value: 'P2Y' },
  { key: 22, text: '3 Years', label: '3 Years', value: 'P3Y' },
];
const gracePeriodOptions = [
  { key: 1, label: '3 Days', value: 'P3D' },
  { key: 2, label: '6 Days', value: 'P6D' },
  { key: 3, label: '1 Week', value: 'P1W' },
  { key: 4, label: '2 Weeks', value: 'P2W' },
  { key: 5, label: '16 Days', value: 'P16D' },
  { key: 6, label: '30 Days', value: 'P30D' },
];

const PlatformTextLink = styled(Typography.Text)`
  :hover {
    text-decoration: underline;
    color: ${namiPrimaryBlue};
  }
`;

export default function ProductDetailPage() {
  const history = useHistory();
  const { userHasEntitlement, planHasEntitlement } = useAppContext();
  const productId = useParams<{ productID: string }>().productID;
  const productQuery = useProductQuery(productId);
  const updateProductMutation = useUpdateProductMutation(productId);
  const archiveProductMutation = useArchiveProductMutation();
  const subscriptionManagement = planHasEntitlement(
    'app.capabilities.entitlement_management'
  );

  const platformType = productQuery.data?.platform_type;
  const platformLabel = platformType ? platformName(platformType) : 'store';
  const lastSync = productQuery.data?.platform_synced
    ? new Date(productQuery.data.platform_synced)
    : null;
  const isAutoCreated = !!lastSync;
  const archived = productQuery.data?.archived;
  const cantArchive = productQuery.data?.delete_warnings.is_in_use_by_paywalls;

  const canUpdate = userHasEntitlement('app.productsku.update') && !archived;
  const errors = (updateProductMutation.error || {}) as TDRFErrors;

  return (
    <Page title={productQuery.data?.name || 'Loading product'}>
      <Row justify="start" gutter={[0, 36]}>
        <Col xs={24} style={{ paddingBottom: 12 }}>
          <Breadcrumb
            items={[
              { url: '/products/', name: 'Products' },
              { name: getPageTitle() },
            ]}
          />
        </Col>
      </Row>
      <Card bordered={false} loading={productQuery.isFetching}>
        <Form
          initialValues={productQuery.data}
          layout="vertical"
          onFinish={updateProductMutation.mutate}
        >
          <Row gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <Form.Item label="Platform">
                <Space
                  direction="horizontal"
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    history.push(
                      `/integrations/${productQuery.data?.platform_type}/${productQuery.data?.platform}/`
                    )
                  }
                >
                  <Image
                    src={platformLogo(platformType)}
                    preview={false}
                    height={24}
                    width={24}
                  />
                  <PlatformTextLink>
                    {productQuery.data?.platform_name}
                  </PlatformTextLink>
                </Space>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <SyncStatus
                label={`Synced with ${platformLabel}`}
                syncDate={lastSync}
                syncLabelPrefix="Last updated"
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <Form.Item label="Name" {...buildNameProps('name', errors)}>
                <Input
                  placeholder="Product Name"
                  type="text"
                  className="intercom-skuName"
                  disabled={!canUpdate}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="SKU ID"
                name="sku_ref_id"
                tooltip="SKU ID cannot be edited after a product has been created"
              >
                <Input
                  placeholder="Product SKU ID"
                  className="intercom-skuRefId"
                  type="text"
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <Form.Item
                label="SKU Type"
                {...buildNameProps('sku_type', errors)}
              >
                <Select
                  placeholder="SKU Type"
                  options={skuTypesOptions}
                  disabled={!canUpdate || isAutoCreated}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row
            gutter={[16, 16]}
            hidden={productQuery.data?.sku_type !== 'subscription'}
          >
            <Col md={8} xs={12}>
              <Form.Item
                label="Subscription Duration"
                className="intercom-subscriptionDuration"
                {...buildNameProps('standard_duration', errors)}
              >
                <Select
                  placeholder="Select Subscription Duration"
                  options={subscriptionDurationOptions}
                  disabled={!canUpdate || isAutoCreated}
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={12}>
              <Form.Item
                label="Free Trial Duration"
                className="intercom-freeTrialDuration"
                {...buildNameProps('free_trial_duration', errors)}
              >
                <Select
                  placeholder="Select Trial Duration"
                  options={trialDurationOptions}
                  disabled={!canUpdate || isAutoCreated}
                />
              </Form.Item>
            </Col>
            <Col md={8} xs={12}>
              <Form.Item
                label="Grace Period Duration"
                className="intercom-gracePeriodDuration"
                {...buildNameProps('grace_period_duration', errors)}
              >
                <Select
                  placeholder="Select Grace Period Duration"
                  options={gracePeriodOptions}
                  disabled={!canUpdate || isAutoCreated}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={12}>
              <Space direction="horizontal">
                <Button
                  type="primary"
                  loading={updateProductMutation.isLoading}
                  htmlType="submit"
                  className="intercom-updateProduct"
                  disabled={!canUpdate}
                >
                  Update Product
                </Button>
                <Button
                  type="text"
                  loading={archiveProductMutation.isLoading}
                  className="intercom-archiveProduct"
                  disabled={
                    !userHasEntitlement('app.productsku.update') || cantArchive
                  }
                  onClick={() =>
                    archiveProductMutation.mutate({
                      product_id: productId,
                      archived: !!productQuery.data?.archived,
                    })
                  }
                  icon={<DeleteOutlined style={{ fontSize: 12 }} />}
                >
                  {archived ? `Unarchive Product` : `Archive Product`}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
        {subscriptionManagement && (
          <ProductEntitlementTable
            isLoading={productQuery.isFetching}
            entitlements={productQuery.data?.entitlements}
          />
        )}
        {planHasEntitlement('app.paywall.legacy.create') && (
          <Button
            type="link"
            icon={<GlobalOutlined />}
            onClick={() => history.push(`/products/${productId}/languages/`)}
            style={{ marginTop: '24px' }}
            disabled={!canUpdate}
          >
            Manage Languages
          </Button>
        )}
        {productQuery.data && (
          <ProductDetailDangerZone
            data={productQuery.data}
            platformLabel={platformLabel}
          />
        )}
      </Card>
    </Page>
  );

  function getPageTitle(): string {
    if (productQuery.isLoading) return 'Loading product...';
    else {
      if (!!productQuery.data?.archived)
        return `${productQuery.data?.name} [Archived]`;
      return productQuery.data?.name || '';
    }
  }
}
