import {
  TSchemaDefinition,
  TSchemaProperty,
  TSchemaRef,
  TSchemaRoot,
} from '../api/types/schema.types';
import { capitalizeString, lowercaseIncludes } from './string';

export function formatDynamicError(
  configErrors: string[]
): Record<string, string | undefined> {
  return (configErrors || []).reduce((output, error) => {
    const errorSplit = error.split(':');
    // Root errors are from validations that happen before Pydantic
    const [key, value] =
      errorSplit.length > 1 ? errorSplit : ['__root__', error];
    const properValue = capitalizeString(getProperMessage(value).trim());
    return { ...output, [key]: properValue };
  }, {});
}

function getProperMessage(value: string): string {
  const expected = [
    'This field may not be null.',
    'None is not an allowed value.',
  ];
  return lowercaseIncludes(expected, value) ? 'Field required.' : value;
}

export function buildSchemaFields(schema: TSchemaRoot): TSchemaProperty[] {
  return Object.entries(schema.properties).map(([name, property]) => {
    return { ...getFieldRef(property, schema), key: name };
  });
}

export function getFieldRef(
  property: TSchemaProperty | TSchemaRef,
  schema: TSchemaRoot
): TSchemaProperty {
  if (!('$ref' in property)) return property;
  const [, ...path] = property.$ref.split('/');
  return path.reduce(
    (output: any, piece) => output[piece] as TSchemaProperty,
    schema
  );
}

//Parse through a JSON schema object and return all potential properties
export function returnSchemaProperties(
  schema: {
    [key: string]: any;
  },
  idRoot: string,
  results: TSchemaDefinition[]
): TSchemaDefinition[] {
  if (typeof schema === 'object') {
    if (schema.hasOwnProperty('type') && schema.type !== 'object') {
      results = [
        ...results,
        {
          title: `${idRoot}`,
          type: schema.type,
          description: schema.title,
          placeholder: schema.title,
          child: idRoot.split('.').length > 2,
          parent: idRoot.split('.').slice(0, 2).join('.'),
        } as TSchemaDefinition,
      ];
    }
    return Object.keys(schema).reduce((output, propertyKey) => {
      if (
        propertyKey === 'examples' ||
        propertyKey === 'default' ||
        propertyKey === 'required'
      )
        return output;
      const newId =
        propertyKey === 'items' || propertyKey === 'properties'
          ? idRoot
          : `${idRoot}.${propertyKey}`;
      return returnSchemaProperties(schema[propertyKey], newId, output);
    }, results);
  }

  return results;
}
