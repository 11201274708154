import React, { useMemo, useState } from 'react';

import { Button, Select } from 'antd';
import { Form, Input } from 'semantic-ui-react';

import { useBooleanState } from '../hooks';
import { copyTextToClipBoard } from '../services/helpers';
import AntIcon from './AntIcon';

const FIELD_TYPE_MAP = {
  'string-password': 'password',
  'string-uri': 'url',
  string: 'text',
};

function LegacySchemaField(props) {
  const { field } = props;
  const Component = 'enum' in field ? SchemaDropdown : SchemaInput;
  return (
    <Form.Field>
      <label>{field.title}</label>
      <Component {...props} />
    </Form.Field>
  );
}

export default LegacySchemaField;

function SchemaInput({
  field,
  value,
  error,
  actionMap,
  required,
  onChange,
  disabled,
}) {
  const [isCopied, setCopied] = useState(false);
  const [isRevealed, reveal] = useBooleanState(!field.revealable);
  const type = useMemo(() => {
    if (field.revealable && !isRevealed) return 'password';
    if (field.revealable && isRevealed) return 'text';
    const typeKey = field.format ? `${field.type}-${field.format}` : field.type;
    return FIELD_TYPE_MAP[typeKey];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRevealed]);

  let action = null;
  if (field.revealable && !isRevealed) {
    action = {
      type: 'button',
      basic: true,
      icon: 'eye',
      content: 'Reveal',
      disabled: disabled,
      onClick: reveal,
    };
  } else if (field.copyable && isRevealed) {
    action = {
      type: 'button',
      basic: true,
      icon: 'copy',
      content: isCopied ? 'Copied' : 'Copy',
      disabled: disabled,
      onClick: () => {
        copyTextToClipBoard(value);
        setCopied(true);
      },
    };
  }

  return (
    <>
      <Input
        required={required}
        type={type}
        name={field.key}
        placeholder={field.placeholder}
        value={value}
        readOnly={field.readOnly}
        autoComplete="new-password"
        onChange={onChange}
        error={error}
        disabled={disabled}
        action={action}
      />
      {field?.helpText && <small>{field?.helpText} </small>}
      {!!field?.actions?.length &&
        field?.actions.map((action) => (
          <span key={action?.value}>
            <Button
              style={{ marginTop: 4 }}
              size="small"
              icon={<AntIcon name={action?.antIcon} />}
              disabled={disabled}
              onClick={actionMap[action?.value]}
            >
              {action?.title}
            </Button>
          </span>
        ))}
    </>
  );
}

function SchemaDropdown({ field, onChange, value, required, disabled }) {
  //TODO - correctly use the default value in dropdown
  const stringValue = value.length === 0 ? undefined : JSON.stringify(value);
  const options = Array.isArray(field.enum)
    ? field.enum.reduce((output, value) => ({ ...output, [value]: value }), {})
    : field.enum;

  return (
    <>
      <Select
        style={{ minWidth: '25rem' }}
        placeholder={field.placeholder}
        value={stringValue}
        onChange={handleOptionSelection}
        disabled={disabled}
      >
        {Object.entries(options).map(([label, option]) => (
          <Select.Option key={label} value={JSON.stringify(option)}>
            {label}
          </Select.Option>
        ))}
      </Select>
      {
        /*
         * This is supposed to be temporary. Waiting anxiously on
         * https://github.com/JedWatson/react-select/issues/4327
         */
        required && (
          <input
            tabIndex={-1}
            autoComplete="off"
            style={{ opacity: 0, width: 0, height: 0, position: 'absolute' }}
            value={stringValue}
            required={required}
          />
        )
      }
    </>
  );

  function handleOptionSelection(value) {
    // Mimics input field event
    onChange({ target: { name: field.key, value: JSON.parse(value) } });
  }
}
