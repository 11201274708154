import React from 'react';

import { useCampaignRulesQuery } from 'src/hooks/queries/campaign.hooks';

import SummaryCard from './SummaryCard';

export default function CampaignsSummaryCard() {
  const campaigns = useCampaignRulesQuery({
    pageSize: 20,
    enabled: true,
  });

  return (
    <SummaryCard
      title="Live Campaign Rules"
      loading={campaigns.isLoading}
      value={campaigns.data?.count}
      link={'/campaigns/rules/?label_id=&status=enabled'}
    />
  );
}
