import React from 'react';

import { Select, Skeleton } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import { environmentLabels, environments } from '../api/types/analytics.types';
import { useActions, useAppSelector } from '../hooks/redux.hooks';
import AnalyticsSlice from '../redux/AnalyticsSlice';
import FilterFormItem from './FilterFormItem';

type EnvironmentSelectProps = {
  type: 'insights' | 'overview';
};

export default function EnvironmentSelect({ type }: EnvironmentSelectProps) {
  const actions = useActions(AnalyticsSlice.actions);
  const environment = useAppSelector(
    (state) => state.analytic.environment.value
  );
  const isRendering = useAppSelector(
    (state) => state.analytic.placeholderState
  );
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  return (
    <FilterFormItem
      label={'Environment'}
      colon={false}
      className={'intercom-environmentSelect'}
    >
      {type === 'insights' && isRendering ? (
        <Skeleton.Input active={true} size="small" block={true} />
      ) : (
        <Select
          value={environment}
          placeholder={environmentLabels[environment]}
          onChange={(value) => {
            queryParams.set('environment', value);
            location.search = queryParams.toString();
            history.push(location);
            actions.setEnvironment({ value });
          }}
          options={environments.map((environment) => ({
            key: environment,
            value: environment,
            label: environmentLabels[environment],
          }))}
        />
      )}
    </FilterFormItem>
  );
}
