import React, { useEffect } from 'react';

import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
} from 'antd';
import { useParams } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import Page from 'src/components/Page/Page';
import { useAppContext } from 'src/hooks';
import {
  useDeleteEntitlementMutation,
  useEntitlementQuery,
  useUpdateEntitlementMutation,
} from 'src/hooks/queries/entitlement.hooks';
import { buildNameProps, TDRFErrors } from 'src/utils/ant';

import EntitlementProductTable from './EntitlementProductTable';

export default function EntitlementDetailPage() {
  const [form] = Form.useForm();
  const { userHasEntitlement } = useAppContext();
  const entitlementId = useParams<{ entitlementID: string }>().entitlementID;
  const entitlementQuery = useEntitlementQuery(entitlementId);
  const updateEntitlementMutation = useUpdateEntitlementMutation(entitlementId);
  const deleteEntitlementMutation = useDeleteEntitlementMutation(entitlementId);

  const errors = (updateEntitlementMutation.error || {}) as TDRFErrors;
  const entitlementName = entitlementQuery.data?.name;
  const refId = entitlementQuery.data?.entitlement_ref_id;
  const pageTitle = entitlementName || 'Loading entitlement';

  useEffect(() => {
    if (!entitlementQuery.data) return;
    form.setFieldsValue(entitlementQuery.data);
  }, [form, entitlementQuery.data]);

  return (
    <Page title={pageTitle}>
      <Row justify="start" gutter={[0, 36]}>
        <Col xs={24} style={{ paddingBottom: 12 }}>
          <Breadcrumb
            items={[
              { url: '/entitlements/', name: 'Entitlements' },
              { name: pageTitle },
            ]}
          />
        </Col>
      </Row>
      <Card bordered={false}>
        <Form
          form={form}
          layout="vertical"
          onFinish={updateEntitlementMutation.mutate}
        >
          <Row gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <Form.Item label="Name" {...buildNameProps('name', errors)}>
                <Input
                  placeholder="Entitlement Name"
                  type="text"
                  className="intercom-entitlementName"
                  disabled={!userHasEntitlement('app.entitlement.update')}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Reference ID"
                tooltip="Ref ID cannot be edited after an entitlement has been created"
                name="entitlement_ref_id"
                extra="Use this ID to reference the entitlement in your app code"
              >
                <Input
                  placeholder="Reference ID"
                  type="text"
                  className="intercom-entitlementRefID"
                  disabled={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={24}>
              <Form.Item
                label="Description"
                {...buildNameProps('description', errors)}
              >
                <Input
                  placeholder="Entitlement Description"
                  type="text"
                  className="intercom-entitlementDesc"
                  disabled={!userHasEntitlement('app.entitlement.update')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={12}>
              <Button
                type="primary"
                loading={updateEntitlementMutation.isLoading}
                htmlType="submit"
                className="intercom-updateEntitlement"
                disabled={!userHasEntitlement('app.entitlement.update')}
              >
                Update Entitlement
              </Button>
            </Col>
          </Row>
        </Form>
        <EntitlementProductTable />
        <Collapse ghost style={{ marginBottom: 15, marginTop: 20 }}>
          <Collapse.Panel
            header={<h4>Danger Zone</h4>}
            key="1"
            className="intercom-entitlementDelete"
          >
            <Space direction="vertical">
              <Typography.Text>
                Deleting this entitlement will make it unavailable in your app.
                Users who purchase the associated products in the future will no
                longer be granted access. Current app users with this
                entitlement will have their access removed.
              </Typography.Text>
              <Typography.Text>
                Associated Product SKUs will not be removed from the Control
                Center.
              </Typography.Text>
              <Typography.Text strong>
                A deleted entitlement cannot be recovered.
              </Typography.Text>
              <Button
                type="primary"
                danger
                disabled={!userHasEntitlement('app.entitlement.delete')}
                loading={deleteEntitlementMutation.isLoading}
                onClick={confirmEntitlementRemoval}
              >
                Delete Entitlement
              </Button>
            </Space>
          </Collapse.Panel>
        </Collapse>
      </Card>
    </Page>
  );

  function confirmEntitlementRemoval() {
    Modal.confirm({
      title: `Remove ${entitlementName} entitlement`,
      content: [
        `Are you sure you want to remove the ${entitlementName} (${refId}) entitlement?`,
      ].join('\n'),
      okText: `Yes, remove ${entitlementName} entitlement`,
      okType: 'danger',
      okButtonProps: { type: 'primary' },
      cancelText: 'Cancel',
      onOk() {
        deleteEntitlementMutation.mutate();
      },
    });
  }
}
