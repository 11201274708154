import { namiBrightGreen, namiPureWhite } from 'src/variables';
import styled from 'styled-components';

const ScheduledDot = styled.span`
  border-radius: 50%;
  border: 1.5px solid ${namiBrightGreen};
  background-color: ${namiPureWhite};
  width: 8px;
  height: 8px;
  display: inline-flex;
  box-shadow: 0.5px 0.5px 7px 0px rgba(76, 84, 90, 0.16);
`;

export default ScheduledDot;
