import { Select } from 'antd';
import { namiDarkGray } from 'src/variables';
import styled, { css } from 'styled-components';

const StatusSelect = styled(Select)<{
  label?: string | null;
  operator?: string;
}>`
  .ant-select-selector {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    ${({ label }) => {
      if (!label) return '';
      return css`
        &:before {
          display: block;
          content: '${label}';
          font-weight: 600;
          min-width: fit-content;
          margin-right: 0.5rem;
        }
      `;
    }}
  }

  .ant-select-selection-placeholder {
    color: ${namiDarkGray};
  }

  .ant-select-selection-item {
    padding-right: 16px;
  }
`;

export default StatusSelect;
