import React from 'react';

import { FieldObject } from '../../../utils/formFieldBuilding';
import { DisplayFieldSwitch } from './DisplayFieldSwitch';
import InputLabel from './InputLabel';

type ToggleSwitchProps = Omit<FieldObject, 'variable'> & {
  onChange: (value: any) => void;
  label: string;
  isSingle?: boolean;
  collapsible?: boolean;
};

export default function ToggleSwitch({
  onChange,
  ...field
}: ToggleSwitchProps) {
  return (
    <DisplayFieldSwitch
      label={<InputLabel {...field} />}
      checked={field.value as boolean}
      onChange={(value) => field.editable && onChange(value)}
    />
  );
}
