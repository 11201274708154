import { client } from './clients';
import {
  PlatformAllowedOriginType,
  PlatformPayloadType,
  PlatformType,
} from './types/main.types';
import {
  extractErrorData,
  extractListResponseData,
  extractResponseData,
} from './utils';

const platforms = {
  getPlatforms: (appId: string): Promise<PlatformType[]> => {
    return client
      .get(`/private/platforms/?app_id=${appId}`)
      .then(extractListResponseData)
      .catch(extractErrorData);
  },
  getPlatform: (platformId: string): Promise<PlatformType> => {
    return client
      .get(`/private/platforms/${platformId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  addPlatform: (platform: PlatformPayloadType): Promise<PlatformType> => {
    return client
      .post('/private/platforms/', platform)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  updatePlatform: (
    platformId: string,
    platform: Partial<PlatformPayloadType>
  ): Promise<PlatformType> => {
    return client
      .patch(`/private/platforms/${platformId}/`, platform)
      .then(extractResponseData)
      .catch(extractErrorData);
  },

  deletePlatform: (platformId: string): Promise<void> => {
    return client
      .delete(`/private/platforms/${platformId}/`)
      .catch(extractErrorData) as unknown as Promise<void>;
  },
  getPlatformAllowedOrigins: (
    platformId: string
  ): Promise<PlatformAllowedOriginType[]> => {
    return client
      .get(`/private/platform_allowed_origins/?platform_id=${platformId}`)
      .then(extractListResponseData)
      .catch(extractErrorData);
  },
  createPlatformAllowedOrigin: (
    origin: Omit<PlatformAllowedOriginType, 'id'>
  ): Promise<PlatformAllowedOriginType> => {
    return client
      .post('/private/platform_allowed_origins/', origin)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  deletePlatformAllowedOrigin: (originId: string): Promise<void> => {
    return client
      .delete(`/private/platform_allowed_origins/${originId}/`)
      .catch(extractErrorData) as unknown as Promise<void>;
  },
};

export default platforms;
