import React, { useState } from 'react';

import { Container } from 'semantic-ui-react';

import { AppContextType } from '../../AppContextProvider';
import { DesktopHeader, MobileHeader } from '../../components/Headers';
import DesktopMenu from '../../components/Menus/DesktopMenu/DesktopMenu';
import MobileMenu from '../../components/Menus/MobileMenu/MobileMenu';
import Responsive from '../../components/Responsive/Responsive';
import { useAppContext } from '../../hooks';

type PageMenuAndHeaderProps = {
  onAppChange: (appId: string) => void;
  isVisible: boolean;
  openAppSwitchMenu: () => void;
};

export default function NavigationFramework({
  isVisible,
  onAppChange,
  openAppSwitchMenu,
}: PageMenuAndHeaderProps) {
  const context: AppContextType = useAppContext();
  const [mobileMenu, setMobileMenu] = useState(false);
  const menuItems = [
    {
      name: 'Overview',
      path: '/overview/',
      iconName: 'overview',
      enabled: context.userHasEntitlement('overview.list'),
    },
    {
      name: 'Insights',
      path: '/insights/revenue/',
      iconName: 'insights',
      enabled: context.userHasEntitlement('analytics.list'),
    },
    {
      name: 'Campaigns',
      path: '/campaigns/placements/',
      iconName: 'campaign',
      enabled: context.userHasEntitlement('app.campaign.list'),
    },
    {
      name: 'Legacy Campaigns',
      path: '/legacy/campaigns',
      iconName: 'legacy',
      enabled: context.userHasEntitlement('app.campaign.legacy.list'),
    },
    {
      name: 'Paywalls',
      path: '/paywalls/',
      iconName: 'paywall',
      enabled: context.userHasEntitlement('app.paywall.list'),
    },
    {
      name: 'Entitlements',
      path: '/entitlements/',
      iconName: 'entitlement',
      enabled: context.userHasEntitlement('app.entitlement.list'),
    },
    {
      name: 'Products',
      path: '/products/',
      iconName: 'products',
      enabled: context.userHasEntitlement('app.productsku.list'),
    },
    {
      name: 'Customers',
      path: '/customers/',
      iconName: 'crm',
      enabled: context.userHasEntitlement('app.user.list'),
    },
    {
      name: 'Developer',
      path: '/developer/',
      iconName: 'sdk',
      enabled: context.userHasEntitlement('app.event.list'),
    },
    {
      name: 'Integrations',
      path: '/integrations/',
      iconName: 'integration',
      enabled: context.userHasEntitlement('app.platform.list'),
    },
    {
      name: 'App Settings',
      path: '/app-settings/',
      iconName: 'app settings',
      enabled: context.userHasEntitlement('app.get'),
    },
  ];
  return (
    <>
      <Responsive size="mdUp">
        <DesktopMenu
          isVisible={isVisible}
          items={menuItems}
          onAppChange={onAppChange}
        />
        {isVisible && <DesktopHeader />}
      </Responsive>
      <Responsive size="mdDown">
        <div className="header-mobile-container">
          <Container>
            {isVisible && (
              <MobileHeader
                mobileMenuOpen={() => setMobileMenu(true)}
                switchAppMenuOpen={openAppSwitchMenu}
              />
            )}
          </Container>
        </div>
        <MobileMenu
          items={menuItems}
          visible={mobileMenu}
          onClick={() => setMobileMenu(false)}
          Logout={() => {
            setMobileMenu(false);
            context.performLogout().then(/* :) */);
          }}
        />
      </Responsive>
    </>
  );
}
