import { Select } from 'antd';
import { namiDarkGray } from 'src/variables';
import styled, { css } from 'styled-components';

const PlacementSelect = styled(Select)<{
  label?: string | null;
  operator?: string;
}>`
  .ant-select-selector {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    ${({ label }) => {
      if (!label) return '';
      return css`
        &:before {
          display: block;
          content: '${label}';
          font-weight: 600;
          min-width: fit-content;
          margin-right: 0.5rem;
        }
      `;
    }}
  }

  .ant-select-selection-placeholder {
    color: ${namiDarkGray};
  }

  .ant-select-selection-item {
    margin-top: 0.2rem;
    font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas',
      'Liberation Mono', Menlo, Courier, monospace;
    font-size: 13px;
    padding-right: 16px;
  }
`;

export default PlacementSelect;
