import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { lightfair } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import {
  Table,
  Button,
  Icon,
  Pagination,
  Statistic,
  Segment,
  Modal,
} from 'semantic-ui-react';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import { namiPrimaryBlue } from 'src/variables';

import EmptyStateSegment from '../../../../../components/EmptyStateSegment/EmptyStateSegment';
import Loading from '../../../../../components/Loading/Loading';
import MobileTable from '../../../../../components/MobileTable/MobileTable';
import Page from '../../../../../components/Page/Page';
import Responsive from '../../../../../components/Responsive/Responsive';
import TimeZoneLabel from '../../../../../components/TimeZoneLabel/TimeZoneLabel';
import {
  getCrmUser,
  getCrmUserEvents,
} from '../../../../../redux/actions/crmActions';
import history from '../../../../../services/history';

const UserEventsPage = ({
  loading,
  getCrmUserEvents,
  getCrmUser,
  totalPages,
  count,
  events,
  crmUser,
}) => {
  const userId = useParams().userID;
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    getCrmUserEvents(userId, pageNumber);
    getCrmUser(userId);
  }, [userId, pageNumber, getCrmUserEvents, getCrmUser, setPageNumber]);

  let children;
  if (loading) {
    children = <Loading />;
  } else if (!events.length) {
    children = (
      <>
        <Statistic size="mini" horizontal>
          <Statistic.Value
            style={{ color: namiPrimaryBlue, fontWeight: 'bold' }}
          >
            0
          </Statistic.Value>
          <label style={{ padding: '4px 10px 0 5px' }}>
            Matching User Events
          </label>
        </Statistic>
        <EmptyStateSegment
          title="No User Events Found"
          subtitle="No user events found on this user."
          icon="sdk"
        >
          <Button
            className="nami-primary-button"
            onClick={() => {
              history.push(`/customers/${userId}/`);
            }}
          >
            <Icon name="arrow alternate circle left" /> Go Back
          </Button>
        </EmptyStateSegment>
      </>
    );
  } else if (!!events.length) {
    children = (
      <>
        <Statistic size="mini" horizontal>
          <Statistic.Value
            style={{ color: namiPrimaryBlue, fontWeight: 'bold' }}
          >
            {count.toLocaleString()}
          </Statistic.Value>
          <label style={{ padding: '4px 10px 0 5px' }}>
            Matching User Events
          </label>
        </Statistic>

        <Responsive size="mdUp">
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="table-header">
                  Event Type
                </Table.HeaderCell>
                <Table.HeaderCell className="table-header">
                  Created Date
                </Table.HeaderCell>
                <Table.HeaderCell className="table-header">
                  Event
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {events.length &&
                events.map((event, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{event.event_type_humanized}</Table.Cell>

                    <Table.Cell>
                      {moment(event.created_date).format(
                        'MMM DD, YYYY - hh:mm:ss'
                      )}
                    </Table.Cell>
                    {event.event ? (
                      <Table.Cell>
                        <Modal
                          trigger={
                            <Button icon size="tiny" onClick={() => {}}>
                              <Icon name="eye" />
                            </Button>
                          }
                          header={event.event_type_humanized}
                          content={
                            <SyntaxHighlighter
                              showLineNumbers={true}
                              language="json"
                              style={lightfair}
                            >
                              {JSON.stringify(event.event, null, 2)}
                            </SyntaxHighlighter>
                          }
                          actions={[{ key: 'done', content: 'Close' }]}
                        />
                      </Table.Cell>
                    ) : (
                      <Table.Cell>N/A</Table.Cell>
                    )}
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          {count > 20 && (
            <Pagination
              defaultActivePage={pageNumber}
              onPageChange={(e, { activePage }) => setPageNumber(activePage)}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={
                pageNumber !== 1
                  ? {
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }
                  : null
              }
              lastItem={
                totalPages !== pageNumber
                  ? {
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }
                  : null
              }
              prevItem={
                pageNumber !== 1
                  ? { content: <Icon name="angle left" />, icon: true }
                  : null
              }
              nextItem={
                totalPages !== pageNumber
                  ? { content: <Icon name="angle right" />, icon: true }
                  : null
              }
              totalPages={totalPages}
            />
          )}
        </Responsive>
        <Responsive size="mdDown">
          <Segment>
            <MobileTable
              disabledIcon={false}
              collection={events.map((event) => ({
                name: event.event_type_humanized,
                metaLabel: moment(event.created_date).format(
                  'MMM DD, YYYY - hh:mm:ss'
                ),
                graphicContent: () => (
                  <Modal
                    trigger={
                      <Button icon size="tiny" onClick={() => {}}>
                        <Icon name="eye" />
                      </Button>
                    }
                    header={event.event_type_humanized}
                    content={
                      <SyntaxHighlighter
                        showLineNumbers={true}
                        language="json"
                        style={lightfair}
                      >
                        {JSON.stringify(event.event, null, 2)}
                      </SyntaxHighlighter>
                    }
                    actions={[{ key: 'done', content: 'Close' }]}
                  />
                ),
              }))}
            />
          </Segment>
          {count > 20 && (
            <Pagination
              defaultActivePage={pageNumber}
              onPageChange={(e, { activePage }) => setPageNumber(activePage)}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              prevItem={
                pageNumber !== 1
                  ? { content: <Icon name="angle left" />, icon: true }
                  : null
              }
              nextItem={
                totalPages !== pageNumber
                  ? { content: <Icon name="angle right" />, icon: true }
                  : null
              }
              totalPages={totalPages}
            />
          )}
        </Responsive>
        <TimeZoneLabel />
      </>
    );
  }
  return (
    <Page>
      <Row justify="start" gutter={[0, 36]}>
        <Col xs={24} style={{ paddingBottom: 8 }}>
          <Breadcrumb
            items={[
              { url: '/customers/', name: 'Customers' },
              {
                url: `/customers/${userId}/`,
                name: crmUser?.pseudonym || 'Loading customer',
              },
              { name: 'Events' },
            ]}
          />
        </Col>
      </Row>
      {children}
    </Page>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCrmUserEvents: (id, pageNumber) =>
      dispatch(getCrmUserEvents(id, pageNumber)),
    getCrmUser: (id) => dispatch(getCrmUser(id)),
  };
};

const mapStateToProps = ({ root, crm }) => {
  return {
    currentApp: root.currentApp,
    events: crm.events,
    count: crm.count,
    totalPages: crm.totalPages,
    loading: crm.loading,
    crmUser: crm.crmUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserEventsPage);
