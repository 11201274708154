import React, { useEffect, useRef, useState } from 'react';

import Picker from './Picker';
import PickerContextWrapper from './PickerContextWrapper';
import './styles.css';

export * from './useColorPicker';

export const ShowRadialGradientContext = React.createContext<boolean>(true);

type ColorPickerProps = {
  value?: string;
  inDarkMode?: boolean;
  hideControls?: boolean;
  hideInputs?: boolean;
  hidePresets?: boolean;
  showRadialGradient?: boolean;
  showDarkModeSwitcher?: boolean;
  presets?: string[];
  hideEyeDrop?: boolean;
  hideOpacity?: boolean;
  hideAdvancedSliders?: boolean;
  hideColorGuide?: boolean;
  hideInputType?: boolean;
  className?: string;
  onChange?(color: string): void;
  onDarkModeChange?(value: boolean): void;
  dynamicColors?: { label: string; value: string }[];
};

export default function ColorPicker({
  value = 'rgba(175, 51, 242, 1)',
  onChange = () => {},
  hideControls = false,
  hideInputs = false,
  hidePresets = false,
  presets = [],
  hideEyeDrop = false,
  hideOpacity = false,
  hideAdvancedSliders = false,
  hideColorGuide = false,
  hideInputType = false,
  showRadialGradient = true,
  showDarkModeSwitcher = false,
  onDarkModeChange,
  inDarkMode = false,
  dynamicColors = [],
  className,
}: ColorPickerProps) {
  const contRef = useRef<HTMLDivElement>(null);
  const [bounds, setBounds] = useState<DOMRect | null>(null);

  useEffect(() => {
    setBounds(contRef?.current?.getBoundingClientRect() || null);
  }, []);

  return (
    <div ref={contRef} className={className}>
      <ShowRadialGradientContext.Provider value={showRadialGradient}>
        <PickerContextWrapper bounds={bounds} value={value} onChange={onChange}>
          <Picker
            inDarkMode={inDarkMode}
            hideControls={hideControls}
            hideInputs={hideInputs}
            hidePresets={hidePresets}
            presets={presets}
            hideEyeDrop={hideEyeDrop}
            hideOpacity={hideOpacity}
            hideAdvancedSliders={hideAdvancedSliders}
            hideColorGuide={hideColorGuide}
            hideInputType={hideInputType}
            showDarkModeSwitcher={showDarkModeSwitcher}
            onDarkModeChange={onDarkModeChange}
            dynamicColors={dynamicColors}
          />
        </PickerContextWrapper>
      </ShowRadialGradientContext.Provider>
    </div>
  );
}
