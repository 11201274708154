import React, { useEffect, useState } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { BinaryOperator } from '@cubejs-client/core';
import { Button } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import {
  TMetricFilter,
  TMetricFilterRule,
  FilterOptions,
  TAppliedFilters,
} from 'src/api/types/analytics.types';
import { useQueryParams } from 'src/hooks';
import styled from 'styled-components';
import { v4 as uuid4 } from 'uuid';

import { useActions, useAppSelector } from '../../../hooks/redux.hooks';
import AnalyticsSlice from '../../../redux/AnalyticsSlice';
import { IdentifierSelect } from './selectors';

type FilterBuilderProps = {
  filterOptions: TMetricFilter[];
  disabled?: boolean;
};

const SelectWrapper = styled.div`
  margin: 5px 5px 0 0;
  min-width: 15rem;
`;

export default function FilterBuilder({
  disabled,
  filterOptions,
}: FilterBuilderProps) {
  const actions = useActions(AnalyticsSlice.actions);
  const finalFilters = useAppSelector((state) => state.analytic.filters);
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();

  const [filters, setFilters] = useState<TAppliedFilters>(finalFilters);

  useEffect(() => setFilters(finalFilters), [finalFilters]);

  const keyValues = Object.entries(filters);
  return (
    <>
      {keyValues.map(([key, filter]) => {
        const handleValueChange = (values: string[]) => {
          if (!(key in filters)) return;
          const newValue = { ...filters, [key]: { ...filters[key], values } };
          const update = values.length > 0 ? actions.setFilters : setFilters;
          update(newValue);
          writeFiltersToURL(newValue);
        };
        const handleOperatorChange = (operator: BinaryOperator) => {
          if (!(key in filters)) return;
          const value = { ...filters, [key]: { ...filters[key], operator } };
          actions.setFilters(value);
          writeFiltersToURL(value);
        };
        const handleRemoval = () => {
          const { [key]: _, ...newFilters } = filters;
          actions.setFilters(newFilters);
          writeFiltersToURL(newFilters);
        };
        const Select = FilterOptions[filter.identifier].filterSelectElement;
        return (
          <SelectWrapper key={key}>
            <Select
              disabled={disabled}
              onValueChange={handleValueChange}
              onRemove={handleRemoval}
              onOperatorChange={handleOperatorChange}
              {...filter}
            />
          </SelectWrapper>
        );
      })}
      <SelectWrapper>
        <IdentifierSelect
          disabled={disabled}
          onChange={addFilter}
          identifierOptions={filterOptions}
          className="intercom-filterBy"
        />
      </SelectWrapper>
      {keyValues.length > 0 ? (
        <Button
          type="text"
          icon={<CloseOutlined />}
          style={{ float: 'right', margin: '5px 0px' }}
          onClick={() => {
            actions.setFilters({});
            writeFiltersToURL({});
          }}
        >
          Reset
        </Button>
      ) : null}
    </>
  );

  function addFilter(identifier: TMetricFilter): void {
    const newFilter: TMetricFilterRule = {
      identifier,
      operator: 'equals',
      values: [],
    };
    setFilters((state) => ({ ...state, [uuid4()]: newFilter }));
  }

  function writeFiltersToURL(newFilters: TAppliedFilters): void {
    let filtersArray: TMetricFilterRule[] = [];
    Object.values(newFilters).forEach((filter) => {
      if (filter.values.length > 0) filtersArray.push(filter);
    });
    queryParams.set(
      'filters',
      filtersArray.length > 0 ? JSON.stringify(filtersArray) : '[]'
    );
    location.search = queryParams.toString();
    history.push(location);
  }
}
