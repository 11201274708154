import React, { useEffect, useState, Fragment } from 'react';

import { SketchPicker } from 'react-color';
import { Button, Message } from 'semantic-ui-react';

import { invertColor, pickColor } from '../../utils/css';

const ColorPickerLegacy = ({ paywall, color = '#000000', onChange }) => {
  const [toggle, setToggle] = useState(false);
  const [display, setDisplay] = useState('none');
  const [hover, setHover] = useState(color);
  let id = `_${Math.random().toString(36).substr(2, 9)}`;

  useEffect(() => {
    if (
      paywall.background_image_url_phone ||
      paywall.background_image_url_tablet
    ) {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      let canvas = document.getElementById(id);

      canvas.style.cursor = 'cell';
      canvas.style.display = display;

      let ctx = canvas.getContext('2d');
      image.onload = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(
          image,
          0,
          0,
          300,
          paywall.background_image_url_phone ? 600 : 450
        );
      };
      image.src =
        paywall.background_image_url_phone ||
        paywall.background_image_url_tablet;
    }
  }, [
    id,
    display,
    paywall.background_image_url_phone,
    paywall.background_image_url_tablet,
  ]);

  const imagePresent = () => {
    return paywall.background_image_url_phone ||
      paywall.background_image_url_tablet
      ? true
      : false;
  };

  const pickOnHover = (event) => {
    setHover(pickColor(event));
  };

  const pickOnClick = (event) => {
    onChange({ hex: pickColor(event) });
    setDisplay('none');
    event.target.removeEventListener('click', pickOnClick);
    event.target.removeEventListener('mousemove', pickOnHover);
  };

  const onClick = () => {
    let canvas = document.getElementById(id);

    if (imagePresent()) {
      canvas.addEventListener('mousemove', pickOnHover);
      canvas.addEventListener('click', pickOnClick);
    }
    setDisplay('block');
  };

  const popover = {
    position: 'absolute',
    zIndex: 100,
  };

  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };

  const colorPicker = {
    background: color,
    color: invertColor(color),
    border: '1px solid rgb(229, 229, 229)',
    textTransform: 'uppercase',
  };

  const eyeDropper = () => {
    if (
      paywall.background_image_url_phone ||
      paywall.background_image_url_tablet
    ) {
      return {
        position: 'absolute',
        zIndex: 100,
        display,
        top: 0,
        left: 0,
        background: 'white',
        padding: 5,
        border: '1px solid lightgrey',
      };
    } else {
      return {
        position: 'absolute',
        zIndex: 100,
        display,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: 'white',
        padding: 10,
        border: '1px solid lightgrey',
      };
    }
  };

  const eyeDropperButton = {
    background: hover,
    color: invertColor(hover),
    marginBottom: 5,
    textTransform: 'uppercase',
    border: '1px solid lightgrey',
  };

  return (
    <Fragment>
      <Button.Group>
        <Button
          style={colorPicker}
          icon="tint"
          content={color}
          onClick={() => setToggle(!toggle)}
        />
        <Button icon="eye dropper" onClick={onClick} />
      </Button.Group>
      {toggle ? (
        <div style={popover}>
          <div style={cover} onClick={() => setToggle(false)} />
          <SketchPicker disableAlpha color={color} onChange={onChange} />
        </div>
      ) : null}
      <div style={eyeDropper()}>
        <Button style={eyeDropperButton} content={hover} />
        <Button
          icon="close"
          floated="right"
          onClick={() => setDisplay('none')}
        />
        {imagePresent() ? (
          <canvas
            id={id}
            width={300}
            height={paywall.background_image_url_phone ? 600 : 450}
          />
        ) : (
          <Message
            info
            content="Please upload an image to your paywall to use the eyedropper tool."
          />
        )}
      </div>
    </Fragment>
  );
};

export default ColorPickerLegacy;
