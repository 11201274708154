import React, { useMemo, useState } from 'react';

import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  MenuOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { uuid4 } from '@sentry/utils';
import { Button, Col, Form, List, Row } from 'antd';
import { ReactSortable } from 'react-sortablejs';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import { TIconName } from 'src/components/AntIcon';
import { truncateString } from 'src/utils/string';
import styled from 'styled-components';

import type {
  TContainer,
  TImageComponent,
  TSymbolComponent,
  TTextComponent,
} from '../../../../../../api/types/paywallTemplate.types';
import { useAppSelector } from '../../../../../../hooks/redux.hooks';
import { RootState } from '../../../../../../redux';
import { interpolate } from '../../../../../../utils/interpolation';
import type { FieldObject } from '../../../utils/formFieldBuilding';
import { buildMediaVariables } from '../../../utils/variables';
import PaywallBuilderDrawer from '../../PaywallBuilderDrawer';
import PaywallBuilderDrawerTitle from '../../PaywallBuilderDrawerTitle';
import SmartInput from '../SmartInput';
import FontSelect from './FontSelect';
import ImageButton from './ImageButton';
import TextInput from './TextInput';

type ComponentEditorProps = Omit<FieldObject, 'variable' | 'value'> & {
  defaultValue?: string | number;
  onChange: (value: TContainer) => void;
  label: string;
  controlled?: boolean;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
  value: TContainer;
};

const defaultFieldAttributes = {
  isSingle: false,
  description: null,
  editable: true,
  aspectRatio: null,
  carousel: null,
  darkModeVariable: null,
  hint: null,
  placeholder: null,
  markdownHint: false,
  options: null,
  maxLimit: null,
  minLimit: null,
} as const;

const AddComponentButton = styled(Button)`
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-self: center;

  div:has(> &) {
    display: flex;
    flex-direction: column;
  }
`;

const newImageBullet: Omit<TImageComponent, 'url'> = {
  component: 'image',
  alignment: 'left',
  imageCropping: 'fit',
  aspectRatio: 1,
  height: 24,
  width: 24,
  refId: 'listIcon',
};

const newImage: Omit<TImageComponent, 'url'> = {
  component: 'image',
  alignment: 'left',
  height: 32,
  aspectRatio: 1,
  imageCropping: 'fit',
  refId: 'listImage',
};

export default function ListContainerComponentEditor({
  name,
  value: containerProp,
  onChange,
  newRow,
  availableListComponents,
}: ComponentEditorProps) {
  const componentsToEdit = availableListComponents || [
    'listContent',
    'listIcon',
    'listImage',
  ];
  const mediaList = useAppSelector(
    (state: RootState) => state.paywallBuilder.mediaList
  );
  const container = useMemo(() => {
    const media = buildMediaVariables(mediaList, { convertToUrl: true });
    return interpolate(containerProp, { media });
  }, [containerProp, mediaList]);

  const [ids, setIds] = useState<string[]>(
    [...Array(container.components.length)].map(() => uuid4())
  );

  const rowContainerMap = useMemo(() => {
    return container.components.reduce(
      (output, item, i) => ({ ...output, [ids[i]]: item as TContainer }),
      {} as Record<string, TContainer>
    );
  }, [ids, container.components]);

  return (
    <Form.Item label={name}>
      <List bordered>
        <ReactSortable
          list={ids.map((id) => ({ id }))}
          style={{ height: '28vh', overflowY: 'scroll' }}
          setList={(newList) => {
            const isNotChanged =
              newList.length === ids.length &&
              newList.every((item, i) => item.id === ids[i]);
            if (isNotChanged) return;
            const newIds = [];
            const newComponents = [];
            for (const item of newList) {
              newIds.push(item.id);
              newComponents.push(rowContainerMap[item.id]);
            }
            setIds(newIds);
            onChange({ ...container, components: newComponents });
          }}
        >
          {Object.entries(rowContainerMap).map(([rowId, row]) => (
            <ListRowItem
              key={rowId}
              {...row}
              onChange={handleRowChange(rowId)}
              onDelete={() => handleRowDelete(rowId)}
              supportListTextContent={componentsToEdit.includes('listContent')}
              supportListImageContent={componentsToEdit.includes('listImage')}
              supportListImageIcon={componentsToEdit.includes('listIcon')}
              supportListNamiIcon={componentsToEdit.includes('listNamiIcon')}
            />
          ))}
        </ReactSortable>
      </List>
      <AddComponentButton
        icon={<PlusOutlined />}
        type="primary"
        block
        ghost
        onClick={() => {
          const components = [...container.components, newRow];
          setIds((prevState) => [...prevState, uuid4()]);
          onChange({ ...container, components });
        }}
      >
        Add List Item
      </AddComponentButton>
      {!!container.spacing && (
        <Row gutter={16}>
          <Col span={12}>
            <SmartInput
              name="Spacing"
              label="Spacing"
              type="number"
              controlled={true}
              value={container.spacing}
              onChange={(value: number) => handleWrapperSpacingChange(value)}
              {...defaultFieldAttributes}
            />
          </Col>
        </Row>
      )}
    </Form.Item>
  );

  function handleWrapperSpacingChange(value: any): void {
    const newContainer = {
      ...container,
      spacing: value,
    };
    onChange({ ...newContainer });
  }

  function handleRowChange(rowId: string) {
    return (newRow: TContainer): void => {
      const newComponents = Object.entries(rowContainerMap).reduce(
        (output, [currentId, currentRow]) => {
          const row = currentId === rowId ? newRow : currentRow;
          return [...output, row];
        },
        [] as TContainer[]
      );
      onChange({ ...container, components: newComponents });
    };
  }

  function handleRowDelete(rowId: string) {
    const components = container.components.filter(
      (component) => component !== rowContainerMap[rowId]
    );
    onChange({ ...container, components });
    setIds(ids.filter((id) => id !== rowId));
  }
}

type TComponentRef<T extends any> = {
  index: number;
  component: T | null;
};

const StyledListItem = styled(List.Item)`
  padding-left: 12px !important;
  padding-right: 10px !important;
  .ant-list-item-meta-title {
    font-size: 13px;
    font-weight: 500 !important;
  }
`;

function ListRowItem({
  onChange,
  onDelete,
  supportListImageContent,
  supportListImageIcon,
  supportListNamiIcon,
  supportListTextContent,
  ...container
}: TContainer & {
  onChange: (container: TContainer) => void;
  onDelete: () => void;
  supportListTextContent: boolean;
  supportListNamiIcon: boolean;
  supportListImageIcon: boolean;
  supportListImageContent: boolean;
}) {
  const [isEditing, setEditing] = useState(false);
  const { imageIcon, namiIcon, image, text } = useMemo(() => {
    let imageIcon: TComponentRef<TImageComponent> = {
      index: -1,
      component: null,
    };
    let namiIcon: TComponentRef<TSymbolComponent> = {
      index: -1,
      component: null,
    };
    let text: TComponentRef<TTextComponent> = { index: -1, component: null };
    let image: TComponentRef<TImageComponent> = { index: -1, component: null };
    container.components.forEach((component, index) => {
      if (component.refId === 'listIcon' && component.component === 'image')
        imageIcon = { index, component };
      if (
        component.refId === 'listNamiIcon' &&
        component.component === 'symbol'
      )
        namiIcon = { index, component };
      if (component.refId === 'listContent' && component.component === 'text')
        text = { index, component };
      if (component.refId === 'listImage' && component.component === 'image')
        image = { index, component };
    });
    return { imageIcon, namiIcon, image, text };
  }, [container.components]);

  const title = text.component?.text
    ? text.component.text
    : image.component?.url
    ? 'Image'
    : 'Empty';
  return (
    <StyledListItem>
      <List.Item.Meta avatar={<MenuOutlined />} title={<span>{title}</span>} />
      <IconActionButton
        type="text"
        size="small"
        icon={<EditOutlined style={{ fontSize: '13px' }} />}
        onClick={() => setEditing(true)}
      >
        Edit
      </IconActionButton>
      <PaywallBuilderDrawer
        title={
          <PaywallBuilderDrawerTitle>{`Editing "${truncateString(
            title,
            26
          )}"`}</PaywallBuilderDrawerTitle>
        }
        closeIcon={<CloseOutlined style={{ fontSize: 15 }} />}
        open={isEditing}
        onClose={() => setEditing(false)}
        className="verticalScrollDrawer"
      >
        <Form layout="vertical">
          {supportListImageIcon && (
            <Row gutter={16}>
              <Col span={24}>
                <ImageButton
                  name="Bullet Icon"
                  label="Bullet Icon"
                  type="image" // Required due to bad design
                  uploadBeforeChange
                  value={imageIcon.component?.url || null}
                  onChange={handleBulletImageChange}
                  {...{
                    ...defaultFieldAttributes,
                    aspectRatio: imageIcon.component?.aspectRatio || null,
                  }}
                />
              </Col>
            </Row>
          )}
          {supportListNamiIcon && (
            <>
              <Row gutter={16}>
                <Col span={12}>
                  <SmartInput
                    name="Icon"
                    label="Icon"
                    type="iconSelect"
                    value={namiIcon.component?.name}
                    onChange={(newIconValue: TIconName) =>
                      handleInputChange(namiIcon, 'name', newIconValue)
                    }
                    {...{
                      ...defaultFieldAttributes,
                    }}
                  />
                </Col>
                <Col span={12}>
                  <SmartInput
                    name="Icon Color"
                    label="Icon Color"
                    type="color"
                    controlled={true}
                    value={namiIcon.component?.fontColor || ''}
                    onChange={(value: string) =>
                      handleInputChange(namiIcon, 'fontColor', value)
                    }
                    {...defaultFieldAttributes}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <SmartInput
                    name="Icon Size"
                    label="Icon Size"
                    type="number"
                    controlled={true}
                    value={namiIcon.component?.fontSize || 0}
                    onChange={(value: number) =>
                      handleInputChange(namiIcon, 'fontSize', value)
                    }
                    {...defaultFieldAttributes}
                  />
                </Col>
              </Row>
            </>
          )}
          {supportListImageContent && (
            <>
              <ImageButton
                name="Image"
                label="Image"
                type="image" // Required due to bad design
                uploadBeforeChange
                value={image.component?.url || null}
                onChange={handleImageChange}
                {...{
                  ...defaultFieldAttributes,
                  description: 'Upload PNG artwork',
                  aspectRatio: image.component?.aspectRatio || null,
                }}
              />
              <Row gutter={16}>
                <Col span={12}>
                  <SmartInput
                    name="Image Height"
                    label="Image Height"
                    type="number"
                    controlled={true}
                    value={image.component?.height || 0}
                    onChange={(value: number) =>
                      handleInputChange(image, 'height', value)
                    }
                    {...defaultFieldAttributes}
                    minLimit={10}
                  />
                </Col>
              </Row>
            </>
          )}
          {supportListTextContent && (
            <>
              <TextInput
                name="Text"
                label="Text"
                type="text"
                controlled={true}
                value={text.component?.text || ''}
                onChange={(value: string) =>
                  handleInputChange(text, 'text', value)
                }
                {...defaultFieldAttributes}
              />
              <FontSelect
                name="Font Family"
                label="Font Family"
                type="fontSelect"
                controlled={true}
                value={text.component?.fontName || 'Helvetica'}
                onChange={(value) =>
                  handleInputChange(text, 'fontName', value || undefined)
                }
                {...defaultFieldAttributes}
              />
              <Row gutter={16}>
                <Col span={12}>
                  <SmartInput
                    name="Text Color"
                    label="Text Color"
                    type="color"
                    controlled={true}
                    value={text.component?.fontColor || ''}
                    onChange={(value: string) =>
                      handleInputChange(text, 'fontColor', value)
                    }
                    {...defaultFieldAttributes}
                  />
                </Col>
                <Col span={12}>
                  <SmartInput
                    name="Font Size"
                    label="Font Size"
                    type="number"
                    controlled={true}
                    value={text.component?.fontSize || 0}
                    onChange={(value: string) =>
                      handleInputChange(text, 'fontSize', value)
                    }
                    {...defaultFieldAttributes}
                  />
                </Col>
              </Row>
            </>
          )}
          <Button
            icon={<DeleteOutlined />}
            type="primary"
            danger
            ghost
            block
            onClick={onDelete}
          >
            Delete List Item
          </Button>
        </Form>
      </PaywallBuilderDrawer>
    </StyledListItem>
  );

  function handleInputChange(
    component: TComponentRef<
      TSymbolComponent | TTextComponent | TImageComponent
    >,
    field: string,
    value: any
  ): void {
    if (component.component === null || component.index === -1) return;
    const components = [...container.components];
    components[component.index] = {
      ...component.component,
      [field]: value,
    };
    onChange({ ...container, components });
  }

  function handleImageChange(url: string | null): void {
    if (url === null) {
      if (!image.component) return;
      const components = container.components.filter(
        (component) => component.refId !== 'listImage'
      );
      onChange({ ...container, components });
      return;
    }

    if (!image.component) {
      const components = [
        imageIcon.component,
        { ...newImage, url } as TImageComponent,
        text.component,
      ].filter(
        <T extends any>(component: T | null): component is T => !!component
      );
      onChange({ ...container, components });
      return;
    }

    const components = [...container.components];
    components[image.index] = { ...image.component, url };
    onChange({ ...container, components });
  }

  function handleBulletImageChange(url: string | null): void {
    if (url === null) {
      if (!imageIcon.component) return;
      const components = container.components.filter(
        (component) => component.refId !== 'listIcon'
      );
      onChange({ ...container, components });
      return;
    }

    if (!imageIcon.component) {
      const components = [
        imageIcon.component,
        { ...newImageBullet, url } as TImageComponent,
        text.component,
      ].filter(
        <T extends any>(component: T | null): component is T => !!component
      );
      onChange({ ...container, components });
      return;
    }

    const components = [...container.components];
    components[imageIcon.index] = { ...imageIcon.component, url };
    onChange({ ...container, components });
  }
}
