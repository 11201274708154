import { Drawer } from 'antd';
import styled from 'styled-components';

export default styled(Drawer).attrs({
  width: '324px',
  mask: false,
  placement: 'right',
  zIndex: 1001,
  bodyStyle: { padding: '4px 15px 24px 15px' },
  headerStyle: { padding: '10px 15px' },
})`
  margin-top: 56px;
  margin-left: 56px;

  .ant-drawer-close {
    margin-right: 0px !important;
  }

  .ant-drawer-content-wrapper {
    box-shadow: -8px 0 8px -8px rgba(0, 0, 0, 0.08);
  }
`;
