import React from 'react';

import { PlanMeta, TDisplayProduct } from 'src/api/types/main.types';

import UpgradeWebPaywall, { WebPaywallProps } from './UpgradeWebPaywall';

type UnlockTemplateWebPaywallProps = WebPaywallProps & {
  entitlement: string;
};

const UnlockTVTemplateWebPaywall = ({
  onCancel,
  visible,
  entitlement,
}: UnlockTemplateWebPaywallProps) => {
  const paywallsOnlyProducts: TDisplayProduct[] = [
    {
      product_value_props: [
        'Phone & Tablet Paywalls',
        'Carousel Paywalls',
        'Bring your own Creative',
      ],
      ...PlanMeta['paywalls_only_team_v1'],
    },
    {
      product_value_props: [
        'Phone & Tablet Paywalls',
        'Video Paywalls',
        'Paywall Personalization',
      ],
      ...PlanMeta['paywalls_only_business_v1'],
    },
    {
      product_value_props: ['TV Paywalls', 'Custom Designed Templates'],
      ...PlanMeta['paywalls_only_enterprise_v1'],
    },
  ];

  const paywallsAndSubsProducts: TDisplayProduct[] = [
    {
      product_value_props: [
        'Phone and Tablet Paywalls',
        'Carousel Paywalls',
        'Bring your own Creative',
      ],
      ...PlanMeta['paywalls_and_subs_team_v1'],
    },
    {
      product_value_props: [
        'Phone and Tablet Paywalls',
        'Video Paywalls',
        'Paywall Personalization',
      ],
      ...PlanMeta['paywalls_and_subs_business_v1'],
    },
    {
      product_value_props: ['TV Paywalls', 'Custom Designed Templates'],
      ...PlanMeta['paywalls_and_subs_enterprise_v1'],
    },
  ];

  return (
    <UpgradeWebPaywall
      modalTitle="Unlock TV Templates"
      productsPaywallsOnly={paywallsOnlyProducts}
      productsPaywallsAndSubs={paywallsAndSubsProducts}
      highlightedProductPaywallsOnly="paywalls_only_enterprise_v1"
      highlightedProductPaywallsAndSubs="paywalls_and_subs_enterprise_v1"
      entitlement={entitlement}
      onCancel={onCancel}
      visible={visible}
    />
  );
};

export default UnlockTVTemplateWebPaywall;
