import * as TYPES from '../types';

const initialState = {
  loading: true,
  changes: false,
  crmUsers: [],
  count: 0,
  totalPages: 1,
  crmUser: null,
  events: [],
  revenues: [],
};

export const crmReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_CRM_USERS:
    case TYPES.GET_CRM_USERS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_CRM_USERS_SUCCESS:
      return {
        ...state,
        crmUsers: action.payload.data.results,
        count: action.payload.data.count,
        totalPages: action.payload.data.total_pages,
        loading: false,
      };
    case TYPES.GET_CRM_USERS_FAIL:
      return {
        ...state,
        crmUsers: [],
        count: null,
        pageNumber: 1,
        totalPages: null,
        loading: false,
      };
    case TYPES.GET_CRM_USER:
    case TYPES.GET_CRM_USER_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_CRM_USER_SUCCESS:
      return {
        ...state,
        crmUser: action.payload.data,
        loading: false,
      };
    case TYPES.GET_CRM_USER_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_CRM_USER_EVENTS:
    case TYPES.GET_CRM_USER_EVENTS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_CRM_USER_EVENTS_SUCCESS:
      return {
        ...state,
        events: action.payload.data.results,
        count: action.payload.data.count,
        totalPages: action.payload.data.total_pages,
        loading: false,
      };
    case TYPES.GET_CRM_USER_EVENTS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_CRM_USER_REVENUES:
    case TYPES.GET_CRM_USER_REVENUES_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_CRM_USER_REVENUES_SUCCESS:
      return {
        ...state,
        revenues: action.payload.data.results,
        count: action.payload.data.count,
        totalPages: action.payload.data.total_pages,
        loading: false,
      };
    case TYPES.GET_CRM_USER_REVENUES_FAIL:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
