import React from 'react';

import { connect } from 'react-redux';
import { Checkbox, Segment } from 'semantic-ui-react';

import { useAppContext } from '../../../../hooks';
import { handlePaywallField } from '../../../../redux/actions/paywallActions';

const Controls = ({ paywall, handlePaywallField }) => {
  const context = useAppContext();

  const toggleControl = (value) => {
    handlePaywallField({ ...paywall, [value]: !paywall[value] });
  };

  const renderForm = () => {
    return (
      <>
        <Checkbox
          slider
          onChange={() => toggleControl('sign_in_control')}
          disabled={!context.userHasEntitlement('app.paywall.update')}
          checked={paywall.sign_in_control}
          label="Sign In"
        />
        <p style={{ marginTop: '1rem' }}>
          This paywall allows end users to sign-in to access paid functionality.
        </p>
        <Checkbox
          slider
          onChange={() => toggleControl('restore_control')}
          disabled={!context.userHasEntitlement('app.paywall.update')}
          checked={paywall.restore_control}
          label="Restore"
        />
        <p style={{ marginTop: '1rem' }}>
          This paywall allows end users to restore a previous purchase.
        </p>

        <Checkbox
          slider
          onChange={() => toggleControl('allow_closing')}
          disabled={!context.userHasEntitlement('app.paywall.update')}
          checked={!paywall.allow_closing}
          label="Blocking"
        />
        <div style={{ marginTop: '1rem' }}>
          <p>
            This paywall will block the user from accessing content or features.
          </p>
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://docs.namiml.com/v1.0/docs/blocking-paywalls"
          >
            More information
          </a>
        </div>
      </>
    );
  };

  return <Segment basic>{renderForm()}</Segment>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlePaywallField: (newData) => dispatch(handlePaywallField(newData)),
  };
};

const mapStateToProps = ({ paywall }) => {
  return {
    paywall: paywall.paywall,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
