import { Area } from 'react-easy-crop/types';

type ImageObject = {
  file: Blob;
  image: string;
  data: string;
};

export function loadImage(url: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
    // eslint-disable-next-line no-restricted-globals
    image.crossOrigin = location.host;
    image.src = url;
  });
}
/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function cropImage(
  imageSrc: string,
  pixelCrop: Area,
  rotation: number = 0
): Promise<ImageObject> {
  const image = await loadImage(imageSrc);
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d')!;

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  context.translate(safeArea / 2, safeArea / 2);
  context.rotate((rotation * Math.PI) / 180);
  context.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  const dx = safeArea / 2 - image.width * 0.5;
  const dy = safeArea / 2 - image.height * 0.5;
  context.drawImage(image, dx, dy);

  const data = context.getImageData(0, 0, safeArea, safeArea);
  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  context.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve({
        file: file!,
        image: canvas.toDataURL('image/jpeg'),
        data: URL.createObjectURL(file!),
      });
    }, 'image/jpeg');
  });
}
