import React from 'react';

import { ReadOutlined } from '@ant-design/icons';

import {
  useActions,
  useAppSelector,
} from '../../../../../../hooks/redux.hooks';
import PaywallBuilderSlice from '../../../../../../redux/PaywallBuilderSlice';
import { DisplayFieldSwitch } from './DisplayFieldSwitch';
import { UpgradeButton } from './FontSelect';

export default function FullscreenToggle() {
  const actions = useActions(PaywallBuilderSlice.actions);
  const fullscreenFlag = useAppSelector(
    (root) =>
      root.paywallBuilder.paywall?.template.initialState?.fullScreenPresentation
  );

  const key = 'state.fullScreenPresentation';

  const tooltipTitle: React.ReactNode = (
    <>
      <span>
        This setting only applies to iOS devices. Android devices are always in
        fullscreen mode.
      </span>
      <UpgradeButton
        size="small"
        type="link"
        href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-templates#fullscreen-mode"
        target="_blank"
        icon={<ReadOutlined />}
      >
        Learn More.
      </UpgradeButton>
    </>
  );

  return (
    <DisplayFieldSwitch
      label="Fullscreen Presentation"
      hint="Display paywall in fullscreen view instead of sheet"
      tooltip={tooltipTitle}
      checked={!!fullscreenFlag}
      onChange={(value) => actions.setTemplateVariable({ key, value })}
    />
  );
}
