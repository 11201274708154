import React from 'react';

import { PlanMeta, TDisplayProduct } from 'src/api/types/main.types';

import UpgradeWebPaywall, { WebPaywallProps } from './UpgradeWebPaywall';

const CSVDownloadWebPaywall = ({ onCancel, visible }: WebPaywallProps) => {
  const paywallsOnlyProducts: TDisplayProduct[] = [
    {
      product_value_props: [
        'CSV download of metrics',
        'Trial Charts',
        'Filter by product, trial length, and more',
      ],
      ...PlanMeta['paywalls_only_team_v1'],
    },
    {
      product_value_props: ['Advanced filters', 'ARPU Charts'],
      ...PlanMeta['paywalls_only_business_v1'],
    },
  ];

  const paywallsAndSubsProducts: TDisplayProduct[] = [
    {
      product_value_props: [
        'CSV download of metrics',
        'Subscriber charts',
        'Filter by product, trial length, and more',
      ],
      ...PlanMeta['paywalls_and_subs_team_v1'],
    },
    {
      product_value_props: ['Advanced filters', 'ARPU charts'],
      ...PlanMeta['paywalls_and_subs_business_v1'],
    },
  ];

  return (
    <UpgradeWebPaywall
      modalTitle="Unlock CSV Download"
      productsPaywallsOnly={paywallsOnlyProducts}
      productsPaywallsAndSubs={paywallsAndSubsProducts}
      highlightedProductPaywallsOnly="paywalls_only_team_v1"
      highlightedProductPaywallsAndSubs="paywalls_and_subs_team_v1"
      entitlement="app.analytics.export.csv_download"
      onCancel={onCancel}
      visible={visible}
    />
  );
};

export default CSVDownloadWebPaywall;
