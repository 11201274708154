import { toast } from 'react-toastify';

import * as TYPES from '../types';

const initialState = {
  loading: true,
  submit: false,
  appDetails: null,
  updateAppErrors: null,
  newAppDetails: {},
  newAppErrors: null,
  changes: false,
  status: null,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_APP:
    case TYPES.GET_APP_LOAD:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_APP_SUCCESS:
      return {
        ...state,
        appDetails: action.payload.data,
        loading: false,
      };

    case TYPES.GET_APP_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.UPDATE_APP:
    case TYPES.UPDATE_APP_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.UPDATE_APP_SUCCESS:
      toast.success('Successfully updated app.');
      return {
        ...state,
        updateAppErrors: null,
        loading: false,
        changes: false,
      };
    case TYPES.UPDATE_APP_FAIL:
      return {
        ...state,
        updateAppErrors: action.error.response.data,
        loading: false,
      };

    case TYPES.ADD_APP:
    case TYPES.ADD_APP_LOAD:
      return {
        ...state,
        loading: true,
        submit: true,
      };

    case TYPES.ADD_APP_SUCCESS:
      toast.success('Added app successfully.');
      return {
        ...state,
        newAppErrors: null,
        loading: false,
        submit: false,
      };

    case TYPES.ADD_APP_FAIL:
      return {
        ...state,
        newAppErrors: action.error.response.data,
        loading: false,
        submit: false,
      };

    case TYPES.DESTROY_APP:
    case TYPES.DESTROY_APP_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.DESTROY_APP_SUCCESS:
      return {
        ...state,
        loading: false,
        changes: false,
      };
    case TYPES.DESTROY_APP_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_APP_STATUS:
    case TYPES.GET_APP_STATUS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_APP_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload.data,
        loading: false,
      };

    case TYPES.GET_APP_STATUS_FAIL:
      return {
        ...state,
        status: null,
        loading: false,
      };

    case TYPES.FINISH_LOGO_UPLOAD:
      return {
        ...state,
        loading: false,
        appDetails: {
          ...state.appDetails,
          logo: action.payload.response,
        },
        changes: true,
      };
    case TYPES.HANDLE_APP_DETAIL_FIELD:
      return {
        ...state,
        appDetails: {
          ...state.appDetails,
          [action.payload.key]: action.payload.value,
        },
        changes: true,
      };

    case TYPES.HANDLE_NEW_APP_FIELD:
      return {
        ...state,
        newAppDetails: {
          ...state.newAppDetails,
          [action.payload.key]: action.payload.value,
        },
      };

    case TYPES.RESET_UPDATE_APP_ERRORS:
      return {
        ...state,
        updateAppErrors: null,
      };

    case TYPES.RESET_NEW_APP_ERRORS:
      return {
        ...state,
        newAppErrors: null,
      };

    case TYPES.RESET_NEW_APP:
      return {
        ...state,
        newAppDetails: {},
      };

    default:
      return state;
  }
};
