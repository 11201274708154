import React from 'react';

import { Image, Space, Table, Tooltip } from 'antd';
import { ColumnGroupType, ColumnType } from 'antd/es/table';
import Moment from 'moment';
import {
  DataKey,
  DataKeyFormat,
  DataKeys,
  MetricDataType,
  SegmentData,
  SegmentOptions,
  TData,
  TDataSerie,
} from 'src/api/types/analytics.types';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { platformLogo } from 'src/services/helpers';
import styled from 'styled-components';

import {
  getLabel,
  PLATFORMS,
  returnDateFormat,
  translateCampaignIdToCampaign,
  translateCampaignLabelToPlacement,
  translatePaywallIdToPaywall,
  translateProductIDToProduct,
} from '../../../utils/formatting';
import SegmentedInsightsTable from './SegmentedInsightsTable';

type InsightsTableProps<Data> = {
  data: Data[];
  chartKeys: DataKey[];
  tableKeys: DataKey[];
  loading: boolean;
};

const StyledTable = styled(Table)`
  th.ant-table-cell:not(:first-child) {
    min-width: fit-content;
    white-space: nowrap;
  }

  td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
    font-size: 0.9em;
    line-height: 1.25;
  }
`;

const StyledColumnLink = styled.a`
  font-weight: bolder;
  cursor: pointer;
  :hover {
    text-decoration: underline !important;
  }
`;

const NoDataContainer = styled.span.attrs({ children: 'No Data' })`
  display: flex;
  width: 100%;
  background-color: white;
  padding-block: 35px;
  justify-content: center;
  align-items: center;
`;

export default function InsightsTable<Data extends TData>({
  loading,
  data,
  chartKeys,
  tableKeys,
}: InsightsTableProps<Data>) {
  let columns: ColumnGroupType<object> | ColumnType<object>[] = [];
  const { interval, segment, insightType } = useAppSelector(({ analytic }) => {
    const { interval, segment, insightType } = analytic;
    return { interval, segment, insightType };
  });

  if (data.length) {
    const dateFormat = returnDateFormat(interval);
    columns = [
      { dataIndex: 'label', key: 'label', fixed: 'left' },
      ...data[0].series.map(({ x }) => {
        const title = <strong>{Moment(x).format(dateFormat)}</strong>;
        return { title, dataIndex: x, key: x };
      }),
    ];
  }

  const keyReference = segment ? chartKeys : tableKeys;
  const dataSource = parseTableData(
    data,
    keyReference,
    segment ? SegmentOptions[segment] : null
  );
  //TODO - avoid parsing table data twice for paywalls-conversion-rate
  const nestedDataSource =
    insightType === 'paywalls-conversion-rate' && segment
      ? parseTableData(
          data,
          tableKeys,
          segment ? SegmentOptions[segment] : null
        )
      : null;

  return (
    <>
      {!!data.length ? (
        segment ? (
          <SegmentedInsightsTable
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            nestedDataSource={
              nestedDataSource?.length && nestedDataSource.length > 1
                ? nestedDataSource.reverse()
                : null
            }
          />
        ) : (
          <StyledTable
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            scroll={{ x: true }}
            bordered
          />
        )
      ) : (
        <NoDataContainer />
      )}
    </>
  );
}

function formatTableCell(format: MetricDataType, serie: TDataSerie): string {
  if (format === 'CURRENCY')
    return serie.value.toLocaleString('en-us', {
      style: 'currency',
      currency: 'USD',
    });
  if (format === 'PERCENT') {
    const serieNum = Number(serie.value) * 100;
    return `${parseFloat(serieNum.toFixed(2))}%`;
  }
  return serie.value.toLocaleString('en-us');
}

export function parseTableData(
  data: TData[],
  keyReference: DataKey[],
  segment: SegmentData | null
): { key: string; label: any; name: string }[] {
  //TODO - typing
  const result = [];
  for (const row of data) {
    const keyParts = row.key.split(',');
    const key = segment && keyParts.length > 1 ? keyParts[1] : keyParts[0];
    if (!keyReference.includes(key as DataKey)) continue;
    const keyMeta = DataKeys[key as DataKey];
    const text = getLabel(keyMeta, row, segment);
    result.push(
      row.series.reduce(
        (output, item) => {
          const value = formatTableCell(keyMeta.format, item);
          return { ...output, [item.x]: value };
        },
        {
          key: row.key,
          label: segment?.tableDisplayTransform
            ? segment.tableDisplayTransform(keyMeta, row, segment)
            : simpleFormatForDisplay(text),
          name: text,
        }
      )
    );
  }
  return result;
}

export function formatSegmentPlatformForDisplay(
  preferredFormat: DataKeyFormat,
  dataObject: TData,
  segment: SegmentData | null
): JSX.Element {
  const titleParts = dataObject.title.split(',');
  if (segment && titleParts.length === 1)
    return simpleFormatForDisplay('Unknown');
  if (!segment) return simpleFormatForDisplay(preferredFormat.display_name);
  return (
    <Space>
      <Image
        height={13}
        width={13}
        preview={false}
        src={platformLogo(titleParts[0])}
      />
      <strong>{PLATFORMS[titleParts[0] as keyof typeof PLATFORMS]}</strong>
    </Space>
  );
}

export function formatProductForDisplay(
  preferredFormat: DataKeyFormat,
  dataObject: TData,
  segment: SegmentData | null
): JSX.Element {
  const titleParts = dataObject.title.split(',');
  if (segment && titleParts.length === 1)
    return simpleFormatForDisplay('Unknown');
  if (!segment) return simpleFormatForDisplay(preferredFormat.display_name);
  const product = translateProductIDToProduct(titleParts[0]);
  if (product) {
    return (
      <Tooltip
        title={`${product.platform_name} - ${product.sku_ref_id}`}
        align={{ offset: [0, 5] }}
      >
        <Space style={{ cursor: 'pointer' }}>
          <Image
            height={13}
            width={13}
            preview={false}
            src={platformLogo(product.platform_type)}
          />
          <StyledColumnLink href={`/products/${product.id}/`}>
            {product.name}
          </StyledColumnLink>
        </Space>
      </Tooltip>
    );
  }
  return <strong>Unknown</strong>;
}

export function formatCampaignLabelForDisplay(
  preferredFormat: DataKeyFormat,
  dataObject: TData,
  segment: SegmentData | null
): JSX.Element {
  const titleParts = dataObject.title.split(',');
  if (segment && titleParts.length === 1)
    return simpleFormatForDisplay('Unknown');
  if (!segment) return simpleFormatForDisplay(preferredFormat.display_name);
  const placement = translateCampaignLabelToPlacement(titleParts[0]);
  if (placement) {
    return (
      <Tooltip
        title={`${placement.description || ''}`}
        align={{ offset: [0, 5] }}
      >
        <StyledColumnLink href={`/campaigns/?label_id=${placement.id}&status=`}>
          {placement.value}
        </StyledColumnLink>
      </Tooltip>
    );
  }
  return <strong>Unknown</strong>;
}

export function formatCampaignRuleForDisplay(
  preferredFormat: DataKeyFormat,
  dataObject: TData,
  segment: SegmentData | null
): JSX.Element {
  const titleParts = dataObject.title.split(',');
  if (segment && titleParts.length === 1)
    return simpleFormatForDisplay('Unknown');
  if (!segment) return simpleFormatForDisplay(preferredFormat.display_name);
  const campaign = translateCampaignIdToCampaign(titleParts[0]);
  if (campaign) {
    return (
      <StyledColumnLink href={`/campaigns/${campaign.id}/`}>
        {campaign.name}
      </StyledColumnLink>
    );
  }
  return <strong>Unknown</strong>;
}

export function formatPaywallForDisplay(
  preferredFormat: DataKeyFormat,
  dataObject: TData,
  segment: SegmentData | null
): JSX.Element {
  const titleParts = dataObject.title.split(',');
  if (segment && titleParts.length === 1)
    return simpleFormatForDisplay('Unknown');
  if (!segment) return simpleFormatForDisplay(preferredFormat.display_name);
  const paywall = translatePaywallIdToPaywall(titleParts[0]);
  if (paywall) {
    return (
      <Tooltip title={`${paywall.description}`} align={{ offset: [0, 5] }}>
        <StyledColumnLink href={`/paywalls/${paywall.id}/`}>
          {paywall.name}
        </StyledColumnLink>
      </Tooltip>
    );
  }
  return <strong>Unknown</strong>;
}

export function simpleFormatForDisplay(value: string): JSX.Element {
  return <strong>{value}</strong>;
}
