import React, { useEffect, useState } from 'react';

import { Select } from 'antd';
import { Modal, Form, Input, Typography, notification } from 'antd';

import api from '../../../../api';
import FormItem from '../../../../components/FormItem';
import PlanUpgradeButton from '../../../../components/PlanUpgradeButton/PlanUpgradeButton';
import { useAppContext, usePromiseState } from '../../../../hooks';
import { extractFieldError } from '../../../../services/utilsService';

const InviteMemberModal = ({ isOpen, onClose, onUpgradeClick }) => {
  const context = useAppContext();
  const [form] = Form.useForm();
  const [roles, setRoles] = useState([]);

  const sentOrgMemberInviteCall = usePromiseState(() => {
    const { email, role } = form.getFieldsValue();
    return api.sentOrgMemberInvite(email, [role]).then(() => {
      closeModal();
      notification.success({
        message: 'Success',
        description: 'Successfully invited user.',
      });
      form.resetFields();
    });
  });

  useEffect(() => {
    if (context.userHasEntitlement('org.role.list')) {
      api.getOrgRoles().then(setRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      title={
        <Typography.Text onClick={onClose}>
          Invite Team Member
          {!context.planHasEntitlement('org.role.granular_permissions') && (
            <PlanUpgradeButton onClick={onUpgradeClick}>
              Advanced Roles
            </PlanUpgradeButton>
          )}
        </Typography.Text>
      }
      closable={false}
      open={isOpen}
      onOk={form.submit}
      okText="Invite"
      onCancel={closeModal}
      centered
      confirmLoading={sentOrgMemberInviteCall.isLoading}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={sentOrgMemberInviteCall.trigger}
        initialValues={{
          email: '',
          role: roles.length ? roles[0].id : '',
        }}
      >
        <FormItem
          label="Email"
          name="email"
          error={extractFieldError(sentOrgMemberInviteCall?.error?.email)}
        >
          <Input placeholder="Email" />
        </FormItem>
        <FormItem
          label="Role"
          name="role"
          error={extractFieldError(sentOrgMemberInviteCall?.error?.role)}
        >
          <Select
            style={{ width: '100%' }}
            status={sentOrgMemberInviteCall?.error?.role ? 'error' : ''}
            disabled={
              !context.planHasEntitlement('org.role.granular_permissions')
            }
          >
            {!!roles.length &&
              roles.map((role) => (
                <Select.Option key={role.id} value={role.id}>
                  {role.display_name}
                </Select.Option>
              ))}
          </Select>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default InviteMemberModal;
