import React from 'react';

import { Card, Col, Row } from 'antd';
import { TOrgCDPIntegrationProvider } from 'src/api/types/main.types';
import {
  getCDPProviderLogo,
  getCDPProviderSubtitle,
} from 'src/pages/admin/settings/org-integrations/OrgIntegrationsPage';
import { namiDarkGray, namiPrimaryBlue, namiPureWhite } from 'src/variables';
import styled, { css } from 'styled-components';

type IntegrationCardProps = {
  provider_type: TOrgCDPIntegrationProvider;
  title: string;
  overflow?: boolean;
  onClick?: () => void;
  loading?: boolean;
};

const StyledCard = styled(Card)<{ hoverable?: boolean }>`
  min-height: 314px !important;
  border: 2px solid ${namiPureWhite} !important;
  padding: 8px !important;

  ${({ hoverable }) => {
    if (hoverable) {
      return css`
        cursor: pointer;
        &:hover {
          border: 2px solid ${namiPrimaryBlue} !important;
        }
      `;
    }
    return css``;
  }}
`;

const CardTitle = styled.div`
  font-weight: 700;
  font-size: 1.25em;
`;
const CardSubtitle = styled.div<{ $overflow?: boolean }>`
  margin-top: 0.5em;
  clear: both;
  color: ${namiDarkGray};
  font-size: 18px;
  ${({ $overflow }) => {
    if ($overflow) {
      return css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `;
    }
    return css``;
  }};
`;

export default function OrgCDPIntegrationCard({
  provider_type,
  title,
  overflow = true,
  onClick,
  loading,
}: IntegrationCardProps) {
  const subtitle = getCDPProviderSubtitle(provider_type);
  const logo = getCDPProviderLogo(provider_type, 70, 64);

  return (
    <StyledCard
      onClick={onClick}
      className={provider_type}
      hoverable={!!onClick}
      loading={loading}
    >
      <Row justify="center">
        <Col style={{ marginTop: 20, marginBottom: 60 }}>{logo}</Col>
      </Row>
      <CardTitle>{title}</CardTitle>
      <CardSubtitle $overflow={overflow}>{subtitle}</CardSubtitle>
    </StyledCard>
  );
}
