import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

import { TCheckboxContainer } from '../../../api/types/paywallTemplate.types';
import { backgroundColor, transition, widthAndHeight } from '../css';

const CheckboxContainer = styled.div<{
  component: TCheckboxContainer;
}>`
  display: flex;
  ${({ component }) => {
    return css`
      ${applyStyles(component)}
    `;
  }}
`;

function applyStyles(
  component: TCheckboxContainer
): FlattenSimpleInterpolation {
  const isActive = component.active || false;
  const fillColor = isActive
    ? component.activeFillColor || 'transparent'
    : component.inactiveFillColor || 'transparent';
  const borderColor = isActive
    ? component.activeBorderColor || 'transparent'
    : component.inactiveBorderColor || 'transparent';
  const borderRadius = isActive
    ? component.activeBorderRadius || 0
    : component.inactiveBorderRadius || 0;
  return css`
    ${transition()}
    ${widthAndHeight(component)};
    ${backgroundColor(fillColor)};
    border-color: ${borderColor} !important;
    border-width: ${isActive
      ? component.activeBorderWidth || 0
      : component.inactiveBorderWidth || 0}px !important;
    border-style: solid;
    border-radius: ${borderRadius}px;
  `;
}

export default CheckboxContainer;
