import { Col } from 'antd';
import PaywallBuilderFrame from 'src/images/marketing/paywallBuilderFrame.png';
import { namiCoral } from 'src/variables';
import styled from 'styled-components';

const HeroColumn = styled(Col)`
  background-image: url(${PaywallBuilderFrame});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${namiCoral};
  height: 100vh;
  width: 100%;
`;

export default HeroColumn;
