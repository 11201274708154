import React from 'react';

import { AimOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

import type { RootState } from '../../../../redux';

export default function PaywallFocusedStatePicker() {
  const inFocusedState = useAppSelector(
    ({ paywallBuilder: { focusedState } }: RootState) => focusedState
  );
  const actions = useActions(PaywallBuilderSlice.actions);

  return (
    <>
      <Tooltip
        title="Preview the way paywall elements look when selected by a remote"
        mouseEnterDelay={0.8}
      >
        <Button
          type={inFocusedState ? 'primary' : 'ghost'}
          icon={<AimOutlined />}
          size="small"
          onClick={toggleFocusedState}
        />
      </Tooltip>
    </>
  );

  function toggleFocusedState() {
    actions.setFocusedState(!inFocusedState);
  }
}
