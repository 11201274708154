export function flatten<T>(
  array: Array<T | T[]>,
  unique: boolean = false
): T[] {
  const output = array.reduce((output: T[], item: T | T[]) => {
    return output.concat(Array.isArray(item) ? flatten(item) : [item]);
  }, []);
  return unique ? Array.from(new Set(output)) : output;
}

export function removeByIndex<T>(array: T[], index: number): T[] {
  return [...array.slice(0, index), ...array.slice(index + 1)];
}

export function isTruthy<T>(value: T | null | undefined): value is T {
  return !!value;
}

export function moveInArray<T>(array: T[], from: number, to: number): T[] {
  let item = array.splice(from, 1);
  array.splice(to, 0, item[0]);
  return array;
}

export function addToArray<T>(array: T[], to: number, newItem: T): T[] {
  return array.splice(to, 0, newItem);
}

export function arraysAreEqual<T>(a: T[], b: T[]): boolean {
  return (
    a.length === b.length && a.every((element, index) => element === b[index])
  );
}
