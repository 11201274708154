import Token from '../services/token';
import { bareClient, preAuthClient } from './clients';
import {
  AuthPayloadType,
  NewAccountPayloadType,
  PreAuthPayloadType,
  SessionDefaultsType,
  TOnboardingPayload,
} from './types/main.types';
import { extractErrorData, extractResponseData } from './utils';

let refreshPromise: Promise<string> | null = null;
let logoutPromise: Promise<void> | null = null;

const auth = {
  signUp: (data: NewAccountPayloadType): Promise<void> => {
    return bareClient
      .post('/private/account/create/', data)
      .catch(extractErrorData) as unknown as Promise<void>;
  },
  finishOnboarding: (
    token: string,
    data: TOnboardingPayload
  ): Promise<AuthPayloadType> => {
    return bareClient
      .post(`/private/account/finish-onboarding/${token}/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  login: (
    email: string,
    password: string
  ): Promise<AuthPayloadType | PreAuthPayloadType> => {
    return bareClient
      .post('/private/account/login/', { username: email, password })
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  confirmMFA: (type: string, code: string): Promise<AuthPayloadType> => {
    return preAuthClient
      .post('/private/account/login/confirm_mfa/', { code, type })
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  logout: (): Promise<void> => {
    if (logoutPromise) return logoutPromise;
    const token = Token.getAccess();
    Token.removeAll();
    const config = { headers: { Authorization: `Bearer ${token}` } };
    logoutPromise = bareClient
      .post('/private/account/logout/', null, config)
      .finally(() => {
        logoutPromise = null;
      }) as unknown as Promise<void>; // The output doesn't matter
    return logoutPromise;
  },
  verifyEmail: (token: string): Promise<AuthPayloadType> => {
    return bareClient
      .post('/private/account/create/email-confirm/', { token })
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  confirmEmail: (token: string): Promise<void> => {
    return bareClient
      .post('/private/account/email-change-confirm/', { token })
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getSessionDefaults: (): Promise<SessionDefaultsType> => {
    return bareClient
      .get('/api/v1/session_defaults/')
      .then(extractResponseData);
  },
  generateToken: (orgId: string | null): Promise<string> => {
    if (refreshPromise) return refreshPromise;

    const data = {
      refresh_token: Token.getRefresh().toString(),
      selected_org: orgId,
    };
    refreshPromise = bareClient
      .post('/private/account/token/refresh/', data)
      .then(({ data: { access_token: accessToken } }) => {
        Token.setAccess(accessToken);
        return accessToken;
      })
      .catch((e) => {
        console.log(e);
        Token.removeAll();
        return null;
      })
      .finally(() => {
        refreshPromise = null;
      });

    return refreshPromise;
  },
};

export default auth;
