import React from 'react';

import cx from 'classnames';

import { ReactComponent as ActiveSubscribersIcon } from '../../images/icons/active_subscribers.svg';
import { ReactComponent as AddIcon } from '../../images/icons/add.svg';
import { ReactComponent as AppSettingsIcon } from '../../images/icons/app-settings.svg';
import { ReactComponent as BarsIcon } from '../../images/icons/bars.svg';
import { ReactComponent as CampaignDiscountIcon } from '../../images/icons/campaign-discount.svg';
import { ReactComponent as CampaignStandardIcon } from '../../images/icons/campaign-standard.svg';
import { ReactComponent as CampaignIcon } from '../../images/icons/campaigns.svg';
import { ReactComponent as ConversionRateIcon } from '../../images/icons/conversion-rate.svg';
import { ReactComponent as CrmIcon } from '../../images/icons/crm.svg';
import { ReactComponent as EntitlementIcon } from '../../images/icons/entitlement.svg';
import { ReactComponent as HelpIcon } from '../../images/icons/help.svg';
import { ReactComponent as InsightsIcon } from '../../images/icons/insights.svg';
import { ReactComponent as IntegrationIcon } from '../../images/icons/integration.svg';
import { ReactComponent as KeyIcon } from '../../images/icons/key.svg';
import { ReactComponent as LanguagesIcon } from '../../images/icons/languages.svg';
import { ReactComponent as LegacyIcon } from '../../images/icons/legacy.svg';
import { ReactComponent as LogoIcon } from '../../images/icons/logo.svg';
import { ReactComponent as LogoutIcon } from '../../images/icons/logout.svg';
import { ReactComponent as NamiSymbolIcon } from '../../images/icons/nami-symbol.svg';
import { ReactComponent as OverviewIcon } from '../../images/icons/overview.svg';
import { ReactComponent as PaywallLinkIcon } from '../../images/icons/paywall-link.svg';
import { ReactComponent as PaywallNamiIcon } from '../../images/icons/paywall-nami.svg';
import { ReactComponent as PaywallIcon } from '../../images/icons/paywalls.svg';
import { ReactComponent as PlacementIcon } from '../../images/icons/pin.svg';
import { ReactComponent as PlatformIcon } from '../../images/icons/platform.svg';
import { ReactComponent as ProductsIcon } from '../../images/icons/products.svg';
import { ReactComponent as RevenueIcon } from '../../images/icons/revenue.svg';
import { ReactComponent as SDKIcon } from '../../images/icons/sdk.svg';
import { ReactComponent as SettingsIcon } from '../../images/icons/settings.svg';
import styles from './NamiIcon.module.less';

type NamiIconProps = {
  icon: string;
  size?: keyof typeof styles;
  color?: keyof typeof styles;
  style?: React.CSSProperties;
  className?: string;
};

export const ICON_MAP = {
  logo: LogoIcon,
  'active subscribers': ActiveSubscribersIcon,
  'conversion rate': ConversionRateIcon,
  revenue: RevenueIcon,
  'nami symbol': NamiSymbolIcon,
  overview: OverviewIcon,
  paywall: PaywallIcon,
  'paywall nami': PaywallNamiIcon,
  'paywall link': PaywallLinkIcon,
  products: ProductsIcon,
  entitlement: EntitlementIcon,
  campaign: CampaignIcon,
  'campaign standard': CampaignStandardIcon,
  'campaign discount': CampaignDiscountIcon,
  'app settings': AppSettingsIcon,
  settings: SettingsIcon,
  platform: PlatformIcon,
  logout: LogoutIcon,
  help: HelpIcon,
  sdk: SDKIcon,
  key: KeyIcon,
  bars: BarsIcon,
  insights: InsightsIcon,
  crm: CrmIcon,
  integration: IntegrationIcon,
  languages: LanguagesIcon,
  add: AddIcon,
  placement: PlacementIcon,
  legacy: LegacyIcon,
};

export default function NamiIcon({
  icon,
  size = 'small',
  color = 'default',
  className,
  style,
}: NamiIconProps) {
  if (!(icon in ICON_MAP)) return null;
  const Component = ICON_MAP[icon as keyof typeof ICON_MAP];
  return (
    <Component
      className={cx(styles[size], styles[color], className)}
      style={style}
    />
  );
}
