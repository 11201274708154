import React from 'react';

import FilterSelect, { BaseFilterSelectProps } from './FilterSelect';
import { identifierLabel } from './mapsAndOptions';

export default function CDPIdSelect(props: BaseFilterSelectProps) {
  return (
    <FilterSelect
      {...props}
      label={identifierLabel['Device.customer_data_platform_id']}
      operators={[
        'equals',
        'not_equals',
        'contains',
        'not_contains',
        'starts_with',
        'not_starts_with',
      ]}
      type="string"
      variant="manual_value"
    />
  );
}
