import React from 'react';

import { Button, Form, Header, Segment } from 'semantic-ui-react';

import api from '../../../api';
import NamiAgreementLinks from '../../../components/NamiAgreementLinks/NamiAgreementLinks';
import NamiIcon from '../../../components/NamiIcon/NamiIcon';
import Page from '../../../components/Page/Page';
import PasswordValidator from '../../../components/PasswordValidator/PasswordValidator';
import Wrapper from '../../../components/Wrapper/Wrapper';
import { useAppContext, useFormState, usePromiseState } from '../../../hooks';
import SegmentClient from '../../../segment';
import { extractFieldError } from '../../../services/utilsService';
import { disabled } from '../../../services/validatorsService';

const AcceptInvitePage = ({ match }) => {
  const context = useAppContext();
  const { form, setFormInput } = useFormState({
    first_name: '',
    last_name: '',
    password: '',
  });
  const {
    isLoading,
    error,
    trigger: signUp,
  } = usePromiseState(() =>
    api.acceptInviteSignup(match.params.inviteID, form).then((payload) => {
      context.authorizeAccess(payload);
      SegmentClient.trackRegistration();
    })
  );

  const { password, first_name: firstName, last_name: lastName } = form;

  return (
    <Page title="Accept Invite">
      <Wrapper>
        <Segment basic textAlign="center">
          <NamiIcon icon="logo" color="primary" size="massive" />
        </Segment>
        <Segment>
          <Form size="large" onSubmit={signUp}>
            <Header textAlign="center">Welcome to Nami</Header>
            <Header textAlign="center" style={{ fontWeight: '400' }} as="h5">
              Create your account to get started.
            </Header>
            <Form.Input
              label="First Name"
              name="first_name"
              value={firstName}
              icon="user"
              iconPosition="left"
              placeholder="First Name"
              onChange={setFormInput}
              fluid
              autoCapitalize="none"
              autoCorrect="off"
              error={extractFieldError(error?.first_name)}
            />

            <Form.Input
              label="Last Name"
              name="last_name"
              value={lastName}
              icon="user"
              iconPosition="left"
              placeholder="Last Name"
              onChange={setFormInput}
              fluid
              autoCapitalize="none"
              autoCorrect="off"
              error={extractFieldError(error?.last_name)}
            />
            <Form.Input
              label="Password"
              name="password"
              icon="lock"
              value={password}
              iconPosition="left"
              placeholder="Password"
              onChange={setFormInput}
              fluid
              autoComplete="new-password"
              type="password"
              error={extractFieldError(error?.password)}
            />
            <PasswordValidator password={password || ''} />
            <NamiAgreementLinks name="Continue" />
            <Button
              loading={isLoading}
              disabled={disabled(
                isLoading,
                firstName,
                lastName,
                'email',
                password
              )}
              className="nami-primary-button"
              fluid
              size="large"
              type="submit"
            >
              Continue
            </Button>
          </Form>
        </Segment>
      </Wrapper>
    </Page>
  );
};

export default AcceptInvitePage;
