import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Space } from 'antd';
import { TOrgSecretKindEnum } from 'src/api/types/main.types';
import { useStoreCredentialsForm } from 'src/hooks/ant-form.hooks';
import { isTruthy } from 'src/utils/array';

import CredentialsForm from './CredentialsForm';
import { buildFormItemProps } from './utils';
import VividPlaceholderPassword from './VividPlaceholderPassword';

export default function AppleSharedSecretCredentialsForm() {
  const form = useStoreCredentialsForm('apple');

  const hasSharedSecret = !!form.secrets.apple_shared_secret;
  return (
    <CredentialsForm
      form={form}
      colon={false}
      layout="vertical"
      onFieldsChange={form.handleFieldsChange}
      className="intercom-applestoresharedsecret"
    >
      <Form.Item
        label="Shared Secret"
        tooltip={{
          title:
            'Shared Secret is found in App Store Connect > Users and Access > Integrations > Shared Secret.',
        }}
        className="intercom-applesharedsecret"
        {...buildFormItemProps(
          ['apple_shared_secret', 'secret', 'secret'],
          form.errors
        )}
      >
        <VividPlaceholderPassword
          autoComplete="new-password"
          visibilityToggle={false}
          maxLength={32}
          disabled={hasSharedSecret}
          placeholder={
            hasSharedSecret ? '●'.repeat(32) : 'App Store Connect Shared Secret'
          }
        />
      </Form.Item>
      <Space style={{ marginTop: '2rem' }}>
        <Button
          type="primary"
          disabled={hasSharedSecret || !form.canCreate}
          loading={form.createMutation.isLoading}
          onClick={createAppleSecrets}
        >
          Save
        </Button>
        <Button
          danger
          type="link"
          loading={form.deleteMutation.isLoading}
          disabled={!hasSharedSecret || !form.canDelete}
          onClick={() => {
            const secretIds = [form.secrets.apple_shared_secret?.id].filter(
              isTruthy
            );
            form.deleteMutation.mutate(secretIds);
          }}
        >
          <Space>
            <DeleteOutlined />
            Delete these credentials
          </Space>
        </Button>
      </Space>
    </CredentialsForm>
  );

  function createAppleSecrets() {
    const { apple_shared_secret } = form.getFieldsValue();
    const payloads = [];
    if (!hasSharedSecret) {
      payloads.push({
        ...apple_shared_secret,
        kind: TOrgSecretKindEnum.AppleSharedSecret,
      });
    }
    form.createMutation.mutate(payloads);
  }
}
