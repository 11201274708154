import React, { useMemo, useState } from 'react';

import { LeftOutlined } from '@ant-design/icons';
import { Col, Collapse, Form, Row, Space } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import styled from 'styled-components';

import { useActions, useAppSelector } from '../../../../hooks/redux.hooks';
import PaywallBuilderSlice from '../../../../redux/PaywallBuilderSlice';
import { capitalizePhrase } from '../../../../utils/string';
import { DisplayFieldSwitch } from '../PaywallBuilder/editor/inputs/DisplayFieldSwitch';
import FieldHint from '../PaywallBuilder/editor/inputs/FieldHint';
import SmartInput from '../PaywallBuilder/editor/SmartInput';
import PaywallBuilderDrawer from '../PaywallBuilder/PaywallBuilderDrawer';
import {
  buildInitialValues,
  FieldObject,
  InitialValuesType,
} from '../utils/formFieldBuilding';

type TemplateEditorLegacyProps = {
  isOpen: boolean;
  groupName: string | null;
  onClose: () => void;
};

type FieldsCollapseProps = {
  fields: FieldObject[];
  textPath: string;
  switcherPath: string;
  defaultTextValue: string;
  collapsible: boolean;
  hint: string | undefined;
  isSingle: boolean;
  form: FormInstance;
  initialValues: InitialValuesType;
  onChange: (variable: string, value: any) => void;
  label: string;
};

const FieldCollapseWrapper = styled(Collapse)`
  width: 100%;
  .ant-collapse-header {
    background: white;
    font-weight: bold;
  }
  & {
    border: none;
  }
`;

const Title = styled.div`
  position: absolute;
  top: 15px;
  right: 50%;
  transform: translateX(50%);
  font-weight: bold;
`;

export default function TemplateEditorLegacy({
  isOpen,
  groupName,
  onClose,
}: TemplateEditorLegacyProps) {
  const [form] = Form.useForm();

  const actions = useActions(PaywallBuilderSlice.actions);

  const fieldGroups = useAppSelector(
    ({ paywallBuilder }) => paywallBuilder.fieldGroups
  );
  const template = useAppSelector(
    ({ paywallBuilder }) => paywallBuilder.paywall!.template
  );

  const title = useMemo(
    () => groupName && capitalizePhrase(groupName),
    [groupName]
  );
  const initialValues = useMemo(() => {
    return !!fieldGroups ? buildInitialValues(fieldGroups) : null;
  }, [fieldGroups]);

  if (initialValues === null || fieldGroups === null) return null;
  const group = (groupName && fieldGroups[groupName]) || {};
  const isSingle = Object.keys(group).length === 1;
  return (
    <PaywallBuilderDrawer
      title={<Title>Edit {title}</Title>}
      onClose={onClose}
      open={isOpen}
      closeIcon={
        <Space>
          <LeftOutlined />
          Done
        </Space>
      }
    >
      <Form layout="vertical" form={form} initialValues={initialValues}>
        <Row gutter={16}>
          {Object.entries(group).map(([label, fieldsWrapper]) => (
            <FieldsCollapse
              key={label}
              label={label}
              form={form}
              onChange={updateTemplate}
              isSingle={isSingle}
              initialValues={initialValues}
              {...fieldsWrapper}
            />
          ))}
        </Row>
      </Form>
    </PaywallBuilderDrawer>
  );

  function updateTemplate(
    path: string,
    value: string | number | string[]
  ): void {
    const newTemplate = JSON.parse(JSON.stringify(template));
    let current = newTemplate;
    const attrs = path.split('.');
    for (let i = 0; i < attrs.length - 1; i++) {
      const key = Array.isArray(current) ? +attrs[i] : attrs[i];
      current = current[key];
    }
    const lastKey = attrs[attrs.length - 1];
    current[lastKey] = value;
    actions.setTemplate(newTemplate);
  }
}

function FieldsCollapse({
  fields,
  hint,
  textPath,
  collapsible,
  switcherPath,
  defaultTextValue,
  isSingle,
  form,
  initialValues,
  onChange,
  label,
}: FieldsCollapseProps) {
  const switcherValue = getDefaultValue(switcherPath);
  const [display, setDisplay] = useState<boolean | null>(switcherValue);
  const shouldDisplay = display === null || display;
  const switcher =
    display !== null ? (
      <DisplayFieldSwitch
        hint={hint}
        label={label}
        name={switcherPath}
        checked={display}
        onChange={handleDisplayChange}
        withColumn
      />
    ) : null;
  const children = [
    switcher,
    (shouldDisplay ? fields : []).map((field) => {
      const path = field.variable;
      const isImage = field.type === 'image';
      const size = field.name === 'Text' || isImage ? 24 : 12;

      const hintMargin = field.type === 'image' ? '6rem' : 0;
      return (
        <Col xs={24} md={size} key={path}>
          <SmartInput
            {...field}
            label={label}
            isSingle={isSingle}
            collapsible={collapsible}
            defaultValue={getDefaultValue(path)}
            onChange={(value: any) => onChange(path, value)}
          />
          {field.hint && isImage && (
            <FieldHint style={{ marginTop: hintMargin }}>
              {field.hint}
            </FieldHint>
          )}
        </Col>
      );
    }),
  ];
  if (collapsible) {
    return (
      <FieldCollapseWrapper
        defaultActiveKey={switcherPath}
        expandIconPosition="end"
      >
        <Collapse.Panel header={label} key={switcherPath}>
          <Row gutter={16}>{children}</Row>
        </Collapse.Panel>
      </FieldCollapseWrapper>
    );
  }
  return <>{children}</>;

  function getDefaultValue(path: string): any {
    return form.getFieldValue(path) ?? initialValues[path];
  }

  function handleDisplayChange(display: boolean): void {
    setDisplay(display);
    onChange(textPath, display ? defaultTextValue : '');
  }
}
