import React, { useState } from 'react';

import { Form, Modal, Select, Space, Checkbox, Tooltip } from 'antd';

import { useAppOptions } from '../../../hooks/queries/app.hooks';
import { useDuplicateCampaignRuleMutation } from '../../../hooks/queries/campaign.hooks';
import { useAppSelector } from '../../../hooks/redux.hooks';

type DuplicateRuleModalProps = {
  open: boolean;
  ruleId: string;
  onClose: () => void;
};

export default function DuplicateRuleModal({
  open,
  ruleId,
  onClose,
}: DuplicateRuleModalProps) {
  // TODO: Figure how to merge with "DuplicatePaywallModal"
  const appId = useAppSelector(({ root }) => root.currentApp?.id);
  const [selectedAppId, setSelectedAppId] = useState<string | null>(appId);
  const [duplicatePaywalls, setDuplicatePaywalls] = useState(false);
  const { appOptions, appIdMap } = useAppOptions();
  const duplicateRuleMutation = useDuplicateCampaignRuleMutation({
    ruleId,
    onSuccess: onClose,
  });

  const selectedApp = findAppOption(selectedAppId);
  const currentApp = findAppOption(appId);

  return (
    <Modal
      title="Duplicate Campaign"
      open={open}
      onCancel={onClose}
      onOk={() => {
        const appId = appIdMap[selectedAppId!];
        duplicateRuleMutation.mutate({ app: appId, duplicatePaywalls });
      }}
      confirmLoading={duplicateRuleMutation.isLoading}
      okText="Duplicate"
      zIndex={1002}
      centered
      closable={false}
    >
      <Form layout="vertical">
        <Space>
          Source App:
          {currentApp?.label || 'None'}
        </Space>
        <Form.Item label="Select App" className="intercom-selectApptoDuplicate">
          <Select
            loading={duplicateRuleMutation.isLoading}
            options={appOptions}
            value={selectedApp}
            onChange={(value) => setSelectedAppId(value as unknown as string)}
            size="large"
          />
        </Form.Item>
        {appId !== selectedAppId && (
          <Space>
            <Tooltip title="If left blank, the new campaign will not have any paywalls attached. If checked, the new campaign will have paywalls attached that are copies of all paywalls attached to the original campaign.">
              <span>Duplicate Paywalls? </span>
            </Tooltip>
            <Checkbox
              checked={duplicatePaywalls}
              onChange={(e) => setDuplicatePaywalls(e.target.checked)}
            />
          </Space>
        )}
      </Form>
    </Modal>
  );

  function findAppOption(appId: string | null) {
    return (appId && appOptions.find(({ value }) => value === appId)) || null;
  }
}
