import styled from 'styled-components';

import { colorPrimaryDark } from '../variables';

const SectionTitle = styled.h3`
  color: ${colorPrimaryDark};
  vertical-align: middle;
  padding-top: 15px;
  margin-bottom: 10px;
`;

export default SectionTitle;
