import cubejs, {
  Filter,
  PivotConfig,
  Query,
  TimeDimensionGranularity,
} from '@cubejs-client/core';
import Moment from 'moment';

import { ANALYTICS_URL } from '../config';
import { baseURL, getAccessToken } from './clients';
import {
  DataKey,
  DateRangeType,
  EnvironmentType,
  SegmentType,
  TAppliedFilters,
  TData,
} from './types/analytics.types';

type MeasureType = { key: string };

export type TDataParams = {
  appId: string;
  interval: TimeDimensionGranularity;
  dateRange: DateRangeType;
  environment: EnvironmentType;
  segment: SegmentType | null;
  filters: TAppliedFilters;
  timezone: string;
};

type FetchOptions = {
  metric: string;
  measures: MeasureType[];
  cubeSegments?: string[];
  dateFilter?: string;
  dateRange: DateRangeType;
  appId: string;
  environment: EnvironmentType;
  environmentSegment?: string;
  granularity?: TimeDimensionGranularity;
  fillMissingDates?: boolean;
  userAppliedSegment?: SegmentType | null;
  filters?: TAppliedFilters | null;
  metricFilter?: Filter;
  timezone: string;
};

export const cubeHandlers: { nullTokenHandler: null | (() => unknown) } = {
  nullTokenHandler: null,
};
const apiUrl =
  (ANALYTICS_URL && ANALYTICS_URL.replace(/\/$/, '')) ||
  `${baseURL}/private/analytics`;
const cubeApi = cubejs(
  () =>
    getAccessToken().then(
      (token) => token || Promise.reject(new Error('GotNullToken'))
    ),
  { apiUrl }
);

const revenueFilter: Filter = {
  member: 'PurchaseEvent.pricing',
  operator: 'notEquals',
  values: ['free_trial'],
};

const purchasesFilter: Filter = {
  member: 'PurchaseEvent.namiEventType',
  operator: 'equals',
  values: ['purchased'],
};

const analytics = {
  getRevenue: ({
    dateRange,
    appId,
    environment,
    interval,
    segment,
    filters,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    return fetchCubeData({
      appId,
      metric: 'PurchaseEvent',
      measures: [{ key: 'revenueInUSD' }],
      dateRange,
      dateFilter: 'PurchaseEvent.eventTime',
      environment,
      environmentSegment: 'PurchaseEvent',
      granularity: interval,
      fillMissingDates: true,
      userAppliedSegment: segment,
      filters,
      metricFilter: revenueFilter,
      timezone,
    });
  },

  getTotalRevenue: ({
    dateRange,
    appId,
    environment,
    filters,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    return fetchCubeData({
      appId,
      metric: 'PurchaseEvent',
      measures: [{ key: 'revenueInUSD' }],
      dateRange,
      dateFilter: 'PurchaseEvent.eventTime',
      environment,
      environmentSegment: 'PurchaseEvent',
      fillMissingDates: true,
      filters,
      metricFilter: revenueFilter,
      timezone,
    });
  },

  getActiveDevices: ({
    dateRange,
    appId,
    environment,
    interval,
    segment,
    filters,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    return fetchCubeData({
      appId,
      metric: 'Session',
      measures: [{ key: 'activeDevices' }],
      dateRange,
      dateFilter: 'Session.createdDate',
      environment,
      environmentSegment: 'Session',
      granularity: interval,
      fillMissingDates: true,
      userAppliedSegment: segment,
      filters,
      timezone,
    });
  },

  getTotalActiveDevices: ({
    dateRange,
    appId,
    environment,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    return fetchCubeData({
      appId,
      metric: 'Session',
      measures: [{ key: 'activeDevices' }],
      dateRange,
      dateFilter: 'Session.createdDate',
      environment,
      environmentSegment: 'Session',
      fillMissingDates: true,
      timezone,
    });
  },

  getSessions: ({
    dateRange,
    appId,
    environment,
    interval,
    segment,
    filters,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    return fetchCubeData({
      appId,
      metric: 'Session',
      measures: [{ key: 'count' }],
      dateRange,
      dateFilter: 'Session.createdDate',
      environment,
      environmentSegment: 'Session',
      granularity: interval,
      fillMissingDates: true,
      userAppliedSegment: segment,
      filters,
      timezone,
    });
  },

  getTotalSessions: ({
    dateRange,
    appId,
    environment,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    return fetchCubeData({
      appId,
      metric: 'Session',
      measures: [{ key: 'count' }],
      dateRange,
      dateFilter: 'Session.createdDate',
      environment,
      environmentSegment: 'Session',
      fillMissingDates: true,
      timezone,
    });
  },

  getImpressions: ({
    dateRange,
    appId,
    environment,
    interval,
    segment,
    filters,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    return fetchCubeData({
      appId,
      metric: 'Impression',
      measures: [{ key: 'impressions' }],
      dateRange,
      dateFilter: 'Impression.createdDate',
      environment,
      environmentSegment: 'Session',
      granularity: interval,
      fillMissingDates: true,
      userAppliedSegment: segment,
      filters,
      timezone,
    });
  },

  getTotalImpressions: ({
    dateRange,
    appId,
    environment,
    filters,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    return fetchCubeData({
      appId,
      metric: 'Impression',
      measures: [{ key: 'impressions' }],
      dateRange,
      dateFilter: 'Impression.createdDate',
      environment,
      environmentSegment: 'Session',
      fillMissingDates: true,
      filters,
      timezone,
    });
  },

  getPaywallConversionRate: ({
    dateRange,
    appId,
    environment,
    interval,
    segment,
    filters,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    return fetchCubeData({
      appId,
      metric: 'Impression',
      measures: [{ key: 'impressions' }, { key: 'conversions' }],
      dateRange,
      environment,
      environmentSegment: 'Session',
      granularity: interval,
      fillMissingDates: true,
      userAppliedSegment: segment,
      filters,
      timezone,
    });
  },

  getTotalPaywallConversionRate: ({
    dateRange,
    appId,
    environment,
    filters,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    return fetchCubeData({
      appId,
      metric: 'Impression',
      measures: [{ key: 'impressions' }, { key: 'conversions' }],
      dateRange,
      environment,
      environmentSegment: 'Session',
      fillMissingDates: true,
      filters,
      timezone,
    });
  },

  getFreeTrials: ({
    dateRange,
    appId,
    environment,
    interval,
    segment,
    filters,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    return fetchCubeData({
      appId,
      metric: 'PurchaseEvent',
      measures: [{ key: 'count' }],
      cubeSegments: ['PurchaseEvent.freeTrial'],
      dateRange,
      dateFilter: 'PurchaseEvent.eventTime',
      environment,
      environmentSegment: 'PurchaseEvent',
      granularity: interval,
      fillMissingDates: true,
      userAppliedSegment: segment,
      filters,
      timezone,
    });
  },

  getFreeTrialConversionRate: ({
    dateRange,
    appId,
    environment,
    interval,
    segment,
    filters,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    return fetchCubeData({
      appId,
      metric: 'Purchase',
      measures: [{ key: 'trials' }, { key: 'trialConversions' }],
      dateRange,
      dateFilter: 'Purchase.createdDate',
      environment,
      environmentSegment: 'Purchase',
      granularity: interval,
      fillMissingDates: true,
      userAppliedSegment: segment,
      filters,
      timezone,
    });
  },

  getPurchases: ({
    dateRange,
    appId,
    environment,
    interval,
    segment,
    filters,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    return fetchCubeData({
      appId,
      metric: 'PurchaseEvent',
      measures: [{ key: 'count' }],
      dateRange,
      dateFilter: 'PurchaseEvent.eventTime',
      environment,
      environmentSegment: 'PurchaseEvent',
      granularity: interval,
      fillMissingDates: true,
      userAppliedSegment: segment,
      filters,
      metricFilter: purchasesFilter,
      timezone,
    });
  },

  getTotalPurchases: ({
    dateRange,
    appId,
    environment,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    return fetchCubeData({
      appId,
      metric: 'PurchaseEvent',
      measures: [{ key: 'count' }],
      dateRange,
      dateFilter: 'PurchaseEvent.eventTime',
      environment,
      environmentSegment: 'PurchaseEvent',
      fillMissingDates: true,
      metricFilter: purchasesFilter,
      timezone,
    });
  },

  getMRR: ({
    dateRange,
    appId,
    environment,
    interval,
    segment,
    filters,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    return fetchCubeData({
      appId,
      metric: 'SubscriptionRevenue',
      measures: [{ key: 'revenueInUSD' }],
      dateRange,
      dateFilter: 'SubscriptionRevenue.startTime',
      environment,
      environmentSegment: 'SubscriptionRevenue',
      granularity: interval,
      fillMissingDates: true,
      userAppliedSegment: segment,
      filters,
      timezone,
    });
  },

  getMRRMovement: ({
    dateRange,
    appId,
    environment,
    interval,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    // TODO - implement Cube function
    return new Promise((resolve) => resolve([]));
  },

  getAvgRevenuePerPayingUser: ({
    dateRange,
    appId,
    environment,
    interval,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    // TODO - implement Cube function
    return new Promise((resolve) => resolve([]));
  },

  getAvgRevenuePerRegisteredUser: ({
    dateRange,
    appId,
    environment,
    interval,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    // TODO - implement Cube function
    return new Promise((resolve) => resolve([]));
  },

  getActivePaidSubscribers: ({
    dateRange,
    appId,
    environment,
    interval,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    // TODO - implement Cube function
    return new Promise((resolve) => resolve([]));
  },

  getNewSubscribers: ({
    dateRange,
    appId,
    environment,
    interval,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    // TODO - implement Cube function
    return new Promise((resolve) => resolve([]));
  },

  getAvgMRRPerSubscriber: ({
    dateRange,
    appId,
    environment,
    interval,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    // TODO - implement Cube function
    return new Promise((resolve) => resolve([]));
  },

  getSubscriberMovement: ({
    dateRange,
    appId,
    environment,
    interval,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    // TODO - implement Cube function
    return new Promise((resolve) => resolve([]));
  },

  getSubscriberLTV: ({
    dateRange,
    appId,
    environment,
    interval,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    // TODO - implement Cube function
    return new Promise((resolve) => resolve([]));
  },

  getSubscriberRetention: ({
    dateRange,
    appId,
    environment,
    interval,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    // TODO - implement Cube function
    return new Promise((resolve) => resolve([]));
  },

  getTrialMovement: ({
    dateRange,
    appId,
    environment,
    interval,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    // TODO - implement Cube function
    return new Promise((resolve) => resolve([]));
  },

  getSubscriberChurn: ({
    dateRange,
    appId,
    environment,
    interval,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    // TODO - implement Cube function
    return new Promise((resolve) => resolve([]));
  },

  getRevenueChurn: ({
    dateRange,
    appId,
    environment,
    interval,
    timezone,
  }: TDataParams): Promise<TData[]> => {
    // TODO - implement Cube function
    return new Promise((resolve) => resolve([]));
  },
};

export default analytics;

function normalizeDate(date: Moment.Moment): string {
  //Changing this format will break pre-agg, proceed with caution
  return Moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS');
}

function convertSegments(
  cubeSegments: string[] | undefined,
  environmentSegment: string | undefined,
  envValue: EnvironmentType
): string[] {
  const result = cubeSegments ? cubeSegments : [];
  if (!environmentSegment) return result;
  return result.concat(environmentSegment + '.' + envValue);
}

function convertUserFilters(options: FetchOptions): Filter[] {
  const { appId, filters, metricFilter } = options;
  let result: Filter[] = [];
  if (filters) {
    const filterKeys = Object.entries(filters);
    filterKeys.forEach(([_key, rule]) => {
      if (rule.values.length) {
        result.push({
          member: rule.identifier,
          operator: rule.operator,
          values: rule.values,
        });
      }
    });
  }
  result.push({
    member: 'App.id',
    operator: 'equals',
    values: [appId],
  });
  if (metricFilter) {
    result.push(metricFilter);
  }

  return result;
}

function getCubeConfig(options: FetchOptions): Query {
  const {
    metric,
    measures,
    cubeSegments,
    granularity,
    userAppliedSegment,
    timezone,
  } = options;
  const [startDate, endDate] = options.dateRange;
  const createdDateMeasure = options.dateFilter
    ? options.dateFilter
    : metric + '.createdDate';
  const dimensionsConfig = userAppliedSegment ? [userAppliedSegment] : [];
  const segmentsConfig = convertSegments(
    cubeSegments,
    options.environmentSegment,
    options.environment
  );

  return {
    measures: measures.map((measure) => `${metric}.${measure.key}`),
    timeDimensions: [
      {
        dimension: createdDateMeasure,
        granularity,
        dateRange: [normalizeDate(startDate), normalizeDate(endDate)],
      },
    ],
    order: { [createdDateMeasure]: 'asc' },
    filters: convertUserFilters(options),
    dimensions: dimensionsConfig,
    segments: segmentsConfig,
    timezone: timezone,
  };
}

function getPivotConfig(options: FetchOptions): PivotConfig {
  const { metric, fillMissingDates, userAppliedSegment: segment } = options;
  const createdDateMeasure = options.dateFilter
    ? options.dateFilter
    : metric + '.createdDate';
  return {
    fillMissingDates,
    x: [createdDateMeasure],
    y: segment ? [segment, 'measures'] : ['measures'],
  };
}

function fetchCubeData(options: FetchOptions): Promise<TData[]> {
  return cubeApi
    .load(getCubeConfig(options))
    .then((resultSet) => resultSet.series(getPivotConfig(options)))
    .catch((error) => {
      if (error.message === 'GotNullToken' && cubeHandlers.nullTokenHandler) {
        cubeHandlers.nullTokenHandler();
      }
      return Promise.reject(error);
    });
}

export function parsePaywallConversionRate(
  data: TData[],
  segment: SegmentType
): TData[] {
  if (!data.length) return data; //TODO - better handling for when data hasn't loaded
  return derivePercentageFromData(
    data,
    segment,
    'Impression.conversions',
    'Impression.impressions',
    'Impression.conversionRate',
    'Impression Conversion Rate'
  );
}

export function parseTotalPaywallConversionRate(data: TData[]): TData[] {
  if (!data.length) return data;
  return derivePercentageFromData(
    data,
    null,
    'Impression.conversions',
    'Impression.impressions',
    'Impression.conversionRate',
    'Impression Conversion Rate'
  );
}

export function parseTrialConversionRate(
  data: TData[],
  segment: SegmentType
): TData[] {
  if (!data.length) return data; //TODO - better handling for when data hasn't loaded
  return derivePercentageFromData(
    data,
    segment,
    'Purchase.trialConversions',
    'Purchase.trials',
    'Purchase.trialConversionRate',
    'Purchase Conversion Rate'
  );
}

export function derivePercentageFromData(
  data: TData[],
  segment: SegmentType,
  divisorKey: DataKey,
  dividendKey: DataKey,
  quotientKey: DataKey,
  quotientTitle: string
): TData[] {
  const result: TData[] = [];
  const keyMap = data.map((serie) => serie.key);
  for (let i = 0; i < keyMap.length; i++) {
    const keyParts = keyMap[i].split(',') as DataKey[];
    const keyPart = segment && keyParts.length > 1 ? keyParts[1] : keyParts[0];
    const segmentName = segment && keyParts.length > 1 ? keyParts[0] : '';
    const derivedQuotientKeyName =
      segment && segmentName ? segmentName + ',' + quotientKey : quotientKey;
    const derivedDividentKey =
      segment && segmentName ? segmentName + ',' + dividendKey : dividendKey;
    if (keyMap.indexOf(derivedQuotientKeyName) !== -1) continue; //If derivedkey already exists, no need to derive data
    if (keyPart === divisorKey) {
      //Look for corresponding dividend with same segment
      const dividendIndex = keyMap.indexOf(derivedDividentKey);
      const divisorIndex = i;
      if (dividendIndex < 0) continue;
      const newObj: TData = {
        title:
          segment && segmentName
            ? segmentName + ', ' + quotientTitle
            : quotientTitle,
        key: derivedQuotientKeyName,
        series: data[divisorIndex].series.map((dataSerie, index) => ({
          x: dataSerie.x,
          value: dataSerie.value
            ? dataSerie.value / data[dividendIndex].series[index].value
            : 0,
        })),
      };
      result.push(newObj);
      result.push(data[dividendIndex]);
      result.push(data[divisorIndex]);
    }
  }
  return result;
}
