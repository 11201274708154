import React, { useMemo } from 'react';

import { Space } from 'antd';
import { useCDPAudiencesQuery } from 'src/hooks/queries/campaign.hooks';
import { getCDPProviderLogo } from 'src/pages/admin/settings/org-integrations/OrgIntegrationsPage';

import FilterSelect, { BaseFilterSelectProps } from './FilterSelect';
import { identifierLabel } from './mapsAndOptions';

export default function CDPAudienceSelect(props: BaseFilterSelectProps) {
  const cdpAudiences = useCDPAudiencesQuery();

  const options = useMemo(() => {
    if (cdpAudiences.isFetching) return [];
    return (cdpAudiences.data?.results || []).map((value) => {
      return {
        label: (
          <Space direction="horizontal">
            {getCDPProviderLogo(value.provider_type, 16, 16, {
              transform: 'translateY(3px)',
            })}
            {value.display_name || value.name}
          </Space>
        ),
        text: value.display_name,
        value: value.id,
        key: value.id,
      };
    });
  }, [cdpAudiences]);

  return (
    <FilterSelect
      {...props}
      label={identifierLabel['Device.cdp_audience']}
      options={options}
      loading={cdpAudiences.isFetching}
      operators={['contains', 'not_contains']}
      variant="select"
    />
  );
}
