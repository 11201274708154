import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import { createSelector } from '@reduxjs/toolkit';
import { Button, Divider, Select, Tooltip } from 'antd';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

type ProductGroupsDropdownMenuProps = {
  flavor: 'small' | 'regular';
};

const selector = createSelector(
  [({ paywallBuilder }: RootState) => paywallBuilder],
  (paywallBuilder) => ({
    groupId: paywallBuilder.groupId,
    productGroups: paywallBuilder.productGroups,
    slides: paywallBuilder.paywall?.template.initialState.slides,
    productGroupCarousel:
      paywallBuilder.paywall?.template['ui.carousels']?.['carouselName']
        .productGroupSlides || false,
  })
);

export function ProductGroupsDropdownMenu({
  flavor,
}: ProductGroupsDropdownMenuProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const { groupId, productGroups, slides, productGroupCarousel } =
    useAppSelector(selector);

  function setEditingSlideId(value: string) {
    if (!productGroupCarousel) return;
    const allSlides = (slides || {})[productGroups[0].id]['carouselName'];
    const slideForProductGroup = allSlides.find(
      (slide) => slide['productGroupId'] === value
    );
    if (slideForProductGroup)
      actions.setViewingSlideId(slideForProductGroup.id);
  }

  return (
    <Tooltip
      title="Pick the active product group"
      mouseEnterDelay={0.6}
      placement="right"
    >
      <Select
        size={flavor === 'small' ? 'small' : 'middle'}
        value={groupId}
        onChange={(value) => {
          actions.setProductGroupId(value);
          setEditingSlideId(value);
          actions.setEditingProductId(null);
        }}
        options={productGroups.map((group) => ({
          key: group.id,
          label: group.display_name,
          value: group.id,
          text: group.ref,
        }))}
        dropdownMatchSelectWidth={false}
        style={{ width: flavor === 'small' ? 124 : 220 }}
        showSearch={true}
        filterOption={(input, option) =>
          (option?.label || '').toLowerCase().includes(input.toLowerCase()) ||
          (option?.text || '').toLowerCase().includes(input.toLowerCase())
        }
        className="verticalScrollTable"
        dropdownRender={(menu) => (
          <>
            {menu}
            {flavor === 'small' && (
              <>
                <Divider style={{ marginTop: 4, marginBottom: 4 }} />
                <Button
                  type="text"
                  size="small"
                  icon={<EditOutlined style={{ fontSize: '13px' }} />}
                  onClick={() => actions.setEditingProductGroups(true)}
                  style={{ marginBottom: 4, marginLeft: 2 }}
                >
                  Edit
                </Button>
              </>
            )}
          </>
        )}
      />
    </Tooltip>
  );
}
