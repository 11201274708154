import moment from 'moment-timezone';

export function toReadableDate(date: Date): string {
  return Intl.DateTimeFormat(navigator.language, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  }).format(date);
}

export function getRelativeTimeString(date: Date): string {
  // Source: https://www.builder.io/blog/relative-time
  const diff = Math.round((date.getTime() - Date.now()) / 1000);

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = day * 365;
  const cutoffs = [minute, hour, day, week, month, year, Infinity];

  const units: Intl.RelativeTimeFormatUnit[] = [
    'second',
    'minute',
    'hour',
    'day',
    'week',
    'month',
    'year',
  ];

  const unitIndex = cutoffs.findIndex((cutoff) => cutoff > Math.abs(diff));

  const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1;

  const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
  return rtf.format(Math.floor(diff / divisor), units[unitIndex]);
}

export function momentCalculator(
  timezone: string,
  add: number,
  unit: moment.unitOfTime.DurationConstructor,
  startOf?: moment.unitOfTime.StartOf,
  endOf?: moment.unitOfTime.StartOf
): moment.Moment {
  const result = moment().tz(timezone).add(add, unit);
  if (startOf) return result.startOf(startOf);
  if (endOf) return result.endOf(endOf);
  return result.endOf('day');
}

export function nowMinus24Hr(timezone: string): moment.Moment {
  return moment().tz(timezone).add(-24, 'hours').startOf('hour');
}

export function nowMinus72Hr(timezone: string): moment.Moment {
  return moment().tz(timezone).add(-72, 'hours').startOf('hour');
}

export function endOfCurHr(timezone: string): moment.Moment {
  return moment().tz(timezone).endOf('hour');
}

export function startOfCurDay(timezone: string): moment.Moment {
  return moment().tz(timezone).startOf('day');
}

export function endOfCurDay(timezone: string): moment.Moment {
  return moment().tz(timezone).endOf('day');
}

export function startOfPrevDay(timezone: string): moment.Moment {
  return moment().tz(timezone).add(-1, 'days').startOf('day');
}

export function endOfPrevDay(timezone: string): moment.Moment {
  return moment().tz(timezone).add(-1, 'days').endOf('day');
}
