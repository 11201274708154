import { useContext } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';

import { AppContext } from '../../AppContextProvider';
import api from '../../api';
import { useAppContext } from '../context.hooks';
import { useAppSelector } from '../redux.hooks';
import QueryKeys from './queryKeys';

export function useIntegrationsMetadataQuery() {
  return useQuery(
    [QueryKeys.integrationsMetadata],
    api.getIntegrationsMetaData
  );
}

export function useEventStreamsQuery() {
  const { userHasEntitlement } = useContext(AppContext);
  const appId = useAppSelector(({ root }) => root.currentApp.id);

  return useQuery([QueryKeys.streams, appId], () => api.getStreams(appId), {
    enabled: !!appId && userHasEntitlement('app.event_stream.list'),
  });
}

export function useAppCDPIntegrationQuery(integrationId: string) {
  const { userHasEntitlement } = useContext(AppContext);
  return useQuery(
    [QueryKeys.appCDPIntegrations, integrationId],
    () => api.getAppCDPIntegration(integrationId),
    {
      enabled: userHasEntitlement('org.cdp_integration.list'),
    }
  );
}

export function useAppCDPIntegrationsQuery() {
  const { userHasEntitlement } = useContext(AppContext);
  const appId = useAppSelector(({ root }) => root.currentApp.id);

  return useQuery(
    [QueryKeys.appCDPIntegrations, appId],
    () => api.getAppCDPIntegrations(appId),
    {
      enabled: userHasEntitlement('org.cdp_integration.list'),
    }
  );
}

export function useAddAppCDPIntegrationMutation() {
  const queryClient = useQueryClient();
  const appId = useAppSelector(({ root }) => root.currentApp.id);

  return useMutation(
    [QueryKeys.appCDPIntegrations],
    (payload: string) => {
      const constructedPayload = {
        integration: payload,
        app: appId,
      };
      return api.createAppCDPIntegration(constructedPayload);
    },
    {
      onSuccess: () => {
        notification.success({
          message: 'Added CDP integration successfully.',
        });
        return queryClient.invalidateQueries([QueryKeys.appCDPIntegrations]);
      },
    }
  );
}

export function useGetCDPIdentityScopeQuery() {
  const orgId = useAppContext().selectedOrg?.id;
  return useQuery([QueryKeys.cdpIntegrationIdentityScope, orgId], () =>
    api.getCDPIdentityScope()
  );
}

export function useGetAppCDPIntegrationLinks(integrationId: string) {
  return useQuery([QueryKeys.cdpIntegrationLinks, integrationId], () =>
    api.getCDPIntegrationLinks(integrationId)
  );
}
