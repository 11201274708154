import React, { useMemo } from 'react';

import { AppstoreAddOutlined } from '@ant-design/icons';
import { Image, Menu, MenuProps, Popover, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import { namiAliceGray } from 'src/variables';
import styled from 'styled-components';

import { AppType } from '../../api/types/app.types';
import { useAppSelector } from '../../hooks/redux.hooks';
import { defaultImage } from '../../services/helpers';

type AppSwitchPopoverProps = {
  appSwitchMenuOpen: boolean;
  onClose: () => void;
  onAppClick: (appId: string) => void;
  children: JSX.Element;
};

const PopoverMenu = styled(Menu)`
  border-right: unset !important;

  .ant-menu-item-group > {
    .ant-menu-item-group-title {
      padding: 4px !important;
    }

    .ant-menu-item-group-list > .ant-menu-item {
      margin-block: 0;
      padding-left: 4px !important;
      line-height: 2.5 !important;
      height: auto !important;
    }

    .ant-menu-item-group-list
      > .ant-menu:not(.ant-menu-horizontal)
      .ant-menu-item-selected {
      background-color: ${namiAliceGray};
    }
  }

  .ant-menu-item {
    padding: 4px 12px !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: ${namiAliceGray};
  }

  .ant-menu-item-selected {
    font-weight: 500;
    color: black;
  }
`;

export default function AppSwitchPopover({
  appSwitchMenuOpen,
  onClose,
  onAppClick,
  children,
}: AppSwitchPopoverProps) {
  const history = useHistory();
  const { currentApp, apps } = useAppSelector(({ root }) => ({
    currentApp: root.currentApp,
    apps: root.apps as AppType[], // TODO: Remove when "root" is properly typed
  }));

  const appMenuItems = useMemo(() => {
    return apps.map((app) => {
      return {
        label: (
          <Space direction="horizontal" size={10}>
            <Image
              height={24}
              width={24}
              style={{
                cursor: 'pointer',
                borderRadius: 5,
              }}
              preview={false}
              src={app.logo || defaultImage(app.name)}
            />
            {app.name}
          </Space>
        ),
        key: app.id,
      };
    });
  }, [apps]);

  let menuItems: MenuProps['items'] = [
    {
      label: 'Manage Apps',
      key: 'apps',
      icon: <AppstoreAddOutlined />,
    },
  ];
  if (appMenuItems.length > 0) {
    menuItems.unshift({
      label: 'Switch app',
      type: 'group',
      children: appMenuItems,
    });
  }

  const dropdownOnClick: MenuProps['onClick'] = ({ key }) => {
    onClose();
    if (key === 'apps') {
      history.push('/settings/?tab=apps');
    } else {
      onAppClick(key);
    }
  };

  return (
    <Popover
      placement="rightTop"
      trigger={['click']}
      className="intercom-switchAppPopover"
      align={{ offset: [-5, 5] }}
      content={
        <PopoverMenu
          items={menuItems}
          onClick={dropdownOnClick}
          inlineIndent={0}
          selectable={false}
          selectedKeys={[currentApp?.id || '']}
          style={{ borderRight: 'unset !important' }}
        />
      }
      open={appSwitchMenuOpen}
      onOpenChange={(open) => {
        if (!open) onClose();
      }}
      style={{ padding: 12, minWidth: '175px !important' }}
    >
      {children}
    </Popover>
  );
}
