import React, { FormEvent, useState } from 'react';

import { Form, Input, Modal } from 'antd';

import { useNewAppMutation } from '../../hooks/queries/app.hooks';
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hooks';
import { resetNewApp, resetNewAppErrors } from '../../redux/actions/appActions';
import { toggleNewAppModal } from '../../redux/actions/uiActions';

export default function NewAppModal() {
  const dispatch = useAppDispatch();
  const addAppMutation = useNewAppMutation();
  const [form] = Form.useForm<{ name: string }>();
  const [newAppName, setNewAppName] = useState<string>('');
  const { submit, newAppModelOpen } = useAppSelector(({ app, ui }) => ({
    submit: app.submit,
    newAppModelOpen: ui.newAppModelOpen,
  }));

  function createNewApp(e: FormEvent | KeyboardEvent) {
    e.preventDefault();
    addAppMutation.mutate(newAppName);
  }

  function cancel() {
    form.resetFields();
    dispatch(toggleNewAppModal());
    dispatch(resetNewApp());
    dispatch(resetNewAppErrors());
  }

  return (
    <Modal
      centered
      onCancel={cancel}
      open={newAppModelOpen}
      title={'Add an App'}
      closable={false}
      onOk={createNewApp}
      okButtonProps={{
        disabled: !newAppName || submit,
      }}
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(changedValues) => setNewAppName(changedValues.name)}
      >
        <Form.Item label="Name" name="name">
          <Input placeholder="Name" />
        </Form.Item>
      </Form>
    </Modal>
  );
}
