import React from 'react';

import { TSemverObj } from 'src/utils/parsing';
import styled, { css } from 'styled-components';

import {
  TCarouselSlidesState,
  TContainer,
} from '../../../api/types/paywallTemplate.types';
import { transition, widthAndHeight } from '../css';
import TemplateComponent from '../TemplateComponent';
import { withOverrides } from '../utils';
import { ContainerWrapper } from './Container';
import HoverTag from './HoverTag';

type ContentProps = {
  component: TContainer | null;
  zIndex: number;
  inFocusedState: boolean;
  groupId: string | null;
  slides?: TCarouselSlidesState;
  minSDKVersion: TSemverObj;
};

const Wrapper = styled.div<{
  zIndex: number;
  component: TContainer;
}>`
  ${({ component }) => css`
    ${widthAndHeight(component)}
  `}
  align-items: center;
  justify-content: start;
  z-index: ${({ zIndex }) => zIndex};
  margin-bottom: ${({ component }) => Number(component.bottomMargin) || 0}px;
  ${transition()};
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const InnerWrapper = styled(ContainerWrapper)`
  align-items: center;
  justify-content: start;
  height: inherit;
`;

export default function Content({
  component,
  zIndex,
  inFocusedState,
  groupId,
  slides,
  minSDKVersion,
}: ContentProps) {
  if (component === null) return null;
  component = component = withOverrides(component);

  return (
    <HoverTag
      title={component.namiComponentType ? component.title || component.id : ''}
      namiComponentType={component.namiComponentType}
      id={component.id}
    >
      <Wrapper zIndex={zIndex} component={component}>
        <InnerWrapper component={component} minSDKVersion={minSDKVersion}>
          {(component.components || []).map((child, i) => (
            <TemplateComponent
              key={i}
              component={child}
              inFocusedState={inFocusedState}
              groupId={groupId}
              slides={slides}
              minSDKVersion={minSDKVersion}
            />
          ))}
        </InnerWrapper>
      </Wrapper>
    </HoverTag>
  );
}
