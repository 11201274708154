import { SelectableItemType } from 'src/api/types/paywall.types';
import { v4 as uuid4 } from 'uuid';

import { TProduct } from '../../../../api/types/sku.types';

export function convertProductToItem(
  product: TProduct,
  variables: { [key: string]: any }
): SelectableItemType {
  return {
    id: uuid4(),
    sku_id: product.id,
    featured: false,
    selected: false,
    name: product.name,
    sku_ref_id: product.sku_ref_id,
    sku_type: product.sku_type,
    variables,
    entitlements: product.entitlements,
  };
}
