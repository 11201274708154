import React from 'react';

import { Alert, Space, Tabs } from 'antd';

import AppleCredentialsForm from './AppleAppStoreConnectCredentialsForm';
import AppleIAPCredentialsForm from './AppleIAPCredentialsForm';
import AppleSharedSecretCredentialsForm from './AppleSharedSecretCredentialsForm';

export default function AppleCredentialsFormWrapper() {
  const tabItems = [
    {
      label: 'Shared Secret',
      key: 'shared-secret',
      children: (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Alert
            message={
              <span>
                Shared Secret is required for receipt validation.{' '}
                <a
                  href="https://learn.namiml.com/public-docs/integrations/billing-platforms/apple-integration/store-credentials/app-store-connect-shared-secret"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </span>
            }
            type="info"
            showIcon
          />
          <AppleSharedSecretCredentialsForm />
        </Space>
      ),
    },
    {
      label: 'App Store Connect API',
      key: 'app-store-connect-api',
      children: (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Alert
            message={
              <span>
                App Store Connect API key is used for syncing product
                information from the store.{' '}
                <a
                  href="https://learn.namiml.com/public-docs/integrations/billing-platforms/apple-integration/store-credentials/apple-app-store-connect-api"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </span>
            }
            type="info"
            showIcon
          />
          <AppleCredentialsForm />
        </Space>
      ),
    },
    {
      label: 'In-App Purchase',
      key: 'in-app-purchase',
      children: (
        <Space direction="vertical" style={{ width: '100%' }}>
          <Alert
            message={
              <span>
                In-App Purchase key is used for syncing purchase history and
                signing promotional offers.{' '}
                <a
                  href="https://learn.namiml.com/public-docs/integrations/billing-platforms/apple-integration/store-credentials/apple-in-app-purchase"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn More
                </a>
              </span>
            }
            type="info"
            showIcon
          />
          <AppleIAPCredentialsForm />
        </Space>
      ),
    },
  ];
  return <Tabs items={tabItems} size="small" />;
}
