/* eslint-disable no-template-curly-in-string */
import React, { useState } from 'react';

import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Select, Space, Tooltip } from 'antd';
import {
  useBulkDuplicateRuleToPlacementsMutation,
  useUnarchivedCampaignLabelIdQuery,
} from 'src/hooks/queries/campaign.hooks';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { namiMediumGray } from 'src/variables';

import { PlacementLabel } from './detail/CampaignLabelSelect';

type DuplicateRuleToPlacementsModalProps = {
  open: boolean;
  ruleId: string;
  onClose: () => void;
  ruleName: string;
};

export default function DuplicateRuleToPlacementsModal({
  open,
  ruleId,
  onClose,
  ruleName,
}: DuplicateRuleToPlacementsModalProps) {
  const appId = useAppSelector(({ root }) => root.currentApp?.id);
  const placements = useUnarchivedCampaignLabelIdQuery();
  const [labels, setLabels] = useState<string[]>([]);
  const [nameTemplate, setNameTemplate] = useState<string>(
    `Duplicate of ${ruleName}`
  );
  const [form] = Form.useForm<{ labels: string[]; nameTemplate: string }>();

  const options = Object.entries(placements).map(([id, placement]) => {
    return {
      key: placement.value,
      name: placement.value,
      value: id,
      label: (
        <Space direction="horizontal">
          {placement.archived && (
            <DeleteOutlined style={{ fontSize: 12, color: namiMediumGray }} />
          )}
          <PlacementLabel>{placement.value}</PlacementLabel>
        </Space>
      ),
    };
  });
  const duplicateToPlacementsMutation =
    useBulkDuplicateRuleToPlacementsMutation({
      ruleId,
      onSuccess: onClose,
    });

  const onFormNameChange = ({ nameTemplate }: { nameTemplate: string }) => {
    setNameTemplate(nameTemplate);
  };

  const NameInputSuffix = (
    <Tooltip title="Use Smart Text variables ${placement.display_name} or ${placement.label} to craft dynamic rule names.">
      <InfoCircleOutlined />
    </Tooltip>
  );

  return (
    <Modal
      title="Copy to Multiple Placements"
      open={open}
      onCancel={onClose}
      onOk={() => {
        duplicateToPlacementsMutation.mutate({
          app: appId,
          labels,
          name_template: nameTemplate,
        });
      }}
      confirmLoading={duplicateToPlacementsMutation.isLoading}
      okText={getOKButtonText()}
      okButtonProps={{ disabled: !labels.length || !nameTemplate }}
      zIndex={1002}
      centered
      closable={false}
    >
      <Form
        layout="vertical"
        onValuesChange={onFormNameChange}
        form={form}
        initialValues={{ nameTemplate: nameTemplate }}
      >
        <Form.Item label="Placements" name="labels">
          <Select
            mode="multiple"
            allowClear
            placeholder="Select placements"
            options={options}
            loading={duplicateToPlacementsMutation.isLoading}
            onChange={setLabels}
            filterOption={(
              input: string,
              option?: {
                key: string;
                name: string;
                value: string;
                label: JSX.Element;
              }
            ) =>
              (option?.name || '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item label="Name" name="nameTemplate">
          <Input suffix={NameInputSuffix} />
        </Form.Item>
      </Form>
    </Modal>
  );

  function getOKButtonText(): string {
    if (!labels.length) return 'Duplicate';
    return `Copy to ${labels.length} Placement${
      labels.length !== 1 ? 's' : ''
    }`;
  }
}
