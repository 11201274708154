import React from 'react';

import FilterSelect, { BaseFilterSelectProps } from './FilterSelect';
import { identifierLabel } from './mapsAndOptions';

export default function AppVersionSelect(props: BaseFilterSelectProps) {
  return (
    <FilterSelect
      {...props}
      variant={'manual_value'}
      label={identifierLabel['Device.app_version']}
      //TODO - support between
      operators={['equals', 'not_equals', 'contains', 'not_contains']}
    />
  );
}
