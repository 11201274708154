import { toast } from 'react-toastify';

import * as TYPES from '../types';

const initialState = {
  loading: true,
  changes: false,
  campaigns: [],
  campaign: null,
  updateCampaignError: null,
};

export const campaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_CAMPAIGNS:
    case TYPES.GET_CAMPAIGNS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaigns: action.payload.data.results,
        loading: false,
      };
    case TYPES.GET_CAMPAIGNS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_CAMPAIGN:
    case TYPES.GET_CAMPAIGN_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_CAMPAIGN_SUCCESS:
      return {
        ...state,
        changes: false,
        campaign: action.payload.data,
        updateCampaignError: null,
        loading: false,
      };
    case TYPES.GET_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TYPES.ADD_CAMPAIGN:
    case TYPES.ADD_CAMPAIGN_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.ADD_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        campaign: action.payload.data,
      };
    case TYPES.ADD_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.DELETE_CAMPAIGN:
    case TYPES.DELETE_CAMPAIGN_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.DELETE_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TYPES.DELETE_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TYPES.UPDATE_CAMPAIGN:
    case TYPES.UPDATE_CAMPAIGN_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.UPDATE_CAMPAIGN_SUCCESS:
      window.scrollTo(0, 0);
      toast.success('Successfully updated campaign.');
      return {
        ...state,
        loading: false,
        changes: false,
        updateCampaignError: null,
      };
    case TYPES.UPDATE_CAMPAIGN_FAIL:
      return {
        ...state,
        loading: false,
        updateCampaignError: action.error.response.data,
      };
    case TYPES.CAMPAIGN_LIVE:
    case TYPES.CAMPAIGN_LIVE_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CAMPAIGN_LIVE_SUCCESS:
      toast.success('Campaign is now live.');
      return {
        ...state,
        loading: false,
      };

    case TYPES.CAMPAIGN_LIVE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.CAMPAIGN_OFFLINE:
    case TYPES.CAMPAIGN_OFFLINE_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CAMPAIGN_OFFLINE_SUCCESS:
      toast.success('Campaign is deactivated.');
      return {
        ...state,
        loading: false,
      };

    case TYPES.CAMPAIGN_OFFLINE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TYPES.HANDLE_CAMPAIGN_FIELD:
      return {
        ...state,
        changes: action.payload.changes,
        campaign: action.payload.campaign,
      };
    default:
      return state;
  }
};
