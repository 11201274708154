import { apiRequest } from '../../services/apiService';
import * as TYPES from '../types';

export const signUpComplete = () => {
  return {
    type: TYPES.SIGNUP_COMPLETE,
    meta: { segment: { event: 'Registration Complete' } },
  };
};

export const resetPasswordEmail = (data) => {
  return apiRequest({
    type: TYPES.RESET_PASSWORD_EMAIL,
    url: '/private/account/password/reset/',
    method: 'POST',
    data,
  });
};

export const resetPassword = (data) => {
  return apiRequest({
    type: TYPES.RESET_PASSWORD,
    url: '/private/account/password/reset/confirm/',
    method: 'POST',
    data,
  });
};

export const handleResetPasswordField = (key, value) => {
  return {
    type: TYPES.HANDLE_RESET_PASSWORD_FIELD,
    payload: { key, value },
  };
};

export const handlePasswordField = (key, value) => {
  return {
    type: TYPES.HANDLE_PASSWORD_FIELD,
    payload: { key, value },
  };
};
