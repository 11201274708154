import React, { useEffect, useState } from 'react';

import { Input, Radio, Select } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';

import api from '../../../api';

const StreamOwnerRadioInput = ({
  isEditing,
  initialStream,
  onChange,
  disabled,
}) => {
  const app = useSelector(({ root }) => root.currentApp);
  const [platforms, setPlatforms] = useState([]);
  const [platformId, setPlatformId] = useState();
  const [ownerType, setOwnerType] = useState();

  useEffect(() => {
    api.getPlatforms(app?.id).then((platforms) => {
      setPlatforms(platforms);
      setPlatformId((platformId) => platformId || platforms[0].id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app?.id]);

  useEffect(() => {
    const { platform, app } = initialStream || {};
    setOwnerType((platform && 'platform') || (app && 'app'));
    setPlatformId(platform);
  }, [initialStream]);

  const onChangeRadio = (e) => {
    setOwnerType(e.target.value);
    if (e.target.value === 'app') {
      onChange({ platform: null, app: app?.id });
    } else {
      onChange({ app: null, platform: platformId });
    }
  };

  const handlePlatformChange = (value) => {
    setPlatformId(value);
    onChange({ app: null, platform: value });
  };

  return (
    <Form.Field className="nami-radio">
      <Radio.Group
        disabled={isEditing || disabled}
        onChange={onChangeRadio}
        value={ownerType}
      >
        <Radio value="app">
          Enable for events from <strong>{app?.name}</strong> App and all
          Platforms
        </Radio>
        <Input.Group compact>
          <Radio value="platform">
            Enable for events from only selected platform:
          </Radio>
          {!!platforms?.length && ownerType === 'platform' && (
            <Select
              style={{ minWidth: '20rem' }}
              placeholder="Select Platform"
              value={platformId}
              disabled={isEditing}
              onChange={handlePlatformChange}
            >
              {platforms.map((platform) => (
                <Select.Option key={platform.id} value={platform.id}>
                  {platform.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Input.Group>
      </Radio.Group>
    </Form.Field>
  );
};

StreamOwnerRadioInput.propTypes = {
  currentApp: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  initialStream: PropTypes.shape({
    app: PropTypes.string,
    platform: PropTypes.string,
  }),
  isEditing: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default StreamOwnerRadioInput;
