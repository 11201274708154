import React from 'react';

import { connect } from 'react-redux';
import { Segment, Form, Button } from 'semantic-ui-react';

import NamiIcon from '../../../components/NamiIcon/NamiIcon';
import Page from '../../../components/Page/Page';
import Wrapper from '../../../components/Wrapper/Wrapper';
import {
  resetPasswordEmail,
  handleResetPasswordField,
} from '../../../redux/actions/authActions';
import { extractFieldError } from '../../../services/utilsService';

const ForgotPasswordPage = ({
  loading,
  resetPasswordEmail,
  resetPasswordData,
  handleResetPasswordField,
  resetPasswordError,
}) => {
  const { email } = resetPasswordData;

  const submit = () => {
    resetPasswordEmail({ username: email });
  };

  return (
    <Page title="Forgot Password">
      <Wrapper>
        <Segment basic textAlign="center">
          <NamiIcon icon="logo" color="primary" size="massive" />
        </Segment>
        <Segment>
          <Form size="large">
            <Form.Input
              label="Email"
              name="email"
              fluid
              value={email}
              onChange={(e) =>
                handleResetPasswordField(e.target.name, e.target.value)
              }
              icon="mail"
              iconPosition="left"
              placeholder="E-mail address"
              error={extractFieldError(resetPasswordError?.email)}
            />
            <Button
              className="nami-primary-button"
              fluid
              size="large"
              loading={loading}
              disabled={loading}
              onClick={submit}
            >
              Reset
            </Button>
          </Form>
        </Segment>
      </Wrapper>
    </Page>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPasswordEmail: (data) => dispatch(resetPasswordEmail(data)),
    handleResetPasswordField: (field, value) =>
      dispatch(handleResetPasswordField(field, value)),
  };
};

const mapStateToProps = ({ auth }) => {
  return {
    loading: auth.loading,
    resetPasswordData: auth.resetPasswordData,
    resetPasswordError: auth.resetPasswordError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
