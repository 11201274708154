import React from 'react';

import { Space, Typography } from 'antd';
import Link from 'antd/lib/typography/Link';
import { useQueryParams } from 'src/hooks';
import { namiNavy, namiPrimaryBlue } from 'src/variables';
import styled from 'styled-components';

import ConfirmEmailSkeleton from './ConfirmEmailSkeleton';

export const Header = styled(Typography.Text)`
  color: ${namiNavy};
  font-size: 24px;
  font-weight: 600;
`;

export const Subtext = styled(Typography.Text)`
  font-size: 16px;
  color: ${namiNavy};
  font-weight: 400;
`;

const SupportSubtext = styled(Typography.Text)`
  font-size: 14px;
  color: ${namiNavy};
  font-weight: 400;

  a {
    color: ${namiPrimaryBlue};
  }
`;

const EmailVerificationPage = () => {
  const email = useQueryParams().get('email_address');

  return (
    <ConfirmEmailSkeleton
      title="Email Verification"
      children={
        <>
          <Header>Check your email</Header>
          {email ? (
            <Space direction="vertical">
              <Subtext>
                To start using Nami Paywall Creator, confirm your email address
                with the link sent to:
              </Subtext>
              <Subtext style={{ fontWeight: 'bold' }}>{email}</Subtext>
            </Space>
          ) : (
            <Subtext>
              We’ve sent you a verification email. Please open it and click the
              link to finish creating your account.
            </Subtext>
          )}
          <Space direction="vertical" style={{ marginTop: 24 }}>
            <SupportSubtext>
              If you do not see the email in your inbox, please check your junk
              folder.
            </SupportSubtext>
            <SupportSubtext>
              Need help?{' '}
              <Link
                href="mailto:support@namiml.com?subject=Help me verify my Nami account"
                style={{ fontWeight: 'bold' }}
              >
                Contact customer support.
              </Link>
            </SupportSubtext>
          </Space>
        </>
      }
    />
  );
};

export default EmailVerificationPage;
