import { client } from './clients';
import {
  FeatureRequestPayloadType,
  FeatureRequestType,
  OrgType,
  TListResult,
  TOrgCDPIntegration,
  TOrgCDPIntegrationPayload,
  TOrgCDPIntegrationToken,
  TOrgSecret,
  TOrgSecretListResult,
  TOrgSecretPayload,
  UpgradeRequestType,
} from './types/main.types';
import { extractErrorData, extractResponseData } from './utils';

const orgs = {
  updateOrg: (orgId: string, data: { name: string }): Promise<OrgType> => {
    return client
      .put(`/private/org/orgs/${orgId}/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  requestUpgrade: (entitlement: string): Promise<UpgradeRequestType> => {
    return client
      .post('/private/upgrade_requests/', { entitlement })
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  newFeaturedRequest: (
    data: FeatureRequestPayloadType
  ): Promise<FeatureRequestType> => {
    return client
      .post('/private/feature_requests/', data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getOrgSecrets: (): Promise<TOrgSecretListResult> => {
    return client
      .get('/private/org_secrets/')
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  createOrgSecret: (data: TOrgSecretPayload): Promise<TOrgSecret> => {
    return client
      .post('/private/org_secrets/', data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  deleteOrgSecret: (secretId: string): Promise<void> => {
    return client
      .delete(`/private/org_secrets/${secretId}`)
      .catch(extractErrorData) as unknown as Promise<void>;
  },
  getCDPIntegration: (integrationId: string): Promise<TOrgCDPIntegration> => {
    return client
      .get(`/private/cdp/org-cdp-integrations/${integrationId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getCDPIntegrationTokens: (
    integrationId: string
  ): Promise<Array<TOrgCDPIntegrationToken>> => {
    return client
      .get(
        `/private/cdp/org-cdp-integrations/${integrationId}/list_tokens/?enabled=true`
      )
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getCDPIntegrations: (): Promise<TListResult<TOrgCDPIntegration>> => {
    return client
      .get('/private/cdp/org-cdp-integrations/')
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  createCDPIntegration: (
    data: Partial<TOrgCDPIntegrationPayload>
  ): Promise<TOrgCDPIntegration> => {
    return client
      .post(`/private/cdp/org-cdp-integrations/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  updateCDPIntegration: (
    id: string,
    data: Partial<TOrgCDPIntegrationPayload>
  ): Promise<TOrgCDPIntegration> => {
    return client
      .patch(`/private/cdp/org-cdp-integrations/${id}/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  generateCDPIntegrationToken: (id: string): Promise<{ token: string }> => {
    return client
      .post(`/private/cdp/org-cdp-integrations/${id}/generate_token/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  revokeCDPIntegrationToken: (
    integrationId: string,
    tokenJTI: string
  ): Promise<void> => {
    const data = {
      token: tokenJTI,
    };
    return client
      .post(
        `/private/cdp/org-cdp-integrations/${integrationId}/disable_token/`,
        data
      )
      .then(extractResponseData)
      .catch(extractErrorData);
  },
};

export default orgs;
