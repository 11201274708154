// Nami Brand 2.0
// primary
export const namiNavy = '#021f3e';
export const namiPrimaryBlue = '#1374de';
export const namiAqua = '#67d6e0';
export const namiPureWhite = '#ffffff';
// supporting
export const namiSecondaryBlue = '#159fde';
export const namiGreen = '#66cc99';
export const namiDarkGreen = '#208653';
export const namiCoral = '#ff5b74';
export const namiYellow = '#ffc669';
export const namiBrightGreen = '#20ba44';
export const namiBrightBlue = '#009FDE';
export const namiDarkRed = '#a80b1a';
export const namiLightRed = '#fcd2d7';
export const namiDarkOrange = '#AD6800';
export const namiLightOrange = '#FAAD14';
// neutral
export const namiBlack = '#000000';
export const namiDarkestGray = '#090a0b';
export const namiDarkGray = '#4c545a';
export const namiMediumGray = '#8c8c8c';
export const namiLightGray = '#dddddd';
export const namiSmoke = '#eff4f7';
export const namiAliceGray = '#f5f8fa';

export const colorPrimary = namiPrimaryBlue;
export const colorPrimaryHover = namiSecondaryBlue;
export const colorPrimaryDark = namiNavy;
export const colorDarkGrey = namiDarkGray;

export const colorWhite = namiPureWhite;
export const colorBlack = namiBlack;
export const colorLighterGrey = namiAliceGray;
export const colorLightGrey = namiLightGray;

export const colorSuccess = namiGreen;
export const colorSuccessHover = `darken(${namiGreen}, 20%)`;
export const colorWarning = namiYellow;
export const colorWarningHover = `darken(${namiYellow}, 20%)`;
export const colorDanger = namiCoral;
export const colorDangerHover = `darken(${namiCoral}, 35%)`;
export const colorNotice = namiSecondaryBlue;
export const colorNoticeHover = `darken(${namiSecondaryBlue}, 20%)`;

export const colorNeutral = '#a2a2a2';
export const colorNegative = '#52595d';

export const background = namiAliceGray;

export const breakpointXxl = '1400px';
export const breakpointXl = '1200px';
export const breakpointLg = '992px';
export const breakpointMd = '767px';
export const breakpointSm = '480px';
export const breakpointXs = '320px';

export const colorAnalyticSuccessBg = 'rgba(18, 196, 87, 0.1)';
export const colorAnalyticSuccessText = namiGreen;
export const colorAnalyticDangerBg = 'rgba(232, 76, 133, 0.1)';
export const colorAnalyticDangerText = namiCoral;

// Ant Design Variables
export const primaryColor = '#1374de';
export const errorColor = '#a8001a';
export const fontFamily = `Gilroy, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`;
export const codeFamily = `'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono', Menlo, Courier, monospace`;
