import { Radio } from 'antd';
import {
  colorWhite,
  namiDarkGray,
  namiDarkestGray,
  namiSmoke,
} from 'src/variables';
import styled from 'styled-components';

const SegmentStyledRadio = styled(Radio.Group)`
  background-color: ${namiSmoke};
  border-radius: 3px;
  padding: 2px;

  .ant-radio-button-wrapper {
    background-color: ${namiSmoke};
    border: unset;
    padding: 0px 12px;
    height: 28px;
    font-size: 13px;
    color: ${namiDarkGray};
  }

  .ant-radio-button-wrapper-checked {
    background-color: ${colorWhite};
    border: unset;
    box-shadow: 0 2px 8px -2px #0000000d, 0 1px 4px -1px #00000012,
      0 0 1px #00000014;
  }

  .ant-radio-button-wrapper:hover {
    color: #000;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #000;
  }

  .ant-radio-button-wrapper:first-child {
    border: unset;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: ${namiSmoke};
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: ${namiDarkestGray};
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: ${namiSmoke};
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 2px 8px -2px #0000000d, 0 1px 4px -1px #00000012,
      0 0 1px #00000014;
  }
`;

export default SegmentStyledRadio;
