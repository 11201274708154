import { apiRequest } from '../../services/apiService';
import * as TYPES from '../types';

const param = (array = []) => {
  if (array && array.length) {
    let url = '';
    array.forEach((item) => {
      url += '&' + item.name + '=' + item.value;
    });
    return (url = url.trim());
  }
  return '';
};

export const getCrmUsers = (id, pageNumber, array) => {
  return apiRequest({
    type: TYPES.GET_CRM_USERS,
    url: `/api/v1/app/${id}/users/?page=${pageNumber}&order=-created${param(
      array
    )}`,

    method: 'GET',
  });
};

export const getCrmUser = (id) => {
  return apiRequest({
    type: TYPES.GET_CRM_USER,
    url: `/api/v1/user/${id}/`,

    method: 'GET',
  });
};

export const getCrmUserEvents = (id, pageNumber) => {
  return apiRequest({
    type: TYPES.GET_CRM_USER_EVENTS,
    url: `/api/v1/user/${id}/events/?page=${pageNumber}&order=-updated&event_type__istartswith=user`,

    method: 'GET',
  });
};

export const getCrmUserRevenues = (id, pageNumber) => {
  return apiRequest({
    type: TYPES.GET_CRM_USER_REVENUES,
    url: `/api/v1/user/${id}/revenue/?page=${pageNumber}&order=-created`,

    method: 'GET',
  });
};
