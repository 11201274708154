import { TimeDimensionGranularity } from '@cubejs-client/core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TListResult } from 'src/api/types/main.types';
import { PaywallType } from 'src/api/types/paywall.types';
import { TProduct } from 'src/api/types/sku.types';
import { TInsightType } from 'src/pages/admin/insights/InsightsPage';
import { momentCalculator } from 'src/utils/date';

import {
  DateRangeType,
  FilterOptions,
  SegmentOptions,
  SegmentType,
  TAppliedFilters,
  TEnvironmentOption,
  TMetricFilter,
  TMetricFilterRule,
} from '../api/types/analytics.types';
import {
  TCampaignLabel,
  TCampaignRule,
  TCampaignRuleAndFullLabel,
} from '../api/types/campaign.types';
type AnalyticsState = {
  insightType: TInsightType | null;
  placeholderState: boolean;
  interval: TimeDimensionGranularity;
  dateRangeCampaignMetrics: DateRangeType;
  dateRangeInsights: DateRangeType;
  environment: TEnvironmentOption;
  segment: SegmentType | null;
  filters: { [key: string]: TMetricFilterRule };
  products: { [productId: string]: TProduct };
  campaigns: { [ruleId: string]: TCampaignRule };
  placements: { [labelId: string]: TCampaignLabel };
  paywalls: { [paywallId: string]: PaywallType };
  hasChanges: boolean;
  timezone: string;
};

const initialState: AnalyticsState = {
  insightType: null,
  placeholderState: true,
  interval: 'day',
  dateRangeCampaignMetrics: [
    momentCalculator('UTC', -7, 'day', 'day'),
    momentCalculator('UTC', 0, 'day', undefined, 'day'),
  ],
  dateRangeInsights: [
    momentCalculator('UTC', -30, 'day', 'day'),
    momentCalculator('UTC', 0, 'day', undefined, 'day'),
  ],
  environment: { value: 'production' },
  segment: null,
  filters: {},
  products: {},
  campaigns: {},
  placements: {},
  paywalls: {},
  hasChanges: false,
  timezone: 'UTC',
};

export default createSlice({
  name: 'Analytics',
  initialState,
  reducers: {
    resetState(state) {
      state.insightType = null;
      state.environment = { value: 'production' };
      state.interval = 'day';
      state.dateRangeCampaignMetrics = [
        momentCalculator('UTC', -7, 'day', 'day'),
        momentCalculator('UTC', 0, 'day', undefined, 'day'),
      ];
      state.dateRangeInsights = [
        momentCalculator('UTC', -30, 'day', 'day'),
        momentCalculator('UTC', 0, 'day', undefined, 'day'),
      ];
      state.segment = null;
      state.filters = {};
      state.timezone = 'UTC';
    },
    initializeState(
      state,
      {
        payload: {
          insightType,
          interval,
          environment,
          dateRange,
          segment,
          filters,
          products,
          campaigns,
          placements,
          paywalls,
          timezone,
        },
      }: PayloadAction<{
        insightType: TInsightType;
        interval: TimeDimensionGranularity;
        environment: TEnvironmentOption;
        dateRange: DateRangeType;
        segment: SegmentType;
        filters: TAppliedFilters;
        products: TListResult<TProduct>;
        campaigns: TListResult<TCampaignRuleAndFullLabel>;
        placements: TListResult<TCampaignLabel>;
        paywalls: TListResult<PaywallType>;
        timezone: string;
      }>
    ) {
      state.insightType = insightType;
      state.interval = interval;
      state.environment = environment;
      state.dateRangeInsights = dateRange;
      state.dateRangeCampaignMetrics = [
        momentCalculator(timezone, -7, 'day', 'day'),
        momentCalculator(timezone, 0, 'day', undefined, 'day'),
      ];
      state.segment = segment;
      state.filters = filters;
      state.products = products.results.reduce((output, product) => {
        return { ...output, [product.id]: product };
      }, {});
      state.campaigns = campaigns.results.reduce((output, campaign) => {
        return { ...output, [campaign.id]: campaign };
      }, {});
      state.placements = placements.results.reduce((output, placement) => {
        return { ...output, [placement.id]: placement };
      }, {});
      state.paywalls = paywalls.results.reduce((output, paywall) => {
        return { ...output, [paywall.id]: paywall };
      }, {});
      state.placeholderState = false;
      state.timezone = timezone;
    },
    setInsightType(state, { payload: type }: PayloadAction<TInsightType>) {
      state.insightType = type;
    },
    setInterval(
      state,
      { payload: interval }: PayloadAction<TimeDimensionGranularity>
    ) {
      state.hasChanges = true;
      state.interval = interval;
    },
    setEnvironment(
      state,
      { payload: environment }: PayloadAction<TEnvironmentOption>
    ) {
      state.hasChanges = true;
      state.environment = environment;
    },
    setDateRangeInsights(
      state,
      { payload: dateRange }: PayloadAction<DateRangeType>
    ) {
      state.hasChanges = true;
      state.dateRangeInsights = dateRange;
    },
    setTimezone(state, { payload: timezone }: PayloadAction<string>) {
      state.hasChanges = false;
      state.timezone = timezone;
    },
    setSegment(state, { payload: segment }: PayloadAction<SegmentType>) {
      state.hasChanges = true;
      state.segment = segment;
    },
    setFilters(state, { payload: filters }: PayloadAction<TAppliedFilters>) {
      state.hasChanges = true;
      state.filters = filters;
    },
    updateFiltersAndSegment(
      state,
      {
        payload: { availableSegments, availableFilters, hasEntitlement },
      }: PayloadAction<{
        availableSegments: NonNullable<SegmentType>[];
        availableFilters: TMetricFilter[];
        hasEntitlement: (entitlement: string) => boolean;
      }>
    ) {
      if (state.segment) {
        const isValid =
          availableSegments.includes(state.segment) &&
          hasEntitlement(SegmentOptions[state.segment].entitlement);
        state.hasChanges = true;
        state.segment = isValid ? state.segment : null;
      }

      const keyValues = Object.entries(state.filters);
      if (keyValues.length > 0) {
        state.hasChanges = true;
        state.filters = keyValues.reduce((output, [key, value]) => {
          const isValid =
            availableFilters.includes(value.identifier) &&
            hasEntitlement(FilterOptions[value.identifier].entitlement);
          return isValid ? { ...output, [key]: value } : output;
        }, {});
      }
    },
    setProducts(
      state,
      { payload: response }: PayloadAction<TListResult<TProduct>>
    ) {
      state.products = response.results.reduce((output, product) => {
        return { ...output, [product.id]: product };
      }, {});
    },
    setPlacements(
      state,
      { payload: response }: PayloadAction<TListResult<TCampaignLabel>>
    ) {
      state.placements = response.results.reduce((output, placement) => {
        return { ...output, [placement.id]: placement };
      }, {});
    },
    setCampaigns(
      state,
      { payload: response }: PayloadAction<TListResult<TCampaignRule>>
    ) {
      state.campaigns = response.results.reduce((output, rule) => {
        return { ...output, [rule.id]: rule };
      }, {});
    },
    setPaywalls(
      state,
      { payload: response }: PayloadAction<TListResult<PaywallType>>
    ) {
      state.paywalls = response.results.reduce((output, paywall) => {
        return { ...output, [paywall.id]: paywall };
      }, {});
    },
  },
});
