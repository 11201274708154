import React from 'react';

import { LockOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, notification } from 'antd';
import { PasswordType } from 'src/api/types/main.types';

import api from '../../../../../api';
import { useAppContext } from '../../../../../hooks';

export default function ChangePasswordForm() {
  const [form] = Form.useForm<PasswordType>();
  const context = useAppContext();

  return (
    <Form form={form} layout="vertical" requiredMark={false}>
      <Row gutter={16}>
        <Col xs={24}>
          <Form.Item
            name="currentPassword"
            label="Existing Password"
            rules={[
              {
                required: true,
                message: 'Please provide your existing password.',
              },
            ]}
          >
            <Input type="password" prefix={<LockOutlined />} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                message: 'Please provide a new password.',
              },
              {
                type: 'string',
                min: 8,
                message: 'Your password must be at least 8 characters long.',
              },
              {
                type: 'string',
                max: 255,
                message: 'Your password is too long',
              },
              () => ({
                validator(_, value) {
                  if (/[A-Z]/.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'Your password must contain at least 1 uppercase character.'
                    )
                  );
                },
              }),
              () => ({
                validator(_, value) {
                  if (/[a-z]/.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'Your password must contain at least 1 lowercase character.'
                    )
                  );
                },
              }),
              () => ({
                validator(_, value) {
                  if (/[0-9]/.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Your password must contain at least 1 number.')
                  );
                },
              }),
            ]}
          >
            <Input type="password" prefix={<LockOutlined />} />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="confirmNewPassword"
            label="Confirm New Password"
            rules={[
              {
                required: true,
                message: 'Please provide a new password.',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match.'
                    )
                  );
                },
              }),
            ]}
          >
            <Input type="password" prefix={<LockOutlined />} />
          </Form.Item>
        </Col>
      </Row>
      <Button
        type="primary"
        onClick={() => form.validateFields().then(() => formSubmit())}
      >
        Change Password
      </Button>
    </Form>
  );

  function formSubmit() {
    const payload: PasswordType = form.getFieldsValue();
    api.changePassword(payload).then(() => {
      context.performLogout();
      notification.success({ message: 'Updated password successfully.' });
    });
  }
}
