import React from 'react';

import { connect } from 'react-redux';
import { Checkbox, Form, Dropdown, Segment } from 'semantic-ui-react';

import ColorPickerLegacy from '../../../../components/ColorPickerLegacy/ColorPickerLegacy';
import {
  handlePaywallColor,
  handlePaywallSize,
} from '../../../../redux/actions/paywallActions';

const Controls = ({
  paywall,
  paywallStyles,
  handlePaywallColor,
  handlePaywallSize,
}) => {
  const {
    signin_button_font_size,
    signin_button_text_color,
    signin_button_shadow_radius,
    signin_button_shadow_color,
    restore_button_font_size,
    restore_button_text_color,
    restore_button_shadow_radius,
    restore_button_shadow_color,
  } = paywallStyles;

  let fontSizeOptions = [
    { value: 8, text: '8px' },
    { value: 9, text: '9px' },
    { value: 10, text: '10px' },
    { value: 11, text: '11px' },
    { value: 12, text: '12px' },
    { value: 13, text: '13px' },
    { value: 14, text: '14px' },
    { value: 15, text: '15px' },
    { value: 16, text: '16px' },
    { value: 17, text: '17px' },
    { value: 18, text: '18px' },
  ];

  const radiusOptions = [
    { value: 1, text: '1px' },
    { value: 2, text: '2px' },
    { value: 3, text: '3px' },
    { value: 4, text: '4px' },
    { value: 5, text: '5px' },
    { value: 6, text: '6px' },
    { value: 7, text: '7px' },
    { value: 8, text: '8px' },
  ];

  return (
    <Segment basic>
      <Form.Field>
        <label>Sign In</label>
      </Form.Field>
      <Form.Group inline>
        <Form.Field>
          <Dropdown
            compact
            selection
            options={fontSizeOptions}
            value={signin_button_font_size}
            onChange={(e, { value }) =>
              handlePaywallSize('signin_button_font_size', value)
            }
          />
        </Form.Field>
        <Form.Field>
          <ColorPickerLegacy
            paywall={paywall}
            color={signin_button_text_color}
            onChange={(color) =>
              handlePaywallColor('signin_button_text_color', color)
            }
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <Checkbox
          label="Shadow"
          slider
          checked={signin_button_shadow_radius === 0 ? false : true}
          onChange={(e) =>
            handlePaywallSize(
              'signin_button_shadow_radius',
              signin_button_shadow_radius === 0 ? 1 : 0
            )
          }
        />
      </Form.Field>
      {!!signin_button_shadow_radius && (
        <Form.Group inline style={{ paddingBottom: 20 }}>
          <Form.Field>
            <Dropdown
              compact
              selection
              options={radiusOptions}
              value={signin_button_shadow_radius}
              onChange={(e, { value }) =>
                handlePaywallSize('signin_button_shadow_radius', value)
              }
            />
            <div
              style={{
                position: 'absolute',
                marginTop: 5,
                fontSize: 12,
              }}
            >
              Radius
            </div>
          </Form.Field>
          <Form.Field>
            <ColorPickerLegacy
              paywall={paywall}
              color={signin_button_shadow_color}
              onChange={(color) =>
                handlePaywallColor('signin_button_shadow_color', color)
              }
            />
          </Form.Field>
        </Form.Group>
      )}
      <Form.Field>
        <label>Restore</label>
      </Form.Field>
      <Form.Group inline>
        <Form.Field>
          <Dropdown
            compact
            selection
            options={fontSizeOptions}
            value={restore_button_font_size}
            onChange={(e, { value }) =>
              handlePaywallSize('restore_button_font_size', value)
            }
          />
        </Form.Field>
        <Form.Field>
          <ColorPickerLegacy
            paywall={paywall}
            color={restore_button_text_color}
            onChange={(color) =>
              handlePaywallColor('restore_button_text_color', color)
            }
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <Checkbox
          label="Shadow"
          slider
          checked={restore_button_shadow_radius === 0 ? false : true}
          onChange={(e) =>
            handlePaywallSize(
              'restore_button_shadow_radius',
              restore_button_shadow_radius === 0 ? 1 : 0
            )
          }
        />
      </Form.Field>
      {!!restore_button_shadow_radius && (
        <Form.Group inline style={{ paddingBottom: 20 }}>
          <Form.Field>
            <Dropdown
              compact
              selection
              options={radiusOptions}
              value={restore_button_shadow_radius}
              onChange={(e, { value }) =>
                handlePaywallSize('restore_button_shadow_radius', value)
              }
            />
            <div
              style={{
                position: 'absolute',
                marginTop: 5,
                fontSize: 12,
              }}
            >
              Radius
            </div>
          </Form.Field>
          <Form.Field>
            <ColorPickerLegacy
              paywall={paywall}
              color={restore_button_shadow_color}
              onChange={(color) =>
                handlePaywallColor('restore_button_shadow_color', color)
              }
            />
          </Form.Field>
        </Form.Group>
      )}
    </Segment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlePaywallColor: (key, color) =>
      dispatch(handlePaywallColor(key, color)),
    handlePaywallSize: (key, value) => dispatch(handlePaywallSize(key, value)),
  };
};

const mapStateToProps = ({ paywall }) => {
  return {
    paywall: paywall.paywall,
    paywallStyles: paywall.paywallStyles,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Controls);
