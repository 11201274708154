import React, { useMemo, useState } from 'react';

import { Button, Modal, Select, Typography } from 'antd';
import {
  TAllComponentDetailValues,
  TComponent,
  TRepeatingList,
} from 'src/api/types/paywallTemplate.types';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { deconstructVariable } from 'src/utils/paywall';
import styled from 'styled-components';

type SelectSchemaSourceModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (dataSource: string) => void;
  type: TAllComponentDetailValues | null;
  parentRepeatingGrid: TComponent | null;
};

const StyledSelect = styled(Select)`
  width: 100%;
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
  font-size: 13px;
  margin-bottom: 24px;
`;

export default function SelectSchemaSourceModal({
  isOpen,
  onClose,
  onSubmit,
  type,
  parentRepeatingGrid,
}: SelectSchemaSourceModalProps) {
  const { customObjectSchema } = useAppSelector(({ paywallBuilder }) => {
    return {
      customObjectSchema: paywallBuilder.customObjectSchema,
    };
  });
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectedSchemaField, setSelectedSchemaField] = useState<string | null>(
    null
  );

  const parentDataSourceRoot = parentRepeatingGrid
    ? deconstructVariable(
        ((parentRepeatingGrid as TRepeatingList).loopSource as string) || '',
        false,
        true
      )
    : null;

  const selectOptions = useMemo(() => {
    return customObjectSchema
      .filter((value) => {
        if (type === 'repeatingList') {
          return value.type === 'array';
        } else {
          return (
            value.type !== 'array' &&
            value.type !== 'boolean' &&
            value.title.includes(parentDataSourceRoot || '')
          );
        }
      })
      .map((value) => {
        return {
          label: parentDataSourceRoot
            ? value.title.replace(`${parentDataSourceRoot}.`, 'Current cell ')
            : value.title,
          value: `\${launch.${value.title}}`,
        };
      });
  }, [type, customObjectSchema, parentDataSourceRoot]);

  return (
    <Modal
      title="Select Data Source"
      open={isOpen}
      footer={null}
      centered
      closable={true}
      zIndex={1005}
      onCancel={onClose}
      forceRender
      destroyOnClose
    >
      <>
        <Typography.Paragraph>
          Select an data source from your custom object schema
        </Typography.Paragraph>
        <StyledSelect
          options={selectOptions}
          value={selectedSchemaField}
          showSearch={true}
          open={selectOpen}
          onDropdownVisibleChange={(visible) => setSelectOpen(visible)}
          filterOption={(input, option) =>
            (option?.value || '').toLowerCase().includes(input.toLowerCase())
          }
          onChange={(newId) => setSelectedSchemaField(newId as string | null)}
        />
        <Button
          htmlType="submit"
          type="primary"
          disabled={!selectedSchemaField}
          onClick={() => {
            if (selectedSchemaField) onSubmit(selectedSchemaField);
          }}
        >
          Submit
        </Button>
      </>
    </Modal>
  );
}
