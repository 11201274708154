import React from 'react';

import { Form, Button, Modal } from 'semantic-ui-react';

const RecoveryCodesModal = ({ open, onClose, onSuccess, codes, copy }) => {
  return (
    <Modal
      dimmer="blurring"
      closeOnDimmerClick={false}
      onClose={onClose}
      size="mini"
      open={open}
    >
      <Modal.Content>
        <Modal.Header as="h3">2FA Recovery Codes</Modal.Header>
        <Modal.Description>
          <p>
            You can use these one-time backup codes to access your account.{' '}
            <strong>
              Please copy and save them in a safe place as you will not be able
              to see these codes again.
            </strong>
          </p>
          <Form onSubmit={onSuccess}>
            <Form.TextArea placeholder="codes" value={codes} />
            <Button className="nami-primary-button" type="submit">
              Copy
            </Button>
            <Button disabled={copy} onClick={onClose}>
              Close
            </Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default RecoveryCodesModal;
