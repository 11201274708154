import React from 'react';

import type { RadioChangeEvent } from 'antd';
import { Radio, Tooltip } from 'antd';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import styled from 'styled-components';

import { ReactComponent as DarkModeIcon } from '../../../../images/darkmode.svg';
import { ReactComponent as LightModeIcon } from '../../../../images/lightmode.svg';
import type { RootState } from '../../../../redux';

const SmallRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    padding: 0 10px !important;
  }
`;

export default function PaywallDarkModePicker() {
  const inDarkMode = useAppSelector(
    ({ paywallBuilder: { inDarkMode } }: RootState) => inDarkMode
  );
  const actions = useActions(PaywallBuilderSlice.actions);

  const pageOptions = [
    {
      label: <LightModeIcon style={{ transform: 'translateY(3px)' }} />,
      value: false,
    },
    {
      label: <DarkModeIcon style={{ transform: 'translateY(3px)' }} />,
      value: true,
    },
  ];
  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    changeDarkMode(value);
  };

  return (
    <Tooltip title="Toggle Dark Mode" mouseEnterDelay={0.75}>
      <SmallRadioGroup
        optionType="button"
        buttonStyle="outline"
        options={pageOptions}
        onChange={onChange}
        value={inDarkMode}
        size="small"
      />
    </Tooltip>
  );

  function changeDarkMode(inDarkMode: boolean) {
    actions.setDarkMode(inDarkMode);
  }
}
