/* eslint-disable no-template-curly-in-string */
import React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import {
  TComponentLocation,
  TProductContainer,
} from 'src/api/types/paywallTemplate.types';
import { useActions } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

type SafeAreaApplicationProps = {
  component: TProductContainer;
  componentLocation: TComponentLocation;
};

export default function SafeAreaApplication({
  component,
  componentLocation,
}: SafeAreaApplicationProps) {
  const actions = useActions(PaywallBuilderSlice.actions);

  if (
    component &&
    (component as any).topMargin !== '${state.safeAreaTop}' &&
    (component as any).topPadding !== '${state.safeAreaTop}'
  ) {
    if (
      component.namiComponentType === 'group' ||
      component.namiComponentType === 'spacer'
    ) {
      return (
        <Button
          icon={<PlusOutlined />}
          size="small"
          style={{ marginLeft: 4, marginTop: 8 }}
          onClick={() =>
            actions.addSafeAreaMarginToComponent({
              location: componentLocation,
            })
          }
        >
          Add Safe Area
        </Button>
      );
    }
  }
  return null;
}
