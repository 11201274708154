const dataAvailable = (data) => {
  if (data) {
    return { data: JSON.stringify(data) };
  }
};
const optionsAvailable = (options) => {
  if (options) {
    return { options: options };
  }
};
const metaAvailable = (meta) => {
  if (meta) {
    return { meta: meta };
  }
};

export const apiRequest = ({
  type,
  client,
  meta,
  url,
  method,
  data,
  options,
  token,
}) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (token) {
    headers.Authorization = token;
  }
  return {
    type,
    ...metaAvailable(meta),
    payload: {
      client,
      request: {
        url,
        headers,
        method: method,
        ...dataAvailable(data),
      },
      ...optionsAvailable(options),
    },
  };
};
