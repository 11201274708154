import { useEffect } from 'react';

import * as Sentry from '@sentry/browser';
import SentryFullStory from '@sentry/fullstory';
import Proptypes from 'prop-types';

import { ENVIRONMENT, SEGMENT_WRITE_KEY, SENTRY_DSN } from '../../config';

const SegmentIO = ({ children }) => {
  useEffect(() => {
    let analytics = (window.analytics = window.analytics || []);
    if (analytics.initialize) return;
    if (analytics.invoked) return;
    analytics.invoked = true;
    analytics.methods = [
      'trackSubmit',
      'trackClick',
      'trackLink',
      'trackForm',
      'pageview',
      'identify',
      'reset',
      'group',
      'track',
      'ready',
      'alias',
      'debug',
      'page',
      'once',
      'off',
      'on',
    ];
    analytics.factory = (t) => {
      return () => {
        let e = Array.prototype.slice.call([]);
        e.unshift(t);
        analytics.push(e);
        return analytics;
      };
    };
    for (let t = 0; t < analytics.methods.length; t++) {
      let e = analytics.methods[t];
      analytics[e] = analytics.factory(e);
    }
    analytics.load = (t, e) => {
      let n = document.createElement('script');
      n.type = 'text/javascript';
      n.async = !0;
      n.src =
        'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js';
      let a = document.getElementsByTagName('script')[0];
      a.parentNode.insertBefore(n, a);
      analytics._loadOptions = e;
    };
    analytics.ready = () => {
      Sentry.init({
        environment: ENVIRONMENT,
        dsn: SENTRY_DSN,
        integrations: [new SentryFullStory('nami-ml')],
      });
    };
    analytics.SNIPPET_VERSION = '4.1.0';
    analytics.load(SEGMENT_WRITE_KEY);
    analytics.ready();
  }, []);

  return children;
};

SegmentIO.propTypes = {
  children: Proptypes.oneOfType([
    Proptypes.arrayOf(Proptypes.node),
    Proptypes.node,
  ]),
};

export default SegmentIO;
