import React, { useMemo } from 'react';

import { LockOutlined } from '@ant-design/icons';
import { Button, Col, Form, Popover, Row } from 'antd';
import { TTemplateIcon } from 'src/api/types/paywall.types';
import IconPickerWebPaywall from 'src/components/WebPaywalls/IconPickerWebPaywall';
import { useAppContext, useBooleanState } from 'src/hooks';
import { namiAliceGray, namiDarkGray } from 'src/variables';
import styled from 'styled-components';

import AntIcon from '../../../../../../components/AntIcon';
import { useTemplateIconsQuery } from '../../../../../../hooks/queries/paywall.hooks';
import { FieldObject } from '../../../utils/formFieldBuilding';
import { UpgradeButton } from './FontSelect';
import InputLabel from './InputLabel';

type IconSelectorProps = Omit<FieldObject, 'variable'> & {
  removable?: boolean;
  defaultValue?: string | number;
  value?: string | null;
  onChange: (value: any) => void;
  label: string;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
};

const IconPickerButton = styled(Button)`
  &,
  &:hover,
  &:focus {
    flex-grow: 1;
    border: 1px solid rgb(229, 229, 229);
  }
`;

const IconButton = styled(Button)`
  color: ${namiDarkGray};
  &:hover {
    background-color: ${namiAliceGray};
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const LabelHelperText = styled.p`
  font-style: italic;
`;

export default function IconSelect({
  removable,
  onChange,
  value,
  ...field
}: IconSelectorProps) {
  const iconsQuery = useTemplateIconsQuery();
  const { userHasEntitlement } = useAppContext();
  const canEdit = userHasEntitlement('app.paywall.capabilities.icon_picker');
  const [isUpgradeOpen, openUpgrade, closeUpgrade] = useBooleanState(false);

  const icons = useMemo((): Record<string, TTemplateIcon> => {
    return (iconsQuery.data || []).reduce((output, icon) => {
      return { ...output, [`\${icon.${icon.name}}`]: icon };
    }, {} as Record<string, TTemplateIcon>);
  }, [iconsQuery.data]);

  return (
    <>
      <Form.Item
        label={<InputLabel {...field} />}
        tooltip={
          canEdit ? null : (
            <UpgradeButton
              size="small"
              type="link"
              onClick={openUpgrade}
              icon={<LockOutlined />}
            >
              Upgrade for access
            </UpgradeButton>
          )
        }
      >
        <Wrapper className="ant-btn-group">
          <Popover
            overlayStyle={{ padding: 0, width: '300px' }}
            overlayInnerStyle={{ width: '300px' }}
            trigger="click"
            content={
              <>
                <Row justify="start">
                  {Object.entries(icons).map(([value, icon]) => (
                    <Col key={icon.name} xs={4}>
                      <IconButton
                        type="text"
                        size="middle"
                        icon={<AntIcon name={icon.ant} />}
                        disabled={!canEdit}
                        onClick={() => canEdit && handleChange(value)}
                      />
                    </Col>
                  ))}
                  {removable && (
                    <Col key={'none'} xs={4}>
                      <IconButton
                        type="text"
                        size="middle"
                        icon={undefined}
                        disabled={!canEdit}
                        onClick={() => canEdit && handleChange(null)}
                      >
                        None
                      </IconButton>
                    </Col>
                  )}
                </Row>
                <Row style={{ marginTop: '6px' }}>
                  <LabelHelperText>
                    Previews only. Icons will vary by platform.
                  </LabelHelperText>
                </Row>
              </>
            }
          >
            <IconPickerButton icon={getAntIcon(value)}>
              {buildLabel()}
            </IconPickerButton>
          </Popover>
        </Wrapper>
      </Form.Item>
      <IconPickerWebPaywall visible={isUpgradeOpen} onCancel={closeUpgrade} />
    </>
  );

  function buildLabel() {
    if (iconsQuery.isFetching) return 'Loading';
    if (value) return icons[value].display_name;
    return 'None';
  }

  function handleChange(value: string | null): void {
    if (!field.editable) return;
    onChange(value);
  }

  function getAntIcon(value: string | null): React.ReactNode {
    if (iconsQuery.isFetching || !value || !(value in icons)) return null;
    return <AntIcon name={icons[value].ant} />;
  }
}
