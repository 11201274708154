import { apiRequest } from '../../services/apiService';
import history from '../../services/history';
import * as TYPES from '../types';

export const getCampaigns = (appId) => {
  return apiRequest({
    type: TYPES.GET_CAMPAIGNS,
    url: `/private/campaigns/?app_id=${appId}`,
    method: 'GET',
  });
};

export const getCampaign = (campaignId) => {
  return apiRequest({
    type: TYPES.GET_CAMPAIGN,
    url: `/private/campaigns/${campaignId}/`,
    method: 'GET',
  });
};

export const deleteCampaign = (campaignId) => {
  return apiRequest({
    type: TYPES.DELETE_CAMPAIGN,
    url: `/private/campaigns/${campaignId}/`,
    method: 'DELETE',
  });
};

export const addCampaign = (data) => {
  return apiRequest({
    type: TYPES.ADD_CAMPAIGN,
    url: `/private/campaigns/`,
    method: 'POST',
    data,
    options: {
      onSuccess({ next, response }) {
        next({ type: TYPES.ADD_CAMPAIGN_SUCCESS, payload: response });
        history.push(`/campaigns/${response.data.id}`);
      },
    },
  });
};

export const updateCampaign = (campaignId, data) => {
  return apiRequest({
    type: TYPES.UPDATE_CAMPAIGN,
    meta: {
      segment: {
        event: 'Campaign Updated',
        context: data,
      },
    },
    url: `/private/campaigns/${campaignId}/`,
    method: 'PUT',
    data,
  });
};

export const campaignLive = (campaignId) => {
  return apiRequest({
    type: TYPES.CAMPAIGN_LIVE,
    url: `/private/campaigns/${campaignId}/activate/`,
    method: 'POST',
  });
};

export const campaignOffline = (appId) => {
  return apiRequest({
    type: TYPES.CAMPAIGN_OFFLINE,
    url: `/private/apps/${appId}/`,
    method: 'PATCH',
    data: { active_campaign: null },
  });
};

export const handleCampaignField = (newData, changes = true) => {
  return {
    type: TYPES.HANDLE_CAMPAIGN_FIELD,
    payload: {
      campaign: newData,
      changes,
    },
  };
};
