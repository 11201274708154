import React, { useContext, useEffect } from 'react';

import { Col, Row, Typography } from 'antd';
import api from 'src/api';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import AnalyticsSlice from 'src/redux/AnalyticsSlice';

import { AppContext } from '../../../../AppContextProvider';
import { PAGES, TInsightType } from '../../insights/InsightsPage';
import CampaignCard from './CampaignCard';
import CampaignInsightsCard from './CampaignInsightsCard';

type CampaignMetricCardsProps = {
  loading: boolean;
  hasSegment: boolean;
};

export default function CampaignMetricCards({
  loading,
  hasSegment,
}: CampaignMetricCardsProps) {
  const { userHasEntitlement } = useContext(AppContext);
  const appId = useAppSelector(({ root }) => root.currentApp?.id);
  const actions = useActions(AnalyticsSlice.actions);
  useEffect(() => {
    api.getPaywalls(appId, { pageSize: 1000 }).then(actions.setPaywalls);
  }, [appId, actions]);

  const overviewCards: TInsightType[] = [
    'revenue',
    'impressions',
    'paywalls-conversion-rate',
  ];

  return (
    <CampaignCard
      title="Campaign Reporting"
      type="inner"
      className="intercom-campaignReporting"
      loading={loading}
      id="reporting"
    >
      <Row>
        {overviewCards.map(
          (card) =>
            userHasEntitlement(PAGES[card].entitlement) && (
              <Col lg={8} xs={24} md={12} key={card}>
                <CampaignInsightsCard
                  title={PAGES[card].title}
                  metric={card}
                  applySegment={hasSegment}
                  chartKeys={PAGES[card].chartKeys}
                  dataGetter={PAGES[card].dataFetcher}
                  totalGetter={PAGES[card].totalFetcher}
                  dataParser={PAGES[card].dataParser}
                  totalDataParser={PAGES[card].totalDataParser}
                />
              </Col>
            )
        )}
      </Row>
      <Row justify="end">
        <Typography.Paragraph
          style={{ fontStyle: 'italic', fontSize: 'small' }}
        >
          Data from last 7 days, production environments only.
        </Typography.Paragraph>
      </Row>
    </CampaignCard>
  );
}
