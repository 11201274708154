import React from 'react';

import { ReadOutlined } from '@ant-design/icons';
import { createSelector } from '@reduxjs/toolkit';
import { Button, Popover } from 'antd';
import NamiIcon from 'src/components/NamiIcon/NamiIcon';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import { namiBrightBlue } from 'src/variables';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  svg {
    font-size: 20px;
    :hover {
      path {
        fill: ${namiBrightBlue};
      }
    }
  }
  transition: all 1s;
`;

const selector = createSelector(
  [({ paywallBuilder }: RootState) => paywallBuilder],
  (paywallBuilder) => ({
    minSDKVersion: paywallBuilder.minSDKVersion,
  })
);

export default function PaywallHeaderSDKVersion() {
  const { minSDKVersion } = useAppSelector(selector);

  return (
    <Popover
      overlayStyle={{ width: 300 }}
      content={
        <>
          <div>
            This paywall requires a minimum Nami SDK Version of{' '}
            <span style={{ fontWeight: 500 }}>{minSDKVersion.semver}</span>
          </div>
          <Button
            type="link"
            size="small"
            href="https://learn.namiml.com/public-docs/get-started/sdks/release-notes"
            target="_blank"
            icon={<ReadOutlined />}
          >
            Release Notes
          </Button>
        </>
      }
    >
      <StyledButton
        type="text"
        className="intercom-builder-warnings"
        icon={<NamiIcon icon={'sdk'} size={'tiny'} color="default" />}
        style={{ marginRight: 3 }}
      />
    </Popover>
  );
}
