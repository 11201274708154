import React, { useState } from 'react';

import { Button, Form, Image, Input, Modal, Select, Space } from 'antd';

import { TProductPayload } from '../../../api/types/sku.types';
import { usePlatformsQuery } from '../../../hooks/queries/platform.hooks';
import { useAddProductMutation } from '../../../hooks/queries/product.hooks';
import { platformLogo } from '../../../services/helpers';

type NewProductModalProps = { isOpen: boolean; onClose: () => void };

const productTypeOptions = [
  { label: 'Subscription', value: 'subscription' },
  { label: 'One Time Purchase', value: 'one_time_purchase' },
].map((option) => ({ ...option, key: option.value }));

export default function NewProductModal({
  isOpen,
  onClose,
}: NewProductModalProps) {
  const [form] = Form.useForm<TProductPayload>();
  const [formErrors, setFormErrors] = useState(false);
  const platformsQuery = usePlatformsQuery();
  const newProductMutation = useAddProductMutation(() => {
    onClose();
    form.resetFields();
  });

  const checkForFieldErrors = () => {
    setFormErrors(form.getFieldsError().some(({ errors }) => errors.length));
  };

  const defaultPlatformId = platformsQuery.data?.length
    ? platformsQuery.data[0].id
    : '';
  const platformOptions = (platformsQuery.data || []).map((platform) => ({
    value: platform.id,
    label: (
      <Space>
        <Image
          height={25}
          width={25}
          preview={false}
          src={platformLogo(platform.type)}
        />
        {platform.name}
      </Space>
    ),
  }));

  return (
    <Modal
      title="Add a New Product"
      open={isOpen}
      onCancel={onClose}
      closable={false}
      footer={null}
      confirmLoading={newProductMutation.isLoading}
    >
      <Form
        layout="vertical"
        form={form}
        requiredMark={false}
        initialValues={{
          platform: defaultPlatformId,
          sku_type: 'subscription',
        }}
        onFieldsChange={checkForFieldErrors}
      >
        <Form.Item
          label="Platform"
          name="platform"
          className="intercom-platformSelector"
        >
          <Select
            placeholder="Select Platform"
            style={{ height: 'auto', wordWrap: 'break-word' }}
            className="dropdown-image"
            options={platformOptions}
          />
        </Form.Item>
        <Form.Item
          className="intercom-skuName"
          label="Reference Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please provide a reference name for this product.',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="intercom-skuRefId"
          label="SKU ID"
          name="sku_ref_id"
          rules={[
            {
              required: true,
              message: 'Please provide the SKU ID for this product.',
            },
            {
              pattern: /^[a-zA-Z0-9-_.]+$/g,
              message: 'SKU ID must not contain spaces or special characters',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="intercom-skuType"
          name="sku_type"
          label="SKU Type"
        >
          <Select placeholder="Select SKU Type" options={productTypeOptions} />
        </Form.Item>
        <Space direction="horizontal">
          <Button
            htmlType="submit"
            loading={newProductMutation.isLoading}
            type="primary"
            disabled={formErrors}
            onClick={() => {
              form
                .validateFields()
                .then(() => newProductMutation.mutate(form.getFieldsValue()));
            }}
          >
            Add
          </Button>
          <Button
            onClick={() => {
              form.resetFields();
              onClose();
            }}
          >
            Cancel
          </Button>
        </Space>
      </Form>
    </Modal>
  );
}
