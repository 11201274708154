import React from 'react';

import { PlanMeta, TDisplayProduct } from 'src/api/types/main.types';

import UpgradeWebPaywall, { WebPaywallProps } from './UpgradeWebPaywall';

const ABTestingWebPaywall = ({ onCancel, visible }: WebPaywallProps) => {
  const paywallsOnlyProducts: TDisplayProduct[] = [
    {
      product_value_props: [
        'Paywall A/B Testing',
        'Carousel paywall',
        'Audience filtering',
      ],
      ...PlanMeta['paywalls_only_team_v1'],
    },
    {
      product_value_props: [
        'Multi-variate testing',
        'Paywall personalization',
        'Advanced campaign filtering',
        'Video paywalls',
      ],
      ...PlanMeta['paywalls_only_business_v1'],
    },
    {
      product_value_props: [
        'ML optimized campaigns',
        'Phone, Tablet, and TV devices',
        'Volume pricing & SLA',
      ],
      ...PlanMeta['paywalls_only_enterprise_v1'],
    },
  ];

  const paywallsAndSubsProducts: TDisplayProduct[] = [
    {
      product_value_props: [
        'Paywall A/B Testing',
        'Carousel paywall',
        'Audience filtering',
      ],
      ...PlanMeta['paywalls_and_subs_team_v1'],
    },
    {
      product_value_props: [
        'Multi-variate testing',
        'Paywall personalization',
        'Advanced campaign filtering',
        'Video paywalls',
      ],
      ...PlanMeta['paywalls_and_subs_business_v1'],
    },
    {
      product_value_props: [
        'ML optimized campaigns',
        'Phone, Tablet, and TV devices',
        'Volume pricing & SLA',
      ],
      ...PlanMeta['paywalls_and_subs_enterprise_v1'],
    },
  ];

  return (
    <UpgradeWebPaywall
      modalTitle="Unlock A/B Testing"
      productsPaywallsOnly={paywallsOnlyProducts}
      productsPaywallsAndSubs={paywallsAndSubsProducts}
      highlightedProductPaywallsOnly="paywalls_only_team_v1"
      highlightedProductPaywallsAndSubs="paywalls_and_subs_team_v1"
      entitlement="app.campaign.paywall_segmentation.basic"
      onCancel={onCancel}
      visible={visible}
    />
  );
};

export default ABTestingWebPaywall;
