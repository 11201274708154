import React, { Fragment, useMemo } from 'react';

import {
  Button,
  Dropdown,
  Icon,
  Image,
  Menu,
  Sidebar,
} from 'semantic-ui-react';
import { namiSmoke } from 'src/variables';

import { AppType } from '../../api/types/app.types';
import { AppContextType } from '../../AppContextProvider';
import Responsive from '../../components/Responsive/Responsive';
import { useAppContext } from '../../hooks';
import { useAppSelector } from '../../hooks/redux.hooks';
import { defaultImage } from '../../services/helpers';
import { truncateString } from '../../utils/string';

type AppSwitchModalProps = {
  isVisible: boolean;
  onAddApp: () => void;
  onClose: () => void;
  onOrgClick: (orgId: string) => void;
  onAppClick: (appId: string) => void;
};

export default function AppSwitchModal({
  isVisible,
  onAddApp,
  onClose,
  onOrgClick,
  onAppClick,
}: AppSwitchModalProps) {
  const context: AppContextType = useAppContext();
  const { currentApp, apps } = useAppSelector(({ root }) => ({
    currentApp: root.currentApp,
    apps: root.apps as AppType[], // TODO: Remove when "root" is properly typed
  }));

  const orgName = formatOrgName(context.selectedOrg?.name || '');
  const orgOptions = useMemo(() => {
    return (context.user?.orgs || [])
      .map((org, _) => ({
        text: formatOrgName(org.name),
        value: org.id,
        active: context.selectedOrg?.id === org.id,
      }))
      .sort((a, b) => a.text.localeCompare(b.text));
  }, [context.user?.orgs, context.selectedOrg?.id]);

  return (
    <Fragment>
      <Responsive size="mdUp">
        <Sidebar
          as={Menu}
          animation="overlay"
          duration={0}
          onHide={onClose}
          vertical
          inverted
          direction="left"
          visible={isVisible}
          style={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'top',
          }}
        >
          {orgOptions.length > 1 && (
            <>
              <Menu.Item header style={{ color: 'black' }}>
                Switch Org
              </Menu.Item>
              <Menu.Item>
                <Dropdown
                  placeholder={orgName}
                  fluid
                  selection
                  onChange={(e, data) => onOrgClick(data.value as string)}
                  options={orgOptions}
                />
              </Menu.Item>
            </>
          )}
          {apps && !!apps.length && (
            <Menu.Item header style={{ color: 'black' }}>
              Switch App
            </Menu.Item>
          )}
          {apps &&
            !!apps.length &&
            apps.map((app, index) => (
              <Menu.Item
                key={index}
                onClick={() => onAppClick(app.id)}
                style={{
                  backgroundColor: app?.id === currentApp?.id ? namiSmoke : '',
                }}
              >
                <Image
                  style={{
                    cursor: 'pointer',
                    height: '30px',
                    width: '30px',
                    float: 'left',
                  }}
                  rounded
                  src={app.logo || defaultImage(app.name)}
                />

                <span
                  style={{
                    float: 'left',
                    margin: '8px 0 0 8px',
                    color: 'black',
                    fontWeight: app?.id === currentApp?.id ? 'bold' : 'normal',
                  }}
                >
                  {truncateString(app.name, 19)}
                </span>
              </Menu.Item>
            ))}
          <Menu.Item>
            <Button
              disabled={!context.userHasEntitlement('app.create')}
              onClick={onAddApp}
              className="add-app nami-primary-button"
            >
              <Icon name="plus" /> Add App
            </Button>
          </Menu.Item>
        </Sidebar>
      </Responsive>
      <Responsive size="mdDown">
        <Sidebar
          as={Menu}
          animation="overlay"
          duration={0}
          vertical
          onHide={onClose}
          inverted
          direction="right"
          visible={isVisible}
          style={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'top',
          }}
        >
          {orgOptions.length > 1 && (
            <>
              <Menu.Item header style={{ color: 'black' }}>
                Switch Org
              </Menu.Item>
              <Menu.Item>
                <Dropdown
                  placeholder={orgName}
                  fluid
                  selection
                  onChange={(e, data) => onOrgClick(data.value as string)}
                  options={orgOptions}
                />
              </Menu.Item>
            </>
          )}
          {!!apps?.length && (
            <Menu.Item header style={{ color: 'black' }}>
              Switch App
            </Menu.Item>
          )}
          {!!apps?.length &&
            apps.map((app) => (
              <Menu.Item
                key={app.id}
                onClick={() => onAppClick(app.id)}
                style={{
                  backgroundColor: app?.id === currentApp?.id ? namiSmoke : '',
                }}
              >
                <Image
                  style={{
                    cursor: 'pointer',
                    height: '30px',
                    width: '30px',
                    float: 'left',
                  }}
                  rounded
                  src={app.logo || defaultImage(app.name)}
                />

                <span
                  style={{
                    float: 'left',
                    margin: '8px 0 0 8px',
                    color: 'black',
                    fontWeight: app?.id === currentApp?.id ? 'bold' : 'normal',
                  }}
                >
                  {truncateString(app.name, 19)}
                </span>
              </Menu.Item>
            ))}
          <Menu.Item>
            <Button
              disabled={!context.userHasEntitlement('app.create')}
              onClick={onAddApp}
              className="add-app nami-primary-button"
            >
              <Icon name="plus" /> Add App
            </Button>
          </Menu.Item>
        </Sidebar>
      </Responsive>
    </Fragment>
  );

  function formatOrgName(name: string): string {
    const maxChar = 25;
    if (!(name.length && name.length > maxChar)) return name;
    return name.substring(0, maxChar - 3) + '...';
  }
}
