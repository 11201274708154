import React, { Fragment } from 'react';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Card, Form, Icon, Segment, TextArea } from 'semantic-ui-react';

import { useAppContext } from '../../../../hooks';
import { handlePaywallField } from '../../../../redux/actions/paywallActions';
import history from '../../../../services/history';
import SectionTitle from '../../../SectionTitle';

const Legal = ({ paywall, handlePaywallField }) => {
  const context = useAppContext();

  const renderPurchaseTerms = () => {
    return (
      <>
        <p>
          Enter purchase terms for the products offered by this paywall here.
        </p>
        <Form.Field>
          <TextArea
            rows="10"
            value={paywall.purchase_terms || ''}
            disabled={!context.userHasEntitlement('app.paywall.update')}
            onChange={(e) =>
              handlePaywallField({ ...paywall, purchase_terms: e.target.value })
            }
            placeholder="Enter your purchase terms here"
          />
        </Form.Field>
      </>
    );
  };

  return (
    <Fragment>
      <Card as={Segment} basic fluid>
        <Card.Content>
          <Card.Header>
            <SectionTitle>
              Legal Clickwrap
              <Icon
                style={{ cursor: 'pointer', paddingLeft: '1em' }}
                onClick={() => history.push('/app-settings/?tab=legal')}
                size="small"
                name="external alternate"
              />
            </SectionTitle>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          Legal clickwrap text and links for your app's Terms of Use and Privacy
          Policy are{' '}
          <NavLink to="/app-settings/?tab=legal">
            configured in App Settings.
          </NavLink>
        </Card.Content>
      </Card>
      <Card as={Segment} basic fluid>
        <Card.Content>
          <Card.Header>
            <SectionTitle>Purchase Terms</SectionTitle>
          </Card.Header>
        </Card.Content>
        <Card.Content> {renderPurchaseTerms()}</Card.Content>
      </Card>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlePaywallField: (data) => dispatch(handlePaywallField(data)),
  };
};

const mapStateToProps = ({ root, paywall }) => {
  return {
    currentApp: root.currentApp,
    paywall: paywall.paywall,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Legal);
