/* eslint-disable no-template-curly-in-string */
import {
  TAllComponentDetailValues,
  TAssertionMapToReadable,
  TBaseComponent,
  TCollapseContainer,
  TComponent,
  TComponentLocation,
  TConditionalComponent,
  TConditionalOperands,
  TContainer,
  TParsedToAssertionMap,
  TPaywallPage,
  TPaywallPageSection,
  TPlayPauseButtonComponent,
  TPOS,
  TTestObject,
  TTestObjectOperator,
  TVolumeControlComponent,
} from 'src/api/types/paywallTemplate.types';
import { getAttr } from 'src/pages/admin/paywalls/utils/functions';

import { PaywallType, TDevice } from '../api/types/paywall.types';
import { namiPrimaryBlue } from '../variables';
import { interpolateString } from './interpolation';
import { parseToSemver, semverLarger, TSemverObj } from './parsing';
import { toReadable } from './string';

export function findBackgroundImage(paywall: PaywallType): string | null {
  const image =
    paywall.background_image_url_phone || paywall.background_image_url_tablet;
  if (image) return image;
  if (paywall.media.backgroundImage) return paywall.media.backgroundImage;
  const paywallMediaKeys = Object.keys(paywall.media);
  if (paywallMediaKeys.length) {
    if (paywallMediaKeys[0] === 'default')
      return paywallMediaKeys.length > 1
        ? paywall.media[paywallMediaKeys[1]]
        : null;
    return paywall.media[paywallMediaKeys[0]];
  }
  return null;
}

export function findBackgroundColor(paywall: PaywallType): string {
  const color = paywall.template?.pages?.[0].backgroundContainer.fillColor;
  return color ? interpolatePaywallValue(paywall, color) : namiPrimaryBlue;
}

function interpolatePaywallValue(paywall: PaywallType, value: string): string {
  const variables = paywall.template?.variables;
  if (!variables) return value;
  return interpolateString(value, { var: variables });
}

export function propertyValueIsVariable(value: any): boolean {
  const stringValue = String(value);
  if (
    stringValue.startsWith('${var.') ||
    stringValue.startsWith('${sku.') ||
    stringValue.startsWith('${state.') ||
    stringValue.startsWith('${media.') ||
    stringValue.startsWith('${slide.')
  )
    return true;
  return false;
}

export function getComponentTitle(value: TComponent | null): string {
  if (!value) return '';
  if (value.title) return value.title;
  if (value.component === 'productContainer') {
    return 'Repeating Product Group';
  }
  if (value.id) {
    if (value.id === 'backgroundContainer') return 'Background';
    if (value.id === 'contentContainer') return 'Content';
    return toReadable(value.id);
  }
  return 'Unnamed component';
}

export const variableNamespaceTest =
  /\$?{?(var|sku|media|state|slide|launch)\.([a-zA-Z0-9-_.]+)}?/;

export function deconstructVariable(
  variable: string,
  nameSpaceOnly: boolean = false,
  valueOnly: boolean = false
): string {
  const found = String(variable).match(variableNamespaceTest);
  if (!found) return variable;
  if (nameSpaceOnly) return found[1];
  if (valueOnly) return found[2];
  return `${found[1]}.${found[2]}`;
}

//Return variable with trailing number increased
//titleText0 becomes titleText1
export function incrementVariable(
  variable: string,
  ids: { [key: string]: string }
): string {
  const found = String(variable).match(/([\w-]*[^\d]+)(\d*)$/);
  if (!found) return variable;
  for (let i = 1; i < 100; i++) {
    const newNumber = found[2] ? Number(found[2]) + i : 0 + (i - 1);
    const newId: string = `${found[1]}${newNumber}`;
    if (!(newId in ids)) return newId;
  }
  return `duplicate${variable}`;
}

export function getProductGroupIndexFromId(id: string): number {
  const productGroupIndexRegex = /\${state.groups.([0-9]+)(\.id|\.ref)}/i;
  const match = id.match(productGroupIndexRegex);

  if (match && match.length > 1) return parseInt(match[1]) || 0;
  return 0;
}

export function getCollapseOpenVariableFromComponent(
  component: TCollapseContainer | undefined
): string {
  if (component && component.open && typeof component.open === 'string')
    return component.open;
  return '${var.collapseOpenByDefault';
}

export function getCustomVariableFromPersonalizationToken(
  value: string
): string | undefined {
  const tokenRegex = /\$\{customer\.(.+)\}/i;
  const tokenMatch = value.match(tokenRegex);
  if (tokenMatch && tokenMatch.length > 1) {
    return tokenMatch[1];
  }
  return undefined;
}

export function getCustomVariableFromLaunchContext(
  value: string
): string | undefined {
  const launchContextRegex = /\$\{launch\.customAttributes\.(.+)\}/i;
  const launchContextMatch = value.match(launchContextRegex);
  if (launchContextMatch && launchContextMatch.length > 1) {
    return launchContextMatch[1];
  }
  return undefined;
}

export function getCustomVariableFromLaunchObject(
  value: string,
  child: boolean
): string | undefined {
  const blockContextRegex = /\$\{block\.(.+)\}/i;
  const objectContextRegex = /\$\{launch\.customObject\.(.+)\}/i;
  const launchContextMatch = child
    ? value.match(blockContextRegex)
    : value.match(objectContextRegex);
  if (launchContextMatch && launchContextMatch.length > 1) {
    return launchContextMatch[launchContextMatch.length - 1];
  }
  return undefined;
}

export function parseOperatorFromCustomLaunchObject(
  operator: TTestObjectOperator,
  expected: any
): TTestObjectOperator {
  if (operator === 'equals') {
    return expected ? 'true' : 'false';
  } else if (operator === 'notEquals') {
    return expected ? 'false' : 'true';
  } else if (operator === 'set') {
    return expected ? 'set' : 'notSet';
  }
  return expected ? 'notSet' : 'set';
}

export function findComponentFromLocation(
  pages: TPaywallPage[],
  idLocation: TComponentLocation,
  splice: boolean = false
): TComponent {
  const page = pages[idLocation.page];
  let pageSection: TComponent = page.contentContainer;

  if (idLocation.section === 'header') {
    if (page.header.length > 1) {
      pageSection = {
        id: 'header',
        component: 'container',
        components: page.header,
      };
    } else {
      pageSection = page.header[0];
    }
  } else if (idLocation.section === 'footer') {
    if (page.footer) {
      if (page.footer.length > 1) {
        pageSection = {
          id: 'footer',
          component: 'container',
          components: page.footer || [],
        };
      } else {
        pageSection = page.footer[0];
      }
    } else {
      pageSection = {
        id: 'footer',
        component: 'container',
        components: [],
      };
    }
  } else if (idLocation.section === 'backgroundContainer') {
    pageSection = page.backgroundContainer;
  }

  for (let i = 0; i < idLocation.pos.length; i++) {
    const locationIndex = idLocation.pos[i];
    if (locationIndex !== undefined && pageSection) {
      if (locationIndex === 'collapseHeader') {
        pageSection = (pageSection as TCollapseContainer).collapseHeader;
      } else if (locationIndex === 'mutedComponents') {
        pageSection = {
          component: 'container',
          components: (pageSection as TVolumeControlComponent).mutedComponents,
        };
      } else if (locationIndex === 'volumeComponents') {
        pageSection = {
          component: 'container',
          components: (pageSection as TVolumeControlComponent).volumeComponents,
        };
      } else if (locationIndex === 'pausedComponents') {
        pageSection = {
          component: 'container',
          components: (pageSection as TPlayPauseButtonComponent)
            .pausedComponents,
        };
      } else if (locationIndex === 'playingComponents') {
        pageSection = {
          component: 'container',
          components: (pageSection as TPlayPauseButtonComponent)
            .playingComponents,
        };
      } else if ((pageSection as TContainer).components) {
        if (splice && i === idLocation.pos.length - 1) {
          pageSection = (pageSection as TContainer).components.splice(
            locationIndex,
            1
          )[0];
        } else {
          pageSection = (pageSection as TContainer).components[locationIndex];
        }
      } else {
        console.warn(`page section ${pageSection.id} has no components`);
      }
    } else {
      console.warn(`Location not valid`);
      console.warn(idLocation);
    }
  }
  return pageSection;
}

export function moveUpTreeFromLocation(
  child: TComponentLocation
): TComponentLocation {
  if (child.pos.length > 0) {
    return {
      page: child.page,
      section: child.section,
      pos: child.pos.slice(0, child.pos.length - 1),
    };
  }
  return child;
}

export function findPositionBelowLeaf(leaf: TComponentLocation): number {
  if (leaf.pos.length > 0) {
    if (typeof leaf.pos[leaf.pos.length - 1] === 'number') {
      return (leaf.pos[leaf.pos.length - 1] as number) + 1;
    }
    //TODO - handle non-numbers here
  }
  return 1;
}

export function parseLocationString(location: string): TComponentLocation {
  const locationPieces = location.split('-');
  let page: number = 0;
  let section: TPaywallPageSection = 'contentContainer';
  let pos: TPOS[] = [];

  if (locationPieces.length >= 2) {
    if (Number.parseInt(locationPieces[0]))
      page = Number.parseInt(locationPieces[0]);
    if (locationPieces[1] as TPaywallPageSection)
      section = locationPieces[1] as TPaywallPageSection;

    if (locationPieces.length > 2) {
      pos = locationPieces.slice(2).reduce((output, piece) => {
        return [
          ...output,
          isNaN(Number.parseInt(piece))
            ? (piece as TPOS)
            : Number.parseInt(piece),
        ];
      }, [] as TPOS[]);
    }
  }

  return {
    page: page,
    section: section,
    pos: pos,
  };
}

export function createLocationStringFromParsedObject(
  location: TComponentLocation
): string {
  const pos = location.pos.length ? '-' + location.pos.join('-') : '';
  return `${location.page}-${location.section}${pos}`;
}

export function generateReadableConditions(
  conditions: TTestObject[],
  version: 'short' | 'long',
  operator: 'and' | 'or' = 'and'
): string {
  const resultArray = conditions.reduce((output, object) => {
    const flatObject = `${object.value} ${object.operator} ${object.expected}`;
    if (flatObject in TAssertionMapToReadable)
      return [...output, TAssertionMapToReadable[flatObject]];
    return output;
  }, [] as string[]);

  if (resultArray.length > 0 && version === 'long')
    return `These styles are applied when ${resultArray.join(` ${operator} `)}`;
  return resultArray.join(` ${operator} `);
}

export function isConditionalComponent(value: TComponent): boolean {
  return value.component === 'condition';
}

export function conditionalStyles(value: TComponent): boolean {
  if (value.conditionAttributes) {
    return (
      value.conditionAttributes.length > 0 &&
      Object.keys(value.conditionAttributes[0].attributes).length > 0
    );
  }
  return false;
}

export function repeatingComponent(value: TComponent): boolean {
  return (
    value.component === 'productContainer' ||
    value.component === 'carouselContainer'
  );
}

export function parseConditionRuleToTestObject(
  selectedOperand: TConditionalOperands | null,
  selectedOperator: TTestObjectOperator | null,
  selectedValue: string | null,
  additionalMetadata: { [key: string]: string }
): { result: TTestObject; error: boolean } {
  const groupIndex = selectedValue?.match(/^group([0-9]+)$/);

  if (selectedOperand === 'launchContext') {
    if (!selectedValue) {
      return {
        result: {
          value: `\${launch.customAttributes.}`,
          operator: selectedOperator || 'set',
          expected: true,
        },
        error: true,
      };
    }
    return {
      result: {
        value: `\${launch.customAttributes.${selectedValue}}`,
        operator: selectedOperator || 'set',
        expected: true,
      },
      error: false,
    };
  } else if (selectedOperand === 'personalizationToken') {
    if (!selectedValue) {
      return {
        result: {
          value: `\${customer.}`,
          operator: selectedOperator || 'set',
          expected: true,
        },
        error: true,
      };
    }
    return {
      result: {
        value: `\${customer.${selectedValue}}`,
        operator: selectedOperator || 'set',
        expected: true,
      },
      error: false,
    };
  } else if (selectedOperand === 'launchObject') {
    if (!selectedValue) {
      return {
        result: {
          value: `\${launch.customObject}`,
          operator: selectedOperator || 'set',
          expected: null,
        },
        error: true,
      };
    }
    return {
      result: {
        value: selectedValue,
        operator:
          selectedOperator === 'set' || selectedOperator === 'notSet'
            ? selectedOperator
            : 'equals',
        expected:
          selectedOperator === 'set' || selectedOperator === 'notSet'
            ? true
            : selectedOperator === 'true',
      },
      error: false,
    };
  } else if (selectedOperand === 'skuEntitlement') {
    if (!selectedValue) {
      return {
        result: {
          value: `\${sku.entitlements}`,
          operator: selectedOperator || 'set',
          expected: null,
        },
        error: true,
      };
    }
    return {
      result: {
        value: `\${sku.entitlements}`,
        operator: selectedOperator || 'set',
        expected: selectedValue,
      },
      error: false,
    };
  } else if (selectedOperand === 'skuVariable') {
    if (!selectedValue) {
      return {
        result: {
          value: `\${sku.DisplayTextText}`,
          operator:
            selectedOperator === 'set' || selectedOperator === 'notSet'
              ? selectedOperator
              : 'equals',
          expected:
            selectedOperator === 'set' || selectedOperator === 'notSet'
              ? true
              : selectedOperator === 'true',
        },
        error: true,
      };
    }
    return {
      result: {
        value: selectedValue,
        operator:
          selectedOperator === 'set' || selectedOperator === 'notSet'
            ? selectedOperator
            : 'equals',
        expected:
          selectedOperator === 'set' || selectedOperator === 'notSet'
            ? true
            : selectedOperator === 'true',
      },
      error: false,
    };
  } else if (
    selectedOperand === 'collapse' &&
    additionalMetadata.collapseParentId
  ) {
    return {
      result: {
        value: '${state.openHeaderIds}',
        operator: 'contains',
        expected: additionalMetadata.collapseParentId,
      },
      error: selectedValue === null,
    };
  } else if (selectedOperand === 'launchGroup') {
    return {
      result: {
        value: '${launch.productGroups}',
        operator: 'contains',
        expected: groupIndex ? `\${state.groups.${groupIndex[1]}.ref}` : null,
      },
      error: !groupIndex,
    };
  } else if (selectedOperand === 'group') {
    return {
      result: {
        value: groupIndex ? `\${state.groups.${groupIndex[1]}.id}` : null,
        operator: 'equals',
        expected: '${state.currentGroupId}',
      },
      error: !groupIndex,
    };
  }
  const readableValues = `${selectedOperand} ${selectedOperator} ${selectedValue}`;
  if (TParsedToAssertionMap.hasOwnProperty(readableValues)) {
    return {
      result: TParsedToAssertionMap[readableValues],
      error: false,
    };
  } else {
    return {
      result: {
        value: selectedOperand,
        operator: selectedOperator || 'equals',
        expected: selectedValue,
      },
      error: true,
    };
  }
}

export function generateNextIdForType(
  type: TAllComponentDetailValues,
  idsMap: { [key: string]: string }
): string {
  for (let i = 0; i < 100; i++) {
    const sampleId = i === 0 ? `${type}` : `${type}${i}`;
    if (!(sampleId in idsMap)) return sampleId;
  }
  return `new${type}`;
}

export function getChangeKeysForNewVariable(
  propertyType: any
): Array<TChangeOptions> {
  let result: Set<TChangeOptions> = new Set();
  if (propertyType === 'dateTimeFormat') result.add('dateTimeFormat');
  return Array.from(result);
}

export function getChangeKeysForVariableValueChange(
  value: any
): Array<TChangeOptions> {
  let result: Set<TChangeOptions> = new Set();
  if (typeof value === 'string' && value.includes('${block.'))
    result.add('repeatingListDynamicColors');
  return Array.from(result);
}

export function getChangeKeysForNewConditions(
  conditions: Array<
    Omit<TConditionalComponent, 'components'> & {
      attributes: Partial<TBaseComponent>;
    }
  >
): Array<TChangeOptions> {
  let result: Set<TChangeOptions> = new Set();
  conditions.forEach((condition) => {
    const allAssertions = [
      ...(condition.assertions || []),
      ...(condition.orAssertions || []),
    ];
    allAssertions.forEach((assertion) => {
      if (assertion.value === '${state.orientation}')
        result.add('orientationCondition');
      if (assertion.value === '${sku.entitlements}')
        result.add('entitlementCondition');
    });
  });

  return Array.from(result);
}

export function getChangeKeysForNewTestObjects(
  conditions: TTestObject[]
): Array<TChangeOptions> {
  let result: Set<TChangeOptions> = new Set();
  conditions.forEach((condition) => {
    if (condition.value === '${state.orientation}')
      result.add('orientationCondition');
  });

  return Array.from(result);
}

export function getChangeKeyForNewComponent(
  componentType: TAllComponentDetailValues
): Array<TChangeOptions> {
  if (componentType === 'collapse') return ['collapse'];
  if (componentType === 'repeatingList') return ['repeatingList'];
  if (componentType === 'videoUrl') return ['video'];
  if (componentType === 'playPauseButton' || componentType === 'volumeButton')
    return ['advanced_video'];
  return [];
}

export type TChangeOptions =
  | 'repeatingListDynamicColors'
  | 'orientationCondition'
  | 'video'
  | 'advanced_video'
  | 'collapse'
  | 'repeatingList'
  | 'custom_launch_object'
  | 'hideComponent'
  | 'entitlementCondition'
  | 'dateTimeFormat'
  | 'productErrorStates';

export const TChangeOptionNames: Record<TChangeOptions, string> = {
  repeatingListDynamicColors: 'dynamic color from custom data source',
  orientationCondition: 'Device Orientation conditions',
  video: 'Video component',
  advanced_video: 'Advanced Video capability',
  collapse: 'Collapse component',
  repeatingList: 'Repeating List component',
  custom_launch_object: 'Custom Data Source capability',
  hideComponent: 'Hidden component',
  entitlementCondition: 'Product Entitlement conditions',
  dateTimeFormat: 'Custom Date Time Formatting',
  productErrorStates: 'Product Error States capability',
};

export function getNewMinSDKVersionFromChange(
  changeKeys: Array<TChangeOptions>,
  formFactor: TDevice,
  currentMinSDK: TSemverObj
): TSemverObj | null {
  let result: TSemverObj = currentMinSDK;
  const semver321 = parseToSemver('3.2.1');
  const semver322 = parseToSemver('3.2.2');
  const semver324 = parseToSemver('3.2.4');
  const semver325 = parseToSemver('3.2.5');
  const changes: Record<TDevice, Record<TChangeOptions, TSemverObj | null>> = {
    phone: {
      orientationCondition: null,
      repeatingListDynamicColors: semver322,
      collapse: semver321,
      video: semver321,
      advanced_video: semver321,
      repeatingList: semver321,
      custom_launch_object: semver321,
      hideComponent: semver321,
      entitlementCondition: semver321,
      dateTimeFormat: semver324,
      productErrorStates: semver325,
    },
    tablet: {
      orientationCondition: semver322,
      repeatingListDynamicColors: semver322,
      collapse: semver321,
      video: semver321,
      advanced_video: semver321,
      repeatingList: semver321,
      custom_launch_object: semver321,
      hideComponent: semver321,
      entitlementCondition: semver321,
      dateTimeFormat: semver324,
      productErrorStates: semver325,
    },
    desktop: {
      orientationCondition: null,
      repeatingListDynamicColors: semver324,
      collapse: semver321,
      video: semver321,
      advanced_video: semver321,
      repeatingList: semver324,
      custom_launch_object: semver324,
      hideComponent: semver321,
      entitlementCondition: semver321,
      dateTimeFormat: semver324,
      productErrorStates: null,
    },
    television: {
      orientationCondition: null,
      repeatingListDynamicColors: null,
      collapse: semver321,
      video: semver321,
      advanced_video: semver321,
      repeatingList: null,
      custom_launch_object: null,
      hideComponent: null,
      entitlementCondition: null,
      dateTimeFormat: null,
      productErrorStates: null,
    },
  };

  if (!changeKeys || !changeKeys.length) return null;

  changeKeys.forEach((changeKey) => {
    if (changes[formFactor] && changes[formFactor][changeKey]) {
      if (
        semverLarger(result, changes[formFactor][changeKey] || currentMinSDK)
      ) {
        result = changes[formFactor][changeKey] || currentMinSDK;
      }
    }
  });

  return result;
}

export function pullOutAllColorsFromPaywall(variables: {
  [key: string]: any;
}): Set<string> {
  const result = new Set<string>();

  Object.entries(variables).forEach(([key, value]) => {
    if (key.toLowerCase().endsWith('color')) {
      if (
        value !== 'transparent' &&
        !value.includes('gradient') &&
        !value.includes('${block')
      )
        result.add(value);
    }
  });
  return result;
}

export function setValue(source: any, path: string[], value: any): void {
  const [last, ...rest] = path.reverse();
  const container = getAttr(source, ...rest.reverse());
  container[last] = value;
}

export function platformCompatibleWithFormFactor(
  platformType: string,
  formFactor: TDevice
): boolean {
  if (['roku_pay', 'amazon_iap'].includes(platformType)) {
    return formFactor === 'television';
  }
  if (['google', 'apple'].includes(platformType)) {
    return ['television', 'phone', 'tablet'].includes(formFactor);
  }
  if (platformType === 'web') {
    return ['phone', 'television', 'desktop', 'tablet'].includes(formFactor);
  }
  console.warn(
    `Unrecognized platform type ${platformType}, cannot determine eligible form factors`
  );
  return false;
}
