import React from 'react';

import { Select, Tooltip } from 'antd';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

import { useActions, useAppSelector } from '../../../../hooks/redux.hooks';
import { RootState } from '../../../../redux';

export default function PaywallPagePicker() {
  const { template, currentPage } = useAppSelector(
    ({ paywallBuilder: { paywall, currentPage } }: RootState) => ({
      template: paywall?.template,
      currentPage: currentPage,
    })
  );
  const actions = useActions(PaywallBuilderSlice.actions);

  const pageOptions = template?.pages.map((page, i) => {
    return {
      label: `Page ${i + 1}`,
      value: page.name,
    };
  });

  return (
    <Tooltip title="Pick a page to edit & preview" mouseEnterDelay={0.8}>
      <Select
        options={pageOptions}
        defaultValue={currentPage}
        onChange={changePage}
        size="small"
        style={{ width: 100 }}
      />
    </Tooltip>
  );

  function changePage(newPageName: string) {
    actions.setPage(newPageName);
  }
}
