import React from 'react';

import { TimeDimensionGranularity } from '@cubejs-client/core';
import { DatePicker, Skeleton, Space, Tag } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  TPredefinedRange,
  getDisabledDateRange,
  predefinedRangeFunction,
  predefinedRangesByInterval,
} from '../api/types/analytics.types';
import { useActions, useAppSelector } from '../hooks/redux.hooks';
import AnalyticsSlice from '../redux/AnalyticsSlice';
import { breakpointMd } from '../variables';
import FilterFormItem from './FilterFormItem';

type DateRangePickerProps = {
  type: 'insights' | 'overview';
};

const DateRangeItem = styled(FilterFormItem)<{ type: string }>`
  ${({ type }) => {
    if (type !== 'insights') return '';
    return css`
      @media only screen and (min-width: ${breakpointMd}) {
        margin-right: 1.5rem;
      }
    `;
  }}
`;

export default function DateRangePicker({ type }: DateRangePickerProps) {
  const actions = useActions(AnalyticsSlice.actions);
  const dateRange = useAppSelector((state) => state.analytic.dateRangeInsights);
  const resolution = useAppSelector((state) =>
    type === 'insights' ? state.analytic.interval : 'day'
  );
  const timezone = useAppSelector((state) => state.analytic.timezone);
  const interval = useAppSelector((state) => state.analytic.interval);
  const isRendering = useAppSelector(
    (state) => state.analytic.placeholderState
  );
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  return (
    <DateRangeItem
      type={type}
      label={'Date Range'}
      colon={false}
      className={'intercom-dateRange'}
    >
      {type === 'insights' && isRendering ? (
        <Skeleton.Input active={true} size="small" block={true} />
      ) : (
        <DatePicker.RangePicker
          disabledDate={getDisabledDateRange[resolution]}
          onChange={(values): void => {
            const [start, end] = values || [];
            if (!start || !end) return;
            setDates([start, end]);
          }}
          allowClear={false}
          value={dateRange}
          renderExtraFooter={(mode) => {
            return (
              <Space
                direction="horizontal"
                size={2}
                wrap={true}
                style={{ lineHeight: '30px', paddingBottom: 6 }}
              >
                {getTagOptions()}
              </Space>
            );
          }}
        />
      )}
    </DateRangeItem>
  );

  function getTagOptions(): Array<React.ReactNode> {
    const rangeOptions: Array<TPredefinedRange> =
      predefinedRangesByInterval[interval];
    return rangeOptions.map((option) => {
      return (
        <Tag
          onClick={() =>
            setDates(
              predefinedRangeFunction[option](timezone, interval),
              interval
            )
          }
          color="blue"
          style={{ cursor: 'pointer' }}
          key={option}
        >
          {option}
        </Tag>
      );
    });
  }

  function setDates(
    range: [moment.Moment, moment.Moment],
    interval?: TimeDimensionGranularity
  ) {
    const [start, end] = range;
    const startEndInterval = interval && interval === 'hour' ? 'hour' : 'day';
    queryParams.set(
      'startDate',
      start.startOf(startEndInterval).toISOString(true)
    );
    queryParams.set('endDate', end.endOf(startEndInterval).toISOString(true));
    location.search = queryParams.toString();
    history.push(location);
    actions.setDateRangeInsights([
      start.startOf(startEndInterval),
      end.endOf(startEndInterval),
    ]);
  }
}
