import React from 'react';

import { Space } from 'antd';
import styled from 'styled-components';

import { namiDarkGray } from '../../../../variables';
import {
  getLabelDotForDetailPage,
  getLabelStatusForDetailPage,
} from './campaign.utils';

const StatusWrapper = styled(Space)`
  .ant-space-item {
    display: flex;
    align-items: center;
  }
`;

const StatusText = styled.span`
  color: ${namiDarkGray};
  font-size: smaller;
`;

export default function PlacementStatusLabel({
  enabled,
  archived = false,
}: {
  enabled: boolean;
  archived?: boolean;
}) {
  return (
    <>
      <StatusWrapper>
        {getLabelDotForDetailPage(enabled, archived)}
        <StatusText>
          {getLabelStatusForDetailPage(enabled, archived)}
        </StatusText>
      </StatusWrapper>
    </>
  );
}
