import * as React from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { ENABLE_REACT_QUERY_DEV_TOOLS } from './config';

const fiveMinutes = 1000 * 60 * 5;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, staleTime: fiveMinutes },
  },
});

export default function ReactQueryProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  React.useEffect(() => {
    if (!ENABLE_REACT_QUERY_DEV_TOOLS) return;
    // @ts-ignore
    window.toggleDevtools = () => setShowDevtools((value) => !value);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {ENABLE_REACT_QUERY_DEV_TOOLS && <ReactQueryDevtools initialIsOpen />}
    </QueryClientProvider>
  );
}
