import Token from '../services/token';
import { client } from './clients';
import {
  UserType,
  UserPayloadType,
  TokenPayloadType,
  TokenType,
  PasswordType,
} from './types/main.types';
import {
  extractResponseData,
  extractErrorData,
  extractListResponseData,
} from './utils';

const account = {
  changePassword: (value: PasswordType): Promise<void> => {
    const payload = {
      old_password: value.currentPassword,
      new_password1: value.newPassword,
      new_password2: value.confirmNewPassword,
    };
    return client
      .put('/private/account/password/change/', payload)
      .catch(extractErrorData) as unknown as Promise<void>;
  },
  getUser: (): Promise<UserType> => {
    return client
      .get('/private/account/')
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  updateUser: (payload: UserPayloadType): Promise<UserType> => {
    return client
      .put('/private/account/', payload)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  notifyUser: (entitlement: string): Promise<void> => {
    return client
      .post('/private/wait_lists/', { entitlement })
      .catch(extractErrorData) as unknown as Promise<void>;
  },
  getUserAccessTokens: (): Promise<TokenType[]> => {
    return client
      .get('/private/account/token/?source=pat')
      .then(extractListResponseData)
      .catch(extractErrorData);
  },

  addUserAccessToken: (data: TokenPayloadType): Promise<string> => {
    return client
      .post('/private/account/token/new_pat/', {
        ...data,
        refresh_token: Token.getRefresh().toString(),
      })
      .then(extractResponseData)
      .then(({ access_token }) => access_token)
      .catch(extractErrorData);
  },
  deleteUserAccessToken: (tokenId: string): Promise<void> => {
    return client
      .delete(`/private/account/token/${tokenId}/`)
      .catch(extractErrorData) as unknown as Promise<void>;
  },
};

export default account;
