import React, { useEffect, useState } from 'react';

import { Form, InputNumber, Select, Typography } from 'antd';
import { namiMediumGray } from 'src/variables';
import styled from 'styled-components';

import { FieldObject } from '../../../utils/formFieldBuilding';
import InputLabel from './InputLabel';

type PtPxInputProps = Omit<FieldObject, 'variable'> & {
  defaultValue?: string | number;
  onChange: (value: any) => void;
  label: string;
  controlled?: boolean;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
};

const FormHint = styled(Typography.Text)`
  float: right;
  margin-bottom: 15px;
  color: ${namiMediumGray} !important;
`;

export default function PtPxInput({
  defaultValue,
  onChange,
  controlled = false,
  ...field
}: PtPxInputProps) {
  const [inputType, setInputType] = useState<'pt' | '%'>('pt');
  const [parsedValue, setParsedValue] = useState<string | number>(0);
  const [minLimit, setMinLimit] = useState<number>(0);
  const [maxLimit, setMaxLimit] = useState<number>(100);

  useEffect(() => {
    if (String(field.value).endsWith('%')) {
      setInputType('%');
      setParsedValue(String(field.value).slice(0, -1));
    } else {
      setInputType('pt');
      setParsedValue(field.value);
    }
  }, [field.value]);

  useEffect(() => {
    if (inputType === '%') {
      setMinLimit(-100);
      setMaxLimit(100);
    } else {
      setMinLimit(-999);
      setMaxLimit(999);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputType]);

  const selectAfter = (
    <Select
      defaultValue="%"
      onChange={setInputType}
      value={inputType}
      className="select-addon-after"
    >
      <Select.Option value="%">%</Select.Option>
      <Select.Option value="pt">pt</Select.Option>
    </Select>
  );

  const textHint = field.hint && <FormHint>{field.hint}</FormHint>;

  const itemProps = {
    help: textHint,
    name: controlled ? undefined : field.variable,
    label: <InputLabel {...field} />,
  };

  return (
    <Form.Item {...itemProps}>
      <InputNumber
        value={parsedValue}
        max={maxLimit}
        min={minLimit}
        disabled={!field.editable}
        addonAfter={selectAfter}
        onChange={handleInputChange}
      />
    </Form.Item>
  );

  function handleInputChange(value: number | string | null): void {
    const numValue = Number(value);
    if (isNaN(numValue) || !field.editable || !isWithinMinMax(numValue)) return;
    if (inputType === '%') onChange(`${value}%`);
    else onChange(numValue);
  }

  function isWithinMinMax(value: number): boolean {
    return maxLimit >= value && minLimit <= value;
  }
}
