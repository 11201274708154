import React from 'react';

import {
  CheckOutlined,
  DeleteOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Form, Upload } from 'antd';
import { ValidateStatus } from 'antd/lib/form/FormItem';
import { NamePath } from 'rc-field-form/lib/interface';
import { readFile } from 'src/utils/file';

type SecretFileUploadProps = {
  help?: React.ReactNode;
  tooltipContent: React.ReactNode;
  label: string;
  validateStatus?: ValidateStatus;
  fileType: string;
  name: NamePath;
  disabled: boolean;
  onContentUpload?: (content: string) => any;
  onChange?: (name: NamePath, value: any) => void;
};

export default function SecretFileUpload({
  label,
  help,
  tooltipContent,
  validateStatus,
  fileType: fileTypes,
  name,
  disabled,
  onChange: onChangeProp,
  onContentUpload = (value) => value,
}: SecretFileUploadProps) {
  const form = Form.useFormInstance();
  const isSet = !!Form.useWatch(name, form);

  const onChange = onChangeProp || form.setFieldValue;

  const [icon, buttonText] = disabled
    ? [<CheckOutlined />, 'File uploaded']
    : isSet
    ? [<DeleteOutlined />, 'Remove file']
    : [<UploadOutlined />, 'Upload File'];
  return (
    <Form.Item
      label={label}
      name={name}
      help={help}
      validateStatus={validateStatus}
      tooltip={{ title: tooltipContent }}
      className={`intercom-${label.replace(/\s/g, '').toLowerCase()}`}
    >
      <Upload
        fileList={[]}
        disabled={disabled}
        openFileDialogOnClick={!isSet}
        customRequest={({ file }) => {
          readFile(file as File).then((content) => {
            onChange(name, onContentUpload(content));
          });
        }}
        accept={fileTypes}
      >
        <Button
          icon={icon}
          disabled={disabled}
          danger={validateStatus === 'error'}
          onClick={() => {
            if (!isSet) return;
            onChange(name, null);
          }}
        >
          {buttonText}
        </Button>
      </Upload>
    </Form.Item>
  );
}
