import React from 'react';

import { PlayCircleOutlined, PushpinOutlined } from '@ant-design/icons';
import { Menu, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import Responsive from 'src/components/Responsive/Responsive';
import { namiDarkGray, namiPrimaryBlue, namiPureWhite } from 'src/variables';
import styled from 'styled-components';

const StyledMenu = styled(Menu)`
  background-color: transparent !important;
  gap: 5px;

  li.ant-menu-overflow-item.ant-menu-item {
    width: calc(50% - 10px);
    background-color: #e9ebec;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    border-bottom: 1px solid #eff4f7;
    color: ${namiDarkGray};
  }

  li.ant-menu-overflow-item.ant-menu-item.ant-menu-item-selected {
    background-color: ${namiPureWhite};
    border-bottom: initial;
    color: ${namiPrimaryBlue};
  }
`;

type CampaignMenuProps = {
  currentKey: string;
};

export default function CampaignMenu({ currentKey }: CampaignMenuProps) {
  const history = useHistory();
  const PlacementValueAndTag = (
    <>
      <Responsive size="mdUp">
        <Space direction="horizontal" size={10}>
          <span>Placements</span>
        </Space>
      </Responsive>
      <Responsive size="mdDown">
        <Space direction="horizontal" size={8}>
          <span>Placements</span>
        </Space>
      </Responsive>
    </>
  );
  const tabList = [
    {
      key: 'placements',
      label: PlacementValueAndTag,
      icon: <PushpinOutlined />,
    },
    {
      key: 'rules',
      label: (
        <Space direction="horizontal">
          <span>Campaign Rules</span>
        </Space>
      ),
      icon: <PlayCircleOutlined />,
    },
  ];
  return (
    <StyledMenu
      selectedKeys={[currentKey]}
      items={tabList}
      mode="horizontal"
      onClick={({ key }) => {
        history.push(`/campaigns/${key}/`);
      }}
    />
  );
}
