import React from 'react';

import Page from '../../../components/Page/Page';
import PreAuthBase from './PreAuthBase';

const PreAuthPage = ({ match }) => {
  return (
    <Page title="Pre Authentication">
      <PreAuthBase
        header="Multi-Factor Authentication"
        subheader="Enter your verification code from your authentication app."
        authType={match.params.type}
        cancelTo="/pre-auth-recovery/recovery/"
        cancelLabel="I don't have access"
      />
    </Page>
  );
};

export default PreAuthPage;
