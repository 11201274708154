import React from 'react';

import cx from 'classnames';
import { Header, Segment } from 'semantic-ui-react';

import NamiIcon from '../NamiIcon/NamiIcon';
import styles from './EmptyStateSegment.module.less';

type EmptyStateSegmentProps = {
  border?: boolean;
  icon?: string;
  outBoundLink?: string;
  title?: string;
  subtitle?: string;
  children?: JSX.Element | JSX.Element[];
  size?: string;
  style?: React.CSSProperties;
  className?: string;
};

export default function EmptyStateSegment({
  border = true,
  icon = 'nami symbol',
  outBoundLink = 'https://learn.namiml.com/',
  title = '',
  subtitle = '',
  children,
  size = 'large',
  style,
  className,
}: EmptyStateSegmentProps) {
  return (
    <Segment
      className={cx(styles.container, className)}
      raised={false}
      basic={!border}
      style={style}
    >
      <Header icon>
        <div className={styles.image}>
          <NamiIcon icon={icon} size={size} color="primary" />
        </div>
        <Header.Subheader>{title}</Header.Subheader>
      </Header>
      <div className={styles.subtitle}>
        <a target="_blank" rel="noopener noreferrer" href={outBoundLink}>
          {subtitle}
        </a>
      </div>
      <Segment basic textAlign="center">
        {children}
      </Segment>
    </Segment>
  );
}
