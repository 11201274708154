import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';
import { Checkbox, Form, Header } from 'semantic-ui-react';
import { namiPrimaryBlue, namiPureWhite } from 'src/variables';

import api from '../../../../api';
import Loading from '../../../../components/Loading/Loading';
import SectionTitle from '../../../../components/SectionTitle';
import {
  useAppContext,
  useFormState,
  usePromiseState,
} from '../../../../hooks';
import { extractFieldError } from '../../../../services/utilsService';
import { DaysInput, MinutesField, MinutesInput, NumberInput } from './inputs';

const TeamPolicyForm = () => {
  const context = useAppContext();
  const { form, setFormField, setFormInput, setForm } = useFormState({});
  const [expiredPasswords, setExpiredPasswords] = useState(false);
  const [lockoutTeam, setLockoutTeam] = useState(false);
  const [inactive, setInactive] = useState(false);
  const {
    isLoading: isUpdating,
    error: updateError,
    trigger: updatePolicy,
  } = usePromiseState(() => {
    const { id: policyId, ...payload } = form;
    return api
      .updatePolicy(policyId, /** @type OrgPolicyPayloadType */ payload)
      .then(() => {
        toast.success('Successfully updated org policies.');
      });
  });
  const { isLoading: isFetching, trigger: fetchPolicy } = usePromiseState(
    async () => {
      if (!context.userHasEntitlement('org.policy.list')) return;
      return api.getPolicy().then((policy) => {
        setForm(policy);
        setExpiredPasswords(!!policy.password_max_age);
        setInactive(!!policy.inactive_user_disable);
        setLockoutTeam(
          !!(
            policy.authn_max_attempts ||
            policy.authn_track_duration ||
            policy.authn_lockout_time
          )
        );
      });
    }
  );
  const {
    isLoading: isFetchingDefaults,
    output: sessionDefaults,
    trigger: fetchSessionDefaults,
  } = usePromiseState(api.getSessionDefaults);

  useEffect(() => {
    fetchPolicy();
    fetchSessionDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoading = isFetching || isUpdating || isFetchingDefaults;

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <SectionTitle>Security Policies</SectionTitle>
      <Form onSubmit={updatePolicy}>
        <p>
          These security policies will apply to all members of your team. Only
          other account Admins can edit or remove them.
        </p>
        <SectionHeader
          text="Expire Passwords"
          description="If selected, then your team members will need to reset their passwords regularly for continued access to the Nami Control Center."
          isChecked={expiredPasswords}
          onCheckboxClick={() => {
            setExpiredPasswords((flag) => !flag);
            setFormField('password_max_age', null);
          }}
        />
        {expiredPasswords && (
          <DaysInput
            label="Expiration Time in Days"
            name="password_max_age"
            onChange={setFormInput}
            value={form.password_max_age || ''}
            error={extractFieldError(updateError?.password_max_age)}
          />
        )}
        <SectionHeader
          text="Lock Account After Failed Login Attempts"
          description="If selected, a team member who has multiple consecutive failed login attempts within a set time limit will be temporarily locked out of accessing their account."
          isChecked={lockoutTeam}
          onCheckboxClick={() => {
            setLockoutTeam((flag) => !flag);
            setFormField('authn_max_attempts', null);
            setFormField('authn_track_duration', null);
            setFormField('authn_lockout_time', null);
          }}
        />
        {lockoutTeam && (
          <Form.Group widths="equal">
            <NumberInput
              label="Consecutive Failed Logins"
              name="authn_max_attempts"
              onChange={setFormInput}
              value={form.authn_max_attempts || ''}
              placeholder="Attempts"
              error={extractFieldError(updateError?.authn_max_attempts)}
            />
            <MinutesInput
              label="Time Limit for Failed Logins in Minutes"
              name="authn_track_duration"
              onChange={setFormInput}
              value={form.authn_track_duration || ''}
              error={extractFieldError(updateError?.authn_track_duration)}
            />
            <MinutesInput
              label="Time Account Locked in Minutes"
              name="authn_lockout_time"
              onChange={setFormInput}
              value={form.authn_lockout_time || ''}
              error={extractFieldError(updateError?.authn_lockout_time)}
            />
          </Form.Group>
        )}
        <SectionHeader
          text="Disable Inactive Team Members"
          description="If selected, team members who have not logged into their Nami Control Center account for given period of time will have their account disabled."
          isChecked={inactive}
          onCheckboxClick={() => {
            setInactive((flag) => !flag);
            setFormField('inactive_user_disable', null);
          }}
        />
        {inactive && (
          <DaysInput
            label="Inactivity Time Period in Days"
            name="inactive_user_disable"
            onChange={setFormInput}
            value={form.inactive_user_disable || ''}
            error={extractFieldError(updateError?.inactive_user_disable)}
          />
        )}
        <SectionHeader
          text="Control Center Sessions"
          description="Team member sessions in the Control Center will be terminated and the user will automatically be logged out after the times below."
        />
        <Form.Group widths="equal">
          <MinutesField
            label="Inactive Session Length in Minutes"
            name="session_inactivity_timeout"
            onChange={setFormInput}
            value={form.session_inactivity_timeout || ''}
            error={extractFieldError(updateError?.session_inactivity_timeout)}
            help={`If left blank, the default is ${sessionDefaults?.inactivity_timeout} minutes.`}
          />
          <MinutesField
            label="Max Session Length in Minutes"
            name="session_max_duration"
            onChange={setFormInput}
            value={form.session_max_duration || ''}
            error={extractFieldError(updateError?.session_max_duration)}
            help={`If left blank, the default is ${sessionDefaults?.max_duration} minutes.`}
          />
        </Form.Group>
        <Form.Button
          style={{ backgroundColor: namiPrimaryBlue, color: namiPureWhite }}
          type="submit"
          disabled={!context.userHasEntitlement('org.policy.update')}
        >
          Update Policies
        </Form.Button>
      </Form>
    </>
  );
};

export default TeamPolicyForm;

function SectionHeader({ isChecked, onCheckboxClick, text, description }) {
  const withCheckbox = typeof isChecked === 'boolean';
  return (
    <>
      <Header>
        {withCheckbox && (
          <Checkbox
            style={{ float: 'right' }}
            checked={isChecked}
            toggle
            onChange={onCheckboxClick}
            // This is a quickfix. When this checkbox is focused, it gets a
            // blue-ish "!important" color from the lib.
            onFocus={(e) => e.target.blur()}
          />
        )}
        <span style={{ fontSize: '0.9em' }}>{text}</span>
      </Header>
      {description && <p>{description}</p>}
    </>
  );
}
