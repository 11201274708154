import React, { Fragment, useState } from 'react';

import { Form, Modal, Input, DatePicker } from 'antd';
import moment from 'moment';
import { namiAliceGray, namiPrimaryBlue } from 'src/variables';

import api from '../../../../api';
import FormItem from '../../../../components/FormItem';
import { usePromiseState, useBooleanState } from '../../../../hooks';
import { copyTextToClipBoard } from '../../../../services/helpers';

// TODO make it one modal

const AddTokenModal = ({ isOpen, onClose, setApiAccessTokens }) => {
  const [form] = Form.useForm();
  const [copied, setCopied] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);

  const addAccessToken = usePromiseState(() => {
    setCopied(false);
    return api
      .addUserAccessToken(form.getFieldsValue())
      .then((newAccessToken) => {
        form.resetFields();
        setAccessToken(newAccessToken);
        openModal();
      })
      .then(api.getUserAccessTokens)
      .then(setApiAccessTokens)
      .then(onClose);
  });

  const copy = () => {
    copyTextToClipBoard(accessToken);
    setCopied(true);
  };

  const handleCancel = () => {
    onClose();
    form.resetFields();
    closeModal();
    setCopied(false);
  };

  return (
    <Fragment>
      <Modal
        title={<strong>Add Access Token</strong>}
        centered
        open={isOpen}
        onOk={form.submit}
        onCancel={handleCancel}
        okText="Add Access Token"
        confirmLoading={addAccessToken.isLoading}
      >
        <Form
          form={form}
          onFinish={addAccessToken.trigger}
          layout="vertical"
          initialValues={{ name: '', expiration: '' }}
        >
          <FormItem
            name="name"
            label="Name"
            error={addAccessToken?.error?.name}
          >
            <Input placeholder="Name" />
          </FormItem>
          <FormItem
            name="expiration"
            label="Expiration"
            error={addAccessToken?.error?.expiration}
          >
            <DatePicker
              disabledDate={(current) =>
                (current && current < moment()) ||
                current > moment().add(365, 'day')
              }
              style={{ width: '100%' }}
            />
          </FormItem>
        </Form>
      </Modal>
      <Modal
        title={<strong>Add Access Token</strong>}
        centered
        closable={false}
        maskClosable={false}
        open={isModalOpen}
        onOk={copy}
        onCancel={handleCancel}
        cancelText="Close"
        okText={copied ? 'Copied!' : 'Copy'}
      >
        Your API token is:
        <pre
          style={{
            background: namiAliceGray,
            borderRadius: 20,
            padding: 20,
            color: namiPrimaryBlue,
            cursor: 'pointer',
          }}
        >
          <span style={{ fontFamily: 'Overpass Mono' }}>{accessToken}</span>
        </pre>
        <strong>
          This API access token is only available now. Please securely store it
          as you will not be able to access it again.
        </strong>
      </Modal>
    </Fragment>
  );
};

export default AddTokenModal;
