import React from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, List } from 'antd';
import { TDisplayProduct, TierNames } from 'src/api/types/main.types';
import { namiSecondaryBlue } from 'src/variables';
import styled, { css } from 'styled-components';

const CardTable = styled.table`
  height: 100%;
  width: 100%;
  border-collapse: collapse;
`;
const ListProp = styled(List.Item)`
  padding: 4px 4px !important;
`;
const ListPropCheck = styled(CheckOutlined)`
  padding-right: 4px;
`;
const ProductCard = styled(Card)<{ $featured: boolean }>`
  margin: '5px 0px';
  ${({ $featured }) => {
    if (!$featured) return '';
    return css`
      border-width: 2px;
      border-color: ${namiSecondaryBlue};
      box-shadow: 3px 3px 12px rgba(19, 116, 222, 0.1);
    `;
  }}
`;

type WebPaywallProductCardProps = {
  product: TDisplayProduct;
  compProduct?: TDisplayProduct;
  userCanPurchase: boolean;
  featured: boolean;
  orgHasProduct: boolean;
  requestUpgrade: () => void;
  purchaseLink: string | undefined;
  useTierForTitle?: boolean;
};
const WebPaywallProductCard = ({
  product,
  compProduct,
  userCanPurchase,
  featured,
  orgHasProduct,
  requestUpgrade,
  purchaseLink,
  useTierForTitle = false,
}: WebPaywallProductCardProps) => {
  const Unauthorizedbutton = (
    <Button type="primary" block={true} disabled={true}>
      Contact your Admin to Upgrade
    </Button>
  );

  const YourPlanButton = (
    <p style={{ textAlign: 'center' }}>
      <CheckOutlined style={{ paddingRight: 5 }} /> Your Current Plan
    </p>
  );

  return (
    <ProductCard
      $featured={featured}
      title={useTierForTitle ? TierNames[product.tier] : product.shortPlanName}
      size="small"
      bodyStyle={{ height: 250 }}
      className={`intercom-purchase-${product.rawPlanName}`}
    >
      <CardTable>
        <tbody>
          <tr>
            <td valign="top">
              <List
                size="small"
                split={false}
                dataSource={product.product_value_props}
                renderItem={(item, index) => (
                  <ListProp key={index}>
                    <ListPropCheck />
                    {item}
                  </ListProp>
                )}
              />
            </td>
          </tr>
          <tr>
            <td valign="bottom">
              {!userCanPurchase && Unauthorizedbutton}
              {!orgHasProduct && userCanPurchase && (
                <Button
                  type="primary"
                  ghost={!featured}
                  block={true}
                  href={product?.purchaseLinkProd ? purchaseLink : undefined}
                  onClick={
                    product?.purchaseLinkProd ? undefined : requestUpgrade
                  }
                  target="_blank"
                >
                  {getButtonPriceText(product)}
                </Button>
              )}
              {orgHasProduct && YourPlanButton}
            </td>
          </tr>
        </tbody>
      </CardTable>
    </ProductCard>
  );

  function getButtonPriceText(product: TDisplayProduct): string {
    if (product.purchaseLinkProd && product.basePricePerMonth) {
      if (compProduct && compProduct.basePricePerMonth) {
        return `Add for +$${
          product.basePricePerMonth - compProduct.basePricePerMonth
        }/month`;
      } else {
        return `Upgrade for $${product.basePricePerMonth}/month`;
      }
    } else {
      return 'Book a Demo';
    }
  }
};

export default WebPaywallProductCard;
