import React, { Fragment } from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monoBlue } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { Button } from 'semantic-ui-react';

import EmptyStateSegment from '../EmptyStateSegment/EmptyStateSegment';
import Responsive from '../Responsive/Responsive';
import styles from './List.module.less';

const List = ({ children, className, code, onClick }) => {
  return (
    <Fragment>
      <Responsive size="mdUp">
        <div className={cx(styles.col, styles.listWrapper)}>
          <ul className={cx(styles.list, className)}>{children}</ul>
        </div>
        {code ? (
          <div className={cx(styles.col, styles.codeWrapper)}>
            <SyntaxHighlighter
              showLineNumbers={true}
              language="json"
              style={monoBlue}
            >
              {`${JSON.stringify(code, null, 2)}`}
            </SyntaxHighlighter>
          </div>
        ) : (
          <EmptyStateSegment
            style={{ height: '80vh' }}
            title="Select an Event"
            subtitle="Select an event to see more information."
            icon="sdk"
          />
        )}
      </Responsive>
      <Responsive size="mdDown">
        {code ? (
          <Fragment>
            <Button
              className="nami-button"
              onClick={onClick}
              type="button"
              active={true}
              style={{ marginBottom: '15px' }}
            >
              Go Back
            </Button>
            <div className={cx(styles.codeWrapper)}>
              <SyntaxHighlighter
                showLineNumbers={true}
                language="json"
                style={monoBlue}
              >
                {`${JSON.stringify(code, null, 2)}`}
              </SyntaxHighlighter>
            </div>
          </Fragment>
        ) : (
          <div className={styles.listWrapper}>
            <ul className={cx(styles.list, styles.listMobile, className)}>
              {children}
            </ul>
          </div>
        )}
      </Responsive>
    </Fragment>
  );
};

List.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default List;
