import React, { useState } from 'react';

import {
  Loading3QuartersOutlined,
  LockFilled,
  TagOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import { AttachMoneyOutlined } from '@mui/icons-material';
import { Image, Row, Space, Tag, Typography } from 'antd';
import { TDevice } from 'src/api/types/paywall.types';
import {
  TPaywallCapabilityReadableNames,
  TPaywallTemplate,
  TTemplateCapability,
  TTemplateFormFactor,
} from 'src/api/types/paywallTemplate.types';
import {
  colorNeutral,
  namiBrightBlue,
  namiDarkGray,
  namiDarkestGray,
  namiLightGray,
  namiPureWhite,
} from 'src/variables';
import styled, { css } from 'styled-components';

const TemplateCapabilitiesToIgnore: TTemplateCapability[] = [
  'product_groups',
  'dynamic_signin',
  'featured_product',
];

type TemplateThumbnailProps = {
  disabled?: boolean;
  onClick: () => void;
  imageSrc: string;
  index: number;
  device: TDevice;
  gated: boolean;
  creatingPaywall?: boolean;
  template: TPaywallTemplate | null;
};

const Wrapper = styled.div<{ device: TDevice }>`
  position: relative;
  ${({ device }) => {
    let [height, width] = ['initial', 'initial'];
    if (device === 'phone') {
      [height, width] = ['400px', '186px'];
    }
    if (device === 'tablet') {
      [height, width] = ['270px', '386px'];
    }
    if (device === 'television' || device === 'desktop') {
      [height, width] = ['444px', '790px'];
    }
    return css`
      width: ${width};
      height: ${height};
    `;
  }}
`;

const Badge = styled(Tag)`
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 1;
  filter: drop-shadow(0px 0.5px 4px rgba(19, 116, 222, 0.12));
`;

const ComingSoonBadge = styled(Badge)`
  color: ${namiDarkestGray};
`;

const PremiumBadge = styled(Badge)`
  background: ${namiBrightBlue};
  border-color: rgba(239, 244, 247, 0.6);
  color: ${namiPureWhite};
  font-size: 13px;
`;

const UpgradeBadge = styled(Badge)`
  background: ${namiBrightBlue};
  border-color: rgba(239, 244, 247, 0.6);
  color: ${namiPureWhite};
`;

const HoverOverlay = styled.div`
  background-color: rgb(255 255 255 / 0.55);
  backdrop-filter: blur(10px);
  width: 100%;
  min-height: 40%;
  position: absolute;
  bottom: 0;
  padding: 10px;
  border-radius: 10px;
  z-index: 1;
  cursor: pointer;
`;

const StyledImage = styled(Image)<{
  disabled?: boolean;
  $newPaywallLoading?: boolean;
}>`
  border: 1px solid ${namiLightGray};
  border-radius: 10px;

  ${({ disabled, $newPaywallLoading }) => {
    if (disabled || $newPaywallLoading) {
      return css`
        cursor: not-allowed;
        opacity: 0.6;
      `;
    }
    return css`
      cursor: pointer;
      &:hover {
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25)) !important;
      }
      opacity: 1;
    `;
  }}
`;

export default function TemplateThumbnail({
  disabled,
  onClick,
  imageSrc,
  index,
  device,
  gated,
  creatingPaywall: newPaywallLoading = false,
  template,
}: TemplateThumbnailProps) {
  const [hover, setHover] = useState(false);
  const badge = disabled ? (
    <ComingSoonBadge>COMING SOON</ComingSoonBadge>
  ) : gated && device === 'television' ? (
    <UpgradeBadge icon={<LockFilled />}>Upgrade</UpgradeBadge>
  ) : gated ? (
    <PremiumBadge
      icon={
        <AttachMoneyOutlined
          style={{ fontSize: 14, transform: 'translateY(3px)', marginRight: 4 }}
        />
      }
    >
      Premium
    </PremiumBadge>
  ) : null;
  return (
    <Wrapper
      device={device}
      onClick={newPaywallLoading ? undefined : onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {badge}
      {hover && <HoverOverlay>{getHoverContent()}</HoverOverlay>}
      <StyledImage
        disabled={disabled}
        preview={false}
        rootClassName={`template${index}`}
        placeholder={<Placeholder />}
        src={imageSrc}
        $newPaywallLoading={newPaywallLoading}
      />
    </Wrapper>
  );

  function getTemplateName(): string {
    return template?.codename || 'Legacy';
  }

  function getTemplateVersionString(): string {
    let result: string[] = [];
    if (template?.version) {
      result.push(`Version ${template?.version}`);
    }
    if (template?.min_sdk_version) {
      result.push(`SDK ${template.min_sdk_version}+`);
    }
    return result.join(' • ');
  }

  function getTemplateMaxSkus(): string {
    const legacy = template === null;
    const maxSkusRaw = legacy ? 6 : template?.['ui.maxSkus'] || 1;
    const productGroups = template?.['ui.requiredGroups'] || 1;
    if (maxSkusRaw > 1) {
      if (productGroups > 1) return `${maxSkusRaw} products per group`;
      return `Up to ${maxSkusRaw} products`;
    }
    return `${maxSkusRaw} product${productGroups > 1 ? ' per group' : ''}`;
  }

  function getTemplateProductGroups(): string {
    const groupsRaw = template?.['ui.requiredGroups'] || 1;
    const dynamicGroups: boolean = template?.['ui.dynamicAddGroups'] || false;
    if (groupsRaw > 1) {
      return `${dynamicGroups ? 'Unlimited' : groupsRaw} product groups`;
    }
    return '';
  }

  function getOrientationString(): string {
    const formFactor: TTemplateFormFactor = (template?.['ui.formFactors'] || [
      {
        form_factor: 'phone',
        supports_landscape: false,
        supports_portrait: true,
      },
    ])[0];

    if ((formFactor.form_factor || 'phone') === 'tablet') {
      if (formFactor.supports_portrait && formFactor.supports_landscape)
        return 'Rotateable';
      return formFactor.supports_portrait
        ? 'Portrait orientation'
        : 'Landscape orientation';
    }

    return '';
  }

  function getTemplateCapabilitiesList(): string {
    let readableFeatures: string[] = [];

    if (!!template?.['ui.v2Ready'])
      readableFeatures.push('Creator Compatible!');
    if (!!template?.['ui.hasFeaturedFlag'])
      readableFeatures.push('Featured Styling');
    if (!!template?.['ui.hasSelectedFlag']) {
      readableFeatures.push('Purchase CTA');
    }

    const firstPage = template?.pages[0] || { footer: null };
    if (firstPage.footer && firstPage.footer.length > 1)
      readableFeatures.push('Sticky Footer');

    if (getOrientationString() !== '')
      readableFeatures.push(getOrientationString());

    (template?.['ui.capabilities'] || []).forEach((capability) => {
      if (!TemplateCapabilitiesToIgnore.includes(capability)) {
        readableFeatures.push(TPaywallCapabilityReadableNames[capability]);
      }
    });

    return readableFeatures.join(' • ');
  }

  function getHoverContent(): JSX.Element {
    return (
      <>
        <Typography.Text strong style={{ fontSize: 15 }}>
          {getTemplateName()}
        </Typography.Text>
        <Typography.Paragraph
          style={{ fontSize: 'small', color: namiDarkGray }}
        >
          {getTemplateVersionString()}
        </Typography.Paragraph>
        <Space direction="vertical" size={4}>
          {!!getTemplateProductGroups() && (
            <Space
              direction="horizontal"
              size={4}
              style={{ fontSize: 'small' }}
            >
              <TagsOutlined style={{ fontSize: 11 }} />
              {getTemplateProductGroups()}
            </Space>
          )}
          <Space
            direction="horizontal"
            size={4}
            style={{ fontSize: 'small', marginBottom: 8 }}
          >
            <TagOutlined style={{ fontSize: 11 }} />
            {getTemplateMaxSkus()}
          </Space>
          <Typography.Paragraph
            style={{ textAlign: 'center', fontSize: 'small' }}
          >
            {getTemplateCapabilitiesList()}
          </Typography.Paragraph>
        </Space>
      </>
    );
  }
}

function Placeholder() {
  return (
    <Row
      align="middle"
      justify="center"
      style={{ height: '400px', width: '186px' }}
    >
      <Loading3QuartersOutlined
        style={{ fontSize: '32px', color: colorNeutral }}
        spin={true}
      />
    </Row>
  );
}
