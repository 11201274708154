import React, { useEffect } from 'react';

import moment from 'moment';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  Grid,
  List,
  Popup,
  Segment,
  Table,
} from 'semantic-ui-react';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import CustomerDetailWebPaywall from 'src/components/WebPaywalls/CustomerDetailWebPaywall';
import { useAppContext, useBooleanState } from 'src/hooks';

import EmptyStateSegment from '../../../../components/EmptyStateSegment/EmptyStateSegment';
import ListDots from '../../../../components/ListDots/ListDots';
import ListDotsSize from '../../../../components/ListDots/ListDotsSide';
import MobileTable from '../../../../components/MobileTable/MobileTable';
import Page from '../../../../components/Page/Page';
import Responsive from '../../../../components/Responsive/Responsive';
import TimeZoneLabel from '../../../../components/TimeZoneLabel/TimeZoneLabel';
import { getCrmUser } from '../../../../redux/actions/crmActions';

const CustomerDetailPage = ({ loading, getCrmUser, crmUser, match }) => {
  const { userHasEntitlement } = useAppContext();
  const [isUpgradeOpen, openUpgrade] = useBooleanState(false);

  useEffect(() => {
    if (userHasEntitlement('app.user.get')) return;
    openUpgrade();
  }, [userHasEntitlement, openUpgrade]);

  useEffect(() => {
    getCrmUser(match.params.userID);
  }, [match.params.userID, getCrmUser]);

  const history = useHistory();

  return (
    <Page>
      <Grid>
        <Grid.Row>
          <Grid.Column mobile={16}>
            <Breadcrumb
              items={[
                { url: '/customers/', name: 'Customers' },
                { name: crmUser?.pseudonym || 'Loading customer' },
              ]}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={8} computer={5}>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Card
                as={Segment}
                loading={loading}
                fluid
                style={{ minHeight: 150, marginBottom: 20 }}
                className="intercom-user-data"
              >
                <Card.Content>
                  <List>
                    <List.Item className="intercom-user-data-pseudonym">
                      <List.Icon name="user outline" />
                      <List.Content>
                        <List.Header>Pseudonym</List.Header>
                        <List.Description>
                          {crmUser && crmUser.pseudonym
                            ? crmUser.pseudonym
                            : 'N/A'}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                    <List.Item className="intercom-user-data-nami-id">
                      <List.Icon name="address book outline" />
                      <List.Content>
                        <List.Header>Nami ID</List.Header>
                        <List.Description>
                          {crmUser && crmUser.id ? crmUser.id : 'None'}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                    <List.Item className="intercom-user-data-external-id">
                      <List.Icon name="folder outline" />
                      <List.Content>
                        <List.Header>External ID</List.Header>
                        <List.Description>
                          {crmUser && crmUser.external_id
                            ? crmUser.external_id
                            : 'None'}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  </List>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <Card
                as={Segment}
                loading={loading}
                fluid
                style={{ minHeight: 100, marginBottom: 20 }}
                className="intercom-quick-stats"
              >
                <Card.Content>
                  <List>
                    <List.Item className="intercom-quick-stats-purchases">
                      <List.Icon name="shopping cart" />
                      <List.Content>
                        <List.Header>Purchases</List.Header>
                        <List.Description>
                          {crmUser ? crmUser.transaction_count : ''}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                    <List.Item className="intercom-quick-stats-app-sessions">
                      <List.Icon name="mobile alternate" />
                      <List.Content>
                        <List.Header>App Sessions</List.Header>
                        <List.Description>
                          {crmUser ? crmUser.session_count : ''}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                    <List.Item className="intercom-quick-stats-first-seen">
                      <List.Icon name="calendar alternate outline" />
                      <List.Content>
                        <List.Header>First Seen</List.Header>
                        <List.Description>
                          {crmUser && crmUser.first_session ? (
                            <Popup
                              trigger={
                                <span>
                                  {crmUser.first_session.created_date_humanized}
                                </span>
                              }
                              content={moment(
                                crmUser.first_session.created_date
                              ).format('MMM DD, YYYY - hh:mm:ss')}
                              inverted
                            />
                          ) : (
                            'N/A'
                          )}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                    <List.Item className="intercom-quick-stats-last-seen">
                      <List.Icon name="calendar alternate outline" />
                      <List.Content>
                        <List.Header>Last Seen</List.Header>
                        <List.Description>
                          {crmUser && crmUser.last_session ? (
                            <Popup
                              trigger={
                                <span>
                                  {crmUser.last_session.created_date_humanized}
                                </span>
                              }
                              content={moment(
                                crmUser.last_session.created_date
                              ).format('MMM DD, YYYY - hh:mm:ss')}
                              inverted
                            />
                          ) : (
                            'N/A'
                          )}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                    <List.Item className="intercom-quick-stats-first-purchase">
                      <List.Icon name="calendar alternate outline" />
                      <List.Content>
                        <List.Header>First Purchase</List.Header>
                        <List.Description>
                          {crmUser && crmUser.first_transaction ? (
                            <Popup
                              trigger={
                                <span>
                                  {
                                    crmUser.first_transaction
                                      .created_date_humanized
                                  }
                                </span>
                              }
                              content={moment(
                                crmUser.first_transaction.created_date
                              ).format('MMM DD, YYYY - hh:mm:ss')}
                              inverted
                            />
                          ) : (
                            'N/A'
                          )}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                    <List.Item className="intercom-quick-stats-last-purchase">
                      <List.Icon name="calendar alternate outline" />
                      <List.Content>
                        <List.Header>Last Purchase</List.Header>
                        <List.Description>
                          {crmUser && crmUser.last_transaction ? (
                            <Popup
                              trigger={
                                <span>
                                  {
                                    crmUser.last_transaction
                                      .created_date_humanized
                                  }
                                </span>
                              }
                              content={moment(
                                crmUser.last_transaction.created_date
                              ).format('MMM DD, YYYY - hh:mm:ss')}
                              inverted
                            />
                          ) : (
                            'N/A'
                          )}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  </List>
                </Card.Content>
              </Card>
              <Card
                as={Segment}
                loading={loading}
                fluid
                style={{ minHeight: 150, marginBottom: 20 }}
                className="intercom-revenue-card"
              >
                <Card.Content>
                  <Card.Header>Recent Revenue</Card.Header>
                  <Card.Description>
                    <div style={{ float: 'left' }}>
                      <ListDotsSize>
                        {crmUser &&
                          !!crmUser.recent_revenue.length &&
                          crmUser.recent_revenue.map((item, index) => (
                            <Popup
                              key={index}
                              trigger={<li>{item.created_date_humanized}</li>}
                              content={moment(item.created_date).format(
                                'MMM DD, YYYY - hh:mm:ss'
                              )}
                              inverted
                            />
                          ))}
                      </ListDotsSize>
                    </div>
                    <div style={{ float: 'left' }}>
                      <ListDots>
                        {crmUser && !!crmUser.recent_revenue.length
                          ? crmUser.recent_revenue.map((item, index) => (
                              <li key={index}>
                                {item.amount} {item.currency}
                              </li>
                            ))
                          : 'No revenue for this user.'}
                      </ListDots>
                    </div>
                  </Card.Description>
                  {crmUser && !!crmUser.recent_revenue.length && (
                    <Card.Description>
                      <Button
                        basic
                        onClick={() => {
                          history.push(
                            `/customers/revenue/${crmUser.pseudonym}/${match.params.userID}/`
                          );
                        }}
                      >
                        See All Revenue
                      </Button>
                    </Card.Description>
                  )}
                </Card.Content>
              </Card>
              <Card
                as={Segment}
                loading={loading}
                fluid
                style={{ minHeight: 150, marginBottom: 20 }}
                className="intercom-events-card"
              >
                <Card.Content>
                  <Card.Header>Recent User Events</Card.Header>
                  <Card.Description style={{ overflow: 'hidden' }}>
                    <div style={{ float: 'left' }}>
                      <ListDotsSize>
                        {crmUser &&
                          !!crmUser.recent_events.length &&
                          crmUser.recent_events.map((item, index) => (
                            <Popup
                              key={index}
                              trigger={<li>{item.created_date_humanized}</li>}
                              content={moment(item.created_date).format(
                                'MMM DD, YYYY - hh:mm:ss'
                              )}
                              inverted
                            />
                          ))}
                      </ListDotsSize>
                    </div>
                    <div style={{ float: 'left', width: '50%' }}>
                      <ListDots>
                        {crmUser && !!crmUser.recent_events.length
                          ? crmUser.recent_events.map((item, index) => (
                              <li key={index}>{item.event_type_humanized}</li>
                            ))
                          : 'No events for this user.'}
                      </ListDots>
                    </div>
                  </Card.Description>
                  {crmUser && !!crmUser.recent_events.length && (
                    <Card.Description>
                      <Button
                        basic
                        onClick={() => {
                          history.push(
                            `/customers/events/${crmUser.pseudonym
                              .toLowerCase()
                              .replace(' ', '-')}/${match.params.userID}/`
                          );
                        }}
                      >
                        See All Events
                      </Button>
                    </Card.Description>
                  )}
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={11}>
            <Responsive size="mdUp">
              <Card
                as={Segment}
                loading={loading}
                fluid
                style={{ minHeight: 300, marginBottom: 20 }}
                className="intercom-active-entitlements-card"
              >
                {crmUser && !!crmUser.active_entitlements.length ? (
                  <Card.Content>
                    <Card.Header>Active Entitlements</Card.Header>
                    <Card.Description
                      style={{ overflowX: 'scroll', marginTop: 25 }}
                    >
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell className="table-header">
                              Name
                            </Table.HeaderCell>
                            <Table.HeaderCell className="table-header">
                              Purchase Platform
                            </Table.HeaderCell>
                            <Table.HeaderCell className="table-header">
                              Purchased SKU
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {crmUser.active_entitlements.map(
                            (entitlement, index) => (
                              <Table.Row key={index}>
                                <Table.Cell>{entitlement.name}</Table.Cell>
                                <Table.Cell>
                                  {entitlement.purchase_platform}
                                </Table.Cell>
                                <Table.Cell>
                                  {entitlement.sku_ref_id}
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </Card.Description>
                  </Card.Content>
                ) : (
                  <EmptyStateSegment
                    title="No Active Entitlements"
                    subtitle="This user does not have any active entitlements."
                    icon="entitlement"
                  />
                )}
              </Card>
            </Responsive>
            <Responsive size="mdDown">
              {crmUser && !!crmUser.active_entitlements.length ? (
                <Card
                  as={Segment}
                  loading={loading}
                  fluid
                  style={{ minHeight: 300, marginBottom: 20 }}
                  className="intercom-active-entitlements-card"
                >
                  <Card.Content>
                    <Card.Header>Active Entitlements</Card.Header>
                    <Card.Description style={{ marginTop: 25 }}>
                      <MobileTable
                        disabledIcon={false}
                        collection={crmUser.active_entitlements.map(
                          (entitlement) => ({
                            name: entitlement.name,
                            metaLabel: entitlement.sku_ref_id
                              ? entitlement.sku_ref_id
                              : 'N/A',
                            graphicContent: () => {},
                          })
                        )}
                      />
                    </Card.Description>
                  </Card.Content>
                </Card>
              ) : (
                <EmptyStateSegment
                  style={{ marginBottom: 20 }}
                  title="No Active Entitlements"
                  subtitle="This user does not have any active entitlements."
                  icon="entitlement"
                />
              )}
            </Responsive>
            <Responsive size="mdUp">
              <Card
                as={Segment}
                loading={loading}
                fluid
                style={{ minHeight: 300, marginBottom: 20 }}
                className="intercom-devices-card"
              >
                {crmUser && !!crmUser.devices.length ? (
                  <Card.Content>
                    <Card.Header>Devices</Card.Header>
                    <Card.Description
                      style={{ overflowX: 'scroll', marginTop: 25 }}
                    >
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell className="table-header">
                              Platform
                            </Table.HeaderCell>
                            <Table.HeaderCell className="table-header">
                              Device Model
                            </Table.HeaderCell>
                            <Table.HeaderCell className="table-header">
                              OS Version
                            </Table.HeaderCell>
                            <Table.HeaderCell className="table-header">
                              Language
                            </Table.HeaderCell>
                            <Table.HeaderCell className="table-header">
                              Nami SDK Version
                            </Table.HeaderCell>
                            <Table.HeaderCell className="table-header">
                              CDP Id
                            </Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {crmUser.devices.map((device, index) => (
                            <Table.Row key={index}>
                              <Table.Cell>{device.platform}</Table.Cell>
                              <Table.Cell>{device.device_model}</Table.Cell>
                              <Table.Cell>{device.os_version}</Table.Cell>
                              <Table.Cell>{device.language}</Table.Cell>
                              <Table.Cell>{device.sdk_version}</Table.Cell>
                              <Table.Cell>
                                {device.customer_data_platform_id}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </Card.Description>
                  </Card.Content>
                ) : (
                  <EmptyStateSegment
                    title="No Devices"
                    subtitle="This user does not have any devices."
                    icon="sdk"
                  />
                )}
              </Card>
            </Responsive>
            <Responsive size="mdDown">
              {crmUser && !!crmUser.devices.length ? (
                <Card
                  as={Segment}
                  loading={loading}
                  fluid
                  style={{ minHeight: 300, marginBottom: 20 }}
                  className="intercom-devices-card"
                >
                  <Card.Content>
                    <Card.Header>Devices</Card.Header>
                    <Card.Description style={{ marginTop: 25 }}>
                      <MobileTable
                        disabledIcon={false}
                        collection={crmUser.devices.map((device) => ({
                          name: device.platform,
                          metaLabel: device.device_model
                            ? device.device_model
                            : 'N/A',
                          graphicContent: () => {},
                        }))}
                      />
                    </Card.Description>
                  </Card.Content>
                </Card>
              ) : (
                <EmptyStateSegment
                  title="No Devices Available"
                  subtitle="This user doesn't have any devices"
                  icon="sdk"
                />
              )}
            </Responsive>
          </Grid.Column>
        </Grid.Row>
        <TimeZoneLabel />
      </Grid>
      <CustomerDetailWebPaywall
        onCancel={() => history.push('/customers/')}
        visible={isUpgradeOpen}
      />
    </Page>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCrmUser: (id, pageNumber, array) =>
      dispatch(getCrmUser(id, pageNumber, array)),
  };
};

const mapStateToProps = ({ crm }) => {
  return {
    loading: crm.loading,
    crmUser: crm.crmUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailPage);
