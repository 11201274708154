import React, { useMemo } from 'react';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';
import { Card, Segment } from 'semantic-ui-react';
import {
  DataKey,
  DataKeys,
  SegmentOptions,
  TData,
} from 'src/api/types/analytics.types';
import { MetricDataType } from 'src/api/types/analytics.types';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { namiMediumGray } from 'src/variables';
import styled from 'styled-components';

import Responsive from '../../../components/Responsive/Responsive';
import { parseChartData, returnDateFormat } from '../../../utils/formatting';
import EmptyInsightsCard from './EmptyInsightsCard';
import InsightsDetailToolbar from './InsightsDetailToolbar';
import SharedLineChart from './SharedLineChart';

type InsightsDetailCardProps<Data> = {
  title: string;
  data: Data[];
  chartKeys: Array<DataKey>;
  loading: boolean;
  description: string;
  reset: () => void;
};

const Title = styled.span`
  font-size: 18px;
  font-weight: bold;
  padding-right: 8px;
`;

const Container = styled(Card)`
  min-height: 380px !important;
  padding: 20px !important;
  box-shadow: 0 2px 10px 0 rgba(51, 51, 79, 0.02) !important;
`;

const Content = styled.div`
  margin: 15px 0 20px;
`;

const LineWrapper = styled.div`
  height: 280px;
  width: 100%;
  min-width: 0;
`;

export default function InsightsDetailCard<Data extends TData>({
  title,
  data: rawData,
  chartKeys,
  loading,
  description,
  reset,
}: InsightsDetailCardProps<Data>) {
  const { interval, segment, hasChanges } = useAppSelector(({ analytic }) => {
    const { interval, segment, hasChanges } = analytic;
    return { interval, segment, hasChanges };
  });
  const dateFormat = returnDateFormat(interval);
  const data = useMemo(() => {
    return parseChartData(
      rawData,
      chartKeys,
      segment ? SegmentOptions[segment] : null,
      dateFormat
    );
  }, [rawData, chartKeys, segment, dateFormat]);

  const chartFormat: MetricDataType = DataKeys[chartKeys[0]].format;

  const zIndex = loading ? { zIndex: 0 } : {};
  const isNotEmpty = rawData.length > 0;

  return (
    <Container forwardedAs={Segment} loading={loading} style={zIndex} fluid>
      <Row>
        <Col span={16}>
          <Title>{title}</Title>
          <Tooltip placement="bottom" title={description}>
            <QuestionCircleOutlined style={{ color: namiMediumGray }} />
          </Tooltip>
        </Col>
        <Col span={8}>
          <InsightsDetailToolbar reset={reset} hasChanges={hasChanges} />
        </Col>
      </Row>
      <Content>
        {isNotEmpty ? (
          <>
            <Responsive size="mdUp">
              <LineWrapper>
                <SharedLineChart
                  data={data}
                  chartStyle={'DESKTOP'}
                  chartFormat={chartFormat}
                />
              </LineWrapper>
            </Responsive>
            <Responsive size="mdDown">
              <LineWrapper>
                <SharedLineChart
                  data={data}
                  chartStyle={'MOBILE'}
                  chartFormat={chartFormat}
                />
              </LineWrapper>
            </Responsive>
          </>
        ) : (
          <EmptyInsightsCard
            title={
              hasChanges
                ? 'No matching data was found. Try using different filters.'
                : `There is not enough data to calculate ${title}`
            }
            hasChanges={hasChanges}
            resetFunction={reset}
            subtitle={!hasChanges ? 'Learn More' : undefined}
          />
        )}
      </Content>
    </Container>
  );
}
