import React, { useState, useEffect } from 'react';

import { Button, Typography } from 'antd';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import {
  getUserAuthenticators,
  createUserAuthenticators,
  disabledUserAuthenticator,
} from '../../../../redux/actions/userActions';
import { copyTextToClipBoard } from '../../../../services/helpers';
import CreateRecoveryCodesModal from './modals/CreateRecoveryCodesModal';
import CreateTOPTModal from './modals/CreateTOPTModal';
import RecoveryCodesModal from './modals/RecoveryCodesModal';

const TOTPSection = ({
  recoveryCodes,
  authenticators,
  getUserAuthenticators,
  createUserAuthenticators,
  disabledUserAuthenticator,
}) => {
  const [modalCreateTOPT, setModalCreateTOPT] = useState(false);
  const [modalCreateRecovery, setModalCreateRecovery] = useState(false);
  const [modalRecoveryCodes, setModalRecoveryCodes] = useState(false);
  const [code, setCode] = useState('');
  const [copy, setCopy] = useState(true);

  useEffect(() => {
    getUserAuthenticators();
  }, [getUserAuthenticators]);

  const finishAuthenticatorActivation = () => {
    setModalCreateTOPT(false);
    setModalCreateRecovery(true);
    setCode('');
  };

  const closeTOPTModal = () => {
    setModalCreateTOPT(false);
    setCode('');
  };
  const finishCreatingAuthenticators = () => {
    setModalCreateRecovery(false);
    setModalRecoveryCodes(true);
    getUserAuthenticators();
    setCopy(true);
  };

  const copyRecoveryCodes = () => {
    copyTextToClipBoard(recoveryCodes);
    toast.success('Copied recovery codes.');
    setCopy(false);
  };

  return (
    <>
      {authenticators && authenticators.length ? (
        <>
          <p>Two-factor authentication is turned on</p>
          <Button
            onClick={() => {
              authenticators.forEach((item) => {
                disabledUserAuthenticator(item.id);
              });
              toast.success('Successfully disabled 2FA');
            }}
          >
            Disable 2FA
          </Button>
        </>
      ) : (
        <>
          <Typography.Paragraph>
            Two-factor authentication (2FA) adds an extra layer of protection to
            your account. Whenever you sign into the Nami website, you’ll need
            to enter both your password and a security code sent to your 2FA
            application. We support using the Google Authenticator, 1Password,
            Microsoft Authenticator, Authy, and other TOTP provider apps to link
            your device.
          </Typography.Paragraph>
          <Button
            type="primary"
            onClick={() => {
              createUserAuthenticators('totp').then((action) => {
                if (action.type.endsWith('SUCCESS')) {
                  setModalCreateTOPT(true);
                }
              });
            }}
          >
            Set up 2FA
          </Button>
        </>
      )}
      <CreateTOPTModal
        open={modalCreateTOPT}
        code={code}
        onCodeChange={setCode}
        onSuccess={finishAuthenticatorActivation}
        onClose={closeTOPTModal}
      />
      <CreateRecoveryCodesModal
        open={modalCreateRecovery}
        onSuccess={finishCreatingAuthenticators}
        onClose={() => setModalCreateRecovery(false)}
      />
      <RecoveryCodesModal
        open={modalRecoveryCodes}
        codes={recoveryCodes}
        copy={copy}
        onSuccess={copyRecoveryCodes}
        onClose={() => setModalRecoveryCodes(false)}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserAuthenticators: () => dispatch(getUserAuthenticators()),
    createUserAuthenticators: (type) =>
      dispatch(createUserAuthenticators(type)),
    disabledUserAuthenticator: (authenticatorId) =>
      dispatch(disabledUserAuthenticator(authenticatorId)),
  };
};

const mapStateToProps = ({ user }) => {
  return {
    authenticators: user.authenticators,
    recoveryCodes: user.recoveryCodes,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TOTPSection);
