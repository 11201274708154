import React from 'react';

import { Tabs } from 'antd';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import styled, { css } from 'styled-components';

import PaywallBuilderDrawer from '../PaywallBuilderDrawer';
import ProductFieldsEditor from './ProductFieldsEditor';
import ProductSelector from './ProductSelector';

const StyledTabs = styled(Tabs)<{ $v2Compatible: boolean }>`
  ${({ $v2Compatible }) => {
    if (!$v2Compatible)
      return css`
        .ant-tabs-nav {
          display: none;
        }
      `;
    return css``;
  }}
`;

export default function ProductEditorDrawer({
  formVersion,
}: {
  formVersion: 'formGroups' | 'v2Form';
}) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const { editingMenuItem, editingMenuTabItem } = useAppSelector(
    ({ paywallBuilder }) => {
      return {
        editingMenuItem: paywallBuilder.editingMenuItem,
        editingMenuTabItem: paywallBuilder.editingProductMenuTabItem,
      };
    }
  );
  let tabItems = [
    {
      label: <>Menu</>,
      key: 'menu',
      children: <ProductSelector />,
    },
  ];

  if (formVersion === 'v2Form') {
    tabItems.push({
      label: <>Fields</>,
      key: 'fields',
      children: <ProductFieldsEditor />,
    });
  }

  return (
    <PaywallBuilderDrawer
      open={editingMenuItem === 'products'}
      title={<span style={{ fontSize: 14 }}>Products</span>}
      closable={false}
      className="verticalScrollDrawer"
    >
      <StyledTabs
        items={tabItems}
        size="small"
        destroyInactiveTabPane={true}
        activeKey={editingMenuTabItem ? editingMenuTabItem : 'menu'}
        $v2Compatible={formVersion === 'v2Form'}
        onChange={(activeKey) =>
          actions.setEditingProductMenuTabItem(activeKey)
        }
      />
    </PaywallBuilderDrawer>
  );
}
