import { apiRequest } from '../../services/apiService';
import * as TYPES from '../types';

export const getDeveloperEntitlements = (appId) => {
  return apiRequest({
    type: TYPES.GET_DEVELOPER_ENTITLEMENTS,
    url: `/private/frontend/v1/events/apps/${appId}/?limit=20`,

    method: 'GET',
  });
};

export const getDeveloperEntitlementsRealTime = (appId) => {
  return apiRequest({
    type: TYPES.GET_DEVELOPER_ENTITLEMENTS_REAL_TIME,
    url: `/private/frontend/v1/events/apps/${appId}/?limit=20`,
    method: 'GET',
  });
};

export const getDeveloperTransactions = (appId) => {
  return apiRequest({
    type: TYPES.GET_DEVELOPER_TRANSACTIONS,
    url: `/private/frontend/v1/apps/${appId}/dev-transactions/?limit=20`,
    method: 'GET',
  });
};

export const getDeveloperTransactionsRealTime = (appId) => {
  return apiRequest({
    type: TYPES.GET_DEVELOPER_TRANSACTIONS_REAL_TIME,
    url: `/private/frontend/v1/apps/${appId}/dev-transactions/?limit=20`,
    method: 'GET',
  });
};

export const getDeveloperImpressions = (appId) => {
  return apiRequest({
    type: TYPES.GET_DEVELOPER_IMPRESSIONS,
    url: `/private/frontend/v1/apps/${appId}/dev-impressions/?limit=20`,
    method: 'GET',
  });
};

export const getDeveloperImpressionsRealTime = (appId) => {
  return apiRequest({
    type: TYPES.GET_DEVELOPER_IMPRESSIONS_REAL_TIME,
    url: `/private/frontend/v1/apps/${appId}/dev-impressions/?limit=20`,
    method: 'GET',
  });
};

export const getDeveloperSessions = (appId) => {
  return apiRequest({
    type: TYPES.GET_DEVELOPER_SESSIONS,
    url: `/private/frontend/v1/apps/${appId}/dev-sessions/?limit=20`,
    method: 'GET',
  });
};

export const getDeveloperSessionsRealTime = (appId) => {
  return apiRequest({
    type: TYPES.GET_DEVELOPER_SESSIONS_REAL_TIME,
    url: `/private/frontend/v1/apps/${appId}/dev-sessions/?limit=20`,
    method: 'GET',
  });
};

export const getDeveloperAppleNotifications = (appId) => {
  return apiRequest({
    type: TYPES.GET_DEVELOPER_APPLE_NOTIFICATIONS,
    url: `/private/frontend/v1/apps/${appId}/dev-apple-notifications/?limit=20`,
    method: 'GET',
  });
};

export const getDeveloperAppleNotificationsRealTime = (appId) => {
  return apiRequest({
    type: TYPES.GET_DEVELOPER_APPLE_NOTIFICATIONS_REAL_TIME,
    url: `/private/frontend/v1/apps/${appId}/dev-apple-notifications/?limit=20`,
    method: 'GET',
  });
};

export const getDeveloperAndroidNotifications = (appId) => {
  return apiRequest({
    type: TYPES.GET_DEVELOPER_ANDROID_NOTIFICATIONS,
    url: `/private/frontend/v1/apps/${appId}/dev-google-notifications/?limit=20`,
    method: 'GET',
  });
};

export const getDeveloperAndroidNotificationsRealTime = (appId) => {
  return apiRequest({
    type: TYPES.GET_DEVELOPER_ANDROID_NOTIFICATIONS_REAL_TIME,
    url: `/private/frontend/v1/apps/${appId}/dev-google-notifications/?limit=20`,
    method: 'GET',
  });
};

export const getDeveloperRecords = () => {
  return { type: TYPES.GET_DEVELOPER_RECORDS };
};

export const getDeveloperRecordsRealTime = () => {
  return { type: TYPES.GET_DEVELOPER_RECORDS_REAL_TIME };
};

export const clearDeveloperRecords = () => {
  return { type: TYPES.CLEAR_DEVELOPER_RECORDS };
};

export const handleDeveloperRecord = (record) => {
  const recordCopy = { ...record };
  delete recordCopy.icon;
  delete recordCopy.frontend_date;
  if (record) {
    return {
      type: TYPES.HANDLE_DEVELOPER_RECORD,
      payload: { record: recordCopy },
    };
  } else {
    return {
      type: TYPES.HANDLE_DEVELOPER_RECORD,
      payload: { record: null },
    };
  }
};

export const handleDeveloperSearch = (value) => {
  return { type: TYPES.HANDLE_DEVELOPER_SEARCH, payload: { value } };
};

export const handleDropdown = (value, text, type) => {
  return {
    type: TYPES.HANDLE_DEVELOPER_DROPDOWN,
    payload: { value, text, type },
  };
};
