import { client } from './clients';
import { AppType } from './types/app.types';
import { TListResult } from './types/main.types';
import { extractResponseData, extractErrorData } from './utils';

const apps = {
  getApps: (): Promise<TListResult<AppType>> =>
    client
      .get('/private/apps/')
      .then(extractResponseData)
      .catch(extractErrorData),
  updateApp: (appId: string, data: Partial<AppType>): Promise<AppType> => {
    return client
      .put(`/private/apps/${appId}/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  deleteApp: (appId: string) => {
    return client
      .delete<void>(`/private/apps/${appId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
};
export default apps;
