export const DEV_MODE = (function (): boolean {
  const value = process.env.REACT_APP_DEV_MODE;
  if (!value) return false;
  try {
    return JSON.parse(value.toLowerCase());
  } catch (e) {
    console.error(e);
    return false;
  }
})();
