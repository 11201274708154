import { toast } from 'react-toastify';

import { getDefaultApp } from '../../services/helpers';
import { getAppId } from '../../services/LocalStorageService';
import * as TYPES from '../types';

const initialState = {
  loading: true,
  error: null,
  apps: [],
  currentApp: null,
  languages: [],
  changes: false,
  updateAppErrors: null,
};

export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.UPDATE_CURRENT_ORG:
    case TYPES.UPDATE_CURRENT_ORG_LOAD:
      return {
        ...state,
      };
    case TYPES.UPDATE_CURRENT_ORG_SUCCESS:
      return {
        ...state,
      };

    case TYPES.UPDATE_CURRENT_ORG_FAIL:
      return {
        ...state,
      };
    case TYPES.GET_APPS:
    case TYPES.GET_APPS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_APPS_SUCCESS:
      const apps = action.payload.data.results;
      return {
        ...state,
        apps,
        currentApp: getDefaultApp(apps, getAppId()),
        loading: false,
      };

    case TYPES.GET_APPS_FAIL:
      return {
        ...state,
        error: action.error.response?.data,
        loading: false,
      };

    case TYPES.CURRENT_APP_UPLOAD_FINISH:
      return {
        ...state,
        currentApp: {
          ...state.currentApp,
          logo: action.payload.response,
        },
        loading: false,
        changes: true,
      };

    case TYPES.HANDLE_CURRENT_APP_FIELD:
      return {
        ...state,
        currentApp: {
          ...state.currentApp,
          [action.payload.key]: action.payload.value,
        },
        changes: true,
      };

    case TYPES.UPDATE_CURRENT_APP:
    case TYPES.UPDATE_CURRENT_APP_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.UPDATE_CURRENT_APP_SUCCESS:
      toast.success('Successfully updated app.');
      return {
        ...state,
        updateAppErrors: null,
        loading: false,
        changes: false,
      };
    case TYPES.UPDATE_CURRENT_APP_FAIL:
      return {
        ...state,
        updateAppErrors: action.error.response?.data,
        loading: false,
      };
    case TYPES.GET_LANGUAGES:
    case TYPES.GET_LANGUAGES_LOAD:
      return {
        ...state,
      };
    case TYPES.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: action.payload.data,
      };
    case TYPES.GET_LANGUAGES_FAIL:
      return {
        ...state,
      };
    case TYPES.CLEAR_ROOT:
      return {
        ...state,
        currentApp: null,
      };
    case TYPES.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
