import { config } from './constants';
import { gradientParser } from './gradientParser';

export type TColorStop = {
  left?: number;
  orientation?: 'directional' | 'angular';
  value: string;
};

const { defaultColor, defaultGradient } = config;

export function low(color: { value: string }): string {
  return color.value.toLowerCase();
}

export function high(color: { value: string }): string {
  return color.value.toUpperCase();
}

export function getColors(value?: string): TColorStop[] {
  let isGradient = value?.includes('gradient');
  if (isGradient) {
    let isConic = value?.includes('conic');
    let safeValue = !isConic && validate(value) ? value : defaultGradient;
    if (isConic) {
      console.log('Sorry we cant handle conic gradients yet');
    }
    const obj = gradientParser(safeValue);
    return obj?.colorStops;
  } else {
    let safeValue = value || defaultColor;
    return [{ value: safeValue }];
  }
}

function validate(color?: string): boolean {
  const img = document.createElement('img') as HTMLImageElement;
  return ['rgb(0, 0, 0)', 'rgb(255, 255, 255)'].every((placeholder) => {
    img.setAttribute('style', 'background: ' + placeholder);
    img.setAttribute('style', 'background: ' + color);
    return img.style.background !== placeholder && img.style.background !== '';
  });
}

export function formatInputValues(
  value: number,
  min: number,
  max: number
): number {
  return isNaN(value) ? min : value < min ? min : value > max ? max : value;
}
