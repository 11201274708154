import { client } from './clients';
import { EventStreamPayloadType, EventStreamType } from './types/main.types';
import {
  extractListResponseData,
  extractResponseData,
  extractErrorData,
} from './utils';

const streams = {
  getStreams: (appId: string): Promise<EventStreamType[]> => {
    return client
      .get(`/private/event_streams/?app_id=${appId}`)
      .then(extractListResponseData)
      .catch(extractErrorData);
  },
  getStream: (streamId: string): Promise<EventStreamType> => {
    return client
      .get(`/private/event_streams/${streamId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  addStream: (data: EventStreamPayloadType): Promise<EventStreamType> => {
    return client
      .post('/private/event_streams/', data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  updateStream: (
    streamId: string,
    data: Promise<EventStreamPayloadType>
  ): Promise<EventStreamType> => {
    return client
      .patch(`/private/event_streams/${streamId}/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  deleteStream: (streamId: string): Promise<void> => {
    return client
      .delete(`/private/event_streams/${streamId}/`)
      .catch(extractErrorData) as unknown as Promise<void>;
  },
  rotateSigningSecret: (streamId: string): Promise<string> => {
    return client
      .post(`/private/event_streams/${streamId}/rotate_signing_secret/`)
      .then(extractResponseData)
      .then(({ signing_secret }) => signing_secret)
      .catch(extractErrorData);
  },
};

export default streams;
