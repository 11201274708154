import { useMemo } from 'react';

import {
  CaseReducerActions,
  SliceCaseReducers,
} from '@reduxjs/toolkit/src/createSlice';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import type { RootState, AppDispatch } from '../redux';

export const useAppDispatch = useDispatch as () => AppDispatch;
export const useAppSelector = useSelector as TypedUseSelectorHook<RootState>;

export function useActions<
  T extends CaseReducerActions<SliceCaseReducers<any>, any>
>(actions: T): T {
  const dispatch = useAppDispatch();
  return useMemo(() => {
    return Object.entries(actions).reduce((output, [key, value]) => {
      const wrappedValue = ((...args: any[]) =>
        // @ts-ignore
        dispatch(value(...args))) as typeof value;
      return { ...output, [key]: wrappedValue };
    }, {} as T);
  }, [dispatch, actions]);
}
