import { namiBrightGreen, namiPureWhite } from 'src/variables';
import styled from 'styled-components';

const LiveBadgeDot = styled.span`
  border-radius: 50%;
  border: 1px solid ${namiPureWhite};
  background-color: ${namiBrightGreen};
  width: 9px;
  height: 9px;
  display: inline-flex;
  box-shadow: 0.5px 0.5px 7px 0px rgba(76, 84, 90, 0.16);
`;

export default LiveBadgeDot;
