import { apiRequest } from '../../services/apiService';
import * as TYPES from '../types';

export const getCitations = (appId) => {
  return apiRequest({
    type: TYPES.GET_CITATIONS,
    url: `/private/legal_citations/?app_id=${appId}`,
    method: 'GET',
  });
};

export const getCitation = (citationId) => {
  return apiRequest({
    type: TYPES.GET_CITATION,
    url: `/private/legal_citations/${citationId}/`,
    method: 'GET',
  });
};

export const addCitation = (data) => {
  return apiRequest({
    type: TYPES.ADD_CITATION,
    url: '/private/legal_citations/',
    method: 'POST',
    data,
    options: {
      onSuccess({ next, dispatch, response }) {
        dispatch(getCitations(data.app));
        const nextAction = {
          type: TYPES.ADD_CITATION_SUCCESS,
          payload: response,
        };
        next(nextAction);
        return nextAction;
      },
    },
  });
};

export const updateCitation = (citationId, data) => {
  return apiRequest({
    type: TYPES.UPDATE_CITATION,
    url: `/private/legal_citations/${citationId}/`,
    method: 'PUT',
    data,
  });
};

export const deleteCitation = (citationId) => {
  return apiRequest({
    type: TYPES.DELETE_CITATION,
    url: `/private/legal_citations/${citationId}/`,
    method: 'DELETE',
  });
};

export const handleCitationsField = (citationId, key, value) => {
  return {
    type: TYPES.HANDLE_CITATIONS_FIELD,
    payload: {
      id: citationId,
      key,
      value,
    },
  };
};

export const handleCitationField = (key, value) => {
  return {
    type: TYPES.HANDLE_CITATION_FIELD,
    payload: {
      key,
      value,
    },
  };
};
