import React from 'react';

import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu, Sidebar } from 'semantic-ui-react';
import { namiNavy } from 'src/variables';

import { pathBeginMatches } from '../../../services/helpers/';
import NamiIcon from '../../NamiIcon/NamiIcon';
import styles from './MobileMenu.module.less';

const MobileMenu = ({ items, visible, onClick, Logout }) => {
  const location = useLocation();
  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      inverted
      vertical
      visible={visible}
      onHide={onClick}
      style={{
        backgroundColor: namiNavy,
        display: 'flex',
        justifyContent: 'top',
        boxShadow: '20px 0 20px rgba(34,36,38,.15)',
      }}
    >
      {items.map(
        (menu, index) =>
          menu.enabled && (
            <Menu.Item
              key={index}
              as={NavLink}
              to={menu.path}
              onClick={onClick}
              active={pathBeginMatches(location.pathname, menu.path)}
            >
              <NamiIcon className={styles.icon} icon={menu.iconName} />
              <span className={styles.iconLabel}>{menu.name}</span>
            </Menu.Item>
          )
      )}
      <Menu.Item as="a" href="https://learn.namiml.com" target="_blank">
        <NamiIcon className={styles.icon} icon="help" />
        <span className={styles.iconLabel}>Help Center</span>
      </Menu.Item>
      <Menu.Item as={NavLink} to="/settings" onClick={onClick}>
        <NamiIcon className={styles.icon} icon="settings" />
        <span className={styles.iconLabel}>Settings</span>
      </Menu.Item>
      <Menu.Item onClick={Logout}>
        <NamiIcon className={styles.icon} icon="logout" />
        <span className={styles.iconLabel}>Log Out</span>
      </Menu.Item>
    </Sidebar>
  );
};

MobileMenu.propTypes = {
  items: PropTypes.array.isRequired,
  visible: PropTypes.bool,
  currentApp: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  Logout: PropTypes.func.isRequired,
};

export default MobileMenu;
