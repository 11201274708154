export const handleModalErrors = (error, dispatch, closeModal) => {
  const { data } = error.response;
  const shouldClose =
    new Set([500, 404]).has(error.response.status) ||
    data.detail?.length ||
    data.non_field_errors ||
    data.token?.length;

  if (shouldClose) {
    dispatch(closeModal());
  }
};
