import React, { useMemo } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Button, Input, Select } from 'antd';
import { arraysAreEqual } from 'src/utils/array';

import {
  TFilterIdentifier,
  TFilterOperator,
} from '../../../../../api/types/campaign.types';
import { FilterDescription } from './FilterDescription';
import FilterWrapper from './FilterWrapper';
import {
  identifierIconMap,
  operatorLabelByIdentifierMap,
  operatorLabelMap,
  TOption,
} from './mapsAndOptions';
import StyledSelect from './StyledSelect';

export type MultiSelectProps = BaseFilterSelectProps & {
  label: string;
  allowMultiple?: boolean;
  options?: TOption[];
  operators: TFilterOperator[];
};

export type VersionSelectProps = BaseFilterSelectProps & {
  label: string;
  type?: 'version' | 'string';
  operators: TFilterOperator[];
};

export type BaseFilterSelectProps = {
  operator: TFilterOperator;
  values: string[];
  className?: string;
  identifier: TFilterIdentifier;
  disabled?: boolean;
  onValueChange(values: string[]): void;
  onOperatorChange(operator: TFilterOperator): void;
  onRemove(): void;
  loading?: boolean;
};

type AllFilterSelectProps = MultiSelectProps &
  VersionSelectProps & {
    variant: 'select' | 'manual_value';
  };

export default function FilterSelect({
  allowMultiple = true,
  onValueChange,
  onRemove,
  values,
  operator,
  options,
  identifier,
  loading,
  onOperatorChange,
  label,
  variant = 'select',
  operators,
}: AllFilterSelectProps) {
  const optionValues = useMemo(
    () =>
      (options || []).filter(
        ({ value }) =>
          values.includes(value) || values.includes(value.toLowerCase())
      ),
    [values, options]
  );

  const empty = 'not selected';

  const operatorLabel =
    operatorLabelByIdentifierMap[identifier] &&
    operatorLabelByIdentifierMap[identifier][operator]
      ? operatorLabelByIdentifierMap[identifier][operator]
      : operatorLabelMap[operator];

  const value = values[0] || '';

  const overrideOperators: TFilterOperator[] = [
    'contains',
    'not_contains',
    'i_contains',
    'not_i_contains',
  ];

  //If operators list is just 2 contains values, change labels to equals/not equals
  const operatorOptions: { value: TFilterOperator; label: string }[] =
    operators.reduce((output, operator) => {
      if (
        shouldOverrideOperatorLabels() &&
        overrideOperators.includes(operator)
      ) {
        return [
          ...output,
          {
            value: operator,
            label: ['contains', 'i_contains'].includes(operator)
              ? 'equals'
              : 'does not equal',
          },
        ];
      }
      return [
        ...output,
        {
          value: operator,
          label: operatorLabelMap[operator],
        },
      ];
    }, [] as { value: TFilterOperator; label: string }[]);

  return (
    <FilterWrapper>
      <FilterDescription label={label} icon={identifierIconMap[identifier]} />
      <Select
        value={operator}
        onChange={(value) => onOperatorChange(value as TFilterOperator)}
        dropdownMatchSelectWidth={false}
        style={{ minWidth: 100, width: 'fit-content' }}
      >
        {operatorOptions.map((operatorValue) => (
          <Select.Option
            key={operatorValue.value}
            value={operatorValue.value}
            label={operatorValue.label}
          >
            {operatorValue.label}
          </Select.Option>
        ))}
      </Select>
      {variant === 'select' ? (
        <StyledSelect
          mode={allowMultiple ? 'multiple' : undefined}
          showSearch
          showArrow={false}
          optionFilterProp="text"
          status={optionValues.length === 0 ? 'error' : undefined}
          options={options}
          value={optionValues.length > 0 ? optionValues : [empty]}
          operator={operatorLabel}
          onChange={handleChange}
          loading={loading}
          dropdownMatchSelectWidth={false}
        />
      ) : (
        <Input
          value={value}
          onChange={handleValueChange}
          placeholder={`Enter value for ${label}`}
          style={{ width: 325 }}
        />
      )}
      <Button
        type="text"
        icon={<CloseOutlined style={{ fontSize: 14 }} />}
        onClick={onRemove}
      />
    </FilterWrapper>
  );

  function shouldOverrideOperatorLabels(): boolean {
    const containsArray: TFilterOperator[] = ['contains', 'not_contains'];
    const iContainsArray: TFilterOperator[] = ['i_contains', 'not_i_contains'];

    return (
      arraysAreEqual(operators, containsArray) ||
      arraysAreEqual(operators, iContainsArray) ||
      operators.length === 1
    );
  }

  function handleChange(values: unknown): void {
    const valuesList = (allowMultiple ? values : [values]) as string[];
    onValueChange(valuesList.filter((value) => value !== empty));
  }

  function handleValueChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value;
    onValueChange(value === '' ? [] : [value]);
  }
}
