import React, { MouseEventHandler } from 'react';

import { Button, Drawer, Typography, Space } from 'antd';

import styles from './SaveChangesHeader.module.less';

export interface SaveChangesHeaderProps {
  open: boolean;
  onClick: MouseEventHandler;
  onDismiss: MouseEventHandler;
}

const SaveChangesHeader = ({
  open,
  onClick,
  onDismiss,
}: SaveChangesHeaderProps) => {
  return (
    <Drawer
      bodyStyle={{ padding: 12 }}
      placement="top"
      visible={open}
      height="65px"
      closable={false}
      zIndex={1000000}
      mask={false}
    >
      <Typography.Text strong>Unsaved changes</Typography.Text>
      <div className={styles.actionWrapper}>
        <Space>
          <Button onClick={onDismiss}>Dismiss</Button>
          <Button type="primary" onClick={onClick}>
            Save
          </Button>
        </Space>
      </div>
    </Drawer>
  );
};

export default SaveChangesHeader;
