import React from 'react';

import { connect } from 'react-redux';
import { Modal, Form, Button, Dimmer, Loader, Icon } from 'semantic-ui-react';

import {
  handlePaywallField,
  handlePaywallKeyField,
  resetPaywallKeys,
} from '../../redux/actions/paywallActions';
import { toggleNewKeyValueModal } from '../../redux/actions/uiActions';
import { extractFieldError } from '../../services/utilsService';
import styles from './NewKeyValueModal.module.less';

const NewKeyValueModal = ({
  toggleNewKeyValueModal,
  handlePaywallField,
  handlePaywallKeyField,
  newKeyValueOpen,
  loading,
  paywall,
  paywallKeys,
  resetPaywallKeys,
}) => {
  const onClose = () => {
    toggleNewKeyValueModal();
    resetPaywallKeys();
  };

  const safelyParseJSON = () => {
    let parsed;
    try {
      return (parsed = JSON.parse(paywallKeys.value));
    } catch (e) {
      handlePaywallKeyField('error', 'Invalid format for Value.');
    }
    return parsed;
  };

  const UpdateTable = () => {
    if (safelyParseJSON(paywallKeys.value)) {
      handlePaywallField({
        ...paywall,
        marketing_content: {
          ...paywall.marketing_content,
          extra_data: {
            ...paywall.marketing_content.extra_data,
            ...{ [paywallKeys.key]: safelyParseJSON(paywallKeys.value) },
          },
        },
      });
      onClose();
    }
    if (paywallKeys.value === `""`) {
      handlePaywallField({
        ...paywall,
        marketing_content: {
          ...paywall.marketing_content,
          extra_data: {
            ...paywall.marketing_content.extra_data,
            ...{ [paywallKeys.key]: safelyParseJSON(paywallKeys.value) },
          },
        },
      });
      onClose();
    }
  };

  const renderForm = () => {
    return (
      <Form>
        <Form.Field>
          <Icon color="teal" name="info circle" /> You must click{' '}
          <strong>Update</strong> before leaving the Paywall screen to save any
          added key-values.
        </Form.Field>
        <Form.Input
          label="Key"
          name="key"
          type="text"
          value={paywallKeys.key}
          onChange={(e) =>
            handlePaywallKeyField(
              e.target.name,
              e.target.value.replace(/ /g, '_')
            )
          }
        />
        <small style={{ position: 'relative', top: '-12px' }}>
          <strong>Note:</strong> If this key already exists, the value for that
          key will be updated.
        </small>
        <Form.TextArea
          label="Value"
          name="value"
          type="text"
          rows="8"
          value={paywallKeys.value}
          error={extractFieldError(paywallKeys?.error)}
          onChange={(e) => handlePaywallKeyField(e.target.name, e.target.value)}
        />
        <small style={{ position: 'relative', top: '-12px' }}>
          <strong>Note:</strong> Value may be a number, string, array, or JSON
          object. Enter strings in double quotes. JSON objects must also use
          double quotes. Example: {`{"number": 2, "key": "value"}`}
        </small>
      </Form>
    );
  };

  const disabled = () => {
    if (paywallKeys.key && paywallKeys.value) {
      return false;
    }
    return true;
  };

  return (
    <Modal
      dimmer="blurring"
      open={newKeyValueOpen}
      size="tiny"
      closeOnDimmerClick={true}
      onClose={onClose}
    >
      <Modal.Header className={styles.header}>Add a New Key Value</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Dimmer active={loading} inverted>
            <Loader content="Adding ..." />
          </Dimmer>
          {renderForm()}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button.Group widths="2">
          <Button onClick={onClose} fluid>
            Cancel
          </Button>
          <Button.Or />
          <Button
            className={'nami-primary-button'}
            disabled={disabled()}
            onClick={() => UpdateTable()}
            fluid
          >
            Add
          </Button>
        </Button.Group>
      </Modal.Actions>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlePaywallField: (data) => dispatch(handlePaywallField(data)),
    handlePaywallKeyField: (key, value) =>
      dispatch(handlePaywallKeyField(key, value)),
    toggleNewKeyValueModal: () => dispatch(toggleNewKeyValueModal()),
    resetPaywallKeys: () => dispatch(resetPaywallKeys()),
  };
};

const mapStateToProps = ({ paywall, ui }) => {
  return {
    newKeyValueOpen: ui.newKeyValueOpen,
    paywall: paywall.paywall,
    paywallKeys: paywall.paywallKeys,
    loading: paywall.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewKeyValueModal);
