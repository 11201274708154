import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './List.module.less';

const ListRecord = (props) => {
  const { children, className, weight, textAlign } = props;

  return (
    <div
      className={cx(
        styles.listRecord,
        styles[weight],
        styles[textAlign],
        className
      )}
    >
      {children}
    </div>
  );
};

ListRecord.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ListRecord;
