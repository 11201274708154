import axios, { AxiosRequestConfig } from 'axios';

import { API_URL } from '../config';
import Token from '../services/token';
import auth from './auth.api';
import { handlePreAuthResponseError } from './utils';

export const baseURL = API_URL && API_URL.replace(/\/$/, '');

const bareClient = axios.create({ baseURL, responseType: 'json' });
const preAuthClient = axios.create({ baseURL, responseType: 'json' });
const client = axios.create({ baseURL, responseType: 'json' });

client.interceptors.request.use(prepareRequest);
preAuthClient.interceptors.request.use(preparePreAuthRequest);
preAuthClient.interceptors.response.use(undefined, handlePreAuthResponseError);

function mergeConfigToken(
  config: AxiosRequestConfig,
  token: string | Token
): AxiosRequestConfig {
  return {
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${token}` },
  };
}

function prepareRequest(
  config: AxiosRequestConfig
): AxiosRequestConfig | Promise<AxiosRequestConfig> {
  return getAccessToken().then((token) =>
    token ? mergeConfigToken(config, token) : config
  );
}

export async function getAccessToken(): Promise<null | string> {
  const accessToken = Token.getAccess();
  const refreshToken = Token.getRefresh();
  if (accessToken.isUsable()) return accessToken.toString();
  if (!refreshToken.isUsable()) {
    Token.removeAll();
    return null;
  }
  return auth.generateToken(accessToken.getOrgId());
}

function preparePreAuthRequest(config: AxiosRequestConfig): AxiosRequestConfig {
  const preToken = Token.getPreAuth();
  return preToken.isValid() ? mergeConfigToken(config, preToken) : config;
}

export { bareClient, preAuthClient, client };
