import React from 'react';

import { ExclamationCircleFilled, EyeOutlined } from '@ant-design/icons';
import {
  Button,
  ConfigProvider,
  Empty,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { TProductEntitlement } from 'src/api/types/sku.types';
import { namiDarkOrange, namiLightOrange } from 'src/variables';

type ProductEntitlementTableProps = {
  isLoading: boolean;
  entitlements: TProductEntitlement[] | undefined;
};

export default function ProductEntitlementTable({
  isLoading,
  entitlements,
}: ProductEntitlementTableProps) {
  const history = useHistory();
  const entitlementColumns: ColumnsType<TProductEntitlement> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Reference ID',
      dataIndex: 'entitlement_ref_id',
      key: 'entitlement_ref_id',
      responsive: ['md'],
    },
    {
      title: 'Associated Date',
      dataIndex: 'associated_date',
      key: 'associated_date',
      render: ({ associated_date }: TProductEntitlement) => {
        return (
          <Tooltip title={moment(associated_date).format('YYYY-MM-DD h:mm A')}>
            <span>{moment(associated_date).fromNow()}</span>
          </Tooltip>
        );
      },
      responsive: ['lg'],
    },
    {
      title: 'Actions',
      key: 'action',
      render: (_: any, record: TProductEntitlement) => (
        <Button
          type="link"
          icon={<EyeOutlined />}
          href={'/entitlements/' + record.id + '/'}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <>
      <Typography.Title level={5} style={{ marginTop: '48px' }}>
        Associated Entitlements
      </Typography.Title>
      <Typography.Paragraph>
        Associated entitlements are granted when this product SKU is purchased
        on device.
      </Typography.Paragraph>
      <ConfigProvider
        renderEmpty={() => (
          <Empty imageStyle={{ display: 'none' }} description={<></>}>
            <Tag
              color="warning"
              icon={<ExclamationCircleFilled />}
              style={{
                color: namiDarkOrange,
                borderColor: namiLightOrange,
                marginBottom: '16px',
              }}
            >
              <Button
                type="link"
                size="small"
                style={{ color: namiDarkOrange, fontSize: '1em' }}
                onClick={() => history.push('/entitlements/')}
              >
                0 Configured Entitlements
              </Button>
            </Tag>
          </Empty>
        )}
      >
        <Table
          dataSource={entitlements}
          columns={entitlementColumns}
          pagination={false}
          loading={isLoading}
          bordered={true}
        />
      </ConfigProvider>
    </>
  );
}
