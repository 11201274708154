import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { TOrgSecretKindEnum } from 'src/api/types/main.types';
import { useStoreCredentialsForm } from 'src/hooks/ant-form.hooks';

import CredentialsForm from './CredentialsForm';
import SecretFileUpload from './SecretFileUpload';
import { buildFormItemProps } from './utils';

export default function GoogleCredentialsForm() {
  const form = useStoreCredentialsForm('google');

  const isSet = !!form.secrets.google_service_account;
  return (
    <CredentialsForm
      form={form}
      colon={false}
      layout="vertical"
      onFieldsChange={form.handleFieldsChange}
      className="intercom-googlestorecredentials"
    >
      <SecretFileUpload
        label="Service Account Credentials"
        tooltipContent={
          <>
            Service Account Credentials can be found in Google Play Console{' '}
            {'> '}
            Setup API Access.
            <Button
              size="small"
              type="link"
              href="https://learn.namiml.com/public-docs/integrations/billing-platforms/google-integration/service-account"
              target="_blank"
            >
              Learn more.
            </Button>
          </>
        }
        {...buildFormItemProps(
          [TOrgSecretKindEnum.GoogleServiceAccount, 'secret'],
          form.errors,
          { topLevelError: true }
        )}
        onChange={form.updateFieldValue}
        fileType=".json"
        disabled={isSet}
        onContentUpload={(content) => {
          try {
            return JSON.parse(content);
          } catch (error) {
            return null;
          }
        }}
      />
      <Space style={{ marginTop: '1rem' }}>
        <Button
          type="primary"
          disabled={isSet || !form.canCreate}
          loading={form.createMutation.isLoading}
          onClick={() => {
            const data = form.getFieldsValue().google_service_account || {};
            data.secret ||= null; // This triggers correct backend validation
            const kind = TOrgSecretKindEnum.GoogleServiceAccount;
            form.createMutation.mutate([{ ...data, kind }]);
          }}
        >
          Save
        </Button>
        <Button
          danger
          type="link"
          loading={form.deleteMutation.isLoading}
          disabled={!isSet || !form.canDelete}
          onClick={() => {
            const secretId = form.secrets.google_service_account?.id;
            if (!secretId) return;
            form.deleteMutation.mutate([secretId]);
          }}
        >
          <Space>
            <DeleteOutlined />
            Delete these credentials
          </Space>
        </Button>
      </Space>
    </CredentialsForm>
  );
}
