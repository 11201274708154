export const getAppId = () => {
  return window.localStorage.getItem('appId');
};

export const setAppId = (id) => {
  window.localStorage.setItem('appId', id);
};

export const removeAppId = () => {
  window.localStorage.removeItem('appId');
};
