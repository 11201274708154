import { UserType } from './api/types/main.types';

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    analytics: any;
  }
}

export default class SegmentClient {
  // TODO: Bring logic from `src/components/SegmentIO/SegmentIO.js`

  static identifyUser(user: UserType): void {
    window.analytics.identify(
      user.id,
      {
        name: `${user.first_name} ${user.last_name}`,
        firstname: user.first_name,
        lastname: user.last_name,
        email: user.username,
        org_ids: user.orgs.map((org) => org.id).join(','),
        org_names: user.orgs.map((org) => org.name).join(','),
      },
      {
        Intercom: {
          user_hash: user.intercom_hash,
        },
      }
    );
  }

  static trackPage(title: string, data: { [key: string]: any }): void {
    window.analytics.page(title, data);
  }

  static trackRegistration(): void {
    window.analytics.track('Registration Complete', null);
  }

  static track(event: string, context?: { [key: string]: any }): void {
    window.analytics.track(event, context || null);
  }
}
