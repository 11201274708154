import React from 'react';

import { Select, Tooltip } from 'antd';
import { createSelector } from 'reselect';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

import type { RootState } from '../../../../redux';

const selector = createSelector(
  [
    ({ paywallBuilder: { launch } }: RootState) => launch.productGroups,
    ({ paywallBuilder: { productGroups } }: RootState) => productGroups,
  ],
  (currentGroups, allGroups) => ({
    currentGroups,
    groupOptions: allGroups.map((group) => ({
      label: group.display_name,
      value: group.ref,
    })),
  })
);

export default function ProductGroupLaunchPicker() {
  const { groupOptions, currentGroups } = useAppSelector(selector);
  const actions = useActions(PaywallBuilderSlice.actions);

  return (
    <Tooltip
      title="Pick a list of product groups to preview"
      mouseEnterDelay={0.8}
      placement="right"
    >
      <Select
        mode="multiple"
        options={groupOptions}
        onChange={actions.setLaunchProductGroups}
        value={currentGroups}
        style={{ width: 'fitContent', minWidth: 200 }}
        placeholder="Select product groups"
        maxTagTextLength={12}
        maxTagCount={2}
        maxTagPlaceholder={(omittedValues) => <>+{omittedValues.length} more</>}
        size="small"
        filterOption={(input, option) =>
          (option?.label || '').toLowerCase().includes(input.toLowerCase()) ||
          (option?.value || '').toLowerCase().includes(input.toLowerCase())
        }
      />
    </Tooltip>
  );
}
