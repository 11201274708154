import React from 'react';

import {
  AppstoreOutlined,
  CodeOutlined,
  GiftOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  TagsOutlined,
  ToolOutlined,
} from '@ant-design/icons';
import { InterestsOutlined } from '@mui/icons-material';
import { Button, Menu, Tooltip } from 'antd';
import { useAppContext } from 'src/hooks';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { namiLightGray, namiSmoke } from 'src/variables';
import styled from 'styled-components';

const DrawerDiv = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1002;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin-top: 56px;
  width: 56px;
  background-color: ${namiSmoke};
  overflow: hidden;
  border-right: 1px solid ${namiLightGray};
  padding-bottom: 18px;
`;

export default function PaywallVerticalMenu({
  onOpenSettings,
  hasCapabilities,
}: {
  onOpenSettings: () => void;
  hasCapabilities: boolean;
}) {
  const appContext = useAppContext();
  const editingMenuItem = useAppSelector(
    ({ paywallBuilder: { editingMenuItem } }: RootState) => editingMenuItem
  );
  const actions = useActions(PaywallBuilderSlice.actions);

  let menuItems = [
    {
      label: (
        <Tooltip
          placement="right"
          title={'Components'}
          className="intercom-builder-components"
        >
          {hasCapabilities ? (
            <InterestsOutlined style={{ height: 17, width: 17 }} />
          ) : (
            <AppstoreOutlined />
          )}
        </Tooltip>
      ),
      key: 'components',
    },
    {
      label: (
        <Tooltip
          placement="right"
          title={'Products'}
          className="intercom-builder-products"
        >
          <TagsOutlined />
        </Tooltip>
      ),
      key: 'products',
    },
  ];
  if (hasCapabilities) {
    menuItems.push({
      label: (
        <Tooltip placement="right" title={'Capabilities'}>
          <ToolOutlined />
        </Tooltip>
      ),
      key: 'capabilities',
    });
  }
  if (appContext.userHasEntitlement('app.paywall.edit_json')) {
    menuItems.push({
      key: 'template',
      label: (
        <Tooltip
          placement="right"
          title={'Template'}
          className="intercom-builder-rawjson"
        >
          <CodeOutlined />
        </Tooltip>
      ),
    });
  }

  return (
    <DrawerDiv>
      <Menu
        mode="inline"
        selectedKeys={editingMenuItem ? [editingMenuItem] : undefined}
        onClick={(info) => {
          actions.setEditingProductId(null);
          actions.setFieldGroupName(null);
          actions.setEditingSlideId(null);
          actions.setEditingMenuItem(
            editingMenuItem === info.key ? null : info.key
          );
        }}
        style={{ backgroundColor: namiSmoke }}
        items={menuItems}
      />
      <span style={{ flexGrow: 1 }}></span>
      <Tooltip
        placement="right"
        title="Paywall Settings"
        key="settings"
        align={{ offset: [-15, 0] }}
      >
        <Button
          type="text"
          onClick={onOpenSettings}
          className={`intercom-paywall-settings`}
          style={{ width: '100%' }}
        >
          <SettingOutlined />
        </Button>
      </Tooltip>
      {hasCapabilities && (
        <>
          <Tooltip
            placement="right"
            title="Releases"
            key="releases"
            align={{ offset: [-15, 0] }}
          >
            <Button
              type="text"
              href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/releases"
              target="_blank"
              className={`intercom-template-builder-help-center`}
              style={{ width: '100%', marginTop: 8 }}
            >
              <GiftOutlined />
            </Button>
          </Tooltip>
          <Tooltip
            placement="right"
            title="Help Center"
            key="help"
            align={{ offset: [-15, 0] }}
          >
            <Button
              type="text"
              href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator"
              target="_blank"
              className={`intercom-template-builder-help-center`}
              style={{ width: '100%', marginTop: 8 }}
            >
              <QuestionCircleOutlined />
            </Button>
          </Tooltip>
        </>
      )}
    </DrawerDiv>
  );
}
