import React, { useContext, useMemo } from 'react';

import { LockFilled } from '@ant-design/icons';
import { Drawer, Menu, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { useQueryParams } from 'src/hooks';
import styled from 'styled-components';

import { AppContext } from '../../../AppContextProvider';
import Responsive from '../../../components/Responsive/Responsive';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hooks';
import { toggleAll } from '../../../redux/actions/uiActions';
import { colorDarkGrey, namiDarkGray } from '../../../variables';
import { PAGES, TInsightType } from './InsightsPage';
import { useInsightType } from './useInsightType';

type TMenuItem = Required<MenuProps>['items'][number];

const StyledMenu = styled(Menu)`
  background-color: transparent;
  border-right: 0px;

  .ant-menu-item-group > {
    .ant-menu-item-group-title {
      color: ${namiDarkGray};
      font-weight: bold;
      text-transform: uppercase;
      padding: 10px 16px 4px 16px !important;
    }

    .ant-menu-item-group-list > .ant-menu-item {
      margin-block: 0;
      color: ${colorDarkGrey};
      padding-left: 16px !important;
      font-size: 13px !important;
      line-height: 2 !important;
      height: auto !important;
    }
  }
`;

export default function InsightsMenu() {
  const insightType = useInsightType();
  const appContext = useContext(AppContext);
  const dispatch = useAppDispatch();
  const navigation = useAppSelector(({ ui }) => ui.navigation);
  const queryParams = useQueryParams();

  const items: TMenuItem[] = useMemo(() => {
    return [
      buildSection('Revenue', [
        buildItem('Revenue', 'revenue'),
        buildItem('MRR', 'mrr'),
        //buildItem('MRR Movement'),
        //buildItem('Average Revenue per Paying User'),
        //buildItem('Average Revenue per Registered User'),
        buildItem('Paywall Conversion Rate', 'paywalls-conversion-rate'),
        buildItem('Purchases', 'purchases'),
      ]),
      // buildSection('Subscribers', [
      //   buildItem('Active Paid Subscribers'),
      //   buildItem('New Subscribers'),
      //   buildItem('Average MRR per Subscriber'),
      //   buildItem('Subscriber Movement'),
      //   buildItem('Subscriber LTV'),
      //   buildItem('Subscriber Retention'),
      // ]),
      buildSection('Leads & Trials', [
        buildItem('Sessions', 'sessions'),
        //buildItem('Active Users', 'active-users'),
        buildItem('Active Devices', 'active-devices'),
        buildItem('Impressions', 'impressions'),
        buildItem('Trial Starts', 'free-trials'),
        //buildItem('Trial Movement'),
        // buildItem('Trial Conversion Rate', 'free-trial-conversion-rate'),
      ]),
      // buildSection('Churn', [
      //   buildItem('Subscriber Churn'),
      //   buildItem('Revenue Churn'),
      // ]),
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightType, queryParams]);

  const menu = (
    <StyledMenu mode="inline" activeKey={insightType} items={items} />
  );
  return (
    <>
      <Responsive size="mdUp">{menu}</Responsive>
      <Responsive size="mdDown">
        <Drawer
          open={navigation}
          placement="left"
          onClose={() => dispatch(toggleAll())}
        >
          {menu}
        </Drawer>
      </Responsive>
    </>
  );

  function buildSection(label: string, children?: TMenuItem[]): TMenuItem {
    return { key: label + 'Section', children, label, type: 'group' };
  }

  function buildItem(label: string, type?: TInsightType): TMenuItem {
    if (!type) return { key: label, label, disabled: true };
    const onClick = () => dispatch(toggleAll());
    const className =
      insightType === type ? 'ant-menu-item-selected' : undefined;
    const locked = !appContext.userHasEntitlement(PAGES[type].entitlement);
    const link = (
      <Link to={`/insights/${type}/?${queryParams.toString()}`}>
        {locked && <LockFilled style={{ marginRight: '0.5rem' }} />}
        {label}
      </Link>
    );
    return { key: label, label: link, onClick, className };
  }
}
