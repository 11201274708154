import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { UseMutationResult } from '@tanstack/react-query';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { FieldData, NamePath } from 'rc-field-form/lib/interface';

import {
  TOrgSecret,
  TOrgSecretKindEnum,
  TOrgSecretPayload,
} from '../api/types/main.types';
import { AppContext } from '../AppContextProvider';
import { unsetAttr } from '../pages/admin/paywalls/utils/functions';
import {
  TOrgSecretCreationErrors,
  useCreateOrgSecretsMutation,
  useDeleteOrgSecretsMutation,
  useOrgSecretsQuery,
} from './queries/org.hooks';

type TStoreCredentialForm = FormInstance<any> & {
  createMutation: UseMutationResult<
    TOrgSecret[],
    unknown,
    TOrgSecretPayload[],
    unknown
  >;
  deleteMutation: UseMutationResult<void[], unknown, string[], unknown>;
  canCreate: boolean;
  canDelete: boolean;
  secrets: Record<TOrgSecretKindEnum, TOrgSecret | undefined>;
  errors: TOrgSecretCreationErrors;
  clearFieldsErrors: (names: NamePath[]) => void;
  handleFieldsChange: (changedFields: FieldData[]) => void;
  updateFieldValue: (name: NamePath, value: any) => void;
};

export function useEnhancedAntForm<Values = any>(
  formProp?: FormInstance<Values>
) {
  // This is mainly to force re-rendering when calling "setFieldValue" or "setFieldsValue"
  const [, setFlag] = useState(false);
  const [form] = Form.useForm<Values>(formProp);

  return useMemo(() => {
    return {
      ...form,
      setFieldValue: (...args) => {
        form.setFieldValue(...args);
        setFlag((flag) => !flag);
      },
      setFieldsValue: (...args) => {
        form.setFieldsValue(...args);
        setFlag((flag) => !flag);
      },
    } as typeof form;
  }, [form]);
}

export function useStoreCredentialsForm(
  kindPrefix: string
): TStoreCredentialForm {
  const { userHasEntitlement } = useContext(AppContext);
  const orgSecretsQuery = useOrgSecretsQuery();
  const [form] = Form.useForm<Record<string, any>>();

  const createMutation = useCreateOrgSecretsMutation();
  const deleteMutation = useDeleteOrgSecretsMutation(form.resetFields);
  const errors = createMutation.error as TOrgSecretCreationErrors;
  const [internalErrors, setInternalErrors] = useState(errors);

  const canCreate = userHasEntitlement('org.secret.create');
  const canDelete = userHasEntitlement('org.secret.delete');

  const secrets = useMemo(() => {
    return (orgSecretsQuery.data?.results || [])
      .filter(({ kind }) =>
        kind.toLowerCase().startsWith(kindPrefix.toLowerCase())
      )
      .reduce((output, secret) => {
        return { ...output, [secret.kind]: secret };
      }, {} as Record<TOrgSecretKindEnum, TOrgSecret | undefined>);
  }, [kindPrefix, orgSecretsQuery.data?.results]);

  useEffect(() => {
    form.setFieldsValue(secrets as any);
  }, [form, secrets]);

  useEffect(() => {
    setInternalErrors(errors);
  }, [errors]);

  const clearFieldsErrors = useCallback((names: NamePath[]): void => {
    setInternalErrors((state) => {
      return names.reduce((state, name) => {
        const path = Array.isArray(name) ? name : [name];
        return unsetAttr(state, ...path);
      }, state);
    });
  }, []);

  const handleFieldsChange = useCallback(
    (changedFields: FieldData[]) => {
      clearFieldsErrors(changedFields.map(({ name }) => name));
    },
    [clearFieldsErrors]
  );
  const updateFieldValue = useCallback(
    (name: NamePath, value: any) => {
      form.setFieldValue(name, value);
      clearFieldsErrors([name]);
    },
    [clearFieldsErrors, form]
  );

  return useMemo(
    () => ({
      ...form,
      createMutation,
      deleteMutation,
      canCreate,
      canDelete,
      secrets,
      errors: internalErrors,
      clearFieldsErrors,
      handleFieldsChange,
      updateFieldValue,
    }),
    [
      form,
      createMutation,
      deleteMutation,
      canCreate,
      canDelete,
      secrets,
      internalErrors,
      clearFieldsErrors,
      handleFieldsChange,
      updateFieldValue,
    ]
  );
}
