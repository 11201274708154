import React, { ProviderProps } from 'react';

import * as Icons from '@ant-design/icons';
import { IconContextProps } from '@ant-design/icons/lib/components/Context';

export type TIconName = keyof typeof Icons;

type AntIconProps = Omit<
  { name: TIconName } & ProviderProps<IconContextProps>,
  'value'
>;

export default function AntIcon({ name, ...props }: AntIconProps) {
  const Icon = !Icons[name]
    ? Icons['QuestionOutlined']
    : (Icons[name] as React.FC);
  return <Icon {...props} />;
}
