import { namiSmoke } from 'src/variables';
import styled from 'styled-components';

const FilterWrapper = styled.div`
  display: flex;
  padding: 6px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 3px;
  background: ${namiSmoke};
  width: fit-content;
  flex-wrap: wrap;
`;

export default FilterWrapper;
