import React from 'react';

import { UnlockFilled } from '@ant-design/icons';
import { Col, Modal, Row, Typography, notification } from 'antd';
import api from 'src/api';
import { TDisplayProduct, TierNames } from 'src/api/types/main.types';
import { useAppContext } from 'src/hooks';
import { DEV_MODE } from 'src/utils/dev';
import { namiBrightBlue } from 'src/variables';
import styled from 'styled-components';

import { ModalSubtitle, WebPaywallProps } from './UpgradeWebPaywall';
import WebPaywallProductCard from './WebPaywallProductCard';

const ModalFooterText = styled.p`
  font-size: 12px;
  font-style: italic;
`;
type CrossgradeWebPaywallProps = WebPaywallProps & {
  modalTitle: string;
  maskBackground?: boolean;
  paywallsOnlyProduct: TDisplayProduct;
  paywallsAndSubsProduct: TDisplayProduct;
  entitlement: string;
};

const CrossgradeWebPaywall = ({
  modalTitle,
  maskBackground,
  paywallsOnlyProduct,
  paywallsAndSubsProduct,
  entitlement,
  onCancel,
  visible,
}: CrossgradeWebPaywallProps) => {
  const context = useAppContext();
  const userCanUpdatePlan = context.userHasEntitlement(
    'org.billing.plan.update'
  );

  return (
    <Modal
      width={900}
      title={
        <>
          <Typography.Title level={4}>
            <UnlockFilled style={{ color: namiBrightBlue, paddingRight: 5 }} />{' '}
            Upgrade your Plan
          </Typography.Title>
          <ModalSubtitle>
            Unlock {modalTitle} on the{' '}
            <b>{TierNames['paywallsAndSubscriptions']}</b> plan.
          </ModalSubtitle>
        </>
      }
      centered
      onCancel={onCancel}
      open={visible}
      footer={null}
      maskStyle={
        maskBackground
          ? {
              backdropFilter: 'blur(10px)',
              WebkitBackdropFilter: 'blur(10px)',
            }
          : undefined
      }
      maskClosable={maskBackground}
    >
      <div className="plan-card-wrapper">
        <Row gutter={16} justify="center">
          <Col sm={24} md={12} lg={8} key={0}>
            <WebPaywallProductCard
              product={paywallsOnlyProduct}
              userCanPurchase={userCanUpdatePlan}
              featured={false}
              orgHasProduct={true}
              requestUpgrade={performRequestUpgrade}
              purchaseLink={buildStripeLink(
                getPurchaseLinkForProduct(paywallsOnlyProduct)
              )}
              useTierForTitle={true}
            />
          </Col>
          <Col sm={24} md={12} lg={8} key={1}>
            <WebPaywallProductCard
              compProduct={paywallsOnlyProduct}
              product={paywallsAndSubsProduct}
              userCanPurchase={userCanUpdatePlan}
              featured={true}
              orgHasProduct={false}
              requestUpgrade={performRequestUpgrade}
              purchaseLink={buildStripeLink(
                getPurchaseLinkForProduct(paywallsAndSubsProduct)
              )}
              useTierForTitle={true}
            />
          </Col>
        </Row>
        <Row gutter={16} justify="center" style={{ marginTop: 24 }}>
          <ModalFooterText>
            * Starts at $1/month per additional 1,000 impressions ** Starts at
            $1/month per additional $1,000 MTR.
          </ModalFooterText>
        </Row>
      </div>
    </Modal>
  );

  function getPurchaseLinkForProduct(product: TDisplayProduct) {
    if (DEV_MODE) return product.purchaseLinkStaging;
    return product.purchaseLinkProd;
  }

  function buildStripeLink(purchaseUrl?: string): string | undefined {
    if (!purchaseUrl) return undefined;

    const orgId = context.selectedOrg?.id;
    const username = context.user?.username;

    const params = new URLSearchParams({
      client_reference_id: orgId || '',
      prefilled_email: username || '',
    }).toString();
    return purchaseUrl + '?' + params;
  }

  function performRequestUpgrade() {
    api.requestUpgrade(entitlement).then(() => {
      onCancel();
      notification.success({
        message: 'Thanks',
        description: 'Our team will be in touch to schedule a demo!',
      });
    });
  }
};

export default CrossgradeWebPaywall;
