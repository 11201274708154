import React, { useMemo } from 'react';

import { Col, Row } from 'antd';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import Page from 'src/components/Page/Page';
import { useQueryParams } from 'src/hooks';
import { useUnarchivedCampaignLabelIdQuery } from 'src/hooks/queries/campaign.hooks';

import CampaignMenu from './CampaignMenu';
import CampaignsWrapper from './CampaignsWrapper';

export default function CampaignsPage() {
  const queryParams = useQueryParams();
  const labelId = useMemo(() => {
    return queryParams.get('label_id') || null;
  }, [queryParams]);

  const labelsMap = useUnarchivedCampaignLabelIdQuery();

  const breadcrumbItems: [{ url?: string; name: string }] = useMemo(() => {
    let temp: [{ url?: string; name: string }] = [
      { url: '/campaigns/placements/', name: 'Placements' },
    ];
    if (labelId && labelsMap && labelsMap[labelId]) {
      temp.push({
        url: `/campaigns/?label_id=${labelsMap[labelId].id}`,
        name: `${labelsMap[labelId].display_name || labelsMap[labelId].value}`,
      });
    }
    temp.push({ name: 'All Campaign Rules' });
    return temp;
  }, [labelId, labelsMap]);

  return (
    <Page title="Campaigns">
      <Row justify="start" gutter={[0, 36]}>
        <Col xs={24} style={{ paddingBottom: 16 }}>
          <Breadcrumb items={breadcrumbItems} />
        </Col>
      </Row>
      <CampaignMenu currentKey="rules" />
      <CampaignsWrapper />
    </Page>
  );
}
