import styled, { css } from 'styled-components';

import { TFont } from '../../../../api/types/main.types';
import { buildFontFaceFamily } from '../../../../utils/fonts';

const FontFamilyText = styled.span<{ font: TFont | null }>`
  ${({ font }) => css`
    font-family: '${font && buildFontFaceFamily(font)}', 'Helvetica';
  `}
`;

export default FontFamilyText;
