import { Select } from 'antd';
import styled, { css } from 'styled-components';

import { namiAliceGray, namiLightRed } from '../../../../../variables';

const StyledSelect = styled(Select)<{
  label?: string | null;
  operator?: string;
}>`
  &.ant-select-disabled .ant-select-selection-item-content {
    margin-right: 0;
  }

  .ant-select-selector {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .ant-select-selection-overflow-item {
    display: flex;

    // Except last 2 children
    &:not(:nth-last-child(-n + 2)):after {
      display: flex;
      content: 'or';
      margin-right: 5px;
      align-items: center;
    }
  }

  .ant-select-selection-item {
    background-color: ${namiAliceGray};
    padding-inline-start: 4px;
    ${({ mode }) => {
      if (mode !== undefined) return '';
      return css`
        color: rgba(0, 0, 0, 0.85) !important;
        max-width: fit-content;
        display: flex;
        padding-block: 2px;
        padding-inline: 5px !important;
        max-height: 24px;
        justify-content: center;
        align-items: center;
        border: 1px solid color(rgb 0.9411 0.9412 0.9413);
        border-radius: 2px;
      `;
    }}

    .ant-select-selection-item-remove > .anticon {
      line-height: 0;
    }
  }

  ${({ status }) => {
    if (status !== 'error') return '';
    return css`
      .ant-select-selection-item {
        background-color: ${namiLightRed};
        padding-inline-start: 4px;

        .ant-select-selection-item-content {
          margin-right: 0;
        }

        .ant-select-selection-item-remove {
          display: none;
        }
      }
    `;
  }}
`;

export default StyledSelect;
