import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Space, Radio, Row, Col } from 'antd';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';

import Page from '../../../../components/Page/Page';
import Responsive from '../../../../components/Responsive/Responsive';
import SearchInput from '../../../../components/SearchInput';
import { useAppContext } from '../../../../hooks';
import { useApiOptions } from '../../../../hooks/queries/apiOptions.hooks';
import { useNewLegacyCampaignMutation } from '../../../../hooks/queries/campaign.hooks';
import LegacyCampaignTable from './LegacyCampaignTable';

export default function LegacyCampaignsPage() {
  const { userHasEntitlement } = useAppContext();
  const [status, setStatus] = useState<'all' | 'live'>('all');
  const [apiOptions, updateApiOptions] = useApiOptions({ pageSize: 10 });
  const addLegacyCampaignMutation = useNewLegacyCampaignMutation();

  function addNewCampaign() {
    addLegacyCampaignMutation.mutate();
  }

  return (
    <Page title="Campaigns">
      <Responsive size="mdUp">
        <Row justify="start" gutter={[0, 36]}>
          <Col xs={24} style={{ paddingBottom: 16 }}>
            <Breadcrumb items={[{ name: 'Legacy Campaigns' }]} />
          </Col>
        </Row>
        <Space style={{ marginBottom: 15 }}>
          Status:
          <Radio.Group
            value={status}
            onChange={(e) => {
              updateApiOptions({ page: 1 });
              setStatus(e.target.value);
            }}
            className="intercom-selectCampaignStatus"
          >
            <Radio.Button value="all">All</Radio.Button>
            <Radio.Button value="live">Live</Radio.Button>
          </Radio.Group>
          <SearchInput
            placeholder={'Search by campaign name'}
            style={{ minWidth: '30rem', marginLeft: '1rem' }}
            value={apiOptions.search || ''}
            onChange={(search) => updateApiOptions({ search })}
          />
        </Space>
        <Button
          type="primary"
          style={{ float: 'right' }}
          onClick={addNewCampaign}
          disabled={!userHasEntitlement('app.campaign.create')}
          className="intercom-addCampaign"
        >
          Add Campaign
        </Button>
      </Responsive>
      <Responsive size="mdDown">
        <Row justify="start" gutter={[0, 36]}>
          <Col xs={24} style={{ paddingBottom: 12 }}>
            <Breadcrumb items={[{ name: 'Legacy Campaigns' }]} />
          </Col>
        </Row>
        <SearchInput
          placeholder="Search by campaign name"
          style={{ marginBottom: '1rem' }}
          value={apiOptions.search || ''}
          onChange={(search) => updateApiOptions({ search })}
        />
        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{ float: 'right' }}
          onClick={addNewCampaign}
          disabled={!userHasEntitlement('app.campaign.create')}
          className="intercom-addCampaign"
        >
          Add
        </Button>
      </Responsive>
      <LegacyCampaignTable
        liveOnly={status === 'live'}
        apiOptions={apiOptions}
        onApiOptionsChange={updateApiOptions}
      />
    </Page>
  );
}
