import React, { useEffect, useState } from 'react';

import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { createSelector } from '@reduxjs/toolkit';
import {
  Col,
  Image,
  Modal,
  Popover,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import MultipleProductGroupMode from 'src/images/marketing/multiGroupMode.png';
import SingleProductGroupMode from 'src/images/marketing/singleGroupMode.png';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { namiLightGray, namiMediumGray, namiPureWhite } from 'src/variables';
import styled, { css } from 'styled-components';

type ProductGroupCapabilitySettingsModalProps = {
  open: boolean;
  onClose: () => void;
};

const ModalSectionTitle = styled(Typography.Text)`
  font-weight: 500;
`;

const RadioCard = styled.div<{ checked: boolean; disabled: boolean }>`
  background-color: #f3f6f9;
  width: 100%;
  height: fit-content;
  border-radius: 10px;
  padding: 15px;

  ${({ checked, disabled }) => {
    if (checked) {
      return css`
        outline: 2px solid rgba(76, 84, 90, 0.3);
        box-shadow: 3px 3px 12px 0px rgba(19, 116, 222, 0.12);
      `;
    }
    if (disabled) {
      return css`
        cursor: not-allowed;
      `;
    }
    return css`
      &:hover {
        outline: 2px solid rgba(76, 84, 90, 0.2);
      }
    `;
  }};
`;

const RadioDot = styled.div<{ checked: boolean }>`
  border-radius: 50%;
  height: 15px;
  width: 15px;
  background-color: ${namiPureWhite};
  outline: 1px solid ${namiLightGray};

  ${({ checked }) => {
    if (checked) {
      return css`
        outline: 2px solid ${namiLightGray};
        border: 2px solid ${namiPureWhite};
        background-color: rgba(76, 84, 90, 0.5);
      `;
    }
    return css``;
  }};
`;

const selector = createSelector(
  [
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.displaySingleGroup'],
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.capabilities'],
    ({ paywallBuilder }: RootState) => paywallBuilder.formFactor,
  ],
  (displaySingleGroup, capabilities, formFactor) => ({
    displaySingleGroup,
    hasSelectedProduct: (capabilities || []).includes('selected_product'),
    formFactor,
  })
);

export default function ProductGroupCapabilitySettingsModal({
  open,
  onClose,
}: ProductGroupCapabilitySettingsModalProps) {
  const { displaySingleGroup, hasSelectedProduct, formFactor } =
    useAppSelector(selector);
  const actions = useActions(PaywallBuilderSlice.actions);

  const [mode, setMode] = useState<'toggleGroup' | 'allGroups'>('toggleGroup');
  const [newMode, setNewMode] = useState<'toggleGroup' | 'allGroups' | null>(
    null
  );
  const supportToggleGroupMode = formFactor !== 'television';

  useEffect(() => {
    if (displaySingleGroup) setMode('toggleGroup');
    else setMode('allGroups');
  }, [displaySingleGroup]);

  return (
    <Modal
      title="Product Group Settings"
      open={open}
      onCancel={onClose}
      centered
      onOk={() => {
        updateMode();
        onClose();
      }}
      zIndex={1003}
    >
      <Space direction="vertical">
        <ModalSectionTitle>
          Product Group Display Mode
          <IconActionButton
            type="text"
            size="small"
            icon={
              <InfoCircleOutlined
                style={{ fontSize: '13px', color: namiMediumGray }}
              />
            }
            href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/product-groups#modes"
            target="_blank"
          />
        </ModalSectionTitle>
        <Row gutter={16}>
          <Col span={12}>
            <Tooltip
              title={
                !supportToggleGroupMode
                  ? 'Toggling between groups not supported on television'
                  : undefined
              }
            >
              <RadioCard
                checked={
                  !newMode ? mode === 'toggleGroup' : newMode === 'toggleGroup'
                }
                onClick={() => {
                  if (supportToggleGroupMode) setNewMode('toggleGroup');
                }}
                disabled={!supportToggleGroupMode}
              >
                <Space direction="horizontal" size="small">
                  <RadioDot
                    checked={
                      !newMode
                        ? mode === 'toggleGroup'
                        : newMode === 'toggleGroup'
                    }
                  />
                  <Typography.Text>Toggle between Groups</Typography.Text>
                </Space>
                <Image
                  src={SingleProductGroupMode}
                  preview={false}
                  width={'100%'}
                  height={200}
                  style={{ objectFit: 'cover' }}
                  alt="Nami paywall with toggle groups mode"
                />
              </RadioCard>
            </Tooltip>
          </Col>
          <Col span={12}>
            <Popover
              content={
                hasSelectedProduct
                  ? 'This display mode is unavailable when Selected Styling is turned on. To display all Product Groups on the paywall without a toggle, remove the Selected Styling capability.'
                  : undefined
              }
              overlayStyle={{ width: 280 }}
            >
              <RadioCard
                checked={
                  !newMode ? mode === 'allGroups' : newMode === 'allGroups'
                }
                onClick={() => {
                  if (hasSelectedProduct) return;
                  setNewMode('allGroups');
                }}
                disabled={hasSelectedProduct}
              >
                <Space direction="horizontal" size="small">
                  <RadioDot
                    checked={
                      !newMode ? mode === 'allGroups' : newMode === 'allGroups'
                    }
                  />
                  <Typography.Text>Display all Groups</Typography.Text>
                </Space>
                <Image
                  src={MultipleProductGroupMode}
                  preview={false}
                  width={'100%'}
                  height={200}
                  style={{ objectFit: 'cover' }}
                  alt="Nami paywall with multiple product group mode"
                />
              </RadioCard>
            </Popover>
          </Col>
        </Row>
      </Space>
      <Space direction="vertical" style={{ marginTop: 28 }}>
        <ModalSectionTitle>Manage Groups</ModalSectionTitle>
        <IconActionButton
          type="text"
          size="small"
          icon={<EditOutlined style={{ fontSize: '13px' }} />}
          onClick={() => actions.setEditingProductGroups(true)}
        >
          Edit Product Groups
        </IconActionButton>
      </Space>
    </Modal>
  );

  function updateMode() {
    if (mode !== newMode) {
      if (newMode === 'allGroups') {
        actions.addMultipleProductGroups();
      } else {
        actions.removeMultipleProductGroups();
      }
    }
  }
}
