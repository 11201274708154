import React, { useEffect, useState } from 'react';

import { Alert } from 'antd';
import { connect } from 'react-redux';
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Input,
  Modal,
  Segment,
} from 'semantic-ui-react';

import ConfirmChangesModal from '../../../../components/ConfirmChangesModal/ConfirmChangesModal';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import DeleteSegment from '../../../../components/DeleteSegment/DeleteSegment';
import EmptyStateSegment from '../../../../components/EmptyStateSegment/EmptyStateSegment';
import Loading from '../../../../components/Loading/Loading';
import Responsive from '../../../../components/Responsive/Responsive';
import SectionTitle from '../../../../components/SectionTitle';
import { useAppContext } from '../../../../hooks';
import {
  addLocale,
  deleteLocale,
  getLocales,
  handleLocaleClearError,
  handleLocaleField,
  handleLocalesField,
  updateLocale,
} from '../../../../redux/actions/localeActions';
import { isSuccessStatus } from '../../../../services/helpers/';
import { extractFieldError } from '../../../../services/utilsService';
import styles from './legal.module.less';

const Languages = ({
  loading,
  changes,
  appId,
  defaultLanguage,
  languages,
  locale,
  localeError,
  localeNewError,
  locales,
  getLocales,
  addLocale,
  updateLocale,
  deleteLocale,
  handleLocaleField,
  handleLocalesField,
  handleLocaleClearError,
}) => {
  const context = useAppContext();
  const [language, setLanguage] = useState(defaultLanguage);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  useEffect(() => {
    getLocales(appId);
  }, [appId, getLocales]);

  const languagesOptions = languages.map((language) => {
    return {
      value: language.code,
      text:
        defaultLanguage === language.code
          ? `${language.language} - (${language.code.toUpperCase()}) - Default`
          : `${language.language} - (${language.code.toUpperCase()})`,
    };
  });

  const localesOptions = locales.map((language) => {
    return {
      value: language.language,
      text:
        defaultLanguage === language.language
          ? `${
              language.language_humanized
            } - (${language.language.toUpperCase()}) - Default`
          : `${
              language.language_humanized
            } - (${language.language.toUpperCase()})`,
    };
  });

  const renderConfirmDelete = (locale) => {
    return (
      <ConfirmModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        action={() => {
          deleteLocale(locale.id).then(() => {
            getLocales(appId);
            setDeleteModal(false);
          });
          setLanguage(locales[0].language);
        }}
        loading={loading}
        title="Delete Locale Config Language"
        body="Are you sure you want to delete the Locale Config Language:"
        name={`${
          locale.language_humanized
        } - (${locale.language.toUpperCase()})`}
        buttonText="Yes. Delete This Language."
      />
    );
  };

  const renderConfirmChangesModal = (locale) => {
    return (
      <ConfirmChangesModal
        when={changes}
        confirm={() => updateLocale(locale.id, locale)}
        loading={loading}
        title="You have unsaved changes"
        body="Are you sure you want to leave without saving your changes?"
      />
    );
  };

  const renderAddLocaleModal = () => {
    return (
      <Modal
        dimmer="blurring"
        closeOnDimmerClick={true}
        onClose={() => {
          handleLocaleClearError();
          setAddModal(false);
        }}
        size="tiny"
        open={addModal}
      >
        <Modal.Header>Add Language</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Field>
                <label>Language</label>
                <Dropdown
                  placeholder="Select Language"
                  fluid
                  search
                  selection
                  style={{ border: '1px solid black !important' }}
                  value={locale.language}
                  onChange={(e, data) =>
                    handleLocaleField('language', data.value)
                  }
                  options={languagesOptions}
                  error={!!localeNewError?.language}
                />
                {localeNewError?.language?.length && (
                  <small className="ui pointing above prompt label">
                    {localeNewError.language[0]}
                  </small>
                )}
              </Form.Field>
              <Form.Input
                fluid
                label="Close Button Text"
                placeholder="Close Button Text"
                name="close_button_text"
                disabled={!context.userHasEntitlement('app.update')}
                onChange={(e) =>
                  handleLocaleField(e.target.name, e.target.value)
                }
                value={locale.close_button_text}
                error={extractFieldError(localeNewError?.close_button_text)}
              />
              <Form.Input
                fluid
                label="Sign In Button Text"
                placeholder="Sign In Button Text"
                name="sign_in_button_text"
                disabled={!context.userHasEntitlement('app.update')}
                onChange={(e) =>
                  handleLocaleField(e.target.name, e.target.value)
                }
                value={locale.sign_in_button_text}
                error={extractFieldError(localeNewError?.sign_in_button_text)}
              />
              <Form.Input
                fluid
                label="Restore Purchases Button Text"
                placeholder="Restore Purchases Button Text"
                name="restore_purchase_button_text"
                disabled={!context.userHasEntitlement('app.update')}
                onChange={(e) =>
                  handleLocaleField(e.target.name, e.target.value)
                }
                value={locale.restore_purchase_button_text}
                error={extractFieldError(
                  localeNewError?.restore_purchase_button_text
                )}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button.Group widths="2">
            <Button
              onClick={() => {
                handleLocaleClearError();
                setAddModal(false);
              }}
              fluid
            >
              Cancel
            </Button>
            <Button.Or />
            <Button
              className="nami-primary-button"
              disabled={!locale.language}
              onClick={() => {
                addLocale({ app: appId, ...locale }).then((action) => {
                  if (isSuccessStatus(action.payload?.status)) {
                    setAddModal(false);
                  }
                });
              }}
              fluid
            >
              Add
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  };

  if (loading) {
    return <Loading style={{ minHeight: 600 }} />;
  } else if (!loading && !locales.length) {
    return (
      <EmptyStateSegment
        title="Create a translation for your app's locale config information."
        subtitle="Learn more about locale configs for your app."
        outBoundLink="https://learn.namiml.com/public-docs/no-code-paywalls/nami-paywalls-in-different-languages"
        border={false}
        icon="languages"
      >
        <Button
          disabled={!context.userHasEntitlement('app.update')}
          className="nami-primary-button"
          onClick={() => setAddModal(true)}
        >
          <Icon name="plus" /> Add Locale Config
        </Button>
        {renderAddLocaleModal()}
      </EmptyStateSegment>
    );
  } else {
    return (
      <Segment basic>
        <Alert
          type="info"
          message="Paywall Button and Accessibility Translations are only used on Legacy Paywalls.
Unsure if you are using Legacy Paywalls? Ask Nami Support."
          showIcon
        />
        <Segment clearing basic>
          <Responsive size="mdUp">
            <Button
              floated="right"
              className="nami-primary-button"
              disabled={!context.userHasEntitlement('app.update')}
              onClick={() => setAddModal(true)}
            >
              <Icon name="plus" /> Add Language
            </Button>
          </Responsive>
          <Responsive size="mdDown">
            <Button
              floated="right"
              className="nami-primary-button"
              disabled={!context.userHasEntitlement('app.update')}
              onClick={() => setAddModal(true)}
            >
              <Icon name="plus" /> Add
            </Button>
          </Responsive>
        </Segment>
        <Form>
          <Form.Field>
            <Dropdown
              className={styles.dropdown}
              placeholder="Select Language"
              fluid
              search
              selection
              disabled={!context.userHasEntitlement('app.update')}
              defaultValue={language || defaultLanguage}
              onChange={(e, data) => {
                setLanguage(data.value);
                handleLocaleClearError();
              }}
              options={localesOptions}
            />
          </Form.Field>
          {locales &&
            !!locales.length &&
            locales
              .filter((locale) => locale.language === language)
              .map((locale, index) => (
                <div key={index}>
                  <SectionTitle>Paywall Buttons</SectionTitle>
                  <Form.Input
                    fluid
                    label="Close Button Text"
                    placeholder="Close Button Text"
                    name="close_button_text"
                    disabled={!context.userHasEntitlement('app.update')}
                    onChange={(e) =>
                      handleLocalesField(
                        locale.id,
                        e.target.name,
                        e.target.value
                      )
                    }
                    value={locale.close_button_text}
                    error={extractFieldError(localeError?.close_button_text)}
                  />
                  <Form.Input
                    fluid
                    label="Sign In Button Text"
                    placeholder="Sign In Button Text"
                    name="sign_in_button_text"
                    disabled={!context.userHasEntitlement('app.update')}
                    onChange={(e) =>
                      handleLocalesField(
                        locale.id,
                        e.target.name,
                        e.target.value
                      )
                    }
                    value={locale.sign_in_button_text}
                    error={extractFieldError(localeError?.sign_in_button_text)}
                  />
                  <Form.Input
                    fluid
                    label="Restore Purchases Button Text"
                    placeholder="Restore Purchases Button Text"
                    name="restore_purchase_button_text"
                    disabled={!context.userHasEntitlement('app.update')}
                    onChange={(e) =>
                      handleLocalesField(
                        locale.id,
                        e.target.name,
                        e.target.value
                      )
                    }
                    value={locale.restore_purchase_button_text}
                    error={extractFieldError(
                      localeError?.restore_purchase_button_text
                    )}
                  />
                  <SectionTitle>Accessibility - Text to Speech</SectionTitle>
                  <Form.Field>
                    <label>Purchase Terms Hint</label>
                    <Input
                      fluid
                      placeholder="Purchase Terms Hint"
                      name="purchase_terms_prefix_hint_text_to_speech"
                      disabled={!context.userHasEntitlement('app.update')}
                      onChange={(e) =>
                        handleLocalesField(
                          locale.id,
                          e.target.name,
                          e.target.value
                        )
                      }
                      value={
                        locale.purchase_terms_prefix_hint_text_to_speech || ''
                      }
                    />
                    <small>
                      This text is spoken before the Purchase Terms section on
                      your paywalls. Recommendation: "purchase terms".
                    </small>
                  </Form.Field>
                  <Form.Field>
                    <label>Purchase Button Hint</label>
                    <Input
                      fluid
                      placeholder="Purchase Button Hint"
                      name="purchase_button_hint_text_to_speech"
                      disabled={!context.userHasEntitlement('app.update')}
                      onChange={(e) =>
                        handleLocalesField(
                          locale.id,
                          e.target.name,
                          e.target.value
                        )
                      }
                      value={locale.purchase_button_hint_text_to_speech || ''}
                    />
                    <small>
                      This text is spoken after describing the purchase button
                      on your paywalls. Recommendation: "activate to start
                      purchase".
                    </small>
                  </Form.Field>
                  <Form.Field>
                    <Button
                      className="nami-primary-button"
                      disabled={!context.userHasEntitlement('app.update')}
                      onClick={() => updateLocale(locale.id, locale)}
                    >
                      Update Language
                    </Button>
                  </Form.Field>
                  {language !== defaultLanguage &&
                    context.userHasEntitlement('app.update') && (
                      <Form.Field>
                        <DeleteSegment
                          title="Delete Locale Config Language"
                          topWarning="Deleting this language will remove it from your app and all paywalls."
                          bottomWarning={`Delete Locale Config Language: ${
                            locale.language_humanized
                          } - (${locale.language.toUpperCase()}).`}
                          action={() => setDeleteModal(true)}
                          actionText="Delete Language"
                        />
                      </Form.Field>
                    )}
                  {renderConfirmChangesModal(locale)}
                  {renderConfirmDelete(locale)}
                </div>
              ))}
          {renderAddLocaleModal()}
        </Form>
      </Segment>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLocales: (appId) => dispatch(getLocales(appId)),
    deleteLocale: (localeId) => dispatch(deleteLocale(localeId)),
    addLocale: (data) => dispatch(addLocale(data)),
    updateLocale: (localeId, data) => dispatch(updateLocale(localeId, data)),
    handleLocaleField: (key, value) => dispatch(handleLocaleField(key, value)),
    handleLocalesField: (localeId, key, value) =>
      dispatch(handleLocalesField(localeId, key, value)),
    handleLocaleClearError: () => dispatch(handleLocaleClearError()),
  };
};

const mapStateToProps = ({ locale, root }) => {
  return {
    loading: locale.loading,
    changes: locale.changes,
    languages: root.languages,
    locales: locale.locales,
    locale: locale.locale,
    localeError: locale.localeError,
    localeNewError: locale.localeNewError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Languages);
