import React from 'react';

import { PlanMeta, TDisplayProduct } from 'src/api/types/main.types';

import UpgradeWebPaywall, { WebPaywallProps } from './UpgradeWebPaywall';

const IconPickerWebPaywall = ({ onCancel, visible }: WebPaywallProps) => {
  const paywallsOnlyProducts: TDisplayProduct[] = [
    {
      product_value_props: ['Icon Picker', 'Dark Mode', 'Google Fonts'],
      ...PlanMeta.paywalls_only_starter_v1,
    },
    {
      product_value_props: [
        'Carousel Paywalls',
        'Promo Paywalls',
        'A/B Testing',
      ],
      ...PlanMeta.paywalls_only_team_v1,
    },
    {
      product_value_props: [
        'Custom Brand Fonts',
        'Video Paywalls',
        'Multi-variate Testing',
      ],
      ...PlanMeta.paywalls_only_business_v1,
    },
  ];

  const paywallsAndSubsProducts: TDisplayProduct[] = [
    {
      product_value_props: ['Icon Picker', 'Dark Mode', 'Google Fonts'],
      ...PlanMeta.paywalls_and_subs_starter_v1,
    },
    {
      product_value_props: [
        'Carousel Paywalls',
        'Promo Paywalls',
        'A/B Testing',
      ],
      ...PlanMeta.paywalls_and_subs_team_v1,
    },
    {
      product_value_props: [
        'Custom Brand Fonts',
        'Video Paywalls',
        'Multi-variate Testing',
      ],
      ...PlanMeta.paywalls_and_subs_business_v1,
    },
  ];

  return (
    <UpgradeWebPaywall
      modalTitle="Unlock Icon Options"
      productsPaywallsOnly={paywallsOnlyProducts}
      productsPaywallsAndSubs={paywallsAndSubsProducts}
      highlightedProductPaywallsOnly="paywalls_only_starter_v1"
      highlightedProductPaywallsAndSubs="paywalls_and_subs_starter_v1"
      entitlement="app.paywall.capabilities.icon_picker"
      onCancel={onCancel}
      visible={visible}
    />
  );
};

export default IconPickerWebPaywall;
