import React from 'react';

import { ReadOutlined } from '@ant-design/icons';
import { Form, Select, Tooltip } from 'antd';
import styled from 'styled-components';

import { FieldObject } from '../../../utils/formFieldBuilding';
import { UpgradeButton } from './FontSelect';
import InputLabel from './InputLabel';

type SafeAreaDisplayProps = Omit<FieldObject, 'variable'> & {
  defaultValue?: string | number;
  onChange: (value: any) => void;
  label: string;
  controlled?: boolean;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
};

const StyledSelect = styled(Select)`
  .ant-select-selection-item {
    font-size: 13px;
    font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas',
      'Liberation Mono', Menlo, Courier, monospace;
  }
`;

export default function SafeAreaDisplay({
  controlled = false,
  ...field
}: SafeAreaDisplayProps) {
  const itemProps = {
    name: controlled ? undefined : field.variable,
    label: <InputLabel {...field} />,
  };

  const title: React.ReactNode = (
    <>
      <span>
        Safe area ensures that the paywall doesn't get cutoff at the top or run
        into the status bar. The size of the safe area is different for each
        device.
      </span>
      <UpgradeButton
        size="small"
        type="link"
        href="https://learn.namiml.com/public-docs/v/template-builder/design/paywall-layout/fullscreen-mode"
        target="_blank"
        icon={<ReadOutlined />}
      >
        Learn More.
      </UpgradeButton>
    </>
  );

  return (
    <Form.Item {...itemProps}>
      <Tooltip title={title}>
        <StyledSelect mode="multiple" value={['Safe Area']} disabled />
      </Tooltip>
    </Form.Item>
  );
}
