import React from 'react';

import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';

import { useAppContext } from '../../../../hooks';
import { handlePaywallField } from '../../../../redux/actions/paywallActions';
import ImageUploader from '../../../ImageUploader/ImageUploader';

const Images = ({ paywall, handlePaywallField }) => {
  const context = useAppContext();

  const onUploadFinish = (type, image) => {
    let key;
    if (type === 'phone') {
      key = 'background_image_url_phone';
    } else {
      key = 'background_image_url_tablet';
    }
    handlePaywallField({
      ...paywall,
      [key]: image,
    });
  };

  const removeImage = (type) => {
    let key;
    if (type === 'phone') {
      key = 'background_image_url_phone';
    } else {
      key = 'background_image_url_tablet';
    }
    handlePaywallField({ ...paywall, [key]: null });
  };

  return (
    <Grid>
      <Grid.Column mobile={16} tablet={8} computer={8}>
        <ImageUploader
          id={paywall.id}
          type="paywall"
          label="Phone"
          imageSrc={paywall.background_image_url_phone}
          title="Click or Drop an Image Here"
          description="Upload background artwork sized for phones. A compressed 2,000 x 1,000 JPEG is recommended."
          aspect={9 / 19.5}
          maxWidth={300}
          height={300}
          removeImage={() => removeImage('phone')}
          onFinish={(image) => onUploadFinish('phone', image)}
          disabled={context.userHasEntitlement('app.paywall.update')}
        />
      </Grid.Column>
      <Grid.Column mobile={16} tablet={8} computer={8}>
        <ImageUploader
          id={paywall.id}
          type="paywall"
          label="Tablet"
          imageSrc={paywall.background_image_url_tablet}
          title="Click or Drop an Image Here"
          description="Upload background artwork sized for tablets. A compressed 2,000 x 2,000 JPEG is recommended."
          aspect={1 / 1.33}
          maxWidth={300}
          height={300}
          removeImage={() => removeImage('tablet')}
          onFinish={(image) => onUploadFinish('tablet', image)}
          disabled={context.userHasEntitlement('app.paywall.update')}
        />
      </Grid.Column>
    </Grid>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlePaywallField: (newData) => dispatch(handlePaywallField(newData)),
  };
};

const mapStateToProps = ({ paywall }) => {
  return {
    paywall: paywall.paywall,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Images);
