import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import {
  Button,
  Dropdown,
  Form,
  Icon,
  Input,
  Modal,
  Segment,
} from 'semantic-ui-react';

import ConfirmChangesModal from '../../../../components/ConfirmChangesModal/ConfirmChangesModal';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import DeleteSegment from '../../../../components/DeleteSegment/DeleteSegment';
import EmptyStateSegment from '../../../../components/EmptyStateSegment/EmptyStateSegment';
import Loading from '../../../../components/Loading/Loading';
import Responsive from '../../../../components/Responsive/Responsive';
import { useAppContext } from '../../../../hooks';
import {
  addCitation,
  deleteCitation,
  getCitations,
  handleCitationField,
  handleCitationsField,
  updateCitation,
} from '../../../../redux/actions/citationActions';
import { isSuccessStatus } from '../../../../services/helpers/';
import { extractFieldError } from '../../../../services/utilsService';
import styles from './legal.module.less';

const Legal = ({
  loading,
  changes,
  appId,
  defaultLanguage,
  languages,
  citation,
  citationError,
  citations,
  getCitations,
  addCitation,
  updateCitation,
  deleteCitation,
  handleCitationField,
  handleCitationsField,
}) => {
  const context = useAppContext();
  const [language, setLanguage] = useState(defaultLanguage);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  useEffect(() => {
    getCitations(appId);
  }, [appId, getCitations]);

  const languagesOptions = languages.map((language) => {
    return {
      value: language.code,
      text:
        defaultLanguage === language.code
          ? `${language.language} - (${language.code.toUpperCase()}) - Default`
          : `${language.language} - (${language.code.toUpperCase()})`,
    };
  });

  const citationsOptions = citations.map((language) => {
    return {
      value: language.language,
      text:
        defaultLanguage === language.language
          ? `${
              language.language_humanized
            } - (${language.language.toUpperCase()}) - Default`
          : `${
              language.language_humanized
            } - (${language.language.toUpperCase()})`,
    };
  });

  const renderConfirmDelete = (citation) => {
    return (
      <ConfirmModal
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
        action={() => {
          deleteCitation(citation.id).then(() => {
            getCitations(appId);
            setDeleteModal(false);
          });
          setLanguage(defaultLanguage);
        }}
        loading={loading}
        title="Delete App Legal Language"
        body="Are you sure you want to delete the App Legal Language:"
        name={`${
          citation.language_humanized
        } - (${citation.language.toUpperCase()})`}
        buttonText="Yes. Delete This Language."
      />
    );
  };

  const renderConfirmChangesModal = (citation) => {
    return (
      <ConfirmChangesModal
        when={changes}
        confirm={() => updateCitation(citation.id, citation)}
        loading={loading}
        title="You have unsaved changes"
        body="Are you sure you want to leave without saving your changes?"
      />
    );
  };

  const renderAddCitationModal = () => {
    return (
      <Modal
        dimmer="blurring"
        closeOnDimmerClick={true}
        onClose={() => setAddModal(false)}
        size="tiny"
        open={addModal}
      >
        <Modal.Header>Add Language</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form>
              <Form.Field>
                <label>Language</label>
                <Dropdown
                  placeholder="Select Language"
                  fluid
                  search
                  selection
                  style={{ border: '1px solid black !important' }}
                  value={citation.language}
                  onChange={(e, data) =>
                    handleCitationField('language', data.value)
                  }
                  options={languagesOptions}
                  error={!!citationError?.language}
                />
                {citationError?.language?.length && (
                  <small className="ui pointing above prompt label">
                    {citationError.language[0]}
                  </small>
                )}
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button.Group widths="2">
            <Button onClick={() => setAddModal(false)} fluid>
              Cancel
            </Button>
            <Button.Or />
            <Button
              className="nami-primary-button"
              disabled={!citation.language}
              onClick={() => {
                addCitation({ app: appId, ...citation }).then((action) => {
                  if (isSuccessStatus(action.payload?.status)) {
                    setAddModal(false);
                  }
                });
              }}
              fluid
            >
              Add
            </Button>
          </Button.Group>
        </Modal.Actions>
      </Modal>
    );
  };

  if (loading) {
    return <Loading style={{ minHeight: 600 }} />;
  } else if (!loading && !citations.length) {
    return (
      <EmptyStateSegment
        title="Create a translation for your app's legal information."
        subtitle="Learn more about legal clickwrap text for your app."
        outBoundLink="https://learn.namiml.com"
        border={false}
        icon="languages"
      >
        <Button
          disabled={!context.userHasEntitlement('app.update')}
          className="add-app nami-primary-button"
          onClick={() => setAddModal(true)}
        >
          <Icon name="plus" /> Add App Legal
        </Button>
        {renderAddCitationModal()}
      </EmptyStateSegment>
    );
  } else {
    return (
      <Segment basic>
        <Segment clearing basic>
          {context.userHasEntitlement('app.locale_config.update') && (
            <>
              <Responsive size="mdUp">
                <Button
                  floated="right"
                  className="nami-primary-button"
                  disabled={!context.userHasEntitlement('app.update')}
                  onClick={() => setAddModal(true)}
                >
                  <Icon name="plus" /> Add Language
                </Button>
              </Responsive>
              <Responsive size="mdDown">
                <Button
                  floated="right"
                  className="nami-primary-button"
                  disabled={!context.userHasEntitlement('app.update')}
                  onClick={() => setAddModal(true)}
                >
                  <Icon name="plus" /> Add
                </Button>
              </Responsive>
            </>
          )}
        </Segment>
        <Form>
          {context.userHasEntitlement('app.locale_config.update') && (
            <Form.Field>
              <Dropdown
                className={styles.dropdown}
                placeholder="Select Language"
                fluid
                search
                selection
                disabled={!context.userHasEntitlement('app.update')}
                defaultValue={language || defaultLanguage}
                onChange={(e, data) => setLanguage(data.value)}
                options={citationsOptions}
              />
            </Form.Field>
          )}
          {citations &&
            !!citations.length &&
            citations
              .filter((citation) => citation.language === language)
              .map((citation, index) => (
                <div key={index}>
                  <Form.Field>
                    <label>Clickwrap Text</label>
                    <Input
                      fluid
                      placeholder="Clickwrap Text"
                      name="clickwrap_text"
                      disabled={!context.userHasEntitlement('app.update')}
                      onChange={(e) =>
                        handleCitationsField(
                          citation.id,
                          e.target.name,
                          e.target.value
                        )
                      }
                      value={citation.clickwrap_text}
                      maxLength={512}
                    />
                    <small>
                      {
                        // eslint-disable-next-line no-template-curly-in-string
                        "Use ${legal.terms_link} and ${legal.privacy_link} to insert the display text as a hyperlink on your paywalls. Example: 'By purchasing you agree to our ${legal.terms_link} and ${legal.privacy_link}.'"
                      }
                    </small>
                  </Form.Field>
                  <Form.Input
                    fluid
                    label="Terms of Use Display Text"
                    placeholder="Terms of Use Display Text"
                    name="tos_text"
                    disabled={!context.userHasEntitlement('app.update')}
                    onChange={(e) =>
                      handleCitationsField(
                        citation.id,
                        e.target.name,
                        e.target.value
                      )
                    }
                    value={citation.tos_text}
                    error={extractFieldError(citationError?.tos_text)}
                    maxLength={256}
                  />
                  <Form.Input
                    fluid
                    label="Terms of Use Link"
                    placeholder="Terms of Use Link"
                    name="tos_url"
                    disabled={!context.userHasEntitlement('app.update')}
                    onChange={(e) =>
                      handleCitationsField(
                        citation.id,
                        e.target.name,
                        e.target.value
                      )
                    }
                    value={citation.tos_url || ''}
                    error={extractFieldError(citationError?.tos_url)}
                    maxLength={256}
                  />
                  <Form.Input
                    fluid
                    label="Privacy Policy Display Text"
                    placeholder="Privacy Policy Display Text"
                    name="privacy_text"
                    disabled={!context.userHasEntitlement('app.update')}
                    onChange={(e) =>
                      handleCitationsField(
                        citation.id,
                        e.target.name,
                        e.target.value
                      )
                    }
                    value={citation.privacy_text}
                    error={extractFieldError(citationError?.privacy_text)}
                    maxLength={256}
                  />
                  <Form.Input
                    fluid
                    label="Privacy Policy Link"
                    placeholder="Privacy Policy Link"
                    name="privacy_url"
                    disabled={!context.userHasEntitlement('app.update')}
                    onChange={(e) =>
                      handleCitationsField(
                        citation.id,
                        e.target.name,
                        e.target.value
                      )
                    }
                    value={citation.privacy_url || ''}
                    error={extractFieldError(citationError?.privacy_url)}
                    maxLength={256}
                  />
                  <Form.Field>
                    <Button
                      className="nami-primary-button"
                      disabled={!context.userHasEntitlement('app.update')}
                      onClick={() => updateCitation(citation.id, citation)}
                    >
                      Update Language
                    </Button>
                  </Form.Field>
                  {language !== defaultLanguage &&
                    context.userHasEntitlement('app.update') && (
                      <Form.Field>
                        <DeleteSegment
                          title="Delete App Legal Language"
                          topWarning="Deleting this language will remove it from your app and all paywalls."
                          bottomWarning={`Delete App Legal Language: ${
                            citation.language_humanized
                          } - (${citation.language.toUpperCase()}).`}
                          action={() => setDeleteModal(true)}
                          actionText="Delete Language"
                        />
                      </Form.Field>
                    )}
                  {renderConfirmChangesModal(citation)}
                  {renderConfirmDelete(citation)}
                </div>
              ))}
          {renderAddCitationModal()}
        </Form>
      </Segment>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCitations: (appId) => dispatch(getCitations(appId)),
    deleteCitation: (citationId) => dispatch(deleteCitation(citationId)),
    addCitation: (data) => dispatch(addCitation(data)),
    updateCitation: (citationId, data) =>
      dispatch(updateCitation(citationId, data)),
    handleCitationField: (key, value) =>
      dispatch(handleCitationField(key, value)),
    handleCitationsField: (citationId, key, value) =>
      dispatch(handleCitationsField(citationId, key, value)),
  };
};

const mapStateToProps = ({ citation, root }) => {
  return {
    loading: citation.loading,
    changes: citation.changes,
    languages: root.languages,
    citations: citation.citations,
    citation: citation.citation,
    citationError: citation.citationError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Legal);
