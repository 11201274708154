import { useContext } from 'react';

import { AppContext } from '../AppContextProvider';
import { PageContext } from '../components/Page/Page';

export function useAppContext() {
  return useContext(AppContext);
}

export function usePageContext() {
  return useContext(PageContext);
}
