import React, { Fragment, useState, useCallback } from 'react';

import * as Sentry from '@sentry/browser';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import { Button, Icon, Segment, Image, Modal, Header } from 'semantic-ui-react';

import ConfirmModal from '../../components/ConfirmModal/ConfirmModal';
import { API_URL } from '../../config';
import { getHash } from '../../services/helpers';
import Token from '../../services/token';
import { cropImage } from '../../utils/image';
import styles from './ImageUploader.module.less';

const ImageUploader = ({
  id,
  type,
  label,
  title,
  description,
  imageSrc,
  maxWidth,
  height,
  aspect,
  width = '100%',
  size = 'small',
  onFinish,
  removeImage,
  disabled = true,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [image, setImage] = useState(imageSrc);
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  const onDrop = useCallback(
    (files) => {
      setLoading(true);
      const onFinishUpload = (image) => {
        onFinish(image);
      };
      if (files && files.length) {
        const reader = new FileReader();
        let URL = `${API_URL}/private/frontend/v1/upload/${type}/${id}/${getHash(
          9
        )}-${files[0].name}`;
        reader.readAsDataURL(files[0]);
        reader.addEventListener('load', () => {
          axios(URL, {
            method: 'PUT',
            headers: {
              Accept: 'application/json',
              'Content-Type': files[0].type,
              Authorization: `Bearer ${Token.getAccess()}`,
            },
            data: files[0],
          }).then((response) => {
            let url = response.data.url;
            onFinishUpload(url);
            setLoading(false);
          });
        });
      }
    },
    [id, type, onFinish]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  const removingImage = () => {
    removeImage();
    setCroppedImage(null);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    setLoading(true);
    const onFinishUpload = (image) => {
      onFinish(image);
    };
    try {
      const croppedImage = await cropImage(image, croppedAreaPixels, rotation);

      setCroppedImage(croppedImage.image);

      let reader = new FileReader();

      let URL = `${API_URL}/private/frontend/v1/upload-datauri/${type}/${id}/${getHash(
        9
      )}-preview-${getHash(9)}.jpg`;

      reader.readAsDataURL(croppedImage.file);
      reader.addEventListener('load', () => {
        axios(URL, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${Token.getAccess()}`,
          },
          data: reader.result,
        }).then((response) => {
          let url = response.data.url;
          onFinishUpload(url);
          setOpen(false);
          setConfirm(false);
          setLoading(false);
        });
      });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [croppedAreaPixels, rotation, image, type, id, onFinish]);

  const edit = (imageUrl) => {
    setImage(imageUrl);
    setOpen(true);
  };

  const reset = (notDisabled) => {
    if (notDisabled) {
      setZoom(1);
      setRotation(0);
    }
  };

  const cancel = () => {
    setOpen(false);
    setZoom(1);
    setRotation(0);
  };

  const renderEditor = () => {
    return (
      <Modal className={styles.modal} open={open} onClose={cancel}>
        <Modal.Header className={styles.modalHeader}>
          <Icon name="image" /> Image Editor
        </Modal.Header>
        <Modal.Content>
          {image !== null && (
            <Segment basic className={styles.editorContainer}>
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={aspect}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
              />
            </Segment>
          )}

          <Button.Group icon size="large" style={{ width: '100%' }}>
            <Button>
              <div style={{ marginBottom: 10 }}>
                <label>Zoom Slider</label>
              </div>
              <input
                type="range"
                min={1.0}
                step="0.1"
                max={20}
                value={zoom}
                onChange={(e) => setZoom(Number(e.target.value))}
              />
            </Button>
            <Button>
              <div style={{ marginBottom: 10 }}>
                <label>Rotation Slider</label>
              </div>
              <input
                type="range"
                min={-360}
                step="1"
                max={360}
                value={rotation}
                onChange={(e) => setRotation(Number(e.target.value))}
              />
            </Button>
          </Button.Group>

          <Header
            textAlign="center"
            color={rotation || zoom !== 1 ? 'red' : 'grey'}
            as="div"
            style={{
              width: '100%',
              cursor: rotation || zoom !== 1 ? 'pointer' : 'not-allowed',
            }}
            onClick={() => reset(rotation || zoom !== 1)}
          >
            Reset
          </Header>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={cancel}>Cancel</Button>
          <Button
            className="nami-primary-button"
            onClick={() => setConfirm(true)}
          >
            <Icon name="check" /> Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  const renderConfirmModal = () => {
    return (
      <ConfirmModal
        open={confirm}
        onClose={() => setConfirm(false)}
        action={showCroppedImage}
        loading={loading}
        title="Confirm Image Change"
        body="Are you sure you want to save your changes? This will be permanent."
        buttonText="Save"
        buttonClass="nami-primary-button"
      />
    );
  };

  return (
    <Segment basic loading={loading} style={{ width, maxWidth, padding: 0 }}>
      {label && (
        <div className={styles.label}>
          <label>{label}</label>
        </div>
      )}
      <Fragment>
        {croppedImage || !!imageSrc ? (
          <Fragment>
            <Image
              className={styles.image}
              src={croppedImage || imageSrc}
              size={size}
            />
            {disabled && (
              <Button.Group icon basic>
                <Button onClick={() => edit(imageSrc)}>
                  <Icon name="pencil" /> Edit Image
                </Button>
                <Button onClick={removingImage}>
                  <Icon name="trash" />
                </Button>
              </Button.Group>
            )}
            {renderEditor()}
            {renderConfirmModal()}
          </Fragment>
        ) : (
          <div
            style={{ width, maxWidth, height }}
            className={styles.dragAndDropContainer}
            {...getRootProps()}
          >
            {disabled ? (
              <div className={styles.dragAndDrop}>
                <input {...getInputProps()} />
                {title && <h4 className={styles.title}>{title}</h4>}
                {description && (
                  <p className={styles.description}>{description}</p>
                )}
              </div>
            ) : (
              <div className={styles.dragAndDrop}>
                <h4 className={styles.title}>
                  You do not have permission to upload an image.
                </h4>
              </div>
            )}
          </div>
        )}
      </Fragment>
    </Segment>
  );
};

ImageUploader.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageSrc: PropTypes.string,
  maxWidth: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  aspect: PropTypes.number.isRequired,
  width: PropTypes.string,
  size: PropTypes.string,
  onFinish: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
};

export default ImageUploader;
