import React from 'react';

import { Col, Row } from 'antd';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import Page from 'src/components/Page/Page';

import CampaignMenu from './CampaignMenu';
import PlacementsWrapper from './PlacementsWrapper';

export default function PlacementsPage() {
  return (
    <Page title="Placements">
      <Row justify="start" gutter={[0, 36]}>
        <Col xs={24} style={{ paddingBottom: 16 }}>
          <Breadcrumb items={[{ name: 'Placements' }]} />
        </Col>
      </Row>
      <CampaignMenu currentKey="placements" />
      <PlacementsWrapper />
    </Page>
  );
}
