import React, { useMemo } from 'react';

import { LockOutlined } from '@ant-design/icons';
import { uuid4 } from '@sentry/utils';
import { Button, Form, Select } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';
import GoogleFontWebPaywall from 'src/components/WebPaywalls/GoogleFontWebPaywall';
import { useAppContext, useBooleanState } from 'src/hooks';
import { namiBrightBlue } from 'src/variables';
import styled from 'styled-components';

import { TFont } from '../../../../../../api/types/main.types';
import {
  useFontsQuery,
  useOrgFontsQuery,
} from '../../../../../../hooks/queries/font.hooks';
import {
  buildFontFaceFamily,
  extractFontFaceData,
  findDefaultFont,
  sanitizeFontName,
} from '../../../../../../utils/fonts';
import FontFamilyText from '../../../../settings/brand/FontFamilyText';
import { FieldObject } from '../../../utils/formFieldBuilding';
import InputLabel from './InputLabel';

type FontSelectorsProps = Omit<FieldObject, 'variable' | 'value'> & {
  defaultValue?: string | number;
  onChange: (value: string | null) => void;
  label: string;
  controlled?: boolean;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
  value: string | null;
};

const EMPTY = uuid4();
export const UpgradeButton = styled(Button)`
  color: #ffffff;
  &:hover {
    color: ${namiBrightBlue};
  }
`;

export default function FontSelect({
  defaultValue,
  onChange,
  label: _,
  name,
  ...field
}: FontSelectorsProps) {
  const fontsQuery = useFontsQuery();
  const orgFontsQuery = useOrgFontsQuery();
  const { userHasEntitlement } = useAppContext();
  const canEdit = userHasEntitlement('app.paywall.capabilities.custom_font');
  const [isUpgradeOpen, openUpgrade, closeUpgrade] = useBooleanState(false);

  const { familyFonts, styleFonts, familyOptions, styleOptions } =
    useMemo(() => {
      const orgFonts = orgFontsQuery.data?.results || [];
      const fonts = orgFonts.concat(fontsQuery.data || []);

      const familyStyles: { [family: string]: TFont[] } = {};

      const styleFonts: { [fontFace: string]: TFont } = {};
      const styleOptions: { [key: string]: DefaultOptionType[] } = {};
      const familyFonts: { [fontFace: string]: TFont } = {};
      const familyOptions: DefaultOptionType[] = [
        {
          key: 'Default',
          label: <FontFamilyText font={null}>System Font</FontFamilyText>,
          value: EMPTY,
        },
      ];

      for (const font of fonts) {
        const { family } = font;
        familyStyles[family] = (familyStyles[family] || []).concat([font]);
        styleFonts[buildFontFaceFamily(font)] = font;

        const familyKey = sanitizeFontName(family);
        styleOptions[familyKey] = (styleOptions[familyKey] || []).concat({
          key: font.id,
          label: <FontFamilyText font={font}>{font.style}</FontFamilyText>,
          value: buildFontFaceFamily(font),
        });
      }

      for (const [fontFamily, fonts] of Object.entries(familyStyles)) {
        const font = findDefaultFont(fonts);
        familyFonts[sanitizeFontName(fontFamily)] = font!;
        familyOptions.push({
          key: fontFamily,
          label: <FontFamilyText font={font}>{fontFamily}</FontFamilyText>,
          value: font && buildFontFaceFamily(font),
        });
      }

      return { familyFonts, styleFonts, familyOptions, styleOptions };
    }, [fontsQuery.data, orgFontsQuery.data?.results]);

  const value = useMemo(() => {
    const isValid =
      !!field.value &&
      field.value.toLowerCase() !== 'helvetica' &&
      // @ts-ignore
      !!Array.from(document.fonts.values()).find(
        (fontFace) => (fontFace as FontFace).family === field.value
      );
    return isValid ? field.value : null;
  }, [field.value]);
  const { family = null } = (value && extractFontFaceData(value)) || {};

  return (
    <>
      <Form.Item
        label={<InputLabel name={name.replace('Name', 'Family')} {...field} />}
        tooltip={
          canEdit ? null : (
            <UpgradeButton
              size="small"
              type="link"
              onClick={openUpgrade}
              icon={<LockOutlined />}
            >
              Upgrade for access
            </UpgradeButton>
          )
        }
      >
        <Select
          value={family ? buildFontFaceFamily(familyFonts[family]) : EMPTY}
          disabled={!field.editable || !canEdit}
          placeholder="Select a font family"
          onChange={handleChange}
          options={familyOptions}
        />
      </Form.Item>
      {value && ((family && styleOptions[family]) || []).length > 1 && (
        <Form.Item
          label={<InputLabel name={name.replace('Name', 'Style')} {...field} />}
        >
          <Select
            value={buildFontFaceFamily(styleFonts[value])}
            disabled={!field.editable || !canEdit || !family}
            placeholder={!family ? 'Select a family first' : undefined}
            onChange={handleChange}
            options={(family && styleOptions[family]) || []}
          />
        </Form.Item>
      )}
      <GoogleFontWebPaywall visible={isUpgradeOpen} onCancel={closeUpgrade} />
    </>
  );

  function handleChange(value: string): void {
    if (!field.editable) return;
    onChange(value === EMPTY ? null : value);
  }
}
