import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';
import { TOrgSecretKindEnum } from 'src/api/types/main.types';
import { useStoreCredentialsForm } from 'src/hooks/ant-form.hooks';
import { isTruthy } from 'src/utils/array';

import CredentialsForm from './CredentialsForm';
import SecretFileUpload from './SecretFileUpload';
import { buildFormItemProps } from './utils';

export default function AppleCredentialsForm() {
  const form = useStoreCredentialsForm('apple');

  const hasAppStoreConnectApi = !!form.secrets.apple_app_store_connect_api;
  return (
    <CredentialsForm
      form={form}
      colon={false}
      layout="vertical"
      onFieldsChange={form.handleFieldsChange}
      className="intercom-applestorecredentials"
    >
      <Form.Item
        label="Issuer ID"
        tooltip={{
          title:
            'Issuer ID is found in App Store Connect > Users and Access > Integrations > App Store Connect API',
        }}
        className="intercom-appleissuerid"
        {...buildFormItemProps(
          ['apple_app_store_connect_api', 'public', 'issuer_id'],
          form.errors
        )}
      >
        <Input
          maxLength={36}
          disabled={hasAppStoreConnectApi}
          placeholder="App Store Connect Issuer ID"
        />
      </Form.Item>
      <Form.Item
        label="Key ID"
        tooltip={{
          title:
            'Key ID is found in App Store Connect > Users and Access > Integrations > App Store Connect API. Generate a Key with App Manager access to get this id and the Private Key file (below).',
        }}
        className="intercom-applekeyid"
        {...buildFormItemProps(
          ['apple_app_store_connect_api', 'public', 'key_id'],
          form.errors
        )}
      >
        <Input
          maxLength={10}
          disabled={hasAppStoreConnectApi}
          placeholder="App Store Connect Key ID"
        />
      </Form.Item>
      <SecretFileUpload
        label="Private Key"
        tooltipContent={
          <>
            Private Key is found in App Store Connect {'> '} Users and Access{' '}
            {'> '} Integrations {'> '} App Store Connect API. Generate a Key
            with App Manager Access to get this file and the Key ID (above).
          </>
        }
        {...buildFormItemProps(
          ['apple_app_store_connect_api', 'secret', 'private_key'],
          form.errors
        )}
        onChange={form.updateFieldValue}
        disabled={hasAppStoreConnectApi}
        fileType=".p8"
      />
      <Space style={{ marginTop: '2rem' }}>
        <Button
          type="primary"
          disabled={hasAppStoreConnectApi || !form.canCreate}
          loading={form.createMutation.isLoading}
          onClick={createAppleSecrets}
        >
          Save
        </Button>
        <Button
          danger
          type="link"
          loading={form.deleteMutation.isLoading}
          disabled={!hasAppStoreConnectApi || !form.canDelete}
          onClick={() => {
            const secretIds = [
              form.secrets.apple_app_store_connect_api?.id,
            ].filter(isTruthy);
            form.deleteMutation.mutate(secretIds);
          }}
        >
          <Space>
            <DeleteOutlined />
            Delete these credentials
          </Space>
        </Button>
      </Space>
    </CredentialsForm>
  );

  function createAppleSecrets() {
    const { apple_app_store_connect_api } = form.getFieldsValue();
    const payloads = [];
    if (!hasAppStoreConnectApi) {
      payloads.push({
        ...apple_app_store_connect_api,
        kind: TOrgSecretKindEnum.AppleAppStoreConnectApi,
      });
    }
    form.createMutation.mutate(payloads);
  }
}
