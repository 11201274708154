import React from 'react';

import { DataObjectOutlined } from '@mui/icons-material';
import { Button, Form, Modal, Space, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { createSelector } from 'reselect';
import FormItem from 'src/components/FormItem';
import { useBooleanState } from 'src/hooks';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

const selector = createSelector(
  [
    ({ paywallBuilder: { launch } }: RootState) => launch.customAttributes,
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.launchCustomAttributes'],
  ],
  (customAttributes) => ({
    customAttributes,
    customAttributesSet:
      customAttributes && Object.keys(customAttributes).length > 0,
  })
);

export default function PaywallCustomAttributeSetter() {
  const actions = useActions(PaywallBuilderSlice.actions);
  const [form] = Form.useForm<object>();

  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const { customAttributes, customAttributesSet } = useAppSelector(selector);

  return (
    <>
      <Tooltip
        title="Preview paywall with custom attributes"
        mouseEnterDelay={0.8}
      >
        <Button
          type={customAttributesSet ? 'primary' : 'ghost'}
          icon={
            <DataObjectOutlined style={{ transform: 'translateY(1.5px)' }} />
          }
          onClick={openModal}
          size="small"
        />
      </Tooltip>
      <Modal
        title="Preview Custom Attributes"
        open={isModalOpen}
        centered
        zIndex={1005}
        footer={null}
        onCancel={closeModal}
      >
        <Form
          form={form}
          initialValues={{
            'custom-json': JSON.stringify(customAttributes),
          }}
          onFinish={updateLaunchCustomAttributes}
        >
          <FormItem
            rules={[
              {
                required: true,
                message: 'Provide a JSON string',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  try {
                    JSON.parse(value);
                    return Promise.resolve();
                  } catch (e) {
                    return Promise.reject(new Error('Invalid JSON'));
                  }
                },
              }),
            ]}
            name="custom-json"
          >
            <TextArea
              rows={8}
              placeholder='{"image": "https://mysite.com/marketing_image.jpg"}'
              style={{ fontFamily: 'monospace' }}
            />
          </FormItem>
          <Space direction="horizontal">
            <Button
              type="primary"
              htmlType="submit"
              disabled={form
                .getFieldsError()
                .some(({ errors }) => errors.length)}
            >
              Apply
            </Button>
            <Button onClick={resetLaunchCustomAttributes}>Clear</Button>
          </Space>
        </Form>
      </Modal>
    </>
  );

  function updateLaunchCustomAttributes() {
    actions.setLaunchCustomContext(
      JSON.parse(form.getFieldValue('custom-json'))
    );
    closeModal();
  }

  function resetLaunchCustomAttributes() {
    form.setFieldValue('custom-json', '{}');
    actions.resetLaunchCustomContext();
    closeModal();
  }
}
