import React from 'react';

import NavigationPrompt from 'react-router-navigation-prompt';
import { Button, Modal } from 'semantic-ui-react';

type ConfirmChangesModalProps = {
  className?: string;
  title?: string;
  body?: string;
  when?: boolean;
  confirm: () => void;
  loading?: boolean; // TODO: drop
};

export default function ConfirmChangesModal({
  title = '',
  body = '',
  className = '',
  when = false,
  confirm,
}: ConfirmChangesModalProps) {
  return (
    <NavigationPrompt when={when}>
      {({ onConfirm, onCancel }) => (
        <Modal
          className={className}
          dimmer="blurring"
          open={when}
          size="tiny"
          closeOnDimmerClick={true}
          onClose={onCancel}
        >
          <Modal.Header>
            <h3>{title}</h3>
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>{body}</Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button data-test="close" onClick={onConfirm}>
              Leave Anyway
            </Button>
            <Button
              data-test="submit"
              className="nami-primary-button"
              onClick={() => {
                onCancel();
                confirm();
              }}
            >
              Save Changes
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </NavigationPrompt>
  );
}
