import React from 'react';

import Icon from '@ant-design/icons';
import { Button, Form, Modal, Space, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { createSelector } from 'reselect';
import FormItem from 'src/components/FormItem';
import { useBooleanState } from 'src/hooks';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { ReactComponent as DatabaseIcon } from 'src/images/icons/database.svg';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

const selector = createSelector(
  [({ paywallBuilder: { launch } }: RootState) => launch.customObject],
  (customObject) => ({
    customObject,
  })
);

export default function PaywallCustomObjectSetter() {
  const actions = useActions(PaywallBuilderSlice.actions);
  const [form] = Form.useForm<object>();

  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const { customObject } = useAppSelector(selector);

  const placeholder = {
    reviews: [
      {
        title: "Best app I've ever used",
        username: 'JoeP3',
        stars: 5,
        platform: 'apple',
      },
      {
        title: "Don't regret purchasing at all",
        username: 'Maraculousa',
        stars: 5,
        platform: 'google',
      },
    ],
  };

  return (
    <>
      <Tooltip
        title="Preview paywall with custom data source"
        mouseEnterDelay={0.8}
      >
        <Button
          type={Object.keys(customObject).length ? 'primary' : 'ghost'}
          icon={<Icon component={DatabaseIcon} style={{ fontSize: 15 }} />}
          onClick={openModal}
          size="small"
        />
      </Tooltip>
      <Modal
        title="Preview Custom Data Source"
        open={isModalOpen}
        centered
        zIndex={1005}
        footer={null}
        onCancel={closeModal}
      >
        <Form
          form={form}
          initialValues={{
            'custom-json': JSON.stringify(customObject),
          }}
          onFinish={updateLaunchCustomObject}
        >
          <FormItem
            rules={[
              {
                required: true,
                message: 'Provide a JSON string',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  try {
                    JSON.parse(value);
                    return Promise.resolve();
                  } catch (e) {
                    return Promise.reject(new Error('Invalid JSON'));
                  }
                },
              }),
            ]}
            name="custom-json"
          >
            <TextArea
              rows={8}
              placeholder={JSON.stringify(placeholder)}
              style={{ fontFamily: 'monospace' }}
            />
          </FormItem>
          <Space direction="horizontal">
            <Button
              type="primary"
              htmlType="submit"
              disabled={form
                .getFieldsError()
                .some(({ errors }) => errors.length)}
            >
              Apply
            </Button>
            <Button onClick={resetLaunchCustomObjects}>Clear</Button>
          </Space>
        </Form>
      </Modal>
    </>
  );

  function updateLaunchCustomObject() {
    actions.setLaunchCustomObject(
      JSON.parse(form.getFieldValue('custom-json'))
    );
    closeModal();
  }

  function resetLaunchCustomObjects() {
    form.setFieldValue('custom-json', '{}');
    actions.resetLaunchCustomObject();
    closeModal();
  }
}
