import React from 'react';

import { UnlockFilled } from '@ant-design/icons';
import { Button, Col, Modal, Row, Typography, notification } from 'antd';
import { DEV_MODE } from 'src/utils/dev';
import { namiBrightBlue } from 'src/variables';
import styled from 'styled-components';

import api from '../../api';
import {
  PlanMeta,
  RawPlanName,
  TDisplayProduct,
} from '../../api/types/main.types';
import { useAppContext } from '../../hooks';
import WebPaywallProductCard from './WebPaywallProductCard';

export const ModalSubtitle = styled.div`
  font-size: 14px;
`;

export type WebPaywallProps = {
  onCancel: () => void;
  visible: boolean;
};

type UpgradeWebPaywallProps = WebPaywallProps & {
  modalTitle?: string;
  maskBackground?: boolean;
  productsPaywallsOnly: TDisplayProduct[];
  productsPaywallsAndSubs: TDisplayProduct[];
  highlightedProductPaywallsOnly: RawPlanName;
  highlightedProductPaywallsAndSubs: RawPlanName;
  entitlement: string;
  upgradeToPaywallsAndSubsGroup?: boolean;
};
const UpgradeWebPaywall = ({
  modalTitle,
  productsPaywallsOnly,
  productsPaywallsAndSubs,
  highlightedProductPaywallsOnly,
  highlightedProductPaywallsAndSubs,
  entitlement,
  onCancel,
  visible,
  maskBackground,
  upgradeToPaywallsAndSubsGroup,
}: UpgradeWebPaywallProps) => {
  const context = useAppContext();
  const planNameRaw = context.getPlanName();
  const planMeta = PlanMeta[planNameRaw as RawPlanName];
  const selectedProducts: TDisplayProduct[] =
    planMeta.tier === 'paywallsOnly' && !upgradeToPaywallsAndSubsGroup
      ? productsPaywallsOnly
      : productsPaywallsAndSubs;
  const highlightedPlan: RawPlanName =
    planMeta.tier === 'paywallsOnly' && !upgradeToPaywallsAndSubsGroup
      ? highlightedProductPaywallsOnly
      : highlightedProductPaywallsAndSubs;

  const orgId = context.selectedOrg?.id;
  const username = context.user?.username;
  const userCanUpdatePlan = context.userHasEntitlement(
    'org.billing.plan.update'
  );

  return (
    <Modal
      width={900}
      title={
        <>
          <Typography.Title level={4}>
            <UnlockFilled style={{ color: namiBrightBlue, paddingRight: 5 }} />{' '}
            {modalTitle ? modalTitle : 'Upgrade your Plan'}
          </Typography.Title>
          <ModalSubtitle>
            You are currently on the <b>{planMeta.fullReadablePlanName}</b>{' '}
            plan. Choose a new plan or visit our
            <Button
              size="small"
              type="link"
              href="https://www.namiml.com/pricing"
              target="_blank"
            >
              <u>Pricing page</u>
            </Button>
            for full offer details.
          </ModalSubtitle>
        </>
      }
      centered
      onCancel={onCancel}
      open={visible}
      footer={null}
      zIndex={1003}
      maskStyle={
        maskBackground
          ? {
              backdropFilter: 'blur(10px)',
              WebkitBackdropFilter: 'blur(10px)',
            }
          : undefined
      }
      maskClosable={maskBackground}
    >
      <div>
        <Row gutter={16} justify="center" style={{ marginBottom: 12 }}>
          {selectedProducts?.map((product, index) => {
            return (
              !product.upgradeTo.includes(planNameRaw as RawPlanName) && (
                <Col sm={24} md={12} lg={8} key={index}>
                  <WebPaywallProductCard
                    product={product}
                    userCanPurchase={userCanUpdatePlan}
                    featured={
                      highlightedPlan === product.rawPlanName &&
                      highlightedPlan !== planNameRaw
                    }
                    orgHasProduct={product.rawPlanName === planNameRaw}
                    requestUpgrade={performRequestUpgrade}
                    purchaseLink={buildStripeLink(
                      getPurchaseLinkForProduct(product),
                      orgId,
                      username
                    )}
                  />
                </Col>
              )
            );
          })}
        </Row>
      </div>
    </Modal>
  );

  function performRequestUpgrade() {
    api.requestUpgrade(entitlement).then(() => {
      onCancel();
      notification.success({
        message: 'Thanks',
        description: 'Our team will be in touch to schedule a demo!',
      });
    });
  }

  function getPurchaseLinkForProduct(product: TDisplayProduct) {
    if (DEV_MODE) return product.purchaseLinkStaging;
    return product.purchaseLinkProd;
  }
};

export function buildStripeLink(
  url: string | undefined,
  orgId: string | undefined,
  email: string | undefined
): string {
  const params = new URLSearchParams({
    client_reference_id: orgId || '',
    prefilled_email: email || '',
  }).toString();
  return url + '?' + params;
}

export default UpgradeWebPaywall;
