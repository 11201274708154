import React from 'react';

import { Button, Col, Form } from 'antd';
import {
  TComponent,
  TConditionalComponent,
} from 'src/api/types/paywallTemplate.types';
import { useActions } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import styled from 'styled-components';

import InputLabel from './InputLabel';

type BuyPromoConditionProps = {
  component: TConditionalComponent;
  assertionIndex: number;
};

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label {
    padding-bottom: 0px !important;
  }
  margin-bottom: 12px;
`;

const StyledButton = styled(Button)`
  padding: 0px !important;
`;

export default function BuyPromoCondition({
  component,
  assertionIndex,
}: BuyPromoConditionProps) {
  const actions = useActions(PaywallBuilderSlice.actions);

  if (componentConditionalContainsOnTapAttribute(component, assertionIndex)) {
    return (
      <Col xs={24} md={24} key={`onTap${assertionIndex}`}>
        <StyledFormItem
          label={
            <InputLabel
              name={'Offer to Purchase'}
              collapsible={false}
              description={null}
            />
          }
        >
          <StyledButton
            type="link"
            onClick={() => {
              actions.setEditingMenuItem('products');
              actions.setEditingProductMenuTabItem('fields');
            }}
            size="small"
          >
            Promo Key
          </StyledButton>
        </StyledFormItem>
      </Col>
    );
  }

  return <></>;
}

export function componentConditionalContainsOnTapAttribute(
  component: TComponent,
  assertionIndex: number
): boolean {
  if (
    component.conditionAttributes?.length &&
    component.conditionAttributes[assertionIndex]
  ) {
    const attributes = component.conditionAttributes[assertionIndex].attributes;
    return Object.keys(attributes).includes('onTap');
  }
  return false;
}
