import React from 'react';

import { Card, Space, Typography } from 'antd';
import styled from 'styled-components';

type CardSlideProps = {
  title: string;
  description: string;
  action: React.ReactNode;
};

const SlideTitle = styled(Typography.Title)`
  margin-bottom: 0 !important;
`;

const SlideParagraph = styled(Typography.Paragraph)`
  margin-bottom: 1.5em !important;
`;

export default function CardSlide({
  title,
  description,
  action,
}: CardSlideProps) {
  return (
    <Card style={{ width: '100%' }} bordered={false}>
      <Space
        style={{ width: '100%' }}
        direction="vertical"
        align="center"
        size="small"
      >
        <SlideTitle level={4}>{title}</SlideTitle>
        <SlideParagraph>{description}</SlideParagraph>
        {action}
      </Space>
    </Card>
  );
}
