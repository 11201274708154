import decode from 'jwt-decode';

type TPayload = {
  iss: string;
  sub: string;
  aud: string[] | string;
  exp: number;
  nbf: number;
  iat: number;
  jti: string;
  name: string;
  source: string;
  session: string; // TODO: Remove, deprecated
  token_type: string;
  selected_org: string | null;
  entitlements: string[];
};
type TDecodedCache = { [key: string]: TPayload };

export default class Token {
  private readonly expirationThreshold: number = 10;
  private readonly token: string;
  private static readonly decodedCache: TDecodedCache = {};

  constructor(token: string) {
    this.token = token;
  }

  static getAccess(): Token {
    return new Token(window.localStorage.getItem('accessToken') as string);
  }

  static setAccess(token: string): void {
    window.localStorage.setItem('accessToken', token);
  }

  static getOrgId(): string | null {
    return this.getAccess().getOrgId();
  }

  getOrgId(): string | null {
    return this.getPayload().selected_org;
  }

  static removeAccess(): void {
    window.localStorage.removeItem('accessToken');
  }

  static getRefresh(): Token {
    return new Token(window.localStorage.getItem('refreshToken') as string);
  }

  static setRefresh(token: string): void {
    window.localStorage.setItem('refreshToken', token);
  }

  static removeRefresh(): void {
    window.localStorage.removeItem('refreshToken');
  }

  static getPreAuth(): Token {
    return new Token(window.localStorage.getItem('preToken') as string);
  }

  static setPreAuth(token: string): void {
    window.localStorage.setItem('preToken', token);
  }

  static removePreAuth(): void {
    window.localStorage.removeItem('preToken');
  }

  static removeAll(): void {
    Token.removePreAuth();
    Token.removeAccess();
    Token.removeRefresh();
  }

  toString(): string {
    return this.token;
  }

  isUsable(): boolean {
    return this.isValid() && !this.isExpired();
  }

  isValid(): boolean {
    return !!this.token;
  }

  getPayload(): TPayload {
    if (!(this.token in Token.decodedCache)) {
      Token.decodedCache[this.token] = decode(this.token);
    }
    return Token.decodedCache[this.token];
  }

  isExpired(): boolean {
    const { exp } = this.getPayload();
    const now = Math.round(new Date().getTime() / 1000);
    return exp! < now + this.expirationThreshold;
  }
}
