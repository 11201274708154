const aMinute = 60;
const anHour = 60 * aMinute;
const aDay = 24 * anHour;

const convert = {
  /**
   * @param days {number|string}
   * @returns {number}
   */
  daysToSeconds: (days) => days * aDay,
  /**
   * @param seconds {number|string}
   * @returns {number}
   */
  secondsToDays: (seconds) => Math.floor(seconds / aDay),
  /**
   * @param minutes {number|string}
   * @returns {number}
   */
  minutesToSeconds: (minutes) => minutes * aMinute,
  /**
   * @param seconds {number|string}
   * @returns {number}
   */
  secondsToMinutes: (seconds) => Math.floor(seconds / aMinute),
};

export default convert;
