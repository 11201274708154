import { namiLightGray } from 'src/variables';
import styled from 'styled-components';

const OffBadgeDot = styled.span`
  border-radius: 50%;
  border: 0.5px solid #c2c2c2;
  background-color: ${namiLightGray};
  width: 8px;
  height: 8px;
  display: inline-flex;
  box-shadow: 0.5px 0.5px 7px 0px rgba(76, 84, 90, 0.16);
`;

export default OffBadgeDot;
