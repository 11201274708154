import styled from 'styled-components';

const FieldHint = styled.span`
  display: block;
  clear: both;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
`;

export default FieldHint;
