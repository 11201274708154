import React, { useEffect, useState } from 'react';

import { Input, InputNumber, Select, Space } from 'antd';
import { valueType } from 'antd/lib/statistic/utils';
import styled from 'styled-components';
import tc from 'tinycolor2';

import { formatInputValues } from './formatters';
import { usePicker } from './PickerContextWrapper';

const StyledInputNumber = styled(InputNumber)`
  .ant-input-number-group-addon {
    background-color: white;
    padding: 0 3px;
  }
`;

const Inputs = ({ hideOpacity }: { hideOpacity: boolean }) => {
  const [disable, setDisable] = useState('');
  const [inputOption, setInputOption] = useState<'hex' | 'rgb'>('hex');
  const picker = usePicker();
  const hex = picker?.tinyColor.toHex();
  const [newHex, setNewHex] = useState(hex);

  useEffect(() => {
    if (disable !== 'hex') {
      setNewHex(hex);
    }
  }, [picker?.tinyColor, disable, hex]);

  const handleHex = (e: React.ChangeEvent<HTMLInputElement>) => {
    let tinyHex = tc(e.target.value);
    setNewHex(e.target.value);
    if (tinyHex.isValid()) {
      let { r, g, b } = tinyHex.toRgb();
      let newColor = `rgba(${r}, ${g}, ${b}, ${picker?.opacity})`;
      picker?.handleChange(newColor);
    }
  };

  if (!picker) return null;

  return (
    <Space style={{ marginTop: 12, width: '100%' }}>
      <Select
        value={inputOption}
        style={{ width: 76 }}
        size="small"
        options={[
          {
            value: 'hex',
            label: 'Hex',
          },
          {
            value: 'rgb',
            label: 'RGB',
          },
        ]}
        onChange={(value) => setInputOption(value)}
      />
      {inputOption === 'hex' && (
        <Input
          value={newHex}
          size="small"
          onChange={(e) => handleHex(e)}
          onFocus={() => setDisable('hex')}
          onBlur={() => setDisable('')}
        />
      )}
      {inputOption === 'rgb' && <RGBInputs />}
      {!hideOpacity && (
        <ManagedInput
          value={picker.opacity * 100}
          callback={(newVal: number) =>
            picker.handleChange(
              `rgba(${picker.r}, ${picker.g}, ${picker.b}, ${newVal / 100})`
            )
          }
          width={56}
          label={'%'}
        />
      )}
    </Space>
  );
};

export default Inputs;

const RGBInputs = () => {
  const picker = usePicker();

  const handleRgb = ({ r, g, b }: { r: number; g: number; b: number }) => {
    picker?.handleChange(`rgba(${r}, ${g}, ${b}, ${picker.opacity})`);
  };

  if (!picker) return null;

  return (
    <Space direction="horizontal" size={2}>
      <ManagedInput
        value={picker.r}
        callback={(newVal: number) =>
          handleRgb({ r: newVal, g: picker.g, b: picker.b })
        }
        max={255}
      />
      <ManagedInput
        value={picker.g}
        callback={(newVal: number) =>
          handleRgb({ r: picker.r, g: newVal, b: picker.b })
        }
        max={255}
      />
      <ManagedInput
        value={picker.b}
        callback={(newVal: number) =>
          handleRgb({ r: picker.r, g: picker.g, b: newVal })
        }
        max={255}
      />
    </Space>
  );
};

const ManagedInput = ({
  value,
  callback,
  max = 100,
  label,
  width = 45.75,
}: {
  value: number;
  callback: (newNumber: number) => void;
  max?: number;
  label?: string;
  width?: number;
}) => {
  const [temp, setTemp] = useState(value);

  useEffect(() => {
    setTemp(value);
  }, [value]);

  const onChange = (e: number | null) => {
    const newVal = formatInputValues(e || 0, 0, max);
    setTemp(newVal);
    callback(newVal);
  };

  return (
    <StyledInputNumber
      size="small"
      value={temp}
      onChange={(e: valueType | null) => onChange(e as number)}
      min={0}
      max={max}
      controls={false}
      style={{ width: width }}
      addonAfter={label}
    />
  );
};
