import { RcFile } from 'antd/es/upload';

import { client } from './clients';
import { TApiOptions, TFont, TListResult } from './types/main.types';
import {
  buildForm,
  buildURLParams,
  extractErrorData,
  extractResponseData,
} from './utils';

const fonts = {
  getFonts: (): Promise<TFont[]> => {
    return client
      .get('/private/fonts/')
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getOrgFonts: (apiOptions: TApiOptions = {}): Promise<TListResult<TFont>> => {
    const params = buildURLParams({ pageSize: 1000, ...apiOptions });
    return client
      .get(`/private/org_fonts/?${params}`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  addFonts: (files: RcFile[]): Promise<TFont[]> => {
    return client
      .post('/private/org_fonts/create_many/', buildForm({ files }))
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  deleteFont: (fontId: string): Promise<void> => {
    return client
      .delete(`/private/org_fonts/${fontId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
};
export default fonts;
