import React, { useCallback, useState } from 'react';

import { TApiOptions } from '../../api/types/main.types';
import { useDebouncedValue } from './debounce.hooks';

type DispatchUpdateAction = React.Dispatch<React.SetStateAction<TApiOptions>>;

export function useApiOptions(
  initialValue?: TApiOptions
): [TApiOptions, DispatchUpdateAction] {
  const [value, setValue] = useState<TApiOptions>(initialValue || {});

  const updateValue: DispatchUpdateAction = useCallback((newValue) => {
    setValue((state) => {
      const newState =
        typeof newValue === 'function' ? newValue(state) : newValue;
      const pageIsNotChanged = !newState.page || newState.page === state.page;
      const page = pageIsNotChanged ? 1 : newState.page || state.page;
      return { ...state, ...newState, page };
    });
  }, []);

  return [value, updateValue];
}

export function useDebouncedApiOptions(apiOptions?: TApiOptions, delay = 500) {
  const { search, ...rest } = apiOptions || {};
  const debouncedSearch = useDebouncedValue(search || '', delay);
  return { ...rest, search: debouncedSearch };
}
