import { client } from './clients';
import { LegalCitationType } from './types/legalCitation.types';
import { extractErrorData, extractListResponseData } from './utils';

const legalCitations = {
  getAppCitations: (appId: string): Promise<LegalCitationType[]> => {
    return client
      .get(`/private/legal_citations/?app_id=${appId}`)
      .then(extractListResponseData)
      .catch(extractErrorData);
  },
};

export default legalCitations;
