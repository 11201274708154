import React from 'react';

import { connect } from 'react-redux';
import { Form, Button, Modal, Image, Dimmer, Loader } from 'semantic-ui-react';

import {
  activateUserAuthenticator,
  disabledUserAuthenticator,
} from '../../../../../redux/actions/userActions';
import { isSuccessStatus } from '../../../../../services/helpers';

const CreateTOPTModal = ({
  open,
  code,
  onCodeChange,
  //Redux
  loading,
  authenticator,
  disabledUserAuthenticator,
  activateUserAuthenticator,
  onClose,
  onSuccess,
}) => {
  const close = () => {
    disabledUserAuthenticator(authenticator.id);
    onClose();
  };
  const submit = () => {
    activateUserAuthenticator(authenticator.id, code).then((action) => {
      if (isSuccessStatus(action.payload?.status)) {
        onSuccess();
      }
    });
  };
  return (
    <Modal
      dimmer="blurring"
      closeOnDimmerClick={true}
      onClose={close}
      size="mini"
      open={open}
    >
      <Modal.Content>
        <Modal.Header as="h3">Set up 2FA</Modal.Header>
        <Modal.Description>
          <Dimmer active={loading} inverted>
            <Loader content="Activating 2FA ..." />
          </Dimmer>
          <p>
            Scan the QR code below with your authenticator app and enter the
            token generated by your app to confirm configuration.
          </p>
          <Form onSubmit={submit}>
            <Form.Input
              placeholder="6 Digit code"
              name="Code"
              label="Code"
              value={code}
              type="number"
              onChange={(e) => onCodeChange(e.target.value)}
            />
            <Image
              src={authenticator.qr_code}
              style={{ width: 200, margin: '0 auto' }}
            />
            <Button
              disabled={code.length < 6}
              fluid
              className="nami-primary-button"
              type="submit"
            >
              Activate 2FA
            </Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    activateUserAuthenticator: (authenticatorId, code) =>
      dispatch(activateUserAuthenticator(authenticatorId, code)),
    disabledUserAuthenticator: (authenticatorId) =>
      dispatch(disabledUserAuthenticator(authenticatorId)),
  };
};

const mapStateToProps = ({ user }) => {
  return {
    loading: user.loading,
    authenticator: user.authenticator,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateTOPTModal);
