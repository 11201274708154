import React, { useContext, useState } from 'react';

import { DownloadOutlined, LockFilled } from '@ant-design/icons';
import { Button, Space } from 'antd';
import Moment from 'moment';
import {
  DataKey,
  DataKeys,
  SegmentData,
  SegmentOptions,
  TData,
} from 'src/api/types/analytics.types';
import CSVDownloadWebPaywall from 'src/components/WebPaywalls/CSVDownloadWebPaywall';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { getLabel } from 'src/utils/formatting';
import { toSlug } from 'src/utils/string';

import { AppContext } from '../../../AppContextProvider';

type InsightsDownloadButtonProps<Data> = {
  title: string;
  data: Data[];
  loading: boolean;
};

export default function InsightsDownloadButton<Data extends TData>({
  title,
  data,
  loading,
}: InsightsDownloadButtonProps<Data>) {
  const { dateRange, segment } = useAppSelector(({ analytic }) => {
    const { segment, dateRangeInsights: dateRange } = analytic;
    return { dateRange, segment };
  });
  const [isUpgradeOpen, openUpgrade] = useState(false);
  const appContext = useContext(AppContext);
  const canDownload = appContext.userHasEntitlement(
    'app.analytics.export.csv_download'
  );
  if (canDownload) {
    const startDate = dateRange[0].format('YYYYMMDD');
    const endDate = dateRange[1].format('YYYYMMDD');
    const filenamePrefix = `nami-${toSlug(title)}-${startDate}-${endDate}`;
    return (
      <Button
        style={{ float: 'right' }}
        onClick={() =>
          downloadCsv(
            buildCsv(data, segment ? SegmentOptions[segment] : null, title),
            `${filenamePrefix}.csv`
          )
        }
        disabled={loading || !data.length}
      >
        <Space>
          <DownloadOutlined />
          Download data
        </Space>
      </Button>
    );
  }
  return (
    <>
      <Button style={{ float: 'right' }} onClick={() => openUpgrade(true)}>
        <Space>
          <LockFilled />
          Download data
        </Space>
      </Button>
      <CSVDownloadWebPaywall
        visible={isUpgradeOpen}
        onCancel={() => openUpgrade(false)}
      />
    </>
  );
}

export function buildCsv(
  data: TData[],
  segment: SegmentData | null,
  title: string
): string {
  const csvArray: Array<string | number>[] = [];

  if (segment) {
    const preHeaderColumns: string[] = new Array(data.length + 1);
    preHeaderColumns[0] = segment
      ? title + ' by ' + segment.display_label
      : title;
    csvArray.push(preHeaderColumns);
  }
  const headerColumns: string[] = new Array(data.length + 1);
  headerColumns[0] = 'Date';
  csvArray.push(headerColumns);
  data[0].series.forEach((column) => {
    const rowPlaceholder = new Array(data.length + 1);
    rowPlaceholder[0] = Moment(column.x).format('YYYY-MM-DD HH:mm');
    csvArray.push(rowPlaceholder);
  });

  data.forEach((dataPoint, i) => {
    const keyParts = dataPoint.key.split(',');
    const key = segment && keyParts.length > 1 ? keyParts[1] : keyParts[0];
    const keyMeta = DataKeys[key as DataKey];
    const label = getLabel(keyMeta, dataPoint, segment);
    let startRowIndex = 1;
    if (segment) {
      csvArray[0][i + 1] = segment ? keyParts[0] : '';
      csvArray[1][i + 1] = segment ? label + ' ' + keyMeta.display_name : label;
      startRowIndex = 2;
    } else {
      csvArray[0][i + 1] = segment ? label + ' ' + keyMeta.display_name : label;
    }
    dataPoint.series.forEach((serie, j) => {
      csvArray[j + startRowIndex][i + 1] = serie.value;
    });
  });

  return csvArray.map((row) => row.join()).join('\n') + '\n';
}

function downloadCsv(content: string, filename: string): void {
  const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  if (typeof link.download === 'undefined') return;
  // Browsers that support HTML5 download attribute
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
