import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import MinutesInput from './MinutesInput';

const MinutesField = ({ label, name, value, onChange, error, help }) => {
  return (
    <Form.Field>
      <label>{label}</label>
      <MinutesInput
        name={name}
        onChange={onChange}
        value={value}
        error={error}
      />
      {!error && <small>{help}</small>}
    </Form.Field>
  );
};

MinutesField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  help: PropTypes.string.isRequired,
};

export default MinutesField;
