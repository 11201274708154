import React, { useMemo } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { useCampaignLabelIdMap } from 'src/hooks/queries/campaign.hooks';
import styled from 'styled-components';

import PlacementSelect from './PlacementSelect';

type PlacementFilterProps = {
  selectedLabel: string | null;
  onChange: (value: string) => void;
  onClear: () => void;
};

const WithWidth = styled.div<{ as: any }>`
  width: 100%;
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
`;

export default function PlacementFilter({
  selectedLabel,
  onChange,
  onClear,
}: PlacementFilterProps) {
  const labelIdValueMap = useCampaignLabelIdMap();
  const options = useMemo(
    () =>
      Object.entries(labelIdValueMap).map(([id, value]) => {
        const label = `${value.value}`;
        return { label, text: label, value: id };
      }),
    [labelIdValueMap]
  );

  return (
    <SelectWrapper>
      <WithWidth
        as={PlacementSelect}
        allowClear
        mode={undefined}
        showArrow={false}
        optionFilterProp="text"
        options={options}
        value={selectedLabel}
        onChange={(value: string) => onChange(value as string)}
        onClear={onClear}
        clearIcon={<CloseOutlined />}
        label={selectedLabel ? 'Placement:' : ''}
        placeholder={
          <>
            Filter by <b>Placement</b>
          </>
        }
        filterOption={(
          input: string,
          option: { label: string; text: string; value: string }
        ) => (option?.label || '').toLowerCase().includes(input.toLowerCase())}
        showSearch={true}
      />
    </SelectWrapper>
  );
}
