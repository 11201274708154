import React from 'react';

import { Button, Col, Form, Input, Modal, Row } from 'antd';
import FormItem from 'antd/es/form/FormItem';

type EditProductFieldModalProps = {
  baseValue: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
};

type TProductFieldPayload = {
  name: string;
  defaultValue: string;
};

export default function EditProductFieldModal({
  baseValue,
  isOpen,
  onClose,
  onSubmit,
}: EditProductFieldModalProps) {
  const [form] = Form.useForm<TProductFieldPayload>();

  return (
    <Modal
      title="Edit Product Field"
      open={isOpen}
      footer={null}
      centered
      onCancel={onClose}
      zIndex={1005}
      forceRender
      afterClose={() => {
        form.resetFields();
      }}
    >
      <Form
        layout="vertical"
        form={form}
        requiredMark={false}
        initialValues={{ name: baseValue }}
      >
        <>
          <Row gutter={16}>
            <Col xs={18}>
              <FormItem
                label="Field Name"
                required
                tooltip="Give this product field a useful name such as 'Subtext' or 'Trial Period Text'"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Provide a value',
                  },
                ]}
              >
                <Input maxLength={100} />
              </FormItem>
            </Col>
          </Row>
          <Button
            htmlType="submit"
            type="primary"
            disabled={form.getFieldsError().some(({ errors }) => errors.length)}
            onClick={() => {
              form
                .validateFields()
                .then(() => onSubmit(form.getFieldValue('name')));
            }}
          >
            Edit
          </Button>
        </>
      </Form>
    </Modal>
  );
}
