import { toast } from 'react-toastify';

import * as TYPES from '../types';

const initialState = {
  loading: true,
  teamData: [],
  currentMember: null,
  currentMemberError: null,
  changes: false,
  teamRoles: [],
};

export const teamReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_TEAM:
    case TYPES.GET_TEAM_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_TEAM_SUCCESS:
      return {
        ...state,
        teamData: action.payload.data.accounts,
        loading: false,
      };
    case TYPES.GET_TEAM_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_TEAM_MEMBER:
    case TYPES.GET_TEAM_MEMBER_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_TEAM_MEMBER_SUCCESS:
      return {
        ...state,
        currentMember: action.payload.data,
        loading: false,
        changes: false,
      };
    case TYPES.GET_TEAM_MEMBER_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.UPDATE_TEAM_MEMBER_ROLE:
    case TYPES.UPDATE_TEAM_MEMBER_ROLE_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.UPDATE_TEAM_MEMBER_ROLE_SUCCESS:
      toast.success('Successfully updated team member role.');
      return {
        ...state,
        loading: false,
        changes: false,
      };
    case TYPES.UPDATE_TEAM_MEMBER_ROLE_FAIL:
      return {
        ...state,
        currentMemberError: action.error.response.data,
        loading: false,
      };

    case TYPES.REMOVE_TEAM_MEMBER:
    case TYPES.REMOVE_TEAM_MEMBER_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.REMOVE_TEAM_MEMBER_SUCCESS:
      toast.success('Successfully removed team member.');
      return {
        ...state,
        loading: false,
      };
    case TYPES.REMOVE_TEAM_MEMBER_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_TEAM_ROLES:
    case TYPES.GET_TEAM_ROLES_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_TEAM_ROLES_SUCCESS:
      return {
        ...state,
        teamRoles: action.payload.data.results,
        loading: false,
      };
    case TYPES.GET_TEAM_ROLES_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.HANDLE_CURRENT_MEMBER_FIELD:
      return {
        ...state,
        currentMember: {
          ...state.currentMember,
          [action.payload.key]: action.payload.value,
        },
        changes: true,
      };
    default:
      return state;
  }
};
