import React from 'react';

import styles from './TimeZoneLabel.module.less';

const TimeZoneLabel = ({ timezone }: { timezone?: string }) => {
  return (
    <div className={styles.label}>
      Metrics displayed in {timezone || 'UTC'} time. Most metrics updated
      hourly.
      <br />
      Currency conversion by APILayer. Conversion rates updated every 24 hours.
    </div>
  );
};

export default TimeZoneLabel;
