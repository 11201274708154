import React from 'react';

import { Form, Input, Modal, notification } from 'antd';

import api from '../../api';
import FormItem from '../../components/FormItem';
import { usePromiseState } from '../../hooks';

type FeatureRequestModalProps = {
  title: string;
  nameLabel: string;
  urlLabel: string;
  useCaseLabel: string;
  visible: boolean;
  showUrlField?: boolean;
  onClose: () => void;
};

export default function FeatureRequestModal({
  title,
  showUrlField = true,
  nameLabel,
  urlLabel,
  useCaseLabel,
  visible,
  onClose,
}: FeatureRequestModalProps) {
  const [form] = Form.useForm();
  const request = usePromiseState(() => {
    return api.newFeaturedRequest(form.getFieldsValue()).then(() => {
      onClose();
      notification.success({
        message: 'Thanks',
        description: 'We will follow up with you about your request.',
      });
      form.resetFields();
    });
  });

  const handleCancel = () => {
    onClose();
    form.resetFields();
  };

  return (
    <Modal
      title={<strong>{title}</strong>}
      centered
      open={visible}
      onOk={form.submit}
      onCancel={handleCancel}
      okText="Submit"
      confirmLoading={!!request.isLoading}
    >
      <Form
        form={form}
        onFinish={request.trigger}
        initialValues={{ name: '', url: '', use_case: '' }}
      >
        <FormItem name="name" error={request?.error?.name}>
          <Input placeholder={nameLabel} />
        </FormItem>
        <FormItem name="url" error={request.error?.url} hidden={!showUrlField}>
          <Input placeholder={urlLabel} />
        </FormItem>
        <FormItem name="use_case" error={request.error?.use_case}>
          <Input.TextArea
            placeholder={useCaseLabel}
            showCount
            maxLength={1000}
          />
        </FormItem>
      </Form>
    </Modal>
  );
}
