import React, { useMemo, useState } from 'react';

import { LeftOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Dropdown,
  Form,
  MenuProps,
  Popconfirm,
  Row,
  notification,
} from 'antd';
import {
  TFieldSettings,
  TFormSection,
} from 'src/api/types/paywallTemplate.types';
import PaywallBuilderDrawer from 'src/pages/admin/paywalls/PaywallBuilder//PaywallBuilderDrawer';
import PaywallBuilderDrawerTitle from 'src/pages/admin/paywalls/PaywallBuilder/PaywallBuilderDrawerTitle';
import { interpolate } from 'src/utils/interpolation';

import { useActions, useAppSelector } from '../../../../../hooks/redux.hooks';
import PaywallBuilderSlice from '../../../../../redux/PaywallBuilderSlice';
import { truncateString } from '../../../../../utils/string';
import { buildMediaVariables } from '../../utils/variables';
import PaywallBuilderDrawerRight from '../PaywallBuilderDrawerRight';
import { FormSection } from '../editor/FormSection';
import ProductPricingFormItem from '../editor/inputs/ProductPricingFormItem';
import SelectGroupsToEditModal from '../modals/SelectGroupsToEditModal';
import SelectPlatformsToEditModal from '../modals/SelectPlatformsToEditModal';

export default function ProductEditor({
  formVersion,
}: {
  formVersion: 'formGroups' | 'v2Form';
}) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const [isGroupSelectModalOpen, setGroupSelectModalOpen] = useState(false);
  const [isPlatformSelectModalOpen, setPlatformSelectModalOpen] =
    useState(false);
  const {
    itemId,
    skuName,
    variables,
    template,
    mediaList,
    productPricingCapability,
    productTrialCapability,
    anySkuHasTrialOffer,
    anySkuHasIntroOffer,
    anySkuHasPromoOffer,
    productGroups,
    skuItems,
    skuVariables,
  } = useAppSelector(({ paywallBuilder }) => {
    const {
      skuItems,
      platformId,
      groupId,
      paywall,
      mediaList,
      anySkuHasTrialOffer,
      anySkuHasIntroOffer,
      anySkuHasPromoOffer,
      productGroups,
    } = paywallBuilder;
    const itemId = paywallBuilder.editingProductId;
    const product =
      (platformId &&
        itemId &&
        groupId &&
        skuItems[groupId][platformId][itemId]) ||
      null;
    return {
      itemId,
      skuName: product?.name || 'Display',
      template: paywall?.template || null,
      variables: product?.variables || null,
      mediaList: mediaList,
      productPricingCapability:
        (paywall?.template['ui.capabilities'] || []).includes(
          'conditional_product_offer'
        ) ||
        (paywall?.template['ui.capabilities'] || []).includes(
          'toggle_product_offer'
        ),
      productTrialCapability:
        (paywall?.template['ui.capabilities'] || []).includes(
          'conditional_product_trial'
        ) ||
        (paywall?.template['ui.capabilities'] || []).includes(
          'toggle_product_trial'
        ),
      anySkuHasTrialOffer,
      anySkuHasIntroOffer,
      anySkuHasPromoOffer,
      productGroups,
      skuItems,
      skuVariables: (paywall?.template['ui.productSettings'] || {})
        ?.variablesList,
    };
  });

  const replacements: {} = useMemo(() => {
    const state = {
      ...(template?.initialState || {}),
      anySkuHasTrialOffer,
      anySkuHasIntroOffer,
      anySkuHasPromoOffer,
    };
    return {
      state,
      sku: variables,
      media: buildMediaVariables(mediaList, { convertToUrl: true }),
    };
  }, [
    anySkuHasIntroOffer,
    anySkuHasPromoOffer,
    anySkuHasTrialOffer,
    mediaList,
    template?.initialState,
    variables,
  ]);

  const parsedTemplate = useMemo(() => {
    if (formVersion === 'v2Form') return null;
    if (template === null) return null;
    return interpolate(template, interpolate(replacements, replacements));
  }, [formVersion, template, replacements]);

  const skuFields = useMemo(() => {
    if (formVersion === 'formGroups') return null;
    const fields = (skuVariables || []).reduce((output, variable) => {
      return [
        ...output,
        {
          type: variable.type === 'boolean' ? 'toggle' : 'text', //TODO - handle promo key
          label: variable.display_name,
          variable: variable.variable,
        } as TFieldSettings,
      ];
    }, [] as TFieldSettings[]);

    return interpolate(fields, interpolate(replacements, replacements));
  }, [formVersion, skuVariables, replacements]);

  const sections: TFormSection[] = useMemo(() => {
    if (formVersion === 'v2Form') {
      return [
        {
          title: 'Content',
          fields: skuFields || [],
          collapsible: false,
          displayVar: null,
          hint: null,
        },
      ];
    }
    if (!(parsedTemplate?.['ui.productSettings'] && variables)) return []; //TODO - is variables being used correctly?
    return parsedTemplate['ui.productSettings'].fields;
  }, [formVersion, parsedTemplate, variables, skuFields]);

  const productGroupIds = useMemo(() => {
    return productGroups.map((group) => {
      return group.id;
    });
  }, [productGroups]);

  const countProducts = useMemo(() => {
    let count = 0;
    for (const group in skuItems) {
      if (productGroupIds.includes(group)) {
        for (const platform in skuItems[group]) {
          count = count + Object.keys(skuItems[group][platform]).length;
        }
      }
    }
    return count;
  }, [productGroupIds, skuItems]);

  const dropdownOnClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'group') setGroupSelectModalOpen(true);
    else setPlatformSelectModalOpen(true);
  };

  let dropdownItems = [
    {
      label: 'Apply to select platforms',
      key: 'platform',
    },
  ];
  if (productGroups.length > 1) {
    dropdownItems.unshift({
      label: 'Apply to select groups',
      key: 'group',
    });
  }

  const children = itemId && (
    <Form layout="vertical">
      <Row gutter={16}>
        {formVersion === 'formGroups' && productPricingCapability && (
          <Col xs={24} key="offer_state_selector">
            <ProductPricingFormItem includeIntroPromo={true} />
          </Col>
        )}
        {formVersion === 'formGroups' && productTrialCapability && (
          <Col xs={24} key="trial_state_selector">
            <ProductPricingFormItem includeIntroPromo={false} />
          </Col>
        )}
        {sections.map((section) => (
          <FormSection
            key={section.title}
            variables={variables || {}}
            isSingle={sections.length === 1}
            collapseIntercomPrefix="intercom-product"
            onChange={(variable, value) => {
              const data = { ...variables, [variable]: value };
              actions.updateProductVariables({ itemId: itemId!, data });
            }}
            {...section}
          />
        ))}
        <Dropdown.Button
          menu={{ items: dropdownItems, onClick: dropdownOnClick }}
          style={{ margin: '24px 10px' }}
          buttonsRender={([_leftButton, rightButton]) => [
            <Popconfirm
              title={`Apply these edits to ${countProducts} products?`}
              onConfirm={() => {
                actions.pushToAllProductVariablesInGroups({
                  originId: itemId!,
                  groups: productGroupIds,
                });
                notification.success({
                  message: `Successfully updated products`,
                });
              }}
              placement="bottom"
              okText="Yes"
              cancelText="No"
            >
              <Button block={true}>Apply Edits to All Products</Button>
            </Popconfirm>,
            rightButton,
          ]}
        />
      </Row>
    </Form>
  );

  let drawer: React.ReactNode = (
    <PaywallBuilderDrawer
      title={
        <PaywallBuilderDrawerTitle>
          Edit {truncateString(skuName, 26)}
        </PaywallBuilderDrawerTitle>
      }
      onClose={() => actions.setEditingProductId(null)}
      open={!!itemId}
      closeIcon={<LeftOutlined style={{ fontSize: 15 }} />}
      className="verticalScrollDrawer intercom-skuDisplayEditor"
    >
      {children}
    </PaywallBuilderDrawer>
  );

  if (formVersion === 'v2Form') {
    drawer = (
      <PaywallBuilderDrawerRight
        title={
          <PaywallBuilderDrawerTitle>
            Edit {truncateString(skuName, 26)}
          </PaywallBuilderDrawerTitle>
        }
        onClose={() => actions.setEditingProductId(null)}
        open={!!itemId}
        closeIcon={<LeftOutlined style={{ fontSize: 15 }} />}
        className="verticalScrollDrawer intercom-skuDisplayEditor"
      >
        {children}
      </PaywallBuilderDrawerRight>
    );
  }

  return (
    <>
      {drawer}
      <SelectGroupsToEditModal
        open={isGroupSelectModalOpen}
        onClose={() => setGroupSelectModalOpen(false)}
        onFinish={(groupIds: string[]) => {
          actions.pushToAllProductVariablesInGroups({
            originId: itemId!,
            groups: groupIds,
          });
          notification.success({
            message: `Successfully updated products`,
          });
          setGroupSelectModalOpen(false);
        }}
      />
      <SelectPlatformsToEditModal
        open={isPlatformSelectModalOpen}
        onClose={() => setPlatformSelectModalOpen(false)}
        onFinish={(platformIds: string[]) => {
          actions.pushToAllProductVariablesInPlatforms({
            originId: itemId!,
            platforms: platformIds,
          });
          notification.success({
            message: `Successfully updated products`,
          });
          setPlatformSelectModalOpen(false);
        }}
      />
    </>
  );
}
