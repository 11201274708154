import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { createSelector } from '@reduxjs/toolkit';
import { Button, Divider, Modal, Select, Space, Typography } from 'antd';
import { TProductVariableType } from 'src/api/types/paywallTemplate.types';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';

import AddProductFieldModal from './AddProductFieldModal';

type SelectProductFieldModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (label: string) => void;
  onSubmitAdd: (label: string, type: TProductVariableType, value: any) => void;
};

const selector = createSelector(
  [({ paywallBuilder: { paywall } }: RootState) => paywall],
  (paywall) => ({
    currentProductFields:
      paywall!.template['ui.productSettings']?.variablesList,
  })
);

export default function SelectProductFieldModal({
  isOpen,
  onClose,
  onSubmit,
  onSubmitAdd,
}: SelectProductFieldModalProps) {
  const { currentProductFields } = useAppSelector(selector);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [selectOpen, setSelectOpen] = useState(false);
  const [selectedProductField, setSelectedProductField] = useState<
    string | null
  >((currentProductFields && currentProductFields[0].variable) || null);

  return (
    <Modal
      title="Select Product Field"
      open={isOpen}
      footer={null}
      centered
      closable={true}
      zIndex={1005}
      onCancel={onClose}
      forceRender
    >
      <>
        <Typography.Paragraph>
          Select an existing product field to use or create a new one
        </Typography.Paragraph>
        <Select
          options={(currentProductFields || []).map((currentField) => ({
            key: currentField.variable,
            value: currentField.variable,
            label: currentField.display_name,
          }))}
          value={selectedProductField}
          showSearch={true}
          open={selectOpen}
          onDropdownVisibleChange={(visible) => setSelectOpen(visible)}
          dropdownRender={(menu) => (
            <>
              {menu}
              <>
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setSelectOpen(false);
                      setAddModalOpen(true);
                    }}
                  >
                    Add Product Field
                  </Button>
                </Space>
                <AddProductFieldModal
                  isOpen={isAddModalOpen}
                  onClose={() => setAddModalOpen(false)}
                  onSubmit={(label, type, value) => {
                    onSubmitAdd(label, type, value);
                    setAddModalOpen(false);
                  }}
                />
              </>
            </>
          )}
          filterOption={(input, option) =>
            (option?.value || '').toLowerCase().includes(input.toLowerCase())
          }
          style={{ width: '100%', marginBottom: '24px' }}
          onChange={(newId) => setSelectedProductField(newId)}
        />
        <Button
          htmlType="submit"
          type="primary"
          disabled={!selectedProductField}
          onClick={() => {
            if (selectedProductField) onSubmit(selectedProductField);
          }}
        >
          Add
        </Button>
      </>
    </Modal>
  );
}
