import React, { useEffect, useRef } from 'react';

import ReactHlsPlayer from 'react-hls-player';
import { TVideoComponent } from 'src/api/types/paywallTemplate.types';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import styled, { css } from 'styled-components';

import { transition } from '../css';
import HoverTag from './HoverTag';

type VideoContainerProps = { component: TVideoComponent };

const VideoWrapper = styled.video<VideoContainerProps>`
  ${({
    component: {
      alignment,
      imageCropping,
      height,
      width,
      spacing,
      rightMargin,
    },
  }) => {
    const fit = imageCropping === 'fit';
    return css`
      ${transition()};
      object-fit: ${fit ? 'contain' : 'cover'};
      object-position: ${alignment} center;
      background-repeat: no-repeat;
      height: ${parseSize(height)};
      width: ${parseSize(width)};
      margin-right: ${rightMargin || spacing || 0}px;
    `;
  }}
`;

const HlsWrapper = styled(ReactHlsPlayer)<VideoContainerProps>`
  ${({
    component: {
      alignment,
      imageCropping,
      height,
      width,
      spacing,
      rightMargin,
    },
  }) => {
    const fit = imageCropping === 'fit';
    return css`
      ${transition()};
      object-fit: ${fit ? 'contain' : 'cover'};
      object-position: ${alignment} center;
      background-repeat: no-repeat;
      height: ${parseSize(height)};
      width: ${parseSize(width)};
      margin-right: ${rightMargin || spacing || 0}px;
    `;
  }}
`;

export default function VideoContainer({ component }: VideoContainerProps) {
  const hlsPlayerRef = useRef<HTMLVideoElement | null>(null);
  const basePlayerRef = useRef<HTMLVideoElement | null>(null);
  const actions = useActions(PaywallBuilderSlice.actions);
  const videoMuted = useAppSelector(
    ({ paywallBuilder: { videoMuted } }: RootState) => videoMuted
  );
  const videoPlaying = useAppSelector(
    ({ paywallBuilder: { videoPlaying } }: RootState) => videoPlaying
  );

  useEffect(() => {
    if (videoPlaying) {
      hlsPlayerRef.current?.play();
      basePlayerRef.current?.play();
    } else {
      hlsPlayerRef.current?.pause();
      basePlayerRef.current?.pause();
    }
  }, [videoPlaying]);

  return (
    <HoverTag
      title={component.namiComponentType ? component.title || component.id : ''}
      namiComponentType={component.namiComponentType}
      id={component.id}
    >
      {component.url && isVideoHLS(component.url) ? (
        <HlsWrapper
          autoPlay={videoPlaying}
          muted={videoMuted}
          component={component}
          src={component.url}
          loop={component.loopVideo}
          poster={component.fallbackImage || ''}
          controls={component.controlsType === 'nativeUi'}
          playerRef={hlsPlayerRef}
          onPlay={() => actions.setVideoPlaying(true)}
          onPause={() => actions.setVideoPlaying(false)}
          onVolumeChange={(e) => {
            if (e.currentTarget.muted || e.currentTarget.volume < 0.1) {
              actions.setVideoMuted(true);
            } else {
              actions.setVideoMuted(false);
            }
          }}
        />
      ) : (
        <VideoWrapper
          autoPlay={videoPlaying}
          muted={videoMuted}
          component={component}
          src={component.url || undefined}
          loop={component.loopVideo}
          poster={component.fallbackImage || ''}
          controls={component.controlsType === 'nativeUi'}
          ref={basePlayerRef}
          onPlay={() => actions.setVideoPlaying(true)}
          onPause={() => actions.setVideoPlaying(false)}
          onVolumeChange={(e) => {
            if (e.currentTarget.muted || e.currentTarget.volume < 0.1) {
              actions.setVideoMuted(true);
            } else {
              actions.setVideoMuted(false);
            }
          }}
        />
      )}
    </HoverTag>
  );
}

function isVideoHLS(videoUrl: string) {
  return videoUrl.endsWith('m3u8');
}

function parseSize(value: undefined | string | number): string {
  if (typeof value === 'undefined') return '100%';
  return typeof value === 'number' ? `${value}px` : value;
}
