import React, { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Grid, Icon, Message, Segment } from 'semantic-ui-react';

import api from '../../../../api';
import { LegalCitationType } from '../../../../api/types/legalCitation.types';
import { PlatformType } from '../../../../api/types/main.types';
import ConfirmChangesModal from '../../../../components/ConfirmChangesModal/ConfirmChangesModal';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import { SaveChangesHeader } from '../../../../components/Headers';
import Loading from '../../../../components/Loading/Loading';
import Page from '../../../../components/Page/Page';
import PreviewPaywall from '../../../../components/PreviewPaywall/PreviewPaywall';
import PaywallProductsSidebar from '../../../../components/Sidebars/PaywallProductsSidebar/PaywallProductsSidebar';
import PaywallSidebar from '../../../../components/Sidebars/PaywallSidebar/PaywallSidebar';
import PaywallStylesSidebar from '../../../../components/Sidebars/PaywallStylesSidebar/PaywallStylesSidebar';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux.hooks';
import { getCitations } from '../../../../redux/actions/citationActions';
import {
  getPaywall,
  getPaywalls,
  toggleDisplayUnsavedChanges,
  updatePaywall,
  updatePaywallMenus,
  updatePaywallStyles,
} from '../../../../redux/actions/paywallActions';
import * as TYPES from '../../../../redux/types';
import history from '../../../../services/history';

export default function PaywallDetailLegacyPage() {
  const [paywallSidebar, setPaywallSidebar] = useState(false);
  const [stylesSidebar, setStylesSidebar] = useState(false);
  const [productsSidebar, setProductsSidebar] = useState(false);
  const [platforms, setPlatforms] = useState<PlatformType[]>([]);
  const [platform, setPlatform] = useState('');
  const [confirm, setConfirm] = useState(false);
  const { paywallID: paywallId } = useParams<{ paywallID: string }>();

  // Redux hooks
  const {
    currentApp,
    paywall,
    changes,
    displayUnsavedChanges,
    paywallMenus,
    paywallMenusRoot,
    paywallStyles,
    loading,
    citations,
  } = useAppSelector(({ root, citation, paywall }) => ({
    currentApp: root.currentApp,
    paywall: paywall.paywall,
    changes: paywall.changes,
    displayUnsavedChanges: paywall.displayUnsavedChanges,
    paywallMenus: paywall.paywallMenus,
    paywallMenusRoot: paywall.paywallMenusRoot,
    paywallStyles: paywall.paywallStyles,
    loading: paywall.loading,
    citations: citation.citations as LegalCitationType[],
  }));
  const dispatch = useAppDispatch();

  const citation = useMemo(
    () => citations.find(({ language }) => language === paywall.language),
    [citations, paywall.language]
  );
  const clickwrapText = citation?.clickwrap_text;

  useEffect(() => {
    dispatch(getPaywall(paywallId));
  }, [paywallId, currentApp, dispatch]);

  useEffect(() => {
    if (!currentApp?.id) return;
    dispatch(getCitations(currentApp.id));
    api.getPlatforms(currentApp.id).then(setPlatforms);
  }, [paywallId, currentApp, paywall.language, dispatch]);

  if (!loading && !paywall) return null;

  let children;
  if (loading && !paywall) {
    children = <Loading />;
  } else {
    children = (
      <div style={{ minHeight: '60vh' }}>
        {paywall && paywall.live && (
          <Segment basic textAlign="center">
            <Icon color="yellow" name="warning sign" /> This paywall is{' '}
            <span style={{ fontWeight: 'bold' }}> LIVE</span>
          </Segment>
        )}
        {!loading &&
          currentApp &&
          paywall?.id &&
          paywall?.style &&
          paywallStyles && (
            <>
              <Segment
                style={{ minHeight: '450px' }}
                onClick={() => setPaywallSidebar(false)}
              >
                <Grid>
                  <Grid.Column
                    textAlign="center"
                    mobile={16}
                    tablet={16}
                    computer={16}
                  >
                    <Button.Group>
                      <Button
                        icon
                        active={paywallSidebar}
                        onClick={(e) => {
                          e.stopPropagation();
                          setPaywallSidebar((state) => !state);
                          setStylesSidebar(false);
                          setProductsSidebar(false);
                        }}
                      >
                        <Icon name="pencil" />
                      </Button>
                      <Button
                        active={stylesSidebar}
                        icon
                        onClick={(e) => {
                          e.stopPropagation();
                          setPaywallSidebar(false);
                          setStylesSidebar((state) => !state);
                          setProductsSidebar(false);
                        }}
                      >
                        <Icon name="paint brush" />
                      </Button>
                      <Button
                        active={productsSidebar}
                        icon
                        onClick={(e) => {
                          e.stopPropagation();
                          setPaywallSidebar(false);
                          setStylesSidebar(false);
                          setProductsSidebar((state) => !state);
                        }}
                      >
                        <Icon name="tag" />
                      </Button>
                    </Button.Group>
                    <PreviewPaywall
                      title={paywall.marketing_content.title || ''}
                      body={paywall.marketing_content.body || ''}
                      imagePhone={paywall.background_image_url_phone}
                      imageTablet={paywall.background_image_url_tablet}
                      products={paywallMenus}
                      purchaseTerms={paywall.purchase_terms}
                      restore={paywall.restore_control}
                      signIn={paywall.sign_in_control}
                      showOverlay={paywall.use_bottom_overlay}
                      skusInOverlay={paywall.skus_in_scrollable_region}
                      sizeOverlay={paywall.scrollable_region_size}
                      paywallStyles={paywallStyles}
                      legal={clickwrapText}
                    />
                    {paywall?.type === 'paywall_linked' && (
                      <Grid.Column
                        textAlign="center"
                        mobile={16}
                        tablet={16}
                        computer={16}
                        style={{ marginTop: 20 }}
                      >
                        <Message info>
                          <Message.Header>Preview Only</Message.Header>
                          <p>
                            This paywall preview is only a sample rendering.
                            Please refer to your app code to see your custom
                            implementation of this paywall.{' '}
                            <a
                              style={{ color: 'black' }}
                              target="_blank"
                              href="https://docs.namiml.com/v1.0/docs/link-a-custom-paywall"
                              rel="noreferrer"
                            >
                              Learn More
                            </a>
                          </p>
                        </Message>
                      </Grid.Column>
                    )}
                  </Grid.Column>
                </Grid>
              </Segment>
              <PaywallSidebar
                visible={paywallSidebar}
                onClose={() => setPaywallSidebar(false)}
                paywall={paywall}
                afterDelete={() => {
                  dispatch(getPaywalls(currentApp.id));
                  dispatch({ type: TYPES.RESET_PAYWALL_STATE });
                  toast.success('Successfully deleted paywall.');
                  history.push('/paywalls/');
                }}
                pushDown={displayUnsavedChanges}
              />
              <PaywallStylesSidebar
                visible={stylesSidebar}
                setVisible={setStylesSidebar}
                pushDown={displayUnsavedChanges}
              />
              <PaywallProductsSidebar
                visible={productsSidebar}
                setVisible={setProductsSidebar}
                platforms={platforms}
                setPlatform={setPlatform}
                platform={platform}
                pushDown={displayUnsavedChanges}
              />
            </>
          )}
        <SaveChangesHeader
          open={displayUnsavedChanges}
          onClick={performPaywallUpdate}
          onDismiss={() => dispatch(toggleDisplayUnsavedChanges())}
        />
        <ConfirmModal
          open={confirm}
          onClose={() => setConfirm(false)}
          action={() => {
            onSubmit();
            setConfirm(false);
          }}
          loading={loading}
          title="Changing Live Paywall"
          body="This paywall is live and any changes will be seen by your app users immediately. Are you sure you want to make these changes?"
          buttonText="Yes. Update Live Paywall."
          buttonClass="nami-primary-button"
        />
        <ConfirmChangesModal
          when={changes}
          confirm={performPaywallUpdate}
          loading={loading}
          title="You have unsaved changes"
          body="Are you sure you want to leave without saving your changes?"
        />
      </div>
    );
  }
  return <Page title={paywall?.name}>{children}</Page>;

  function onSubmit() {
    dispatch(updatePaywall(paywall.id, paywall));
    dispatch(updatePaywallStyles(paywall.style, paywallStyles));
    if (!paywallMenusRoot.id) return;
    dispatch(
      updatePaywallMenus(paywallMenusRoot.id, {
        platform,
        ordered_items: paywallMenus,
      })
    );
  }

  function performPaywallUpdate() {
    if (paywall.live) {
      setConfirm(true);
    } else {
      onSubmit();
    }
    setPaywallSidebar(false);
    setStylesSidebar(false);
    setProductsSidebar(false);
  }
}
