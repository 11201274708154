import React from 'react';

import { PlanMeta, TDisplayProduct } from 'src/api/types/main.types';

import CrossgradeWebPaywall from './CrossgradeWebPaywall';
import { WebPaywallProps } from './UpgradeWebPaywall';

type PaywallsAndSubsWebPaywallProps = WebPaywallProps & {
  plan?: string;
};

const PaywallsAndSubsCrossgradeWebPaywall = ({
  onCancel,
  visible,
  plan = 'starter',
}: PaywallsAndSubsWebPaywallProps) => {
  const productsByPlan: { [key: string]: TDisplayProduct[] } = {
    starter: [
      {
        product_value_props: [
          'No-Code Paywall CMS',
          'Bring your own purchase code',
          '10k impressions included*',
        ],
        ...PlanMeta['paywalls_only_starter_v1'],
      },
      {
        product_value_props: [
          'No-Code Paywall CMS',
          'Easy IAPs & Subscriptions',
          'Entitlement engine',
          '10k impressions included*',
          '$10k MTR included**',
        ],
        ...PlanMeta['paywalls_and_subs_starter_v1'],
      },
    ],
    team: [
      {
        product_value_props: [
          'No-Code Paywall CMS',
          'Bring your own purchase code',
          '15k impressions included*',
        ],
        ...PlanMeta['paywalls_only_team_v1'],
      },
      {
        product_value_props: [
          'No-Code Paywall CMS',
          'Easy IAPs & Subscriptions',
          'Entitlement engine',
          '15k impressions included*',
          '$15k MTR included**',
        ],
        ...PlanMeta['paywalls_and_subs_team_v1'],
      },
    ],
    business: [
      {
        product_value_props: [
          'No-Code Paywall CMS',
          'Bring your own purchase code',
          '25k impressions included*',
        ],
        ...PlanMeta['paywalls_only_business_v1'],
      },
      {
        product_value_props: [
          'No-Code Paywall CMS',
          'Easy IAPs & Subscriptions',
          'Entitlement engine',
          '25k impressions included*',
          '$25k MTR included**',
        ],
        ...PlanMeta['paywalls_and_subs_business_v1'],
      },
    ],
  };

  if (plan === 'enterprise') return <></>;
  return (
    <CrossgradeWebPaywall
      modalTitle="Subscription Management"
      paywallsOnlyProduct={productsByPlan[plan][0]}
      paywallsAndSubsProduct={productsByPlan[plan][1]}
      entitlement="app.entitlement.list"
      onCancel={onCancel}
      visible={visible}
      maskBackground={true}
    />
  );
};

export default PaywallsAndSubsCrossgradeWebPaywall;
