import React from 'react';

import { Form, Input, Button, notification } from 'antd';

import api from '../../../../api';
import SectionTitle from '../../../../components/SectionTitle';
import {
  useAppContext,
  useInputState,
  usePromiseState,
} from '../../../../hooks';

const OrgForm = () => {
  const context = useAppContext();
  const [form] = Form.useForm();
  const [orgName, setOrgName] = useInputState(context.selectedOrg.name);

  const success = () => {
    const payload = { name: orgName };
    return api.updateOrg(context.selectedOrg.id, payload).then(() => {
      notification.success({
        message: 'Success',
        description: 'Updated organization name.',
      });
      context.setSelectedOrgName(orgName);
      window.analytics.track('User Org Updated', payload);
    });
  };

  const { isLoading, trigger: updateOrg } = usePromiseState(success);

  return (
    <>
      <SectionTitle>Organization Details</SectionTitle>
      <Form
        initialValues={{
          name: orgName,
        }}
        form={form}
        layout="vertical"
        onFinish={updateOrg}
      >
        <Form.Item
          name="name"
          label="Organization Name"
          rules={[{ required: true }]}
        >
          <Input
            disabled={!context.userHasEntitlement('org.update')}
            placeholder="Name"
            value={orgName}
            onChange={setOrgName}
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          disabled={!context.userHasEntitlement('org.update')}
          loading={isLoading}
        >
          Update Name
        </Button>
      </Form>
    </>
  );
};

export default OrgForm;
