import React, { useEffect, useMemo, useState } from 'react';

import {
  CloseOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { Button, Col, Modal, Radio, Row, Space } from 'antd';
import {
  TCollapseContainer,
  TComponent,
  TTemplateCapability,
  TTestObject,
} from 'src/api/types/paywallTemplate.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import { useActions } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { namiDarkGray } from 'src/variables';
import { v4 as uuid4 } from 'uuid';

import {
  TAssertionType,
  TConditionEditorType,
} from '../../utils/componentGeneration';
import ConditionRuleEditor from '../editor/inputs/ConditionRuleEditor';

type ConditionsEditorProps = {
  onCancel: () => void;
  onFinish: (
    newAssertions: TTestObject[],
    index: number,
    type: TAssertionType
  ) => void;
  open: boolean;
  templateCapabilities: TTemplateCapability[];
  componentAssertions: TTestObject[];
  assertionIndex: number; //Only used for conditionAttributes
  productChild: boolean;
  childOfCollapseComponent?: TCollapseContainer;
  parentRepeatingGrid: TComponent | null;
  type: TAssertionType;
  variant: TConditionEditorType;
};

export default function ConditionsEditor({
  onCancel,
  onFinish,
  open,
  templateCapabilities,
  componentAssertions,
  assertionIndex,
  productChild,
  childOfCollapseComponent,
  parentRepeatingGrid,
  type,
  variant,
}: ConditionsEditorProps) {
  const actions = useActions(PaywallBuilderSlice.actions);

  const [assertionsMap, setAssertionsMap] = useState<{
    [key: string]: { assertion: TTestObject; error: boolean };
  }>({});
  const [joinType, setJoinType] = useState<TAssertionType>(type);

  useEffect(() => {
    const map = componentAssertions.reduce((output, assertion) => {
      return {
        ...output,
        [uuid4()]: {
          assertion: assertion,
          error: false,
        },
      };
    }, {} as { [key: string]: { assertion: TTestObject; error: boolean } });
    setAssertionsMap(map);
  }, [componentAssertions]);

  const disableSave: boolean = useMemo(() => {
    return Object.values(assertionsMap).some((value) => value.error === true);
  }, [assertionsMap]);

  return (
    <>
      <Modal
        title={
          <Space direction="horizontal" size={3}>
            Edit Conditions
            <IconActionButton
              type="text"
              size="small"
              icon={
                <QuestionCircleOutlined
                  style={{ fontSize: '13px', color: namiDarkGray }}
                />
              }
              href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/conditions"
              target="_blank"
            />
          </Space>
        }
        open={open}
        centered
        onCancel={onCancel}
        zIndex={1002}
        destroyOnClose={true}
        okText="Save"
        onOk={() => {
          saveAssertions();
          onCancel();
        }}
        okButtonProps={{
          disabled: disableSave,
        }}
        width={650}
      >
        {variant !== 'loopSource' && (
          <Row gutter={[0, 5]} style={{ width: '100%', marginBottom: 12 }}>
            <Radio.Group
              defaultValue={joinType}
              onChange={(e) => setJoinType(e.target.value)}
            >
              <Radio.Button value="and">AND</Radio.Button>
              <Radio.Button value="or">OR</Radio.Button>
            </Radio.Group>
          </Row>
        )}
        {Object.keys(assertionsMap).map((assertionId, index) => {
          return (
            <span key={index}>
              <Row gutter={[0, 5]} style={{ width: '100%', marginTop: 8 }}>
                <Col span={22}>
                  <ConditionRuleEditor
                    condition={assertionsMap[assertionId].assertion}
                    templateCapabilities={templateCapabilities}
                    openCapabilitiesTab={openCapabilitiesTab}
                    updateCondition={updateAssertion}
                    id={assertionId}
                    key={assertionId}
                    productChild={productChild}
                    childOfCollapseComponent={childOfCollapseComponent}
                    parentRepeatingGrid={parentRepeatingGrid}
                    onClose={onCancel}
                    variant={variant}
                  />
                </Col>
                {Object.keys(assertionsMap).length > 1 && (
                  <Col span={2}>
                    <Button
                      type="text"
                      onClick={() => removeAssertion(assertionId)}
                    >
                      {<CloseOutlined />}
                    </Button>
                  </Col>
                )}
              </Row>
            </span>
          );
        })}
        <Row gutter={[0, 10]} style={{ width: '100%', marginTop: 10 }}>
          <Button icon={<PlusOutlined />} size="small" onClick={addAssertion}>
            Add Rule
          </Button>
        </Row>
      </Modal>
    </>
  );

  function addAssertion() {
    setAssertionsMap({
      ...assertionsMap,
      [uuid4()]: {
        assertion: {
          value: '',
          operator: 'equals',
          expected: '',
        } as TTestObject,
        error: true,
      },
    });
  }

  function removeAssertion(assertionId: string) {
    const newAssertions = Object.keys(assertionsMap).reduce(
      (output, assertionKey) => {
        if (assertionId === assertionKey) return output;
        return { ...output, [assertionKey]: assertionsMap[assertionKey] };
      },
      {} as { [key: string]: { assertion: TTestObject; error: boolean } }
    );
    setAssertionsMap(newAssertions);
  }

  function updateAssertion(
    newAssertion: TTestObject,
    assertionId: string,
    error?: boolean
  ) {
    if (Object.keys(assertionsMap).length) {
      const newAssertions = Object.keys(assertionsMap).reduce(
        (output, assertionKey) => {
          if (assertionId === assertionKey)
            return {
              ...output,
              [assertionKey]: {
                assertion: newAssertion,
                error: error || false,
              },
            };
          return { ...output, [assertionKey]: assertionsMap[assertionKey] };
        },
        {} as { [key: string]: { assertion: TTestObject; error: boolean } }
      );
      setAssertionsMap(newAssertions);
    } else {
      setAssertionsMap({
        [assertionId]: {
          assertion: newAssertion,
          error: error || false,
        },
      } as { [key: string]: { assertion: TTestObject; error: boolean } });
    }
  }

  function saveAssertions() {
    const assertionsArray: TTestObject[] = Object.values(assertionsMap).reduce(
      (output, assertion) => {
        return [...output, assertion.assertion];
      },
      [] as TTestObject[]
    );
    onFinish(assertionsArray, assertionIndex, joinType);
  }

  function openCapabilitiesTab() {
    actions.setEditingMenuItem('capabilities');
  }
}
