import React from 'react';

import { BinaryOperator } from '@cubejs-client/core';
import { Button, Input, Popover, Radio, Space, Typography } from 'antd';
import styled from 'styled-components';

import { FilterSelectProps } from '.';
import { useBooleanState } from '../../../../hooks';
import { operatorLabels } from './index';

type OperatorSelectProps = FilterSelectProps & {
  label: React.ReactNode;
  operators: BinaryOperator[];
};

const TriggerButton = styled(Button)`
  display: flex;
  color: black !important;
  min-width: 15rem;
  text-align: left;
  padding-left: 11px;
  padding-right: 11px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  &:hover > .ant-select-clear {
    opacity: 1;
  }
`;

const ClearWrapper = styled.span`
  position: absolute !important;
  right: 10px;
`;

const DoneButton = styled(Button).attrs({ type: 'primary', ghost: true })`
  margin-top: 1rem;
  width: 100%;
`;

const VerticalSpace = styled(Space).attrs({ direction: 'vertical' })`
  width: 30rem;

  .ant-popover:has(&) {
    padding-top: 0;
  }
`;

export default function OperatorSelect({
  label,
  operators: operatorsProp,
  onValueChange,
  onOperatorChange,
  onRemove,
  values,
  operator,
  className,
}: OperatorSelectProps) {
  const [isOpen, , close, toggle] = useBooleanState(false);
  const value = values[0] || '';
  const operators = new Set(operatorsProp);

  const radioOptions = Object.entries(operatorLabels)
    .filter(([operatorValue]) => operators.has(operatorValue as BinaryOperator))
    .map(([operatorValue, label]) => (
      <Radio key={operatorValue} value={operatorValue}>
        <span>{label}</span>
        {operatorValue === operator ? (
          <Input value={value} onChange={handleValueChange} />
        ) : null}
      </Radio>
    ));
  const content = (
    <Radio.Group
      onChange={(e) => onOperatorChange(e.target.value)}
      value={operator}
    >
      <VerticalSpace>
        {radioOptions}
        <DoneButton onClick={close}>Done</DoneButton>
      </VerticalSpace>
    </Radio.Group>
  );

  return (
    <Popover
      content={content}
      placement="bottomLeft"
      trigger={[]}
      open={isOpen}
      showArrow={false}
    >
      <TriggerButton
        className={className}
        onClick={toggle} /*danger={!isValid} */
      >
        <Typography.Text>
          <strong>{label}</strong> {operatorLabels[operator]} {value}
        </Typography.Text>
        <ClearButton onClick={onRemove} />
      </TriggerButton>
    </Popover>
  );

  function handleValueChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const value = e.target.value;
    onValueChange(value === '' ? [] : [value]);
  }
}

function ClearButton({ onClick }: { onClick: () => void }) {
  return (
    <ClearWrapper
      className="ant-select-clear"
      unselectable="on"
      style={{ userSelect: 'none' }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <span role="img" className="anticon anticon-close-circle">
        <svg
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="close-circle"
          width="1em"
          height="1em"
          fill="currentColor"
        >
          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
        </svg>
      </span>
    </ClearWrapper>
  );
}
