import { TApiOptions } from '../../api/types/main.types';

export function spreadApiOptions(
  apiOptions: TApiOptions = {}
): (number | undefined | string)[] {
  // This function exists to turn the API options object into a dependable array
  return Object.entries({ page: 1, search: '', ...apiOptions })
    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    .map(([key, value]) => `${key}=${value}`);
}
