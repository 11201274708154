import React from 'react';

import PropTypes from 'prop-types';
import { Icon, List } from 'semantic-ui-react';

import {
  hasLowerCase,
  hasNumber,
  hasUpperCase,
} from '../../services/validatorsService';

const PasswordValidator = ({ password, passwordConfirmation }) => {
  const confirmCheck = typeof passwordConfirmation === 'string';
  const passwordsMatch =
    password !== '' &&
    passwordConfirmation !== '' &&
    password === passwordConfirmation;
  return (
    <List size="small">
      <List.Item>
        <Icon
          name={hasLowerCase(password) ? 'check' : 'warning'}
          color={hasLowerCase(password) ? 'green' : 'grey'}
          disabled={!hasLowerCase(password)}
          size="tiny"
          circular
          inverted
        />{' '}
        Password contains 1 lower case letter
      </List.Item>
      <List.Item>
        <Icon
          name={hasUpperCase(password) ? 'check' : 'warning'}
          color={hasUpperCase(password) ? 'green' : 'grey'}
          disabled={!hasUpperCase(password)}
          size="tiny"
          circular
          inverted
        />{' '}
        Password contains 1 upper case letter
      </List.Item>
      <List.Item>
        <Icon
          name={hasNumber(password) ? 'check' : 'warning'}
          color={hasNumber(password) ? 'green' : 'grey'}
          disabled={!hasNumber(password)}
          size="tiny"
          circular
          inverted
        />{' '}
        Password contains 1 number
      </List.Item>
      <List.Item>
        <Icon
          name={password.length >= 8 ? 'check' : 'warning'}
          color={password.length >= 8 ? 'green' : 'grey'}
          disabled={password.length < 8}
          size="tiny"
          circular
          inverted
        />{' '}
        Password at least 8 characters long
      </List.Item>
      {confirmCheck && (
        <List.Item>
          <Icon
            name={passwordsMatch ? 'check' : 'warning'}
            color={passwordsMatch ? 'green' : 'grey'}
            disabled={!passwordsMatch}
            size="tiny"
            circular
            inverted
          />{' '}
          Passwords must match
        </List.Item>
      )}
    </List>
  );
};

PasswordValidator.propTypes = {
  password: PropTypes.string.isRequired,
  passwordConfirmation: PropTypes.string,
};

export default PasswordValidator;
