import { capitalizePhrase } from '../../../../utils/string';

type TRawTreeItem = Record<
  string,
  { title: string; children?: TRawTreeItem; value?: string }
>;

export type TTreeItem = {
  key: string;
  title: string;
  children?: TTreeItem[];
  value?: string;
};

export function buildTree(events: string[]): TTreeItem[] {
  const output: TRawTreeItem = {};
  events.forEach((event) => {
    let children = output;
    const [lastPiece, ...pieces] = event.split('.').reverse();
    pieces.reverse().forEach((piece) => {
      if (!(piece in children)) {
        children[piece] = { title: toReadable(piece), children: {} };
      }
      children = children[piece].children!;
    });
    children[lastPiece] = { title: toReadable(lastPiece), value: event };
  });
  return convertChildrenToArray(output);
}

function convertChildrenToArray(children: TRawTreeItem): TTreeItem[] {
  return Object.entries(children).reduce(
    (output, [key, { children, ...child }]) => {
      const newChildren = children && convertChildrenToArray(children);
      return [
        ...output,
        { ...child, children: newChildren, key: child.value || key },
      ];
    },
    [] as TTreeItem[]
  );
}

function toReadable(string: string): string {
  return capitalizePhrase(string, '_', ' ');
}
