import React from 'react';

import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, List, Space, Tooltip, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { useAppContext, useBooleanState } from 'src/hooks';
import {
  useDeletePlatformAllowedOriginMutation,
  usePlatformAllowedOriginsQuery,
} from 'src/hooks/queries/platform.hooks';
import { namiMediumGray } from 'src/variables';
import styled from 'styled-components';

import { TIntegrationParams } from '../params.types';
import AddCORSModal from './AddCORSModal';

const StyledUrl = styled(Typography.Text)`
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
  font-size: 13px;
`;

export default function CORSFields() {
  const appContext = useAppContext();
  const { integrationId, type } = useParams<TIntegrationParams>();
  const [isAddModalOpen, openAddModal, closeAddModal] = useBooleanState(false);
  const cors = usePlatformAllowedOriginsQuery(integrationId);
  const deleteCorsMutation = useDeletePlatformAllowedOriginMutation();

  if (!integrationId) return null;
  if (type !== 'web') return null;

  return (
    <Space direction="vertical" style={{ width: '100%', marginBottom: 32 }}>
      <Space direction="horizontal" size={6}>
        <Typography.Text style={{ fontWeight: 500 }}>
          CORS Allowed Origins
        </Typography.Text>
        <Tooltip title="Add your domain URLs to whitelist them with Nami servers. URLs must be exact matches, including http vs https protocol and development port. No wildcards allowed.">
          <InfoCircleOutlined style={{ color: namiMediumGray, fontSize: 13 }} />
        </Tooltip>
      </Space>
      {!cors.data?.length ? (
        <Typography.Text italic>No origins added</Typography.Text>
      ) : (
        <List size="small" bordered={true}>
          {(cors.data || []).map((item) => {
            return (
              <List.Item
                key={item.id}
                actions={[
                  <Button
                    type="link"
                    size="small"
                    style={{ fontSize: 13 }}
                    onClick={() =>
                      deleteCorsMutation.mutate({ originId: item.id })
                    }
                    disabled={
                      !appContext.userHasEntitlement('app.platform.update')
                    }
                  >
                    Delete
                  </Button>,
                ]}
              >
                <StyledUrl>{item.url}</StyledUrl>
              </List.Item>
            );
          })}
        </List>
      )}
      <Button
        size="small"
        icon={<PlusOutlined />}
        onClick={openAddModal}
        disabled={!appContext.userHasEntitlement('app.platform.update')}
      >
        Add Origin URL
      </Button>
      <AddCORSModal isOpen={isAddModalOpen} onClose={closeAddModal} />
    </Space>
  );
}
