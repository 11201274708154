import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet';
import { Segment } from 'semantic-ui-react';

import { useAppContext, usePostRenderEffect } from '../../hooks';
import { useAppSelector } from '../../hooks/redux.hooks';
import SegmentClient from '../../segment';
import { capitalizePhrase } from '../../utils/string';

export type PageProps = {
  title: string;
  layout?: PageLayoutType;
  contentContainerClass?: string;
  children: React.ReactNode;
};

type PageContextType = {
  setTitle: (title: string) => void;
};

type PageLayoutType = 'base' | 'full';

const DEFAULT_TITLE = 'Control Center';

// noinspection JSUnusedLocalSymbols
export const PageContext = React.createContext<PageContextType>({
  setTitle: (title: string) => {},
});

export default function Page({
  title: titleProp,
  layout = 'base',
  contentContainerClass = 'content-container',
  children,
}: PageProps) {
  const app = useAppSelector(({ root }) => root.currentApp);
  const { selectedOrg: org } = useAppContext();
  const [title, setTitle] = useState(titleProp);

  usePostRenderEffect(() => setTitle(titleProp), [titleProp]);
  useEffect(() => {
    if (!(app && org)) return;
    SegmentClient.trackPage(title, {
      app: { id: app.id, name: app.name },
      org: { id: org.id, name: org.name },
    });
  }, [title, app, org]);

  const displayTitle = title
    ? capitalizePhrase(title.replace(/[-_]/g, ' '))
    : DEFAULT_TITLE;

  return (
    <div
      className={layout === 'full' ? 'full-main-container' : 'main-container'}
    >
      <Segment basic className="nami-container">
        <PageContext.Provider value={{ setTitle }}>
          <div className={contentContainerClass}>
            <Helmet defaultTitle={DEFAULT_TITLE}>
              <meta charSet="utf-8" />
              <title>Nami - {displayTitle}</title>
            </Helmet>
            {children}
          </div>
        </PageContext.Provider>
      </Segment>
    </div>
  );
}
