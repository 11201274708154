import { client } from './clients';
import { LanguageType } from './types/main.types';
import { extractResponseData, extractErrorData } from './utils';

const languages = {
  getLanguages: (): Promise<LanguageType[]> => {
    return client
      .get('/api/v1/languages/')
      .then(extractResponseData)
      .catch(extractErrorData);
  },
};
export default languages;
