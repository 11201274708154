import React from 'react';

import CodeSnippet from 'src/components/CodeSnippet/CodeSnippet';

const INDENT_1 = ' '.repeat(2);
const INDENT_2 = INDENT_1.repeat(2);
const INDENT_3 = INDENT_1.repeat(3);

const BUILDERS: [
  string,
  (integrationId: string, integrationType?: string) => string
][] = [
  [
    'Swift',
    (integrationId: string): string => {
      return [
        `import NamiApple`,
        ``,
        `let appPlatformId = "${integrationId}"`,
        ``,
        `let namiConfig = NamiConfiguration(appPlatformId: appPlatformId)`,
        `Nami.configure(with: namiConfig)`,
      ].join('\n');
    },
  ],
  [
    'Kotlin',
    (integrationId: string): string => {
      return [
        `import com.namiml.Nami`,
        `import com.namiml.NamiConfiguration`,
        ``,
        `val appPlatformId = "${integrationId}"`,
        ``,
        `Nami.configure(`,
        `${INDENT_1}NamiConfiguration.build(this, appPlatformId) {}`,
        `)`,
      ].join('\n');
    },
  ],
  [
    'React Native',
    (integrationId: string, integrationType?: string): string => {
      const appleIntegrationId =
        integrationType && integrationType === 'apple'
          ? integrationId
          : 'YOUR_APPLE_APP_PLATFORM_ID';
      const googleIntegrationId =
        integrationType &&
        (integrationType === 'google' || integrationType === 'amazon_iap')
          ? integrationId
          : 'YOUR_GOOGLE_OR_AMAZON_APP_PLATFORM_ID';
      return [
        `import { Nami } from 'react-native-nami-sdk';`,
        ``,
        `const App = () => {`,
        `${INDENT_1}useEffect(() => {`,
        `${INDENT_2}let configDict = {`,
        `${INDENT_3}'appPlatformID-apple': '${appleIntegrationId}',`,
        `${INDENT_3}'appPlatformID-android': '${googleIntegrationId}',`,
        `${INDENT_2}};`,
        ``,
        `${INDENT_2}Nami.configure(configDict);`,
        `${INDENT_1}}, []);`,
        `};`,
      ].join('\n');
    },
  ],
  [
    'Flutter',
    (integrationId: string, integrationType?: string): string => {
      const appleIntegrationId =
        integrationType && integrationType === 'apple'
          ? integrationId
          : 'YOUR_APPLE_APP_PLATFORM_ID';
      const googleIntegrationId =
        integrationType &&
        (integrationType === 'google' || integrationType === 'amazon_iap')
          ? integrationId
          : 'YOUR_GOOGLE_OR_AMAZON_APP_PLATFORM_ID';
      return [
        `import 'package:nami_flutter/nami.dart';`,
        `import 'package:nami_flutter/nami_configuration.dart';`,
        ``,
        `var appleAppPlatformId = "${appleIntegrationId}";`,
        `var androidAppPlatformId = "${googleIntegrationId}";`,
        ``,
        `var namiConfiguration = NamiConfiguration(`,
        `${INDENT_1}appPlatformIdApple: appleAppPlatformId`,
        `${INDENT_1}appPlatformIdGoogle: androidAppPlatformId`,
        `);`,
        ``,
        `Nami.configure(namiConfiguration);`,
      ].join('\n');
    },
  ],
  [
    'Unity',
    (integrationId: string, integrationType?: string): string => {
      const appleIntegrationId =
        integrationType && integrationType === 'apple'
          ? integrationId
          : 'YOUR_APPLE_APP_PLATFORM_ID';
      const googleIntegrationId =
        integrationType &&
        (integrationType === 'google' || integrationType === 'amazon_iap')
          ? integrationId
          : 'YOUR_GOOGLE_OR_AMAZON_APP_PLATFORM_ID';
      return [
        `using NamiSDK;`,
        ``,
        `var appPlatformId = Application.platform == RuntimePlatform.Android ? "${googleIntegrationId}" : "${appleIntegrationId}";`,
        ``,
        `// if appPlatformId is null or omitted it will use the keys from settings`,
        `var configuration = new NamiConfiguration.Builder(appPlatformId).Build();`,
        `Nami.Init(configuration);`,
      ].join('\n');
    },
  ],
];

export default function IntegrationSnippets({
  platformId,
  platformType,
}: {
  platformId: string;
  platformType: string | undefined;
}) {
  return (
    <CodeSnippet
      dynamicValue={platformId}
      platformType={platformType}
      builders={BUILDERS}
    />
  );
}
