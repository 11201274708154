/**
 * @param string {string}
 * @param converter {function (string|number): string|number}
 * @returns {string}
 */
export function adjustErrorUnit(string, converter) {
  return string.replace(
    /(\d+(\.\d+)?)\.$/g,
    (_, number) => `${converter(number)}.`
  );
}

/**
 * @param value {string|number}
 * @returns {number}
 */
export function toPositiveInteger(value) {
  return Math.floor(+value.toString().replace(/\D/g, ''));
}
