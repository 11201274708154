import React from 'react';

import styles from './NamiAgreementLinks.module.less';

const NamiAgreementLinks = ({ name }) => {
  const link = (text, link) => {
    return (
      <a target="_blank" rel="noopener noreferrer" href={link}>
        {text}{' '}
      </a>
    );
  };
  return (
    <div className={styles.links}>
      By clicking “{name}” you confirm that you have read and agree to Nami ML’s{' '}
      {link('Terms of Service', 'https://www.namiml.com/legal/terms')} and{' '}
      {link('Privacy Policy', 'https://www.namiml.com/legal/privacy')}.{' '}
    </div>
  );
};
export default NamiAgreementLinks;
