import React from 'react';

import { Form, Input, Modal } from 'antd';

import { useAddEntitlementMutation } from '../../../hooks/queries/entitlement.hooks';
import { useAppSelector } from '../../../hooks/redux.hooks';

type NewEntitlementModalProps = { isOpen: boolean; onClose: () => void };

export default function NewEntitlementModal({
  isOpen,
  onClose,
}: NewEntitlementModalProps) {
  const [form] = Form.useForm();
  const appId = useAppSelector(({ root }) => root.currentApp.id);
  const newEntitlementMutation = useAddEntitlementMutation(form, onClose);

  return (
    <Modal
      title="Add a New Entitlement"
      open={isOpen}
      onCancel={onClose}
      closable={false}
      confirmLoading={newEntitlementMutation.isLoading}
      onOk={() => newEntitlementMutation.mutate()}
      okText="Add"
    >
      <Form layout="vertical" form={form} initialValues={{ app: appId }}>
        <Form.Item name="app" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          className="intercom-entitlementName"
          label="Name"
          name="name"
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="intercom-entitlementRefId"
          label="Reference ID"
          name="entitlement_ref_id"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
