import React, { Fragment, useState } from 'react';

import { connect } from 'react-redux';
import { Button, Divider, Form, Icon, Segment } from 'semantic-ui-react';

import { useAppContext } from '../../../../hooks';
import {
  handlePaywallColor,
  handlePaywallField,
  handlePaywallSize,
} from '../../../../redux/actions/paywallActions';
import { toggleNewKeyValueModal } from '../../../../redux/actions/uiActions';
import ConfirmModal from '../../../ConfirmModal/ConfirmModal';
import EmptyStateSegment from '../../../EmptyStateSegment/EmptyStateSegment';
import MobileTable from '../../../MobileTable/MobileTable';
import Responsive from '../../../Responsive/Responsive';
import SectionTitle from '../../../SectionTitle';

const Content = ({
  paywall,
  loading,
  toggleNewKeyValueModal,
  handlePaywallField,
}) => {
  const context = useAppContext();
  const [key, setKey] = useState('');
  const [toggle, setToggle] = useState(false);

  const updateField = (key, value) => {
    handlePaywallField({
      ...paywall,
      marketing_content: { ...paywall.marketing_content, [key]: value },
    });
  };

  const openModal = (contentKey) => {
    setKey(contentKey);
    setToggle(true);
  };

  const submit = () => {
    handlePaywallField({
      ...paywall,
      marketing_content: {
        ...paywall.marketing_content,
        extra_data: {
          ...delete paywall.marketing_content.extra_data[key],
          ...paywall.marketing_content.extra_data,
        },
      },
    });

    setToggle(false);
  };

  const renderConfirmDeleteModal = () => {
    return (
      <ConfirmModal
        open={toggle}
        onClose={() => setToggle(false)}
        action={submit}
        loading={loading}
        title="Delete Key Value Confirmation"
        body="Are you sure you want to delete this key value?"
        buttonText="Yes. Delete This Key Value."
      />
    );
  };

  const convertObjectToArray = (object) => {
    if (object) {
      return Object.keys(object).map((key) => ({
        key: key,
        value: object[key],
      }));
    } else {
      return [];
    }
  };

  const renderTable = (marketingContentExtras) => {
    return (
      <Fragment>
        <Responsive size="mdUp">
          <Segment clearing basic>
            <Button
              id="modal-button"
              className="nami-primary-button"
              floated="right"
              disabled={!context.userHasEntitlement('app.paywall.update')}
              onClick={toggleNewKeyValueModal}
            >
              <Icon name="plus" /> Add Key-Value
            </Button>
          </Segment>
        </Responsive>
        <Responsive size="mdDown">
          <Segment clearing basic>
            <Button
              id="modal-button"
              className="nami-primary-button"
              floated="right"
              disabled={!context.userHasEntitlement('app.paywall.update')}
              onClick={toggleNewKeyValueModal}
            >
              <Icon name="plus" /> Add
            </Button>
          </Segment>
        </Responsive>
        <Divider />
        {marketingContentExtras.length && (
          <MobileTable
            icon="close"
            collection={marketingContentExtras.map((content) => ({
              name: content.key,
              metaLabel: JSON.stringify(content.value),
              graphicContent: () => {},
              onClick: () => {
                if (context.userHasEntitlement('app.paywall.update')) {
                  openModal(content.key);
                }
              },
              imageColWidth: 0,
            }))}
          />
        )}
      </Fragment>
    );
  };

  const renderPaywallTextForm = () => {
    return (
      <>
        <Form.Input
          fluid
          disabled={!context.userHasEntitlement('app.paywall.update')}
          label="Title"
          onChange={(e) => updateField('title', e.target.value)}
          value={paywall.marketing_content.title || ''}
        />

        <Form.Input
          fluid
          disabled={!context.userHasEntitlement('app.paywall.update')}
          label="Body"
          onChange={(e) => updateField('body', e.target.value)}
          value={paywall.marketing_content.body || ''}
        />
      </>
    );
  };
  const marketingContentExtras = convertObjectToArray(
    paywall.marketing_content.extra_data
  );

  return (
    <Segment basic>
      {renderPaywallTextForm()}
      {paywall.type === 'paywall_linked' && (
        <Fragment>
          <SectionTitle>Additonal Metadata</SectionTitle>
          <p>
            Add any additional metadata here as key-value pairs that you would
            like to control from the cloud on your linked paywall. Keys and
            values must be JSON serializable.
          </p>

          {marketingContentExtras && marketingContentExtras.length ? (
            <>
              {renderTable(marketingContentExtras)}
              {renderConfirmDeleteModal()}
            </>
          ) : (
            <EmptyStateSegment
              title="Add your first key-value pair."
              subtitle="Learn more about key-values for linked paywalls."
              outBoundLink={
                'https://learn.namiml.com/public-docs/no-code-paywalls/legacy-paywalls'
              }
              icon="key"
              border={false}
            >
              <Button
                className="nami-primary-button"
                disabled={!context.userHasEntitlement('app.paywall.update')}
                onClick={toggleNewKeyValueModal}
              >
                <Icon name="plus" /> Add Key-Value
              </Button>
            </EmptyStateSegment>
          )}
        </Fragment>
      )}
    </Segment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlePaywallField: (data) => dispatch(handlePaywallField(data)),
    toggleNewKeyValueModal: () => dispatch(toggleNewKeyValueModal()),
    handlePaywallColor: (key, color) =>
      dispatch(handlePaywallColor(key, color)),
    handlePaywallSize: (key, value) => dispatch(handlePaywallSize(key, value)),
  };
};

const mapStateToProps = ({ paywall }) => {
  return {
    loading: paywall.loading,
    paywall: paywall.paywall,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
