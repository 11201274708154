import React from 'react';

import { UserOutlined } from '@ant-design/icons';
import { createSelector } from '@reduxjs/toolkit';
import { Button, Form, Modal, Space, Tooltip } from 'antd';
import { TextArea } from 'semantic-ui-react';
import FormItem from 'src/components/FormItem';
import { useBooleanState } from 'src/hooks';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

const selector = createSelector(
  [({ paywallBuilder: { customer } }: RootState) => customer],
  (customer) => ({
    customerAttributes: customer,
    customerAttributesSet: customer && Object.keys(customer).length > 0,
  })
);

export default function PaywallPersonalizationTokenSetter() {
  const actions = useActions(PaywallBuilderSlice.actions);
  const [form] = Form.useForm<object>();

  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const { customerAttributes, customerAttributesSet } =
    useAppSelector(selector);

  return (
    <>
      <Tooltip
        title="Preview paywall with personalized customer attributes"
        mouseEnterDelay={0.8}
      >
        <Button
          type={customerAttributesSet ? 'primary' : 'ghost'}
          icon={<UserOutlined />}
          onClick={openModal}
          size="small"
        />
      </Tooltip>
      <Modal
        title="Preview Personalized Customer Attributes"
        open={isModalOpen}
        centered
        zIndex={1001}
        footer={null}
        onCancel={closeModal}
      >
        <Form
          form={form}
          initialValues={{
            'custom-json': JSON.stringify(customerAttributes),
          }}
          onFinish={updateCustomerAttributes}
        >
          <FormItem
            rules={[
              {
                required: true,
                message: 'Provide a JSON string',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  try {
                    JSON.parse(value);
                    return Promise.resolve();
                  } catch (e) {
                    return Promise.reject(new Error('Invalid JSON'));
                  }
                },
              }),
            ]}
            name="customer-json"
          >
            <TextArea
              rows={8}
              placeholder='{"firstName": "Taylor}'
              style={{ fontFamily: 'monospace', width: '100%' }}
            />
          </FormItem>
          <Space direction="horizontal">
            <Button
              type="primary"
              htmlType="submit"
              disabled={form
                .getFieldsError()
                .some(({ errors }) => errors.length)}
            >
              Apply
            </Button>
            <Button onClick={resetCustomerAttributes}>Clear</Button>
          </Space>
        </Form>
      </Modal>
    </>
  );

  function updateCustomerAttributes() {
    actions.setCustomerAttributes(
      JSON.parse(form.getFieldValue('customer-json'))
    );
    closeModal();
  }

  function resetCustomerAttributes() {
    form.setFieldValue('customer-json', '{}');
    actions.resetCustomerAttributes();
    closeModal();
  }
}
