import React, { useMemo } from 'react';

import { LeftOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { UserRoleType, UserType } from 'src/api/types/main.types';
import Loading from 'src/components/Loading/Loading';
import SectionTitle from 'src/components/SectionTitle';
import { useAppContext, useQueryParams } from 'src/hooks';
import {
  useDeleteOrgMemberMutation,
  useOrgMemberQuery,
  useOrgRoleQuery,
  useUpdateOrgMemberRoleMutation,
} from 'src/hooks/queries/org.hooks';

export default function TeamDetailPage() {
  const context = useAppContext();
  const currentUser = useQueryParams().get('user_id');
  const user = useOrgMemberQuery(currentUser!);
  const [form] = Form.useForm<UserType>();
  const roles = useOrgRoleQuery();
  const updateRoleMutation = useUpdateOrgMemberRoleMutation();
  const deleteOrgMemberMutation = useDeleteOrgMemberMutation(currentUser!);

  const filterRole: UserRoleType | undefined = useMemo(() => {
    if (user.data && user.data.permissions.length) {
      return user.data.permissions.filter(
        (permission) => permission.id === context.selectedOrg?.id
      )[0].roles[0];
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!currentUser) {
    return <></>;
  }
  if (user.isLoading || user.isFetching || roles.isFetching) {
    return <Loading />;
  }

  return (
    <>
      <Row justify="start">
        <Col xs={24} style={{ paddingBottom: 12 }}>
          <Button
            type="link"
            icon={<LeftOutlined />}
            href="/settings?tab=team"
            style={{ paddingLeft: 0 }}
          >
            Back to Team List
          </Button>
        </Col>
      </Row>
      <SectionTitle>Edit User</SectionTitle>
      <Form
        form={form}
        layout="vertical"
        initialValues={user.data}
        onFinish={() => {
          form.validateFields();
          updateRoleMutation.mutate({
            userId: currentUser,
            roles: [form.getFieldValue('roles')],
          });
        }}
        requiredMark={false}
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              name="first_name"
              label={<Typography.Text>First Name</Typography.Text>}
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="last_name"
              label={<Typography.Text>Last Name</Typography.Text>}
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24}>
            <Form.Item
              name="username"
              label={<Typography.Text>Email Address</Typography.Text>}
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <Form.Item
              label={<Typography.Text>Role</Typography.Text>}
              initialValue={filterRole?.id}
              name="roles"
              required
            >
              <Select
                loading={roles.isFetching}
                style={{ width: '100%' }}
                defaultValue={filterRole?.id}
              >
                {(roles.data || []).map((role) => (
                  <Select.Option key={role.id} value={role.id}>
                    {role.display_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Button
          htmlType="submit"
          disabled={!context.userHasEntitlement('org.members.update')}
          type="primary"
        >
          Update Team Member
        </Button>
        <Collapse ghost style={{ marginBottom: 24, marginTop: 32 }}>
          <Collapse.Panel
            header={<h4>Danger Zone</h4>}
            key="1"
            className="intercom-orgMemberDelete"
          >
            <Space direction="vertical">
              <Typography.Text strong>
                Removing this user from the organization will revoke their
                access to all associated apps.
              </Typography.Text>
              <Typography.Text>
                The user will still have access to the Nami platform and can be
                added to the Organization again in the future.
              </Typography.Text>
              <Space direction="horizontal" size="small">
                <Button
                  type="primary"
                  danger
                  loading={deleteOrgMemberMutation.isLoading}
                  onClick={() => deleteOrgMemberMutation.mutate()}
                  disabled={!context.userHasEntitlement('org.members.remove')}
                >
                  Remove From Organization
                </Button>
              </Space>
            </Space>
          </Collapse.Panel>
        </Collapse>
      </Form>
    </>
  );
}
