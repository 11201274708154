import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  SendOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

import { namiBrightGreen, namiDarkGray } from '../../../../variables';

export const PausedIcon = styled(PauseCircleOutlined)`
  color: ${namiDarkGray};
`;

export const LiveIcon = styled(PlayCircleOutlined)`
  color: ${namiBrightGreen};
`;

export const SendIcon = styled(SendOutlined)`
  color: ${namiBrightGreen};
`;
