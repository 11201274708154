import React, { useContext, useMemo } from 'react';

import { AppContext } from '../../../../../AppContextProvider';
import FilterSelect, { BaseFilterSelectProps } from './FilterSelect';
import { identifierLabel } from './mapsAndOptions';

export default function DeviceLanguageSelect(props: BaseFilterSelectProps) {
  const { languages } = useContext(AppContext);

  const options = useMemo(
    () =>
      Object.entries(languages).map(([code, name]) => {
        const label = `${name} - (${code.toUpperCase()})`;
        return { label, text: label, value: code, key: code };
      }),
    [languages]
  );

  return (
    <FilterSelect
      {...props}
      label={identifierLabel['Device.os_language']}
      options={options}
      operators={['i_contains', 'not_i_contains']}
      variant="select"
    />
  );
}
