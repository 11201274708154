export const STOP_LOADING = 'STOP_LOADING';

export const CLEAR_ROOT = 'CLEAR_ROOT';
export const GET_APPS = 'GET_APPS';
export const GET_APPS_LOAD = 'GET_APPS_LOAD';
export const GET_APPS_SUCCESS = 'GET_APPS_SUCCESS';
export const GET_APPS_FAIL = 'GET_APPS_FAIL';

export const UPDATE_CURRENT_APP = 'UPDATE_CURRENT_APP';
export const UPDATE_CURRENT_APP_LOAD = 'UPDATE_CURRENT_APP_LOAD';
export const UPDATE_CURRENT_APP_SUCCESS = 'UPDATE_CURRENT_APP_SUCCESS';
export const UPDATE_CURRENT_APP_FAIL = 'UPDATE_CURRENT_APP_FAIL';

export const GET_LANGUAGES = 'GET_LANGUAGES';
export const GET_LANGUAGES_LOAD = 'GET_LANGUAGES_LOAD';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export const GET_LANGUAGES_FAIL = 'GET_LANGUAGES_FAIL';

export const UPDATE_CURRENT_ORG = 'UPDATE_CURRENT_ORG';
export const UPDATE_CURRENT_ORG_LOAD = 'UPDATE_CURRENT_ORG_LOAD';
export const UPDATE_CURRENT_ORG_SUCCESS = 'UPDATE_CURRENT_ORG_SUCCESS';
export const UPDATE_CURRENT_ORG_FAIL = 'UPDATE_CURRENT_ORG_FAIL';

export const HANDLE_CURRENT_APP_FIELD = 'HANDLE_CURRENT_APP_FIELD';

export const CURRENT_APP_UPLOAD_FINISH = 'CURRENT_APP_UPLOAD_FINISH';

// app
export const GET_APP = 'GET_APP';
export const GET_APP_LOAD = 'GET_APP_LOAD';
export const GET_APP_SUCCESS = 'GET_APP_SUCCESS';
export const GET_APP_FAIL = 'GET_APP_FAIL';

export const ADD_APP = 'ADD_APP';
export const ADD_APP_LOAD = 'ADD_APP_LOAD';
export const ADD_APP_SUCCESS = 'ADD_APP_SUCCESS';
export const ADD_APP_FAIL = 'ADD_APP_FAIL';

export const UPDATE_APP = 'UPDATE_APP';
export const UPDATE_APP_LOAD = 'UPDATE_APP_LOAD';
export const UPDATE_APP_SUCCESS = 'UPDATE_APP_SUCCESS';
export const UPDATE_APP_FAIL = 'UPDATE_APP_FAIL';

export const DESTROY_APP = 'DESTROY_APP';
export const DESTROY_APP_LOAD = 'DESTROY_APP_LOAD';
export const DESTROY_APP_SUCCESS = 'DESTROY_APP_SUCCESS';
export const DESTROY_APP_FAIL = 'DESTROY_APP_FAIL';

export const GET_APP_STATUS = 'GET_APP_STATUS';
export const GET_APP_STATUS_LOAD = 'GET_APP_STATUS_LOAD';
export const GET_APP_STATUS_SUCCESS = 'GET_APP_STATUS_SUCCESS';
export const GET_APP_STATUS_FAIL = 'GET_APP_STATUS_FAIL';

export const HANDLE_NEW_APP_FIELD = 'HANDLE_NEW_APP_FIELD';
export const HANDLE_APP_DETAIL_FIELD = 'HANDLE_APP_DETAIL_FIELD';

export const RESET_NEW_APP = 'RESET_NEW_APP';
export const RESET_NEW_APP_ERRORS = 'RESET_NEW_APP_ERRORS';
export const RESET_UPDATE_APP_ERRORS = 'RESET_UPDATE_APP_ERRORS';

export const FINISH_LOGO_UPLOAD = 'FINISH_LOGO_UPLOAD';

// citations
export const GET_CITATIONS = 'GET_CITATIONS';
export const GET_CITATIONS_LOAD = 'GET_CITATIONS_LOAD';
export const GET_CITATIONS_SUCCESS = 'GET_CITATIONS_SUCCESS';
export const GET_CITATIONS_FAIL = 'GET_CITATIONS_FAIL';

export const GET_CITATION = 'GET_CITATION';
export const GET_CITATION_LOAD = 'GET_CITATION_LOAD';
export const GET_CITATION_SUCCESS = 'GET_CITATION_SUCCESS';
export const GET_CITATION_FAIL = 'GET_CITATION_FAIL';

export const ADD_CITATION = 'ADD_CITATION';
export const ADD_CITATION_LOAD = 'ADD_CITATION_LOAD';
export const ADD_CITATION_SUCCESS = 'ADD_CITATION_SUCCESS';
export const ADD_CITATION_FAIL = 'ADD_CITATION_FAIL';

export const UPDATE_CITATION = 'UPDATE_CITATION';
export const UPDATE_CITATION_LOAD = 'UPDATE_CITATION_LOAD';
export const UPDATE_CITATION_SUCCESS = 'UPDATE_CITATION_SUCCESS';
export const UPDATE_CITATION_FAIL = 'UPDATE_CITATION_FAIL';

export const DELETE_CITATION = 'DELETE_CITATION';
export const DELETE_CITATION_LOAD = 'DELETE_CITATION_LOAD';
export const DELETE_CITATION_SUCCESS = 'DELETE_CITATION_SUCCESS';
export const DELETE_CITATION_FAIL = 'DELETE_CITATION_FAIL';

export const HANDLE_CITATIONS_FIELD = 'HANDLE_CITATIONS_FIELD';
export const HANDLE_CITATION_FIELD = 'HANDLE_CITATION_FIELD';

// locales
export const GET_LOCALES = 'GET_LOCALES';
export const GET_LOCALES_LOAD = 'GET_LOCALES_LOAD';
export const GET_LOCALES_SUCCESS = 'GET_LOCALES_SUCCESS';
export const GET_LOCALES_FAIL = 'GET_LOCALES_FAIL';

export const ADD_LOCALE = 'ADD_LOCALE';
export const ADD_LOCALE_LOAD = 'ADD_LOCALE_LOAD';
export const ADD_LOCALE_SUCCESS = 'ADD_LOCALE_SUCCESS';
export const ADD_LOCALE_FAIL = 'ADD_LOCALE_FAIL';

export const UPDATE_LOCALE = 'UPDATE_LOCALE';
export const UPDATE_LOCALE_LOAD = 'UPDATE_LOCALE_LOAD';
export const UPDATE_LOCALE_SUCCESS = 'UPDATE_LOCALE_SUCCESS';
export const UPDATE_LOCALE_FAIL = 'UPDATE_LOCALE_FAIL';

export const DELETE_LOCALE = 'DELETE_LOCALE';
export const DELETE_LOCALE_LOAD = 'DELETE_LOCALE_LOAD';
export const DELETE_LOCALE_SUCCESS = 'DELETE_LOCALE_SUCCESS';
export const DELETE_LOCALE_FAIL = 'DELETE_LOCALE_FAIL';

export const HANDLE_LOCALES_FIELD = 'HANDLE_LOCALES_FIELD';
export const HANDLE_LOCALE_FIELD = 'HANDLE_LOCALE_FIELD';
export const HANDLE_LOCALE_CLEAR_ERROR = 'HANDLE_LOCALE_CLEAR_ERROR';

export const GET_USER_AUTHENTICATORS = 'GET_USER_AUTHENTICATORS';
export const GET_USER_AUTHENTICATORS_LOAD = 'GET_USER_AUTHENTICATORS_LOAD';
export const GET_USER_AUTHENTICATORS_SUCCESS =
  'GET_USER_AUTHENTICATORS_SUCCESS';
export const GET_USER_AUTHENTICATORS_FAIL = 'GET_USER_AUTHENTICATORS_FAIL';

export const CREATE_USER_AUTHENTICATORS = 'CREATE_USER_AUTHENTICATORS';
export const CREATE_USER_AUTHENTICATORS_LOAD =
  'CREATE_USER_AUTHENTICATORS_LOAD';
export const CREATE_USER_AUTHENTICATORS_SUCCESS =
  'CREATE_USER_AUTHENTICATORS_SUCCESS';
export const CREATE_USER_AUTHENTICATORS_FAIL =
  'CREATE_USER_AUTHENTICATORS_FAIL';

export const ACTIVATE_USER_AUTHENTICATOR = 'ACTIVATE_USER_AUTHENTICATOR';
export const ACTIVATE_USER_AUTHENTICATOR_LOAD =
  'ACTIVATE_USER_AUTHENTICATOR_LOAD';
export const ACTIVATE_USER_AUTHENTICATOR_SUCCESS =
  'ACTIVATE_USER_AUTHENTICATOR_SUCCESS';
export const ACTIVATE_USER_AUTHENTICATOR_FAIL =
  'ACTIVATE_USER_AUTHENTICATOR_FAIL';

export const DISABLED_USER_AUTHENTICATOR = 'DISABLED_USER_AUTHENTICATOR';
export const DISABLED_USER_AUTHENTICATOR_LOAD =
  'DISABLED_USER_AUTHENTICATOR_LOAD';
export const DISABLED_USER_AUTHENTICATOR_SUCCESS =
  'DISABLED_USER_AUTHENTICATOR_SUCCESS';
export const DISABLED_USER_AUTHENTICATOR_FAIL =
  'DISABLED_USER_AUTHENTICATOR_FAIL';

export const HANDLE_PASSWORD_FIELD = 'HANDLE_PASSWORD_FIELD';

//team
export const GET_TEAM = 'GET_TEAM';
export const GET_TEAM_LOAD = 'GET_TEAM_LOAD';
export const GET_TEAM_SUCCESS = 'GET_TEAM_SUCCESS';
export const GET_TEAM_FAIL = 'GET_TEAM_FAIL';

export const GET_TEAM_MEMBER = 'GET_TEAM_MEMBER';
export const GET_TEAM_MEMBER_LOAD = 'GET_TEAM_MEMBER_LOAD';
export const GET_TEAM_MEMBER_SUCCESS = 'GET_TEAM_MEMBER_SUCCESS';
export const GET_TEAM_MEMBER_FAIL = 'GET_TEAM_MEMBER_FAIL';

export const UPDATE_TEAM_MEMBER_ROLE = 'UPDATE_TEAM_MEMBER_ROLE';
export const UPDATE_TEAM_MEMBER_ROLE_LOAD = 'UPDATE_TEAM_MEMBER_ROLE_LOAD';
export const UPDATE_TEAM_MEMBER_ROLE_SUCCESS =
  'UPDATE_TEAM_MEMBER_ROLE_SUCCESS';
export const UPDATE_TEAM_MEMBER_ROLE_FAIL = 'UPDATE_TEAM_MEMBER_ROLE_FAIL';

export const REMOVE_TEAM_MEMBER = 'REMOVE_TEAM_MEMBER';
export const REMOVE_TEAM_MEMBER_LOAD = 'REMOVE_TEAM_MEMBER_LOAD';
export const REMOVE_TEAM_MEMBER_SUCCESS = 'REMOVE_TEAM_MEMBER_SUCCESS';
export const REMOVE_TEAM_MEMBER_FAIL = 'REMOVE_TEAM_MEMBER_FAIL';

export const GET_TEAM_ROLES = 'GET_TEAM_ROLES';
export const GET_TEAM_ROLES_LOAD = 'GET_TEAM_ROLES_LOAD';
export const GET_TEAM_ROLES_SUCCESS = 'GET_TEAM_ROLES_SUCCESS';
export const GET_TEAM_ROLES_FAIL = 'GET_TEAM_ROLES_FAIL';

export const HANDLE_CURRENT_MEMBER_FIELD = 'HANDLE_CURRENT_MEMBER_FIELD';

// ui
export const TOGGLE_NEW_APP_MODAL = 'TOGGLE_NEW_APP_MODAL';
export const TOGGLE_NEW_PRODUCT_MODAL = 'TOGGLE_NEW_PRODUCT_MODAL';
export const TOGGLE_NEW_ENTITLEMENT_MODAL = 'TOGGLE_NEW_ENTITLEMENT_MODAL';
export const TOGGLE_NEW_PRODUCT_ASSOCIATE_MODAL =
  'TOGGLE_NEW_PRODUCT_ASSOCIATE_MODAL';
export const TOGGLE_NEW_KEY_VALUE_MODAL = 'TOGGLE_NEW_KEY_VALUE_MODAL';
export const TOGGLE_NAVIGATION = 'TOGGLE_NAVIGATION';
export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const TOGGLE_ALL = 'TOGGLE_ALL';

// developer
export const GET_DEVELOPER_ENTITLEMENTS = 'GET_DEVELOPER_ENTITLEMENTS';
export const GET_DEVELOPER_ENTITLEMENTS_LOAD =
  'GET_DEVELOPER_ENTITLEMENTS_LOAD';
export const GET_DEVELOPER_ENTITLEMENTS_SUCCESS =
  'GET_DEVELOPER_ENTITLEMENTS_SUCCESS';
export const GET_DEVELOPER_ENTITLEMENTS_FAIL =
  'GET_DEVELOPER_ENTITLEMENTS_FAIL';

export const GET_DEVELOPER_TRANSACTIONS = 'GET_DEVELOPER_TRANSACTIONS';
export const GET_DEVELOPER_TRANSACTIONS_LOAD =
  'GET_DEVELOPER_TRANSACTIONS_LOAD';
export const GET_DEVELOPER_TRANSACTIONS_SUCCESS =
  'GET_DEVELOPER_TRANSACTIONS_SUCCESS';
export const GET_DEVELOPER_TRANSACTIONS_FAIL =
  'GET_DEVELOPER_TRANSACTIONS_FAIL';

export const GET_DEVELOPER_IMPRESSIONS = 'GET_DEVELOPER_IMPRESSIONS';
export const GET_DEVELOPER_IMPRESSIONS_LOAD = 'GET_DEVELOPER_IMPRESSIONS_LOAD';
export const GET_DEVELOPER_IMPRESSIONS_SUCCESS =
  'GET_DEVELOPER_IMPRESSIONS_SUCCESS';
export const GET_DEVELOPER_IMPRESSIONS_FAIL = 'GET_DEVELOPER_IMPRESSIONS_FAIL';

export const GET_DEVELOPER_SESSIONS = 'GET_DEVELOPER_SESSIONS';
export const GET_DEVELOPER_SESSIONS_LOAD = 'GET_DEVELOPER_SESSIONS_LOAD';
export const GET_DEVELOPER_SESSIONS_SUCCESS = 'GET_DEVELOPER_SESSIONS_SUCCESS';
export const GET_DEVELOPER_SESSIONS_FAIL = 'GET_DEVELOPER_SESSIONS_FAIL';

export const GET_DEVELOPER_APPLE_NOTIFICATIONS =
  'GET_DEVELOPER_APPLE_NOTIFICATIONS';
export const GET_DEVELOPER_APPLE_NOTIFICATIONS_LOAD =
  'GET_DEVELOPER_APPLE_NOTIFICATIONS_LOAD';
export const GET_DEVELOPER_APPLE_NOTIFICATIONS_SUCCESS =
  'GET_DEVELOPER_APPLE_NOTIFICATIONS_SUCCESS';
export const GET_DEVELOPER_APPLE_NOTIFICATIONS_FAIL =
  'GET_DEVELOPER_APPLE_NOTIFICATIONS_FAIL';

export const GET_DEVELOPER_ANDROID_NOTIFICATIONS =
  'GET_DEVELOPER_ANDROID_NOTIFICATIONS';
export const GET_DEVELOPER_ANDROID_NOTIFICATIONS_LOAD =
  'GET_DEVELOPER_ANDROID_NOTIFICATIONS_LOAD';
export const GET_DEVELOPER_ANDROID_NOTIFICATIONS_SUCCESS =
  'GET_DEVELOPER_ANDROID_NOTIFICATIONS_SUCCESS';
export const GET_DEVELOPER_ANDROID_NOTIFICATIONS_FAIL =
  'GET_DEVELOPER_ANDROID_NOTIFICATIONS_FAIL';

export const GET_DEVELOPER_ENTITLEMENTS_REAL_TIME =
  'GET_DEVELOPER_ENTITLEMENTS_REAL_TIME';
export const GET_DEVELOPER_ENTITLEMENTS_REAL_TIME_LOAD =
  'GET_DEVELOPER_ENTITLEMENTS_REAL_TIME_LOAD';
export const GET_DEVELOPER_ENTITLEMENTS_REAL_TIME_SUCCESS =
  'GET_DEVELOPER_ENTITLEMENTS_REAL_TIME_SUCCESS';
export const GET_DEVELOPER_ENTITLEMENTS_REAL_TIME_FAIL =
  'GET_DEVELOPER_ENTITLEMENTS_REAL_TIME_FAIL';

export const GET_DEVELOPER_TRANSACTIONS_REAL_TIME =
  'GET_DEVELOPER_TRANSACTIONS_REAL_TIME';
export const GET_DEVELOPER_TRANSACTIONS_REAL_TIME_LOAD =
  'GET_DEVELOPER_TRANSACTIONS_REAL_TIME_LOAD';
export const GET_DEVELOPER_TRANSACTIONS_REAL_TIME_SUCCESS =
  'GET_DEVELOPER_TRANSACTIONS_REAL_TIME_SUCCESS';
export const GET_DEVELOPER_TRANSACTIONS_REAL_TIME_FAIL =
  'GET_DEVELOPER_TRANSACTIONS_REAL_TIME_FAIL';

export const GET_DEVELOPER_IMPRESSIONS_REAL_TIME =
  'GET_DEVELOPER_IMPRESSIONS_REAL_TIME';
export const GET_DEVELOPER_IMPRESSIONS_REAL_TIME_LOAD =
  'GET_DEVELOPER_IMPRESSIONS_REAL_TIME_LOAD';
export const GET_DEVELOPER_IMPRESSIONS_REAL_TIME_SUCCESS =
  'GET_DEVELOPER_IMPRESSIONS_REAL_TIME_SUCCESS';
export const GET_DEVELOPER_IMPRESSIONS_REAL_TIME_FAIL =
  'GET_DEVELOPER_IMPRESSIONS_REAL_TIME_FAIL';

export const GET_DEVELOPER_SESSIONS_REAL_TIME =
  'GET_DEVELOPER_SESSIONS_REAL_TIME';
export const GET_DEVELOPER_SESSIONS_REAL_TIME_LOAD =
  'GET_DEVELOPER_SESSIONS_REAL_TIME_LOAD';
export const GET_DEVELOPER_SESSIONS_REAL_TIME_SUCCESS =
  'GET_DEVELOPER_SESSIONS_REAL_TIME_SUCCESS';
export const GET_DEVELOPER_SESSIONS_REAL_TIME_FAIL =
  'GET_DEVELOPER_SESSIONS_REAL_TIME_FAIL';

export const GET_DEVELOPER_APPLE_NOTIFICATIONS_REAL_TIME =
  'GET_DEVELOPER_APPLE_NOTIFICATIONS_REAL_TIME';
export const GET_DEVELOPER_APPLE_NOTIFICATIONS_REAL_TIME_LOAD =
  'GET_DEVELOPER_APPLE_NOTIFICATIONS_REAL_TIME_LOAD';
export const GET_DEVELOPER_APPLE_NOTIFICATIONS_REAL_TIME_SUCCESS =
  'GET_DEVELOPER_APPLE_NOTIFICATIONS_REAL_TIME_SUCCESS';
export const GET_DEVELOPER_APPLE_NOTIFICATIONS_REAL_TIME_FAIL =
  'GET_DEVELOPER_APPLE_NOTIFICATIONS_REAL_TIME_FAIL';

export const GET_DEVELOPER_ANDROID_NOTIFICATIONS_REAL_TIME =
  'GET_DEVELOPER_ANDROID_NOTIFICATIONS_REAL_TIME';
export const GET_DEVELOPER_ANDROID_NOTIFICATIONS_REAL_TIME_LOAD =
  'GET_DEVELOPER_ANDROID_NOTIFICATIONS_REAL_TIME_LOAD';
export const GET_DEVELOPER_ANDROID_NOTIFICATIONS_REAL_TIME_SUCCESS =
  'GET_DEVELOPER_ANDROID_NOTIFICATIONS_REAL_TIME_SUCCESS';
export const GET_DEVELOPER_ANDROID_NOTIFICATIONS_REAL_TIME_FAIL =
  'GET_DEVELOPER_ANDROID_NOTIFICATIONS_REAL_TIME_FAIL';

export const GET_DEVELOPER_RECORDS = 'GET_DEVELOPER_RECORDS';
export const GET_DEVELOPER_RECORDS_REAL_TIME =
  'GET_DEVELOPER_RECORDS_REAL_TIME';
export const CLEAR_DEVELOPER_RECORDS = 'CLEAR_DEVELOPER_RECORDS';
export const HANDLE_DEVELOPER_RECORD = 'HANDLE_DEVELOPER_RECORD';
export const HANDLE_DEVELOPER_SEARCH = 'HANDLE_DEVELOPER_SEARCH';
export const HANDLE_DEVELOPER_DROPDOWN = 'HANDLE_DEVELOPER_DROPDOWN';

// auth

export const RESET_PASSWORD_EMAIL = 'RESET_PASSWORD_EMAIL';
export const RESET_PASSWORD_EMAIL_LOAD = 'RESET_PASSWORD_EMAIL_LOAD';
export const RESET_PASSWORD_EMAIL_SUCCESS = 'RESET_PASSWORD_EMAIL_SUCCESS';
export const RESET_PASSWORD_EMAIL_FAIL = 'RESET_PASSWORD_EMAIL_FAIL';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_LOAD = 'RESET_PASSWORD_LOAD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const HANDLE_RESET_PASSWORD_FIELD = 'HANDLE_RESET_PASSWORD_FIELD';
export const SIGNUP_COMPLETE = 'SIGNUP_COMPLETE';

//paywall
export const GET_PAYWALLS = 'GET_PAYWALLS';
export const GET_PAYWALLS_LOAD = 'GET_PAYWALLS_LOAD';
export const GET_PAYWALLS_SUCCESS = 'GET_PAYWALLS_SUCCESS';
export const GET_PAYWALLS_FAIL = 'GET_PAYWALLS_FAIL';

export const GET_PAYWALL = 'GET_PAYWALL';
export const GET_PAYWALL_LOAD = 'GET_PAYWALL_LOAD';
export const GET_PAYWALL_SUCCESS = 'GET_PAYWALL_SUCCESS';
export const GET_PAYWALL_FAIL = 'GET_PAYWALL_FAIL';

export const UPDATE_PAYWALL = 'UPDATE_PAYWALL';
export const UPDATE_PAYWALL_LOAD = 'UPDATE_PAYWALL_LOAD';
export const UPDATE_PAYWALL_SUCCESS = 'UPDATE_PAYWALL_SUCCESS';
export const UPDATE_PAYWALL_FAIL = 'UPDATE_PAYWALL_FAIL';

export const RESET_PAYWALL_STATE = 'RESET_PAYWALL_STATE';

export const GET_PAYWALL_STYLES = 'GET_PAYWALL_STYLES';
export const GET_PAYWALL_STYLES_LOAD = 'GET_PAYWALL_STYLES_LOAD';
export const GET_PAYWALL_STYLES_SUCCESS = 'GET_PAYWALL_STYLES_SUCCESS';
export const GET_PAYWALL_STYLES_FAIL = 'GET_PAYWALL_STYLES_FAIL';

export const UPDATE_PAYWALL_STYLES = 'UPDATE_PAYWALL_STYLES';
export const UPDATE_PAYWALL_STYLES_LOAD = 'UPDATE_PAYWALL_STYLES_LOAD';
export const UPDATE_PAYWALL_STYLES_SUCCESS = 'UPDATE_PAYWALL_STYLES_SUCCESS';
export const UPDATE_PAYWALL_STYLES_FAIL = 'UPDATE_PAYWALL_STYLES_FAIL';

export const GET_PAYWALL_MENUS = 'GET_PAYWALL_MENUS';
export const GET_PAYWALL_MENUS_LOAD = 'GET_PAYWALL_MENUS_LOAD';
export const GET_PAYWALL_MENUS_SUCCESS = 'GET_PAYWALL_MENUS_SUCCESS';
export const GET_PAYWALL_MENUS_FAIL = 'GET_PAYWALL_MENUS_FAIL';

export const UPDATE_PAYWALL_MENUS = 'UPDATE_PAYWALL_MENUS';
export const UPDATE_PAYWALL_MENUS_LOAD = 'UPDATE_PAYWALL_MENUS_LOAD';
export const UPDATE_PAYWALL_MENUS_SUCCESS = 'UPDATE_PAYWALL_MENUS_SUCCESS';
export const UPDATE_PAYWALL_MENUS_FAIL = 'UPDATE_PAYWALL_MENUS_FAIL';

export const GET_PAYWALL_MENUS_AVAILABLE = 'GET_PAYWALL_MENUS_AVAILABLE';
export const GET_PAYWALL_MENUS_AVAILABLE_LOAD =
  'GET_PAYWALL_MENUS_AVAILABLE_LOAD';
export const GET_PAYWALL_MENUS_AVAILABLE_SUCCESS =
  'GET_PAYWALL_MENUS_AVAILABLE_SUCCESS';
export const GET_PAYWALL_MENUS_AVAILABLE_FAIL =
  'GET_PAYWALL_MENUS_AVAILABLE_FAIL';

export const HANDLE_PAYWALL_FIELD = 'HANDLE_PAYWALL_FIELD';
export const HANDLE_TOGGLE_FEATURED = 'HANDLE_TOGGLE_FEATURED';
export const HANDLE_PAYWALL_MENUS = 'HANDLE_PAYWALL_MENUS';
export const HANDLE_PAYWALL_MENUS_AVAILABLE = 'HANDLE_PAYWALL_MENUS_AVAILABLE';
export const HANDLE_PAYWALL_MENUS_CHANGES = 'HANDLE_PAYWALL_MENUS_CHANGES';

export const HANDLE_PAYWALL_STYLES_FIELD = 'HANDLE_PAYWALL_STYLES_FIELD';
export const HANDLE_PAYWALL_COLOR = 'HANDLE_PAYWALL_COLOR';
export const HANDLE_PAYWALL_SIZE = 'HANDLE_PAYWALL_SIZE';

export const HANDLE_PAYWALL_KEY_FIELD = 'HANDLE_PAYWALL_KEY_FIELD';
export const RESET_PAYWALL_KEYS = 'RESET_PAYWALL_KEYS';
export const TOGGLE_DISPLAY_UNSAVED_CHANGES = 'TOGGLE_DISPLAY_UNSAVED_CHANGES';

//campaign
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const GET_CAMPAIGNS_LOAD = 'GET_CAMPAIGNS_LOAD';
export const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';
export const GET_CAMPAIGNS_FAIL = 'GET_CAMPAIGNS_FAIL';

export const GET_CAMPAIGN = 'GET_CAMPAIGN';
export const GET_CAMPAIGN_LOAD = 'GET_CAMPAIGN_LOAD';
export const GET_CAMPAIGN_SUCCESS = 'GET_CAMPAIGN_SUCCESS';
export const GET_CAMPAIGN_FAIL = 'GET_CAMPAIGN_FAIL';

export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
export const ADD_CAMPAIGN_LOAD = 'ADD_CAMPAIGN_LOAD';
export const ADD_CAMPAIGN_SUCCESS = 'ADD_CAMPAIGN_SUCCESS';
export const ADD_CAMPAIGN_FAIL = 'ADD_CAMPAIGN_FAIL';

export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const UPDATE_CAMPAIGN_LOAD = 'UPDATE_CAMPAIGN_LOAD';
export const UPDATE_CAMPAIGN_SUCCESS = 'UPDATE_CAMPAIGN_SUCCESS';
export const UPDATE_CAMPAIGN_FAIL = 'UPDATE_CAMPAIGN_FAIL';

export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const DELETE_CAMPAIGN_LOAD = 'DELETE_CAMPAIGN_LOAD';
export const DELETE_CAMPAIGN_SUCCESS = 'DELETE_CAMPAIGN_SUCCESS';
export const DELETE_CAMPAIGN_FAIL = 'DELETE_CAMPAIGN_FAIL';

export const CAMPAIGN_LIVE = 'CAMPAIGN_LIVE';
export const CAMPAIGN_LIVE_LOAD = 'CAMPAIGN_LIVE_LOAD';
export const CAMPAIGN_LIVE_SUCCESS = 'CAMPAIGN_LIVE_SUCCESS';
export const CAMPAIGN_LIVE_FAIL = 'CAMPAIGN_LIVE_FAIL';

export const CAMPAIGN_OFFLINE = 'CAMPAIGN_OFFLINE';
export const CAMPAIGN_OFFLINE_LOAD = 'CAMPAIGN_OFFLINE_LOAD';
export const CAMPAIGN_OFFLINE_SUCCESS = 'CAMPAIGN_OFFLINE_SUCCESS';
export const CAMPAIGN_OFFLINE_FAIL = 'CAMPAIGN_OFFLINE_FAIL';

export const HANDLE_CAMPAIGN_FIELD = 'HANDLE_CAMPAIGN_FIELD';

//product

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_LOAD = 'GET_PRODUCTS_LOAD';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';

export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_LOAD = 'GET_PRODUCT_LOAD';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_LOAD = 'UPDATE_PRODUCT_LOAD';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';

export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_LOAD = 'DELETE_PRODUCT_LOAD';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAIL = 'DELETE_PRODUCT_FAIL';

export const GET_PRODUCT_TRANSLATIONS = 'GET_PRODUCT_TRANSLATIONS';
export const GET_PRODUCT_TRANSLATIONS_LOAD = 'GET_PRODUCT_TRANSLATIONS_LOAD';
export const GET_PRODUCT_TRANSLATIONS_SUCCESS =
  'GET_PRODUCT_TRANSLATIONS_SUCCESS';
export const GET_PRODUCT_TRANSLATIONS_FAIL = 'GET_PRODUCT_TRANSLATIONS_FAIL';

export const ADD_PRODUCT_TRANSLATION = 'ADD_PRODUCT_TRANSLATION';
export const ADD_PRODUCT_TRANSLATION_LOAD = 'ADD_PRODUCT_TRANSLATION_LOAD';
export const ADD_PRODUCT_TRANSLATION_SUCCESS =
  'ADD_PRODUCT_TRANSLATION_SUCCESS';
export const ADD_PRODUCT_TRANSLATION_FAIL = 'ADD_PRODUCT_TRANSLATION_FAIL';

export const UPDATE_PRODUCT_TRANSLATION = 'UPDATE_PRODUCT_TRANSLATION';
export const UPDATE_PRODUCT_TRANSLATION_LOAD =
  'UPDATE_PRODUCT_TRANSLATION_LOAD';
export const UPDATE_PRODUCT_TRANSLATION_SUCCESS =
  'UPDATE_PRODUCT_TRANSLATION_SUCCESS';
export const UPDATE_PRODUCT_TRANSLATION_FAIL =
  'UPDATE_PRODUCT_TRANSLATION_FAIL';

export const DELETE_PRODUCT_TRANSLATION = 'DELETE_PRODUCT_TRANSLATION';
export const DELETE_PRODUCT_TRANSLATION_LOAD =
  'DELETE_PRODUCT_TRANSLATION_LOAD';
export const DELETE_PRODUCT_TRANSLATION_SUCCESS =
  'DELETE_PRODUCT_TRANSLATION_SUCCESS';
export const DELETE_PRODUCT_TRANSLATION_FAIL =
  'DELETE_PRODUCT_TRANSLATION_FAIL';

export const HANDLE_PRODUCT_TRANSLATION_FIELD =
  'HANDLE_PRODUCT_TRANSLATION_FIELD';
export const HANDLE_PRODUCT_TRANSLATIONS_FIELD =
  'HANDLE_PRODUCT_TRANSLATIONS_FIELD';
export const CLEAR_PRODUCT_ERRORS = 'CLEAR_PRODUCT_ERRORS';
export const CLEAR_PRODUCT_FIELDS = 'CLEAR_PRODUCT_FIELDS';
export const HANDLE_PRODUCT_FIELD = 'HANDLE_PRODUCT_FIELD';

//CRM
export const GET_CRM_USERS = 'GET_CRM_USERS';
export const GET_CRM_USERS_LOAD = 'GET_CRM_USERS_LOAD';
export const GET_CRM_USERS_SUCCESS = 'GET_CRM_USERS_SUCCESS';
export const GET_CRM_USERS_FAIL = 'GET_CRM_USERS_FAIL';

export const GET_CRM_USER = 'GET_CRM_USER';
export const GET_CRM_USER_LOAD = 'GET_CRM_USER_LOAD';
export const GET_CRM_USER_SUCCESS = 'GET_CRM_USER_SUCCESS';
export const GET_CRM_USER_FAIL = 'GET_CRM_USER_FAIL';

export const GET_CRM_USER_EVENTS = 'GET_CRM_USER_EVENTS';
export const GET_CRM_USER_EVENTS_LOAD = 'GET_CRM_USER_EVENTS_LOAD';
export const GET_CRM_USER_EVENTS_SUCCESS = 'GET_CRM_USER_EVENTS_SUCCESS';
export const GET_CRM_USER_EVENTS_FAIL = 'GET_CRM_USER_EVENTS_FAIL';

export const GET_CRM_USER_REVENUES = 'GET_CRM_USER_REVENUES';
export const GET_CRM_USER_REVENUES_LOAD = 'GET_CRM_USER_REVENUES_LOAD';
export const GET_CRM_USER_REVENUES_SUCCESS = 'GET_CRM_USER_REVENUES_SUCCESS';
export const GET_CRM_USER_REVENUES_FAIL = 'GET_CRM_USER_REVENUES_FAIL';

export const RESTART_STATE = 'RESTART_STATE';
