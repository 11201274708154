import React from 'react';

import { PlanMeta, TDisplayProduct } from 'src/api/types/main.types';

import UpgradeWebPaywall, { WebPaywallProps } from './UpgradeWebPaywall';

const PaywallCapabilityWebPaywall = ({
  onCancel,
  visible,
}: WebPaywallProps) => {
  const paywallsOnlyProducts: TDisplayProduct[] = [
    {
      product_value_props: ['Google Fonts', 'Carousel Paywalls', 'A/B Testing'],
      ...PlanMeta['paywalls_only_team_v1'],
    },
    {
      product_value_props: [
        'Dynamic Sign In Logic',
        'Product Groups',
        'Custom Brand Fonts',
      ],
      ...PlanMeta['paywalls_only_business_v1'],
    },
    {
      product_value_props: ['Custom Paywalls', 'White-glove support'],
      ...PlanMeta['paywalls_only_enterprise_v1'],
    },
  ];

  const paywallsAndSubsProducts: TDisplayProduct[] = [
    {
      product_value_props: ['Google Fonts', 'Carousel Paywalls', 'A/B Testing'],
      ...PlanMeta['paywalls_and_subs_team_v1'],
    },
    {
      product_value_props: [
        'Dynamic Sign In Logic',
        'Product Groups',
        'Custom Brand Fonts',
      ],
      ...PlanMeta['paywalls_and_subs_business_v1'],
    },
    {
      product_value_props: ['Custom Paywalls', 'White-glove support'],
      ...PlanMeta['paywalls_and_subs_enterprise_v1'],
    },
  ];

  return (
    <UpgradeWebPaywall
      modalTitle="Unlock Paywall Capabilities"
      productsPaywallsOnly={paywallsOnlyProducts}
      productsPaywallsAndSubs={paywallsAndSubsProducts}
      highlightedProductPaywallsOnly="paywalls_only_business_v1"
      highlightedProductPaywallsAndSubs="paywalls_and_subs_business_v1"
      entitlement="font.create"
      onCancel={onCancel}
      visible={visible}
    />
  );
};

export default PaywallCapabilityWebPaywall;
