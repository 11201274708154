import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import NamiIcon from '../NamiIcon/NamiIcon';
import styles from './List.module.less';

const ListIcon = ({ className, icon }) => {
  return (
    <div className={cx(styles.listIcon, styles.floatLeft, className)}>
      <NamiIcon icon={icon} />
    </div>
  );
};

ListIcon.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ListIcon;
