import React, { useState } from 'react';

import { ToastContainer } from 'react-toastify';

import 'react-dates/initialize';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-dates/lib/css/_datepicker.css';

import AppContextProvider from './AppContextProvider';
import SegmentIO from './components/SegmentIO/SegmentIO';
import AdminRouter from './pages/admin/AdminRouter';
import PublicRouter from './pages/public/PublicRouter';
import './App.less';
import ReactQueryProvider from './ReactQueryProvider';
import Token from './services/token';

declare global {
  // noinspection JSUnusedGlobalSymbols
  interface Window {
    Intercom: any;
  }
}

export default function App() {
  const [isAuthed, setAuthStatus] = useState(Token.getRefresh().isUsable());

  return (
    <ReactQueryProvider>
      <AppContextProvider
        isAuthed={isAuthed}
        onAuthStatusChange={setAuthStatus}
      >
        <SegmentIO>
          {isAuthed ? <AdminRouter /> : <PublicRouter />}
          <Toast />
        </SegmentIO>
      </AppContextProvider>
    </ReactQueryProvider>
  );
}

function Toast() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
}
