import React from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

import { FieldObject } from '../../../utils/formFieldBuilding';
import { DisplayFieldSwitch } from './DisplayFieldSwitch';
import InputLabel from './InputLabel';

type ToggleSwitchProps = Omit<FieldObject, 'variable'> & {
  onChange: (value: any) => void;
  label: string;
};

const selector = createSelector(
  [({ paywallBuilder: { paywall } }: RootState) => paywall],
  (paywall) => ({
    initialState: paywall!.template.initialState,
  })
);

export default function CollapseOpenToggleSwitch({
  label,
  ...field
}: ToggleSwitchProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const { initialState } = useAppSelector(selector);
  const currentOpenCollapses = initialState.openHeaderIds || [];

  return (
    <DisplayFieldSwitch
      label={<InputLabel {...field} />}
      checked={currentOpenCollapses.includes((field as any).variable)}
      onChange={(value) => field.editable && onToggleChange(value)}
    />
  );

  function onToggleChange(value: any) {
    actions.toggleCollapseOpen({
      value: value,
      variable: (field as any).variable,
    });
  }
}
