import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Space } from 'antd';
import { TOrgSecretKindEnum } from 'src/api/types/main.types';
import { useStoreCredentialsForm } from 'src/hooks/ant-form.hooks';

import CredentialsForm from './CredentialsForm';
import { buildFormItemProps } from './utils';
import VividPlaceholderPassword from './VividPlaceholderPassword';

export default function AmazonIAPCredentialsForm() {
  const form = useStoreCredentialsForm('amazon');

  const hasSharedSecret = !!form.secrets.amazon_iap_shared_secret;
  return (
    <CredentialsForm
      form={form}
      colon={false}
      layout="vertical"
      onFieldsChange={form.handleFieldsChange}
      className="intercom-amazonstorecredentials"
    >
      <Form.Item
        label="Shared Key"
        className="intercom-amazonIAPSharedKey"
        tooltip={{
          title:
            'Shared Key is found in Amazon Developer Console > Settings > Identity > Shared Key.',
        }}
        {...buildFormItemProps(
          ['amazon_iap_shared_secret', 'secret', 'shared_secret'],
          form.errors
        )}
      >
        <VividPlaceholderPassword
          autoComplete="new-password"
          visibilityToggle={false}
          maxLength={36}
          disabled={hasSharedSecret}
          placeholder={
            hasSharedSecret ? '●'.repeat(32) : 'Amazon Store Shared Key'
          }
        />
      </Form.Item>
      <Space style={{ marginTop: '2rem' }}>
        <Button
          type="primary"
          disabled={hasSharedSecret || !form.canCreate}
          loading={form.createMutation.isLoading}
          onClick={createSecret}
        >
          Save
        </Button>
        <Button
          danger
          type="link"
          loading={form.deleteMutation.isLoading}
          disabled={!hasSharedSecret || !form.canDelete}
          onClick={() => {
            const secretId = form.secrets.amazon_iap_shared_secret?.id;
            if (!secretId) return;
            form.deleteMutation.mutate([secretId]);
          }}
        >
          <Space>
            <DeleteOutlined />
            Delete these credentials
          </Space>
        </Button>
      </Space>
    </CredentialsForm>
  );

  function createSecret() {
    const { amazon_iap_shared_secret } = form.getFieldsValue();
    const kind = TOrgSecretKindEnum.AmazonIAPSharedSecret;
    form.createMutation.mutate([{ ...amazon_iap_shared_secret, kind }]);
  }
}
