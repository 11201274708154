import React, { Fragment } from 'react';

import PropTypes from 'prop-types';
import { Divider, Grid, Icon } from 'semantic-ui-react';

import styles from './MobileTable.module.less';

const MobileTable = ({ collection, icon, disabledIcon = true }) => {
  return (
    <Fragment>
      {collection.map((item, index) => (
        <div key={index} onClick={item.onClick} className={styles.container}>
          <Grid verticalAlign="middle">
            <Grid.Row>
              {item.graphicContent() && (
                <Grid.Column width={3} textAlign="center">
                  {item.graphicContent()}
                </Grid.Column>
              )}
              <Grid.Column width={item.graphicContent() ? 10 : 13}>
                <Grid.Row>
                  <strong className={styles.title}>{item.name}</strong>
                </Grid.Row>
                <Grid.Row className={styles.label}>{item.metaLabel}</Grid.Row>
              </Grid.Column>
              {disabledIcon && (
                <Grid.Column width={3} textAlign="center" floated="right">
                  <Icon name={icon} />
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
          <Divider />
        </div>
      ))}
    </Fragment>
  );
};

MobileTable.defaultProps = {
  collection: [],
  icon: 'angle right',
};

MobileTable.propTypes = {
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      name: PropTypes.string,
      graphicContent: PropTypes.func,
      imageColWidth: PropTypes.number,
    })
  ).isRequired,
};

export default MobileTable;
