import React from 'react';

import SectionTitle from '../../../../components/SectionTitle';
import { useAppContext, useQueryParams } from '../../../../hooks';
import { useAppSelector } from '../../../../hooks/redux.hooks';
import TeamDetailPage from './TeamDetail';
import TeamTable from './TeamTable';

export default function TeamPage() {
  const appContext = useAppContext();
  const loading = useAppSelector(({ root }) => root.loading);
  const currentUser = useQueryParams().get('user_id');

  if (!(loading === false && appContext.selectedOrg)) return null;
  if (currentUser) {
    return <TeamDetailPage />;
  }
  return (
    <>
      <SectionTitle>Team</SectionTitle>
      <TeamTable />
    </>
  );
}
