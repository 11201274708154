import { apiRequest } from '../../services/apiService';
import { handleModalErrors } from '../../services/ErrorService';
import history from '../../services/history';
import { setAppId } from '../../services/LocalStorageService';
import * as TYPES from '../types';
import { toggleNewAppModal } from './uiActions';

export const getApp = (appId) => {
  return apiRequest({
    type: TYPES.GET_APP,
    url: `/private/apps/${appId}/`,
    method: 'GET',
  });
};

export const addApp = (data) => {
  return apiRequest({
    type: TYPES.ADD_APP,
    url: `/private/apps/`,
    method: 'POST',
    data,
    options: {
      onSuccess({ dispatch, response }) {
        dispatch({ type: TYPES.ADD_APP_SUCCESS });
        dispatch(toggleNewAppModal());
        dispatch(resetNewApp());
        setAppId(response.data.id);
        history.push('/overview/');
        dispatch(
          apiRequest({
            type: 'NOTHING',
            url: '/private/legal_citations/',
            method: 'POST',
            data: {
              app: response.data.id,
              language: response.data.default_language,
            },
          })
        );
      },
      onError({ dispatch, error }) {
        handleModalErrors(error, dispatch, toggleNewAppModal);
        dispatch({ type: TYPES.ADD_APP_FAIL, error: error });
      },
    },
  });
};

export const updateApp = (appId, data) => {
  return apiRequest({
    type: TYPES.UPDATE_APP,
    url: `/private/apps/${appId}/`,
    method: 'PATCH',
    data,
  });
};

export const destroyApp = (appId) => {
  return apiRequest({
    type: TYPES.DESTROY_APP,
    url: `/private/apps/${appId}/`,
    method: 'DELETE',
  });
};

export const getAppStatus = (appId) => {
  return apiRequest({
    type: TYPES.GET_APP_STATUS,
    url: `/private/apps/${appId}/`,
    method: 'GET',
  });
};

export const handleAppDetailField = (key, value) => {
  return {
    type: TYPES.HANDLE_APP_DETAIL_FIELD,
    payload: { key, value },
  };
};

export const resetUpdateAppErrors = () => {
  return {
    type: TYPES.RESET_UPDATE_APP_ERRORS,
  };
};

export const resetNewAppErrors = () => {
  return {
    type: TYPES.RESET_NEW_APP_ERRORS,
  };
};

export const resetNewApp = () => {
  return {
    type: TYPES.RESET_NEW_APP,
  };
};

export const finishLogoUpload = (response) => {
  return {
    type: TYPES.FINISH_LOGO_UPLOAD,
    payload: { response },
  };
};
