import { toast } from 'react-toastify';

import * as TYPES from '../types';

const initialState = {
  loading: true,
  changes: false,
  citations: [],
  citation: {
    language: '',
  },
  citationError: null,
  languages: [],
};

export const citationReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_CITATIONS:
    case TYPES.GET_CITATIONS_LOAD:
      return {
        ...state,
      };
    case TYPES.GET_CITATIONS_SUCCESS:
      return {
        ...state,
        citations: action.payload.data.results,
        citationError: null,
        loading: false,
      };
    case TYPES.GET_CITATIONS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_CITATION:
    case TYPES.GET_CITATION_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_CITATION_SUCCESS:
      return {
        ...state,
        citation: action.payload.data,
        loading: false,
      };
    case TYPES.GET_CITATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.ADD_CITATION:
    case TYPES.ADD_CITATION_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.ADD_CITATION_SUCCESS:
      toast.success('Successfully added language.');
      return {
        ...state,
        loading: false,
        citation: {
          ...initialState.citation,
        },
        citationError: null,
      };
    case TYPES.ADD_CITATION_FAIL:
      return {
        ...state,
        citationError: action.error.response.data,
        loading: false,
      };

    case TYPES.DELETE_CITATION:
    case TYPES.DELETE_CITATION_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.DELETE_CITATION_SUCCESS:
      toast.success('Successfully deleted language.');
      return {
        ...state,
        loading: false,
      };
    case TYPES.DELETE_CITATION_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TYPES.UPDATE_CITATION:
    case TYPES.UPDATE_CITATION_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.UPDATE_CITATION_SUCCESS:
      toast.success('Successfully updated language.');
      return {
        ...state,
        loading: false,
        changes: false,
        citationError: null,
      };
    case TYPES.UPDATE_CITATION_FAIL:
      return {
        ...state,
        citationError: action.error.response.data,
        loading: false,
      };
    case TYPES.HANDLE_CITATION_FIELD:
      return {
        ...state,
        citation: {
          ...state.citation,
          [action.payload.key]: action.payload.value,
        },
      };
    case TYPES.HANDLE_CITATIONS_FIELD:
      return {
        ...state,
        citations: state.citations.map((citation) =>
          citation.id === action.payload.id
            ? { ...citation, [action.payload.key]: action.payload.value }
            : citation
        ),
        changes: true,
      };

    default:
      return state;
  }
};
