import React, { useEffect, useMemo, useState } from 'react';

import { useParams } from 'react-router-dom';

import { TDataParams } from '../../../../api/analytics.api';
import {
  DataKey,
  SegmentData,
  SegmentOptions,
  SegmentType,
  TData,
} from '../../../../api/types/analytics.types';
import { TAppliedFilters } from '../../../../api/types/analytics.types';
import { useAppSelector } from '../../../../hooks/redux.hooks';
import { TInsightType } from '../../insights/InsightsPage';
import InsightCard from '../../overview/InsightCard';
import { TCampaignDetailParams } from './params.types';

type CampaignInsightsCardProps<Data extends TData> = {
  title: string;
  metric: TInsightType;
  chartKeys: Array<DataKey>;
  applySegment: boolean;
  totalGetter: (params: TDataParams) => Promise<Data[]>;
  dataGetter: (params: TDataParams) => Promise<Data[]>;
  dataParser?(data: Data[], segment: SegmentType): Data[];
  totalDataParser?(data: Data[]): Data[];
};

export default function CampaignInsightsCard<Data extends TData>({
  title,
  metric,
  chartKeys,
  applySegment,
  totalGetter,
  dataGetter,
  dataParser = (data) => data,
  totalDataParser = (data) => data,
}: CampaignInsightsCardProps<Data>) {
  const [data, setData] = useState<Data[]>([]);
  const [periodTotal, setPeriodTotal] = useState<Data[]>([]);
  const [loading, setLoading] = useState(true);

  const { appId, dateRange, timezone } = useAppSelector(
    ({ root, analytic }) => {
      const appId = root.currentApp?.id;
      const { dateRangeCampaignMetrics: dateRange, timezone } = analytic;
      return { appId, dateRange, timezone };
    }
  );

  const ruleId = useParams<TCampaignDetailParams>().campaignID;
  const filters = useMemo(() => {
    return {
      filter_by_campaign: {
        identifier: 'CampaignRule.id',
        operator: 'equals',
        values: [ruleId],
      },
    } as TAppliedFilters;
  }, [ruleId]);

  const urlMeta =
    '?filters=' +
    JSON.stringify([filters.filter_by_campaign]) +
    (applySegment ? '&segment=paywall' : '');
  const segmentData: SegmentData | null = applySegment
    ? SegmentOptions['Paywall.id']
    : null;
  const segmentType: SegmentType = applySegment ? 'Paywall.id' : null;

  useEffect(() => {
    setLoading(true);
    const params: TDataParams = {
      dateRange: dateRange,
      appId,
      environment: 'production',
      interval: 'day',
      segment: segmentType,
      filters: filters,
      timezone,
    };
    Promise.all([
      totalGetter(params).then((data) => {
        setPeriodTotal(totalDataParser(data));
      }),
      dataGetter(params).then((data) => {
        setData(dataParser(data, segmentType));
      }),
    ]).finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InsightCard
      title={title}
      metric={metric}
      loading={loading}
      data={data}
      totalData={periodTotal}
      chartKeys={chartKeys}
      urlMeta={urlMeta}
      segment={segmentData}
    />
  );
}
