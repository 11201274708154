import React from 'react';

import { Form, Input, Modal, Select, Space } from 'antd';
import type { TCampaignRulePayload } from 'src/api/types/campaign.types';
import {
  useCampaignLabelsQuery,
  useNewCampaignRuleMutation,
} from 'src/hooks/queries/campaign.hooks';
import styled from 'styled-components';

import {
  PlacementDescription,
  PlacementLabel,
} from './detail/CampaignLabelSelect';

const PlacementSelect = styled(Select)`
  .ant-select-selection-item {
    margin-top: 0.15rem;
  }
`;

type AddRuleModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function AddRuleModal({ isOpen, onClose }: AddRuleModalProps) {
  const [form] = Form.useForm<TCampaignRulePayload>();
  const newLabelRuleMutation = useNewCampaignRuleMutation();
  const labelsQuery = useCampaignLabelsQuery({
    pageSize: 1000,
    archived: false,
  });

  const placementOptions = (labelsQuery.data?.results || []).map((label) => ({
    key: label.value,
    name: label.value,
    value: label.id,
    label: (
      <Space direction="horizontal">
        <PlacementLabel>{label.value}</PlacementLabel>
        {label.description && ' • '}
        <PlacementDescription>{label.description}</PlacementDescription>
      </Space>
    ),
  }));

  return (
    <Modal
      title="Add a New Campaign Rule"
      open={isOpen}
      onOk={() => {
        form.validateFields().then(() =>
          newLabelRuleMutation.mutate({
            ...form.getFieldsValue(),
            enabled: false,
            priority: 0,
          })
        );
      }}
      onCancel={onClose}
      confirmLoading={newLabelRuleMutation.isLoading}
      okText="Add"
    >
      <Form layout="vertical" form={form} requiredMark={false}>
        <Form.Item
          label="Campaign Name"
          name="name"
          className="intercom-addCampaignName"
          tooltip="Placement labels are used by app code to raise a paywall at a particular place in your app. When adding a new placement label, be sure to update your app code."
          rules={[
            { required: true, message: 'Please provide a campaign name' },
          ]}
        >
          <Input placeholder="Campaign Name" />
        </Form.Item>
        <Form.Item
          label="Placement"
          name="label"
          className="intercom-addCampaignLabel"
          rules={[
            {
              required: true,
              message: 'Please assign your new campaign to a placement',
            },
          ]}
        >
          <PlacementSelect
            placeholder="Select a Placement"
            showSearch
            loading={labelsQuery.isFetching}
            optionFilterProp="label"
            optionLabelProp="name"
            className="code-text-styling"
            options={placementOptions}
            filterOption={(input, option) =>
              (option?.name || '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}
