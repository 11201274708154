import React from 'react';

import { connect } from 'react-redux';
import { Checkbox, Form, Dropdown, Segment } from 'semantic-ui-react';

import ColorPickerLegacy from '../../../../components/ColorPickerLegacy/ColorPickerLegacy';
import {
  handlePaywallColor,
  handlePaywallSize,
} from '../../../../redux/actions/paywallActions';

const Content = ({
  paywall,
  paywallStyles,
  handlePaywallColor,
  handlePaywallSize,
}) => {
  const {
    title_text_color,
    title_font_size,
    title_shadow_color,
    title_shadow_radius,
    body_text_color,
    body_font_size,
    body_shadow_color,
    body_shadow_radius,
  } = paywallStyles;

  let fontSizeOptions = [
    { value: 8, text: '8px' },
    { value: 9, text: '9px' },
    { value: 10, text: '10px' },
    { value: 11, text: '11px' },
    { value: 12, text: '12px' },
    { value: 13, text: '13px' },
    { value: 14, text: '14px' },
    { value: 15, text: '15px' },
    { value: 16, text: '16px' },
    { value: 18, text: '18px' },
    { value: 20, text: '20px' },
    { value: 22, text: '22px' },
    { value: 24, text: '24px' },
    { value: 26, text: '26px' },
    { value: 28, text: '28px' },
    { value: 30, text: '30px' },
    { value: 34, text: '34px' },
    { value: 38, text: '38px' },
    { value: 42, text: '42px' },
    { value: 46, text: '46px' },
    { value: 50, text: '50px' },
  ];

  let shadowRadiusOptions = [
    { value: 1, text: '1px' },
    { value: 2, text: '2px' },
    { value: 3, text: '3px' },
    { value: 4, text: '4px' },
    { value: 5, text: '5px' },
    { value: 6, text: '6px' },
    { value: 7, text: '7px' },
    { value: 8, text: '8px' },
    { value: 9, text: '9px' },
    { value: 10, text: '10px' },
  ];

  return (
    <Segment basic>
      <Form.Field>
        <label>Title</label>
      </Form.Field>
      <Form.Group inline>
        <Form.Field>
          <Dropdown
            compact
            selection
            options={fontSizeOptions}
            value={title_font_size}
            onChange={(e, { value }) =>
              handlePaywallSize('title_font_size', value)
            }
          />
        </Form.Field>
        <Form.Field>
          <ColorPickerLegacy
            paywall={paywall}
            color={title_text_color}
            onChange={(color) => handlePaywallColor('title_text_color', color)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <Checkbox
          label="Shadow"
          slider
          checked={title_shadow_radius === 0 ? false : true}
          onChange={(e) =>
            handlePaywallSize(
              'title_shadow_radius',
              title_shadow_radius === 0 ? 1 : 0
            )
          }
        />
      </Form.Field>
      {title_shadow_radius !== 0 && (
        <Form.Group inline style={{ marginBottom: 30 }}>
          <Form.Field>
            <Dropdown
              compact
              selection
              options={shadowRadiusOptions}
              value={title_shadow_radius}
              onChange={(e, { value }) =>
                handlePaywallSize('title_shadow_radius', value)
              }
            />
            <div
              style={{
                position: 'absolute',
                marginTop: 5,
                fontSize: 12,
              }}
            >
              Radius
            </div>
          </Form.Field>
          <Form.Field>
            <ColorPickerLegacy
              paywall={paywall}
              color={title_shadow_color}
              onChange={(color) =>
                handlePaywallColor('title_shadow_color', color)
              }
            />
          </Form.Field>
        </Form.Group>
      )}
      <Form.Field>
        <label>Body</label>
      </Form.Field>
      <Form.Group inline>
        <Form.Field>
          <Dropdown
            compact
            selection
            options={fontSizeOptions}
            value={body_font_size}
            onChange={(e, { value }) =>
              handlePaywallSize('body_font_size', value)
            }
          />
        </Form.Field>
        <Form.Field>
          <ColorPickerLegacy
            paywall={paywall}
            color={body_text_color}
            onChange={(color) => handlePaywallColor('body_text_color', color)}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <Checkbox
          label="Shadow"
          slider
          checked={body_shadow_radius === 0 ? false : true}
          onChange={(e) =>
            handlePaywallSize(
              'body_shadow_radius',
              body_shadow_radius === 0 ? 1 : 0
            )
          }
        />
      </Form.Field>
      {body_shadow_radius !== 0 && (
        <Form.Group inline>
          <Form.Field>
            <Dropdown
              compact
              selection
              options={shadowRadiusOptions}
              value={body_shadow_radius}
              onChange={(e, { value }) =>
                handlePaywallSize('body_shadow_radius', value)
              }
            />
            <div
              style={{
                position: 'absolute',
                marginTop: 5,
                fontSize: 12,
              }}
            >
              Radius
            </div>
          </Form.Field>
          <Form.Field>
            <ColorPickerLegacy
              paywall={paywall}
              color={body_shadow_color}
              onChange={(color) =>
                handlePaywallColor('body_shadow_color', color)
              }
            />
          </Form.Field>
        </Form.Group>
      )}
    </Segment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlePaywallColor: (key, color) =>
      dispatch(handlePaywallColor(key, color)),
    handlePaywallSize: (key, value) => dispatch(handlePaywallSize(key, value)),
  };
};

const mapStateToProps = ({ paywall }) => {
  return {
    paywall: paywall.paywall,
    paywallStyles: paywall.paywallStyles,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
