import { useEffect, useRef } from 'react';

export function usePostRenderEffect(fn, dependencies) {
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) return fn();
    mounted.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
