import React, { useEffect, useState } from 'react';

import cx from 'classnames';
import { connect } from 'react-redux';
import {
  Accordion,
  Sidebar,
  Icon,
  Button,
  Segment,
  Form,
} from 'semantic-ui-react';

import {
  handlePaywallStylesField,
  getPaywallStyles,
} from '../../../redux/actions/paywallActions';
import { downloadContent, readFile } from '../../../utils/file';
import sharedStyles from '../SharedStyles.module.less';
import Base from './Sections/Base';
import Content from './Sections/Content';
import Controls from './Sections/Controls';
import Legal from './Sections/Legal';
import Products from './Sections/Products';

const PaywallStylesSidebar = ({
  visible,
  setVisible,
  pushDown,
  paywall,
  paywallStyles,
  getPaywallStyles,
  handlePaywallStylesField,
}) => {
  const [number, setNumber] = useState(0);

  useEffect(() => {
    if (paywall.style) {
      getPaywallStyles(paywall.style);
    }
  }, [paywall.style, getPaywallStyles]);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = number === index ? -1 : index;
    setNumber(newIndex);
  };
  const sortObject = (obj) => {
    return Object.keys(obj)
      .sort()
      .reduce(function (result, key) {
        result[key] = obj[key];
        return result;
      }, {});
  };

  return (
    <Form>
      <Sidebar
        className={
          pushDown
            ? cx(sharedStyles.sidebar, sharedStyles.sidebarPushDown)
            : sharedStyles.sidebar
        }
        direction="right"
        animation="overlay"
        width="wide"
        visible={visible}
        onHide={() => setVisible(false)}
      >
        <Accordion className={sharedStyles.accordion} fluid styled>
          <Accordion.Title
            active={number === 0}
            index={0}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Base
          </Accordion.Title>
          <Accordion.Content active={number === 0}>
            <Base />
          </Accordion.Content>
          <Accordion.Title
            active={number === 1}
            index={1}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Content
          </Accordion.Title>
          <Accordion.Content active={number === 1}>
            <Content />
          </Accordion.Content>
          <Accordion.Title
            active={number === 2}
            index={2}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Products
          </Accordion.Title>
          <Accordion.Content active={number === 2}>
            <Products />
          </Accordion.Content>
          <Accordion.Title
            active={number === 3}
            index={3}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Controls
          </Accordion.Title>
          <Accordion.Content active={number === 3}>
            <Controls />
          </Accordion.Content>
          <Accordion.Title
            active={number === 4}
            index={4}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Legal
          </Accordion.Title>
          <Accordion.Content active={number === 4}>
            <Legal />
          </Accordion.Content>
          <Accordion.Title
            active={number === 5}
            index={5}
            onClick={handleClick}
          >
            <Icon name="dropdown" />
            Import / Export
          </Accordion.Title>
          <Accordion.Content active={number === 5}>
            <Button.Group fluid basic>
              <Button as="label" htmlFor="file">
                <Icon name="upload" /> Import
              </Button>
              <Button.Or />
              <Button
                onClick={() => {
                  const paywallSlug = paywall.name
                    .replace(/[^a-zA-Z0-9]/g, '-')
                    .toLowerCase();
                  return downloadContent(
                    `${paywallSlug}-theme.json`,
                    JSON.stringify(sortObject(paywallStyles), null, 1)
                  );
                }}
              >
                <Icon name="download" /> Export
              </Button>
            </Button.Group>
            <input
              type="file"
              id="file"
              onChange={() => {
                const file = document.getElementById('file').files[0];
                readFile(file).then((result) => {
                  const styles = { ...paywallStyles, ...JSON.parse(result) };
                  handlePaywallStylesField(styles);
                });
              }}
              accept=".json"
              hidden
            />
          </Accordion.Content>
        </Accordion>
        <Segment basic>
          <Button fluid onClick={() => setVisible(false)}>
            Close
          </Button>
        </Segment>
      </Sidebar>
    </Form>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlePaywallStylesField: (data) =>
      dispatch(handlePaywallStylesField(data)),
    getPaywallStyles: (paywallStyleId) =>
      dispatch(getPaywallStyles(paywallStyleId)),
  };
};

const mapStateToProps = ({ paywall, auth }) => {
  return {
    paywall: paywall.paywall,
    paywallStyles: paywall.paywallStyles,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaywallStylesSidebar);
