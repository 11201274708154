import {
  TDevice,
  TDeviceOrientation,
  TFormFactorOrientation,
  TOfferState,
} from 'src/api/types/paywall.types';
import { TPaywallTemplate } from 'src/api/types/paywallTemplate.types';

export function getAttr(object: any, ...attrs: Array<string | number>): any {
  let current = object;
  for (const attr of attrs) {
    if (current === undefined || current === null) return undefined;
    current = Array.isArray(current) ? current[+attr] : current[attr];
  }
  return current;
}

export function unsetAttr(input: any, ...attrs: Array<string | number>): any {
  const output = JSON.parse(JSON.stringify(input));
  const last = attrs.at(-1);
  if (last === undefined) return output;
  const parent = getAttr(output, ...attrs.slice(0, -1));
  if (parent === undefined) return output;
  if (Array.isArray(parent)) {
    parent.splice(+last, 1);
  } else {
    delete parent[last];
  }
  return output;
}

export function getPricingStateOptions(includeIntroPromo: boolean): Array<{
  label: string;
  value: TOfferState;
  title: string;
}> {
  const options: Array<{ label: string; title: string; value: TOfferState }> = [
    {
      label: 'Standard Pricing',
      title: 'Standard',
      value: 'standard',
    },
    {
      label: 'Free Trial',
      title: 'Trial',
      value: 'trial',
    },
  ];

  if (includeIntroPromo)
    return [
      ...options,
      {
        label: 'Introductory Pricing',
        title: 'Intro',
        value: 'intro',
      },
      {
        label: 'Promotional Pricing',
        title: 'Promo',
        value: 'promo',
      },
    ];

  return options;
}

export function pullOutFormFactor(paywall: TPaywallTemplate): TDevice {
  if (paywall?.['ui.formFactors'] && paywall['ui.formFactors'].length > 0) {
    return paywall['ui.formFactors'][0].form_factor || 'phone';
  }
  return 'phone';
}

export function pullOutDeviceOrientation(
  paywall: TPaywallTemplate
): TFormFactorOrientation {
  const formFactor = pullOutFormFactor(paywall);
  const orientation = pullOutOrientation(paywall);
  const rotateable = supportsBothOrientations(paywall);

  if (formFactor === 'tablet') {
    return rotateable
      ? 'tablet-rotateable'
      : orientation === 'landscape'
      ? 'tablet-landscape'
      : 'tablet-portrait';
  } else if (formFactor === 'television') {
    return 'television';
  } else if (formFactor === 'desktop') {
    return 'desktop';
  }
  return 'phone';
}

export function pullOutOrientation(
  paywall: TPaywallTemplate
): TDeviceOrientation {
  if (paywall?.['ui.formFactors'] && paywall['ui.formFactors'].length > 0) {
    return paywall['ui.formFactors'][0].supports_landscape
      ? 'landscape'
      : 'portrait';
  }
  return 'portrait';
}

export function supportsBothOrientations(paywall: TPaywallTemplate): boolean {
  if (paywall?.['ui.formFactors'] && paywall['ui.formFactors'].length > 0) {
    return (
      paywall['ui.formFactors'][0].supports_landscape &&
      paywall['ui.formFactors'][0].supports_portrait
    );
  }
  return false;
}
