import React, { useMemo } from 'react';

import { Image, Space } from 'antd';
import { platformLogo } from 'src/services/helpers';

import { usePlatformsQuery } from '../../../../../hooks/queries/platform.hooks';
import FilterSelect, { BaseFilterSelectProps } from './FilterSelect';
import { identifierLabel } from './mapsAndOptions';

export default function FormFactorSelect(props: BaseFilterSelectProps) {
  const platformQuery = usePlatformsQuery();

  const label = identifierLabel['Platform.id'];

  const options = useMemo(() => {
    return (platformQuery.data || []).map(({ id: value, name: text, type }) => {
      return {
        value,
        key: value,
        label: (
          <Space>
            <Image
              height={15}
              width={15}
              preview={false}
              src={platformLogo(type)}
            />
            {text}
          </Space>
        ),
        text,
      };
    });
  }, [platformQuery.data]);

  return (
    <FilterSelect
      {...props}
      variant="select"
      label={label}
      options={options}
      operators={['contains', 'not_contains']}
    />
  );
}
