import React, { useEffect } from 'react';

import { Segment, Icon, Header } from 'semantic-ui-react';

import api from '../../../api';
import Page from '../../../components/Page/Page';
import history from '../../../services/history';

const EmailConfirmPage = ({ match }) => {
  useEffect(() => {
    if (match.params.token) {
      api.confirmEmail(match.params.token).then(() => {
        setTimeout(() => {
          history.push('/overview/');
        }, 6000);
      });
    }
  }, [match.params.token]);

  return (
    <Page>
      <Segment textAlign="center" style={{ height: '50vh' }}>
        <div
          style={{
            position: 'relative',
            top: '50%',
            transform: 'translateY(-60%)',
          }}
        >
          <Header icon>
            <Icon color="green" name="check circle" />
            Your Email Has Been Verified
          </Header>
          <p>Thank you for verifying your email with Nami.</p>
        </div>
      </Segment>
    </Page>
  );
};

export default EmailConfirmPage;
