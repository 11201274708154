import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { namiPrimaryBlue } from 'src/variables';
import styled from 'styled-components';

import api from '../../../api';
import { useAppContext, useBooleanState } from '../../../hooks';
import SegmentClient from '../../../segment';
import ConfirmEmailSkeleton from '../email-verification/ConfirmEmailSkeleton';
import { Header, Subtext } from '../email-verification/EmailVerificationPage';

const RedirectLink = styled.span`
  color: ${namiPrimaryBlue};
  cursor: pointer;
  font-weight: 700;
`;

export default function EmailAcceptedPage() {
  const token = useParams<{ token: string }>().token;
  const context = useAppContext();
  const [isValidating, startValidating, stopValidating] =
    useBooleanState(false);

  useEffect(() => {
    setTimeout(performEmailVerification, 2500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ConfirmEmailSkeleton
      title="Email Verified"
      children={
        <>
          <Header>Your Email has been Verified</Header>
          <Subtext>Thank you for verifying your email with Nami.</Subtext>
          <Subtext>
            You will be redirected in a few seconds. If you are not redirected
            automatically, please{' '}
            <RedirectLink onClick={performEmailVerification}>
              click here
            </RedirectLink>
            .
          </Subtext>
        </>
      }
    />
  );

  function performEmailVerification() {
    if (!token || isValidating) return;
    startValidating();
    api
      .verifyEmail(token)
      .then((payload) => {
        context.authorizeAccess(payload, '/pick-plan/');
        toast.success('Email verification successful.');
        SegmentClient.trackRegistration();
      })
      // Not using `.finally()` because if the call succeeds, this component
      // will be unloaded when the state update happens
      .catch(stopValidating);
  }
}
