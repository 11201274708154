import React, { useContext } from 'react';

import { LockFilled, PieChartOutlined } from '@ant-design/icons';
import { Col, Row, Select, Skeleton, Space } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import {
  SegmentOptions,
  SegmentType,
  TMetricFilter,
} from 'src/api/types/analytics.types';
import { colorDarkGrey } from 'src/variables';

import { AppContext } from '../../../AppContextProvider';
import { useActions, useAppSelector } from '../../../hooks/redux.hooks';
import AnalyticsSlice from '../../../redux/AnalyticsSlice';
import FilterBuilder from './FilterBuilder';

type InsightGroupProps = {
  metricSegments: Array<NonNullable<SegmentType>>;
  metricFilters: Array<TMetricFilter>;
};

export default function InsightsGroup({
  metricSegments,
  metricFilters,
}: InsightGroupProps) {
  const appContext = useContext(AppContext);
  const actions = useActions(AnalyticsSlice.actions);
  const selectedSegment = useAppSelector((state) => state.analytic.segment);
  const isRendering = useAppSelector(
    (state) => state.analytic.placeholderState
  );
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const segmentOptions = metricSegments.map((segment) => {
    const segmentMeta = SegmentOptions[segment];
    const locked = !appContext.userHasEntitlement(segmentMeta.entitlement);
    return (
      <Select.Option
        key={segment}
        value={segmentMeta.cube_segment}
        disabled={locked}
        className={'intercom-' + segmentMeta.cube_segment}
        label={segmentMeta.display_label}
      >
        {locked && <LockFilled style={{ paddingRight: '5px' }} />}
        {segmentMeta.display_label}
      </Select.Option>
    );
  });
  return (
    <Row align="top" justify="start" style={{ marginBottom: '1rem' }}>
      <Col md={12} lg={18} style={{ display: 'flex', flexWrap: 'wrap' }}>
        {!isRendering && <FilterBuilder filterOptions={metricFilters} />}
        {isRendering && <Skeleton.Input active={true} size="small" />}
      </Col>
      <Col md={12} lg={6}>
        {!isRendering && (
          <Select
            style={{
              marginLeft: '0px',
              width: '15rem',
              float: 'right',
            }}
            placeholder={
              <Space style={{ color: colorDarkGrey }}>
                <PieChartOutlined />
                <span>Segment</span>
              </Space>
            }
            onChange={(value) => {
              queryParams.set('segment', value || '');
              location.search = queryParams.toString();
              history.push(location);
              actions.setSegment(value);
            }}
            allowClear
            value={selectedSegment}
            className={'intercom-groupBy'}
            optionLabelProp="label"
          >
            {segmentOptions}
          </Select>
        )}
        {isRendering && <Skeleton.Input active={true} size="small" />}
      </Col>
    </Row>
  );
}
