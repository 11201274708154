import { Form } from 'antd';
import styled from 'styled-components';

const FilterFormItem = styled(Form.Item)`
  .ant-form-item-row {
    flex-direction: column;
    align-items: start;
    justify-content: center;

    .ant-form-item-control {
      width: 100%; // There's a max-width set to 100% somewhere

      .ant-picker-range {
        width: 100%;
      }
    }

    .ant-form-item-label {
      font-weight: 500;
    }
  }
`;

export default FilterFormItem;
