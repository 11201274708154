import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Table,
  Button,
  Icon,
  Pagination,
  Statistic,
  Segment,
  Popup,
} from 'semantic-ui-react';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import { namiPrimaryBlue } from 'src/variables';

import EmptyStateSegment from '../../../../../components/EmptyStateSegment/EmptyStateSegment';
import Loading from '../../../../../components/Loading/Loading';
import MobileTable from '../../../../../components/MobileTable/MobileTable';
import Page from '../../../../../components/Page/Page';
import Responsive from '../../../../../components/Responsive/Responsive';
import TimeZoneLabel from '../../../../../components/TimeZoneLabel/TimeZoneLabel';
import {
  getCrmUserRevenues,
  getCrmUser,
} from '../../../../../redux/actions/crmActions';
import { copyTextToClipBoard } from '../../../../../services/helpers/';
import history from '../../../../../services/history';

const UserRevenuesPage = ({
  loading,
  getCrmUserRevenues,
  getCrmUser,
  totalPages,
  count,
  revenues,
  crmUser,
}) => {
  const useId = useParams().userID;
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    getCrmUserRevenues(useId, pageNumber);
    getCrmUser(useId);
  }, [useId, pageNumber, getCrmUserRevenues, getCrmUser]);

  const copy = (platformID) => {
    toast.success('Copied transaction id.');
    copyTextToClipBoard(platformID);
  };

  let children;
  if (loading) {
    children = <Loading />;
  } else if (!revenues.length) {
    children = (
      <>
        <Statistic size="mini" horizontal>
          <Statistic.Value
            style={{ color: namiPrimaryBlue, fontWeight: 'bold' }}
          >
            0
          </Statistic.Value>
          <label style={{ padding: '4px 10px 0 5px' }}>
            Matching Revenue Records
          </label>
        </Statistic>
        <EmptyStateSegment
          title="No Revenue Records Found"
          subtitle="No revenue records found on this user."
          icon="sdk"
        >
          <Button
            className="nami-primary-button"
            onClick={() => {
              history.push(`/customers/${useId}/`);
            }}
          >
            <Icon name="arrow alternate circle left" /> Go Back
          </Button>
        </EmptyStateSegment>
      </>
    );
  } else if (!!revenues.length) {
    children = (
      <>
        <Statistic size="mini" horizontal>
          <Statistic.Value
            style={{ color: namiPrimaryBlue, fontWeight: 'bold' }}
          >
            {count.toLocaleString()}
          </Statistic.Value>
          <label style={{ padding: '4px 10px 0 5px' }}>
            Matching Revenue Records
          </label>
        </Statistic>
        <Responsive size="mdUp">
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell className="table-header">
                  Amount
                </Table.HeaderCell>
                <Table.HeaderCell className="table-header">
                  Created Date
                </Table.HeaderCell>
                <Table.HeaderCell className="table-header">
                  Platform Type
                </Table.HeaderCell>
                <Table.HeaderCell className="table-header">
                  Transaction ID
                </Table.HeaderCell>
                <Table.HeaderCell className="table-header">
                  Type
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {revenues.length &&
                revenues.map((event, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{`${event.amount} ${event.currency}`}</Table.Cell>
                    <Table.Cell>
                      {moment(event.created_date).format(
                        'MMM DD, YYYY - hh:mm:ss'
                      )}
                    </Table.Cell>
                    <Table.Cell>{event.platform_type}</Table.Cell>

                    <Popup
                      trigger={
                        <Table.Cell
                          style={{ cursor: 'pointer' }}
                          onClick={() => copy(event.transaction_id)}
                        >
                          {event.transaction_id}
                        </Table.Cell>
                      }
                      content="copy"
                      size="mini"
                      inverted
                      position="left center"
                    />

                    <Table.Cell>{event.type}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
          {count > 20 && (
            <Pagination
              defaultActivePage={pageNumber}
              onPageChange={(e, { activePage }) => setPageNumber(activePage)}
              ellipsisItem={{
                content: <Icon name="ellipsis horizontal" />,
                icon: true,
              }}
              firstItem={
                pageNumber !== 1
                  ? {
                      content: <Icon name="angle double left" />,
                      icon: true,
                    }
                  : null
              }
              lastItem={
                totalPages !== pageNumber
                  ? {
                      content: <Icon name="angle double right" />,
                      icon: true,
                    }
                  : null
              }
              prevItem={
                pageNumber !== 1
                  ? { content: <Icon name="angle left" />, icon: true }
                  : null
              }
              nextItem={
                totalPages !== pageNumber
                  ? { content: <Icon name="angle right" />, icon: true }
                  : null
              }
              totalPages={totalPages}
            />
          )}
        </Responsive>
        <Responsive size="mdDown">
          <Segment>
            <MobileTable
              disabledIcon={false}
              collection={revenues.map((event) => ({
                name: `${event.amount} ${event.currency}`,
                metaLabel: moment(event.created_date).format(
                  'MMM DD, YYYY - hh:mm:ss'
                ),
                graphicContent: () => {},
              }))}
            />
          </Segment>
          {count > 20 && (
            <Pagination
              defaultActivePage={pageNumber}
              onPageChange={(e, { activePage }) => setPageNumber(activePage)}
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              prevItem={
                pageNumber !== 1
                  ? { content: <Icon name="angle left" />, icon: true }
                  : null
              }
              nextItem={
                totalPages !== pageNumber
                  ? { content: <Icon name="angle right" />, icon: true }
                  : null
              }
              totalPages={totalPages}
            />
          )}
        </Responsive>
        <TimeZoneLabel />
      </>
    );
  }
  return (
    <Page>
      <Row justify="start" gutter={[0, 36]}>
        <Col xs={24} style={{ paddingBottom: 8 }}>
          <Breadcrumb
            items={[
              { url: '/customers/', name: 'Customers' },
              {
                url: `/customers/${useId}/`,
                name: crmUser?.pseudonym || 'Loading customer',
              },
              { name: 'Revenue' },
            ]}
          />
        </Col>
      </Row>
      {children}
    </Page>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCrmUserRevenues: (id, pageNumber) =>
      dispatch(getCrmUserRevenues(id, pageNumber)),
    getCrmUser: (id) => dispatch(getCrmUser(id)),
  };
};

const mapStateToProps = ({ crm }) => {
  return {
    revenues: crm.revenues,
    count: crm.count,
    totalPages: crm.totalPages,
    loading: crm.loading,
    crmUser: crm.crmUser,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserRevenuesPage);
