import React from 'react';

import PropTypes from 'prop-types';

import styles from './Wrapper.module.less';

const Wrapper = ({ children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.containerWrapper}>
        <div className={styles.container}>{children}</div>
      </div>
    </div>
  );
};

Wrapper.propTypes = {
  children: PropTypes.node,
};

export default Wrapper;
