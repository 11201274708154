import { bareClient, client } from './clients';
import {
  AuthPayloadType,
  InviteAcceptancePayloadType,
  RoleType,
  UserType,
} from './types/main.types';
import {
  extractResponseData,
  extractListResponseData,
  extractErrorData,
} from './utils';
const orgMembers = {
  acceptInviteSignup: (
    inviteID: string,
    data: InviteAcceptancePayloadType
  ): Promise<AuthPayloadType> => {
    const payload = {
      ...data,
      password_confirm: data.password,
      user_accepts_tos: true,
      opted_in_marketing: true,
    };
    return bareClient
      .post(`/private/org/accounts/roles/accept_invite/${inviteID}/`, payload)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getOrgMembers: (orgId: string): Promise<UserType[]> => {
    return client
      .get(`/private/org/orgs/${orgId}/`)
      .then((response) => response.data.accounts)
      .catch(extractErrorData);
  },
  getOrgMember: (userId: string): Promise<UserType> => {
    return client
      .get(`/private/org/accounts/${userId}`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getOrgRoles: (): Promise<RoleType[]> => {
    return client
      .get('/private/org/roles/')
      .then(extractListResponseData)
      .catch(extractErrorData);
  },
  sentOrgMemberInvite: (email: string, roles: string[]): Promise<void> => {
    return client
      .post('/private/org/accounts/roles/invite/', { email, roles })
      .catch(extractErrorData) as unknown as Promise<void>;
  },
  removeOrgMember: (userId: string): Promise<void> => {
    return client
      .delete(`/private/org/accounts/${userId}/roles/`)
      .catch(extractErrorData) as unknown as Promise<void>;
  },
  updateOrgMemberRole: (userId: string, data: Array<string>): Promise<void> => {
    return client
      .put(`/private/org/accounts/${userId}/roles/`, { roles: data })
      .catch(extractErrorData) as unknown as Promise<void>;
  },
};

export default orgMembers;
