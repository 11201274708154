import React, { useEffect, useState } from 'react';

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Space } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import SearchInput from 'src/components/SearchInput';
import { useAppContext, useBooleanState, useQueryParams } from 'src/hooks';
import {
  useCampaignRuleApiOptions,
  useNewCampaignRuleMutation,
} from 'src/hooks/queries/campaign.hooks';

import AddRuleModal from './AddRuleModal';
import CampaignsTable from './CampaignsTable';
import PlacementFilter from './PlacementFilter';
import { ToolbarRow } from './PlacementsWrapper';
import StatusFilter from './StatusFilter';

export default function CampaignsWrapper() {
  const { userHasEntitlement } = useAppContext();
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const [labelId, setLabelId] = useState<string | null>(
    queryParams.get('label_id') || null
  );
  const [isAddModalOpen, openAddModal, closeAddModal] = useBooleanState(false);
  const [status, setStatus] = useState<string | null>(
    queryParams.get('status') || null
  );
  const [apiOptions, updateApiOptions] = useCampaignRuleApiOptions({
    pageSize: 20,
    label_id: labelId || '',
    enabled: getEnabledStatus(status),
    archived: getArchivedStatus(),
  });
  const newLabelRuleMutation = useNewCampaignRuleMutation();

  useEffect(() => {
    updateApiOptions({
      ...apiOptions,
      label_id: labelId || '',
      enabled: getEnabledStatus(status),
      archived: getArchivedStatus(),
    });
    queryParams.set('label_id', labelId || '');
    queryParams.set('status', status || '');
    location.search = queryParams.toString();
    history.push(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelId, status]);

  return (
    <>
      <ToolbarRow gutter={[8, 8]}>
        <Col xs={24} md={6}>
          <SearchInput
            placeholder="Search campaign rule"
            value={apiOptions.search || ''}
            onChange={(search) => {
              updateApiOptions({ search });
            }}
          />
        </Col>
        <Col xs={12} md={6}>
          <PlacementFilter
            selectedLabel={labelId}
            onChange={setLabelId}
            onClear={() => setLabelId(null)}
          />
        </Col>
        <Col xs={12} md={4}>
          <StatusFilter
            status={status}
            onChange={setStatus}
            onClear={() => setStatus(null)}
          />
        </Col>
        <Col xs={0} md={2}>
          <IconActionButton
            type="text"
            icon={<CloseOutlined style={{ fontSize: '13px' }} />}
            onClick={() => resetFilters()}
            disabled={!labelId && !status}
          >
            Reset
          </IconActionButton>
        </Col>
        <Col xs={24} md={6}>
          <Space direction="horizontal" size="small" style={{ float: 'right' }}>
            {/* <Responsive size="mdUp">
              <Button.Group>
                <Button
                  icon={<EditOutlined />}
                  // onClick={}
                  disabled={true} //TODO
                />
                <Button icon={<CopyOutlined />} disabled={true} />
                <Button icon={<DeleteOutlined />} disabled={true} />
              </Button.Group>
            </Responsive> */}
            <Button
              type="primary"
              onClick={() => {
                if (!labelId) openAddModal();
                else {
                  newLabelRuleMutation.mutate({
                    label: labelId,
                    name: 'Untitled Campaign',
                    priority: 0,
                    enabled: false,
                  });
                }
              }}
              icon={<PlusOutlined />}
              disabled={!userHasEntitlement('app.campaign.create')}
              className="intercom-addCampaign"
            >
              Add
            </Button>
          </Space>
          {/* <Button
            icon={<FileOutlined />}
            disabled={true} //TODO
            style={{ float: 'right' }}
            className="intercom-exportCampaignRules"
          >
            Export
          </Button> */}
        </Col>
      </ToolbarRow>
      <CampaignsTable
        apiOptions={apiOptions}
        onApiOptionsChange={updateApiOptions}
      />
      <AddRuleModal isOpen={isAddModalOpen} onClose={closeAddModal} />
    </>
  );

  function resetFilters() {
    setStatus(null);
    setLabelId(null);
  }

  function getArchivedStatus(): boolean {
    if (status === 'archived') return true;
    return false;
  }
}

export function getEnabledStatus(status: string | null): boolean | undefined {
  if (status === 'enabled') return true;
  if (status === 'paused') return false;
  return undefined;
}
