export function hasLowerCase(str: string): boolean {
  return str.toUpperCase() !== str;
}

export function hasUpperCase(str: string): boolean {
  return str.toLowerCase() !== str;
}

export function hasNumber(str: string): boolean {
  return /\d/g.test(str);
}

export const disabled = (
  loading: boolean,
  firstName?: string,
  lastName?: string,
  email?: string,
  password?: string
) => {
  return !(
    !loading &&
    firstName &&
    lastName &&
    email &&
    passwordIsValid(password)
  );
};

export function passwordIsValid(value?: string): boolean {
  return (
    !!value &&
    value.length >= 8 &&
    hasLowerCase(value) &&
    hasUpperCase(value) &&
    hasNumber(value)
  );
}
