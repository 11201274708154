import { Select } from 'antd';
import styled, { css } from 'styled-components';

import {
  namiAliceGray,
  namiDarkGray,
  namiDarkRed,
  namiLightRed,
} from '../../../../variables';

const FilterSelect = styled(Select)<{
  label?: string | null;
  operator?: string;
}>`
  &.ant-select-disabled .ant-select-selection-item-content {
    margin-right: 0;
  }

  .ant-select-selector {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    ${({ label, operator }) => {
      if (!(label && operator)) return '';
      return css`
        &:before {
          display: block;
          content: '${label} ${operator}';
          font-weight: bold;
          margin-right: 0.5rem;
          margin-left: 0.5rem;
          min-width: fit-content;
        }
      `;
    }}
  }

  .ant-select-selection-overflow-item {
    display: flex;

    // Except last 2 children
    &:not(:nth-last-child(-n + 2)):after {
      display: flex;
      content: 'or';
      margin-right: 5px;
      align-items: center;
    }
  }

  .ant-select-selection-item {
    background-color: ${namiAliceGray};
    padding-inline-start: 4px;

    .ant-select-selection-item-remove > .anticon {
      line-height: 0;
    }
  }

  .ant-select-selection-placeholder {
    color: ${namiDarkGray};
  }

  ${({ status }) => {
    if (status !== 'error') return '';
    return css`
      .ant-select-selection-item {
        border: 1px solid ${namiDarkRed};
        background-color: ${namiLightRed};
        padding-inline-start: 4px;

        .ant-select-selection-item-content {
          margin-right: 0;
        }

        .ant-select-selection-item-remove {
          display: none;
        }
      }
    `;
  }}
`;

export default FilterSelect;
