import React, { useMemo, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Button, Modal, Popconfirm, Select, Space, Typography } from 'antd';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';

type SelectGroupsToEditModalProps = {
  onClose: () => void;
  open: boolean;
  onFinish: (groupId: string[]) => void;
};

const selector = createSelector(
  [
    ({ paywallBuilder: { productGroups } }: RootState) => productGroups,
    ({ paywallBuilder: { skuItems } }: RootState) => skuItems,
  ],
  (productGroups, skuItems) => ({
    currentProductGroups: productGroups,
    skuItems,
  })
);

export default function SelectGroupsToEditModal({
  onClose,
  open,
  onFinish,
}: SelectGroupsToEditModalProps) {
  const { currentProductGroups, skuItems } = useAppSelector(selector);

  const groupOptions = currentProductGroups.map((group, i) => {
    return {
      label: group.display_name,
      value: group.id,
      key: group.ref,
    };
  });
  const [selectedGroups, setSelectedGroups] = useState<Array<string>>([]);

  const countProducts = useMemo(() => {
    let count = 0;
    for (const group in skuItems) {
      if (selectedGroups.includes(group)) {
        for (const platform in skuItems[group]) {
          count = count + Object.keys(skuItems[group][platform]).length;
        }
      }
    }
    return count;
  }, [selectedGroups, skuItems]);

  return (
    <Modal
      title="Apply Edits to Selected Products"
      open={open}
      footer={null}
      centered
      zIndex={1002}
      onCancel={onClose}
      destroyOnClose={true}
    >
      <Space direction="vertical" style={{ width: '100%' }} size="middle">
        <Typography.Text>
          Choose product groups to apply edits to only products in these groups.
        </Typography.Text>
        <Select
          options={groupOptions}
          mode="multiple"
          placeholder="Select product groups"
          onChange={setSelectedGroups}
          style={{ width: '100%' }}
          showArrow={true}
          maxTagCount={4}
          filterOption={(input, option) =>
            (option?.label || '').toLowerCase().includes(input.toLowerCase()) ||
            (option?.key || '').toLowerCase().includes(input.toLowerCase())
          }
        />
        <Popconfirm
          title={`Are you sure you want to apply these changes to ${countProducts} products?`}
          onConfirm={() => {
            onFinish(selectedGroups);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary" disabled={!selectedGroups.length}>
            Apply Edits
          </Button>
        </Popconfirm>
      </Space>
    </Modal>
  );
}
