import React from 'react';

import { Card, Result } from 'antd';

import NamiIcon from '../../components/NamiIcon/NamiIcon';

type EmptyStateProps = {
  title: string;
  icon: string;
  size: 'large';
  link: string;
  linkText: React.ReactNode;
  children: React.ReactNode;
  loading?: boolean;
};

export default function EmptyState({
  title,
  icon,
  size,
  link,
  linkText,
  children,
  loading,
}: EmptyStateProps) {
  return (
    <Card loading={loading}>
      <Result
        icon={<NamiIcon icon={icon} size={size} color="primary" />}
        title={title}
        style={{ padding: '32px 12px' }}
        extra={
          <>
            <div style={{ marginBottom: 15, marginTop: -20 }}>
              <a href={link} target="_blank" rel="noreferrer">
                {linkText}
              </a>
            </div>
            {children}
          </>
        }
      />
    </Card>
  );
}
