import React, { useEffect, useState } from 'react';

import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, MenuProps, Space, Tooltip } from 'antd';
import { useAppContext } from 'src/hooks';
import { namiLightGray, namiMediumGray } from 'src/variables';
import styled from 'styled-components';
import { v4 as uuid4 } from 'uuid';

import {
  TCampaignRuleFilter,
  TCampaignRuleQueryOperator,
  TFilterIdentifier,
  TFilterOperator,
} from '../../../../api/types/campaign.types';
import AppVersionSelect from './selectors/AppVersionSelect';
import CDPAudienceSelect from './selectors/CDPAudienceSelect';
import CDPIdSelect from './selectors/CDPIDSelect';
import DeviceFormFactorSelect from './selectors/DeviceFormFactorSelect';
import DeviceGeoIpSelect from './selectors/DeviceGeoIpSelect';
import DeviceLanguageSelect from './selectors/DeviceLanguageSelect';
import DeviceMarketplaceSelect from './selectors/DeviceMarketplaceSelect';
import DeviceOSSelect from './selectors/DeviceOSSelect';
import DeviceRegionSelect from './selectors/DeviceRegionSelect';
import NamiDeviceIDSelect from './selectors/NamiDeviceIDSelect';
import NamiExternalIDSelect from './selectors/NamiExternalIDSelect';
import OSVersionSelect from './selectors/OSVersionSelect';
import PlatformSelect from './selectors/PlatformSelect';
import SDKVersionSelect from './selectors/SDKVersionSelect';
import {
  anonymous_filters,
  identifierHelpTextMap,
  identifierIconMap,
  identifierLabel,
} from './selectors/mapsAndOptions';

export type TFilters = { [key: string]: TCampaignRuleFilter };
type AudienceBuilderProps = {
  anonymous: boolean;
  disabled?: boolean;
  filters: TFilters;
  onChange(value: ((prevState: TFilters) => TFilters) | TFilters): void;
  operator: TCampaignRuleQueryOperator;
};

const SelectJoint = styled.span`
  border: 1px solid ${namiLightGray};
  padding: 3px 6px;
  border-radius: 3px;
  flex-grow: 1;
  font-size: small;
`;
const SelectJointDivider = styled(Divider)`
  width: 100px;
  min-width: auto;
  margin: 8px 0px !important;
  border-top-color: rgba(0, 0, 0, 0.1) !important;
  .ant-divider-inner-text {
    padding: 0em !important;
  }
`;

const selectMap = {
  'Platform.id': PlatformSelect,
  'Device.os_country': DeviceRegionSelect,
  'Device.marketplace_country': DeviceMarketplaceSelect,
  'Device.geo_ip_country': DeviceGeoIpSelect,
  'Device.os_language': DeviceLanguageSelect,
  'Device.os_name': DeviceOSSelect,
  'Device.form_factor': DeviceFormFactorSelect,
  'Device.os_version': OSVersionSelect,
  'Device.sdk_version': SDKVersionSelect,
  'Device.app_version': AppVersionSelect,
  'Device.cdp_audience': CDPAudienceSelect,
  'Device.id': NamiDeviceIDSelect,
  'Device.external_id': NamiExternalIDSelect,
  'Device.customer_data_platform_id': CDPIdSelect,
} as const;

export default function AudienceBuilder({
  anonymous,
  disabled,
  filters,
  onChange,
  operator,
}: AudienceBuilderProps) {
  const appContext = useAppContext();
  const keyValues = Object.entries(filters);
  const lastIndex = keyValues.length - 1;
  const [usedFilters, setUsedFilters] = useState<Array<TFilterIdentifier>>([]);
  const [filterOptions, setFilterOptions] = useState<MenuProps['items']>([]);

  useEffect(() => {
    const filterIds = Object.entries(filters).map(
      ([_filterId, filter]) => filter.identifier
    );
    setUsedFilters(filterIds);
  }, [filters]);

  useEffect(() => {
    const availableFilterOptions = getIdentifierOptions(usedFilters);
    setFilterOptions(availableFilterOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usedFilters]);

  return (
    <>
      {keyValues.map(([key, filter], i) => {
        const handleValueChange = (values: string[]) => {
          onChange((filters) => {
            if (!(key in filters)) return filters;
            return { ...filters, [key]: { ...filters[key], values } };
          });
        };
        const handleOperatorChange = (operator: TFilterOperator) => {
          onChange((filters) => {
            if (!(key in filters)) return filters;
            return { ...filters, [key]: { ...filters[key], operator } };
          });
        };
        const handleRemoval = () => {
          onChange((filters) => {
            const newFilters = { ...filters };
            delete newFilters[key];
            return newFilters;
          });
        };
        const Select = selectMap[filter.identifier];
        return (
          <div key={key}>
            <Select
              disabled={disabled}
              onValueChange={handleValueChange}
              onRemove={handleRemoval}
              onOperatorChange={handleOperatorChange}
              {...filter}
            />
            {i !== lastIndex ? (
              <SelectJointDivider plain>
                <SelectJoint>{operator}</SelectJoint>
              </SelectJointDivider>
            ) : null}
          </div>
        );
      })}
      {(filterOptions?.length || 0) > 0 && (
        <Dropdown
          menu={{ items: filterOptions }}
          disabled={disabled}
          trigger={['click']}
        >
          <Button
            icon={<PlusOutlined />}
            size="small"
            style={{ marginTop: 20 }}
          >
            Add Filter
          </Button>
        </Dropdown>
      )}
    </>
  );

  function getIdentifierOptions(
    usedOptions: TFilterIdentifier[]
  ): MenuProps['items'] {
    const availableOptions: TFilterIdentifier[] = Object.keys(
      identifierLabel
    ).reduce((output, identifier) => {
      if (!usedOptions.includes(identifier as TFilterIdentifier)) {
        if (
          (identifier as TFilterIdentifier) === 'Device.cdp_audience' &&
          !anonymous
        ) {
          if (appContext.planHasEntitlement('org.cdp_integration.list')) {
            //TODO - what if app cdp integrations are empty?
            return [...output, identifier as TFilterIdentifier];
          }
          return output;
        }

        if (
          (identifier as TFilterIdentifier) === 'Device.geo_ip_country' &&
          !anonymous
        ) {
          if (
            appContext.planHasEntitlement('app.campaign.filter.geo_ip_country')
          ) {
            return [...output, identifier as TFilterIdentifier];
          }
          return output;
        }

        if (anonymous) {
          if (anonymous_filters.includes(identifier as TFilterIdentifier)) {
            return [...output, identifier as TFilterIdentifier];
          }
          return output;
        }
        return [...output, identifier as TFilterIdentifier];
      }
      return output;
    }, [] as TFilterIdentifier[]);

    return availableOptions.map((identifier) => {
      const Icon = identifierIconMap[identifier];
      const label = (
        <Space>
          {Icon}
          <span>{identifierLabel[identifier]}</span>
          <Tooltip title={identifierHelpTextMap[identifier]}>
            <QuestionCircleOutlined
              style={{ fontSize: 13, color: namiMediumGray }}
            />
          </Tooltip>
        </Space>
      );
      return {
        text: identifierLabel[identifier],
        value: identifier,
        key: identifier,
        label,
        helptext: identifierHelpTextMap[identifier],
        onClick: () => addFilter(identifier),
      };
    });
  }

  function addFilter(identifier: TFilterIdentifier): void {
    const versionFilters: TFilterIdentifier[] = [
      'Device.os_version',
      'Device.sdk_version',
      'Device.app_version',
      'Device.id',
      'Device.external_id',
      'Device.customer_data_platform_id',
    ];
    const caseSensitiveFilters: TFilterIdentifier[] = [
      'Platform.id',
      'Device.cdp_audience',
    ];
    const newFilter: TCampaignRuleFilter = {
      identifier,
      operator: versionFilters.includes(identifier)
        ? 'equals'
        : caseSensitiveFilters.includes(identifier)
        ? 'contains'
        : 'i_contains',
      values: [],
    };
    onChange({ ...filters, [uuid4()]: newFilter });
  }
}
