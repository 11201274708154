import { toast } from 'react-toastify';

import history from '../../services/history';
import Token from '../../services/token';
import * as TYPES from '../types';

const initialState = {
  loading: false,
  resetPasswordData: {
    email: '',
    password: '',
    passwordConfirm: '',
  },
  password: {
    passwordError: null,
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  },
  resetPasswordError: null,
  apiAccessLoading: true,
  apiAccessData: {
    name: '',
    expiration: null,
    refresh_token: null,
  },
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.HANDLE_PASSWORD_FIELD:
      return {
        ...state,
        password: {
          ...state.password,
          [action.payload.key]: action.payload.value,
        },
      };
    case TYPES.RESET_PASSWORD_EMAIL:
    case TYPES.RESET_PASSWORD_EMAIL_LOAD:
      return {
        ...state,
        loading: true,
      };

    case TYPES.RESET_PASSWORD_EMAIL_SUCCESS:
      toast.success(
        'A password reset email will be sent if we find a user associated with this email.'
      );
      history.push('/login/');
      return {
        ...state,
        loading: false,
        resetPasswordData: {
          ...state.resetPasswordData,
          email: '',
          password: '',
          passwordConfirm: '',
        },
        resetPasswordError: null,
      };
    case TYPES.RESET_PASSWORD_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        resetPasswordError: action.error.response.data,
      };

    case TYPES.RESET_PASSWORD:
    case TYPES.RESET_PASSWORD_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.RESET_PASSWORD_SUCCESS:
      Token.setAccess(action.payload.data.access_token);
      Token.setRefresh(action.payload.data.refresh_token);
      toast.success('Password changed successfully.');
      history.push('/overview/');
      return {
        ...state,
        loading: false,
        resetPasswordData: {
          ...state.resetPasswordData,
          email: '',
          password: '',
          passwordConfirm: '',
        },
        resetPasswordError: null,
      };
    case TYPES.RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        resetPasswordError: action.error.response.data,
      };
    case TYPES.HANDLE_RESET_PASSWORD_FIELD:
      return {
        ...state,
        resetPasswordData: {
          ...state.resetPasswordData,
          [action.payload.key]: action.payload.value,
        },
      };
    case TYPES.RESTART_STATE:
      return initialState;
    default:
      return state;
  }
};
