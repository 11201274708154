import React from 'react';

import * as Icons from '@ant-design/icons';
import { Col, Row, Space } from 'antd';
import { TFormGroup } from 'src/api/types/paywallTemplate.types';
import { testObjectMatches } from 'src/components/PaywallPreview/utils';
import { namiPrimaryBlue } from 'src/variables';
import styled from 'styled-components';

import { capitalizePhrase, toSlug } from '../../../../../utils/string';

interface GroupsMenuProps {
  onGroupClick: (groupName: string) => void;
  formGroups: TFormGroup[] | undefined;
}

const GroupMenuItem = styled(Row)`
  padding: 0 !important;
  height: 36px !important;
  width: 100%;
`;

const GroupMenuTitle = styled(Col)`
  cursor: pointer;
  &:hover {
    color: ${namiPrimaryBlue};
    text-decoration: underline;
  }
`;

const GroupAccessIcon = styled(Icons.EditOutlined)`
  float: right;
  font-size: 13px;
  cursor: pointer;
  &:hover {
    color: ${namiPrimaryBlue};
  }
`;

export default function GroupsMenu({
  onGroupClick,
  formGroups,
}: GroupsMenuProps) {
  if (!formGroups) return null;
  return (
    <>
      {formGroups.map((group) => {
        if (group.conditions) {
          if (!group.conditions.every(testObjectMatches)) return null;
        } else if (group.orConditions) {
          if (!group.orConditions.some(testObjectMatches)) return null;
        }
        return (
          <GroupMenuItem
            className={'intercom-component-' + toSlug(group.title)}
            key={group.title}
          >
            <GroupMenuTitle
              onClick={() => onGroupClick(group.title)}
              flex="auto"
            >
              {capitalizePhrase(group.title)}
            </GroupMenuTitle>
            <Col>
              <Space direction="horizontal" size={10}>
                {/* <GroupDeleteIcon /> */}
                <GroupAccessIcon onClick={() => onGroupClick(group.title)} />
              </Space>
            </Col>
          </GroupMenuItem>
        );
      })}
    </>
  );
}
