import React, { useContext, useEffect, useState } from 'react';

import { Col, Image, Layout, Row, Space, Typography } from 'antd';
import Link from 'antd/lib/typography/Link';
import { TierType } from 'src/api/types/main.types';
import NamiLogo from 'src/images/namiMLlogo.svg';
import PickPlanBG from 'src/images/pickPlanBg.jpg';
import { namiAqua, namiPrimaryBlue, namiPureWhite } from 'src/variables';
import styled from 'styled-components';

import Page from '../../../components/Page/Page';
import { Header } from '../../public/email-verification/EmailVerificationPage';
import { NavigationContext } from '../AdminRouter';
import PlanCard from './PlanCard';
import TierToggle from './TierToggle';

const Background = styled(Layout)`
  background-image: url(${PickPlanBG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${namiPrimaryBlue};
  height: 100vh;
  width: 100%;
`;

const Content = styled(Space)`
  background-color: unset;
  overflow-y: scroll;
  scrollbar-width: none;
  height: 100%;
  text-align: center;
  justify-content: start;
  padding: 5em 10%;
`;

const SupportSubtext = styled(Typography.Text)`
  font-size: 14px;
  color: ${namiPureWhite};
  font-weight: 400;

  a {
    color: ${namiAqua};
    font-weight: 600;
  }
`;

const PickPlan = () => {
  const [tier, setTier] = useState<TierType>('paywallsOnly');
  const navigationContext = useContext(NavigationContext);

  useEffect(() => {
    navigationContext.hideNavigation();
    return () => navigationContext.showNavigation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      title="Join"
      layout="full"
      contentContainerClass="full-main-container-white"
    >
      <Background>
        <Content size={24} direction="vertical" align="center">
          <Image
            src={NamiLogo}
            preview={false}
            width={200}
            onClick={() => window.open('https://namiml.com')}
            style={{ cursor: 'pointer' }}
          />
          <Header style={{ color: namiPureWhite }}>Choose your plan:</Header>
          <TierToggle
            selectedTier={tier}
            onClick={(tier: TierType) => setTier(tier)}
          />
          <Row
            className="intercom-products-list"
            gutter={[24, 20]}
            style={{ width: '100%', marginTop: 16 }}
          >
            {tier === 'paywallsOnly' && (
              <>
                <Col lg={8} sm={12} style={{ width: '100%' }}>
                  <PlanCard product="paywalls_only_team_v1" />
                </Col>
                <Col lg={8} sm={12} style={{ width: '100%' }}>
                  <PlanCard product="paywalls_only_business_v1" badge={true} />
                </Col>
                <Col lg={8} sm={12} style={{ width: '100%' }}>
                  <PlanCard product="paywalls_only_enterprise_v1" />
                </Col>
              </>
            )}
            {tier === 'paywallsAndSubscriptions' && (
              <>
                <Col lg={8} sm={12} style={{ width: '100%' }}>
                  <PlanCard product="paywalls_and_subs_team_v1" />
                </Col>
                <Col lg={8} sm={12} style={{ width: '100%' }}>
                  <PlanCard
                    product="paywalls_and_subs_business_v1"
                    badge={true}
                  />
                </Col>
                <Col lg={8} sm={12} style={{ width: '100%' }}>
                  <PlanCard product="paywalls_and_subs_enterprise_v1" />
                </Col>
              </>
            )}
          </Row>
          <SupportSubtext>
            * Starts at $50/month per additional 1,000 impressions
            {tier === 'paywallsAndSubscriptions' &&
              ' and $50/month per additional $1,000 MTR'}
          </SupportSubtext>
          <Space direction="vertical" style={{ marginTop: 24 }}>
            <SupportSubtext>
              Need help choosing a plan?{' '}
              <Link href="mailto:sales@namiml.com?subject=Help me choose a Nami plan">
                Contact a product expert.
              </Link>
            </SupportSubtext>
          </Space>
        </Content>
      </Background>
    </Page>
  );
};

export default PickPlan;
