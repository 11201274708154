import React, { useMemo, useState } from 'react';

import { PlusOutlined, ReadOutlined } from '@ant-design/icons';
import { Button, Form, List, Row, Space } from 'antd';
import {
  TFieldSettings,
  TProductVariableType,
} from 'src/api/types/paywallTemplate.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import styled from 'styled-components';

import AddProductFieldModal from '../modals/AddProductFieldModal';
import EditProductFieldModal from '../modals/EditProductFieldModal';

const StyledList = styled(List)`
  padding: 0px 12px;
  width: 100%;
`;

const StyledListItem = styled(List.Item)`
  ul.ant-list-item-action {
    margin-left: 16px;
    li {
      padding: 0px 2px;
    }
  }
`;

export default function ProductFieldsEditor() {
  const actions = useActions(PaywallBuilderSlice.actions);
  const [isAddFieldModalOpen, setAddFieldModalOpen] = useState(false);
  const [isEditFieldModalOpen, setEditFieldModalOpen] = useState(false);
  const [editingField, setEditingField] = useState<string | null>(null);
  const [editingFieldVariable, setEditingFieldVariable] = useState<
    string | null
  >(null);
  const { skuVariables } = useAppSelector(({ paywallBuilder }) => {
    return {
      skuVariables:
        paywallBuilder.paywall?.template['ui.productSettings']?.variablesList ||
        [],
      template: paywallBuilder.paywall?.template || null,
    };
  });

  const skuFields: TFieldSettings[] = useMemo(() => {
    return skuVariables.reduce((output, variable) => {
      return [
        ...output,
        {
          type: 'text', //TODO - handle other types
          label: variable.display_name,
          variable: variable.variable,
        } as TFieldSettings,
      ];
    }, [] as TFieldSettings[]);
  }, [skuVariables]);

  return (
    <>
      <Form layout="vertical">
        <Row gutter={16}>
          <StyledList itemLayout="horizontal">
            {skuFields.map((field) => {
              return (
                <StyledListItem
                  key={field.variable}
                  actions={[
                    <Button
                      type="link"
                      size="small"
                      style={{ fontSize: 13 }}
                      onClick={() => {
                        setEditingField(field.label);
                        setEditingFieldVariable(field.variable);
                        setEditFieldModalOpen(true);
                      }}
                    >
                      Rename
                    </Button>,
                    <Button
                      type="link"
                      size="small"
                      style={{ fontSize: 13 }}
                      disabled={skuFields.length < 2}
                      onClick={() => {
                        actions.removeSkuVariable(field.variable);
                        setEditingField(null);
                        setEditingFieldVariable(null);
                      }}
                    >
                      Delete
                    </Button>,
                  ]}
                >
                  {field.label}
                </StyledListItem>
              );
            })}
          </StyledList>
          <Space
            direction="horizontal"
            style={{ marginLeft: 8, marginTop: 12 }}
          >
            <Button
              icon={<PlusOutlined />}
              size="small"
              onClick={() => setAddFieldModalOpen(true)}
            >
              Add Product Field
            </Button>
            <IconActionButton
              icon={<ReadOutlined style={{ fontSize: 13 }} />}
              type="text"
              target="_blank"
              href={`https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/product-fields`}
              size="small"
            >
              Learn
            </IconActionButton>
          </Space>
        </Row>
      </Form>
      {editingField && (
        <EditProductFieldModal
          isOpen={isEditFieldModalOpen}
          onClose={() => {
            setEditingField(null);
            setEditingFieldVariable(null);
            setEditFieldModalOpen(false);
          }}
          onSubmit={(value: string) => {
            editSkuFieldName(editingFieldVariable, value);
            setEditFieldModalOpen(false);
          }}
          baseValue={editingField}
        />
      )}
      <AddProductFieldModal
        isOpen={isAddFieldModalOpen}
        onClose={() => setAddFieldModalOpen(false)}
        onSubmit={(
          label: string,
          type: TProductVariableType,
          defaultValue: any
        ) => {
          addNewSkuField(label, type, defaultValue);
          setAddFieldModalOpen(false);
        }}
      />
    </>
  );

  function addNewSkuField(
    label: string,
    type: TProductVariableType,
    defaultValue: any
  ) {
    actions.addSkuVariable({
      name: label,
      value: defaultValue,
      type: type,
    });
    setAddFieldModalOpen(false);
  }

  function editSkuFieldName(variable: string | null, value: string) {
    if (variable) {
      actions.updateSkuVariableLabel({
        variable: variable,
        newValue: value,
      });
    }
  }
}
