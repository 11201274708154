import React, { ReactNode, useMemo } from 'react';

import { FilterOutlined } from '@ant-design/icons';
import { Serie } from '@nivo/line';
import { Button, Card, Statistic } from 'antd';
import { DataKey, DataKeys, SegmentData } from 'src/api/types/analytics.types';
import { MetricDataType, TData } from 'src/api/types/analytics.types';
import styled from 'styled-components';

import { parseChartData, returnDateFormat } from '../../../utils/formatting';
import { namiDarkGray, namiPrimaryBlue } from '../../../variables';
import { TInsightType } from '../insights/InsightsPage';
import SharedLineChart from '../insights/SharedLineChart';

type InsightCardProps<Data extends TData> = {
  title: string;
  metric: TInsightType;
  data: Data[];
  totalData: Data[];
  loading: boolean;
  chartKeys: DataKey[];
  urlMeta?: string;
  segment: SegmentData | null;
};

const LineWrapper = styled.div`
  height: 200px;
  width: 100%;
  min-width: 0;
`;

const TitleWrapper = styled.div`
  color: ${namiDarkGray};
  font-size: 16px;
  margin-bottom: 4px;
`;

const FilterIcon = styled(FilterOutlined)`
  color: ${namiDarkGray};
`;

const Content = styled.div`
  margin: 8px 0 0;
`;

export default function InsightCard<Data extends TData>({
  title,
  metric,
  data: rawData,
  totalData: dataRow,
  loading,
  chartKeys,
  urlMeta,
  segment,
}: InsightCardProps<Data>) {
  const dateFormat = returnDateFormat('day');
  const data: Serie[] = useMemo(() => {
    return parseChartData(rawData, chartKeys, segment, dateFormat);
  }, [rawData, chartKeys, segment, dateFormat]);

  const totalNumberRaw = dataRow.length ? dataRow[0].series[0].value : 0;
  const chartFormat: MetricDataType = DataKeys[chartKeys[0]].format;
  return (
    <Card
      style={{ minHeight: 324 }}
      bordered={false}
      className={`intercom-insight${title}`}
    >
      <Content>
        <Statistic
          title={
            <TitleWrapper>
              {title}
              {getLinkButton(metric, urlMeta)}
            </TitleWrapper>
          }
          value={formatTotalNumber(chartFormat, totalNumberRaw)}
          valueStyle={{ color: namiPrimaryBlue, fontWeight: 500 }}
          loading={loading}
        />
        {!!rawData.length && (
          <LineWrapper>
            <SharedLineChart
              data={data}
              chartStyle={'OVERVIEW'}
              chartFormat={chartFormat}
            />
          </LineWrapper>
        )}
      </Content>
    </Card>
  );

  function formatTotalNumber(
    format: MetricDataType,
    value: string | number | undefined
  ): string {
    if (value === undefined) return '';
    if (format === 'CURRENCY') {
      const params = { style: 'currency', currency: 'USD' };
      return value.toLocaleString('en-us', params);
    }
    if (format !== 'PERCENT') return value.toLocaleString('en-us');
    const itemNum = Number(value) * 100;
    return `${parseFloat(itemNum.toFixed(2))}%`;
  }

  function getLinkButton(metric: string, linkMeta?: string): ReactNode {
    return (
      <Button
        type="link"
        icon={<FilterIcon />}
        href={['/insights/', metric, linkMeta].join('')}
        style={{ float: 'right' }}
      />
    );
  }
}
