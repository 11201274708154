import * as TYPES from '../types';

const initialState = {
  loading: true,
  authenticator: {},
  authenticators: [],
  recoveryCodes: {},
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_USER_AUTHENTICATORS:
    case TYPES.GET_USER_AUTHENTICATORS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_USER_AUTHENTICATORS_SUCCESS:
      return {
        ...state,
        authenticators: action.payload.data.results,
        loading: false,
      };
    case TYPES.GET_USER_AUTHENTICATORS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.CREATE_USER_AUTHENTICATORS:
    case TYPES.CREATE_USER_AUTHENTICATORS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CREATE_USER_AUTHENTICATORS_SUCCESS:
      return {
        ...state,
        authenticator: action.payload.data,
        recoveryCodes: action.payload.data?.codes
          ? action.payload.data.codes.toString()
          : '',
        loading: false,
      };
    case TYPES.CREATE_USER_AUTHENTICATORS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.ACTIVATE_USER_AUTHENTICATOR:
    case TYPES.ACTIVATE_USER_AUTHENTICATOR_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.ACTIVATE_USER_AUTHENTICATOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TYPES.ACTIVATE_USER_AUTHENTICATOR_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.DISABLED_USER_AUTHENTICATOR:
    case TYPES.DISABLED_USER_AUTHENTICATOR_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.DISABLED_USER_AUTHENTICATOR_SUCCESS:
      return {
        ...state,
        authenticator: [],
        loading: false,
      };
    case TYPES.DISABLED_USER_AUTHENTICATOR_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
