import React from 'react';

import tinycolor from 'tinycolor2';

export const pickColor = (
  event: React.MouseEvent<HTMLCanvasElement>
): string => {
  // TODO: Write tests
  const canvas = event.target as HTMLCanvasElement;
  const rect = canvas.getBoundingClientRect();
  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;
  const context = canvas.getContext('2d')!;
  const pixel = context.getImageData(x, y, 1, 1);
  const data = pixel.data;
  const hexColor = [data[0], data[1], data[2]]
    .map((item) => item.toString(16).padStart(2, '0'))
    .join('');
  return `#${hexColor}`;
};

export const invertColor = (hex: string): string => {
  // http://stackoverflow.com/a/3943023/112731
  // TODO: Find a better name for this function
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    return '#FFFFFF';
  }
  const red = parseInt(hex.slice(0, 2), 16);
  const green = parseInt(hex.slice(2, 4), 16);
  const blue = parseInt(hex.slice(4, 6), 16);
  return red * 0.299 + green * 0.587 + blue * 0.114 > 186
    ? '#000000'
    : '#FFFFFF';
};

export const getReadableTextColor = (color: string): string => {
  return tinycolor(color).isDark() ? '#FFFFFF' : '#000000';
};
