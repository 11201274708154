import { apiRequest } from '../../services/apiService';
import * as TYPES from '../types';

export const getUserAuthenticators = () => {
  return apiRequest({
    type: TYPES.GET_USER_AUTHENTICATORS,
    url: '/private/account/authenticators/',
    method: 'GET',
  });
};

export const createUserAuthenticators = (type) => {
  return apiRequest({
    type: TYPES.CREATE_USER_AUTHENTICATORS,
    url: '/private/account/authenticators/',
    method: 'POST',
    data: { type },
  });
};

export const activateUserAuthenticator = (authenticatorId, code) => {
  return apiRequest({
    type: TYPES.ACTIVATE_USER_AUTHENTICATOR,
    url: `/private/account/authenticators/${authenticatorId}/setup_totp/`,
    method: 'POST',
    data: { code },
    options: {
      onSuccess({ next, response }) {
        const nextAction = {
          type: TYPES.ACTIVATE_USER_AUTHENTICATOR_SUCCESS,
          payload: response,
        };
        next(nextAction);
        return nextAction;
      },
    },
  });
};

export const disabledUserAuthenticator = (authenticatorId) => {
  return apiRequest({
    type: TYPES.DISABLED_USER_AUTHENTICATOR,
    url: `/private/account/authenticators/${authenticatorId}/`,
    method: 'DELETE',
    options: {
      onSuccess({ next, dispatch, response }) {
        next({
          type: TYPES.DISABLED_USER_AUTHENTICATOR_SUCCESS,
          payload: response,
        });
        dispatch(getUserAuthenticators());
      },
    },
  });
};
