import React from 'react';

import styles from './ListDots.module.less';

const ListDots = ({ children }) => {
  return (
    <ul
      className={
        React.Children.count(children) === 1 ? styles.barFirst : styles.bar
      }
    >
      {children}
    </ul>
  );
};

export default ListDots;
