import React from 'react';

import { FormItemProps } from 'antd/lib/form/FormItem';

import { getAttr } from '../../paywalls/utils/functions';

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };
type BuiltFormItemProps = WithRequired<
  Pick<FormItemProps, 'help' | 'validateStatus' | 'name'>,
  'name'
>;

export function buildFormItemProps(
  name: string[],
  errors: Record<string, any> | null | undefined,
  { topLevelError = false }: { topLevelError?: boolean } = {}
): BuiltFormItemProps {
  let help = getAttr(errors, ...name) || null;
  if (topLevelError && !!help && typeof help === 'object') {
    help = (
      <span>
        {Object.entries(help).reduce((output, [key, value]) => {
          const prefix = key === '__root__' ? '' : `Attribute "${key}": `;
          return [...output, `${prefix}${value}`];
        }, [] as string[])}
      </span>
    );
  }
  const validateStatus = !!help ? 'error' : undefined;
  return { help, name, validateStatus };
}
