import tinycolor from 'tinycolor2';

import { high, low } from './formatters';
import { isUpperCase } from './utils';

const REGEX = {
  linearGradient: /^(-(webkit|o|ms|moz)-)?(linear-gradient)/i,
  repeatingLinearGradient:
    /^(-(webkit|o|ms|moz)-)?(repeating-linear-gradient)/i,
  radialGradient: /^(-(webkit|o|ms|moz)-)?(radial-gradient)/i,
  repeatingRadialGradient:
    /^(-(webkit|o|ms|moz)-)?(repeating-radial-gradient)/i,
  sideOrCorner:
    /^to (left (top|bottom)|right (top|bottom)|top (left|right)|bottom (left|right)|left|right|top|bottom)/i,
  extentKeywords:
    /^(closest-side|closest-corner|farthest-side|farthest-corner|contain|cover)/,
  positionKeywords: /^(left|center|right|top|bottom)/i,
  pixelValue: /^(-?(([0-9]*\.[0-9]+)|([0-9]+\.?)))px/,
  percentageValue: /^(-?(([0-9]*\.[0-9]+)|([0-9]+\.?)))%/,
  emValue: /^(-?(([0-9]*\.[0-9]+)|([0-9]+\.?)))em/,
  angleValue: /^(-?(([0-9]*\.[0-9]+)|([0-9]+\.?)))deg/,
  startCall: /^\(/,
  endCall: /^\)/,
  comma: /^,/,
  hexColor: /^#([0-9a-fA-F]+)/,
  literalColor: /^([a-zA-Z]+)/,
  rgbColor: /^rgb/i,
  spacedRgbColor: /^(\d{1,3})\s+(\d{1,3})\s+(\d{1,3})\s+\/\s+([0-1](\.\d+)?)/,
  rgbaColor: /^rgba/i,
  hslColor: /^hsl/i,
  hsvColor: /^hsv/i,
  number: /^(([0-9]*\.[0-9]+)|([0-9]+\.?))/,
};

export function gradientParser(input = '') {
  const ast = matchListing(
    () =>
      matchGradient(
        'linear-gradient',
        REGEX.linearGradient,
        matchLinearOrientation
      ) ||
      matchGradient(
        'repeating-linear-gradient',
        REGEX.repeatingLinearGradient,
        matchLinearOrientation
      ) ||
      matchGradient(
        'radial-gradient',
        REGEX.radialGradient,
        matchListRadialOrientations
      ) ||
      matchGradient(
        'repeating-radial-gradient',
        REGEX.repeatingRadialGradient,
        matchListRadialOrientations
      )
  );

  if (input.length > 0) throw error('Invalid input not EOF');

  const ast0 = ast[0];
  const checkSelected = ast0?.colorStops?.filter((c) =>
    isUpperCase(c.value)
  ).length;

  if (checkSelected > 0) return ast0;
  return {
    ...ast0,
    colorStops: ast0.colorStops.map((c, i) => ({
      ...c,
      value: i === 0 ? high(c) : low(c),
    })),
  };

  function error(msg) {
    const err = new Error(input + ': ' + msg);
    err.source = input;
    return err;
  }

  function matchGradient(gradientType, pattern, orientationMatcher) {
    return matchCall(pattern, () => {
      const orientation = orientationMatcher();
      if (orientation && !scan(REGEX.comma)) {
        throw error('Missing comma before color stops');
      }

      const colorStops = matchListing(() => {
        const color =
          match('hex', REGEX.hexColor, 1) ||
          matchCall(REGEX.hslColor, convertHsl) ||
          matchCall(REGEX.rgbaColor, checkCaps) ||
          matchCall(REGEX.rgbColor, convertRgb) ||
          match('literal', REGEX.literalColor, 0) ||
          matchCall(REGEX.hsvColor, convertHsv);
        if (!color) throw error('Expected color definition');
        color.left = parseInt(matchDistance()?.value);
        return color;
      });

      return { type: gradientType, orientation, colorStops };
    });
  }

  function matchCall(pattern, callback) {
    const captures = scan(pattern);
    if (!captures) return;
    if (!scan(REGEX.startCall)) throw error('Missing (');
    const result = callback(captures);
    if (!scan(REGEX.endCall)) throw error('Missing )');
    return result;
  }

  function matchLinearOrientation() {
    return (
      match('directional', REGEX.sideOrCorner, 1) ||
      match('angular', REGEX.angleValue, 1)
    );
  }

  function matchListRadialOrientations() {
    let radialOrientation = matchRadialOrientation();

    if (!radialOrientation) return;
    const radialOrientations = [];
    radialOrientations.push(radialOrientation);

    const lookaheadCache = input;
    if (!scan(REGEX.comma)) return radialOrientations;

    radialOrientation = matchRadialOrientation();
    if (radialOrientation) {
      radialOrientations.push(radialOrientation);
    } else {
      input = lookaheadCache;
    }

    return radialOrientations;
  }

  function matchRadialOrientation() {
    let radialType = matchCircle() || matchEllipse();

    if (radialType) {
      radialType.at = matchAtPosition();
    } else {
      const extent = matchExtentKeyword();
      if (extent) {
        radialType = extent;
        const positionAt = matchAtPosition();
        if (positionAt) {
          radialType.at = positionAt;
        }
      } else {
        const defaultPosition = matchPositioning();
        if (defaultPosition) {
          radialType = {
            type: 'default-radial',
            at: defaultPosition,
          };
        }
      }
    }

    return radialType;
  }

  function matchCircle() {
    const circle = match('shape', /^(circle)/i, 0);

    if (circle) {
      circle.style = matchLength() || matchExtentKeyword();
    }

    return circle;
  }

  function matchEllipse() {
    const ellipse = match('shape', /^(ellipse)/i, 0);

    if (ellipse) {
      ellipse.style = matchDistance() || matchExtentKeyword();
    }

    return ellipse;
  }

  function matchExtentKeyword() {
    return match('extent-keyword', REGEX.extentKeywords, 1);
  }

  function matchAtPosition() {
    if (!match('position', /^at/, 0)) return;
    const positioning = matchPositioning();
    if (!positioning) throw error('Missing positioning value');
    return positioning;
  }

  function matchPositioning() {
    const location = { x: matchDistance(), y: matchDistance() };
    if (!(location.x || location.y)) return;
    return { type: 'position', value: location };
  }

  function matchListing(matcher) {
    let captures = matcher();
    const result = [];

    if (captures) {
      result.push(captures);
      while (scan(REGEX.comma)) {
        captures = matcher();
        if (!captures) throw error('One extra comma');
        result.push(captures);
      }
    }

    return result;
  }

  function convertHsl(val) {
    const capIt = isUpperCase(val?.[0]);
    const [h, s, l, alpha] = matchListing(matchNumber);
    const { r, g, b, a } = tinycolor({ h, s, l, a: alpha || 1 }).toRgb();
    return { value: `${capIt ? 'RGBA' : 'rgba'}(${r}, ${g}, ${b}, ${a})` };
  }

  function convertHsv(val) {
    const capIt = isUpperCase(val?.[0]);
    const [h, s, v, alpha] = matchListing(matchNumber);
    const { r, g, b, a } = tinycolor({ h, s, v, a: alpha || 1 }).toRgb();
    return { value: `${capIt ? 'RGBA' : 'rgba'}(${r}, ${g}, ${b}, ${a})` };
  }

  function convertRgb(val) {
    const capIt = isUpperCase(val?.[0]);
    const captures = scan(REGEX.spacedRgbColor);
    const [, r, g, b, a = 1] = captures || [null, ...matchListing(matchNumber)];
    return { value: `${capIt ? 'RGBA' : 'rgba'}(${r}, ${g}, ${b}, ${a})` };
  }

  function checkCaps(val) {
    const capIt = isUpperCase(val?.[0]);
    const value = `${capIt ? 'RGBA' : 'rgba'}(${matchListing(matchNumber)})`;
    return { value };
  }

  function matchNumber() {
    return scan(REGEX.number)[1];
  }

  function matchDistance() {
    return (
      match('%', REGEX.percentageValue, 1) ||
      match('position-keyword', REGEX.positionKeywords, 1) ||
      matchLength()
    );
  }

  function matchLength() {
    return match('px', REGEX.pixelValue, 1) || match('em', REGEX.emValue, 1);
  }

  function match(type, pattern, captureIndex) {
    const captures = scan(pattern);
    if (!captures) return;
    return { type, value: captures[captureIndex] };
  }

  function scan(regexp) {
    const blankCaptures = /^[\n\r\t\s]+/.exec(input);
    if (blankCaptures) {
      consume(blankCaptures[0].length);
    }

    const captures = regexp.exec(input);
    if (captures) {
      consume(captures[0].length);
    }

    return captures;
  }

  function consume(size) {
    input = input.substring(size);
  }
}
