import React, { Component, Fragment } from 'react';

import { Col, Row } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Dropdown, Form, Icon, Input } from 'semantic-ui-react';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';

import EmptyStateSegment from '../../../components/EmptyStateSegment/EmptyStateSegment';
import List from '../../../components/List/List';
import ListIcon from '../../../components/List/ListIcon';
import ListItem from '../../../components/List/ListItem';
import ListRecord from '../../../components/List/ListRecord';
import Loading from '../../../components/Loading/Loading';
import Page from '../../../components/Page/Page';
import Responsive from '../../../components/Responsive/Responsive';
import {
  getDeveloperAndroidNotifications,
  getDeveloperAndroidNotificationsRealTime,
  getDeveloperAppleNotifications,
  getDeveloperAppleNotificationsRealTime,
  getDeveloperEntitlements,
  getDeveloperEntitlementsRealTime,
  getDeveloperImpressions,
  getDeveloperImpressionsRealTime,
  getDeveloperRecords,
  getDeveloperRecordsRealTime,
  getDeveloperSessions,
  getDeveloperSessionsRealTime,
  getDeveloperTransactions,
  getDeveloperTransactionsRealTime,
  handleDeveloperRecord,
  handleDeveloperSearch,
  handleDropdown,
} from '../../../redux/actions/developerActions';

const appEnvironmentOptions = [
  { key: 1, text: 'Simulator Development', value: 'simulator_development' },
  { key: 2, text: 'Device Development', value: 'device_development' },
  { key: 3, text: 'Beta Testing', value: 'beta_testing' },
  { key: 4, text: 'Production', value: 'production' },
];

const purchaseEnvironmentOptions = [
  { key: 1, text: 'Sandbox', value: 'sandbox' },
  { key: 2, text: 'Production', value: 'purchaseProduction' },
];

const sdkEventOptions = [
  { key: 2, text: 'Transaction', value: 'transaction' },
  { key: 3, text: 'Impression', value: 'impression' },
];

const platformOptions = [
  { key: 1, text: 'Apple Notification', value: 'apple notification' },
  { key: 2, text: 'Android Notification', value: 'android notification' },
];

const namiOptions = [{ key: 1, text: 'Entitlement', value: 'entitlement' }];

export class DeveloperPage extends Component {
  state = {
    live: true,
  };

  setupInterval = () => {
    this.timerID = setInterval(() => {
      Promise.all([
        this.props.getDeveloperTransactionsRealTime(this.props.currentApp.id),
        this.props.getDeveloperImpressionsRealTime(this.props.currentApp.id),
        this.props.getDeveloperAppleNotificationsRealTime(
          this.props.currentApp.id
        ),
        this.props.getDeveloperAndroidNotificationsRealTime(
          this.props.currentApp.id
        ),
        this.props.getDeveloperEntitlementsRealTime(this.props.currentApp.id),
      ]).then((values) => {
        this.props.getDeveloperRecordsRealTime();
      });
    }, 10000);
  };

  componentDidMount() {
    if (this.props.currentApp) {
      Promise.all([
        this.props.getDeveloperTransactions(this.props.currentApp.id),
        this.props.getDeveloperImpressions(this.props.currentApp.id),
        this.props.getDeveloperAppleNotifications(this.props.currentApp.id),
        this.props.getDeveloperAndroidNotifications(this.props.currentApp.id),
        this.props.getDeveloperEntitlements(this.props.currentApp.id),
      ]).then((values) => {
        this.props.getDeveloperRecords();
        this.setupInterval();
      });
    }
  }

  componentWillUnmount() {
    const { handleDeveloperRecord, handleDeveloperSearch } = this.props;
    clearInterval(this.timerID);
    handleDeveloperRecord(null);
    handleDeveloperSearch('');
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.impressions.length !== prevProps.impressions.length ||
      this.props.transactions.length !== prevProps.transactions.length ||
      this.props.appleNotifications.length !==
        prevProps.appleNotifications.length ||
      this.props.entitlements.length !== prevProps.entitlements.length
    ) {
      this.props.getDeveloperRecords();
    }
  }

  renderMiddleColumn = (record) => {
    switch (record.type) {
      case 'Impression':
        return record.call_to_action_name;
      case 'Session':
        return record.device_id;
      case 'Transaction':
        return record.sku_ref_id;
      case 'Apple Notification':
        return record.notification_type;
      case 'Android Notification':
        return record.notification_type;
      case 'Entitlement':
        return record.event_type;
      default:
        return record.id;
    }
  };

  live = () => {
    const { handleDeveloperRecord } = this.props;
    clearInterval(this.timerID);
    this.setState({ live: true });
    const { handleDropdown, handleDeveloperSearch } = this.props;
    handleDropdown('', 'Event Type', 'eventType');
    handleDropdown('', 'Environment', 'environment');
    handleDeveloperSearch('');
    this.setupInterval();
    handleDeveloperRecord(null);
  };

  pause = () => {
    const { handleDeveloperRecord } = this.props;
    this.setState({ live: false });
    clearInterval(this.timerID);
    this.props.getDeveloperTransactions(this.props.currentApp.id);
    this.props.getDeveloperImpressions(this.props.currentApp.id);
    this.props.getDeveloperAppleNotifications(this.props.currentApp.id);
    this.props.getDeveloperAndroidNotifications(this.props.currentApp.id);
    this.props.getDeveloperEntitlements(this.props.currentApp.id);
    handleDeveloperRecord(null);
  };

  renderDropdownItemsSection = (array, icon, content, type) => {
    const { handleDropdown } = this.props;
    return (
      <Fragment>
        <Dropdown.Header icon={icon} content={content} />
        <Dropdown.Divider />
        {array.map((option) => (
          <Dropdown.Item
            key={option.value}
            {...option}
            onClick={() => handleDropdown(option.value, option.text, type)}
          />
        ))}
      </Fragment>
    );
  };

  renderDropdownEventType = () => {
    const { dropdownEventType, handleDropdown } = this.props;
    const { live } = this.state;
    return (
      <Form.Field>
        <label>Event Type</label>
        <Dropdown
          placeholder="Event Type"
          disabled={live}
          fluid
          button
          text={dropdownEventType}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              key={1}
              text="All"
              value=""
              onClick={() => handleDropdown('', 'All', 'eventType')}
            />
            {this.renderDropdownItemsSection(
              sdkEventOptions,
              'tags',
              'SDK',
              'eventType'
            )}
            {this.renderDropdownItemsSection(
              platformOptions,
              'tags',
              'Platform',
              'eventType'
            )}
            {this.renderDropdownItemsSection(
              namiOptions,
              'tags',
              'Nami',
              'eventType'
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Field>
    );
  };

  renderDropdownEnvironment = () => {
    const { dropdownEnvironment, handleDropdown } = this.props;
    const { live } = this.state;
    return (
      <Form.Field>
        <label>Environment</label>
        <Dropdown
          placeholder="Environment"
          disabled={live}
          fluid
          button
          text={dropdownEnvironment}
        >
          <Dropdown.Menu>
            <Dropdown.Item
              key={1}
              text="All"
              value=""
              onClick={() => handleDropdown('', 'All', 'environment')}
            />
            {this.renderDropdownItemsSection(
              appEnvironmentOptions,
              'database',
              'App Environment',
              'environment'
            )}
            {this.renderDropdownItemsSection(
              purchaseEnvironmentOptions,
              'database',
              'Purchase Environment',
              'environment'
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Field>
    );
  };

  renderForm = () => {
    const { handleDeveloperSearch } = this.props;
    const { live } = this.state;
    return (
      <Form>
        <Form.Field>
          <Button.Group>
            <Button
              className="nami-button"
              active={this.state.live}
              onClick={this.live}
              type="button"
            >
              Live
            </Button>
            <Button
              className="nami-button"
              active={!this.state.live}
              onClick={this.pause}
              type="button"
            >
              Pause
            </Button>
          </Button.Group>
        </Form.Field>
        {this.state.live && (
          <div style={{ marginBottom: 15 }}>
            <Icon color="blue" name="info circle" /> Pause the event stream to
            enable search and filters
          </div>
        )}
        <Responsive size="mdUp">
          <Form.Group widths="equal">
            <Form.Field>
              <label>Search</label>
              <Input
                placeholder="Search..."
                icon="search"
                disabled={live}
                onChange={(e) => handleDeveloperSearch(e.target.value)}
              />
            </Form.Field>
            {this.renderDropdownEventType()}
            {this.renderDropdownEnvironment()}
          </Form.Group>
        </Responsive>
        <Responsive size="mdDown">
          {!this.state.live && (
            <Form.Group widths="equal">
              <Form.Field>
                <label>Search</label>
                <Input
                  placeholder="Search..."
                  icon="search"
                  disabled={live}
                  onChange={(e) => handleDeveloperSearch(e.target.value)}
                />
              </Form.Field>
              {this.renderDropdownEventType()}
              {this.renderDropdownEnvironment()}
            </Form.Group>
          )}
        </Responsive>
      </Form>
    );
  };

  renderList = () => {
    const {
      loading,
      records,
      record,
      filtering,
      handleDeveloperRecord,
      realTime,
    } = this.props;
    const { live } = this.state;
    return (
      <Fragment>
        {!loading && records && records.length ? (
          <List code={record} onClick={() => handleDeveloperRecord(null)}>
            {records.map((item, index) => (
              <ListItem
                key={index}
                tabIndex={index}
                recent={live && item.recent}
                realTime={live && realTime}
                onClick={() => handleDeveloperRecord(item)}
              >
                <ListRecord weight="bold">
                  <ListIcon color="primary" icon={item.icon} />
                  {item.type}
                </ListRecord>
                <ListRecord>{this.renderMiddleColumn(item)}</ListRecord>
                <ListRecord textAlign="right">
                  {moment(item.frontend_date).format('YYYY/MM/DD, hh:mm:ss')}
                </ListRecord>
              </ListItem>
            ))}
          </List>
        ) : (
          <EmptyStateSegment
            title={`No results for ${filtering}.`}
            icon="sdk"
          />
        )}
      </Fragment>
    );
  };

  render() {
    const { loading, records, filtering, record } = this.props;
    let children;
    if (loading) {
      children = <Loading />;
    } else if (!records.length && !filtering) {
      children = (
        <EmptyStateSegment
          title="This app does not have any events yet."
          subtitle="Integrate the Nami SDK to start sending events."
          outBoundLink="https://learn.namiml.com/public-docs/get-started/sdks"
          icon="sdk"
        />
      );
    } else {
      children = (
        <Fragment>
          <Responsive size="mdUp">
            {this.renderForm()}
            {this.renderList()}
          </Responsive>
          <Responsive size="mdDown">
            {!record && this.renderForm()}
            {this.renderList()}
          </Responsive>
        </Fragment>
      );
    }
    return (
      <Page>
        <Row justify="start" gutter={[0, 36]}>
          <Col xs={24} style={{ paddingBottom: 18 }}>
            <Breadcrumb items={[{ name: 'Developer Feed' }]} />
          </Col>
        </Row>
        {children}
      </Page>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getDeveloperEntitlements: (appId) =>
      dispatch(getDeveloperEntitlements(appId)),
    getDeveloperTransactions: (appId) =>
      dispatch(getDeveloperTransactions(appId)),
    getDeveloperImpressions: (appId) =>
      dispatch(getDeveloperImpressions(appId)),
    getDeveloperSessions: (appId) => dispatch(getDeveloperSessions(appId)),
    getDeveloperAppleNotifications: (appId) =>
      dispatch(getDeveloperAppleNotifications(appId)),
    getDeveloperAndroidNotifications: (appId) =>
      dispatch(getDeveloperAndroidNotifications(appId)),
    getDeveloperEntitlementsRealTime: (appId) =>
      dispatch(getDeveloperEntitlementsRealTime(appId)),
    getDeveloperTransactionsRealTime: (appId) =>
      dispatch(getDeveloperTransactionsRealTime(appId)),
    getDeveloperImpressionsRealTime: (appId) =>
      dispatch(getDeveloperImpressionsRealTime(appId)),
    getDeveloperSessionsRealTime: (appId) =>
      dispatch(getDeveloperSessionsRealTime(appId)),
    getDeveloperAppleNotificationsRealTime: (appId) =>
      dispatch(getDeveloperAppleNotificationsRealTime(appId)),
    getDeveloperAndroidNotificationsRealTime: (appId) =>
      dispatch(getDeveloperAndroidNotificationsRealTime(appId)),
    getDeveloperRecords: () => dispatch(getDeveloperRecords()),
    getDeveloperRecordsRealTime: () => dispatch(getDeveloperRecordsRealTime()),
    handleDeveloperRecord: (record) => dispatch(handleDeveloperRecord(record)),
    handleDeveloperSearch: (value) => dispatch(handleDeveloperSearch(value)),
    handleDropdown: (value, text, type) =>
      dispatch(handleDropdown(value, text, type)),
  };
};

const mapStateToProps = ({ root, developer }) => {
  return {
    currentApp: root.currentApp,
    loading: developer.loading,
    entitlements: developer.entitlements,
    sessions: developer.sessions,
    impressions: developer.impressions,
    transactions: developer.transactions,
    appleNotifications: developer.appleNotifications,
    records: developer.records,
    record: developer.record,
    filtering: developer.filtering,
    dropdownEnvironment: developer.dropdownEnvironment,
    dropdownEventType: developer.dropdownEventType,
    realTime: developer.realTime,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeveloperPage);
