import { Button } from 'antd';
import { namiPrimaryBlue } from 'src/variables';
import styled from 'styled-components';

const IconActionButton = styled(Button)`
  background: unset !important;
  :hover {
    color: ${namiPrimaryBlue} !important;
    border-color: transparent;
    background: unset !important;
  }
  span:nth-child(2) {
    margin-left: 5px;
    :hover {
      text-decoration: underline;
    }
  }
`;

export default IconActionButton;
