import styled from 'styled-components';

const ResponsiveColumn = styled.div`
  width: 33.33%;
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;

  @media only screen and (max-width: 991px) and (min-width: 768px) {
    width: 50%;
    padding-top: 1rem;
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-top: 1rem;
  }
`;

export default ResponsiveColumn;
