import React from 'react';

import FilterSelect, { BaseFilterSelectProps } from './FilterSelect';
import { identifierLabel, osNameLabelMap } from './mapsAndOptions';

export default function DeviceOSSelect(props: BaseFilterSelectProps) {
  const options = Object.entries(osNameLabelMap).map(([value, label]) => {
    return { value, key: value, label: label, text: label };
  });

  return (
    <FilterSelect
      {...props}
      label={identifierLabel['Device.os_name']}
      options={options}
      operators={['i_contains', 'not_i_contains']}
      variant="select"
    />
  );
}
