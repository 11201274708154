import React from 'react';

import CodeSnippet from 'src/components/CodeSnippet/CodeSnippet';

const INDENT_1 = ' '.repeat(2);

const BUILDERS: [string, ((label: string) => string) | null][] = [
  [
    'Swift',
    (label: string): string => {
      const param = !!label ? `label: "${label}"` : '';
      return `NamiCampaignManager.launch(${param})`;
    },
  ],
  [
    'Kotlin',
    (label: string): string => {
      const param = !!label ? `, "${label}"` : '';
      return `NamiCampaignManager.launch(this${param})`;
    },
  ],
  [
    'React Native',
    (label: string): string => {
      const param = !!label ? `'${label}'` : `label`;
      return [
        `NamiCampaignManager.launch(${param}, (success, error) => {`,
        `${INDENT_1}console.log('success', success);`,
        `${INDENT_1}console.log('error', error);`,
        `});`,
      ].join('\n');
    },
  ],
  [
    'Flutter',
    (label: string): string => {
      const param = !!label ? `label: "${label}"` : '';
      return `NamiCampaignManager.launch(${param});`;
    },
  ],
  [
    'Unity',
    (label: string): string => {
      const param = !!label ? `"${label}"` : '';
      return `NamiCampaignManager.Launch(${param});`;
    },
  ],
];

export default function CampaignSnippets({ label }: { label: string }) {
  return <CodeSnippet dynamicValue={label} builders={BUILDERS} />;
}
