import { useCallback, useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { useHistory } from 'react-router';

import api from '../../api';
import { AppType } from '../../api/types/app.types';
import { TApiOptions } from '../../api/types/main.types';
import { PaywallType } from '../../api/types/paywall.types';
import {
  TPaywallTemplate,
  TRawPaywallTemplate,
} from '../../api/types/paywallTemplate.types';
import { useAppContext } from '../context.hooks';
import { useAppSelector } from '../redux.hooks';
import { useDebouncedApiOptions } from './apiOptions.hooks';
import QueryKeys from './queryKeys';
import { spreadApiOptions } from './utils';

export function usePaywallRedirect() {
  const history = useHistory();
  return useCallback(
    ({ type, id }: PaywallType) => {
      const location =
        type === 'component' ? `/paywalls/${id}/` : `/paywalls/legacy/${id}/`;
      history.push(location);
    },
    [history]
  );
}

export function usePaywallsQuery(apiOptions?: TApiOptions) {
  apiOptions = useDebouncedApiOptions(apiOptions);
  const appId = useAppSelector(({ root }) => root.currentApp.id);

  return useQuery(
    [QueryKeys.paywalls, appId, ...spreadApiOptions(apiOptions)],
    () => api.getPaywalls(appId, apiOptions),
    { keepPreviousData: true }
  );
}

export function useAllPaywallsQuery() {
  const appId = useAppSelector(({ root }) => root.currentApp.id);

  return useQuery([QueryKeys.paywalls, appId], () =>
    fetcher({ pageSize: 1000, archived: false })
  );

  async function fetcher(apiOptions: TApiOptions = {}): Promise<PaywallType[]> {
    const { results, next } = await api.getPaywalls(appId, apiOptions);
    if (!next) return results;
    const moreResults = await fetcher({ ...apiOptions, page: next });
    return results.concat(moreResults);
  }
}

export function usePaywallIdMap() {
  const paywalls = useAllPaywallsQuery().data;
  return useMemo(() => {
    return (paywalls || []).reduce((output, paywall) => {
      return { ...output, [paywall.id]: paywall };
    }, {} as Record<string, PaywallType>);
  }, [paywalls]);
}

export function useAddPaywallMutation(onSettled?: () => void) {
  const redirectToPaywall = usePaywallRedirect();
  const queryClient = useQueryClient();
  const appId = useAppSelector(({ root }) => root.currentApp.id);
  return useMutation(
    ['newPaywall'],
    ({ type, template }: { type: string; template?: TPaywallTemplate }) => {
      const name = (template?.codename || 'New') + ' Paywall';
      const payload = { name, app: appId, type, template, description: '' };
      return api.addPaywall(payload);
    },
    {
      onSettled,
      onSuccess: (paywall) => {
        redirectToPaywall(paywall);
        return queryClient.invalidateQueries([QueryKeys.paywalls]);
      },
    }
  );
}

export function useTemplateIconsQuery() {
  return useQuery([QueryKeys.templateIcons], api.getTemplateIcons);
}

export function useTemplatesQuery(
  onSuccess?: (data: TRawPaywallTemplate[]) => void
) {
  const orgId = useAppContext().selectedOrg?.id;
  return useQuery([QueryKeys.templates, orgId], api.getTemplates, {
    onSuccess,
  });
}

export function useOrgTemplatesQuery(
  onSuccess?: (data: TRawPaywallTemplate[]) => void
) {
  const orgId = useAppContext().selectedOrg?.id;
  return useQuery([QueryKeys.orgTemplates, orgId], api.getOrgTemplates, {
    onSuccess,
  });
}

export function useDeletePaywallMutation({
  onSuccess = () => {},
}: {
  onSuccess: () => void;
}) {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(
    ['deletePaywall'],
    (paywallId: string) => api.deletePaywall(paywallId),
    {
      onSuccess: () => {
        onSuccess();
        history.push('/paywalls/');
        return queryClient.invalidateQueries([QueryKeys.paywalls]);
      },
      onError: (error: any) => {
        if (!error?.delete_warnings.is_used_in_active_campaign) return;
        const message =
          'This paywall is attached to a campaign. Please remove from the campaign before you delete this paywall.';
        notification.error({ message });
      },
    }
  );
}

export function useArchivePaywallMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    ['archiveProduct'],
    ({ paywall_id, archived }: { paywall_id: string; archived: boolean }) =>
      api.updatePaywall(paywall_id, { archived: !archived }),
    {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.paywalls]),
    }
  );
}

export function useDuplicatePaywallMutation({
  paywallId,
  onSettled = () => {},
}: {
  paywallId: string;
  onSettled: () => void;
}) {
  const currentApp = useAppSelector(({ root }) => root.currentApp.id);
  const queryClient = useQueryClient();
  const redirectToPaywall = usePaywallRedirect();
  return useMutation(
    ['duplicatePaywall'],
    ({ app }: { app: AppType }) =>
      api.duplicatePaywall(paywallId, app.id).then((newPaywall) => {
        if (currentApp === newPaywall.app) {
          redirectToPaywall(newPaywall);
        } else {
          const message = `Paywall copied to ${app.name} app.`;
          notification.success({ message });
        }
      }),
    {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.paywalls]),
      onSettled,
    }
  );
}

export function useSwapPaywallMutation({
  paywallId,
  onSettled = () => {},
}: {
  paywallId: string;
  onSettled: () => void;
}) {
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation(
    ['swapPaywall'],
    async ({ newPaywallId }: { newPaywallId: string }) => {
      return api.swapPaywall(paywallId, { newPaywallId: newPaywallId });
    },
    {
      onSuccess: () => {
        notification.success({ message: 'Paywalls swapped.' });
        history.push('/paywalls/');
        return Promise.all([
          queryClient.invalidateQueries([QueryKeys.paywalls]),
          queryClient.invalidateQueries([QueryKeys.campaignRules]),
          queryClient.invalidateQueries([QueryKeys.campaignSegments]),
        ]);
      },
      onSettled,
    }
  );
}
