import React from 'react';

import { LockOutlined } from '@ant-design/icons';
import { Form, Switch } from 'antd';
import TrialEligibilityWebPaywall from 'src/components/WebPaywalls/TrialEligibilityWebPaywall';
import { useAppContext, useBooleanState } from 'src/hooks';

import {
  useActions,
  useAppSelector,
} from '../../../../../../hooks/redux.hooks';
import PaywallBuilderSlice from '../../../../../../redux/PaywallBuilderSlice';
import { UpgradeButton } from './FontSelect';
import InputLabel from './InputLabel';

export default function TrialEligiblityToggle() {
  const actions = useActions(PaywallBuilderSlice.actions);
  const trialEligibleFlag = useAppSelector(
    (root) =>
      root.paywallBuilder.paywall?.template.variables?.enableCheckForTrial
  );
  const { planHasEntitlement } = useAppContext();
  const canEdit = planHasEntitlement(
    'app.paywall.capabilities.conditional_product_trial'
  );
  const [isUpgradeOpen, openUpgrade, closeUpgrade] = useBooleanState(false);

  const key = 'var.enableCheckForTrial';

  return (
    <>
      <Form.Item
        label={
          <InputLabel name={'Turn on Free Trial Capability'} description={''} />
        }
        tooltip={
          canEdit ? null : (
            <UpgradeButton
              size="small"
              type="link"
              onClick={openUpgrade}
              icon={<LockOutlined />}
            >
              Upgrade for access
            </UpgradeButton>
          )
        }
      >
        <Switch
          checked={!!trialEligibleFlag}
          onChange={(value) => {
            actions.setTemplateVariable({ key, value });
            actions.setAnySkuHasTrialOffer(value);
            actions.setAnySkuHasIntroOffer(false);
            actions.setAnySkuHasPromoOffer(false);
          }}
          disabled={!canEdit}
        />
      </Form.Item>
      <TrialEligibilityWebPaywall
        visible={isUpgradeOpen}
        onCancel={closeUpgrade}
      />
    </>
  );
}
