import { client } from './clients';
import { TApiOptions, TListResult } from './types/main.types';
import {
  PaywallType,
  TComponentPaywallPayload,
  TPaywallMedia,
  TPaywallMediaPayload,
  TPaywallMenu,
  TPaywallPayload,
  TTemplateIcon,
} from './types/paywall.types';
import {
  TRawPaywallTemplate,
  TTemplateMetadata,
} from './types/paywallTemplate.types';
import {
  buildForm,
  buildURLParams,
  extractErrorData,
  extractListResponseData,
  extractResponseData,
} from './utils';

const paywalls = {
  getPaywalls: (
    appId: string,
    apiOptions: TApiOptions = {}
  ): Promise<TListResult<PaywallType>> => {
    const params = buildURLParams({ pageSize: 50, ...apiOptions, appId });
    return client
      .get(`/private/paywalls/?${params}`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getPaywall: (paywallId: string): Promise<PaywallType> =>
    client
      .get(`/private/paywalls/${paywallId}/`)
      .then(extractResponseData)
      .catch(extractErrorData),
  getTemplates: (): Promise<TRawPaywallTemplate[]> =>
    fetchTemplates().then(extractListResponseData).catch(extractErrorData),
  getOrgTemplates: (): Promise<TRawPaywallTemplate[]> => {
    const params = buildURLParams({ pageSize: 100 });
    return client
      .get(`/private/org_paywall_templates/?${params}`)
      .then(extractListResponseData)
      .catch(extractErrorData);
  },
  getTemplateMetadata: (): Promise<TTemplateMetadata> =>
    // TODO: Find a way to store this locally in a way that makes sense
    fetchTemplates()
      .then(({ data }) => ({
        groupIcons: data.group_icons,
        orderedGroups: data.ordered_groups,
      }))
      .catch(extractErrorData),
  getPaywallMenus: (
    paywallId: string,
    apiOptions: TApiOptions = {}
  ): Promise<TPaywallMenu[]> => {
    const params = buildURLParams({ pageSize: 50, ...apiOptions });
    return client
      .get(`/private/paywall_menus/?paywall_id=${paywallId}&${params}`)
      .then(extractListResponseData)
      .catch(extractErrorData);
  },
  updatePaywallMenu: (menuId: string, data: any): Promise<PaywallType> =>
    client
      .patch(`/private/paywall_menus/${menuId}/`, data)
      .then(extractResponseData)
      .catch(extractErrorData),
  updatePaywall: (
    paywallId: string,
    paywall: Partial<TPaywallPayload>
  ): Promise<PaywallType> =>
    client
      .patch(`/private/paywalls/${paywallId}/`, paywall)
      .then(extractResponseData)
      .catch(extractErrorData),
  deletePaywall: (paywallId: string): Promise<void> =>
    client
      .delete(`/private/paywalls/${paywallId}/`)
      .catch(extractErrorData) as unknown as Promise<void>,
  addPaywall: (
    data: TComponentPaywallPayload | TPaywallPayload
  ): Promise<PaywallType> =>
    client
      .post('/private/paywalls/', data)
      .then(extractResponseData)
      .catch(extractErrorData),
  duplicatePaywall: (paywallId: string, app?: string): Promise<PaywallType> =>
    client
      .post(`/private/paywalls/${paywallId}/duplicate/`, { app })
      .then(extractResponseData)
      .catch(extractErrorData),
  swapPaywall: (
    paywallId: string,
    { newPaywallId }: { newPaywallId: string }
  ): Promise<void> => {
    const data = { new_paywall_id: newPaywallId };
    return client
      .post(`/private/paywalls/${paywallId}/swap/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getPaywallMedia: (paywallId: string): Promise<TPaywallMedia[]> =>
    client
      .get(`/private/paywall_media/?paywall_id=${paywallId}`)
      .then(extractListResponseData)
      .catch(extractErrorData),
  updatePaywallMedia: (
    mediaId: string,
    data: Partial<TPaywallMediaPayload>
  ): Promise<TPaywallMedia> => {
    const payload =
      typeof data.content !== 'string' && !!data.content
        ? buildForm(data)
        : data;
    return client
      .patch(`/private/paywall_media/${mediaId}/`, payload)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  addPaywallMedia: (data: TPaywallMediaPayload): Promise<TPaywallMedia> => {
    const payload =
      typeof data.content !== 'string' && !!data.content
        ? buildForm(data)
        : data;
    return client
      .post(`/private/paywall_media/`, payload)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getTemplateIcons: (): Promise<TTemplateIcon[]> =>
    client
      .get(`/private/paywall_templates/icons/`)
      .then(extractListResponseData)
      .catch(extractErrorData),
};

export default paywalls;

function fetchTemplates() {
  return client.get('/private/paywall_templates/');
}
