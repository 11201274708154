import React, { useEffect, useMemo, useState } from 'react';

import { LaunchOutlined } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import {
  Alert,
  Button,
  Divider,
  Form,
  Select,
  Skeleton,
  Space,
  Switch,
  Typography,
  notification,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import api from 'src/api';
import {
  TCDPIntegrationLinks,
  TOrgCDPIntegrationDocLinks,
} from 'src/api/types/main.types';
import { useAppContext, useBooleanState, usePageContext } from 'src/hooks';
import {
  useAppCDPIntegrationQuery,
  useGetAppCDPIntegrationLinks,
  useGetCDPIdentityScopeQuery,
} from 'src/hooks/queries/integration.hooks';
import QueryKeys from 'src/hooks/queries/queryKeys';
import { toReadable } from 'src/utils/string';
import styled from 'styled-components';

import { TIntegrationParams } from './params.types';

export const LinkButtonNoLRPadding = styled(Button)`
  padding: 4px 0px !important;
`;

const HeaderTitle = styled.span`
  font-size: 1.3rem;
  font-weight: 500;
`;

export default function CDPForm() {
  const history = useHistory();
  const { type, integrationId: streamId } = useParams<TIntegrationParams>();
  const [form] = Form.useForm();
  const [isLoading, startLoading, stopLoading] = useBooleanState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [hasScope, setHasScope] = useState(false);
  const [externalIDScopeLink, setExternalIdScopeLink] =
    useState<TCDPIntegrationLinks | null>(null);
  const [cdpIDScopeLink, setCDPIdScopeLink] =
    useState<TCDPIntegrationLinks | null>(null);
  const queryClient = useQueryClient();

  const cdpIntegrationQuery = useAppCDPIntegrationQuery(streamId || '');
  const scopeQuery = useGetCDPIdentityScopeQuery();
  const linksQuery = useGetAppCDPIntegrationLinks(streamId || '');

  const { userHasEntitlement } = useAppContext();
  const { setTitle } = usePageContext();
  const canUpdate = userHasEntitlement('app.platform.update');

  const integration = useMemo(() => {
    return cdpIntegrationQuery.data;
  }, [cdpIntegrationQuery]);

  useEffect(() => {
    if (scopeQuery.isFetching) return;
    setHasScope(!!scopeQuery.data?.length);
  }, [scopeQuery]);

  useEffect(() => {
    setTitle(integration?.display_name || type);
  }, [type, integration, setTitle]);

  useEffect(() => {
    if (!integration) return;
    form.setFieldsValue({
      ...integration,
      external_id: externalIDScopeLink?.scope,
      cdp_id: cdpIDScopeLink?.scope,
    });
    setIsEnabled(integration?.enabled);
  }, [integration, form, externalIDScopeLink, cdpIDScopeLink]);

  const scopeOptions = useMemo(() => {
    return (scopeQuery.data || []).map((scope) => {
      return {
        label: toReadable(scope.name),
        value: scope.id,
      };
    });
  }, [scopeQuery.data]);

  useEffect(() => {
    const ecidMatch = (linksQuery.data || []).filter(
      (link) => link.type === 'external_id'
    );
    const cdpIdMatch = (linksQuery.data || []).filter(
      (link) => link.type === 'cdp_id'
    );

    if (ecidMatch && ecidMatch.length > 0) {
      setExternalIdScopeLink(ecidMatch[0]);
    }
    if (cdpIdMatch && cdpIdMatch.length > 0) {
      setCDPIdScopeLink(cdpIdMatch[0]);
    }
  }, [linksQuery]);

  return (
    <Form
      form={form}
      style={{ width: '100%', height: '100%' }}
      labelCol={{ span: 4 }}
    >
      <Space direction="vertical">
        <HeaderTitle>Settings</HeaderTitle>
        <LinkButtonNoLRPadding
          type="text"
          icon={
            <LaunchOutlined
              style={{
                fontSize: 16,
                transform: 'translateY(3px)',
                marginRight: 4,
              }}
            />
          }
          onClick={() => history.push('/settings?tab=org-integrations')}
        >
          Configure integration under Org Settings
        </LinkButtonNoLRPadding>
      </Space>
      <Divider />
      <Form.Item label="Enable Integration" name="enabled">
        <Switch
          size="small"
          checked={isEnabled}
          onChange={() => setIsEnabled(!isEnabled)}
        />
      </Form.Item>
      {linksQuery.isFetching || scopeQuery.isFetching ? (
        <Skeleton active />
      ) : (
        <>
          <Typography.Text style={{ fontWeight: 500 }}>
            Identifier Mapping
          </Typography.Text>
          {hasScope && (
            <>
              <Form.Item
                label="Nami External ID"
                name="external_id"
                tooltip={{
                  title:
                    'Which id in your CDP corresponds to the Nami external ID? Nami external ID is set when logging in users.',
                }}
              >
                <Select options={scopeOptions} />
              </Form.Item>
              <Form.Item
                label="Nami CDP ID"
                name="cdp_id"
                tooltip={{
                  title:
                    'Which id in your CDP corresponds to the Nami CDP ID? Nami CDP ID is set when user launches the app',
                }}
              >
                <Select options={scopeOptions} />
              </Form.Item>
            </>
          )}
          {!hasScope && integration && integration.provider && (
            <Alert
              type="warning"
              closable={false}
              banner
              message={
                <Space direction="horizontal" size="small">
                  <span>
                    Send some test data to this integration to finish setup.
                  </span>
                  <a
                    href={TOrgCDPIntegrationDocLinks[integration.provider]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn More.
                  </a>
                </Space>
              }
              style={{ marginTop: 12, marginBottom: 24 }}
            />
          )}
        </>
      )}
      <Space style={{ marginBottom: '2rem' }}>
        <Button
          onClick={onSave}
          disabled={!canUpdate}
          loading={isLoading}
          className="nami-primary-button"
        >
          Save Changes
        </Button>
      </Space>
    </Form>
  );

  function onSave() {
    if (!integration) return;
    startLoading();

    const newExternalIdScope = form.getFieldValue('external_id');
    const newCdpIDScope = form.getFieldValue('cdp_id');

    let promises: Promise<any>[] = [];

    promises.push(
      api.updateAppCDPIntegration(integration?.id, {
        ...form.getFieldsValue(),
        enabled: isEnabled,
      })
    );

    //Create new integration links if necessary
    if (!externalIDScopeLink && newExternalIdScope) {
      //Create integration link for external id
      promises.push(
        api.createCDPIntegrationLink({
          scope: newExternalIdScope,
          type: 'external_id',
          activation: integration?.id,
        })
      );
    }
    if (!cdpIDScopeLink && newCdpIDScope) {
      //Create integration link for external id
      promises.push(
        api.createCDPIntegrationLink({
          scope: newCdpIDScope,
          type: 'cdp_id',
          activation: integration?.id,
        })
      );
    }

    //Update integration links if necessary
    if (
      externalIDScopeLink &&
      newExternalIdScope &&
      externalIDScopeLink.scope !== newExternalIdScope
    ) {
      promises.push(
        api.updateCDPIntegrationLink(externalIDScopeLink.id, {
          scope: newExternalIdScope,
          type: 'external_id',
          activation: integration?.id,
        })
      );
    }
    if (
      cdpIDScopeLink &&
      newCdpIDScope &&
      cdpIDScopeLink.scope !== newCdpIDScope
    ) {
      promises.push(
        api.updateCDPIntegrationLink(cdpIDScopeLink.id, {
          scope: newCdpIDScope,
          type: 'cdp_id',
          activation: integration?.id,
        })
      );
    }

    Promise.all(promises)
      .then(() => {
        notification.success({ message: 'CDP Integration updated.' });
        queryClient.invalidateQueries([QueryKeys.appCDPIntegrations, streamId]);
      })
      .finally(() => stopLoading());
  }
}
