import { Form } from 'antd';
import styled from 'styled-components';

const CredentialsForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 12px;
  }
`;

export default CredentialsForm;
