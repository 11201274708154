import React from 'react';

import PropTypes from 'prop-types';

import convert from '../../../../../utils/conversion';
import ConvertedNumberInput from './ConvertedNumberInput';

const MinutesInput = ({ label, name, value, onChange, error }) => {
  return (
    <ConvertedNumberInput
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      placeholder="Minutes"
      error={error}
      displayConverter={convert.secondsToMinutes}
      valueConverter={convert.minutesToSeconds}
    />
  );
};

MinutesInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};

export default MinutesInput;
