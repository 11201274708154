import { useContext } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from '../../api';
import { TApiOptions, TFont } from '../../api/types/main.types';
import { AppContext } from '../../AppContextProvider';
import { loadFonts } from '../../utils/fonts';
import { useDebouncedApiOptions } from './apiOptions.hooks';
import QueryKeys from './queryKeys';
import { spreadApiOptions } from './utils';

export function useFontsQuery() {
  const { userHasEntitlement } = useContext(AppContext);
  return useQuery(
    [QueryKeys.fonts],
    async () => {
      const fonts = await api.getFonts();
      await loadFonts(fonts);
      return fonts;
    },
    { enabled: userHasEntitlement('app.paywall.capabilities.custom_font') }
  );
}

export function useOrgFontsQuery(apiOptions?: TApiOptions) {
  apiOptions = useDebouncedApiOptions(apiOptions);
  const { userHasEntitlement } = useContext(AppContext);

  return useQuery(
    [QueryKeys.orgFonts, ...spreadApiOptions(apiOptions)],
    async () => {
      const response = await api.getOrgFonts(apiOptions);
      await loadFonts(response.results);
      return response;
    },
    { enabled: userHasEntitlement('font.list') }
  );
}

export function useDeleteFontsMutation(fonts: TFont[]) {
  const queryClient = useQueryClient();

  return useMutation(
    ['deleteFont'],
    (): Promise<void[]> =>
      Promise.all(fonts.map((font) => api.deleteFont(font.id))),
    {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.orgFonts]),
    }
  );
}
