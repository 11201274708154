import React from 'react';

import { EditOutlined } from '@ant-design/icons';
import { Button, Space, Table, Typography } from 'antd';
import { useHistory } from 'react-router-dom';

import { TApiOptions } from '../../../../api/types/main.types';
import { useAppContext } from '../../../../hooks';
import { useLegacyCampaignsQuery } from '../../../../hooks/queries/campaign.hooks';
import { LiveIcon, PausedIcon } from '../utils/statusIcons';

type LegacyCampaignPageProps = {
  liveOnly: boolean;
  apiOptions: TApiOptions;
  onApiOptionsChange: React.Dispatch<React.SetStateAction<TApiOptions>>;
};

export default function LegacyCampaignTable({
  liveOnly,
  apiOptions,
  onApiOptionsChange,
}: LegacyCampaignPageProps) {
  const history = useHistory();
  const campaignsQuery = useLegacyCampaignsQuery({
    ...apiOptions,
    ...(liveOnly ? { active: true } : {}),
  });
  const appContext = useAppContext();

  const campaigns = campaignsQuery.data?.results || [];

  return (
    <Table
      loading={campaignsQuery.isFetching}
      dataSource={campaigns}
      columns={[
        {
          title: <Typography.Text strong>Status</Typography.Text>,
          dataIndex: 'is_active',
          ellipsis: true,
          responsive: ['sm'],
          render: (live) =>
            live ? (
              <Space>
                <LiveIcon />
                Live
              </Space>
            ) : (
              <Space>
                <PausedIcon />
                Paused
              </Space>
            ),
        },
        {
          title: <Typography.Text strong>Name</Typography.Text>,
          ellipsis: true,
          render: (campaign) => (
            <Space>
              <Typography.Text>{campaign.name}</Typography.Text>
            </Space>
          ),
        },
        {
          title: <Typography.Text strong>Actions</Typography.Text>,
          ellipsis: true,
          render: ({ id }) => (
            <Space>
              <Button
                type="default"
                icon={<EditOutlined />}
                disabled={!appContext.userHasEntitlement('app.campaign.get')}
                onClick={() => history.push(`/campaigns/legacy/${id}/`)}
              />
            </Space>
          ),
        },
      ]}
      pagination={{
        total: campaignsQuery.data?.count,
        pageSize: apiOptions.pageSize,
        onChange: (page, pageSize) =>
          onApiOptionsChange((state) => ({ ...state, page, pageSize })),
        current: campaignsQuery.data?.page_number,
        showSizeChanger: false,
        style: { paddingBottom: 72 },
        showTotal: (total, [n1, n2]) => `${n1} - ${n2} of ${total}`,
      }}
      style={{ cursor: 'default' }}
    />
  );
}
