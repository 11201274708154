import React, { useMemo, useState } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import {
  Button,
  Image,
  Modal,
  Popconfirm,
  Select,
  Space,
  Typography,
} from 'antd';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import { platformLogo } from 'src/services/helpers';

type SelectPlatformsToEditModalProps = {
  onClose: () => void;
  open: boolean;
  onFinish: (groupId: string[]) => void;
};

const selector = createSelector(
  [
    ({ paywallBuilder: { platforms } }: RootState) => platforms,
    ({ paywallBuilder: { skuItems } }: RootState) => skuItems,
  ],
  (platforms, skuItems) => ({
    platforms,
    skuItems,
  })
);

export default function SelectPlatformsToEditModal({
  onClose,
  open,
  onFinish,
}: SelectPlatformsToEditModalProps) {
  const { platforms, skuItems } = useAppSelector(selector);

  const platformOptions = platforms.map((platform) => {
    return {
      label: (
        <Space>
          <Image
            height={13}
            width={13}
            preview={false}
            src={platformLogo(platform.type)}
          />
          {platform.name}
        </Space>
      ),
      value: platform.id,
      key: platform.id,
      text: platform.name,
      type: platform.type,
    };
  });
  const [selectedPlatforms, setSelectedPlatforms] = useState<Array<string>>([]);

  const countProducts = useMemo(() => {
    let count = 0;
    for (const group in skuItems) {
      for (const platform in skuItems[group]) {
        if (selectedPlatforms.includes(platform)) {
          count = count + Object.keys(skuItems[group][platform]).length;
        }
      }
    }
    return count;
  }, [selectedPlatforms, skuItems]);

  return (
    <Modal
      title="Apply Edits to Selected Platforms"
      open={open}
      footer={null}
      centered
      zIndex={1002}
      onCancel={onClose}
      destroyOnClose={true}
    >
      <Space direction="vertical" style={{ width: '100%' }} size="middle">
        <Typography.Text>
          Choose platforms to apply edits to only products in these groups.
        </Typography.Text>
        <Select
          options={platformOptions}
          mode="multiple"
          placeholder="Select platforms"
          onChange={setSelectedPlatforms}
          style={{ width: '100%' }}
          showArrow={true}
          maxTagCount={4}
          filterOption={(input, option) =>
            (option?.text || '').toLowerCase().includes(input.toLowerCase()) ||
            (option?.type || '').toLowerCase().includes(input.toLowerCase())
          }
        />
        <Popconfirm
          title={`Are you sure you want to apply these changes to ${countProducts} products?`}
          onConfirm={() => {
            onFinish(selectedPlatforms);
          }}
          okText="Yes"
          cancelText="No"
        >
          <Button type="primary" disabled={!selectedPlatforms.length}>
            Apply Edits
          </Button>
        </Popconfirm>
      </Space>
    </Modal>
  );
}
