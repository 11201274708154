import React from 'react';

import { CopyOutlined, SyncOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  ConfigProvider,
  Form,
  Input,
  List,
  Modal,
  Row,
  Space,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import moment from 'moment';
import { TOrgCDPIntegration } from 'src/api/types/main.types';
import Loading from 'src/components/Loading/Loading';
import { useAppContext } from 'src/hooks';
import {
  useCDPIntegrationQuery,
  useCDPIntegrationTokenQuery,
  useCreateCDPIntegrationTokenQuery,
  useEditCDPIntegrationMutation,
  useRevokeCDPIntegrationTokenQuery,
} from 'src/hooks/queries/org.hooks';
import { copyTextToClipBoard } from 'src/services/helpers';
import styled from 'styled-components';

type OrgIntegrationModalProps = {
  id: string;
  open: boolean;
  onClose: () => void;
};

const CopyButton = styled(Button).attrs({ children: 'Copy' })`
  border: none;
  height: 100%;
  width: 100%;
  border-radius: 0;
  border-end-end-radius: 1px;
  border-start-end-radius: 1px;

  .ant-input-group-addon:has(&) {
    padding: 0;
  }
`;

const CodeStyledText = styled(Typography.Text)`
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
`;

const CodeStyledTextArea = styled(Input.TextArea)`
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
  font-size: small;
`;

export default function OrgIntegrationModal({
  id,
  open,
  onClose,
}: OrgIntegrationModalProps) {
  const context = useAppContext();
  const integration = useCDPIntegrationQuery(id);
  const updateIntegrationMutation = useEditCDPIntegrationMutation(id);
  const tokens = useCDPIntegrationTokenQuery(id);
  const createTokenMutation = useCreateCDPIntegrationTokenQuery(
    id,
    openTokenModal
  );
  const revokeTokenMutation = useRevokeCDPIntegrationTokenQuery(id);
  const [form] = Form.useForm<TOrgCDPIntegration>();
  return (
    <Modal
      open={open}
      centered={true}
      onCancel={onClose}
      title={
        integration.isLoading
          ? `Loading`
          : `Edit ${integration.data?.display_name}`
      }
      destroyOnClose
      okButtonProps={{
        disabled: !context.userHasEntitlement('org.cdp_integration.update'),
      }}
      onOk={() => {
        form
          .validateFields()
          .then(() =>
            updateIntegrationMutation.mutate({
              display_name: form.getFieldValue('display_name'),
            })
          )
          .then(() => onClose());
      }}
    >
      {integration.isRefetching || integration.isLoading ? (
        <Loading />
      ) : (
        <Form
          layout="vertical"
          form={form}
          initialValues={{ ...integration.data }}
          requiredMark={false}
        >
          <Form.Item
            name="display_name"
            label="Integration Name"
            rules={[
              {
                required: true,
                message: 'Please provide a value',
              },
              {
                max: 255,
                message: 'Please provide a shorter name.',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Delivery URL" name="url">
            <Input
              readOnly={true}
              addonAfter={
                <CopyButton
                  disabled={!integration.data?.url}
                  onClick={copyURLValue}
                />
              }
            />
          </Form.Item>
          <Form.Item label="Tokens">
            <ConfigProvider renderEmpty={() => <>No tokens found</>}>
              <List
                bordered
                size="small"
                dataSource={tokens.data || []}
                renderItem={(item) => (
                  <List.Item>
                    <Row style={{ width: '100%' }}>
                      <Col sm={20}>
                        <Space direction="horizontal" size="small">
                          <CodeStyledText>••••••••••••••</CodeStyledText>
                          <Tooltip
                            title={moment(item.iat).format('YYYY-MM-DD h:mm A')}
                          >
                            <Typography.Text>
                              Created {moment(item.iat).fromNow()}
                            </Typography.Text>
                          </Tooltip>
                        </Space>
                      </Col>
                      <Col sm={4}>
                        <Button
                          size="small"
                          style={{ float: 'right' }}
                          onClick={() =>
                            revokeTokenMutation.mutate({ tokenJTI: item.jti })
                          }
                          disabled={
                            !context.userHasEntitlement(
                              'org.cdp_integration.update'
                            )
                          }
                        >
                          Revoke
                        </Button>
                      </Col>
                    </Row>
                  </List.Item>
                )}
                footer={
                  <Button
                    size="small"
                    icon={<SyncOutlined />}
                    onClick={() => createTokenMutation.mutate()}
                    disabled={
                      !context.userHasEntitlement('org.cdp_integration.update')
                    }
                  >
                    Generate New Token
                  </Button>
                }
              />
            </ConfigProvider>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );

  function openTokenModal(token: string) {
    Modal.info({
      title: 'New Integration Token',
      className: 'special-modal-confirm',
      content: (
        <>
          <Typography.Paragraph>
            Store this token in a safe location. You will not be able to view
            this token again. If you lose your token, you will need to
            regenerate the token and replace in your code.
          </Typography.Paragraph>
          <CodeStyledTextArea
            readOnly
            value={token}
            autoSize={{ minRows: 5, maxRows: 15 }}
            style={{ marginBottom: 12 }}
          />
          <Button
            onClick={() =>
              copyTextToClipBoard(token).then(() =>
                notification.success({ message: `Copied Token to clipboard` })
              )
            }
            icon={<CopyOutlined />}
          >
            Copy Token
          </Button>
        </>
      ),
      icon: undefined,
      closable: false,
    });
  }

  function copyURLValue() {
    const message = `Copied Delivery URL to clipboard`;
    copyTextToClipBoard(integration.data?.url).then(() =>
      notification.success({ message })
    );
  }
}
