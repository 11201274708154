import React from 'react';

import { ReadOutlined, ToolOutlined } from '@ant-design/icons';
import { Button, Select, Tooltip } from 'antd';
import { TOfferState } from 'src/api/types/paywall.types';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { namiLightGray, namiSecondaryBlue } from 'src/variables';
import styled from 'styled-components';

import { getPricingStateOptions } from '../utils/functions';

type ProductOfferStatePickerProps = {
  includeIntroPromo: boolean;
  toggleEligibility: boolean;
};
const TooltipLinkButton = styled(Button)`
  color: ${namiLightGray};
  :hover {
    color: ${namiSecondaryBlue};
  }
  padding-left: 0px;
`;

export default function ProductOfferStatePicker({
  includeIntroPromo,
  toggleEligibility,
}: ProductOfferStatePickerProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const [
    anySkuHasIntroOffer,
    anySkuHasPromoOffer,
    anySkuHasTrialOffer,
    enableCheckForOffers,
    enableCheckForTrial,
  ] = useAppSelector(({ paywallBuilder }) => {
    return [
      paywallBuilder.anySkuHasIntroOffer,
      paywallBuilder.anySkuHasPromoOffer,
      paywallBuilder.anySkuHasTrialOffer,
      paywallBuilder.paywall?.template.variables?.enableCheckForOffers,
      paywallBuilder.paywall?.template.variables?.enableCheckForTrial,
    ];
  });

  const offerOptions = getPricingStateOptions(includeIntroPromo);
  return (
    <Tooltip
      title={getTooltipContent()}
      mouseEnterDelay={0.6}
      placement="right"
    >
      <Select
        options={offerOptions}
        value={pickPricingStateValue()}
        size="small"
        style={{ width: 164 }}
        dropdownMatchSelectWidth={false}
        placeholder="Pick Eligibility"
        onChange={changeOfferState}
        disabled={!canEdit()}
      />
    </Tooltip>
  );

  function getTooltipContent(): JSX.Element {
    if (toggleEligibility) {
      return (
        <TooltipLinkButton
          type="link"
          size="small"
          icon={<ToolOutlined />}
          onClick={() => actions.setEditingMenuItem('Capabilities')}
        >{`Turn on ${
          includeIntroPromo ? 'promo' : 'trial'
        } capability to preview`}</TooltipLinkButton>
      );
    }

    return (
      <>
        {`Pick ${includeIntroPromo ? 'promo' : 'trial'} eligibility to preview`}
        <TooltipLinkButton
          type="link"
          href="https://learn.namiml.com/public-docs/no-code-paywalls/pricing-eligibility-states"
          target="_blank"
          size="small"
          icon={<ReadOutlined />}
        >
          Learn more
        </TooltipLinkButton>
      </>
    );
  }

  function canEdit() {
    if (toggleEligibility) {
      if (includeIntroPromo) return enableCheckForOffers;
      return enableCheckForTrial;
    }
    return true;
  }

  function changeOfferState(offer: TOfferState) {
    actions.setAnySkuHasTrialOffer(offer === 'trial');
    actions.setAnySkuHasIntroOffer(offer === 'intro');
    actions.setAnySkuHasPromoOffer(offer === 'promo');
  }

  function pickPricingStateValue(): TOfferState {
    return anySkuHasTrialOffer
      ? 'trial'
      : anySkuHasIntroOffer
      ? 'intro'
      : anySkuHasPromoOffer
      ? 'promo'
      : 'standard';
  }
}
