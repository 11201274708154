import { useEffect, useState } from 'react';

export function useDebouncedValue<V extends any>(value: V, delay: number): V {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    if (debouncedValue === value) return;
    const timeoutId = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, delay]);

  return debouncedValue;
}
