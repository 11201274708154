import React, { useEffect, useState } from 'react';

import { Card, Col, Row } from 'antd';
import { useHistory } from 'react-router';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import { useAppSelector } from 'src/hooks/redux.hooks';

import Page from '../../../components/Page/Page';
import { useAppContext, useQueryParams } from '../../../hooks';
import GeneralTab from './general/GeneralTab';
import Languages from './tabs/languages';
import Legal from './tabs/legal';

const TAB_ENTITLEMENTS_MAP: Record<string, string[]> = {
  general: [],
  legal: [],
  languages: ['app.locale_config.list'],
};

export default function AppSettingsPage() {
  const context = useAppContext();
  const currentApp = useAppSelector(({ root }) => root.currentApp);
  const history = useHistory();
  const currentTab = useQueryParams().get('tab');
  const [tab, setTab] = useState(currentTab || 'general');

  useEffect(() => {
    if (!currentTab) return;
    const hasAccess = (TAB_ENTITLEMENTS_MAP[currentTab] || []).every(
      context.userHasEntitlement
    );
    if (hasAccess) {
      setTab(currentTab);
    } else {
      history.push(`/app-settings/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeTab(key: string) {
    history.push(`/app-settings/?tab=${key}`);
    setTab(key);
  }

  const CONTENTS: Record<string, JSX.Element> = {
    general: <GeneralTab />,
    legal: (
      <Legal
        appId={currentApp.id}
        defaultLanguage={currentApp.default_language}
      />
    ),
    languages: (
      <Languages
        appId={currentApp.id}
        defaultLanguage={currentApp.default_language}
      />
    ),
  };

  const tabList = [
    {
      key: 'general',
      tab: 'General',
      enabled: true,
    },
    {
      key: 'legal',
      tab: 'Legal',
      enabled: context.planHasEntitlement('app.legalcitation.list'),
    },
    {
      key: 'languages',
      tab: 'Languages',
      enabled: context.planHasEntitlement('app.locale_config.list'),
    },
  ].filter((tab) => tab.enabled);

  return (
    <Page title="App Settings">
      <Row justify="start" gutter={[0, 36]}>
        <Col xs={24} style={{ paddingBottom: 12 }}>
          <Breadcrumb items={[{ name: 'App Settings' }]} />
        </Col>
      </Row>
      <Card
        tabList={tabList}
        activeTabKey={tab}
        defaultActiveTabKey={tab}
        onTabChange={onChangeTab}
      >
        {CONTENTS[tab] || null}
      </Card>
    </Page>
  );
}
