import React from 'react';

import { PlanMeta, TDisplayProduct } from 'src/api/types/main.types';

import UpgradeWebPaywall, { WebPaywallProps } from './UpgradeWebPaywall';

type MetricsWebPaywallProps = WebPaywallProps & {
  entitlement: string;
};

const MetricsWebPaywall = ({
  onCancel,
  visible,
  entitlement,
}: MetricsWebPaywallProps) => {
  const paywallsOnlyProducts: TDisplayProduct[] = [
    {
      product_value_props: ['MRR Charts', 'Basic filters', '7 metrics'],
      ...PlanMeta['paywalls_only_starter_v1'],
    },
    {
      product_value_props: [
        'Trials and Trial Conversion Rate',
        'Filter by product, trial length, and more',
        'CSV download of metrics',
      ],
      ...PlanMeta['paywalls_only_team_v1'],
    },
    {
      product_value_props: ['Advanced filters', 'ARPU Charts'],
      ...PlanMeta['paywalls_only_business_v1'],
    },
  ];

  const paywallsAndSubsProducts: TDisplayProduct[] = [
    {
      product_value_props: ['MRR Charts', 'Basic filters', '7 metrics'],
      ...PlanMeta['paywalls_and_subs_starter_v1'],
    },
    {
      product_value_props: [
        'Subscriber charts',
        'Trials and Trial Conversion Rate',
        'Filter by product, trial length, and more',
        'CSV download of metrics',
      ],
      ...PlanMeta['paywalls_and_subs_team_v1'],
    },
    {
      product_value_props: ['Advanced filters', 'ARPU charts'],
      ...PlanMeta['paywalls_and_subs_business_v1'],
    },
  ];

  return (
    <UpgradeWebPaywall
      modalTitle="Unlock API Access"
      productsPaywallsOnly={paywallsOnlyProducts}
      productsPaywallsAndSubs={paywallsAndSubsProducts}
      highlightedProductPaywallsOnly="paywalls_only_team_v1"
      highlightedProductPaywallsAndSubs="paywalls_and_subs_team_v1"
      entitlement={entitlement}
      onCancel={onCancel}
      visible={visible}
    />
  );
};

export default MetricsWebPaywall;
