import * as TYPES from '../types';

export const toggleNewAppModal = () => {
  return {
    type: TYPES.TOGGLE_NEW_APP_MODAL,
  };
};

export const toggleNewProductModal = () => {
  return {
    type: TYPES.TOGGLE_NEW_PRODUCT_MODAL,
  };
};

export const toggleNewEntitlementModal = () => {
  return {
    type: TYPES.TOGGLE_NEW_ENTITLEMENT_MODAL,
  };
};

export const toggleNewProductAssociateModal = () => {
  return {
    type: TYPES.TOGGLE_NEW_PRODUCT_ASSOCIATE_MODAL,
  };
};

export const toggleNewKeyValueModal = () => {
  return {
    type: TYPES.TOGGLE_NEW_KEY_VALUE_MODAL,
  };
};

export const toggleNavigation = (toggle) => {
  return {
    type: TYPES.TOGGLE_NAVIGATION,
    payload: { navigation: toggle },
  };
};

export const toggleFilter = (toggle) => {
  return {
    type: TYPES.TOGGLE_FILTER,
    payload: { filter: toggle },
  };
};

export const toggleAll = () => {
  return {
    type: TYPES.TOGGLE_ALL,
  };
};
