import { namiLightGray, namiPureWhite } from 'src/variables';
import styled from 'styled-components';

const DisabledBadgeDot = styled.span`
  border-radius: 50%;
  border: 1px solid ${namiPureWhite};
  background-color: ${namiLightGray};
  width: 9px;
  height: 9px;
  display: inline-flex;
`;

export default DisabledBadgeDot;
