import React, { useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Col, Image, Input, Modal, Row, Space, Tree, Typography } from 'antd';
import { platformLogo } from 'src/services/helpers';
import { namiLightGray } from 'src/variables';
import styled from 'styled-components';

import { TProduct } from '../../../api/types/sku.types';
import { lowercaseIncludes } from '../../../utils/string';

type TProps = {
  isOpen: boolean;
  isLoading: boolean;
  products: TProduct[];
  onOk: (products: { id: string; associated: boolean }[]) => void;
  onCancel: () => void;
};

const StyledModal = styled(Modal)`
  .ant-space-item {
    width: 100%;
  }
`;

const StyledTree = styled(Tree)`
  padding-top: 1rem;
  height: 224px;
  width: 100%;
  border: 1px solid ${namiLightGray};
  overflow-y: scroll;
`;

export default function EntitlementProductAssociationModal({
  isOpen,
  isLoading,
  products,
  onOk,
  onCancel,
}: TProps) {
  const [productSearch, setProductSearch] = useState('');
  const [productsToAssociate, setProductsToAssociate] = useState<string[]>([]);
  return (
    <StyledModal
      title="Associate Product SKUs"
      open={isOpen}
      confirmLoading={isLoading}
      onOk={() => {
        onOk(productsToAssociate.map((id) => ({ id, associated: true })));
        onCancel();
      }}
      onCancel={onCancel}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Input
          value={productSearch}
          onChange={(e) => setProductSearch(e.target.value)}
          placeholder="Search by product name or SKU id"
          suffix={<SearchOutlined />}
        />
        <StyledTree
          checkable
          selectable={false}
          checkedKeys={productsToAssociate}
          onCheck={(ids) => setProductsToAssociate(ids as string[])}
          treeData={products
            .filter(
              (product) =>
                !productSearch ||
                lowercaseIncludes(
                  [product.name, product.sku_ref_id],
                  productSearch
                )
            )
            .map((product) => ({
              key: product.id,
              value: product.id,
              title: <ProductItemTitle {...product} />,
            }))}
        />
      </Space>
    </StyledModal>
  );
}

function ProductItemTitle(
  product: Pick<
    TProduct,
    'name' | 'sku_ref_id' | 'platform_type' | 'platform_name'
  >
) {
  return (
    <>
      <Row gutter={[12, 12]}>
        <Col>
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {product.name}
          </Typography.Title>
        </Col>
        <Col>
          <Typography.Paragraph style={{ marginBottom: 0 }}>
            {product.sku_ref_id}
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row gutter={[12, 12]} style={{ marginBottom: 8 }}>
        <Col>
          <Image
            src={platformLogo(product.platform_type)}
            height={12}
            width={12}
            preview={false}
          />
        </Col>
        <Col>
          <Typography.Text>{product.platform_name}</Typography.Text>
        </Col>
      </Row>
    </>
  );
}
