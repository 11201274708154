import React from 'react';

import styled, { css } from 'styled-components';

import { TImageComponent } from '../../../api/types/paywallTemplate.types';
import { transition } from '../css';
import HoverTag from './HoverTag';

type ImageProps = { component: TImageComponent };

const Wrapper = styled.img<ImageProps>`
  ${({
    component: {
      alignment,
      imageCropping,
      height,
      width,
      spacing,
      rightMargin,
    },
  }) => {
    const fit = imageCropping === 'fit';
    return css`
      ${transition()};
      object-fit: ${fit ? 'contain' : 'cover'};
      object-position: ${alignment} center;
      background-repeat: no-repeat;
      height: ${parseSize(height)};
      width: ${parseSize(width)};
      margin-right: ${rightMargin || spacing || 0}px;
    `;
  }}
`;

export default function Image({ component }: ImageProps) {
  return (
    <HoverTag
      title={component.namiComponentType ? component.title || component.id : ''}
      namiComponentType={component.namiComponentType}
      id={component.id}
    >
      {component.url ? (
        <Wrapper component={component} src={component.url} />
      ) : null}
    </HoverTag>
  );
}

function parseSize(value: undefined | string | number): string {
  if (typeof value === 'undefined') return '100%';
  return typeof value === 'number' ? `${value}px` : value;
}
