import React, { useContext } from 'react';

import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { Divider, Space } from 'antd';
import { AppContext } from 'src/AppContextProvider';
import SectionTitle from 'src/components/SectionTitle';

import AmazonIAPCredentialsForm from './AmazonIAPCredentialsForm';
import AppleCredentialsFormWrapper from './AppleCredentialsFormWrapper';
import GoogleCredentialsForm from './GoogleCredentialsForm';
import RokuCredentialsForm from './RokuCredentialsForm';

export default function StoreCredentialsTab() {
  const { userHasEntitlement } = useContext(AppContext);
  return (
    <div style={{ paddingBottom: 32 }}>
      <SectionTitle>
        <Space>
          <AppleFilled />
          App Store Credentials
        </Space>
      </SectionTitle>
      <AppleCredentialsFormWrapper />
      <Divider />
      <SectionTitle>
        <Space>
          <AndroidFilled />
          Play Store Credentials
        </Space>
      </SectionTitle>
      <GoogleCredentialsForm />
      {userHasEntitlement('app.capabilities.purchase_platform.amazon_iap') && (
        <>
          <Divider />
          <SectionTitle>Amazon IAP Credentials</SectionTitle>
          <AmazonIAPCredentialsForm />
        </>
      )}
      {userHasEntitlement('app.capabilities.purchase_platform.roku_pay') && (
        <>
          <Divider />
          <SectionTitle>Roku Credentials</SectionTitle>
          <RokuCredentialsForm />
        </>
      )}
    </div>
  );
}
