import React from 'react';

import * as Icons from '@ant-design/icons';

import {
  TFilterIdentifier,
  TFilterOperator,
} from '../../../../../api/types/campaign.types';

export type TOSName =
  | 'android'
  | 'ios'
  | 'ipados'
  | 'roku os'
  | 'tvos'
  | 'fire os';

export type TFormFactor = 'phone' | 'tablet' | 'television' | 'desktop';

export type TOption = {
  label: React.ReactNode;
  text: string;
  key: string;
  value: string;
  help_text?: string;
};

//TODO - new labels for case-sensitivity
export const operatorLabelMap: Record<TFilterOperator, string> = {
  in: 'is',
  i_contains: 'contains',
  equals: 'equals',
  i_equals: 'equals',
  not_equals: 'does not equal',
  not_i_equals: 'does not equal',
  contains: 'contains',
  not_contains: 'does not contain',
  not_i_contains: 'does not contain',
  greater_than: 'greater than',
  greater_than_or_equal_to: 'greater than or equal to',
  less_than: 'less than',
  less_than_or_equal_to: 'less than or equal to',
  starts_with: 'starts with',
  not_starts_with: "doesn't start with",
  i_starts_with: 'starts with',
  not_i_starts_with: "doesn't start with",
};

export const operatorLabelByIdentifierMap: Record<TFilterIdentifier, any> = {
  'Platform.id': {
    contains: 'is',
  },
  'Device.os_country': {
    i_contains: 'is',
  },
  'Device.marketplace_country': {
    i_contains: 'is',
  },
  'Device.geo_ip_country': {
    i_contains: 'is',
  },
  'Device.os_language': {
    i_contains: 'is',
  },
  'Device.form_factor': {
    i_contains: 'is',
  },
  'Device.os_name': {
    i_contains: 'is',
  },
  'Device.os_version': {},
  'Device.sdk_version': {},
  'Device.app_version': {},
  'Device.cdp_audience': {
    contains: 'is',
    not_contains: 'is not',
  },
  'Device.id': {},
  'Device.external_id': {},
  'Device.customer_data_platform_id': {},
};

export const operatorShortLabelByIdentifierMap: Record<TFilterIdentifier, any> =
  {
    'Platform.id': {
      contains: 'is',
      not_contains: 'is not',
    },
    'Device.os_country': {
      i_contains: 'is',
      not_i_contains: 'is not',
    },
    'Device.marketplace_country': {
      i_contains: 'is',
      not_i_contains: 'is not',
    },
    'Device.geo_ip_country': {
      i_contains: 'is',
      not_i_contains: 'is not',
    },
    'Device.os_language': {
      i_contains: 'is',
      not_i_contains: 'is not',
    },
    'Device.form_factor': {
      i_contains: 'is',
    },
    'Device.os_name': {
      i_contains: 'is',
      not_i_contains: 'is not',
    },
    'Device.os_version': {},
    'Device.sdk_version': {},
    'Device.app_version': {},
    'Device.cdp_audience': {
      contains: 'is',
      not_contains: 'is not',
    },
    'Device.id': {},
    'Device.external_id': {},
    'Device.customer_data_platform_id': {},
  };

export const identifierHelpTextMap: Record<TFilterIdentifier, string> = {
  'Platform.id': 'Filter users by their Nami App Platform Integration',
  'Device.os_country': 'Filter users by their device Region Country',
  'Device.marketplace_country':
    'Filter users by their device Marketplace Country',
  'Device.geo_ip_country':
    "Filter users by their device's current IP Address location",
  'Device.os_language': 'Filter users by their device language',
  'Device.form_factor':
    'Filter users by the form factor of their device such as phone or tablet',
  'Device.os_name':
    'Filter users by their device Operating System such as iOS or Android',
  'Device.os_version': 'Filter users by their device Operating System version',
  'Device.sdk_version': 'Filters users by their device Nami SDK Version',
  'Device.app_version': 'Filter users by their device App Version',
  'Device.cdp_audience':
    'Filter to users belonging to specific CDP (customer data platform) Audience(s)',
  'Device.id': 'Filter users by their Nami Device ID for testing',
  'Device.external_id': 'Filter users by their login/External ID',
  'Device.customer_data_platform_id':
    'Filter users by their CDP (customer data platform) ID',
};

export const identifierIconMap: Record<TFilterIdentifier, JSX.Element> = {
  'Platform.id': <Icons.ApiOutlined />,
  'Device.os_country': <Icons.FlagOutlined />,
  'Device.marketplace_country': <Icons.FlagOutlined />,
  'Device.geo_ip_country': <Icons.AimOutlined />,
  'Device.os_language': <Icons.GlobalOutlined />,
  'Device.form_factor': <Icons.DesktopOutlined />,
  'Device.os_name': <Icons.MobileOutlined />,
  'Device.os_version': <Icons.SettingOutlined />,
  'Device.sdk_version': <Icons.SettingOutlined />,
  'Device.app_version': <Icons.SettingOutlined />,
  'Device.cdp_audience': <Icons.TeamOutlined />,
  'Device.id': <Icons.CodeOutlined />,
  'Device.external_id': <Icons.CodeOutlined />,
  'Device.customer_data_platform_id': <Icons.CodeOutlined />,
};

export const identifierLabel: Record<TFilterIdentifier, string> = {
  'Platform.id': 'Platform',
  'Device.os_name': 'Device OS',
  'Device.form_factor': 'Device Form Factor',
  'Device.os_language': 'Device Language',
  'Device.os_country': 'Device Region',
  'Device.marketplace_country': 'Device Marketplace',
  'Device.geo_ip_country': 'Device Geolocation',
  'Device.os_version': 'OS Version',
  'Device.app_version': 'App Version',
  'Device.sdk_version': 'SDK Version',
  'Device.cdp_audience': 'CDP Audience',
  'Device.id': 'Nami Device ID',
  'Device.external_id': 'External ID',
  'Device.customer_data_platform_id': 'CDP ID',
};

export const anonymous_filters: string[] = [
  'Platform.id',
  'Device.form_factor',
];

export const semver_filters: TFilterIdentifier[] = ['Device.sdk_version'];

export const osNameLabelMap: Record<TOSName, string> = {
  android: 'Android',
  ios: 'iOS',
  ipados: 'iPadOS',
  tvos: 'tvOS',
  'roku os': 'Roku',
  'fire os': 'FireOS',
};

export const formFactorLabelMap: Record<TFormFactor, string> = {
  phone: 'Phone',
  tablet: 'Tablet',
  television: 'TV',
  desktop: 'Desktop',
};

export const operatorShortLabelMap: Record<TFilterOperator, string> = {
  in: 'is',
  equals: '=',
  i_equals: '=',
  contains: 'contains',
  i_contains: 'contains',
  not_contains: 'does not contain',
  not_i_contains: 'does not contain',
  not_equals: '≠',
  not_i_equals: '≠',
  less_than: '<',
  less_than_or_equal_to: '≤',
  greater_than: '>',
  greater_than_or_equal_to: '≥',
  starts_with: 'starts with',
  not_starts_with: "doesn't start with",
  i_starts_with: 'starts with',
  not_i_starts_with: "doesn't start with",
};
