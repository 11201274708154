import React from 'react';

import { Divider } from 'antd';
import SectionTitle from 'src/components/SectionTitle';

import ChangePasswordForm from './forms/ChangePasswordForm';
import TOTPSection from './TOTPSection';

export default function SecurityPage() {
  return (
    <>
      <SectionTitle>Change Password</SectionTitle>
      <ChangePasswordForm />
      <Divider />
      <SectionTitle>Security</SectionTitle>
      <TOTPSection />
    </>
  );
}
