import React from 'react';

import { UserOutlined, LockOutlined, MailOutlined } from '@ant-design/icons';
import { Row, Col, Form, Input, Typography, Button } from 'antd';
import styled from 'styled-components';

import api from '../../../api';
import NamiAgreementLinks from '../../../components/NamiAgreementLinks/NamiAgreementLinks';
import Page from '../../../components/Page/Page';
import { usePromiseState, useAppContext, useQueryParams } from '../../../hooks';
import { ReactComponent as Logo } from '../../../images/icons/nami-app-sumo-logo.svg';
import SegmentClient from '../../../segment';
import { hasLowerCase, hasUpperCase, hasNumber } from '../../../utils/string';

const Iframe = styled.iframe`
  border: none;
  min-height: 800px;
  height: 100vh;
  position: absolute;
  top: 0;
  bottom: 0;
`;

const AppSumoLogo = styled(Logo)`
  margin-left: 3%;
  margin-top: 3%;
`;

const JoinAppSumoPage = () => {
  const appContext = useAppContext();
  const queryParams = useQueryParams();
  const [form] = Form.useForm();

  const values = Array.from(queryParams.entries()).reduce(
    (output, [key, value]) => ({ ...output, [key]: value }),
    {}
  );

  const activateRequest = usePromiseState(() => {
    let data = { ...form.getFieldsValue(), opted_in_marketing: true };
    return api.finishOnboarding(values.token, data).then((response) => {
      appContext.authorizeAccess(response);
      SegmentClient.trackRegistration();
    });
  });

  const passwordValidators = [
    [(password) => password && password !== '', 'Password is required'],
    [
      (password) => hasLowerCase(password),
      'Password needs 1 lower case letter',
    ],
    [
      (password) => hasUpperCase(password),
      'Password needs 1 upper case letter',
    ],
    [(password) => hasNumber(password), 'Password needs 1 number'],
    [
      (password) => password.length > 7,
      'Password should be at least 8 characters long',
    ],
  ];

  const getPasswordRules = () => ({
    validator(_, value) {
      for (let i = 0; i < passwordValidators.length; i++) {
        const [isValid, errorMessage] = passwordValidators[i];
        if (!isValid(value)) return Promise.reject(new Error(errorMessage));
      }
      return Promise.resolve();
    },
  });

  return (
    <Page title="Join Nami with App Sumo">
      <Row>
        <Col xs={24} lg={12}>
          <AppSumoLogo />
          <Form
            form={form}
            layout="vertical"
            initialValues={values}
            onFinish={activateRequest.trigger}
            style={{ maxWidth: 500, margin: '40px auto', padding: 20 }}
          >
            <Typography.Title level={3}>Activate your account</Typography.Title>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: 'This field is required' }]}
              validateStatus={activateRequest?.error?.email ? 'error' : ''}
              help={activateRequest?.error?.email}
            >
              <Input
                prefix={<MailOutlined />}
                placeholder="Email"
                disabled={true}
              />
            </Form.Item>
            <Form.Item
              name="first_name"
              label="First Name"
              rules={[{ required: true, message: 'This field is required' }]}
              validateStatus={activateRequest?.error?.first_name ? 'error' : ''}
              help={activateRequest?.error?.first_name}
            >
              <Input prefix={<UserOutlined />} placeholder="First Name" />
            </Form.Item>
            <Form.Item
              name="last_name"
              label="Last Name"
              rules={[{ required: true, message: 'This field is required' }]}
              validateStatus={activateRequest?.error?.last_name ? 'error' : ''}
              help={activateRequest?.error?.last_name}
            >
              <Input prefix={<UserOutlined />} placeholder="Last Name" />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              hasFeedback
              rules={[{}, () => getPasswordRules()]}
            >
              <Input.Password
                allowClear
                autoComplete="new-password"
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <NamiAgreementLinks name="Activate" />
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={activateRequest.isLoading}
              >
                Activate
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} lg={12}>
          <Iframe
            title="marketing message webflow"
            width="100%"
            height="100%"
            src="https://www.namiml.com/embeds/appsumo-activate"
          />
        </Col>
      </Row>
    </Page>
  );
};

export default JoinAppSumoPage;
