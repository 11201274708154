import React from 'react';

import { LaunchSharp } from '@mui/icons-material';
import { Button, Form } from 'antd';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

const labelMap: Record<string, string> = {
  apple: 'App Store Connect Shared Secret',
  amazon_iap: 'Amazon Store Shared Key',
  roku_pay: 'Roku Pay API Key',
  google: 'Service Account Credentials (.json)',
};

export default function StoreCredentialsLink() {
  const { type } = useParams<{ type: string }>();
  const history = useHistory();

  if (!(type in labelMap)) return null;
  return (
    <Form.Item label={labelMap[type]}>
      <Button
        type="link"
        icon={
          <LaunchSharp
            style={{
              fontSize: 16,
              transform: 'translate(-4px, 2px)',
            }}
          />
        }
        onClick={() => history.push('/settings?tab=storeCredentials')}
      >
        Go to Store Credentials
      </Button>
    </Form.Item>
  );
}
