import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AnalyticsState = {
  paywallsPage: number;
  campaignsPage: number;
};

const initialState: AnalyticsState = {
  paywallsPage: 1,
  campaignsPage: 1,
};

export default createSlice({
  name: 'Pagination',
  initialState,
  reducers: {
    setPaywallsPage(state, { payload: paywallsPage }: PayloadAction<number>) {
      state.paywallsPage = paywallsPage;
    },
    setCampaignsPage(state, { payload: campaignsPage }: PayloadAction<number>) {
      state.campaignsPage = campaignsPage;
    },
  },
});
