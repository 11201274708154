import React from 'react';

import { LeftOutlined } from '@ant-design/icons';
import { Form, Row } from 'antd';
import { createSelector } from 'reselect';

import { TFormGroup } from '../../../../../api/types/paywallTemplate.types';
import { useActions, useAppSelector } from '../../../../../hooks/redux.hooks';
import { RootState } from '../../../../../redux';
import PaywallBuilderSlice from '../../../../../redux/PaywallBuilderSlice';
import { capitalizePhrase, toSlug } from '../../../../../utils/string';
import { buildMediaVariables } from '../../utils/variables';
import PaywallBuilderDrawer from '../PaywallBuilderDrawer';
import PaywallBuilderDrawerTitle from '../PaywallBuilderDrawerTitle';
import { FormSection } from './FormSection';

type TemplateEditorProps = {
  formGroup: TFormGroup | null;
  onClose: () => void;
};

const variableSelector = createSelector(
  [
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall!.template.variables!,
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall!.template.initialState!,
    ({ paywallBuilder: { mediaList } }: RootState) => mediaList,
  ],
  (variables, state, mediaList): { [key: string]: any } => ({
    var: variables,
    state,
    media: buildMediaVariables(mediaList),
  })
);

export default function TemplateEditor({
  formGroup,
  onClose,
}: TemplateEditorProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const variables = useAppSelector(variableSelector);
  const title = formGroup && capitalizePhrase(formGroup.title);
  if (!formGroup) return null;
  const isSingle = formGroup.sections.length === 1;
  return (
    <PaywallBuilderDrawer
      title={
        <PaywallBuilderDrawerTitle>Edit {title}</PaywallBuilderDrawerTitle>
      }
      onClose={onClose}
      open={!!formGroup}
      closeIcon={<LeftOutlined style={{ fontSize: 15 }} />}
      className={
        'intercom-edit-' +
        toSlug(title ? title : 'component') +
        ' verticalScrollDrawer'
      }
    >
      <Form layout="vertical">
        <Row gutter={16}>
          {formGroup.sections.map(({ displayVar, ...section }) => (
            <FormSection
              key={section.title}
              collapseIntercomPrefix="intercom"
              displayVar={displayVar && `var.${displayVar}`}
              variables={variables}
              isSingle={isSingle}
              onChange={(variable, value) =>
                actions.setTemplateVariable({ key: variable, value })
              }
              {...section}
            />
          ))}
        </Row>
      </Form>
    </PaywallBuilderDrawer>
  );
}
