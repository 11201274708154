import React from 'react';

import { namiDarkGray, namiPureWhite } from 'src/variables';
import styled from 'styled-components';

type FilterDescriptionProps = { icon: JSX.Element; label: string };

const Wrapper = styled.div`
  display: flex;
  padding: 0px 10px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  background: ${namiPureWhite};
  min-width: 150px;
  width: fit-content;
`;

const DescriptionLabel = styled.span`
  color: ${namiDarkGray};
  font-size: 14px;
  font-weight: 600;
`;
export function FilterDescription({ icon, label }: FilterDescriptionProps) {
  return (
    <Wrapper>
      {icon}
      <DescriptionLabel>{label}</DescriptionLabel>
    </Wrapper>
  );
}
