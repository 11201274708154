import { useCallback, useState } from 'react';

export function useInputState(initialState) {
  const [value, setValue] = useState(initialState);
  return [value, (e) => setValue(e.target.value)];
}

export function useFormState(initialState) {
  const [form, setForm] = useState(initialState);

  const setFormField = useCallback((field, value) => {
    setForm((formState) => ({
      ...formState,
      [field]: typeof value === 'function' ? value(formState[field]) : value,
    }));
  }, []);
  const setFormInput = useCallback(
    (e) => setFormField(e.target.name, e.target.value),
    [setFormField]
  );

  return { form, setFormField, setFormInput, setForm };
}
