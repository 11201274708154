import React from 'react';

import { NavLink } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import { useAppContext } from '../../../hooks';
import AppIcon from '../../AppIcon/AppIcon';
import NamiIcon from '../../NamiIcon/NamiIcon';
import styles from './MobileHeader.module.less';

type MobileHeaderProps = {
  mobileMenuOpen: () => void;
  switchAppMenuOpen: () => void;
};

export default function MobileHeader({
  mobileMenuOpen,
  switchAppMenuOpen,
}: MobileHeaderProps) {
  const context = useAppContext();
  return (
    <div className={styles.header}>
      <Grid verticalAlign="middle" columns={3}>
        <Grid.Column onClick={mobileMenuOpen} textAlign="left">
          <NamiIcon color="primary" icon="bars" />
        </Grid.Column>
        <Grid.Column textAlign="center">
          <NavLink to="/overview/">
            <NamiIcon icon="logo" color="primary" size="large" />
          </NavLink>
        </Grid.Column>
        {context.selectedOrg && ( // TODO: Move this condition to the "AppIcon"
          <Grid.Column textAlign="right">
            <AppIcon onClick={switchAppMenuOpen} />
          </Grid.Column>
        )}
      </Grid>
    </div>
  );
}
