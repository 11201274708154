import React from 'react';

import { Tooltip } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import { Menu, MenuItemProps, Sidebar } from 'semantic-ui-react';
import { useBooleanState } from 'src/hooks';
import AppSwitchPopover from 'src/pages/admin/AppSwitchPopover';
import styled from 'styled-components';

import { pathBeginMatches } from '../../../services/helpers/';
import { colorPrimaryDark } from '../../../variables';
import AppIcon from '../../AppIcon/AppIcon';
import NamiIcon from '../../NamiIcon/NamiIcon';

type DesktopMenuProps = {
  items: {
    enabled: boolean;
    name: string;
    path: string;
    iconName: string;
  }[];
  isVisible: boolean;
  onAppChange: (appId: string) => void;
};

const Wrapper = styled(Sidebar)`
  display: flex !important;
  background-color: ${colorPrimaryDark} !important;
  width: 60px !important;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const Link = styled(Menu.Item).attrs({ className: 'item' })<MenuItemProps>`
  padding: 15px 0 !important;
  min-width: 0 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08) !important;
  opacity: 0.6;

  &.active {
    background-color: black !important;
    opacity: 1;
  }

  &:before {
    display: none;
  }
`;

export default function DesktopMenu({
  items,
  isVisible,
  onAppChange,
}: DesktopMenuProps) {
  const location = useLocation();
  const [appSwitchMenu, openAppSwitchMenu, closeAppSwitchMenu] =
    useBooleanState(false);

  return (
    <Wrapper
      forwardedAs={Menu}
      fixed="left"
      icon="labeled"
      vertical
      inverted
      visible={isVisible}
      width="very thin"
    >
      <AppSwitchPopover
        appSwitchMenuOpen={appSwitchMenu}
        onAppClick={onAppChange}
        onClose={closeAppSwitchMenu}
      >
        <AppIcon onClick={openAppSwitchMenu} />
      </AppSwitchPopover>
      {items.map(
        (menu, index) =>
          menu.enabled && (
            <Tooltip
              title={menu.name}
              placement="right"
              align={{ offset: [-6] }}
              key={index}
            >
              <Link
                data-cy={menu.name}
                forwardedAs={NavLink}
                to={menu.path}
                active={pathBeginMatches(location.pathname, menu.path)}
              >
                <NamiIcon icon={menu.iconName} />
              </Link>
            </Tooltip>
          )
      )}
      <span style={{ flexGrow: 1 }}></span>
      <Tooltip
        title="Help Center"
        placement="right"
        align={{ offset: [-6] }}
        key="help"
      >
        <Link forwardedAs="a" href="https://learn.namiml.com" target="_blank">
          <NamiIcon icon="help" />
        </Link>
      </Tooltip>
    </Wrapper>
  );
}
