import React from 'react';

import { Login } from '@mui/icons-material';
import { Button, Tooltip } from 'antd';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

export default function PaywallSigninStatePicker() {
  const isLoggedIn = useAppSelector(
    ({ paywallBuilder: { isLoggedIn } }: RootState) => isLoggedIn
  );
  const actions = useActions(PaywallBuilderSlice.actions);

  return (
    <>
      <Tooltip
        title="Preview the paywall as a signed in user"
        mouseEnterDelay={0.8}
      >
        <Button
          type={isLoggedIn ? 'primary' : 'ghost'}
          icon={<Login style={{ transform: 'translateY(1.5px)' }} />}
          size="small"
          onClick={toggleLoggedInState}
        />
      </Tooltip>
    </>
  );

  function toggleLoggedInState() {
    actions.setIsLoggedIn(!isLoggedIn);
  }
}
