import * as TYPES from '../types';

const initialState = {
  newProductOpen: false,
  newAppModelOpen: false,
  newPlatformOpen: false,
  newEntitlementOpen: false,
  newProductAssociateOpen: false,
  newKeyValueOpen: false,
  mainNavigation: false,
  appNavigation: false,
  navigation: false,
  filter: false,
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.TOGGLE_NEW_PRODUCT_MODAL:
      return {
        ...state,
        newProductOpen: !state.newProductOpen,
      };

    case TYPES.TOGGLE_NEW_APP_MODAL:
      return {
        ...state,
        newAppModelOpen: !state.newAppModelOpen,
      };
    case TYPES.TOGGLE_NEW_ENTITLEMENT_MODAL:
      return {
        ...state,
        newEntitlementOpen: !state.newEntitlementOpen,
      };
    case TYPES.TOGGLE_NEW_PRODUCT_ASSOCIATE_MODAL:
      return {
        ...state,
        newProductAssociateOpen: !state.newProductAssociateOpen,
      };

    case TYPES.TOGGLE_NEW_KEY_VALUE_MODAL:
      return {
        ...state,
        newKeyValueOpen: !state.newKeyValueOpen,
      };
    case TYPES.TOGGLE_NAVIGATION:
      return {
        ...state,
        navigation: action.payload.navigation,
      };

    case TYPES.TOGGLE_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };

    case TYPES.TOGGLE_ALL:
      return {
        ...state,
        filter: false,
        navigation: false,
        mainNavigation: false,
        appNavigation: false,
      };

    default:
      return state;
  }
};
