import React from 'react';

import {
  parsePaywallConversionRate,
  parseTotalPaywallConversionRate,
  parseTrialConversionRate,
} from 'src/api/analytics.api';
import { TData } from 'src/api/types/analytics.types';

import api from '../../../api';
import LineChartPage, { LineChartPageProps } from '../LineChartPage';
import { useInsightType } from './useInsightType';

export type TInsightType =
  | 'revenue'
  | 'mrr'
  | 'mrr-movement'
  | 'avg-revenue-per-paying-user'
  | 'avg-revenue-per-registered-user'
  | 'paywalls-conversion-rate'
  | 'purchases'
  | 'active-paid-subscribers'
  | 'new-subscribers'
  | 'avg-mrr-per-subscriber'
  | 'subscriber-movement'
  | 'subscriber-ltv'
  | 'subscriber-retention'
  | 'sessions'
  | 'impressions'
  | 'active-users'
  | 'active-devices'
  | 'free-trials'
  | 'trial-movement'
  | 'free-trial-conversion-rate'
  | 'subscriber-churn'
  | 'revenue-churn';

const assertProps = <T extends TData>(
  props: LineChartPageProps<T>
): LineChartPageProps<T> => props;

export const PAGES: Record<TInsightType, any> = {
  revenue: assertProps({
    insightType: 'revenue',
    title: 'Revenue',
    dataFetcher: api.getRevenue,
    totalFetcher: api.getTotalRevenue,
    chartKeys: ['PurchaseEvent.revenueInUSD'],
    description:
      'The sum of all revenue generated. Unlike MRR, this number is not normalized to a monthly period. It also includes all revenue from one-time purchases or consumables.',
    entitlement: 'app.analytics.revenue',
    availableSegments: [
      'AppPlatform.platformType',
      'Purchase.purchaseCountry',
      'ProductSKU.id',
      'ProductSKU.standardDuration',
      'ProductSKU.freeTrialDuration',
      'ProductSKU.skuType',
      'CampaignLabel.id',
      'CampaignRule.id',
      'Paywall.id',
    ],
    availableFilters: [
      'AppPlatform.platformType',
      'Purchase.purchaseCountry',
      'ProductSKU.id',
      'ProductSKU.standardDuration',
      'ProductSKU.freeTrialDuration',
      'ProductSKU.skuType',
      'CampaignLabel.id',
      'CampaignRule.id',
      'Paywall.id',
    ],
  }),
  mrr: assertProps({
    insightType: 'mrr',
    title: 'Monthly Recurring Revenue',
    dataFetcher: api.getMRR,
    chartKeys: ['SubscriptionRevenue.revenueInUSD'],
    description:
      'Normalized net subscription monthly revenue. Normalizes all subscription lengths to 1 month. Excludes one-time purchases or consumable purchases.',
    entitlement: 'app.analytics.mrr',
    availableSegments: [
      'AppPlatform.platformType',
      'Purchase.purchaseCountry',
      'ProductSKU.id',
      'ProductSKU.standardDuration',
      'ProductSKU.freeTrialDuration',
      'CampaignLabel.id',
      'CampaignRule.id',
      'Paywall.id',
    ],
    availableFilters: [
      'AppPlatform.platformType',
      'Purchase.purchaseCountry',
      'ProductSKU.id',
      'ProductSKU.standardDuration',
      'ProductSKU.freeTrialDuration',
      'CampaignLabel.id',
      'CampaignRule.id',
      'Paywall.id',
    ],
  }),
  'mrr-movement': assertProps({
    insightType: 'mrr-movement',
    title: 'MRR Movement',
    dataFetcher: api.getMRRMovement, //TODO - implement method
    chartKeys: ['SubscriptionRevenue.revenueInUSD'], //TODO
    description:
      'Visual of your Net MRR. New MRR is from subscribers that were added during this period. Churned MRR is from subscribers that expired during the period.',
    entitlement: 'app.analytics.mrr_movement',
    availableSegments: ['AppPlatform.platformType'],
    availableFilters: ['AppPlatform.platformType'],
  }),
  'avg-revenue-per-paying-user': assertProps({
    insightType: 'avg-revenue-per-paying-user',
    title: 'Avg Revenue per Paying User',
    dataFetcher: api.getAvgRevenuePerPayingUser, //TODO - implement method
    chartKeys: ['SubscriptionRevenue.revenueInUSD'], //TODO
    description:
      'ARPPU = Total revenue divided by the number of paying users. A paying user is any user who has paid for a subscription or one-time purchase.',
    entitlement: 'app.analytics.avg_revenue_per_paying_user',
    availableSegments: ['AppPlatform.platformType'],
    availableFilters: ['AppPlatform.platformType'],
  }),
  'avg-revenue-per-registered-user': assertProps({
    insightType: 'avg-revenue-per-registered-user',
    title: 'Avg Revenue per Registered User',
    dataFetcher: api.getAvgRevenuePerRegisteredUser, //TODO - implement method
    chartKeys: ['SubscriptionRevenue.revenueInUSD'], //TODO
    description:
      'Total revenue divided by the number of registered app users. Includes one-time purchases.',
    entitlement: 'app.analytics.avg_revenue_per_registered_user',
    availableSegments: ['AppPlatform.platformType'],
    availableFilters: ['AppPlatform.platformType'],
  }),
  'paywalls-conversion-rate': assertProps({
    insightType: 'paywalls-conversion-rate',
    title: 'Paywall Conversion Rate',
    dataFetcher: api.getPaywallConversionRate,
    totalFetcher: api.getTotalPaywallConversionRate,
    dataParser: parsePaywallConversionRate,
    totalDataParser: parseTotalPaywallConversionRate,
    chartKeys: ['Impression.conversionRate'],
    tableKeys: [
      'Impression.impressions',
      'Impression.conversions',
      'Impression.conversionRate',
    ],
    description:
      'The rate at which users who see your paywall convert to paid users. Calculated by taking transactions divided by all paywall impressions during the time period.',
    entitlement: 'app.analytics.paywall_conversion_rate',
    availableSegments: [
      'AppPlatform.platformType',
      'Device.country',
      'CampaignLabel.id',
      'CampaignRule.id',
      'Paywall.id',
    ],
    availableFilters: [
      'AppPlatform.platformType',
      'Device.country',
      'CampaignLabel.id',
      'CampaignRule.id',
      'Paywall.id',
    ],
  }),
  purchases: assertProps({
    insightType: 'purchases',
    title: 'Purchases',
    dataFetcher: api.getPurchases,
    totalFetcher: api.getTotalPurchases,
    chartKeys: ['PurchaseEvent.count'],
    description:
      'Total of all product purchases made in your Nami app. Includes one-time purchases and consumable products.',
    entitlement: 'app.analytics.purchases',
    availableSegments: [
      'AppPlatform.platformType',
      'Purchase.purchaseCountry',
      'ProductSKU.id',
      'ProductSKU.standardDuration',
      'ProductSKU.freeTrialDuration',
      'ProductSKU.skuType',
      'CampaignLabel.id',
      'CampaignRule.id',
      'Paywall.id',
    ],
    availableFilters: [
      'AppPlatform.platformType',
      'Purchase.purchaseCountry',
      'ProductSKU.id',
      'ProductSKU.standardDuration',
      'ProductSKU.freeTrialDuration',
      'ProductSKU.skuType',
      'CampaignLabel.id',
      'CampaignRule.id',
      'Paywall.id',
    ],
  }),
  'active-paid-subscribers': assertProps({
    insightType: 'active-paid-subscribers',
    title: 'Active Paid Subscribers',
    dataFetcher: api.getActivePaidSubscribers, //TODO - implement method
    chartKeys: ['SubscriptionRevenue.revenueInUSD'], //TODO
    description: 'Total number of users with 1 or more active subscriptions.',
    entitlement: 'app.analytics.active_paid_subscribers',
    availableSegments: ['AppPlatform.platformType'],
    availableFilters: ['AppPlatform.platformType'],
  }),
  'new-subscribers': assertProps({
    insightType: 'new-subscribers',
    title: 'New Subscribers',
    dataFetcher: api.getNewSubscribers, //TODO - implement method
    chartKeys: ['SubscriptionRevenue.revenueInUSD'], //TODO
    description:
      'Total number of users who started a subscription during the time period',
    entitlement: 'app.analytics.new_subscribers',
    availableSegments: ['AppPlatform.platformType'],
    availableFilters: ['AppPlatform.platformType'],
  }),
  'avg-mrr-per-subscriber': assertProps({
    insightType: 'avg-mrr-per-subscriber',
    title: 'Avg MRR per Subscriber',
    dataFetcher: api.getAvgMRRPerSubscriber, //TODO - implement method
    chartKeys: ['SubscriptionRevenue.revenueInUSD'], //TODO
    description: 'Total MRR divided by the number of subscribers.',
    entitlement: 'app.analytics.avg_mrr_per_subscriber',
    availableSegments: ['AppPlatform.platformType'],
    availableFilters: ['AppPlatform.platformType'],
  }),
  'subscriber-movement': assertProps({
    insightType: 'subscriber-movement',
    title: 'Subscriber Movement',
    dataFetcher: api.getSubscriberMovement, //TODO - implement method
    chartKeys: ['SubscriptionRevenue.revenueInUSD'], //TODO
    description:
      'Visual of your subscriber base. New Subscribers are subscribers that subscribed for the first time during this period. Churned Subscribers are subscribers that expired during this period.',
    entitlement: 'app.analytics.subscriber_movement',
    availableSegments: ['AppPlatform.platformType'],
    availableFilters: ['AppPlatform.platformType'],
  }),
  'subscriber-ltv': assertProps({
    insightType: 'subscriber-ltv',
    title: 'Subscriber LTV',
    dataFetcher: api.getSubscriberLTV, //TODO - implement method
    chartKeys: ['SubscriptionRevenue.revenueInUSD'], //TODO
    description:
      'Subscriber Lifetime Value or LTV is the estimated amount of revenue you will receive from a subscriber through their entire lifecycle.',
    entitlement: 'app.analytics.subscriber_ltv',
    availableSegments: ['AppPlatform.platformType'],
    availableFilters: ['AppPlatform.platformType'],
  }),
  'subscriber-retention': assertProps({
    insightType: 'subscriber-retention',
    title: 'Subscriber Retention',
    dataFetcher: api.getSubscriberRetention, //TODO - implement method
    chartKeys: ['SubscriptionRevenue.revenueInUSD'], //TODO
    description:
      'Visualizes average tenure of subscribers by showing the % of customers in a cohort with 1 or more active subscriptions.',
    entitlement: 'app.analytics.subscriber_retention',
    availableSegments: ['AppPlatform.platformType'],
    availableFilters: ['AppPlatform.platformType'],
  }),
  sessions: assertProps({
    insightType: 'sessions',
    title: 'Sessions',
    dataFetcher: api.getSessions,
    totalFetcher: api.getTotalSessions,
    chartKeys: ['Session.count'],
    description:
      'A session is defined as the length of time between when a user launches their app and when the app is backgrounded. Total Sessions sums up all of these periods across all user devices.',
    entitlement: 'app.analytics.sessions',
    availableSegments: [
      'AppPlatform.platformType',
      'Device.country',
      'Device.language',
      'Device.sdkVersion',
    ],
    availableFilters: [
      'AppPlatform.platformType',
      'Device.country',
      'Device.language',
      'Device.sdkVersion',
    ],
  }),
  impressions: assertProps({
    insightType: 'impressions',
    title: 'Impressions',
    dataFetcher: api.getImpressions,
    totalFetcher: api.getTotalImpressions,
    chartKeys: ['Impression.impressions'],
    description:
      'Total of all views of a Nami paywall in your app during the timeframe',
    entitlement: 'app.analytics.impressions',
    availableSegments: [
      'AppPlatform.platformType',
      'Device.country',
      'CampaignLabel.id',
      'CampaignRule.id',
      'Paywall.id',
    ],
    availableFilters: [
      'AppPlatform.platformType',
      'Device.country',
      'CampaignLabel.id',
      'CampaignRule.id',
      'Paywall.id',
    ],
  }),
  'active-users': assertProps({
    insightType: 'active-users',
    title: 'Active Users',
    dataFetcher: api.getActiveDevices, //TODO
    totalFetcher: api.getTotalActiveDevices, //TODO
    chartKeys: ['Session.activeDevices'], //TODO
    description: '', //TODO
    entitlement: 'app.analytics.app_users',
    availableSegments: [], //TODO
    availableFilters: [], //TODO
  }),
  'active-devices': assertProps({
    insightType: 'active-devices',
    title: 'Active Devices',
    dataFetcher: api.getActiveDevices,
    totalFetcher: api.getTotalActiveDevices,
    chartKeys: ['Session.activeDevices'],
    description:
      'Total number of devices that had at least 1 app session in the selected timeframe.',
    entitlement: 'app.analytics.app_users',
    availableSegments: [
      'AppPlatform.platformType',
      'Device.country',
      'Device.language',
      'Device.sdkVersion',
    ],
    availableFilters: [
      'AppPlatform.platformType',
      'Device.country',
      'Device.language',
      'Device.sdkVersion',
    ],
  }),
  'free-trials': assertProps({
    insightType: 'free-trials',
    title: 'Trial Starts',
    dataFetcher: api.getFreeTrials,
    chartKeys: ['PurchaseEvent.count'], //TODO
    description:
      'Total number of customers who started a free trial during the time period. All trial customers are included, regardless of whether they convert from their trial to a paid customer.',
    entitlement: 'app.analytics.trials',
    availableSegments: [
      'AppPlatform.platformType',
      'Purchase.purchaseCountry',
      'ProductSKU.id',
      'ProductSKU.standardDuration',
      'ProductSKU.freeTrialDuration',
      'ProductSKU.skuType',
      'CampaignLabel.id',
      'CampaignRule.id',
      'Paywall.id',
    ],
    availableFilters: [
      'AppPlatform.platformType',
      'Purchase.purchaseCountry',
      'ProductSKU.id',
      'ProductSKU.standardDuration',
      'ProductSKU.freeTrialDuration',
      'ProductSKU.skuType',
      'CampaignLabel.id',
      'CampaignRule.id',
      'Paywall.id',
    ],
  }),
  'trial-movement': assertProps({
    insightType: 'trial-movement',
    title: 'Trial Movement',
    dataFetcher: api.getTrialMovement, //TODO - implement method
    chartKeys: ['SubscriptionRevenue.revenueInUSD'], //TODO
    description:
      'Visual of your trial base. New Trials are trials started during this period. Ended Trials are trials that completed during the period.',
    entitlement: 'app.analytics.trial_movement',
    availableSegments: ['AppPlatform.platformType'],
    availableFilters: ['AppPlatform.platformType'],
  }),
  'free-trial-conversion-rate': assertProps({
    insightType: 'free-trial-conversion-rate',
    title: 'Trial Conversion Rate',
    dataFetcher: api.getFreeTrialConversionRate,
    dataParser: parseTrialConversionRate,
    chartKeys: ['Purchase.trialConversionRate'],
    tableKeys: [
      'Purchase.trials',
      'Purchase.trialConversions',
      'Purchase.trialConversionRate',
    ],
    description:
      'Rate at which users convert from a free trial to a paid subscriber. ',
    entitlement: 'app.analytics.trial_conversion_rate',
    availableSegments: [
      'AppPlatform.platformType',
      'Purchase.purchaseCountry',
      'ProductSKU.id',
      'ProductSKU.standardDuration',
      'ProductSKU.freeTrialDuration',
      'ProductSKU.skuType',
      'CampaignLabel.id',
      'CampaignRule.id',
      'Paywall.id',
    ],
    availableFilters: [
      'AppPlatform.platformType',
      'Purchase.purchaseCountry',
      'ProductSKU.id',
      'ProductSKU.standardDuration',
      'ProductSKU.freeTrialDuration',
      'ProductSKU.skuType',
      'CampaignLabel.id',
      'CampaignRule.id',
      'Paywall.id',
    ],
  }),
  'subscriber-churn': assertProps({
    insightType: 'subscriber-churn',
    title: 'Subscriber Churn',
    dataFetcher: api.getSubscriberChurn, //TODO - implement method
    chartKeys: ['SubscriptionRevenue.revenueInUSD'], //TODO
    description:
      'The rate at which you lost subscribers during a given period.',
    entitlement: 'app.analytics.subscriber_churn',
    availableSegments: ['AppPlatform.platformType'],
    availableFilters: ['AppPlatform.platformType'],
  }),
  'revenue-churn': assertProps({
    insightType: 'revenue-churn',
    title: 'Revenue Churn',
    dataFetcher: api.getRevenueChurn, //TODO - implement method
    chartKeys: ['SubscriptionRevenue.revenueInUSD'], //TODO
    description: 'The rate at which you lost revenue during a given period.',
    entitlement: 'app.analytics.revenue_churn', //TODO
    availableSegments: ['AppPlatform.platformType'],
    availableFilters: ['AppPlatform.platformType'],
  }),
};

export default function InsightsPage() {
  const insightType = useInsightType();
  const props = PAGES[insightType];
  return <LineChartPage {...props} />;
}
