import React from 'react';

import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

const NumberInput = ({ value, onChange, error, ...props }) => {
  return (
    <Form.Input
      value={value}
      onChange={updateField}
      inputMode="numeric"
      error={error}
      {...props}
      // This limitation comes from JS' precision issue
      // See: https://stackoverflow.com/a/29333607/4694834
      maxLength="14"
    />
  );

  function updateField(e) {
    const value = e.target.value.replace(/\D+/g, '');
    onChange({ target: { name: e.target.name, value } });
  }
};

NumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};

export default NumberInput;
