import React, { useMemo } from 'react';

import styled from 'styled-components';

import { capitalizePhrase } from '../../../../../../utils/string';
import { FieldObject } from '../../../utils/formFieldBuilding';

type InputLabelProps = Pick<FieldObject, 'name' | 'description'> & {
  label?: string;
  isSingle?: boolean;
  collapsible?: boolean;
};

const Label = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 13px;
`;

const Description = styled.span`
  display: block;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export default function InputLabel({
  collapsible,
  name,
  isSingle,
  label = '',
  description,
}: InputLabelProps) {
  const finalLabel = useMemo(() => {
    if (collapsible ?? true) return name;
    if (isSingle) return name === 'Text' ? 'Content' : name;
    return capitalizePhrase(`${label} ${name}`)
      .replace('Text Text', 'Text')
      .trim();
  }, [collapsible, name, isSingle, label]);

  return (
    <Label>
      <span>{finalLabel}</span>
      {description ? <Description>{description}</Description> : null}
    </Label>
  );
}
