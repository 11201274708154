import React from 'react';

import PropTypes from 'prop-types';

import NumberInput from './NumberInput';
import { adjustErrorUnit, toPositiveInteger } from './utils';

const ConvertedNumberInput = ({
  value,
  onChange,
  error,
  displayConverter,
  valueConverter,
  ...props
}) => {
  const newValue = value ? displayConverter(value).toString() : '';
  return (
    <NumberInput
      value={newValue}
      onChange={updateField}
      error={error && adjustErrorUnit(error, displayConverter)}
      {...props}
    />
  );

  function updateField(e) {
    const value = +e.target.value
      ? valueConverter(toPositiveInteger(e.target.value))
      : null;
    onChange({ target: { name: e.target.name, value } });
  }
};

ConvertedNumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  displayConverter: PropTypes.func.isRequired,
  valueConverter: PropTypes.func.isRequired,
};

export default ConvertedNumberInput;
