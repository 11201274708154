import React from 'react';

import { TVolumeControlComponent } from 'src/api/types/paywallTemplate.types';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { TSemverObj } from 'src/utils/parsing';
import styled, { css } from 'styled-components';

import {
  applyStyles,
  flexDirection,
  pickAndApplyBackgroundColor,
} from '../css';
import TemplateComponent from '../TemplateComponent';
import HoverTag from './HoverTag';

type ComponentProps<T> = {
  component: T;
  inFocusedState?: boolean;
  groupId: string | null;
  minSDKVersion: TSemverObj;
};

const ButtonWrapper = styled.div<{
  component: TVolumeControlComponent;
  inFocusedState?: boolean;
}>`
  display: flex;
  flex-direction: column;

  ${({ component, inFocusedState }) => {
    const isVertical = component.direction === 'vertical';
    const spaceKey = isVertical ? 'margin-top' : 'margin-left';

    return css`
      ${pickAndApplyBackgroundColor(component, inFocusedState)}
      ${flexDirection(component)}
      ${applyStyles(component, inFocusedState)}

       > * {
        &:not(:first-child) {
          ${spaceKey}: ${component.spacing || 0}px;
        }
      }
    `;
  }}
`;

export default function VolumeControlButton({
  component,
  inFocusedState,
  groupId,
  minSDKVersion,
}: ComponentProps<TVolumeControlComponent>) {
  const videoMuted = useAppSelector(
    ({ paywallBuilder }) => paywallBuilder.videoMuted
  );
  const components = videoMuted
    ? component.mutedComponents
    : component.volumeComponents;

  return (
    <HoverTag
      title={component.namiComponentType ? component.title || component.id : ''}
      namiComponentType={component.namiComponentType}
      id={component.id}
    >
      <ButtonWrapper component={component} inFocusedState={inFocusedState}>
        {components.map((child, j) => (
          <TemplateComponent
            key={j}
            component={child}
            inFocusedState={inFocusedState}
            groupId={groupId}
            minSDKVersion={minSDKVersion}
          />
        ))}
      </ButtonWrapper>
    </HoverTag>
  );
}
