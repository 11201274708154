import { TFont } from '../api/types/main.types';

export function loadFonts(fonts: TFont[]): Promise<FontFaceSet> {
  const existingFonts = new Set(
    // @ts-ignore
    Array.from(document.fonts.values()).map(
      (fontFace) => (fontFace as FontFace).family
    )
  );
  fonts.forEach((font) => {
    const name = buildFontFaceFamily(font);
    if (existingFonts.has(name)) return;
    const fontFace = new FontFace(name, `url(${font.file})`);
    // @ts-ignore
    document.fonts.add(fontFace);
  });
  return document.fonts.ready.then();
}

export function buildFontFaceFamily(font: TFont): string {
  return sanitizeFontName(`${font.family}-${font.style}`);
}

export function sanitizeFontName(value: string): string {
  return value.replace(/ /g, '');
}

export function extractFontFaceData(
  fontFaceName: string
): Pick<TFont, 'family' | 'style'> | null {
  const familyStyleSeparatorIndex = fontFaceName.lastIndexOf('-');
  if (familyStyleSeparatorIndex < 0) return null;
  const family = fontFaceName.slice(0, familyStyleSeparatorIndex);
  const style = fontFaceName.slice(familyStyleSeparatorIndex + 1);
  return { family, style };
}

export function findDefaultFont(fonts: TFont[]): TFont {
  const firstFont = fonts[0];
  if (fonts.length === 1) return firstFont;
  const font = fonts.find(({ style }) => style.toLowerCase() === 'regular');
  return font || firstFont;
}
