import React, { useEffect, useState } from 'react';

import * as Icons from '@ant-design/icons';
import { Menu } from 'antd';
import styled from 'styled-components';

import api from '../../../../api';
import { useAppSelector } from '../../../../hooks/redux.hooks';
import { capitalizePhrase } from '../../../../utils/string';

interface GroupsMenuLegacyProps {
  onGroupClick: (groupName: string) => void;
}

const GroupMenu = styled(Menu)`
  border: none;
`;

const GroupMenuItem = styled(Menu.Item)`
  padding: 0 !important;
`;

const GroupAccessIcon = styled(Icons.RightOutlined)`
  float: right;
  margin-top: 12px;
`;

export default function GroupsMenuLegacy({
  onGroupClick,
}: GroupsMenuLegacyProps) {
  const [orderedGroups, setOrderedGroups] = useState<string[]>([]);
  const [groupIcons, setGroupIcons] = useState<{
    [key: string]: keyof typeof Icons;
  }>({});

  const fieldGroups = useAppSelector(
    ({ paywallBuilder }) => paywallBuilder.fieldGroups
  );

  useEffect(() => {
    if (!fieldGroups) return;
    api.getTemplateMetadata().then((metadata) => {
      setGroupIcons(metadata.groupIcons);
      setOrderedGroups(metadata.orderedGroups);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldGroups]);

  if (!fieldGroups) return null;
  return (
    <GroupMenu selectable={false}>
      {orderedGroups
        .filter((groupName) => groupName in fieldGroups)
        .map((groupName) => {
          const Icon = Icons[groupIcons[groupName]] as React.FC;
          return (
            <GroupMenuItem
              key={groupName}
              onClick={() => onGroupClick(groupName)}
            >
              <Icon />
              <span>{capitalizePhrase(groupName)}</span>
              <GroupAccessIcon />
            </GroupMenuItem>
          );
        })}
    </GroupMenu>
  );
}
