import React from 'react';

import { Pause, PlayArrowRounded } from '@mui/icons-material';
import { Button, Tooltip } from 'antd';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

export default function PaywallVideoPlayStatePicker() {
  const videoPlaying = useAppSelector(
    ({ paywallBuilder: { videoPlaying } }: RootState) => videoPlaying
  );
  const actions = useActions(PaywallBuilderSlice.actions);

  return (
    <>
      <Tooltip
        title={`Preview the paywall with ${
          videoPlaying ? ' video paused' : ' video playing'
        } `}
        mouseEnterDelay={0.8}
      >
        <Button
          type={'ghost'}
          icon={
            videoPlaying ? (
              <Pause style={{ transform: 'translateY(1.5px)' }} />
            ) : (
              <PlayArrowRounded style={{ transform: 'translateY(1.5px)' }} />
            )
          }
          size="small"
          onClick={togglePlayingState}
        />
      </Tooltip>
    </>
  );

  function togglePlayingState() {
    actions.setVideoPlaying(!videoPlaying);
  }
}
