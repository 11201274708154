import React from 'react';

import { connect } from 'react-redux';
import { Button, Checkbox, Form, Segment } from 'semantic-ui-react';

import { useAppContext } from '../../../../hooks';
import { handlePaywallField } from '../../../../redux/actions/paywallActions';
import styles from '../PaywallSidebar.module.less';

const Layout = ({ paywall, handlePaywallField }) => {
  const context = useAppContext();
  const toggleControl = (value) => {
    handlePaywallField({ ...paywall, [value]: !paywall[value] });
  };

  const renderForm = () => {
    return (
      <>
        <Checkbox
          label="Use Bottom Overlay"
          slider
          checked={paywall.use_bottom_overlay}
          disabled={!context.userHasEntitlement('app.paywall.update')}
          onChange={(e, { checked }) => toggleControl('use_bottom_overlay')}
        />
        <p style={{ marginTop: '1rem' }}>
          This will remove the background color of the bottom overlay.
        </p>
        <Checkbox
          label="Products In Overlay"
          slider
          checked={paywall.skus_in_scrollable_region}
          disabled={!context.userHasEntitlement('app.paywall.update')}
          onChange={(e, { checked }) =>
            toggleControl('skus_in_scrollable_region')
          }
        />
        <p style={{ marginTop: '1rem' }}>
          This will show products inside the overlay.
        </p>
        <Form.Field>
          <label>Bottom Overlay Size</label>
          <Button.Group>
            <Button
              className={
                paywall.scrollable_region_size === 'S' ? styles.grey : ''
              }
              onClick={() =>
                handlePaywallField({ ...paywall, scrollable_region_size: 'S' })
              }
            >
              S
            </Button>
            <Button
              className={
                paywall.scrollable_region_size === 'M' ? styles.grey : ''
              }
              onClick={() =>
                handlePaywallField({ ...paywall, scrollable_region_size: 'M' })
              }
            >
              M
            </Button>
            <Button
              className={
                paywall.scrollable_region_size === 'L' ? styles.grey : ''
              }
              onClick={() =>
                handlePaywallField({ ...paywall, scrollable_region_size: 'L' })
              }
            >
              L
            </Button>
          </Button.Group>
        </Form.Field>
      </>
    );
  };

  return <Segment basic>{renderForm()}</Segment>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlePaywallField: (newData) => dispatch(handlePaywallField(newData)),
  };
};

const mapStateToProps = ({ paywall, user }) => {
  return {
    paywall: paywall.paywall,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
