import React from 'react';

import { Form, Modal, Select } from 'antd';
import { useAddProductLanguageMutation } from 'src/hooks/queries/product.hooks';
import { useAppSelector } from 'src/hooks/redux.hooks';

type NewLanguageModalProps = {
  productId: string;
  isOpen: boolean;
  onClose: () => void;
};

export default function NewLanguageModal({
  productId,
  isOpen,
  onClose,
}: NewLanguageModalProps) {
  const { languages, defaultLanguage } = useAppSelector(({ root }) => ({
    languages: root.languages as [{ code: string; language: string }],
    defaultLanguage: root.currentApp.default_language as string,
  }));
  const [form] = Form.useForm();
  const newLanguageMutation = useAddProductLanguageMutation(productId, () => {
    onClose();
    form.resetFields();
  });
  const languagesOptions = languages.map(({ code: value, language }) => {
    const suffix = defaultLanguage === value ? ' - Default' : '';
    const label = `${language} - (${value.toUpperCase()})` + suffix;
    return { value, label };
  });

  return (
    <Modal
      title="Add Language"
      open={isOpen}
      onCancel={onClose}
      closable={false}
      confirmLoading={newLanguageMutation.isLoading}
      onOk={() => newLanguageMutation.mutate(form.getFieldValue('language'))}
      okText="Add"
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Language"
          name="language"
          className="intercom-productLanguageSelector"
        >
          <Select placeholder="Select Language" options={languagesOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
