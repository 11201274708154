import React from 'react';

import { FileDoneOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table, Typography } from 'antd';
import { ColumnType } from 'antd/lib/table';
import {
  PlanMeta,
  RawPlanName,
  TInternalProduct,
  TierNames,
} from 'src/api/types/main.types';
import SectionTitle from 'src/components/SectionTitle';
import PaywallsAndSubsCrossgradeWebPaywall from 'src/components/WebPaywalls/PaywallsAndSubsCrossgradeWebPaywall';
import { DEV_MODE } from 'src/utils/dev';

import { useAppContext, useBooleanState } from '../../../../hooks';

const BillingPage = () => {
  const appContext = useAppContext();
  const planName = appContext.getPlanName();
  const parsedPlan = { key: 'plan', ...PlanMeta[planName as RawPlanName] };
  const hasAccessToUpdatePlanPayment = appContext.userHasEntitlement(
    'org.billing.payment.update'
  );
  const hasAccessToViewPlanPrice = appContext.userHasEntitlement(
    'org.billing.payment.view'
  );
  const [isUpgradeOpen, openUpgrade, closeUpgrade] = useBooleanState(false);

  const tableColumns: ColumnType<TInternalProduct>[] = [
    {
      key: 'tier',
      title: 'Plan',
      render: (record: TInternalProduct) => (
        <Typography.Text>{TierNames[record.tier]}</Typography.Text>
      ),
    },
    {
      key: 'plan',
      title: 'Tier',
      render: (record: TInternalProduct) =>
        record.shortPlanName ? (
          <Typography.Text>{record.shortPlanName}</Typography.Text>
        ) : (
          <Typography.Text>{record.fullReadablePlanName}</Typography.Text>
        ),
    },
  ];

  if (hasAccessToViewPlanPrice) {
    tableColumns.push({
      key: 'price',
      title: 'Price',
      render: (record: TInternalProduct) => (
        <Typography.Text>${record.basePricePerMonth} / month</Typography.Text>
      ),
    });
  }

  return (
    <>
      <Row style={{ paddingBottom: 24 }}>
        <Col span={12}>
          <SectionTitle>Billing</SectionTitle>
        </Col>
        <Col span={12}>
          {hasAccessToUpdatePlanPayment && (
            <Button
              type="link"
              icon={<FileDoneOutlined />}
              href={getCustomerPortalLink()}
              target="_blank"
              style={{ float: 'right' }}
            >
              Manage Nami Subscription
            </Button>
          )}
        </Col>
      </Row>
      <Table
        dataSource={[parsedPlan]}
        columns={tableColumns}
        pagination={false}
        style={{ paddingBottom: 48 }}
        summary={(_currentData) => {
          return (
            parsedPlan.tier === 'paywallsOnly' &&
            hasAccessToUpdatePlanPayment && (
              <Table.Summary.Row key={'addProduct'}>
                <Table.Summary.Cell index={0} colSpan={3}>
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={openUpgrade}
                  >
                    Add Subscription Management
                  </Button>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )
          );
        }}
      />
      <PaywallsAndSubsCrossgradeWebPaywall
        visible={isUpgradeOpen}
        onCancel={closeUpgrade}
        plan={parsedPlan.product}
      />
    </>
  );

  function getCustomerPortalLink() {
    if (DEV_MODE)
      return 'https://billing.stripe.com/p/login/test_bIY6rf1op7bE0a47ss';
    return 'https://billing.stripe.com/p/login/14kdTQ4Gf6Hy1bO7ss';
  }
};

export default BillingPage;
