import React from 'react';

import { PlanMeta, TDisplayProduct } from 'src/api/types/main.types';

import UpgradeWebPaywall, { WebPaywallProps } from './UpgradeWebPaywall';

const LaunchContextWebPaywall = ({ onCancel, visible }: WebPaywallProps) => {
  const paywallsOnlyProducts: TDisplayProduct[] = [
    {
      product_value_props: [
        'Custom Paywall Variables and Data Sources',
        'Advanced Product Group Features',
        'White-glove support',
      ],
      ...PlanMeta['paywalls_only_enterprise_v1'],
    },
  ];

  const paywallsAndSubsProducts: TDisplayProduct[] = [
    {
      product_value_props: [
        'Custom Paywall Variables and Data Sources',
        'Advanced Product Group Features',
        'White-glove support',
      ],
      ...PlanMeta['paywalls_and_subs_enterprise_v1'],
    },
  ];

  return (
    <UpgradeWebPaywall
      modalTitle="Unlock Paywall Variables and Conditional Groups"
      productsPaywallsOnly={paywallsOnlyProducts}
      productsPaywallsAndSubs={paywallsAndSubsProducts}
      highlightedProductPaywallsOnly="paywalls_only_enterprise_v1"
      highlightedProductPaywallsAndSubs="paywalls_and_subs_enterprise_v1"
      entitlement="app.paywall.capabilities.custom_launch_context"
      onCancel={onCancel}
      visible={visible}
    />
  );
};

export default LaunchContextWebPaywall;
