import React, { useState } from 'react';

import { Button, Segment, Accordion, Icon } from 'semantic-ui-react';

import SectionTitle from '../SectionTitle';
import styles from './DeleteSegment.module.less';

type DeleteSegmentProps = {
  title?: string;
  topWarning?: string;
  bottomWarning?: string;
  action?: () => void;
  actionText?: string;
  dataCy?: string;
  disabled?: boolean;
};

export default function DeleteSegment({
  title = '',
  topWarning = '',
  bottomWarning = '',
  actionText = 'Remove',
  action = () => {},
  dataCy,
  disabled = false,
}: DeleteSegmentProps) {
  const [open, toggle] = useState(false);

  return (
    <Accordion>
      <Accordion.Title onClick={() => toggle(!open)} active={open}>
        <SectionTitle>
          Danger Zone
          <Icon name="dropdown" />
        </SectionTitle>
      </Accordion.Title>
      <Accordion.Content active={open}>
        <Segment>
          <h3 className={styles.title}>{title}</h3>
          <p>{topWarning}</p>
          <p className={styles.paragraph}>{bottomWarning}</p>
          <Button
            data-cy={dataCy}
            disabled={disabled}
            onClick={action}
            className={styles.button}
          >
            {actionText}
          </Button>
        </Segment>
      </Accordion.Content>
    </Accordion>
  );
}
