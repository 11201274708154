import React from 'react';

import { Form, FormItemProps } from 'antd';

export interface OurFormItemProps extends FormItemProps {
  error?: string;
}

export default function FormItem({
  children,
  error,
  validateStatus,
  help,
  ...props
}: OurFormItemProps) {
  const status = error ? 'error' : validateStatus || '';
  return (
    <Form.Item {...props} validateStatus={status} help={error || help}>
      {children}
    </Form.Item>
  );
}
