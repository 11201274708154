import React from 'react';

import Page from '../../../components/Page/Page';
import PreAuthBase from './PreAuthBase';

const PreAuthRecoveryPage = ({ match }) => {
  return (
    <Page title="Pre Authentication Recovery">
      <PreAuthBase
        header="Account Recovery"
        subheader="Enter one of your backup recovery codes to regain access to your account."
        authType={match.params.type}
        cancelTo="/login/"
        cancelLabel="Cancel"
      />
    </Page>
  );
};

export default PreAuthRecoveryPage;
