import { toast } from 'react-toastify';

import * as TYPES from '../types';

const initialState = {
  loading: true,
  changes: false,
  locales: [],
  locale: {
    language: '',
    close_button_text: '',
    sign_in_button_text: '',
    restore_purchase_button_text: '',
  },
  languages: [],
  localeNewError: null,
};

export const localeReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LOCALES:
    case TYPES.GET_LOCALES_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_LOCALES_SUCCESS:
      return {
        ...state,
        locales: action.payload.data.results,
        localeError: null,
        loading: false,
      };
    case TYPES.GET_LOCALES_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.ADD_LOCALE:
    case TYPES.ADD_LOCALE_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.ADD_LOCALE_SUCCESS:
      toast.success('Successfully added language.');
      return {
        ...state,
        loading: false,
        locale: {
          ...initialState.locale,
        },
        localeNewError: null,
      };
    case TYPES.ADD_LOCALE_FAIL:
      return {
        ...state,
        localeNewError: action.error.response.data,
        loading: false,
      };
    case TYPES.UPDATE_LOCALE:
    case TYPES.UPDATE_LOCALE_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.UPDATE_LOCALE_SUCCESS:
      toast.success('Successfully updated language.');
      return {
        ...state,
        loading: false,
        changes: false,
        localeError: null,
      };
    case TYPES.UPDATE_LOCALE_FAIL:
      return {
        ...state,
        localeError: action.error.response.data,
        loading: false,
      };

    case TYPES.DELETE_LOCALE:
    case TYPES.DELETE_LOCALE_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.DELETE_LOCALE_SUCCESS:
      toast.success('Successfully deleted language.');
      return {
        ...state,
        loading: false,
      };
    case TYPES.DELETE_LOCALE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.HANDLE_LOCALE_FIELD:
      return {
        ...state,
        locale: {
          ...state.locale,
          [action.payload.key]: action.payload.value,
        },
      };
    case TYPES.HANDLE_LOCALES_FIELD:
      return {
        ...state,
        locales: state.locales.map((locale) =>
          locale.id === action.payload.id
            ? { ...locale, [action.payload.key]: action.payload.value }
            : locale
        ),
        changes: true,
      };
    case TYPES.HANDLE_LOCALE_CLEAR_ERROR:
      return {
        ...state,
        localeNewError: null,
        localeError: null,
      };

    default:
      return state;
  }
};
