import { filterArrayObjects, filterArrayByType } from '../../services/helpers';
import * as TYPES from '../types';

const initialState = {
  loading: true,
  filtering: false,

  dropdownEnvironment: '',
  dropdownEventType: '',

  sessions: [],
  impressions: [],
  transactions: [],
  appleNotifications: [],
  androidNotifications: [],
  entitlements: [],

  sessionsRealTime: [],
  transactionsRealTime: [],
  impressionsRealTime: [],
  appleNotificationsRealTime: [],
  androidNotificationsRealTime: [],
  entitlementsRealTime: [],

  records: [],
  record: null,
  realTime: true,
  lastRecord: null,
};

export const developerReducer = (state = initialState, action) => {
  let records = [
    ...state.transactions,
    ...state.impressions,
    ...state.sessions,
    ...state.appleNotifications,
    ...state.androidNotifications,
    ...state.entitlements,
  ];
  let all = [];
  records
    .sort((a, b) => new Date(b.frontend_date) - new Date(a.frontend_date))
    .map((item) => all.push(item));

  let convertArray = ({ array, type, icon, date = false }) => {
    return array.map((item) => {
      return {
        ...item,
        type: type,
        icon: icon,
        frontend_date: date
          ? item.start_time
          : item.purchase_date || item.created_date,
      };
    });
  };

  switch (action.type) {
    case TYPES.GET_DEVELOPER_ENTITLEMENTS:
    case TYPES.GET_DEVELOPER_ENTITLEMENTS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_DEVELOPER_ENTITLEMENTS_SUCCESS:
      return {
        ...state,
        entitlements: convertArray({
          array: action.payload.data,
          type: 'Entitlement',
          icon: 'nami symbol',
        }),
        loading: false,
      };
    case TYPES.GET_DEVELOPER_ENTITLEMENTS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_DEVELOPER_ENTITLEMENTS_REAL_TIME:
    case TYPES.GET_DEVELOPER_ENTITLEMENTS_REAL_TIME_LOAD:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_DEVELOPER_ENTITLEMENTS_REAL_TIME_SUCCESS:
      return {
        ...state,
        entitlementsRealTime: convertArray({
          array: action.payload.data,
          type: 'Entitlement',
          icon: 'nami symbol',
        }),
        loading: false,
      };
    case TYPES.GET_DEVELOPER_ENTITLEMENTS_REAL_TIME_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_DEVELOPER_TRANSACTIONS:
    case TYPES.GET_DEVELOPER_TRANSACTIONS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_DEVELOPER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: convertArray({
          array: action.payload.data,
          type: 'Transaction',
          icon: 'sdk',
        }),
        loading: false,
      };
    case TYPES.GET_DEVELOPER_TRANSACTIONS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_DEVELOPER_TRANSACTIONS_REAL_TIME:
    case TYPES.GET_DEVELOPER_TRANSACTIONS_REAL_TIME_LOAD:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_DEVELOPER_TRANSACTIONS_REAL_TIME_SUCCESS:
      return {
        ...state,

        transactionsRealTime: convertArray({
          array: action.payload.data,
          type: 'Transaction',
          icon: 'sdk',
        }),
        loading: false,
      };
    case TYPES.GET_DEVELOPER_TRANSACTIONS_REAL_TIME_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_DEVELOPER_IMPRESSIONS:
    case TYPES.GET_DEVELOPER_IMPRESSIONS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_DEVELOPER_IMPRESSIONS_SUCCESS:
      return {
        ...state,
        impressions: convertArray({
          array: action.payload.data,
          type: 'Impression',
          icon: 'sdk',
        }),
        loading: false,
      };
    case TYPES.GET_DEVELOPER_IMPRESSIONS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_DEVELOPER_IMPRESSIONS_REAL_TIME:
    case TYPES.GET_DEVELOPER_IMPRESSIONS_REAL_TIME_LOAD:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_DEVELOPER_IMPRESSIONS_REAL_TIME_SUCCESS:
      return {
        ...state,
        impressionsRealTime: convertArray({
          array: action.payload.data,
          type: 'Impression',
          icon: 'sdk',
        }),
        loading: false,
      };
    case TYPES.GET_DEVELOPER_IMPRESSIONS_REAL_TIME_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_DEVELOPER_SESSIONS:
    case TYPES.GET_DEVELOPER_SESSIONS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_DEVELOPER_SESSIONS_SUCCESS:
      return {
        ...state,
        sessions: convertArray({
          array: action.payload.data,
          type: 'Session',
          icon: 'sdk',
          date: true,
        }),
        loading: false,
      };
    case TYPES.GET_DEVELOPER_SESSIONS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_DEVELOPER_SESSIONS_REAL_TIME:
    case TYPES.GET_DEVELOPER_SESSIONS_REAL_TIME_LOAD:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_DEVELOPER_SESSIONS_REAL_TIME_SUCCESS:
      return {
        ...state,
        sessionsRealTime: convertArray({
          array: action.payload.data,
          type: 'Session',
          icon: 'sdk',
          date: true,
        }),
        loading: false,
      };
    case TYPES.GET_DEVELOPER_SESSIONS_REAL_TIME_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_DEVELOPER_APPLE_NOTIFICATIONS:
    case TYPES.GET_DEVELOPER_APPLE_NOTIFICATIONS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_DEVELOPER_APPLE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        appleNotifications: convertArray({
          array: action.payload.data,
          type: 'Apple Notification',
          icon: 'products',
        }),
        loading: false,
      };
    case TYPES.GET_DEVELOPER_APPLE_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_DEVELOPER_APPLE_NOTIFICATIONS_REAL_TIME:
    case TYPES.GET_DEVELOPER_APPLE_NOTIFICATIONS_REAL_TIME_LOAD:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_DEVELOPER_APPLE_NOTIFICATIONS_REAL_TIME_SUCCESS:
      return {
        ...state,
        appleNotificationsRealTime: convertArray({
          array: action.payload.data,
          type: 'Apple Notification',
          icon: 'products',
        }),
        loading: false,
      };
    case TYPES.GET_DEVELOPER_APPLE_NOTIFICATIONS_REAL_TIME_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_DEVELOPER_ANDROID_NOTIFICATIONS:
    case TYPES.GET_DEVELOPER_ANDROID_NOTIFICATIONS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_DEVELOPER_ANDROID_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        androidNotifications: convertArray({
          array: action.payload.data,
          type: 'Android Notification',
          icon: 'products',
        }),
        loading: false,
      };
    case TYPES.GET_DEVELOPER_ANDROID_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_DEVELOPER_ANDROID_NOTIFICATIONS_REAL_TIME:
    case TYPES.GET_DEVELOPER_ANDROID_NOTIFICATIONS_REAL_TIME_LOAD:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_DEVELOPER_ANDROID_NOTIFICATIONS_REAL_TIME_SUCCESS:
      return {
        ...state,
        androidNotificationsRealTime: convertArray({
          array: action.payload.data,
          type: 'Android Notification',
          icon: 'products',
        }),
        loading: false,
      };
    case TYPES.GET_DEVELOPER_ANDROID_NOTIFICATIONS_REAL_TIME_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_DEVELOPER_RECORDS:
      return {
        ...state,
        lastRecord: [...new Set(all)][0],
        records: [...new Set(all)],
        loading: false,
      };

    case TYPES.GET_DEVELOPER_RECORDS_REAL_TIME:
      let results;
      state.records.forEach((item) => {
        delete item.recent;
      });
      if (state.lastRecord) {
        let recordsRealTime = [
          ...state.transactionsRealTime,
          ...state.impressionsRealTime,
          ...state.sessionsRealTime,
          ...state.appleNotificationsRealTime,
          ...state.androidNotificationsRealTime,
          ...state.entitlementsRealTime,
        ];
        results = recordsRealTime
          .filter(
            (item) =>
              new Date(item.frontend_date).getTime() >
              new Date(state.lastRecord.frontend_date).getTime()
          )
          .sort((a, b) => new Date(a.frontend_date) - new Date(b.frontend_date))
          .map((item) => state.records.unshift({ ...item, recent: true }));
      }
      return {
        ...state,
        lastRecord:
          results && results.length ? state.records[0] : state.lastRecord,
        realTime: results && results.length,
      };

    case TYPES.HANDLE_DEVELOPER_RECORD:
      return {
        ...state,
        record: action.payload.record,
      };

    case TYPES.HANDLE_DEVELOPER_SEARCH:
      return {
        ...state,
        filtering: action.payload.value,
        records: filterArrayObjects(all, action.payload.value),
      };

    case TYPES.HANDLE_DEVELOPER_DROPDOWN:
      return {
        ...state,
        filtering: action.payload.value,
        dropdownEnvironment:
          action.payload.type === 'environment' ? action.payload.text : '',
        dropdownEventType:
          action.payload.type === 'eventType' ? action.payload.text : '',
        records: filterArrayByType(
          all,
          action.payload.value,
          action.payload.type
        ),
      };

    default:
      return state;
  }
};
