import React from 'react';

import { PlanMeta, TDisplayProduct } from 'src/api/types/main.types';

import UpgradeWebPaywall, { WebPaywallProps } from './UpgradeWebPaywall';

export const InfinitePlacementsWebPaywall = ({
  onCancel,
  visible,
}: WebPaywallProps) => {
  const paywallsOnlyProducts: TDisplayProduct[] = [
    {
      product_value_props: [
        '3 paywall placements',
        'Paywall A/B Testing',
        'Audience filtering',
      ],
      ...PlanMeta['paywalls_only_team_v1'],
    },
    {
      product_value_props: [
        'Unlimited paywall placements',
        'Deeplink URL Placements',
        'Multi-variate testing',
        'Advanced campaign filtering',
      ],
      ...PlanMeta['paywalls_only_business_v1'],
    },
    {
      product_value_props: [
        'Bulk placement editing',
        'Export placements',
        'External audiences',
        'Volume pricing & SLA',
      ],
      ...PlanMeta['paywalls_only_enterprise_v1'],
    },
  ];

  const paywallsAndSubsProducts: TDisplayProduct[] = [
    {
      product_value_props: [
        '3 paywall placements',
        'Paywall A/B Testing',
        'Audience filtering',
      ],
      ...PlanMeta['paywalls_and_subs_team_v1'],
    },
    {
      product_value_props: [
        'Unlimited paywall placements',
        'Deeplink URL Placements',
        'Multi-variate testing',
        'Advanced campaign filtering',
      ],
      ...PlanMeta['paywalls_and_subs_business_v1'],
    },
    {
      product_value_props: [
        'Bulk placement editing',
        'Export placements',
        'External audiences',
        'Volume pricing & SLA',
      ],
      ...PlanMeta['paywalls_and_subs_enterprise_v1'],
    },
  ];

  return (
    <UpgradeWebPaywall
      modalTitle="Unlock Unlimited Placements"
      productsPaywallsOnly={paywallsOnlyProducts}
      productsPaywallsAndSubs={paywallsAndSubsProducts}
      highlightedProductPaywallsOnly="paywalls_only_business_v1"
      highlightedProductPaywallsAndSubs="paywalls_and_subs_business_v1"
      entitlement="app.placement.create_infinite"
      onCancel={onCancel}
      visible={visible}
    />
  );
};
