import React from 'react';

import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Row, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHistory } from 'react-router-dom';
import { AppType } from 'src/api/types/app.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import SectionTitle from 'src/components/SectionTitle';
import { useAppContext } from 'src/hooks';
import { useAppsQuery } from 'src/hooks/queries/app.hooks';
import { useAppDispatch } from 'src/hooks/redux.hooks';
import { getApps } from 'src/redux/actions/rootActions';
import { toggleNewAppModal } from 'src/redux/actions/uiActions';
import { defaultImage } from 'src/services/helpers';
import { setAppId } from 'src/services/LocalStorageService';

export default function AppsPageNew() {
  const history = useHistory();
  const context = useAppContext();
  const dispatch = useAppDispatch();
  const appsQuery = useAppsQuery();
  const loading = appsQuery.isFetching || appsQuery.isLoading;
  const apps = appsQuery.data?.results || [];
  const dataSource = apps.map((app) => ({ ...app, key: app.id }));

  return (
    <>
      <SectionTitle>Apps</SectionTitle>
      <Row style={{ marginBottom: 24, marginTop: 12, float: 'right' }}>
        <Col xs={24}>
          <Button
            icon={<PlusOutlined />}
            disabled={!context.userHasEntitlement('app.create')}
            onClick={() => dispatch(toggleNewAppModal())}
          >
            Add App
          </Button>
        </Col>
      </Row>
      <Table
        dataSource={dataSource}
        rowKey="id"
        columns={getColumns()}
        style={{ cursor: 'default' }}
        loading={loading}
      />
    </>
  );

  function getColumns(): ColumnsType<AppType> {
    return [
      {
        title: <Typography.Text strong>App Name</Typography.Text>,
        render: (app: AppType) => renderAppName(app),
      },
      {
        title: <Typography.Text strong>Actions</Typography.Text>,
        render: (app: AppType) => renderAppActions(app),
      },
    ];
  }

  function renderAppName(app: AppType): React.ReactNode {
    return (
      <Space direction="horizontal" size={8} wrap>
        <Avatar
          shape="square"
          src={app.logo || defaultImage(app.name)}
          style={{ borderRadius: 6, border: '1px solid white' }}
        />
        <Typography.Text>{app.name}</Typography.Text>
      </Space>
    );
  }

  function renderAppActions(app: AppType): React.ReactNode {
    return (
      <Space direction="horizontal" size={6}>
        <IconActionButton
          type="text"
          size="small"
          icon={<EditOutlined style={{ fontSize: '13px' }} />}
          disabled={!context.userHasEntitlement('app.update')}
          onClick={() => {
            setAppId(app.id);
            dispatch(getApps());
            history.push(`/app-settings/`);
          }}
        >
          Edit
        </IconActionButton>
      </Space>
    );
  }
}
