import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';
import { TOrgSecretKindEnum } from 'src/api/types/main.types';
import { useStoreCredentialsForm } from 'src/hooks/ant-form.hooks';
import { isTruthy } from 'src/utils/array';

import CredentialsForm from './CredentialsForm';
import SecretFileUpload from './SecretFileUpload';
import { buildFormItemProps } from './utils';

export default function AppleIAPCredentialsForm() {
  const form = useStoreCredentialsForm('apple');

  const hasIAP = !!form.secrets.apple_in_app_purchase;
  return (
    <CredentialsForm
      form={form}
      colon={false}
      layout="vertical"
      onFieldsChange={form.handleFieldsChange}
      className="intercom-appleiapcredentials"
    >
      <Form.Item
        label="Issuer ID"
        tooltip={{
          title:
            'Issuer ID is found in App Store Connect > Users and Access > Integrations > In-App Purchase',
        }}
        className="intercom-appleissuerid"
        {...buildFormItemProps(
          ['apple_in_app_purchase', 'public', 'issuer_id'],
          form.errors
        )}
      >
        <Input
          maxLength={36}
          disabled={hasIAP}
          placeholder="App Store Connect Issuer ID"
        />
      </Form.Item>
      <Form.Item
        label="Key ID"
        tooltip={{
          title:
            'Key ID is found in App Store Connect > Users and Access > Integrations > In App Purchase. Generate a Key with App Manager access to get this id and the Private Key file (below).',
        }}
        className="intercom-applekeyid"
        {...buildFormItemProps(
          ['apple_in_app_purchase', 'public', 'key_id'],
          form.errors
        )}
      >
        <Input
          maxLength={10}
          disabled={hasIAP}
          placeholder="App Store Connect Key ID"
        />
      </Form.Item>
      <SecretFileUpload
        label="Private Key"
        tooltipContent={
          <>
            Private Key is found in App Store Connect {'> '} Users and Access{' '}
            {'> '} Integrations {'> '} In App Purchase. Generate a Key with App
            Manager Access to get this file and the Key ID (above).
          </>
        }
        {...buildFormItemProps(
          ['apple_in_app_purchase', 'secret', 'private_key'],
          form.errors
        )}
        onChange={form.updateFieldValue}
        disabled={hasIAP}
        fileType=".p8"
      />
      <Space style={{ marginTop: '2rem' }}>
        <Button
          type="primary"
          disabled={hasIAP || !form.canCreate}
          loading={form.createMutation.isLoading}
          onClick={createAppleSecrets}
        >
          Save
        </Button>
        <Button
          danger
          type="link"
          loading={form.deleteMutation.isLoading}
          disabled={!hasIAP || !form.canDelete}
          onClick={() => {
            const secretIds = [form.secrets.apple_in_app_purchase?.id].filter(
              isTruthy
            );
            form.deleteMutation.mutate(secretIds);
          }}
        >
          <Space>
            <DeleteOutlined />
            Delete these credentials
          </Space>
        </Button>
      </Space>
    </CredentialsForm>
  );

  function createAppleSecrets() {
    const { apple_in_app_purchase } = form.getFieldsValue();
    const payloads = [];
    if (!hasIAP) {
      payloads.push({
        ...apple_in_app_purchase,
        kind: TOrgSecretKindEnum.AppleInAppPurchase,
      });
    }
    form.createMutation.mutate(payloads);
  }
}
