import React from 'react';

import { PreviewDeviceMeta } from 'src/api/types/paywall.types';
import { useAppSelector } from 'src/hooks/redux.hooks';
import styled from 'styled-components';

import { colorBlack, colorNegative } from '../../variables';

type TVProps = {
  className?: string;
  children: JSX.Element | JSX.Element[];
  inFocusedState: boolean;
};

const Wrapper = styled.div`
  height: fit-content;
  width: fit-content;
  background-color: ${colorBlack};
  border-radius: 8px;
  margin: 0px auto;
  padding: 5px;
  z-index: 2;
  box-shadow: 0 35px 60px -24px rgba(0 0 0 / 0.4);
`;

const Body = styled.div`
  border-radius: 3px;
  width: fit-content;
  height: fit-content;
  background-color: ${colorBlack};
  position: relative;
  padding: 5px;
  z-index: 2;
  cursor: pointer;
`;

const TVView = styled.div`
  width: fit-content;
  height: fit-content;
  background-color: ${colorNegative};
  border-radius: 3px;
  position: relative;
  overflow: hidden;
`;

export const TVInner = styled.div<{ height: number; width: number }>`
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export default function TV({ children, ...props }: TVProps) {
  const { currentDevice } = useAppSelector(({ paywallBuilder }) => ({
    currentDevice: paywallBuilder.previewDevice,
  }));
  const currentDeviceMeta = PreviewDeviceMeta[currentDevice];

  return (
    <Wrapper {...props}>
      <Body className="namipreviewer tv">
        <TVView>
          <TVInner
            height={currentDeviceMeta.viewportHeight}
            width={currentDeviceMeta.viewportWidth}
          >
            {children}
          </TVInner>
        </TVView>
      </Body>
    </Wrapper>
  );
}
