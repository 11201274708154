import React from 'react';

import FilterSelect, { BaseFilterSelectProps } from './FilterSelect';
import { identifierLabel } from './mapsAndOptions';

export default function OSVersionSelect(props: BaseFilterSelectProps) {
  return (
    <FilterSelect
      {...props}
      label={identifierLabel['Device.os_version']}
      //TODO - support between
      operators={[
        'equals',
        'not_equals',
        'greater_than',
        'greater_than_or_equal_to',
        'less_than',
        'less_than_or_equal_to',
      ]}
      variant="manual_value"
    />
  );
}
