import React, { useContext, useEffect, useState } from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Upload } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { RcFile, UploadChangeParam } from 'antd/lib/upload/interface';
import PlanUpgradeButton from 'src/components/PlanUpgradeButton/PlanUpgradeButton';
import SectionTitle from 'src/components/SectionTitle';
import CustomFontWebPaywall from 'src/components/WebPaywalls/CustomFontWebPaywall';
import { useBooleanState } from 'src/hooks';
import styled from 'styled-components';

import api from '../../../../api';
import { AppContext } from '../../../../AppContextProvider';
import QueryKeys from '../../../../hooks/queries/queryKeys';

const BrandFontsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export default function FontSectionHeader() {
  const { userHasEntitlement, planHasEntitlement } = useContext(AppContext);
  const queryClient = useQueryClient();
  const [isUpgradeOpen, openUpgrade, closeUpgrade] = useBooleanState(false);
  const [isUploading, setUploading] = useState<boolean>(false);
  const [fileState, setFileState] = useState<{
    uploading: UploadFile[];
    uploaded: RcFile[];
  }>({
    uploading: [],
    uploaded: [],
  });

  useEffect(() => {
    const { uploaded, uploading } = fileState;
    if (uploading.length > 0 || uploaded.length === 0) return;
    api
      .addFonts(uploaded)
      .then(() => queryClient.invalidateQueries([QueryKeys.orgFonts]))
      .finally(() => {
        removeFiles(uploaded);
        setUploading(false);
      });
  }, [fileState, queryClient]);

  const userCannotCreate = !userHasEntitlement('font.create');
  const planCannotCreate = !planHasEntitlement('font.create');
  return (
    <>
      <BrandFontsHeader>
        <SectionTitle>Fonts</SectionTitle>
        {userCannotCreate && planCannotCreate && (
          <PlanUpgradeButton onClick={openUpgrade}>
            Upload Custom Font
          </PlanUpgradeButton>
        )}
        {!planCannotCreate && (
          <Upload
            multiple
            fileList={[]} // This field only takes care of uploading, not displaying
            disabled={userCannotCreate}
            customRequest={({ file }) => {
              setFileState((state) => ({
                ...state,
                uploading: state.uploading.filter(
                  ({ uid }) => uid !== (file as RcFile).uid
                ),
                uploaded: state.uploaded.concat(file as RcFile),
              }));
            }}
            accept=".ttf,.otf,.woff,.woff2"
            onChange={handleChange}
            className="intercom-orgBrand-uploadFont"
          >
            <Button
              disabled={isUploading || userCannotCreate}
              loading={isUploading}
              icon={<UploadOutlined />}
            >
              Upload Custom Font
            </Button>
          </Upload>
        )}
      </BrandFontsHeader>
      <CustomFontWebPaywall
        visible={isUpgradeOpen}
        onCancel={() => closeUpgrade()}
      />
    </>
  );

  function handleChange({ file }: UploadChangeParam): void {
    if (file.status === 'removed') {
      removeFiles([file]);
    } else if (file.status === 'uploading') {
      setUploading(true);
      setFileState((state) => ({
        ...state,
        uploading: state.uploading.concat([file]),
      }));
    }
  }

  function removeFiles(files: (RcFile | UploadFile)[]): void {
    const fileIds = new Set(files.map((file) => file.uid));
    setFileState((state) => ({
      ...state,
      uploading: state.uploading.filter(({ uid }) => !fileIds.has(uid)),
      uploaded: state.uploaded.filter(({ uid }) => !fileIds.has(uid)),
    }));
  }
}
