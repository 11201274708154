import { apiRequest } from '../../services/apiService';
import * as TYPES from '../types';

export const getLocales = (appId) => {
  return apiRequest({
    type: TYPES.GET_LOCALES,
    url: `/private/locale_configs/?app_id=${appId}`,
    method: 'GET',
  });
};

export const addLocale = (data) => {
  return apiRequest({
    type: TYPES.ADD_LOCALE,
    url: `/private/locale_configs/`,
    method: 'POST',
    data,
    options: {
      onSuccess({ next, dispatch, response }) {
        dispatch(getLocales(data.app));
        const nextAction = {
          type: TYPES.ADD_LOCALE_SUCCESS,
          payload: response,
        };
        next(nextAction);
        return nextAction;
      },
    },
  });
};

export const updateLocale = (localeId, data) => {
  return apiRequest({
    type: TYPES.UPDATE_LOCALE,
    url: `/private/locale_configs/${localeId}/`,
    method: 'PATCH',
    data,
  });
};

export const deleteLocale = (localeId) => {
  return apiRequest({
    type: TYPES.DELETE_LOCALE,
    url: `/private/locale_configs/${localeId}/`,
    method: 'DELETE',
  });
};

export const handleLocalesField = (localeId, key, value) => {
  return {
    type: TYPES.HANDLE_LOCALES_FIELD,
    payload: {
      id: localeId,
      key,
      value,
    },
  };
};

export const handleLocaleField = (key, value) => {
  return {
    type: TYPES.HANDLE_LOCALE_FIELD,
    payload: {
      key,
      value,
    },
  };
};

export const handleLocaleClearError = () => {
  return {
    type: TYPES.HANDLE_LOCALE_CLEAR_ERROR,
  };
};
