import { useContext } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'src/AppContextProvider';

import api from '../../api';
import {
  TOrgCDPIntegrationPayload,
  TOrgSecret,
  TOrgSecretPayload,
} from '../../api/types/main.types';
import { formatDynamicError } from '../../utils/schema';
import { useAppSelector } from '../redux.hooks';
import QueryKeys from './queryKeys';

export type TOrgSecretCreationErrors = Record<
  string,
  Record<string, Record<string, string | undefined>>
>;

export function useOrgSecretsQuery() {
  return useQuery([QueryKeys.orgSecrets], api.getOrgSecrets);
}

export function useCreateOrgSecretsMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    [QueryKeys.orgSecrets],
    async (dataList: TOrgSecretPayload[]) => {
      const kinds = dataList.map(({ kind }) => kind);
      const results = await Promise.allSettled(
        dataList.map((data) => api.createOrgSecret(data))
      );

      const responses = results.reduce((output, result) => {
        if (result.status !== 'fulfilled') return [];
        return [...output, result.value];
      }, [] as TOrgSecret[]);
      const errors = results.reduce((output, response, index) => {
        if (response.status !== 'rejected') return output;
        const kind = kinds[index];
        const reason = response.reason as Record<string, string[]>;
        return {
          ...output,
          [kind]: Object.entries(reason).reduce((output, [key, value]) => {
            return { ...output, [key]: formatDynamicError(value) };
          }, {}),
        };
      }, {} as TOrgSecretCreationErrors);

      const errorsLength = Object.keys(errors).length;
      if (errorsLength === results.length) {
        return Promise.reject(errors);
      }
      if (responses.length > 0) {
        await queryClient.invalidateQueries([QueryKeys.orgSecrets]);
      }
      return errorsLength > 0
        ? Promise.reject(errors)
        : Promise.resolve(responses);
    }
  );
}

export function useDeleteOrgSecretsMutation(onSuccess = () => {}) {
  const queryClient = useQueryClient();

  return useMutation(
    [QueryKeys.orgSecrets],
    (secretIds: string[]) => Promise.all(secretIds.map(api.deleteOrgSecret)),
    {
      onSuccess: () => {
        if (onSuccess) {
          onSuccess();
        }
        return queryClient.invalidateQueries([QueryKeys.orgSecrets]);
      },
    }
  );
}

export function useCDPIntegrationQuery(integrationId: string) {
  return useQuery([QueryKeys.cdpIntegrations, integrationId], () =>
    api.getCDPIntegration(integrationId)
  );
}

export function useCDPIntegrationsQuery() {
  const { userHasEntitlement } = useContext(AppContext);
  const appId = useAppSelector(({ root }) => root.currentApp.id);

  return useQuery(
    [QueryKeys.cdpIntegrations, appId],
    () => api.getCDPIntegrations(),
    {
      enabled: userHasEntitlement('org.cdp_integration.list'),
    }
  );
}

export function useAddCDPIntegrationMutation(onSuccess: () => void) {
  const queryClient = useQueryClient();
  return useMutation(
    [QueryKeys.cdpIntegrations],
    (payload: Partial<TOrgCDPIntegrationPayload>) => {
      return api.createCDPIntegration(payload);
    },
    {
      onSuccess: () => {
        notification.success({
          message: 'Added org integration successfully.',
        });
        onSuccess();
        return queryClient.invalidateQueries([QueryKeys.cdpIntegrations]);
      },
    }
  );
}

export function useEditCDPIntegrationMutation(integrationId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    ['updateCDPIntegration'],
    (values: Partial<TOrgCDPIntegrationPayload>) =>
      api.updateCDPIntegration(integrationId, { ...values }),
    {
      onSuccess: () => {
        notification.success({ message: 'CDP Integration updated.' });
        return queryClient.invalidateQueries([QueryKeys.cdpIntegrations]);
      },
    }
  );
}

export function useCDPIntegrationTokenQuery(integrationId: string) {
  return useQuery([QueryKeys.cdpIntegrationTokens, integrationId], () =>
    api.getCDPIntegrationTokens(integrationId)
  );
}

export function useCreateCDPIntegrationTokenQuery(
  integrationId: string,
  onSuccess = (token: string) => {}
) {
  const queryClient = useQueryClient();
  return useMutation(
    [QueryKeys.cdpIntegrationTokens],
    () => {
      return api.generateCDPIntegrationToken(integrationId);
    },
    {
      onSuccess: (data) => {
        notification.success({
          message: 'Added org token successfully.',
        });
        onSuccess(data.token);
        return queryClient.invalidateQueries([QueryKeys.cdpIntegrationTokens]);
      },
    }
  );
}

export function useRevokeCDPIntegrationTokenQuery(integrationId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    [QueryKeys.cdpIntegrationTokens],
    (payload: { tokenJTI: string }) => {
      return api.revokeCDPIntegrationToken(integrationId, payload.tokenJTI);
    },
    {
      onSuccess: () => {
        notification.success({
          message: 'Revoked org token',
        });
        return queryClient.invalidateQueries([QueryKeys.cdpIntegrationTokens]);
      },
    }
  );
}

export function useOrgMemberQuery(userId: string) {
  return useQuery([QueryKeys.orgMembers, userId], () =>
    api.getOrgMember(userId)
  );
}

export function useOrgRoleQuery() {
  return useQuery([QueryKeys.orgRoles], () => api.getOrgRoles());
}

export function useUpdateOrgMemberRoleMutation() {
  const queryClient = useQueryClient();

  return useMutation(
    ['updateOrgMemberRole'],
    ({ userId, roles }: { userId: string; roles: Array<string> }) =>
      api.updateOrgMemberRole(userId, roles),
    {
      onSuccess: () => {
        notification.success({ message: 'Team Member Role Updated.' });
        return Promise.all([
          queryClient.invalidateQueries([QueryKeys.orgMembers]),
        ]);
      },
    }
  );
}

export function useDeleteOrgMemberMutation(userId: string) {
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation(['deleteOrgMember'], () => api.removeOrgMember(userId), {
    onSuccess: () => {
      history.push('/settings?tab=team');
      notification.success({ message: 'User removed from team' });
      return Promise.all([
        queryClient.invalidateQueries([QueryKeys.orgMembers]),
      ]);
    },
  });
}
