import React, { useMemo } from 'react';

import { Col, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { TOrgCDPIntegrationDocLinks } from 'src/api/types/main.types';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import IntegrationCard from 'src/components/Cards/IntegrationCard/IntegrationCard';
import { useAppCDPIntegrationQuery } from 'src/hooks/queries/integration.hooks';
import { platformLogo } from 'src/services/helpers';
import styled from 'styled-components';

import Page from '../../../components/Page/Page';
import CDPForm from './CDPForm';
import { TIntegrationParams } from './params.types';

const FormCol = styled(Col)`
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: white;
  padding: 2rem 2rem 0 2rem !important;
  border-radius: 2px;
`;

export default function CDPIntegrationDetailPage() {
  const params = useParams<TIntegrationParams>();
  const cdpIntegrationQuery = useAppCDPIntegrationQuery(
    params.integrationId || ''
  );

  const integration = useMemo(() => {
    return cdpIntegrationQuery.data;
  }, [cdpIntegrationQuery]);

  const references: { title: string; url: string }[] = useMemo(() => {
    if (integration && integration.provider) {
      return [
        {
          title: 'Documentation',
          url: TOrgCDPIntegrationDocLinks[integration.provider],
        },
      ];
    }
    return [];
  }, [integration]);

  const pageTitle = integration?.display_name || 'Loading integration';
  return (
    <Page title={pageTitle}>
      <Row gutter={[16, 16]}>
        <Col sm={24}>
          <Breadcrumb
            items={[
              { name: 'Integrations', url: '/integrations/' },
              { name: pageTitle },
            ]}
          />
        </Col>
        <Col sm={24} md={8} lg={6}>
          {integration && (
            <IntegrationCard
              title={integration.display_name}
              subtitle={'Org CDP Integration'}
              logo={platformLogo(integration.provider)}
              resources={references}
              // status={!integration.implemented ? 'Coming Soon' : ''}
            />
          )}
        </Col>
        <FormCol sm={24} md={16} lg={18}>
          <CDPForm />
        </FormCol>
      </Row>
    </Page>
  );
}
