import { useCallback, useState } from 'react';

export function useBooleanState(
  initialState: boolean
): [boolean, () => void, () => void, () => void] {
  const [value, setValue] = useState(initialState);
  return [
    value,
    useCallback(() => setValue(true), []),
    useCallback(() => setValue(false), []),
    useCallback(() => setValue((flag) => !flag), []),
  ];
}
