import React, { useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Image, Space, notification } from 'antd';
import { defaultImage } from 'src/services/helpers';
import { removeAppId } from 'src/services/LocalStorageService';

import api from '../../api';
import { AppType } from '../../api/types/app.types';
import { addApp } from '../../redux/actions/appActions';
import { getApps } from '../../redux/actions/rootActions';
import { useAppDispatch } from '../redux.hooks';
import QueryKeys from './queryKeys';

export function useAppsQuery() {
  return useQuery([QueryKeys.apps], api.getApps);
}

export function useAppOptions() {
  const appsQuery = useAppsQuery();

  const appIdMap = useMemo(() => {
    return (appsQuery.data?.results || []).reduce(
      (output, app) => ({ ...output, [app.id]: app }),
      {} as Record<string, AppType>
    );
  }, [appsQuery.data?.results]);

  const appOptions = useMemo(() => {
    return (appsQuery.data?.results || []).map((app) => ({
      key: app.id,
      value: app.id,
      label: (
        <Space>
          <Image
            style={{ borderRadius: 2 }}
            src={app.logo || defaultImage(app.name)}
            width={24}
            height={24}
            preview={false}
          />
          {app.name}
        </Space>
      ),
      name: app.name,
    }));
  }, [appsQuery.data?.results]);

  return { appOptions, appIdMap } as const;
}

export function useNewAppMutation() {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  // @ts-ignore TODO: Remove ts-ignore
  return useMutation(
    ['newApp'],
    (name: string) => dispatch(addApp({ name })) as unknown as Promise<void>,
    {
      onSuccess: () => {
        return Promise.all([
          dispatch(getApps()),
          queryClient.invalidateQueries([QueryKeys.apps]),
        ]);
      },
    }
  );
}

export function useUpdateAppMutation(appId: string) {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  return useMutation(
    ['updateApp'],
    (values: AppType) => api.updateApp(appId, values),
    {
      onSuccess: () => {
        notification.success({ message: 'App Updated' });
        return Promise.all([
          dispatch(getApps()),
          queryClient.invalidateQueries([QueryKeys.apps]),
        ]);
      },
    }
  );
}

export function useDeleteAppMutation(appId: string) {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  return useMutation(['deleteApp'], () => api.deleteApp(appId), {
    onSuccess: () => {
      notification.success({ message: 'App Deleted' });
      removeAppId();
      return Promise.all([
        dispatch(getApps()),
        queryClient.invalidateQueries([QueryKeys.apps]),
      ]);
    },
  });
}
