import './polyfils';
import React from 'react';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import './index.less';
import App from './App';
import store from './redux';
import history from './services/history';
import * as serviceWorker from './serviceWorker';

const rootEl = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  rootEl
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

let comment = document.createComment(`
@license
MyFonts Webfont Build ID 4249435, 2021-12-16T14:03:18-0500
The fonts listed in this notice are subject to the End User License
Agreement(s) entered into by the website owner. All other parties are
explicitly restricted from using the Licensed Webfonts(s).
You may obtain a valid license at the URLs below.
Copyright: Copyright � 2016 by Radomir Tinkov. All rights reserved.
Webfont: Gilroy-Regular by Radomir Tinkov
URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/regular/
Webfont: Gilroy-Medium by Radomir Tinkov
URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/medium/
Webfont: Gilroy-SemiBold by Radomir Tinkov
URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/semi-bold/
Copyright: Copyright � 2016 by Radomir Tinkov. All rights reserved.
© 2021 MyFonts Inc
`);
document.body.appendChild(comment);

serviceWorker.unregister();
