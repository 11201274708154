import React from 'react';

import { Col, Row, Space, Typography } from 'antd';
import { TierType } from 'src/api/types/main.types';
import { namiPrimaryBlue, namiPureWhite } from 'src/variables';
import styled, { css } from 'styled-components';

type TierToggleProps = {
  selectedTier: TierType;
  onClick: (tier: TierType) => void;
};

const ToggleWrapper = styled.div`
  height: 45px;
  width: 420px;
  border-radius: 50px;
  border: 2px solid ${namiPureWhite};
  background: rgba(255, 255, 255, 0.15);
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    width: fit-content;
  }
`;

const ToggleSwitch = styled.div<{ $active: boolean }>`
  height: 100%;
  border-radius: 50px;
  padding: 0px;
  transition: all 0.5s;
  align-content: center;
  ${({ $active }) => {
    if ($active) {
      return css`
        background: ${namiPureWhite};
      `;
    }
    return css``;
  }}
`;

const ToggleText = styled(Typography.Text)<{ $active: boolean }>`
  font-weight: 600;
  font-size: 15px;
  transition: all 0.5s;
  line-height: normal;
  ${({ $active }) => {
    if ($active) {
      return css`
        color: ${namiPrimaryBlue};
      `;
    }
    return css`
      color: ${namiPureWhite};
    `;
  }}

  @media only screen and (max-width: 500px) {
    font-size: 13px;
    padding: 0px 12px;
  }
`;

export default function TierToggle({ selectedTier, onClick }: TierToggleProps) {
  return (
    <ToggleWrapper className="intercom-plan-pickTierToggle">
      <Row
        gutter={0}
        style={{
          width: '100%',
          height: '100%',
        }}
        justify="center"
        align="middle"
      >
        <Col
          span={12}
          style={{ height: '100%' }}
          className="intercom-plan-pickPaywallsOnlyTier"
        >
          <ToggleSwitch
            $active={selectedTier === 'paywallsOnly'}
            onClick={() => setTierAsActive('paywallsOnly')}
          >
            <Space align="center" style={{ height: '100%' }}>
              <ToggleText $active={selectedTier === 'paywallsOnly'}>
                Subscription Studio
              </ToggleText>
            </Space>
          </ToggleSwitch>
        </Col>
        <Col
          span={12}
          style={{ height: '100%' }}
          className="intercom-plan-pickPaywallsAndSubsTier"
        >
          <ToggleSwitch
            $active={selectedTier === 'paywallsAndSubscriptions'}
            onClick={() => setTierAsActive('paywallsAndSubscriptions')}
          >
            <Space align="center" style={{ height: '100%' }}>
              <ToggleText $active={selectedTier === 'paywallsAndSubscriptions'}>
                Subscription Studio+
              </ToggleText>
            </Space>
          </ToggleSwitch>
        </Col>
      </Row>
    </ToggleWrapper>
  );

  function setTierAsActive(tier: TierType) {
    onClick(tier);
  }
}
