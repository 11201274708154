import { TCarouselIndicatorContainer } from 'src/api/types/paywallTemplate.types';
import styled, { css } from 'styled-components';

import {
  applyStyles,
  flexDirection,
  pickAndApplyBackgroundColor,
} from '../css';

const CarouselIndicator = styled.div<{
  component: TCarouselIndicatorContainer;
}>`
  display: flex;

  ${({ component }) => {
    const background = pickAndApplyBackgroundColor(component);
    return css`
      ${background};
      ${flexDirection(component)}
      ${applyStyles(component)}
      margin: 0 5px;
      transition: background-color 0.6s ease;
    `;
  }}
`;

export default CarouselIndicator;
