import React, { useState } from 'react';

import cx from 'classnames';
import {
  Accordion,
  Button,
  Form,
  Icon,
  Segment,
  Sidebar,
} from 'semantic-ui-react';

import api from '../../../api';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import { useAppContext, usePromiseState } from '../../../hooks';
import DeleteSegment from '../../DeleteSegment/DeleteSegment';
import sharedStyles from '../SharedStyles.module.less';
import Content from './Sections/Content';
import Controls from './Sections/Controls';
import Images from './Sections/Images';
import Layout from './Sections/Layout';
import Legal from './Sections/Legal';
import Overview from './Sections/Overview';

const PaywallSidebar = ({
  visible,
  onClose,
  afterDelete,
  pushDown,
  paywall,
}) => {
  const context = useAppContext();
  const [number, setNumber] = useState(0);
  const [confirmation, setConfirmation] = useState(false);
  const deletePaywallRequest = usePromiseState(() =>
    api.deletePaywall(paywall.id).then(afterDelete)
  );

  const handleAccordionClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = number === index ? -1 : index;
    setNumber(newIndex);
  };

  const renderDeletePaywallModal = () => {
    if (paywall && paywall.name) {
      return (
        <ConfirmModal
          open={confirmation}
          onClose={() => setConfirmation(false)}
          action={deletePaywallRequest.trigger}
          loading={deletePaywallRequest.isLoading}
          title="Delete Paywall Confirmation"
          body="Are you sure you want to delete"
          name={paywall.name}
          buttonText="Yes. Delete This Paywall."
        />
      );
    }
  };

  return (
    <Form>
      <Sidebar
        className={
          pushDown
            ? cx(sharedStyles.sidebar, sharedStyles.sidebarPushDown)
            : sharedStyles.sidebar
        }
        direction="right"
        animation="overlay"
        width="wide"
        visible={visible}
      >
        <Accordion className={sharedStyles.accordion} fluid styled>
          <Accordion.Title
            active={number === 0}
            index={0}
            onClick={handleAccordionClick}
          >
            <Icon name="dropdown" />
            Overview
          </Accordion.Title>
          <Accordion.Content active={number === 0}>
            <Overview />
          </Accordion.Content>
          <Accordion.Title
            active={number === 1}
            index={1}
            onClick={handleAccordionClick}
          >
            <Icon name="dropdown" />
            Content
          </Accordion.Title>
          <Accordion.Content active={number === 1}>
            <Content />
          </Accordion.Content>
          <Accordion.Title
            active={number === 2}
            index={2}
            onClick={handleAccordionClick}
          >
            <Icon name="dropdown" />
            Layout
          </Accordion.Title>
          <Accordion.Content active={number === 2}>
            <Layout />
          </Accordion.Content>
          <Accordion.Title
            active={number === 3}
            index={3}
            onClick={handleAccordionClick}
          >
            <Icon name="dropdown" />
            Controls
          </Accordion.Title>
          <Accordion.Content active={number === 3}>
            <Controls />
          </Accordion.Content>
          <Accordion.Title
            active={number === 4}
            index={4}
            onClick={handleAccordionClick}
          >
            <Icon name="dropdown" />
            Images
          </Accordion.Title>
          <Accordion.Content active={number === 4}>
            <Images />
          </Accordion.Content>
          <Accordion.Title
            active={number === 5}
            index={5}
            onClick={handleAccordionClick}
          >
            <Icon name="dropdown" />
            Legal
          </Accordion.Title>
          <Accordion.Content active={number === 5}>
            <Legal />
          </Accordion.Content>
        </Accordion>
        <Segment basic>
          <DeleteSegment
            dataCy="delete-paywall"
            title="Delete Paywall"
            topWarning="Deleting this paywall will remove all associated assets including text and images."
            bottomWarning="A deleted paywall cannot be restored."
            action={() => setConfirmation(true)}
            actionText="Delete Paywall"
            disabled={!context.userHasEntitlement('app.paywall.delete')}
          />
        </Segment>
        {renderDeletePaywallModal()}
        <Segment basic>
          <Button fluid onClick={onClose}>
            Close
          </Button>
        </Segment>
      </Sidebar>
    </Form>
  );
};

export default PaywallSidebar;
