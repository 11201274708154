import React from 'react';

import { ClearOutlined } from '@ant-design/icons';
import { Button, Card, Space, Typography } from 'antd';
import NamiIcon from 'src/components/NamiIcon/NamiIcon';
import styled from 'styled-components';

const Wrapper = styled(Card)`
  height: 280px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  margin-bottom: 15px;
`;

type EmptyInsightsCardProps = {
  title: string;
  subtitle?: string;
  resetFunction: () => void;
  hasChanges: boolean;
};

export default function EmptyInsightsCard({
  title,
  subtitle,
  hasChanges,
  resetFunction,
}: EmptyInsightsCardProps) {
  return (
    <Wrapper bordered={false}>
      <Space direction="vertical" align="center" style={{ width: '100%' }}>
        <IconWrapper>
          <NamiIcon icon={'insights'} size={'medium'} color="primary" />
        </IconWrapper>
        <Typography.Paragraph>{title}</Typography.Paragraph>
        {hasChanges && (
          <Button type="link" onClick={resetFunction} icon={<ClearOutlined />}>
            Clear Filters
          </Button>
        )}
        {!hasChanges && !!subtitle ? (
          <Typography.Link
            href="https://learn.namiml.com/public-docs/analytics"
            target="_blank"
            rel="noopener noreferrer"
          >
            {subtitle}
          </Typography.Link>
        ) : null}
      </Space>
    </Wrapper>
  );
}
