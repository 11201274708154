import React from 'react';

import {
  TComponent,
  TComponentLocation,
} from 'src/api/types/paywallTemplate.types';
import { useAppSelector } from 'src/hooks/redux.hooks';
import {
  findComponentFromLocation,
  parseLocationString,
} from 'src/utils/paywall';

import ComponentEditor from './ComponentEditor';

type ComponentEditorProps = {
  componentId: string | null;
};

export default function SectionAndComponentEditor({
  componentId,
}: ComponentEditorProps) {
  const { template, idLocations } = useAppSelector(({ paywallBuilder }) => {
    return {
      template: paywallBuilder.paywall?.template,
      idLocations: paywallBuilder.idLocations,
    };
  });
  let component: TComponent | null = null;
  let parsedLocation: TComponentLocation | null = null;

  if (!template || !componentId) return null;

  if (!componentId) {
    parsedLocation = null;
  } else {
    parsedLocation = parseLocationString(idLocations[componentId]);
    component = findComponentFromLocation(template.pages || [], parsedLocation);
  }

  return (
    <ComponentEditor
      parsedLocation={parsedLocation}
      component={component}
      componentId={componentId}
    />
  );
}
