import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  TProductGroup,
  TProductGroupPayload,
} from 'src/api/types/paywall.types';

import api from '../../api';
import { TApiOptions } from '../../api/types/main.types';
import { TProduct, TSkuTranslationFull } from '../../api/types/sku.types';
import { useAppSelector } from '../redux.hooks';
import { useDebouncedApiOptions } from './apiOptions.hooks';
import QueryKeys from './queryKeys';
import { spreadApiOptions } from './utils';

export function useProductsQuery(apiOptions?: TApiOptions) {
  apiOptions = useDebouncedApiOptions(apiOptions);
  const appId = useAppSelector(({ root }) => root.currentApp.id);

  return useQuery(
    [QueryKeys.products, appId, ...spreadApiOptions(apiOptions)],
    () => api.getProducts(appId, apiOptions),
    { keepPreviousData: true }
  );
}

export function useProductQuery(productId: string) {
  return useQuery([QueryKeys.products, productId], () =>
    api.getProduct(productId)
  );
}

export function useProductLanguageQuery(productId: string) {
  return useQuery([QueryKeys.productTranslations, productId], () =>
    api.getProductTranslations(productId).then((translations) =>
      translations.reduce((output, translation) => {
        return { ...output, [translation.language]: translation };
      }, {} as Record<string, TSkuTranslationFull | undefined>)
    )
  );
}

export function useUpdateProductMutation(productId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    ['updateProduct'],
    (values: TProduct) =>
      api.updateProduct(productId, { ...values, entitlements: [] }),
    {
      onSuccess: () => {
        notification.success({ message: 'Product updated.' });
        return queryClient.invalidateQueries([QueryKeys.products]);
      },
    }
  );
}

export function useArchiveProductMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    ['archiveProduct'],
    ({ product_id, archived }: { product_id: string; archived: boolean }) =>
      api.patchProduct(product_id, { archived: !archived }),
    {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.products]),
    }
  );
}

export function useUpdateProductLanguageMutation(productId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    ['updateProductTranslation'],
    (values: TSkuTranslationFull) =>
      api.updateProductTranslation(productId, values.id, values),
    {
      onSuccess: () => {
        notification.success({ message: 'Translation updated.' });
        const key = [QueryKeys.productTranslations, productId];
        return queryClient.invalidateQueries(key);
      },
    }
  );
}

export function useAddProductMutation(onSuccess: () => void) {
  const queryClient = useQueryClient();
  const language = useAppSelector(({ root }) => {
    return root.currentApp.default_language;
  });
  return useMutation(['newProduct'], api.createProduct, {
    onSuccess: async (product) => {
      await api.addProductTranslation(product.id, language);
      notification.success({ message: 'Added product successfully.' });
      onSuccess();
      return queryClient.invalidateQueries([QueryKeys.products]);
    },
  });
}

export function useAddProductLanguageMutation(
  productId: string,
  onSuccess: () => void
) {
  const queryClient = useQueryClient();
  return useMutation(
    ['newProductTranslation'],
    (language: string) => api.addProductTranslation(productId, language),
    {
      onSuccess: () => {
        notification.success({ message: 'Added language successfully.' });
        onSuccess();
        return queryClient.invalidateQueries([QueryKeys.productTranslations]);
      },
    }
  );
}

export function useAddProductGroupMutation({
  onSuccess,
}: {
  onSuccess: (productGroup: TProductGroup) => void;
}) {
  const queryClient = useQueryClient();
  return useMutation(
    ['newProductGroup'],
    (values: TProductGroupPayload) => api.createProductGroup(values),
    {
      onSuccess: (productGroup) => {
        notification.success({ message: 'Added product group successfully.' });
        onSuccess(productGroup);
        return queryClient.invalidateQueries([QueryKeys.paywalls]);
      },
    }
  );
}

export function useDeleteProductMutation(productId: string) {
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation(['deleteProduct'], () => api.deleteProduct(productId), {
    onSuccess: () => {
      history.push('/products/');
      return queryClient.invalidateQueries([QueryKeys.products]);
    },
    onError: (result: {
      delete_warnings?: { is_in_use_by_paywalls?: string[] };
    }) => {
      const usedBy = result.delete_warnings?.is_in_use_by_paywalls?.length || 1;
      notification.error({
        message: `This product is being used by ${usedBy} paywall${
          usedBy > 1 ? 's' : ''
        }. Remove this product from all paywall menus before deleting.`,
      });
      return;
    },
  });
}

export function useDeleteProductLanguageMutation(
  productId: string,
  onSuccess: () => void
) {
  const queryClient = useQueryClient();

  return useMutation(
    ['deleteProductLanguage'],
    (translationId: string) =>
      api.deleteProductTranslation(productId, translationId),
    {
      onSuccess: () => {
        onSuccess();
        return queryClient.invalidateQueries([QueryKeys.productTranslations]);
      },
    }
  );
}
