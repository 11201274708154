import { client } from './clients';
import { OrgPolicyPayloadType, OrgPolicyType } from './types/main.types';
import { extractResponseData, extractErrorData } from './utils';

const policies = {
  getPolicy: (): Promise<OrgPolicyType> => {
    return client
      .get('/private/org/policies/')
      .then(extractResponseData)
      .then(({ results }) => {
        // TODO: Invert this condition and apply a shortcut
        if (results.length === 0) {
          return client
            .post('/private/org/policies/')
            .then(extractResponseData);
        }
        return results[0];
      });
  },
  updatePolicy: (
    id: string,
    data: OrgPolicyPayloadType
  ): Promise<OrgPolicyType> => {
    return client
      .patch(`/private/org/policies/${id}/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
};

export default policies;
