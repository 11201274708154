import React from 'react';

import { Radio } from 'antd';

type StatusFilterProps = {
  status: string;
  onChange: (value: string) => void;
};

export default function StatusFilter({ status, onChange }: StatusFilterProps) {
  let options = [
    {
      label: <>Available</>,
      text: 'Available',
      value: 'enabled',
    },
    {
      label: <>Archived</>,
      text: 'Archived',
      value: 'archived',
    },
  ];
  return (
    <Radio.Group
      options={options}
      onChange={(e) => onChange(e.target.value)}
      value={status}
      optionType="button"
      style={{ float: 'right' }}
    />
  );
}
