import React, { useMemo } from 'react';

import Icon from '@ant-design/icons';
import { Col, Row, Select, Space } from 'antd';
import {
  TComponent,
  TComponentLocation,
  TImageComponent,
  TRepeatingList,
  TTextComponent,
} from 'src/api/types/paywallTemplate.types';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { ReactComponent as DatabaseIcon } from 'src/images/icons/database.svg';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { deconstructVariable } from 'src/utils/paywall';
import styled from 'styled-components';

type RepeatingGridDataSourceProps = {
  component: TComponent;
  componentLocation: TComponentLocation | null;
  parentRepeatingGrid: TComponent | null;
};

const StyledSelect = styled(Select)`
  width: 100%;
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
  font-size: 13px;
`;

export default function RepeatingGridDataSourceSelector({
  component,
  componentLocation,
  parentRepeatingGrid,
}: RepeatingGridDataSourceProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const { customObjectSchema } = useAppSelector(({ paywallBuilder }) => {
    return {
      customObjectSchema: paywallBuilder.customObjectSchema,
    };
  });
  const isParentArray = component.namiComponentType === 'repeatingList';
  const parentDataSourceRoot = parentRepeatingGrid
    ? deconstructVariable(
        ((parentRepeatingGrid as TRepeatingList).loopSource as string) || '',
        false,
        true
      )
    : null;
  const parentDataSourceLoopVariable = parentRepeatingGrid
    ? (parentRepeatingGrid as TRepeatingList).loopVariable
    : null;

  const currentValue = useMemo(() => {
    const componentType = component.namiComponentType;

    if (componentType === 'repeatingList') {
      if (Array.isArray((component as TRepeatingList).loopSource)) {
        return undefined;
      } else {
        return deconstructVariable(
          ((component as TRepeatingList).loopSource as string) || '',
          false,
          true
        );
      }
    } else if (componentType === 'repeatingTextSource') {
      return (component as TTextComponent).text;
    } else {
      return (component as TImageComponent).url || '';
    }
  }, [component]);

  const selectOptions = useMemo(() => {
    return customObjectSchema
      .filter((value) => {
        if (isParentArray) {
          return value.type === 'array';
        } else {
          return (
            value.type !== 'array' &&
            value.type !== 'boolean' &&
            value.title.includes(parentDataSourceRoot || '')
          );
        }
      })
      .map((value) => {
        return {
          label: parentDataSourceRoot
            ? value.title.replace(`${parentDataSourceRoot}.`, 'Current cell ')
            : value.title,
          value: parentDataSourceRoot
            ? value.title.replace(
                `${parentDataSourceRoot}.`,
                `\${${parentDataSourceLoopVariable}.`
              ) + '}'
            : `\${launch.${value.title}}`,
          text: value.title,
        };
      });
  }, [
    parentDataSourceRoot,
    parentDataSourceLoopVariable,
    isParentArray,
    customObjectSchema,
  ]);

  return (
    <Col xs={24} md={24} key={'conditions'} style={{ marginBottom: 16 }}>
      <Row gutter={[0, 10]} style={{ width: '100%' }} wrap>
        <Space direction="horizontal" size={5}>
          Data Source
          <Icon component={DatabaseIcon} style={{ fontSize: 15 }} />
        </Space>
        <StyledSelect
          options={selectOptions}
          value={currentValue}
          onChange={(value) => update(value as string)}
          showSearch
        />
      </Row>
    </Col>
  );

  function update(value: string) {
    if (componentLocation)
      actions.updateRepeatingGridComponentDataSource({
        idLocation: componentLocation,
        dataSource: value,
      });
  }
}
