import React, { useState } from 'react';

import { LinkOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Steps } from 'antd';
import { useHistory } from 'react-router-dom';
import { useAppContext } from 'src/hooks';
import { namiPureWhite } from 'src/variables';
import styled from 'styled-components';

import CardSlide from '../CardSlide/CardSlide';

type GettingStartedProps = {
  createdApp: boolean;
  createdPaywall: boolean;
  installedSDK: boolean;
  openNewApp: () => { type: string };
};

type TStepDefinition = {
  title: string;
  description: string;
  completed: boolean;
  action: JSX.Element;
};

const StepWrapper = styled.div`
  width: 100%;
  padding: 20px;
  background-color: ${namiPureWhite};
  margin-top: 8px;
  margin-bottom: 24px;
`;

export default function GettingStarted({
  createdApp,
  createdPaywall,
  installedSDK,
  openNewApp,
}: GettingStartedProps) {
  const history = useHistory();
  const context = useAppContext();
  const [currentIndex, setCurrentIndex] = useState(getCurrentSlide());
  const steps: TStepDefinition[] = [
    {
      title: 'Add App',
      description: 'Add your first app to get started.',
      completed: createdApp,
      action: (
        <Button
          onClick={openNewApp}
          disabled={!context.userHasEntitlement('app.create')}
          type="primary"
          icon={<PlusOutlined />}
          className="intercom-onboardingAddApp"
        >
          Add App
        </Button>
      ),
    },
    {
      title: 'Create Paywall',
      description: 'Create a no-code paywall in minutes.',
      completed: createdPaywall,
      action: (
        <Button
          onClick={openNewPaywall}
          disabled={!context.userHasEntitlement('app.paywall.create')}
          type="primary"
          icon={<PlusOutlined />}
          className="intercom-onboardingAddPaywall"
        >
          Create Paywall
        </Button>
      ),
    },
    {
      title: 'Install SDK',
      description: 'Have your development team add the Nami SDK to your app.',
      completed: installedSDK,
      action: (
        <Button
          onClick={goToDocsSDK}
          type="primary"
          icon={<LinkOutlined />}
          className="intercom-onboardingAddSDK"
        >
          Go to Instructions
        </Button>
      ),
    },
  ];

  return (
    <>
      <StepWrapper>
        <Steps
          current={currentIndex}
          onChange={(value) => setCurrentIndex(value)}
          style={{ padding: 12 }}
          type="navigation"
        >
          {steps.map((step, index) => (
            <Steps.Step
              key={step.title}
              title={step.title}
              status={
                step.completed
                  ? 'finish'
                  : index === currentIndex
                  ? 'process'
                  : 'wait'
              }
            />
          ))}
        </Steps>
        <CardSlide
          title={steps[currentIndex].title}
          description={steps[currentIndex].description}
          action={steps[currentIndex].action}
        />
      </StepWrapper>
    </>
  );

  function openNewPaywall(): void {
    history.push('/paywalls/');
  }

  function goToDocsSDK(): void {
    window.open(
      'https://learn.namiml.com/public-docs/get-started/quickstart-guide#integrate-the-sdk',
      '_blank'
    );
  }

  function getCurrentSlide(): number {
    if (createdApp && createdPaywall && !installedSDK) return 2;
    return createdApp && !createdPaywall ? 1 : 0;
  }
}
