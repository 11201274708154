import React from 'react';

import { EditOutlined, StarFilled } from '@ant-design/icons';
import { createSelector } from '@reduxjs/toolkit';
import { Form, Radio, Space, Typography } from 'antd';
import { TProductGroup } from 'src/api/types/paywall.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import SegmentStyledRadio from 'src/components/SegmentStyleRadio/SegmentStyledRadio';
import { useAppContext } from 'src/hooks';
import { RootState } from 'src/redux';

import {
  useActions,
  useAppSelector,
} from '../../../../../../hooks/redux.hooks';
import PaywallBuilderSlice from '../../../../../../redux/PaywallBuilderSlice';
import { ProductGroupsDropdownMenu } from '../../ProductGroupsDropdownMenu';

type ProductGroupsFormItemProps = {
  location?: 'products' | 'components';
};

const selector = createSelector(
  [({ paywallBuilder }: RootState) => paywallBuilder],
  (paywallBuilder) => ({
    groupId: paywallBuilder.groupId,
    productGroups: paywallBuilder.productGroups,
    slides: paywallBuilder.paywall?.template.initialState.slides,
    dynamicAddGroups:
      paywallBuilder.paywall?.template['ui.dynamicAddGroups'] || false,
    productGroupCarousel:
      paywallBuilder.paywall?.template['ui.carousels']?.['carouselName']?.[
        'productGroupSlides'
      ] || false,
    v2Ready:
      !Array.isArray(paywallBuilder.paywall?.template['ui.v2Ready']) &&
      paywallBuilder.paywall?.template['ui.v2Ready'],
    requiredGroups: paywallBuilder.paywall?.template['ui.requiredGroups'] || 1,
  })
);

export default function ProductGroupsFormItem({
  location = 'components',
}: ProductGroupsFormItemProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const appContext = useAppContext();
  const {
    groupId,
    productGroups,
    slides,
    dynamicAddGroups,
    productGroupCarousel,
    v2Ready,
    requiredGroups,
  } = useAppSelector(selector);

  if ((productGroups.length < 2 && !dynamicAddGroups) || requiredGroups < 2)
    return null;

  function setEditingSlideId(value: string) {
    if (!productGroupCarousel) return;
    const allSlides = (slides || {})[productGroups[0].id]['carouselName'];
    const slideForProductGroup = allSlides.find(
      (slide) => slide['productGroupId'] === value
    );
    if (slideForProductGroup)
      actions.setViewingSlideId(slideForProductGroup.id);
  }

  const label =
    location === 'components' ? (
      <Typography.Text style={{ marginBottom: '0em' }}>
        Product Group
      </Typography.Text>
    ) : (
      <Typography.Text style={{ marginBottom: '0em', fontWeight: 500 }}>
        Product Group
      </Typography.Text>
    );

  return (
    <>
      <Space direction="horizontal">
        <Form.Item label={label}>
          {productGroups.length > 2 && (
            <ProductGroupsDropdownMenu flavor="regular" />
          )}
          {productGroups.length <= 2 && (
            <SegmentStyledRadio
              value={groupId}
              onChange={(e) => {
                actions.setProductGroupId(e.target.value);
                setEditingSlideId(e.target.value);
                actions.setEditingProductId(null);
              }}
            >
              {productGroups.map((group) => (
                <Radio.Button key={group.id} value={group.id}>
                  <Space direction="horizontal">
                    {getDefaultStar(group)}
                    <Typography.Text ellipsis={true} style={{ maxWidth: 125 }}>
                      {group.display_name}
                    </Typography.Text>
                  </Space>
                </Radio.Button>
              ))}
            </SegmentStyledRadio>
          )}
        </Form.Item>
        <IconActionButton
          type="text"
          size="small"
          icon={<EditOutlined style={{ fontSize: '13px' }} />}
          disabled={
            !v2Ready &&
            !appContext.userHasEntitlement('app.productgroup.update')
          }
          onClick={() => actions.setEditingProductGroups(true)}
        >
          Edit
        </IconActionButton>
      </Space>
    </>
  );

  function getDefaultStar(productGroup: TProductGroup): JSX.Element {
    if (productGroup.default)
      return <StarFilled style={{ fontSize: '0.8em' }} />;
    return <></>;
  }
}
