import React from 'react';

import {
  CheckOutlined,
  FilterOutlined,
  LineChartOutlined,
} from '@ant-design/icons';
import { Button, Col, Drawer, Form, Row, Select, Skeleton } from 'antd';
import moment from 'moment-timezone';
import { useHistory, useLocation } from 'react-router-dom';
import { intervals, timezones } from 'src/api/types/analytics.types';
import { capitalizeString } from 'src/utils/string';
import styled from 'styled-components';

import DateRangePicker from '../../../components/DateRangePicker';
import EnvironmentSelect from '../../../components/EnvironmentSelect';
import FilterFormItem from '../../../components/FilterFormItem';
import Responsive from '../../../components/Responsive/Responsive';
import {
  useActions,
  useAppDispatch,
  useAppSelector,
} from '../../../hooks/redux.hooks';
import {
  toggleAll,
  toggleFilter,
  toggleNavigation,
} from '../../../redux/actions/uiActions';
import AnalyticsSlice from '../../../redux/AnalyticsSlice';

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export default function InsightsFilter() {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(({ ui }) => ui.filter);
  return (
    <>
      <Responsive size="mdUp" as={Row} gutter={[6, 0]}>
        <Col span={4} offset={6}>
          <EnvironmentSelect type="insights" />
        </Col>
        <Col span={2} />
        <Col span={6}>
          <DateRangePicker type="insights" />
        </Col>
        <Col span={3}>
          <TZSelect />
        </Col>
        <Col span={3}>
          <IntervalSelect />
        </Col>
      </Responsive>
      <Responsive size="mdDown">
        <ButtonsWrapper>
          <Button
            onClick={() => {
              dispatch(toggleAll());
              dispatch(toggleNavigation(true));
            }}
          >
            <LineChartOutlined />
            All Metrics
          </Button>
          <Button
            onClick={() => {
              dispatch(toggleAll());
              dispatch(toggleFilter(true));
            }}
          >
            <FilterOutlined />
            Filter
          </Button>
        </ButtonsWrapper>
        <Drawer open={filter} onClose={() => dispatch(toggleAll())}>
          <Form layout="vertical">
            <EnvironmentSelect type="insights" />
            <DateRangePicker type="insights" />
            <TZSelect />
            <IntervalSelect />
          </Form>
          <Button
            className="nami-primary-button"
            onClick={() => dispatch(toggleAll())}
          >
            <CheckOutlined />
            Done
          </Button>
        </Drawer>
      </Responsive>
    </>
  );
}

function TZSelect() {
  const actions = useActions(AnalyticsSlice.actions);
  const timezone = useAppSelector((state) => state.analytic.timezone);
  const [startDate, endDate] = useAppSelector(
    (state) => state.analytic.dateRangeInsights
  );
  const isRendering = useAppSelector(
    (state) => state.analytic.placeholderState
  );
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  return (
    <FilterFormItem
      label={'Timezone'}
      colon={false}
      className="intercom-tzSelect"
    >
      {isRendering && (
        <Skeleton.Input active={true} size="small" block={true} />
      )}
      {!isRendering && (
        <Select
          value={timezone}
          placeholder={timezone}
          onChange={(value) => {
            queryParams.set('timezone', value);
            queryParams.set(
              'startDate',
              moment.tz(startDate, value).toISOString(true)
            );
            queryParams.set(
              'endDate',
              moment.tz(endDate, value).toISOString(true)
            );
            location.search = queryParams.toString();
            history.push(location);
            actions.setTimezone(value);
            actions.setDateRangeInsights([
              moment.tz(startDate, value),
              moment.tz(endDate, value),
            ]);
          }}
          options={timezones.map((tz) => ({
            key: tz,
            value: tz,
            label: tz,
          }))}
        />
      )}
    </FilterFormItem>
  );
}

function IntervalSelect() {
  const actions = useActions(AnalyticsSlice.actions);
  const interval = useAppSelector((state) => state.analytic.interval);
  const isRendering = useAppSelector(
    (state) => state.analytic.placeholderState
  );
  const [startDate, endDate] = useAppSelector(
    (state) => state.analytic.dateRangeInsights
  );
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  return (
    <FilterFormItem
      label={'Resolution'}
      colon={false}
      className="intercom-intervalSelect"
    >
      {!isRendering && (
        <Select
          value={interval}
          placeholder={capitalizeString(interval)}
          onChange={(value) => {
            queryParams.set('resolution', value);
            if (interval === 'hour') {
              queryParams.set(
                'endDate',
                endDate.endOf('day').toISOString(true)
              );
              actions.setDateRangeInsights([startDate, endDate.endOf('day')]);
            }
            location.search = queryParams.toString();
            history.push(location);
            actions.setInterval(value);
          }}
          options={intervals.map((interval) => ({
            key: interval,
            value: interval,
            label: capitalizeString(interval),
          }))}
        />
      )}
      {isRendering && (
        <Skeleton.Input active={true} size="small" block={true} />
      )}
    </FilterFormItem>
  );
}
