import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { useParams } from 'react-router-dom';
import { TSkuTranslationFull } from 'src/api/types/sku.types';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import Page from 'src/components/Page/Page';
import { useAppContext, useBooleanState } from 'src/hooks';
import {
  useDeleteProductLanguageMutation,
  useProductQuery,
  useProductLanguageQuery,
  useUpdateProductLanguageMutation,
} from 'src/hooks/queries/product.hooks';
import { useAppSelector } from 'src/hooks/redux.hooks';

import { buildNameProps, TDRFErrors } from '../../../../utils/ant';
import NewLanguageModal from './NewLanguageModal';

export default function ProductLanguagesPage() {
  const productId = useParams<{ productID: string }>().productID;
  const appContext = useAppContext();
  const defaultLanguage = useAppSelector(
    ({ root }) => root.currentApp.default_language as string
  );
  const [form] = Form.useForm<TSkuTranslationFull>();

  const [language, setLanguage] = useState(defaultLanguage);
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);

  const productQuery = useProductQuery(productId);
  const translationsQuery = useProductLanguageQuery(productId);
  const updateMutation = useUpdateProductLanguageMutation(productId);
  const deleteMutation = useDeleteProductLanguageMutation(productId, () => {
    setLanguage(defaultLanguage);
    const translation = translationsQuery.data?.[defaultLanguage];
    if (!translation) return;
    form.setFieldsValue(translation);
  });

  const translations = translationsQuery.data || {};
  const translation = translations[language];

  const translationsOptions = Object.values(translations).map((translation) => {
    const { language_humanized: label, language: value } = translation!;
    const suffix = defaultLanguage === value ? ' - Default' : '';
    return { value, label: `${label} (${value.toUpperCase()})` + suffix };
  });

  const errors = (updateMutation.error || {}) as TDRFErrors;

  return (
    <Page title={productQuery.data?.name || 'Loading product'}>
      <Row justify="start" gutter={[0, 36]}>
        <Col xs={24} style={{ paddingBottom: 12 }}>
          <Breadcrumb
            items={[
              { url: '/products/', name: 'Products' },
              {
                url: `/products/${productId}/`,
                name: productQuery.data?.name || 'Loading product',
              },
              { name: 'Languages' },
            ]}
          />
        </Col>
      </Row>
      <Card
        bordered={false}
        loading={translationsQuery.isFetching || productQuery.isFetching}
      >
        <Alert
          type="info"
          message="Product Translations are only used on Legacy Paywalls.
Unsure if you are using Legacy Paywalls? Ask Nami Support."
          showIcon
        />
        <Form
          layout="vertical"
          form={form}
          initialValues={translation}
          onFinish={updateMutation.mutate}
        >
          <Row
            gutter={[16, 16]}
            style={{ marginTop: '36px', marginBottom: '20px' }}
          >
            <Col md={24}>
              <Button
                icon={<PlusOutlined />}
                type="ghost"
                style={{ float: 'right' }}
                disabled={
                  !appContext.userHasEntitlement('app.productsku.update')
                }
                onClick={openModal}
              >
                Add Language
              </Button>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={24}>
              <Form.Item name="language">
                <Select
                  placeholder="Select language"
                  options={translationsOptions}
                  onChange={(value) => {
                    setLanguage(value);
                    const translation = translations[value];
                    if (!translation) return;
                    form.setFieldsValue(translation);
                  }}
                  disabled={
                    !appContext.userHasEntitlement('app.productsku.update')
                  }
                />
              </Form.Item>
              <Form.Item name="id" hidden={true}>
                <Input type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Display Text"
                className="intercom-productLanguage-displayText"
                {...buildNameProps('display_text', errors)}
              >
                <Input
                  placeholder="Product Display Text"
                  type="text"
                  disabled={
                    !appContext.userHasEntitlement('app.productsku.update')
                  }
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Sub-Display Text"
                {...buildNameProps('sub_display_text', errors)}
              >
                <Input
                  placeholder="Product Sub-Display Text"
                  type="text"
                  className="intercom-productLanguage-subdisplaytext"
                  disabled={
                    !appContext.userHasEntitlement('app.productsku.update')
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={12}>
              <Button
                type="primary"
                loading={updateMutation.isLoading}
                htmlType="submit"
                className="intercom-updateProductLanguage"
                disabled={
                  !appContext.userHasEntitlement('app.productsku.update')
                }
              >
                Update Language
              </Button>
            </Col>
          </Row>
        </Form>
        {translation?.language !== defaultLanguage && (
          <Collapse ghost style={{ marginBottom: 15, marginTop: 20 }}>
            <Collapse.Panel
              header={<h4>Danger Zone</h4>}
              key="1"
              className="intercom-languageDelete"
            >
              <Space direction="vertical">
                <Typography.Text>
                  Deleting this language will remove it from your app and all
                  paywalls.
                </Typography.Text>
                <Button
                  type="primary"
                  danger
                  disabled={
                    !appContext.userHasEntitlement('app.productsku.delete')
                  }
                  loading={deleteMutation.isLoading}
                  onClick={() =>
                    translation && deleteMutation.mutate(translation.id)
                  }
                >
                  Delete Language {buildLanguageLabel(translation)}
                </Button>
              </Space>
            </Collapse.Panel>
          </Collapse>
        )}
      </Card>
      <NewLanguageModal
        productId={productId}
        isOpen={isModalOpen}
        onClose={closeModal}
      />
    </Page>
  );

  function buildLanguageLabel(translation?: TSkuTranslationFull) {
    const { language_humanized: label, language: code } = translation || {};
    return `${label} - (${(code || '').toUpperCase()})`;
  }
}
