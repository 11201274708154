import React, { useMemo } from 'react';

import {
  DownOutlined,
  EyeInvisibleOutlined,
  LockFilled,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Col, Row, Space, Tooltip, Tree } from 'antd';
import {
  AntTreeNodeProps,
  DataNode,
  EventDataNode,
  TreeProps,
} from 'antd/lib/tree';
import { BasicDataNode } from 'rc-tree';
import {
  TCollapseContainer,
  TComponent,
  TContainer,
  TPaywallTemplate,
  TPlayPauseButtonComponent,
  TVolumeControlComponent,
} from 'src/api/types/paywallTemplate.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import BuilderTreeWebPaywall from 'src/components/WebPaywalls/BuilderTreeWebPaywall';
import { useAppContext, useBooleanState } from 'src/hooks';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { getComponentTitle } from 'src/utils/paywall';
import { namiBrightBlue, namiMediumGray } from 'src/variables';
import styled from 'styled-components';

import {
  findParentIdOfComponent,
  findProductContainerChildIds,
  findRepeatingGridChildIds,
  findTypeAndSectionById,
} from '../../utils/componentGeneration';
import AddComponentPopover, {
  allProductComponents,
  TNewComponentsByParentType,
} from './AddComponentPopover';

type TreeViewProps = {
  template: TPaywallTemplate;
};

const StyledTree = styled(Tree)`
  padding: 0px !important;
  font-size: 13px;
  .ant-tree .ant-tree-treenode {
    padding: 0px !important;
  }

  .ant-tree-switcher {
    width: unset;
  }

  .ant-tree-indent-unit {
    width: 12px !important;
  }

  span.ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #f0f5f8 !important;
  }
`;

const AddSectionButton = styled(Button)`
  width: 100%;
  text-align: left;
  color: #6e7881;
  margin-left: 3px;
`;

export default function TreeView({ template }: TreeViewProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const {
    currentPage,
    expandedKeys,
    selectedKey,
    idLocations,
    idTypeLocations,
    formFactor,
  } = useAppSelector(({ paywallBuilder }) => ({
    currentPage: paywallBuilder.currentPage,
    expandedKeys: paywallBuilder.expandedTreeKeys,
    selectedKey: paywallBuilder.selectedTreeKey,
    idLocations: paywallBuilder.idLocations,
    idTypeLocations: paywallBuilder.idTypeLocations,
    formFactor: paywallBuilder.formFactor,
  }));

  const [
    builderTreePaywallOpen,
    openBuilderTreePaywall,
    closeBuilderTreePaywall,
  ] = useBooleanState(false);
  const appContext = useAppContext();
  const userCanAdd = appContext.planHasEntitlement(
    'app.paywall.component.create'
  );

  const productChildren = useMemo(() => {
    return findProductContainerChildIds(idLocations, idTypeLocations);
  }, [idLocations, idTypeLocations]);

  const repeatingListChildren = useMemo(() => {
    return findRepeatingGridChildIds(idLocations, idTypeLocations);
  }, [idLocations, idTypeLocations]);

  const carouselChildren = useMemo(() => {
    const carouselContainerLocation = idLocations['carouselSlide'];
    if (!carouselContainerLocation) return [];
    return Object.entries(idLocations).reduce((output, [key, loc]) => {
      if (loc.includes(carouselContainerLocation)) {
        return [...output, key];
      }
      return output;
    }, [] as Array<string>);
  }, [idLocations]);

  const headerChildren = useMemo(() => {
    const headerLocation = idLocations['header'];
    if (!headerLocation) return [];
    return Object.entries(idLocations).reduce((output, [key, loc]) => {
      if (loc.includes(headerLocation)) {
        return [...output, key];
      }
      return output;
    }, [] as Array<string>);
  }, [idLocations]);

  const footerChildren = useMemo(() => {
    const carouselContainerLocation = idLocations['footer'];
    if (!carouselContainerLocation) return [];
    return Object.entries(idLocations).reduce((output, [key, loc]) => {
      if (loc.includes(carouselContainerLocation)) {
        return [...output, key];
      }
      return output;
    }, [] as Array<string>);
  }, [idLocations]);

  const canAddHeaderFooter =
    formFactor !== 'television' && formFactor !== 'desktop';

  const treeData = generateTree(template);

  const onDrop: TreeProps['onDrop'] = (info) => {
    const dropPos = info.node.pos.split('-');
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);
    actions.reorderComponent({
      id: info.dragNode.key as string,
      idLocation: idLocations[info.dragNode.key as string],
      parentLocation: idLocations[info.node.key as string],
      location: dropPosition,
      dropToGap: info.dropToGap,
    });
  };

  return (
    <>
      <Space direction="vertical" style={{ width: '100%' }} size={0}>
        <StyledTree
          switcherIcon={(props: AntTreeNodeProps) => {
            if (props.eventKey === 'root') return <></>;
            return <DownOutlined />;
          }}
          treeData={treeData}
          expandedKeys={expandedKeys}
          onSelect={(_selectedKeys, e) => {
            actions.addToExpandedTreeKeys(e.node.key.toString());
            if (
              [
                'playingComponents',
                'pausedComponents',
                'volumeComponents',
                'mutedComponents',
              ].includes(e.node.key.toString())
            ) {
              //TODO - handle differently
            } else {
              selectNode(e.node.key.toString());
            }
          }}
          selectedKeys={selectedKey ? [selectedKey] : undefined}
          onExpand={(
            _expandedKeys,
            info: {
              node: EventDataNode<BasicDataNode | DataNode>;
              expanded: boolean;
            }
          ) => {
            if (info.expanded) {
              actions.addToExpandedTreeKeys(info.node.key.toString());
            } else {
              actions.removeFromExpandedTreeKeys(info.node.key.toString());
            }
          }}
          draggable={{ icon: false, nodeDraggable: () => true }}
          allowDrop={(options) =>
            checkForDrop(
              options.dragNode,
              options.dropNode,
              options.dropPosition
            )
          }
          onDrop={onDrop}
          selectable
          blockNode
        />
        {!headerChildren.length && canAddHeaderFooter && (
          <Tooltip
            title="Add header section"
            placement="topLeft"
            align={{ offset: [0, 5] }}
          >
            <AddSectionButton
              type="text"
              icon={
                userCanAdd ? (
                  <PlusOutlined
                    style={{ fontSize: '13px', color: '#6E7881' }}
                  />
                ) : (
                  <LockFilled style={{ color: namiBrightBlue, fontSize: 13 }} />
                )
              }
              onClick={() => {
                if (!userCanAdd) {
                  openBuilderTreePaywall();
                } else {
                  actions.addHeader();
                }
              }}
              size="small"
            >
              Header
            </AddSectionButton>
          </Tooltip>
        )}
        {!footerChildren.length && canAddHeaderFooter && (
          <Tooltip
            title="Add footer section"
            placement="topLeft"
            align={{ offset: [0, 5] }}
          >
            <AddSectionButton
              type="text"
              icon={
                userCanAdd ? (
                  <PlusOutlined
                    style={{ fontSize: '13px', color: '#6E7881' }}
                  />
                ) : (
                  <LockFilled style={{ color: namiBrightBlue, fontSize: 13 }} />
                )
              }
              onClick={() => {
                if (!userCanAdd) {
                  openBuilderTreePaywall();
                } else {
                  actions.addFooter();
                }
              }}
              size="small"
            >
              Footer
            </AddSectionButton>
          </Tooltip>
        )}
      </Space>
      <BuilderTreeWebPaywall
        visible={builderTreePaywallOpen}
        onCancel={closeBuilderTreePaywall}
      />
    </>
  );

  function selectNode(id: string | undefined) {
    if (id) {
      actions.setSelectedTreeKey(id);
      actions.setEditingComponentId(id);
      actions.setEditingSlideId(null);
    }
  }

  function checkForDrop(
    dragNode: BasicDataNode | DataNode,
    dropNode: BasicDataNode | DataNode,
    dropPosition: number
  ): boolean {
    if (!appContext.userHasEntitlement('app.paywall.component.reorder'))
      return false;

    const dropNodeKey = ((dropNode as DataNode).key as string) || '';
    const dragNodeKey = ((dragNode as DataNode).key as string) || '';

    if (!dropNodeKey || !dragNodeKey) return false;

    const dragNodeMeta = findTypeAndSectionById(
      dragNodeKey,
      idLocations,
      idTypeLocations
    );
    let dropNodeMeta = findTypeAndSectionById(
      dropNodeKey,
      idLocations,
      idTypeLocations
    );

    if (dropPosition === 1) {
      const parentDropNodeKey = findParentIdOfComponent(
        dropNodeKey,
        idLocations
      );
      if (parentDropNodeKey) {
        dropNodeMeta = findTypeAndSectionById(
          parentDropNodeKey || '',
          idLocations,
          idTypeLocations
        );
      }
    }

    let buildableResult = true;

    //Don't allow moving high level sections
    if (
      ['backgroundContainer', 'contentContainer', 'footer', 'header'].includes(
        dragNodeKey
      )
    ) {
      return false;
    }

    //First check if component can be added to the target section
    if (TNewComponentsByParentType[dropNodeMeta.section]) {
      buildableResult = TNewComponentsByParentType[
        dropNodeMeta.section
      ].includes(dragNodeMeta.type);
    }
    //Then check if target type can support component
    if (TNewComponentsByParentType[dropNodeMeta.type]) {
      buildableResult = TNewComponentsByParentType[dropNodeMeta.type].includes(
        dragNodeMeta.type
      );
    }
    //Restrict moving product children outside repeating product group
    if (
      dragNodeMeta.type !== 'productContainer' &&
      allProductComponents.includes(dragNodeMeta.type)
    ) {
      buildableResult =
        productChildren.includes(dropNodeKey) ||
        allProductComponents.includes(dropNodeMeta.type);
    }
    //Restrict moving non-supported components into slide
    if (carouselChildren.includes(dropNodeKey)) {
      return TNewComponentsByParentType['slide'].includes(dragNodeMeta.type);
    }
    if (carouselChildren.includes(dragNodeKey)) {
      if (
        [
          'carouselTitleText',
          'carouselBodyText',
          'carouselImage',
          'carouselBulletList',
        ].includes(dragNodeMeta.type)
      ) {
        buildableResult = carouselChildren.includes(dropNodeKey);
      }
    }
    //Restrict moving carousel inside stack
    if (dragNodeMeta.type === 'carousel' && dragNodeMeta.section === 'header') {
      buildableResult = false;
    }
    //Restrict moving carousel from content to header or vice versa
    if (
      dragNodeMeta.type === 'carousel' &&
      dropNodeMeta.section !== dragNodeMeta.section
    ) {
      buildableResult = false;
    }
    //Restrict moving repeating component types outside of repeating list
    if (repeatingListChildren.includes(dragNodeKey)) {
      if (
        dragNodeMeta.type === 'repeatingImageSource' ||
        dragNodeMeta.type === 'repeatingTextSource'
      ) {
        buildableResult = repeatingListChildren.includes(dropNodeKey);
      }
    }

    return buildableResult;
  }

  function generateTitle(
    value: TComponent,
    overrideTitle?: string,
    overrideId?: string
  ): React.ReactNode {
    const canAdd = canAddComponentChildren(value, overrideId);
    const addButton = (
      <IconActionButton
        type="text"
        size="small"
        icon={<PlusOutlined style={{ fontSize: '13px', color: '#596168' }} />}
        className="hover-mui-icon"
      />
    );
    return (
      <Row>
        <Col flex="auto">
          {overrideTitle ? overrideTitle : getComponentTitleStyled(value)}
        </Col>
        {canAdd && (
          <Col flex="18px">
            <AddComponentPopover
              child={addButton}
              value={value}
              productChild={productChildren.includes(value.id || '')}
              openBuilderTreePaywall={openBuilderTreePaywall}
              overrideId={overrideId}
            />
          </Col>
        )}
      </Row>
    );
  }

  function canAddComponentChildren(
    value: TComponent,
    overrideId?: string
  ): boolean {
    const allowedComponentTypes = [
      'container',
      'productContainer',
      'button',
      'condition',
      'playPauseButton',
      'volumeButton',
      'slide',
    ];
    return (
      value.namiComponentType !== 'divider' &&
      allowedComponentTypes.includes(value.component) &&
      !(value.namiComponentType === 'volumeButton' && !overrideId) &&
      !(value.namiComponentType === 'playPauseButton' && !overrideId)
    );
  }

  function generateTree(paywall: TPaywallTemplate): DataNode[] {
    let bodyChildren: DataNode[] = [];
    const page =
      paywall.pages.find((page) => page.name === currentPage) ||
      paywall.pages[0];

    const backgroundChildren = walkComponentTree(page.backgroundContainer);
    bodyChildren.push(backgroundChildren);

    if (page.header && page.header.length > 0 && canAddHeaderFooter) {
      const headerChildren = walkComponentTree({
        ...page.header[0],
        id: page.header[0].id || 'header',
      });
      bodyChildren.push(headerChildren);
    }

    const body = walkComponentTree(page.contentContainer);
    bodyChildren.push(body);

    if (page.footer && page.footer.length > 0) {
      const footerChildren = walkComponentTree({
        id: 'footer',
        component: 'container',
        components: (page.footer[0] as TContainer).components
          ? (page.footer[0] as TContainer).components
          : page.footer,
      });
      bodyChildren.push(footerChildren);
    }

    return [
      {
        title: (
          <Row style={{ cursor: 'not-allowed' }}>
            <Col flex="auto">Sections</Col>
          </Row>
        ),
        key: 'root',
        children: bodyChildren,
        selectable: false,
      },
    ];
  }

  function walkComponentTree(value: TComponent): DataNode {
    if (value === undefined) {
      return value;
    }

    let children: DataNode[] = [];
    if ((value as TContainer).components) {
      //Only push 1 toggle item to tree
      if (value.namiComponentType === 'productGroupToggle') {
        children.push(walkComponentTree((value as TContainer).components[0]));
      } else if (value.namiComponentType === 'collapse') {
        children.push(
          walkComponentTree(
            (value as TCollapseContainer).collapseHeader as TContainer
          )
        );
        (value as TContainer).components.forEach((component) => {
          children.push(walkComponentTree(component));
        });
      } else {
        (value as TContainer).components.forEach((component) => {
          children.push(walkComponentTree(component));
        });
      }

      return {
        title: generateTitle(value),
        key: value.id || '',
        children: children,
      };
    } else if (value.namiComponentType === 'volumeButton') {
      let volumeChildren = (
        value as TVolumeControlComponent
      ).volumeComponents.reduce((output, component) => {
        return [...output, walkComponentTree(component)];
      }, [] as DataNode[]);
      let volumeParent: DataNode = {
        title: generateTitle(value, 'Volume On', `volumeComponents`),
        key: `volumeComponents`,
        children: volumeChildren,
      };
      children.push(volumeParent);

      let mutedChildren = (
        value as TVolumeControlComponent
      ).mutedComponents.reduce((output, component) => {
        return [...output, walkComponentTree(component)];
      }, [] as DataNode[]);
      let mutedParent: DataNode = {
        title: generateTitle(value, 'Volume Muted', 'mutedComponents'),
        key: `mutedComponents`,
        children: mutedChildren,
      };
      children.push(mutedParent);

      return {
        title: generateTitle(value),
        key: value.id || '',
        children: children,
      };
    } else if (value.namiComponentType === 'playPauseButton') {
      let playingChildren = (
        value as TPlayPauseButtonComponent
      ).playingComponents.reduce((output, component) => {
        return [...output, walkComponentTree(component)];
      }, [] as DataNode[]);
      let playingParent: DataNode = {
        title: generateTitle(value, 'Video Playing', 'playingComponents'),
        key: `playingComponents`,
        children: playingChildren,
      };
      children.push(playingParent);

      let pausedChildren = (
        value as TPlayPauseButtonComponent
      ).pausedComponents.reduce((output, component) => {
        return [...output, walkComponentTree(component)];
      }, [] as DataNode[]);
      let pausedParent: DataNode = {
        title: generateTitle(value, 'Video Paused', 'pausedComponents'),
        key: `pausedComponents`,
        children: pausedChildren,
      };
      children.push(pausedParent);
      return {
        title: generateTitle(value),
        key: value.id || '',
        children: children,
      };
    }

    return {
      title: generateTitle(value),
      key: value.id || '',
    };
  }
}

export function getComponentTitleStyled(
  value: TComponent | null
): React.ReactNode {
  const titleCopy = getComponentTitle(value);
  if (value && value.hidden) {
    return (
      <Space size={5} style={{ color: namiMediumGray }}>
        <EyeInvisibleOutlined style={{ fontSize: 11 }} />
        {titleCopy}
      </Space>
    );
  }
  return <>{titleCopy}</>;
}
