import React, { MouseEventHandler, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { namiPureWhite } from 'src/variables';

import { AppContextType } from '../../AppContextProvider';
import { useAppContext } from '../../hooks';
import { useAppSelector } from '../../hooks/redux.hooks';
import { defaultImage } from '../../services/helpers';
import styles from './AppIcon.module.less';

type AppIconProps = { onClick: MouseEventHandler };

export default function AppIcon({ onClick }: AppIconProps) {
  const context: AppContextType = useAppContext();
  const app = useAppSelector(({ root }) => root.currentApp);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  if (!context.selectedOrg) return null;
  if (!app) {
    return (
      <div className={styles.iconBackground} onClick={onClick}>
        <Button type="primary" size="large" className={styles.button}>
          <PlusOutlined
            style={{ height: 20, width: 20, color: namiPureWhite }}
          />
        </Button>
      </div>
    );
  }
  const { logo, name } = app;
  return (
    <Tooltip
      title={'Switch app'}
      placement="right"
      align={{ offset: [-6] }}
      open={tooltipOpen}
    >
      <div
        onClick={(e) => {
          setTooltipOpen(false);
          onClick(e);
        }}
        className={styles.iconBackground}
        onMouseEnter={(e) => setTooltipOpen(true)}
        onMouseLeave={(e) => setTooltipOpen(false)}
      >
        <div
          data-cy="app-name"
          className={styles.iconImage}
          style={{
            backgroundImage: `url(${logo || defaultImage(escape(name))})`,
          }}
        />
      </div>
    </Tooltip>
  );
}
