import React, { useEffect, useState } from 'react';

import { TimeDimensionGranularity } from '@cubejs-client/core';
import { createSelector } from '@reduxjs/toolkit';
import { Col, Row } from 'antd';
import moment from 'moment-timezone';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import api from 'src/api';
import {
  DataKey,
  DateRangeType,
  EnvironmentType,
  FilterOptions,
  SegmentOptions,
  SegmentType,
  TAppliedFilters,
  TData,
  TEnvironmentOption,
  TMetricFilter,
  TMetricFilterRule,
  environments,
  intervals,
  timezones,
} from 'src/api/types/analytics.types';
import MetricsWebPaywall from 'src/components/WebPaywalls/MetricsWebPaywall';
import { useAppContext, useBooleanState, useQueryParams } from 'src/hooks';
import type { RootState } from 'src/redux';
import AnalyticsSlice from 'src/redux/AnalyticsSlice';
import { momentCalculator } from 'src/utils/date';
import { v4 as uuid4 } from 'uuid';

import { TDataParams } from '../../api/analytics.api';
import Page from '../../components/Page/Page';
import Responsive from '../../components/Responsive/Responsive';
import TimeZoneLabel from '../../components/TimeZoneLabel/TimeZoneLabel';
import { useActions, useAppSelector } from '../../hooks/redux.hooks';
import InsightsDetailCard from './insights/InsightsDetailCard';
import InsightsDownloadButton from './insights/InsightsDownloadButton';
import InsightsFilter from './insights/InsightsFilter';
import InsightsGroup from './insights/InsightsGroup';
import InsightsMenu from './insights/InsightsMenu';
import { TInsightType } from './insights/InsightsPage';
import InsightsTable from './insights/InsightsTable';

const selector = createSelector(
  [
    ({ analytic }: RootState) => analytic,
    ({ root }: RootState) => root.currentApp,
  ],
  (analytic, app) => ({
    appId: app?.id,
    environment: analytic.environment.value,
    dateRange: analytic.dateRangeInsights,
    interval: analytic.interval,
    segment: analytic.segment,
    filters: analytic.filters,
    placeholderState: analytic.placeholderState,
    timezone: analytic.timezone,
    type: analytic.insightType,
  })
);

export type LineChartPageProps<Data extends TData> = {
  title: string;
  dataFetcher(params: TDataParams): Promise<Data[]>;
  totalFetcher?(params: TDataParams): Promise<Data[]>;
  dataParser?(data: Data[], segment: SegmentType): Data[];
  totalDataParser?(data: Data[]): Data[];
  chartKeys: Array<DataKey>;
  tableKeys?: Array<DataKey>;
  description: string;
  entitlement: string;
  availableSegments: Array<NonNullable<SegmentType>>;
  availableFilters: Array<TMetricFilter>;
  insightType: TInsightType;
};

export default function LineChartPage<Data extends TData>({
  title,
  dataFetcher,
  dataParser: postDataParser = (data) => data,
  chartKeys,
  tableKeys,
  description,
  entitlement,
  availableSegments,
  availableFilters,
  insightType,
}: LineChartPageProps<Data>) {
  const queryParams = useQueryParams();
  const {
    appId,
    dateRange,
    environment,
    interval,
    segment,
    filters,
    placeholderState,
    timezone,
  } = useAppSelector(selector);
  const history = useHistory();
  const location = useLocation();
  const { userHasEntitlement } = useAppContext();
  const [isLoading, setLoading] = useState(true);
  const [titleData, setTitleData] = useState<{ [key: string]: Data[] }>({});
  const [isUpgradeOpen, openUpgrade] = useBooleanState(false);
  const actions = useActions(AnalyticsSlice.actions);

  useEffect(() => {
    setLoading(true);
    actions.resetState();
    fetchAndSetAnalyticData();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    writeToURLFromAnalyticsState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    actions.setInsightType(insightType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightType]);

  useEffect(() => {
    actions.updateFiltersAndSegment({
      availableSegments,
      availableFilters,
      hasEntitlement: userHasEntitlement,
    });
  }, [availableSegments, availableFilters, actions, userHasEntitlement]);

  const data = titleData[title] || [];
  const isRendering = isLoading || placeholderState;

  useEffect(() => {
    if (!appId) return;
    if (placeholderState) return;
    setLoading(true);
    if (!userHasEntitlement(entitlement)) return openUpgrade();
    dataFetcher({
      dateRange,
      appId,
      environment,
      interval,
      segment,
      filters,
      timezone,
    })
      .then((data) => {
        setTitleData((dataMap) => ({
          ...dataMap,
          [title]: postDataParser(data, segment),
        }));
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appId,
    title,
    dateRange,
    environment,
    interval,
    segment,
    filters,
    timezone,
  ]);
  return (
    <Page title={title}>
      <Row>
        <Col span={24}>
          <InsightsFilter />
        </Col>
      </Row>
      <Row>
        <Responsive size="mdUp" as={Col} span={6}>
          <InsightsMenu />
        </Responsive>
        <Responsive size="mdDown">
          <InsightsMenu />
        </Responsive>
        <Col xs={24} md={18} style={{ marginBottom: '50px' }}>
          <InsightsDetailCard
            title={title}
            loading={isRendering}
            data={data}
            description={description}
            chartKeys={chartKeys}
            reset={resetFilters}
          />
          <InsightsGroup
            metricSegments={availableSegments}
            metricFilters={availableFilters}
          />
          <InsightsTable
            loading={isRendering}
            data={data}
            chartKeys={chartKeys}
            tableKeys={tableKeys || chartKeys}
          />
          <Responsive size="mdUp" as={Row} align="middle">
            <Col span={12} flex="auto">
              <TimeZoneLabel timezone={timezone} />
            </Col>
            <Col span={12} flex="auto">
              <InsightsDownloadButton
                title={title}
                data={data}
                loading={isRendering}
              />
            </Col>
          </Responsive>
          <Responsive size="mdDown" as={Row}>
            <Col span={24}>
              <TimeZoneLabel timezone={timezone} />
            </Col>
          </Responsive>
        </Col>
      </Row>
      <MetricsWebPaywall
        visible={isUpgradeOpen}
        onCancel={() => history.push('/overview/')}
        entitlement={entitlement}
      />
    </Page>
  );

  function writeToURLFromAnalyticsState() {
    if (!queryParams.has('environment'))
      queryParams.set('environment', environment);
    if (!queryParams.has('startDate'))
      queryParams.set('startDate', dateRange[0].toISOString(true));
    if (!queryParams.has('endDate'))
      queryParams.set('endDate', dateRange[1].toISOString(true));
    if (!queryParams.has('resolution')) queryParams.set('resolution', interval);
    if (!queryParams.has('segment')) queryParams.set('segment', segment || '');
    if (!queryParams.has('filters'))
      queryParams.set(
        'filters',
        filters.length ? JSON.stringify(filters) : '[]'
      );
    if (!queryParams.has('timezone')) queryParams.set('timezone', timezone);

    location.search = queryParams.toString();
    history.push(location);
  }

  function resetFilters() {
    actions.setEnvironment({ value: 'production' });
    queryParams.set('environment', 'production');
    actions.setInterval('day');
    queryParams.set('resolution', 'day');
    actions.setDateRangeInsights([
      momentCalculator(timezone, -30, 'day', 'day'),
      momentCalculator(timezone, 0, 'day', undefined, 'day'),
    ]);
    queryParams.set(
      'startDate',
      momentCalculator(timezone, -30, 'day', 'day').toISOString(true)
    );
    queryParams.set(
      'startDate',
      momentCalculator(timezone, 0, 'day', undefined, 'day').toISOString(true)
    );
    actions.setSegment(null);
    queryParams.set('segment', '');
    actions.setFilters({});
    queryParams.set('filters', '[]');

    location.search = queryParams.toString();
    history.push(location);
  }

  function fetchAndSetAnalyticData(): Promise<void> {
    return Promise.all([
      api.getCampaignLabels(appId, { pageSize: 1000 }),
      api.getCampaignRules(appId, { pageSize: 1000 }),
      api.getProducts(appId, { pageSize: 2500 }),
      api.getPaywalls(appId, { pageSize: 1000 }),
    ]).then(([placements, campaigns, products, paywalls]) => {
      actions.initializeState({
        insightType: insightType,
        interval: getInterval(),
        environment: getEnvironment(),
        dateRange: getDateRange(),
        segment: getSegment(userHasEntitlement),
        filters: getFilters(userHasEntitlement),
        products,
        campaigns,
        placements,
        paywalls,
        timezone: getTimezone(),
      });
    });
  }

  function getDateRange(): DateRangeType {
    const startDateRaw = queryParams.get('startDate');
    const endDateRaw = queryParams.get('endDate');

    if (!!startDateRaw && !!endDateRaw) {
      const startDate = moment(startDateRaw).tz(timezone);
      const endDate = moment(endDateRaw).tz(timezone);

      if (startDate.isValid() && endDate.isValid()) {
        return [startDate, endDate];
      }
    }
    return [
      momentCalculator(timezone, -30, 'day', 'day'),
      momentCalculator(timezone, 0, 'day', undefined, 'day'),
    ];
  }

  function getEnvironment(): TEnvironmentOption {
    const env = queryParams.get('environment') as EnvironmentType | null;
    if (!!env && environments.includes(env)) {
      return { value: env };
    }
    return { value: 'production' };
  }

  function getTimezone(): string {
    const tz = queryParams.get('timezone');
    if (!!tz && timezones.includes(tz)) {
      return tz;
    }

    return 'UTC';
  }

  function getInterval(): TimeDimensionGranularity {
    const interval = queryParams.get(
      'resolution'
    ) as TimeDimensionGranularity | null;
    if (!!interval && intervals.includes(interval)) {
      return interval;
    }

    return 'day';
  }

  function getSegment(
    hasEntitlement: (entitlement: string) => boolean
  ): SegmentType {
    const segmentKey = queryParams.get('segment') as SegmentType | null;
    if (
      !!segmentKey &&
      availableSegments.includes(segmentKey) &&
      hasEntitlement(SegmentOptions[segmentKey].entitlement)
    ) {
      return segmentKey;
    }
    return null;
  }

  function getFilters(
    hasEntitlement: (entitlement: string) => boolean
  ): TAppliedFilters {
    const filtersJSON = queryParams.get('filters');
    if (!!filtersJSON) {
      const filters: TMetricFilterRule[] = JSON.parse(filtersJSON);

      return filters.reduce((output, filter) => {
        const { identifier, values } = filter;
        const isValid =
          availableFilters.includes(identifier) &&
          hasEntitlement(FilterOptions[identifier].entitlement) &&
          values.length;
        return isValid ? { ...output, [uuid4()]: filter } : output;
      }, {});
    }
    return {};
  }
}
