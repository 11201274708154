import React, { useContext, useMemo } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { Alert, List } from 'antd';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import styled from 'styled-components';

import { TFont } from '../../../../api/types/main.types';
import { AppContext } from '../../../../AppContextProvider';
import {
  useDeleteFontsMutation,
  useOrgFontsQuery,
} from '../../../../hooks/queries/font.hooks';
import { findDefaultFont } from '../../../../utils/fonts';
import FontFamilyText from './FontFamilyText';
import FontSectionHeader from './FontSectionHeader';

const FontItem = styled(List.Item)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FontTextWrapper = styled.span`
  display: flex;
  flex-direction: column;
`;

export default function FontSection() {
  const { userHasEntitlement } = useContext(AppContext);
  const fontsQuery = useOrgFontsQuery();

  const fonts = fontsQuery.data?.results;

  const fontFamilies = useMemo(
    () =>
      (fonts || []).reduce((output, font) => {
        const fonts = output[font.family] || [];
        return { ...output, [font.family]: fonts.concat([font]) };
      }, {} as { [key: string]: TFont[] }),
    [fonts]
  );

  let list;

  if (!userHasEntitlement('font.list')) {
    list = <NoAccessFontList />;
  } else if ((fonts || []).length === 0 && !fontsQuery.isFetching) {
    list = <EmptyFontsList />;
  } else {
    const canDelete = userHasEntitlement('font.delete');
    list = (
      <List
        size="small"
        header={null}
        footer={null}
        bordered
        loading={fontsQuery.isFetching}
        dataSource={Object.entries(fontFamilies)}
        renderItem={([fontFamily, fonts]) => (
          <FontItem>
            <FontTextWrapper>
              <FontFamilyText
                font={findDefaultFont(fonts)}
                style={{ fontSize: '14pt' }}
              >
                {fontFamily}
              </FontFamilyText>
              <FontStyles fonts={fonts} />
            </FontTextWrapper>
            <DeleteFontButton fonts={fonts} disabled={!canDelete} />
          </FontItem>
        )}
        className="intercom-orgBrand-fontList"
      />
    );
  }
  return (
    <>
      <FontSectionHeader />
      <Alert
        type="warning"
        message="By uploading font files to your Nami Organization, you represent and warrant that your organization has all required licenses for these fonts. You also agree to indemnify and hold Nami harmless against any and all third party claims related to your use of fonts."
        showIcon
        style={{ marginBottom: '20px' }}
      />
      {list}
    </>
  );
}

function DeleteFontButton({
  fonts,
  disabled,
}: {
  fonts: TFont[];
  disabled?: boolean;
}) {
  const deleteFontsMutation = useDeleteFontsMutation(fonts);

  return (
    <IconActionButton
      size="small"
      icon={<DeleteOutlined style={{ fontSize: '12px' }} />}
      loading={deleteFontsMutation.isLoading}
      type="text"
      disabled={disabled}
      onClick={() => !disabled && deleteFontsMutation.mutate()}
    >
      Delete
    </IconActionButton>
  );
}

function EmptyFontsList() {
  return (
    <List
      size="small"
      header={null}
      footer={null}
      bordered
      dataSource={[
        <i>
          No Fonts Uploaded.{' '}
          <a href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/custom-brand-fonts">
            Read about Brand Fonts
          </a>
        </i>,
      ]}
      renderItem={(content) => <List.Item>{content}</List.Item>}
      className="intercom-orgBrand-emptyFonts"
    />
  );
}

function NoAccessFontList() {
  return (
    <List
      size="small"
      header={null}
      footer={null}
      bordered
      dataSource={[<i>You do not have access to custom fonts.</i>]}
      renderItem={(content) => <List.Item>{content}</List.Item>}
    />
  );
}

function FontStyles({ fonts }: { fonts: TFont[] }) {
  return (
    <span>
      {fonts
        .map((font) => (
          <FontFamilyText font={font} style={{ fontSize: '10pt' }}>
            {font.style}
          </FontFamilyText>
        ))
        .reduce((output, node, i) => {
          if (i === 0) return node;
          return (
            <>
              {output}, {node}
            </>
          );
        }, <></>)}
    </span>
  );
}
