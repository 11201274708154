import React from 'react';

import { SyncOutlined, WarningOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import styled from 'styled-components';

import { getRelativeTimeString } from '../utils/date';

type SyncSectionProps = {
  label: string;
  syncDate: Date | null;
  syncLabelPrefix: string;
  displayNonSynced?: boolean;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: end;
`;

export default function SyncStatus({
  label,
  syncDate,
  syncLabelPrefix,
  displayNonSynced = false,
}: SyncSectionProps) {
  if (!syncDate && !displayNonSynced) return null;
  if (!syncDate) {
    return (
      <Wrapper>
        <Space>
          <WarningOutlined />
          <span>Not Synced</span>
        </Space>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Space>
        <SyncOutlined />
        <span>{label}</span>
      </Space>
      <small>
        {syncLabelPrefix} {getRelativeTimeString(syncDate)}
      </small>
    </Wrapper>
  );
}
