import React, { useMemo } from 'react';

import { ResponsiveLine, Serie, SliceTooltipProps } from '@nivo/line';
import { LineChartStyle, MetricDataType } from 'src/api/types/analytics.types';
import {
  formatNumber,
  formatPercentageNumber,
  returnFormatForNivo,
} from 'src/utils/formatting';
import styled from 'styled-components';

type SharedLineChartProps = {
  data: Serie[];
  chartStyle: LineChartStyle;
  chartFormat: MetricDataType;
};

const Wrapper = styled.div`
  background: white;
  padding: 9px 12px;
  border-radius: 0.28571429rem;
  border: 1px solid #bfbfbf;
  box-shadow: 0 2px 10px 0 rgb(51 51 79 / 2%);
`;

const Point = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 0;
  font-size: 16px;
  font-weight: 500;
`;

const PointLabel = styled.span`
  font-size: 14px;
  margin-right: 0.5rem;
`;

const Bullet = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

export default function SharedLineChart({
  data,
  chartStyle,
  chartFormat,
}: SharedLineChartProps) {
  let margins = { top: 25, right: 25, bottom: 25, left: 45 };
  if (chartStyle === 'OVERVIEW')
    margins = { top: 30, right: 20, bottom: 50, left: 45 };

  const tickValues = useMemo(() => {
    if (data.length === 0) return [];
    const xValues = data[0].data.reduce(
      (out, data) => [...out, data.x as string],
      [] as string[]
    );
    const innerWidth = 1024;
    const gridWidth = Math.ceil(innerWidth / xValues.length);
    const tickDistance = Math.floor(300 / gridWidth);
    const values =
      tickDistance === 0
        ? xValues
        : xValues.filter((_, i) => i % tickDistance === 0);
    return Array.from(new Set(values));
  }, [data]);
  const yAxisNumberFormatter =
    chartFormat === 'PERCENT' ? formatPercentageNumber : formatNumber;
  return (
    <ResponsiveLine
      data={data}
      margin={margins}
      xScale={{ type: 'point' }}
      colors={[
        '#1374DE',
        '#66CC99',
        '#FFC669',
        '#FF5B74',
        '#9574EA',
        '#67D6E0',
        '#021F3E',
        '#73D13D',
        '#F262D3',
        '#EB7546',
      ]}
      yScale={{ type: 'linear', min: 0, max: 'auto' }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickValues,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 10,
        tickValues: 5,
        format: yAxisNumberFormatter,
      }}
      enableGridX={false}
      enableGridY={false}
      lineWidth={2}
      enablePoints={true}
      pointSize={2.5}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2.5}
      pointBorderColor={{ from: 'serieColor' }}
      pointLabelYOffset={-12}
      useMesh={false}
      enableCrosshair={false}
      enableSlices="x"
      sliceTooltip={MetricTooltip}
      yFormat={returnFormatForNivo(chartFormat)}
    />
  );
}

function MetricTooltip({ slice: { points } }: SliceTooltipProps) {
  return (
    <Wrapper>
      <div style={{ color: 'black' }}>{points[0].data.xFormatted}</div>
      {[...points].map((point) => (
        <Point key={point.id} style={{ color: point.serieColor }}>
          <Bullet style={{ background: point.serieColor }} />
          <PointLabel>{point.serieId}:</PointLabel>
          {point.data.yFormatted}
        </Point>
      ))}
    </Wrapper>
  );
}
