import { Card } from 'antd';
import { breakpointMd } from 'src/variables';
import styled from 'styled-components';

const CampaignCard = styled(Card)`
  margin-bottom: 24px;
  border-radius: 4px;

  box-shadow: 0.5px 0.5px 2px 0px rgba(76, 84, 90, 0.1);
  .ant-card-head {
    background-color: #ffffff;
  }

  @media only screen and (max-width: ${breakpointMd}) {
    margin-bottom: 16px;

    .ant-card-body {
      padding: 16px 16px !important;
    }
    .ant-card-head {
      padding: 0 16px !important;
    }
  }
`;

export default CampaignCard;
