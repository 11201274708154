import React from 'react';

import { Col, Form, Switch } from 'antd';
import { SwitchChangeEventHandler } from 'antd/es/switch';
import styled from 'styled-components';

import FieldHint from './FieldHint';

type DisplayFieldSwitchProps = {
  label: React.ReactNode;
  name?: string;
  hint?: string;
  checked: boolean;
  onChange: SwitchChangeEventHandler;
  withColumn?: boolean;
  tooltip?: React.ReactNode;
};

const Column = styled(Col)`
  padding-top: 8px;
  margin-bottom: 24px;
`;

const FormItem = styled(Form.Item)`
  margin-bottom: 0;

  .ant-form-item-label {
    padding: 0;
    margin-right: 1rem;
  }

  .ant-form-item-row {
    align-items: center;
    flex-direction: row;
  }

  .ant-form-item-control {
    width: 0 !important; // I don't know why
  }
`;

export function DisplayFieldSwitch({
  label,
  name,
  onChange,
  checked,
  hint,
  withColumn = false,
  tooltip,
}: DisplayFieldSwitchProps) {
  const content = (
    <>
      <FormItem name={name} label={<>{label}:</>} tooltip={tooltip}>
        <Switch checked={checked} onChange={onChange} />
      </FormItem>
      <FieldHint>{hint}</FieldHint>
    </>
  );
  if (!withColumn) return content;
  return (
    <Column xs={24} md={24}>
      {content}
    </Column>
  );
}
