import React from 'react';

import { LockFilled } from '@ant-design/icons';
import { Button } from 'antd';
import { namiBrightBlue } from 'src/variables';

const PlanUpgradeButton = ({ children, onClick }) => {
  return (
    <Button
      style={{ float: 'right' }}
      icon={<LockFilled style={{ color: namiBrightBlue }} />}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default PlanUpgradeButton;
