import React from 'react';

import { Form, Radio, Tooltip } from 'antd';
import { TOfferState } from 'src/api/types/paywall.types';
import SegmentStyledRadio from 'src/components/SegmentStyleRadio/SegmentStyledRadio';

import {
  useActions,
  useAppSelector,
} from '../../../../../../hooks/redux.hooks';
import PaywallBuilderSlice from '../../../../../../redux/PaywallBuilderSlice';
import { getPricingStateOptions } from '../../../utils/functions';

type ProductPricingFormItemProps = {
  includeIntroPromo: boolean;
};

export default function ProductPricingFormItem({
  includeIntroPromo,
}: ProductPricingFormItemProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const [
    anySkuHasIntroOffer,
    anySkuHasPromoOffer,
    anySkuHasTrialOffer,
    enableCheckForOffers,
    enableCheckForTrial,
    toggleOfferEligibility,
    toggleTrialEligiblity,
  ] = useAppSelector(({ paywallBuilder }) => {
    return [
      paywallBuilder.anySkuHasIntroOffer,
      paywallBuilder.anySkuHasPromoOffer,
      paywallBuilder.anySkuHasTrialOffer,
      paywallBuilder.paywall?.template.variables?.enableCheckForOffers,
      paywallBuilder.paywall?.template.variables?.enableCheckForTrial,
      (paywallBuilder.paywall?.template['ui.capabilities'] || []).includes(
        'toggle_product_offer'
      ),
      (paywallBuilder.paywall?.template['ui.capabilities'] || []).includes(
        'toggle_product_trial'
      ),
    ];
  });

  const offerOptions = getPricingStateOptions(includeIntroPromo);

  return (
    <Form.Item label="Pricing Eligibility">
      <Tooltip title={getTooltipContent()} mouseEnterDelay={0.6}>
        <SegmentStyledRadio
          onChange={(e) => changeOfferState(e.target.value as TOfferState)}
          value={pickPricingStateValue()}
          disabled={!canEdit()}
        >
          {offerOptions.map((option) => (
            <Radio.Button key={option.value} value={option.value}>
              {option.title}
            </Radio.Button>
          ))}
        </SegmentStyledRadio>
      </Tooltip>
    </Form.Item>
  );

  function getTooltipContent(): JSX.Element {
    if (toggleOfferEligibility || toggleTrialEligiblity) {
      if (!enableCheckForOffers && !enableCheckForTrial) {
        return (
          <>{`Turn on ${
            includeIntroPromo ? 'promo' : 'trial'
          } capability to preview`}</>
        );
      }
    }
    return <>Switch eligibility state to set product display</>;
  }

  function canEdit() {
    if (toggleOfferEligibility || toggleTrialEligiblity) {
      if (includeIntroPromo) return enableCheckForOffers;
      return enableCheckForTrial;
    }
    return true;
  }

  function changeOfferState(offer: TOfferState) {
    actions.setAnySkuHasTrialOffer(offer === 'trial');
    actions.setAnySkuHasIntroOffer(offer === 'intro');
    actions.setAnySkuHasPromoOffer(offer === 'promo');
  }

  function pickPricingStateValue(): TOfferState {
    return anySkuHasTrialOffer
      ? 'trial'
      : anySkuHasIntroOffer
      ? 'intro'
      : anySkuHasPromoOffer
      ? 'promo'
      : 'standard';
  }
}
