import React, { useMemo } from 'react';

import { BulbOutlined } from '@ant-design/icons';
import { Button, Col, Form, InputNumber, Popover, Row, Slider } from 'antd';
import styled from 'styled-components';
import tinycolor from 'tinycolor2';

import { FieldObject } from '../../../utils/formFieldBuilding';
import GradientPicker from './GradientPicker';
import InputLabel from './InputLabel';

type DropShadowProps = Omit<FieldObject, 'variable'> & {
  removable?: boolean;
  defaultValue?: string | number;
  value: string | null;
  onChange: (value: any) => void;
  label: string;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
};

type DropShadowFields = {
  x: number;
  y: number;
  blur: number;
  color: string;
};

const DropShadowCreatorButton = styled(Button)`
  &,
  &:hover,
  &:focus {
    flex-grow: 1;
    border: 1px solid rgb(229, 229, 229);
  }
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export default function DropShadowCreator({
  onChange,
  value,
  ...field
}: DropShadowProps) {
  const shadowParts: DropShadowFields = useMemo((): DropShadowFields => {
    const [, x, y, blur, color] = value.match(
      /(-?\d{1,2})\s(-?\d{1,2})\s(\d{1,2})\s(rgba?\((\d{1,3}\s?){3}\/ (\d?\.?\d+)\))/i
    );
    return {
      x,
      y,
      blur,
      color,
    };
  }, [value]);
  return (
    <Form.Item label={<InputLabel {...field} />}>
      <Wrapper className="ant-btn-group">
        <Popover
          overlayStyle={{ padding: 0, width: '300px' }}
          overlayInnerStyle={{ width: '300px' }}
          trigger="click"
          content={
            <>
              <Row justify="start">
                <Col span={20}>
                  <GradientPicker
                    value={shadowParts.color}
                    showGradientControls={false}
                    onChange={handleColorChange}
                    {...field}
                    name="Color"
                    showOpacity={true}
                  />
                </Col>
              </Row>
              <Row justify="start">
                <Col span={12}>
                  <InputLabel label={'X'} name={'X'} description={null} />
                  <Slider
                    min={-50}
                    max={50}
                    value={shadowParts.x}
                    onChange={handleXChange}
                  />
                </Col>
                <Col span={12}>
                  <InputNumber
                    min={-50}
                    max={50}
                    style={{ margin: '0 16px' }}
                    value={shadowParts.x}
                    onChange={handleXChange}
                  />
                </Col>
              </Row>
              <Row justify="start">
                <Col span={12}>
                  <InputLabel label={'Y'} name={'Y'} description={null} />
                  <Slider
                    min={-50}
                    max={50}
                    value={shadowParts.y}
                    onChange={handleYChange}
                  />
                </Col>
                <Col span={12}>
                  <InputNumber
                    min={-50}
                    max={50}
                    style={{ margin: '0 16px' }}
                    value={shadowParts.y}
                    onChange={handleYChange}
                  />
                </Col>
              </Row>
              <Row justify="start">
                <Col span={12}>
                  <InputLabel label={'Blur'} name={'Blur'} description={null} />
                  <Slider
                    min={0}
                    max={50}
                    value={shadowParts.blur}
                    onChange={handleBlurChange}
                  />
                </Col>
                <Col span={12}>
                  <InputNumber
                    min={0}
                    max={50}
                    style={{ margin: '0 16px' }}
                    value={shadowParts.blur}
                    onChange={handleBlurChange}
                  />
                </Col>
              </Row>
              <Row justify="start">
                <Col span={20}>
                  <Button type="ghost" size="small" onClick={clearDropShadow}>
                    Reset
                  </Button>
                </Col>
              </Row>
            </>
          }
        >
          <DropShadowCreatorButton icon={<BulbOutlined />}>
            {buildLabel()}
          </DropShadowCreatorButton>
        </Popover>
      </Wrapper>
    </Form.Item>
  );

  function buildLabel() {
    if (value) return 'Drop Shadow';
    return 'None';
  }

  function handleXChange(value: number | null): void {
    shadowParts.x = value || 0;
    handleChange();
  }

  function handleYChange(value: number | null): void {
    shadowParts.y = value || 0;
    handleChange();
  }

  function handleBlurChange(value: number | null): void {
    shadowParts.blur = value || 0;
    handleChange();
  }

  function handleColorChange(value: string): void {
    let tinyHex = tinycolor(value);
    if (tinyHex.isValid()) {
      const { r, g, b } = tinyHex.toRgb();
      const alpha = tinyHex.getAlpha();
      shadowParts.color = `rgb(${r} ${g} ${b} / ${alpha})`;
    } else {
      shadowParts.color = value;
    }
    handleChange();
  }

  function clearDropShadow(): void {
    shadowParts.x = 0;
    shadowParts.y = 0;
    shadowParts.blur = 0;
    handleChange();
  }

  function handleChange(): void {
    const valueForSDK = `${shadowParts.x} ${shadowParts.y} ${shadowParts.blur} ${shadowParts.color}`;
    if (!field.editable) return;
    onChange(valueForSDK);
  }
}
