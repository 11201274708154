import React from 'react';

import { PlanMeta, TDisplayProduct } from 'src/api/types/main.types';

import UpgradeWebPaywall, { WebPaywallProps } from './UpgradeWebPaywall';

const CustomerDetailWebPaywall = ({ onCancel, visible }: WebPaywallProps) => {
  const paywallsOnlyProducts: TDisplayProduct[] = [
    {
      product_value_props: ['Customer profile', 'Basic Customer Filters'],
      ...PlanMeta['paywalls_only_team_v1'],
    },
    {
      product_value_props: ['Advanced Customer Filters', 'CRM API'],
      ...PlanMeta['paywalls_only_business_v1'],
    },
  ];

  const paywallsAndSubsProducts: TDisplayProduct[] = [
    {
      product_value_props: ['Customer profile', 'Basic Customer Filters'],
      ...PlanMeta['paywalls_and_subs_team_v1'],
    },
    {
      product_value_props: ['Advanced Customer Filters', 'CRM API'],
      ...PlanMeta['paywalls_and_subs_business_v1'],
    },
  ];

  return (
    <UpgradeWebPaywall
      modalTitle="Unlock Customer Detail"
      productsPaywallsOnly={paywallsOnlyProducts}
      productsPaywallsAndSubs={paywallsAndSubsProducts}
      highlightedProductPaywallsOnly="paywalls_only_team_v1"
      highlightedProductPaywallsAndSubs="paywalls_and_subs_team_v1"
      entitlement="app.user.get"
      onCancel={onCancel}
      visible={visible}
      maskBackground={true}
    />
  );
};

export default CustomerDetailWebPaywall;
