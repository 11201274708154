import React, { useState } from 'react';

import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, Upload, notification } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import {
  RcFile,
  UploadChangeParam,
  UploadProps,
} from 'antd/lib/upload/interface';
import { UploadRequestOption } from 'node_modules/rc-upload/lib/interface';
import { namiDarkGray, namiSmoke } from 'src/variables';
import styled from 'styled-components';

import api from '../../../../../../api';
import ImageCroppingModal from '../../../../../../components/ImageCroppingModal';
import { useBooleanState } from '../../../../../../hooks';
import Token from '../../../../../../services/token';
import { FieldObject } from '../../../utils/formFieldBuilding';
import InputLabel from './InputLabel';

type BaseImageEditorProps = Omit<FieldObject, 'variable'> & {
  label: string;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
  controlled?: boolean;
};

type ImageEditorProps = BaseImageEditorProps &
  (
    | {
        uploadBeforeChange?: false;
        value: File | Blob | RcFile | string | null;
        onChange: (value: File | Blob | RcFile | string | null) => void;
      }
    | {
        uploadBeforeChange: true;
        value: string | null;
        onChange: (value: string | null) => void;
      }
  );

export const StyledUpload = styled(Upload)`
  .ant-upload-list-item {
    margin-top: 0px;
  }
  div.ant-upload-list-item-uploading {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18pt;
  }

  img.ant-upload-list-item-image {
    border-radius: 3px;
    border: 0.5px solid #ebebeb;
    object-fit: cover !important;
    background-color: ${namiSmoke};
  }

  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    padding: 5px;
  }

  .ant-upload-list-text .ant-upload-list-item-name,
  .ant-upload-list-picture .ant-upload-list-item-name {
    color: ${namiDarkGray} !important;
    font-size: 13px;
    padding: 5px;
  }
`;

export default function ImageButton({
  value,
  onChange,
  aspectRatio,
  uploadBeforeChange,
  ...field
}: ImageEditorProps) {
  const [isCropperOpen, showEditor, hideCropper] = useBooleanState(false);
  const [file, setFile] = useState<UploadFile | null>(
    value ? buildFile(value) : null
  );

  return (
    <Form.Item label={<InputLabel {...field} />} style={{ width: '100%' }}>
      <StyledUpload
        listType="picture"
        customRequest={({ file }: UploadRequestOption) => handleNewFile(file)}
        accept=".jpg,.jpeg,.png" // TODO: Pick these up from Omaha
        showUploadList={{
          removeIcon: <DeleteOutlined style={{ fontSize: 13 }} />,
        }}
        fileList={file ? [file] : []}
        onPreview={aspectRatio ? showEditor : undefined}
        onChange={handleChange as UploadProps['onChange']}
      >
        {!file && <Button icon={<UploadOutlined />}>Upload</Button>}
      </StyledUpload>
      {!!file?.url && aspectRatio && (
        <ImageCroppingModal
          imageUrl={file.url}
          visible={isCropperOpen}
          onCancel={hideCropper}
          onCrop={handleNewFile}
          aspectRatio={aspectRatio!}
        />
      )}
    </Form.Item>
  );

  function handleChange(info: UploadChangeParam): void {
    if (info.file.status === 'removed') {
      deleteImage();
    } else if (info.file.status === 'uploading') {
      if (info.file?.size && info.file.size > 300000) {
        const description =
          'This file is larger than 300KB. It may be slow to load or not load at all on mobile devices. Consider a smaller image.';
        notification.warning({ message: 'Large file', description });
      }
      setFile(info.file);
    }
  }

  function deleteImage() {
    if (!field.editable) return;
    onChange(null);
    setFile(null);
  }

  function handleNewFile(file: string | Blob | RcFile): void {
    if (!field.editable) return;
    if (uploadBeforeChange && file && typeof file !== 'string') {
      api.uploadFile(file, { orgId: Token.getOrgId()! }).then((url) => {
        onChange(url);
        setFile(buildFile(url));
      });
    } else {
      onChange(file as string | null);
      setFile(buildFile(file));
    }
  }
}

function buildFile(file: string | Blob | RcFile): UploadFile {
  const url = typeof file === 'string' ? file : URL.createObjectURL(file);
  const name = url.split('/').reverse()[0];
  return { uid: '-1', name, status: 'done', url };
}
