import { BinaryOperator, TimeDimensionGranularity } from '@cubejs-client/core';
import Moment from 'moment/moment';
import {
  formatCampaignLabelForDisplay,
  formatCampaignRuleForDisplay,
  formatPaywallForDisplay,
  formatProductForDisplay,
  formatSegmentPlatformForDisplay,
} from 'src/pages/admin/insights/InsightsTable';
import {
  AppPlatformSelect,
  CampaignSelect,
  CountrySelect,
  FilterSelectProps,
  LanguageSelect,
  PaywallSelect,
  PlacementSelect,
  ProductSKUSelect,
  SDKVersionSelect,
  SKUDurationSelect,
  SKUTypeSelect,
  TrialDurationSelect,
} from 'src/pages/admin/insights/selectors';
import {
  endOfToday,
  endOfYesterday,
  oneYearAgo,
  sevenDaysAgo,
  startOfToday,
} from 'src/services/utilsService';
import { momentCalculator } from 'src/utils/date';
import {
  capitalize,
  formatDuration,
  formatSegmentPlatform,
  translateCampaignId,
  translateCampaignLabel,
  translateCountryCode,
  translateLanguageCode,
  translatePaywallId,
  translateProductID,
} from 'src/utils/formatting';

export type EnvironmentType = 'production' | 'nonProduction';
export const environments: EnvironmentType[] = ['production', 'nonProduction'];
export const environmentLabels = {
  production: 'Production',
  nonProduction: 'Testing & Development',
} as const;
export type TEnvironmentOption = { value: EnvironmentType };

export type TPredefinedRange =
  | 'Last 24 hours'
  | 'Today'
  | 'Last 72 hours'
  | 'Yesterday'
  | 'Last 7 days'
  | 'This week'
  | 'Last 14 days'
  | 'Last 30 days'
  | 'This month'
  | 'Last 90 days'
  | 'Last 6 months'
  | 'This year'
  | 'Last 12 months';

export function getToday(
  timezone: string,
  interval?: TimeDimensionGranularity
): [Moment.Moment, Moment.Moment] {
  const endOfInterval = interval || 'day';
  return [
    momentCalculator(timezone, 0, 'day', 'day'),
    momentCalculator(timezone, 0, 'day', undefined, endOfInterval),
  ];
}

export function getLast72Hrs(
  timezone: string,
  interval?: TimeDimensionGranularity
): [Moment.Moment, Moment.Moment] {
  const endOfInterval = interval || 'day';
  return [
    momentCalculator(timezone, -72, 'hour', 'hour'),
    momentCalculator(timezone, -1, 'hour', undefined, endOfInterval),
  ];
}

export function getLast24Hrs(
  timezone: string,
  interval?: TimeDimensionGranularity
): [Moment.Moment, Moment.Moment] {
  const endOfInterval = interval || 'day';
  return [
    momentCalculator(timezone, -24, 'hour', 'hour'),
    momentCalculator(timezone, -1, 'hour', undefined, endOfInterval),
  ];
}

export function getYesterday(
  timezone: string,
  interval?: TimeDimensionGranularity
): [Moment.Moment, Moment.Moment] {
  const endOfInterval = interval || 'day';
  return [
    momentCalculator(timezone, -1, 'day', 'day'),
    momentCalculator(timezone, -1, 'day', undefined, endOfInterval),
  ];
}

export function getLast7Days(
  timezone: string,
  interval?: TimeDimensionGranularity
): [Moment.Moment, Moment.Moment] {
  const endOfInterval = interval || 'day';
  return [
    momentCalculator(timezone, -7, 'day', 'day'),
    momentCalculator(timezone, 0, 'day', undefined, endOfInterval),
  ];
}

export function getThisWeek(
  timezone: string,
  interval?: TimeDimensionGranularity
): [Moment.Moment, Moment.Moment] {
  const endOfInterval = interval || 'day';
  return [
    momentCalculator(timezone, 0, 'week', 'isoWeek'),
    momentCalculator(timezone, -1, 'hour', undefined, endOfInterval),
  ];
}

export function getLast14Days(
  timezone: string
): [Moment.Moment, Moment.Moment] {
  return [
    momentCalculator(timezone, -14, 'day', 'day'),
    momentCalculator(timezone, 0, 'day', undefined, 'day'),
  ];
}

export function getLast30Days(
  timezone: string
): [Moment.Moment, Moment.Moment] {
  return [
    momentCalculator(timezone, -30, 'day', 'day'),
    momentCalculator(timezone, 0, 'day', undefined, 'day'),
  ];
}

export function getThisMonth(timezone: string): [Moment.Moment, Moment.Moment] {
  return [
    momentCalculator(timezone, 0, 'month', 'month'),
    momentCalculator(timezone, 0, 'day', undefined, 'day'),
  ];
}

export function getLast90Days(
  timezone: string
): [Moment.Moment, Moment.Moment] {
  return [
    momentCalculator(timezone, -90, 'day', 'day'),
    momentCalculator(timezone, 0, 'day', undefined, 'day'),
  ];
}

export function getLast6Mths(timezone: string): [Moment.Moment, Moment.Moment] {
  return [
    momentCalculator(timezone, -6, 'month', 'day'),
    momentCalculator(timezone, 0, 'day', undefined, 'day'),
  ];
}

export function getLast12Mths(
  timezone: string
): [Moment.Moment, Moment.Moment] {
  return [
    momentCalculator(timezone, -12, 'month', 'day'),
    momentCalculator(timezone, 0, 'day', undefined, 'day'),
  ];
}

export function getThisYr(timezone: string): [Moment.Moment, Moment.Moment] {
  return [
    momentCalculator(timezone, 0, 'year', 'year'),
    momentCalculator(timezone, 0, 'day', undefined, 'day'),
  ];
}

export const predefinedRangeFunction: Record<
  TPredefinedRange,
  (
    timezone: string,
    interval?: TimeDimensionGranularity
  ) => [Moment.Moment, Moment.Moment]
> = {
  'Last 24 hours': getLast24Hrs,
  'Last 72 hours': getLast72Hrs,
  Today: getToday,
  Yesterday: getYesterday,
  'Last 7 days': getLast7Days,
  'This week': getThisWeek,
  'Last 14 days': getLast14Days,
  'Last 30 days': getLast30Days,
  'This month': getThisMonth,
  'Last 6 months': getLast6Mths,
  'Last 90 days': getLast90Days,
  'Last 12 months': getLast12Mths,
  'This year': getThisYr,
};

export const predefinedRangesByInterval: Record<
  TimeDimensionGranularity,
  Array<TPredefinedRange>
> = {
  second: [],
  minute: [],
  hour: ['Last 24 hours', 'Today', 'Yesterday', 'Last 72 hours', 'This week'],
  day: [
    'Today',
    'Yesterday',
    'Last 72 hours',
    'This week',
    'Last 14 days',
    'Last 30 days',
    'This month',
    'Last 90 days',
    'Last 6 months',
  ],
  week: [
    'Last 7 days',
    'Last 14 days',
    'Last 30 days',
    'This month',
    'Last 90 days',
    'Last 6 months',
    'Last 12 months',
    'This year',
  ],
  month: [
    'Last 30 days',
    'Last 90 days',
    'Last 6 months',
    'Last 12 months',
    'This year',
  ],
  quarter: ['Last 90 days', 'Last 6 months', 'Last 12 months', 'This year'],
  year: ['Last 12 months', 'This year'],
};

export const getDisabledDateRange: Record<
  TimeDimensionGranularity,
  (currentDate: Moment.Moment) => boolean
> = {
  //unused
  second: (currentDate: Moment.Moment) => {
    return (
      currentDate.isBefore(startOfToday) || currentDate.isAfter(endOfToday)
    );
  },
  //unused
  minute: (currentDate: Moment.Moment) => {
    return (
      currentDate.isBefore(endOfYesterday) || currentDate.isAfter(endOfToday)
    );
  },
  hour: (currentDate: Moment.Moment) => {
    return (
      currentDate.isBefore(sevenDaysAgo) || currentDate.isAfter(endOfToday)
    );
  },
  day: (currentDate: Moment.Moment) => {
    return currentDate.isBefore(oneYearAgo) || currentDate.isAfter(endOfToday);
  },
  week: (currentDate: Moment.Moment) => {
    return currentDate.isAfter(endOfToday);
  },
  month: (currentDate: Moment.Moment) => {
    return currentDate.isAfter(endOfToday);
  },
  quarter: (currentDate: Moment.Moment) => {
    return currentDate.isAfter(endOfToday);
  },
  year: (currentDate: Moment.Moment) => {
    return currentDate.isAfter(endOfToday);
  },
};

export const intervals: TimeDimensionGranularity[] = [
  'hour',
  'day',
  'week',
  'month',
  'quarter',
  'year',
];

export const timezones: string[] = ['UTC', 'US/Pacific'];

export type SegmentType =
  | null
  | 'ProductSKU.id'
  | 'ProductSKU.standardDuration'
  | 'ProductSKU.freeTrialDuration'
  | 'AppPlatform.platformType'
  | 'Purchase.purchaseCountry'
  | 'Device.country'
  | 'Device.language'
  | 'Device.sdkVersion'
  | 'ProductSKU.skuType'
  | 'CampaignLabel.id'
  | 'CampaignRule.id'
  | 'Paywall.id';

export interface SegmentData {
  display_label: string;
  entitlement: string;
  cube_segment: SegmentType;
  labelTransforms: Function[];
  tableDisplayTransform?: Function;
}

export const SegmentURLParams: Record<string, NonNullable<SegmentType>> = {
  purchase_country: 'Purchase.purchaseCountry',
  device_country: 'Device.country',
  sku: 'ProductSKU.id',
  sku_type: 'ProductSKU.skuType',
  trial_duration: 'ProductSKU.freeTrialDuration',
  product_duration: 'ProductSKU.standardDuration',
  placement: 'CampaignLabel.id',
  campaign: 'CampaignRule.id',
  paywall: 'Paywall.id',
  platform: 'AppPlatform.platformType',
  language: 'Device.language',
  sdk_version: 'Device.sdkVersion',
};

export const SegmentOptions: Record<NonNullable<SegmentType>, SegmentData> = {
  'ProductSKU.id': {
    display_label: 'Product SKU',
    entitlement: 'app.analytics.global_filter.by_product_sku',
    cube_segment: 'ProductSKU.id',
    labelTransforms: [translateProductID],
    tableDisplayTransform: formatProductForDisplay,
  },
  'ProductSKU.skuType': {
    display_label: 'SKU Type',
    entitlement: 'app.analytics.global_filter.by_purchase_type',
    cube_segment: 'ProductSKU.skuType',
    labelTransforms: [capitalize],
  },
  'ProductSKU.standardDuration': {
    display_label: 'Product Duration',
    entitlement: 'app.analytics.global_filter.by_product_duration',
    cube_segment: 'ProductSKU.standardDuration',
    labelTransforms: [formatDuration],
  },
  'ProductSKU.freeTrialDuration': {
    display_label: 'Trial Duration',
    entitlement: 'app.analytics.global_filter.by_trial_length',
    cube_segment: 'ProductSKU.freeTrialDuration',
    labelTransforms: [formatDuration],
  },
  'CampaignLabel.id': {
    display_label: 'Placement',
    entitlement: 'app.analytics.global_filter.by_campaign',
    cube_segment: 'CampaignLabel.id',
    labelTransforms: [translateCampaignLabel],
    tableDisplayTransform: formatCampaignLabelForDisplay,
  },
  'CampaignRule.id': {
    display_label: 'Campaign',
    entitlement: 'app.analytics.global_filter.by_campaign',
    cube_segment: 'CampaignRule.id',
    labelTransforms: [translateCampaignId],
    tableDisplayTransform: formatCampaignRuleForDisplay,
  },
  'Paywall.id': {
    display_label: 'Paywall',
    entitlement: 'app.analytics.global_filter.by_paywall',
    cube_segment: 'Paywall.id',
    labelTransforms: [translatePaywallId],
    tableDisplayTransform: formatPaywallForDisplay,
  },
  'AppPlatform.platformType': {
    display_label: 'Platform',
    entitlement: 'app.analytics.global_filter.by_platform',
    cube_segment: 'AppPlatform.platformType',
    labelTransforms: [formatSegmentPlatform],
    tableDisplayTransform: formatSegmentPlatformForDisplay,
  },
  'Purchase.purchaseCountry': {
    display_label: 'Country',
    entitlement: 'app.analytics.global_filter.by_country',
    cube_segment: 'Purchase.purchaseCountry',
    labelTransforms: [translateCountryCode],
  },
  'Device.country': {
    display_label: 'Country',
    entitlement: 'app.analytics.global_filter.by_country',
    cube_segment: 'Device.country',
    labelTransforms: [translateCountryCode],
  },
  'Device.language': {
    display_label: 'Language',
    entitlement: 'app.analytics.global_filter.by_language',
    cube_segment: 'Device.language',
    labelTransforms: [translateLanguageCode],
  },
  'Device.sdkVersion': {
    display_label: 'SDK Version',
    entitlement: 'app.analytics.global_filter.by_sdk_version',
    cube_segment: 'Device.sdkVersion',
    labelTransforms: [],
  },
};

export type TMetricFilter =
  | 'ProductSKU.id'
  | 'Paywall.id'
  | 'AppPlatform.platformType'
  | 'Purchase.purchaseCountry'
  | 'Device.country'
  | 'CampaignLabel.id'
  | 'CampaignRule.id'
  | 'ProductSKU.skuType'
  | 'Device.language'
  | 'Device.sdkVersion'
  | 'ProductSKU.standardDuration'
  | 'ProductSKU.freeTrialDuration';

export interface MetricFilterData {
  display_label: string;
  entitlement: string;
  filterSelectElement: (props: FilterSelectProps) => JSX.Element;
}

export type TMetricFilterRule = {
  identifier: TMetricFilter;
  operator: BinaryOperator;
  values: string[];
};

export const operators: BinaryOperator[] = [
  'equals',
  'notEquals',
  'contains',
  'notContains',
];

export type TAppliedFilters = { [key: string]: TMetricFilterRule };

export const FilterOptions: Record<TMetricFilter, MetricFilterData> = {
  'ProductSKU.id': {
    display_label: 'Product SKU',
    entitlement: 'app.analytics.global_filter.by_product_sku',
    filterSelectElement: ProductSKUSelect,
  },
  'Paywall.id': {
    display_label: 'Paywall',
    entitlement: 'app.analytics.global_filter.by_paywall',
    filterSelectElement: PaywallSelect,
  },
  'AppPlatform.platformType': {
    display_label: 'Platform',
    entitlement: 'app.analytics.global_filter.by_platform',
    filterSelectElement: AppPlatformSelect,
  },
  'Purchase.purchaseCountry': {
    display_label: 'Country',
    entitlement: 'app.analytics.global_filter.by_country',
    filterSelectElement: CountrySelect,
  },
  'Device.country': {
    display_label: 'Country',
    entitlement: 'app.analytics.global_filter.by_country',
    filterSelectElement: CountrySelect,
  },
  'Device.language': {
    display_label: 'Language',
    entitlement: 'app.analytics.global_filter.by_language',
    filterSelectElement: LanguageSelect,
  },
  'ProductSKU.skuType': {
    display_label: 'SKU Type',
    entitlement: 'app.analytics.global_filter.by_purchase_type',
    filterSelectElement: SKUTypeSelect,
  },
  'CampaignLabel.id': {
    display_label: 'Placement',
    entitlement: 'app.analytics.global_filter.by_campaign',
    filterSelectElement: PlacementSelect,
  },
  'CampaignRule.id': {
    display_label: 'Campaign',
    entitlement: 'app.analytics.global_filter.by_campaign',
    filterSelectElement: CampaignSelect,
  },
  'Device.sdkVersion': {
    display_label: 'SDK Version',
    entitlement: 'app.analytics.global_filter.by_sdk_version',
    filterSelectElement: SDKVersionSelect,
  },
  'ProductSKU.standardDuration': {
    display_label: 'Product Duration',
    entitlement: 'app.analytics.global_filter.by_product_duration',
    filterSelectElement: SKUDurationSelect,
  },
  'ProductSKU.freeTrialDuration': {
    display_label: 'Trial Duration',
    entitlement: 'app.analytics.global_filter.by_trial_length',
    filterSelectElement: TrialDurationSelect,
  },
};

export type DateRangeType = [Moment.Moment, Moment.Moment];

export type TData = {
  series: TDataSerie[];
  title: string;
  key: string;
};

export type TDataSerie = {
  value: number;
  x: string;
};

export type MetricDataType = 'INTEGER' | 'CURRENCY' | 'PERCENT';

export type DataKey =
  | 'PurchaseEvent.revenueInUSD'
  | 'SubscriptionRevenue.revenueInUSD'
  | 'Session.activeDevices'
  | 'Impression.impressions'
  | 'Impression.conversions'
  | 'Impression.conversionRate'
  | 'Session.count'
  | 'Purchase.count'
  | 'PurchaseEvent.count'
  | 'Purchase.trialConversionRate'
  | 'Purchase.trials'
  | 'Purchase.trialConversions';

export interface DataKeyFormat {
  display_name: string;
  format: MetricDataType;
}

export const DataKeys: Record<DataKey, DataKeyFormat> = {
  'PurchaseEvent.revenueInUSD': {
    display_name: 'Revenue',
    format: 'CURRENCY',
  },
  'SubscriptionRevenue.revenueInUSD': {
    display_name: 'MRR',
    format: 'CURRENCY',
  },
  'Session.activeDevices': {
    display_name: 'Devices',
    format: 'INTEGER',
  },
  'Impression.impressions': {
    display_name: 'Impressions',
    format: 'INTEGER',
  },
  'Impression.conversions': {
    display_name: 'Transactions',
    format: 'INTEGER',
  },
  'Impression.conversionRate': {
    display_name: 'Conversion Rate',
    format: 'PERCENT',
  },
  'Session.count': {
    display_name: 'Sessions',
    format: 'INTEGER',
  },
  'Purchase.count': {
    display_name: 'Purchases',
    format: 'INTEGER',
  },
  'PurchaseEvent.count': {
    display_name: 'Purchases',
    format: 'INTEGER',
  },
  'Purchase.trialConversionRate': {
    display_name: 'Trial Conversion Rate',
    format: 'PERCENT',
  },
  'Purchase.trials': {
    display_name: 'Trials',
    format: 'INTEGER',
  },
  'Purchase.trialConversions': {
    display_name: 'Conversions',
    format: 'INTEGER',
  },
};

export type LineChartStyle = 'DESKTOP' | 'MOBILE' | 'OVERVIEW';
