import React, { useEffect, useMemo, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, TablePaginationConfig } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import Responsive from 'src/components/Responsive/Responsive';

import AddPaywallModal from '../../../components/AddPaywallModal/AddPaywallModal';
import EmptyState from '../../../components/EmptyState/EmptyState';
import Loading from '../../../components/Loading/Loading';
import Page from '../../../components/Page/Page';
import SearchInput from '../../../components/SearchInput';
import { useAppContext, useBooleanState, useQueryParams } from '../../../hooks';
import { useApiOptions } from '../../../hooks/queries/apiOptions.hooks';
import { usePaywallsQuery } from '../../../hooks/queries/paywall.hooks';
import { useActions, useAppSelector } from '../../../hooks/redux.hooks';
import PaginationSlice from '../../../redux/PaginationSlice';
import FormFactorFilter from './FormFactorFilter';
import PaywallTable from './PaywallTable';
import StatusFilter from './StatusFilter';

export default function PaywallsPage() {
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const appContext = useAppContext();
  const { paywallsPage: page } = useAppSelector((state) => state.pagination);
  const { setPaywallsPage } = useActions(PaginationSlice.actions);
  const [status, setStatus] = useState<string | null>(
    queryParams.get('status') || null
  );
  const [formFactor, setFormFactor] = useState<string | null>(
    queryParams.get('form_factor') || null
  );
  const [apiOptions, updateApiOptions] = useApiOptions({
    pageSize: 20,
    enabled: getEnabledStatus(status),
    archived: getArchivedStatus(),
  });
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const paywallsQuery = usePaywallsQuery({ ...apiOptions, page });

  useEffect(() => {
    updateApiOptions({
      ...apiOptions,
      archived: getArchivedStatus(),
      enabled: getEnabledStatus(status),
      form_factor: formFactor || '',
    });
    queryParams.set('status', status || '');
    queryParams.set('form_factor', formFactor || '');
    location.search = queryParams.toString();
    history.push(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, formFactor]);

  const pagination = useMemo(
    (): TablePaginationConfig => ({
      total: paywallsQuery.data?.count,
      pageSize: apiOptions.pageSize,
      onChange: (newPage, pageSize) => {
        setPaywallsPage(newPage);
        updateApiOptions((state) => ({ ...state, pageSize }));
      },
      current: page,
      showSizeChanger: false,
      style: { paddingBottom: 72 },
      showTotal: (total, [n1, n2]) => `${n1} - ${n2} of ${total}`,
    }),
    [
      paywallsQuery.data,
      page,
      apiOptions.pageSize,
      updateApiOptions,
      setPaywallsPage,
    ]
  );

  const canCreate = appContext.userHasEntitlement('app.paywall.create');

  let children;
  if (paywallsQuery.isFetching) {
    children = <Loading />;
  } else if (paywallsQuery.data?.results.length === 0) {
    children = (
      <EmptyState
        icon="paywall"
        size="large"
        title="Create your first paywall."
        link="https://learn.namiml.com/public-docs/no-code-paywalls/overview"
        linkText="Learn more about paywalls."
      >
        <Button
          className="add-paywall"
          type="primary"
          icon={<PlusOutlined />}
          disabled={!canCreate}
          onClick={openModal}
        >
          Add Paywall
        </Button>
      </EmptyState>
    );
  } else {
    children = (
      <>
        <PaywallTable
          paywalls={paywallsQuery.data?.results || []}
          loading={paywallsQuery.isLoading}
          pagination={pagination}
        />
      </>
    );
  }

  return (
    <Page title="Paywalls">
      <Row justify="start" gutter={[0, 36]}>
        <Col xs={24} style={{ paddingBottom: 12 }}>
          <Breadcrumb items={[{ name: 'Paywalls' }]} />
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ paddingBottom: 24 }}>
        <Col xs={24} md={8}>
          <SearchInput
            // style={{ width: '100%' }}
            placeholder="Search by paywall name"
            value={apiOptions.search || ''}
            onChange={(search) => {
              setPaywallsPage(1);
              updateApiOptions({ search });
            }}
          />
        </Col>
        <Col sm={12} md={8}>
          <Space direction="horizontal">
            <StatusFilter
              status={status}
              onChange={(value) => {
                setPaywallsPage(1);
                setStatus(value);
              }}
              onClear={() => setStatus(null)}
            />
            <FormFactorFilter
              formFactor={formFactor}
              onChange={(value) => {
                setPaywallsPage(1);
                setFormFactor(value);
              }}
              onClear={() => setStatus(null)}
            />
          </Space>
        </Col>
        <Col sm={12} md={8}>
          <Button
            type="primary"
            className="add-paywall"
            icon={<PlusOutlined style={{ marginRight: '0.5rem' }} />}
            style={{ float: 'right' }}
            disabled={!canCreate}
            onClick={openModal}
          >
            <Responsive size="mdUp">Add Paywall</Responsive>
            <Responsive size="mdDown">Add</Responsive>
          </Button>
        </Col>
      </Row>
      {children}
      <AddPaywallModal open={isModalOpen} onClose={closeModal} />
    </Page>
  );

  function getArchivedStatus(): boolean {
    if (status === 'archived') return true;
    return false;
  }

  function getEnabledStatus(status: string | null): boolean | undefined {
    if (status === 'enabled') return true;
    if (status === 'paused') return false;
    return undefined;
  }
}
