import React from 'react';

import { CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import LiveBadgeDot from 'src/components/StatusDots/LiveBadgeDot';
import { namiMediumGray } from 'src/variables';
import styled from 'styled-components';

import StatusSelect from '../campaigns/StatusSelect';

type StatusFilterProps = {
  status: string | null;
  onChange: (value: string | null) => void;
  onClear: () => void;
  includeArchived?: boolean;
};

const WithWidth = styled.div<{ as: any }>`
  width: 175px;
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
`;

export default function StatusFilter({
  status,
  onChange,
  onClear,
  includeArchived = true,
}: StatusFilterProps) {
  let options = [
    {
      label: <>Live {<LiveBadgeDot />}</>,
      text: 'Live',
      value: 'enabled',
    },
  ];
  if (includeArchived) {
    options.push({
      label: (
        <>
          Archived{' '}
          {
            <DeleteOutlined
              style={{
                fontSize: 12,
                color: `${namiMediumGray}`,
                marginLeft: 2,
              }}
            />
          }
        </>
      ),
      text: 'Archived',
      value: 'archived',
    });
  }
  return (
    <SelectWrapper>
      <WithWidth
        as={StatusSelect}
        allowClear
        mode={undefined}
        showArrow={false}
        optionFilterProp="text"
        options={options}
        value={status}
        onChange={(value: string) => onChange(value)}
        onClear={onClear}
        clearIcon={<CloseOutlined />}
        label={status ? 'Status:' : ''}
        placeholder={
          <>
            Filter by <b>Status</b>
          </>
        }
      />
    </SelectWrapper>
  );
}
