import { useCallback, useState } from 'react';

export function useStepper(
  step: number,
  max: number,
  min: number,
  initialValue: number
): [number, () => void, () => void, (value: number) => void] {
  const [value, setValue] = useState<number>(initialValue);

  const stepValueUp = useCallback((): void => {
    setValue((currentValue) => {
      const steppedValue = currentValue + step;
      return steppedValue > max ? max : steppedValue;
    });
  }, [step, max]);

  const stepValueDown = useCallback((): void => {
    setValue((currentValue) => {
      const steppedValue = currentValue - step;
      return steppedValue < min ? min : steppedValue;
    });
  }, [step, min]);
  return [value, stepValueUp, stepValueDown, setValue];
}
