import React from 'react';

import FilterSelect, { BaseFilterSelectProps } from './FilterSelect';
import { identifierLabel } from './mapsAndOptions';

export default function NamiExternalIDSelect(props: BaseFilterSelectProps) {
  return (
    <FilterSelect
      {...props}
      label={identifierLabel['Device.external_id']}
      operators={['equals', 'not_equals']}
      type="string"
      variant="manual_value"
    />
  );
}
