import React, { Fragment, useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import { connect } from 'react-redux';
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Icon,
  Image,
  Label,
  List,
  Segment,
} from 'semantic-ui-react';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import { namiPrimaryBlue } from 'src/variables';

import ConfirmChangesModal from '../../../../components/ConfirmChangesModal/ConfirmChangesModal';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import DeleteSegment from '../../../../components/DeleteSegment/DeleteSegment';
import Loading from '../../../../components/Loading/Loading';
import Page from '../../../../components/Page/Page';
import SectionTitle from '../../../../components/SectionTitle';
import { useAppContext } from '../../../../hooks';
import {
  campaignLive,
  campaignOffline,
  deleteCampaign,
  getCampaign,
  getCampaigns,
  handleCampaignField,
  updateCampaign,
} from '../../../../redux/actions/campaignActions';
import { getPaywalls } from '../../../../redux/actions/paywallActions';
import history from '../../../../services/history';
import { extractFieldError } from '../../../../services/utilsService';

const CampaignDetailLegacyPage = ({
  match,
  loading,
  changes,
  currentApp,
  campaign,
  updateCampaignError,
  paywalls,
  getCampaign,
  deleteCampaign,
  updateCampaign,
  handleCampaignField,
  campaignLive,
  campaignOffline,
  getCampaigns,
  getPaywalls,
}) => {
  const context = useAppContext();
  const [modal, setModal] = useState(false);
  const [limit, setLimit] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [offlineModal, setOfflineModal] = useState(false);

  useEffect(() => {
    if (currentApp && currentApp.id) {
      getPaywalls(currentApp.id);
      getCampaign(match.params.campaignID);
      getCampaigns(currentApp.id);
    }
  }, [
    currentApp,
    match.params.campaignID,
    getPaywalls,
    getCampaign,
    getCampaigns,
  ]);

  useEffect(() => {
    if (campaign && campaign.name) {
      if (
        (campaign.rules.limit && campaign.rules.limit.interval !== undefined) ||
        (campaign.rules.limit && campaign.rules.limit.entity !== undefined)
      ) {
        setLimit(
          campaign.rules.limit.interval !== '' ||
            campaign.rules.limit.entity !== ''
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign, match.params.campaignID]);

  const toggleLimit = () => {
    if (limit) {
      handleCampaignField({
        ...campaign,
        rules: {
          ...campaign.rules,
          limit: {
            interval: '',
            entity: '',
          },
        },
      });
    }
    setLimit(!limit);
  };

  const safeRulesValue = (obj, type, key) => {
    try {
      return obj.rules[type][key];
    } catch (e) {
      return null;
    }
  };

  const getCurrentPaywall = (type) => {
    if (campaign[type]) {
      return paywallOptions().find((option) => option.value === campaign[type]);
    } else {
      return null;
    }
  };

  const getDisplayIntervals = () => {
    return Array.from(new Array(10), (x, i) => i + 1).map((i, k) => ({
      key: k,
      text: i,
      value: i,
    }));
  };

  const goToPresentationRules = () => {
    window.open(
      'https://docs.namiml.com/v1.0/docs/campaign-behavior#display-every-rules',
      '_blank'
    );
  };

  const renderConfirmChangesModal = () => {
    return (
      <ConfirmChangesModal
        when={changes}
        confirm={() => updateCampaign(campaign.id, campaign)}
        loading={loading}
        title="You have unsaved changes"
        body="Are you sure you want to leave without saving your changes?"
      />
    );
  };

  const renderConfirmModal = () => {
    return (
      <ConfirmModal
        open={confirm}
        onClose={() => setConfirm(false)}
        action={() => {
          updateCampaign(campaign.id, campaign);
          setConfirm(false);
        }}
        loading={loading}
        title="Changing Live Campaign"
        body="This campaign is live and any changes will be seen by your app users immediately. Are you sure you want to make these changes?"
        buttonText="Yes. Update Live Campaign."
        buttonClass="nami-primary-button"
      />
    );
  };

  const renderConfirmOfflineModal = () => {
    return (
      <ConfirmModal
        open={offlineModal}
        onClose={() => setOfflineModal(false)}
        action={() => {
          campaignOffline(currentApp.id).then(() => {
            handleCampaignField({ ...campaign, is_active: null }, false);
          });
          setOfflineModal(false);
        }}
        loading={loading}
        title="Deactivate Campaign Confirmation"
        body="Are you sure you want to deactivate"
        explanation="Deactivating this campaign may cause your app to stop working as expected and may cause your paywall to stop capturing revenue."
        name={campaign.name}
        buttonText="Deactivate Campaign"
      />
    );
  };

  const UpdateCampaign = () => {
    if (campaign.is_active) {
      setConfirm(true);
    } else {
      updateCampaign(campaign.id, campaign);
    }
  };

  const paywallOptions = () => {
    return [{ key: 'none', label: 'None', value: null }].concat(
      paywalls.map((paywall, index) => ({
        key: index,
        value: paywall.id,
        text: (
          <List horizontal>
            {paywall.background_image_url_tablet ||
            paywall.background_image_url_phone ? (
              <Fragment>
                <List.Item>
                  <Image
                    width="35px"
                    height="35px"
                    alt={paywall.name}
                    src={
                      paywall.background_image_url_tablet ||
                      paywall.background_image_url_phone
                    }
                    size="mini"
                  />
                </List.Item>
                <List.Item>{paywall.name}</List.Item>
              </Fragment>
            ) : (
              <Fragment>
                <List.Item
                  style={{
                    backgroundColor: namiPrimaryBlue,
                    width: 35,
                    height: 35,
                  }}
                />
                <List.Item
                  style={{
                    position: 'relative',
                    top: '-12px',
                  }}
                >
                  {paywall.name}
                </List.Item>
              </Fragment>
            )}
          </List>
        ),
        content: (
          <List horizontal>
            {paywall.background_image_url_tablet ||
            paywall.background_image_url_phone ? (
              <Fragment>
                <List.Item>
                  <Image
                    width="35px"
                    height="35px"
                    alt={paywall.name}
                    src={
                      paywall.background_image_url_tablet ||
                      paywall.background_image_url_phone
                    }
                    size="mini"
                  />
                </List.Item>
                <List.Item>{paywall.name}</List.Item>
              </Fragment>
            ) : (
              <Fragment>
                <List.Item
                  style={{
                    backgroundColor: namiPrimaryBlue,
                    width: 35,
                    height: 35,
                  }}
                >
                  {' '}
                </List.Item>
                <List.Item
                  style={{
                    position: 'relative',
                    top: '-12px',
                  }}
                >
                  {paywall.name}
                </List.Item>
              </Fragment>
            )}
          </List>
        ),
      }))
    );
  };

  const renderConfirmDelete = () => {
    if (campaign && campaign.name) {
      return (
        <ConfirmModal
          open={modal}
          onClose={() => setModal(false)}
          action={() => {
            deleteCampaign(campaign.id);
            setModal(false);
            history.push('/campaigns/');
          }}
          loading={loading}
          title="Delete Campaign Confirmation"
          body="Are you sure you want to delete"
          name={campaign.name}
          buttonText="Yes. Delete This Campaign."
        />
      );
    }
  };

  const renderRules = () => {
    return (
      <Fragment>
        {campaign.ml_enabled && (
          <Segment basic>
            <Form.Field>
              <Checkbox
                label="Smart Paywall"
                disabled={!context.userHasEntitlement('app.campaign.update')}
                slider
                onChange={() => {
                  handleCampaignField({
                    ...campaign,
                    ml_triggered: !campaign.ml_triggered,
                  });
                }}
                checked={campaign.ml_triggered}
              />
            </Form.Field>
          </Segment>
        )}
        <Grid container>
          <Grid.Row>
            <Grid.Column
              mobile={4}
              tablet={4}
              computer={2}
              verticalAlign="middle"
            >
              Display every
            </Grid.Column>
            <Grid.Column mobile={12} tablet={6} computer={7}>
              <Dropdown
                placeholder="N"
                options={getDisplayIntervals()}
                disabled={!context.userHasEntitlement('app.campaign.update')}
                compact
                fluid
                selection
                onChange={(e, data) => {
                  handleCampaignField({
                    ...campaign,
                    rules: {
                      ...campaign.rules,
                      display: {
                        ...campaign.rules.display,
                        interval: data.value,
                      },
                    },
                  });
                }}
                value={safeRulesValue(campaign, 'display', 'interval')}
              />
            </Grid.Column>
            <Grid.Column mobile={12} tablet={6} computer={7} floated="right">
              <Dropdown
                placeholder="Select metric..."
                options={[
                  { key: 0, text: 'Sessions', value: 'sessions' },
                  {
                    key: 1,
                    text: 'Core Content Items',
                    value: 'core_content_views',
                  },
                ]}
                disabled={!context.userHasEntitlement('app.campaign.update')}
                onChange={(e, data) => {
                  handleCampaignField({
                    ...campaign,
                    rules: {
                      ...campaign.rules,
                      display: {
                        ...campaign.rules.display,
                        entity: data.value,
                      },
                    },
                  });
                }}
                value={safeRulesValue(campaign, 'display', 'entity')}
                selection
                fluid
                compact
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Checkbox
                slider
                disabled={!context.userHasEntitlement('app.campaign.update')}
                label="Limit frequency of paywall"
                onChange={toggleLimit}
                checked={limit}
              />
            </Grid.Column>
          </Grid.Row>
          {limit && (
            <Grid.Row>
              <Grid.Column
                mobile={4}
                tablet={1}
                computer={1}
                verticalAlign="middle"
              >
                to
              </Grid.Column>
              <Grid.Column mobile={12} tablet={6} computer={6}>
                <Dropdown
                  placeholder="N"
                  disabled={!context.userHasEntitlement('app.campaign.update')}
                  options={getDisplayIntervals()}
                  onChange={(e, data) => {
                    handleCampaignField({
                      ...campaign,
                      rules: {
                        ...campaign.rules,
                        limit: {
                          ...campaign.rules.limit,
                          interval: data.value,
                        },
                      },
                    });
                  }}
                  value={safeRulesValue(campaign, 'limit', 'interval')}
                  compact
                  fluid
                  selection
                />
              </Grid.Column>
              <Grid.Column
                mobile={4}
                tablet={3}
                computer={2}
                verticalAlign="middle"
              >
                time(s) per
              </Grid.Column>
              <Grid.Column mobile={12} tablet={6} computer={7}>
                <Dropdown
                  placeholder="Select time period..."
                  disabled={!context.userHasEntitlement('app.campaign.update')}
                  options={[
                    { key: 0, text: 'Hour', value: 'hour' },
                    { key: 1, text: 'Day', value: 'day' },
                    { key: 2, text: 'Week', value: 'week' },
                    { key: 3, text: 'Month', value: 'month' },
                  ]}
                  onChange={(e, data) => {
                    handleCampaignField({
                      ...campaign,
                      rules: {
                        ...campaign.rules,
                        limit: {
                          ...campaign.rules.limit,
                          entity: data.value,
                        },
                      },
                    });
                  }}
                  value={safeRulesValue(campaign, 'limit', 'entity')}
                  selection
                  fluid
                  compact
                />
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Fragment>
    );
  };

  let children;
  if (loading) {
    children = <Loading />;
  } else if (!loading && campaign) {
    const options = paywallOptions();
    const userInitiatedPaywall = getCurrentPaywall('user_initiated');
    const namiTriggeredPaywall = getCurrentPaywall('nami_triggered');
    children = (
      <>
        <Row justify="start" gutter={[0, 36]}>
          <Col xs={24} style={{ paddingBottom: 12 }}>
            <Breadcrumb
              items={[
                { url: '/legacy/campaigns/', name: 'Legacy Campaigns' },
                { name: campaign.name },
              ]}
            />
          </Col>
        </Row>
        <div style={{ minHeight: '60vh' }} className={'campaigns'}>
          {campaign.is_active && (
            <Segment basic textAlign="center">
              <Icon color="yellow" name="warning sign" /> This campaign is{' '}
              <strong>LIVE</strong>
            </Segment>
          )}
          <Segment>
            <Segment basic>
              <Form>
                <Form.Input
                  label="Name"
                  placeholder="Campaign Name"
                  disabled={!context.userHasEntitlement('app.campaign.update')}
                  onChange={(e) =>
                    handleCampaignField({ ...campaign, name: e.target.value })
                  }
                  value={campaign.name}
                  error={extractFieldError(updateCampaignError?.name)}
                />

                <SectionTitle>
                  Nami Triggered Paywall
                  {namiTriggeredPaywall && (
                    <Icon
                      style={{ cursor: 'pointer', paddingLeft: '1em' }}
                      onClick={() =>
                        history.push(
                          `/paywalls/legacy/${namiTriggeredPaywall.value}/`
                        )
                      }
                      size="small"
                      name="external alternate"
                    />
                  )}
                </SectionTitle>
                <Form.Field>
                  <Dropdown
                    selection
                    disabled={
                      !context.userHasEntitlement('app.campaign.update')
                    }
                    placeholder="Select a Paywall"
                    value={namiTriggeredPaywall && namiTriggeredPaywall.value}
                    onChange={(e, data) =>
                      handleCampaignField({
                        ...campaign,
                        nami_triggered: data.value,
                      })
                    }
                    options={options}
                  />
                </Form.Field>
                <p>
                  This <strong>paywall</strong> is presented automatically to
                  your users based on a combination of business rules and
                  machine learning.
                </p>
                {campaign.nami_triggered && (
                  <Fragment>
                    <SectionTitle>
                      Presentation Rules{' '}
                      <Icon
                        style={{ cursor: 'pointer', paddingLeft: '1em' }}
                        onClick={goToPresentationRules}
                        size="small"
                        name="external alternate"
                      />
                    </SectionTitle>
                    {renderRules()}
                  </Fragment>
                )}
                <SectionTitle>
                  User Initiated Paywall
                  {userInitiatedPaywall && (
                    <Icon
                      style={{ cursor: 'pointer', paddingLeft: '1em' }}
                      onClick={() =>
                        history.push(
                          `/paywalls/legacy/${userInitiatedPaywall.value}`
                        )
                      }
                      size="small"
                      name="external alternate"
                    />
                  )}
                </SectionTitle>
                <Form.Field>
                  <Dropdown
                    selection
                    disabled={
                      !context.userHasEntitlement('app.campaign.update')
                    }
                    placeholder="Select a Paywall"
                    value={userInitiatedPaywall && userInitiatedPaywall.value}
                    onChange={(e, data) =>
                      handleCampaignField({
                        ...campaign,
                        user_initiated: data.value,
                      })
                    }
                    options={options}
                  />
                </Form.Field>
                <p>
                  This <strong>paywall</strong> is presented in response to your
                  users taking an action in your app asking to see your paid
                  products.
                </p>

                <Form.Field>
                  <Button
                    disabled={
                      !context.userHasEntitlement('app.campaign.update')
                    }
                    onClick={UpdateCampaign}
                    className="nami-primary-button"
                    style={{ marginBottom: 15 }}
                  >
                    Update Campaign
                  </Button>
                  {campaign.is_active ? (
                    <Button
                      basic
                      color="red"
                      onClick={() => setOfflineModal(true)}
                      disabled={
                        !context.userHasEntitlement('app.campaign.update')
                      }
                    >
                      <Label
                        circular
                        color="red"
                        empty
                        style={{ marginRight: 5 }}
                      />{' '}
                      Deactivate Campaign
                    </Button>
                  ) : (
                    <Button
                      basic
                      color="green"
                      onClick={() =>
                        campaignLive(campaign.id).then(() => {
                          handleCampaignField(
                            { ...campaign, is_active: true },
                            false
                          );
                        })
                      }
                      disabled={
                        !context.userHasEntitlement('app.campaign.update')
                      }
                    >
                      <Label
                        circular
                        color="green"
                        empty
                        style={{ marginRight: 5 }}
                      />{' '}
                      Set Campaign Live
                    </Button>
                  )}
                </Form.Field>
              </Form>
            </Segment>

            {!campaign.is_active && (
              <Segment basic>
                <DeleteSegment
                  title="Delete Campaign"
                  topWarning="Deleting this campaign will remove all your configuration progress."
                  bottomWarning="A deleted campaign cannot be restored."
                  action={() => setModal(true)}
                  actionText="Delete Campaign"
                  disabled={!context.userHasEntitlement('app.campaign.delete')}
                />
              </Segment>
            )}
          </Segment>
          {renderConfirmModal()}
          {renderConfirmOfflineModal()}
          {renderConfirmChangesModal()}
          {renderConfirmDelete()}
        </div>
      </>
    );
  } else {
    children = null;
  }
  return <Page title={campaign?.name}>{children}</Page>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCampaign: (campaignId) => dispatch(getCampaign(campaignId)),
    getCampaigns: (appID) => dispatch(getCampaigns(appID)),
    handleCampaignField: (data, changes) =>
      dispatch(handleCampaignField(data, changes)),
    campaignLive: (campaignId) => dispatch(campaignLive(campaignId)),
    campaignOffline: (appId) => dispatch(campaignOffline(appId)),
    getPaywalls: (appId) => dispatch(getPaywalls(appId)),
    deleteCampaign: (campaignId) => dispatch(deleteCampaign(campaignId)),
    updateCampaign: (campaignId, data) =>
      dispatch(updateCampaign(campaignId, data)),
  };
};

const mapStateToProps = ({ root, paywall, campaign }) => {
  return {
    currentApp: root.currentApp,
    paywalls: paywall.paywalls,
    loading: campaign.loading,
    changes: campaign.changes,
    campaign: campaign.campaign,
    campaignStatusChangeError: campaign.campaignStatusChangeError,
    updateCampaignError: campaign.updateCampaignError,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignDetailLegacyPage);
