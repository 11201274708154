import {
  TComponent,
  TComponentLocation,
  TField,
  TFieldSettings,
  TImageComponent,
  TListSubComponent,
  TTextComponent,
} from '../../../../api/types/paywallTemplate.types';
import { flatten } from '../../../../utils/array';
import { capitalizePhrase, toReadable } from '../../../../utils/string';

// TODO: Kill this once we can confirm "ui.formGroups" exists in all paywall templates

type LooseObject = { [key: string]: any };

export type InitialValuesType = { [key: string]: string | number };

export interface IFieldGroups {
  [groupName: string]: {
    [fieldLabel: string]: {
      display: boolean | null;
      textPath: string;
      switcherPath: string;
      hint: string | undefined;
      defaultTextValue: string;
      collapsible: boolean;
      fields: FieldObject[];
    };
  };
}

export type FieldObject = {
  variable: string;
  name: string;
  type: TField;
  value: any;
  hint: string | null;
  markdownHint: boolean;
  multiline?: boolean;
  splitLines?: boolean;
  editable: boolean;
  placeholder: string | null;
  description: string | null;
  aspectRatio: number | null;
  options: { label: string; value: any }[] | null;
  maxLimit: number | null;
  minLimit: number | null;
  carousel: string | null;
  darkModeVariable: string | null;
  showSmartTextCloud?: boolean;
  showVariableCloud?: boolean;
  showOpacity?: boolean;
  newRow?: any;
  availableListComponents?: TListSubComponent[] | null;
  componentLocation?: TComponentLocation;
  component?: TComponent;
  currentAssertionIndex?: number;
  parentDataSourceRoot?: string | null;
};

const FIELD_TYPES: { [key: string]: TField } = {
  Size: 'number',
  Width: 'number',
  Radius: 'number',
  Color: 'color',
  Alignment: 'alignment',
};
const TEXT_FIELDS = new Set(['text', 'texts']);
const NON_NESTED_TYPES = new Set(['undefined', 'string', 'number']);
const CONTROL_LABELS = new Set(['Sign In Button', 'Restore Button']);

const CONTROL_VALUES: LooseObject = {
  'Sign In Button': '**Sign In**',
  'Restore Button': '**Restore Purchases**',
};

const EDITABLE_ATTRS = new Set([
  'url',
  'fontSize',
  'fontColor',
  'alignment',
  'text',
  'texts',
  'fillColor',
  'borderWidth',
  'borderRadius',
  'borderColor',
]);

export function buildFieldGroups(
  // TODO: Kill this flow
  data: any,
  output: IFieldGroups = {},
  path: Array<string | number> = []
): IFieldGroups {
  if (!isNested(data)) return output;
  if (Array.isArray(data)) {
    return data.reduce((output, value, i) => {
      return buildFieldGroups(value, output, path.concat(i));
    }, output);
  }
  if (isEditable(data)) {
    if (data._fields) {
      output = fieldReducer(output, path.join('.'), data);
    } else {
      output = legacyFieldReducer(output, path.join('.'), data);
    }
  }
  return Object.entries(data).reduce((output, [key, value]) => {
    if (key.startsWith('_')) return output;
    return buildFieldGroups(value, output, path.concat(key));
  }, output);
}

function fieldReducer(
  output: IFieldGroups,
  fieldPrefix: string,
  component: TComponent
) {
  const fields = component._fields!;
  return Object.entries(fields).reduce(
    (output, [attribute, fieldSettings]): IFieldGroups => {
      const skipField =
        typeof fieldSettings === 'string' ||
        fieldSettings === null ||
        !(attribute in component);
      if (skipField) return output;
      const field = buildFieldObject(
        {
          ...fieldSettings,
          aspectRatio: (component as TImageComponent).aspectRatio || null,
          variable: `${fieldPrefix}.${attribute}`,
        },
        component[attribute as keyof TComponent]
      );
      const group = output[fields._group] || {};
      const fieldsWrapper = group[fields._label] || {
        fields: [],
        textPath: fields._toggleAttr
          ? `${fieldPrefix}.${fields._toggleAttr}`
          : '',
        switcherPath: `${fieldPrefix}.switcher`,
        hint: fields._hint,
        defaultTextValue: fields._toggleValue,
        collapsible: fields._collapsible || false,
        display:
          typeof fields._toggleAttr === 'string' ? !!fields._toggleAttr : null,
      };
      return {
        ...output,
        [fields._group!]: {
          ...group,
          [fields._label!]: {
            ...fieldsWrapper,
            fields: fieldsWrapper.fields.concat(field),
          },
        },
      };
    },
    output
  );
}

export function buildFieldObject(
  fieldSettings: TFieldSettings,
  value: any
): FieldObject {
  return {
    variable: fieldSettings.variable,
    name: fieldSettings.label,
    carousel: fieldSettings.carousel || null,
    value,
    placeholder: fieldSettings.placeholder || null,
    description: fieldSettings.description || null,
    type: fieldSettings.type,
    hint: fieldSettings.hint || null,
    markdownHint: fieldSettings.markdownHint || false,
    editable: !fieldSettings.readOnly,
    aspectRatio: fieldSettings.aspectRatio || null,
    options: fieldSettings.options || null,
    maxLimit: fieldSettings.maxLimit || null,
    minLimit: fieldSettings.minLimit || null,
    darkModeVariable: fieldSettings.darkModeVariable || null,
    showOpacity: fieldSettings.showOpacity || false,
  };
}

function legacyFieldReducer(
  output: IFieldGroups,
  fieldPrefix: string,
  component: TComponent
): IFieldGroups {
  const {
    _fieldGroupLabel,
    _fieldReadOnly,
    _fieldLabel,
    _fieldPlaceholder,
    _fieldDescription,
    _fieldHint,
    _fieldOmit,
    ...fieldComponent
  } = component;
  const textComponent = fieldComponent as TTextComponent;
  const { aspectRatio } = fieldComponent as TImageComponent;
  const toOmit = new Set(_fieldOmit || []);
  const displayValue = CONTROL_LABELS.has(_fieldLabel!)
    ? !!textComponent.text
    : null;
  return Object.entries(fieldComponent).reduce((output, [fieldName, value]) => {
    if (!EDITABLE_ATTRS.has(fieldName) || toOmit.has(fieldName)) return output;
    const name = adjustFieldName(fieldName);
    const isArrayText = fieldName === 'texts';
    const isText = TEXT_FIELDS.has(fieldName);
    const multiline =
      isArrayText || (isText && textComponent.textType !== 'title');
    const field: FieldObject = {
      variable: `${fieldPrefix}.${fieldName}`,
      name,
      value,
      placeholder: _fieldPlaceholder || null,
      description: _fieldDescription || null,
      type: findType(name, fieldComponent.component),
      hint: _fieldHint ?? null,
      multiline,
      markdownHint: multiline && textComponent.textType !== 'legal',
      splitLines: isArrayText,
      editable: !isText || !_fieldReadOnly,
      aspectRatio: aspectRatio || null,
      options: null,
      maxLimit: null,
      minLimit: null,
      carousel: null,
      darkModeVariable: null,
      showOpacity: false,
    };
    const group = output[_fieldGroupLabel!] || {};
    const fieldsWrapper = group[_fieldLabel!] || {
      fields: [],
      textPath: `${fieldPrefix}.text`,
      switcherPath: `${fieldPrefix}.switcher`,
      hint: _fieldHint,
      collapsible: false,
      defaultTextValue: CONTROL_VALUES[_fieldLabel!],
      display: displayValue,
    };
    return {
      ...output,
      [_fieldGroupLabel!]: {
        ...group,
        [_fieldLabel!]: {
          ...fieldsWrapper,
          fields: fieldsWrapper.fields.concat(field),
        },
      },
    };
  }, output);
}

function isEditable(value: TComponent): value is TComponent {
  return !!value._fieldGroupLabel || !!value._fields;
}

function isNested(value: any): boolean {
  return !(NON_NESTED_TYPES.has(typeof value) || value === null);
}

export function buildInitialValues(
  fieldGroups: IFieldGroups
): InitialValuesType {
  return Object.values(fieldGroups).reduce((output, fieldGroup) => {
    return flatten(Object.values(fieldGroup)).reduce(
      (output, { display, switcherPath, fields }) => {
        const actualFields: { variable: string; value: any }[] = [
          ...fields,
          { variable: switcherPath, value: display },
        ];
        return actualFields.reduce((output, { variable, value }) => {
          return {
            ...output,
            [variable]: Array.isArray(value) ? value.join('\n') : value,
          };
        }, output);
      },
      output
    );
  }, {} as InitialValuesType);
}

function findType(name: string, componentName: string): TField {
  if (componentName === 'image') return 'image'; // TODO: take other image fields into consideration
  const suffix = name.slice(name.lastIndexOf(' ') + 1);
  return FIELD_TYPES[suffix] || 'text';
}

function adjustFieldName(name: string): string {
  return capitalizePhrase(toReadable(name))
    .replace('Url', 'Image')
    .replace('Texts', 'Text')
    .replace('Border Radius', 'Corner Radius')
    .replace('Fill Color', 'Color')
    .replace('Font Color', 'Text Color')
    .replace('Alignment', 'Text Alignment');
}
