import React from 'react';

import { Button, Modal, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

type ConfirmModalProps = {
  className?: string;
  name?: string;
  title: string;
  body: string;
  explanation?: string;
  buttonText: string;
  buttonClass?: string;
  open: boolean;
  loading?: boolean;
  onClose: () => void;
  action?: () => void;
};

const CardHeader = styled(Modal.Header)`
  display: flex !important;
  justify-content: center !important;
`;

export default function ConfirmModal({
  title,
  body,
  explanation = '',
  action = () => {},
  buttonText,
  buttonClass = 'destructive-button',
  name,
  loading = false,
  ...props
}: ConfirmModalProps) {
  return (
    <Modal dimmer="blurring" size="tiny" closeOnDimmerClick={true} {...props}>
      <CardHeader>
        <h3>{title}</h3>
      </CardHeader>
      <Modal.Content>
        <Segment basic loading={loading}>
          <Modal.Description>
            {body} {name && <strong>{name}</strong>}
            {name && '?'}
            {explanation && <p style={{ marginTop: 20 }}>{explanation}</p>}
          </Modal.Description>
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button data-test="close" onClick={props.onClose}>
          Cancel
        </Button>
        <Button data-test="submit" className={buttonClass} onClick={action}>
          {buttonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
