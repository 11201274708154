import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './List.module.less';

const ListItem = (props) => {
  const { children, className, onClick, tabIndex, recent, realTime } = props;

  return (
    <li
      className={cx(
        styles.listItem,
        realTime && styles.notRecent,
        realTime && recent && styles.recent,
        className
      )}
      tabIndex={tabIndex}
      onClick={onClick}
    >
      {children}
    </li>
  );
};

ListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ListItem;
