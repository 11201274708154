import React from 'react';

import { CheckOutlined } from '@ant-design/icons';
import { Button, Divider, List, Space, Typography, notification } from 'antd';
import api from 'src/api';
import { PlanMeta, RawPlanName } from 'src/api/types/main.types';
import { useAppContext } from 'src/hooks';
import { DEV_MODE } from 'src/utils/dev';
import {
  namiAqua,
  namiBlack,
  namiCoral,
  namiLightGray,
  namiLightOrange,
  namiNavy,
  namiPrimaryBlue,
  namiPureWhite,
  namiYellow,
} from 'src/variables';
import styled, { css } from 'styled-components';

type PlanCardProps = {
  product: RawPlanName;
  badge?: boolean;
};

//Limit to 4 bullets per product
const MarketingValueProps: Record<RawPlanName, string[]> = {
  unlimited: [],
  appsumo_tier1: [],
  appsumo_tier2: [],
  appsumo_tier3: [],
  appsumo_tier4: [],
  appsumo_tier5: [],
  enterprise_paywall_mgmt_v1: [],
  enterprise_v1: [],
  pro_v1: [],
  free_v1: [],
  free_v2: [],
  starter_plus_v1: [],
  paywalls_only_starter_v1: [],
  paywalls_and_subs_starter_v1: [],
  paywalls_only_team_v1: [
    'Next-gen Paywall Creator',
    'A/B and A/ZZ Testing',
    '15k impressions*',
  ],
  paywalls_only_business_v1: [
    'Paywall personalization',
    'Audience segmentation',
    '25k impressions*',
  ],
  paywalls_only_enterprise_v1: [
    'Mobile, web, TV platforms',
    'Carrier grade specs',
    'Volume pricing & SLA',
  ],
  paywalls_and_subs_team_v1: [
    'Includes Subscription Studio',
    'Built-in App Store & Google Play purchase engine',
    'Gate premium content',
    '15k impressions / $15k MTR*',
  ],
  paywalls_and_subs_business_v1: [
    'Premium webhooks',
    'Amazon Appstore integration',
    '25k impressions / $25k MTR*',
  ],
  paywalls_and_subs_enterprise_v1: [
    'Mobile, web, TV platforms',
    'Carrier grade specs',
    'Volume pricing & SLA',
  ],
};

const CardWrapper = styled.div<{ backgroundColor: string }>`
  padding: 24px 30px;
  gap: 12px;
  width: 280px;
  height: fit-content;
  text-align: left;
  border-radius: 12px;
  background-color: ${({ backgroundColor }) => backgroundColor};

  @media only screen and (max-width: 575px) {
    width: 100%;
  }
`;

const PopularBadge = styled.div`
  position: absolute;
  border-radius: 50px;
  padding: 5px 15px;
  font-weight: 700;
  font-size: 12px;
  background-color: ${namiCoral};
  color: ${namiPureWhite};
  transform: translate(160%, -40%);
`;

const CardProductTitle = styled(Typography.Text)<{ theme: 'dark' | 'light' }>`
  font-size: 24px;
  font-weight: 600;
  ${({ theme }) => {
    if (theme === 'light') {
      return css`
        color: ${namiNavy};
      `;
    }
    return css`
      color: ${namiPureWhite};
    `;
  }}
`;

const CardProductSubtitle = styled(Typography.Text)<{
  theme: 'dark' | 'light';
}>`
  font-size: 14px;
  ${({ theme }) => {
    if (theme === 'light') {
      return css`
        color: ${namiNavy};
      `;
    }
    return css`
      color: ${namiPureWhite};
    `;
  }}
`;

const CardPrice = styled(Typography.Text)<{ theme: 'dark' | 'light' }>`
  font-size: 18px;
  font-weight: 600;
  ${({ theme }) => {
    if (theme === 'light') {
      return css`
        color: ${namiPrimaryBlue};
      `;
    }
    return css`
      color: ${namiPureWhite};
    `;
  }}
`;

const CardDivider = styled(Divider)<{ theme: 'dark' | 'light' }>`
  margin: 8px 0px;
  ${({ theme }) => {
    if (theme === 'light') {
      return css`
        background-color: ${namiLightGray};
      `;
    }
    return css`
      background-color: ${namiPureWhite};
    `;
  }}
`;

const CardListHeader = styled(Typography.Text)`
  font-size: 14px;
  font-weight: 700;
  color: ${namiPureWhite};
`;

const ListProp = styled(List.Item)<{ theme: 'dark' | 'light' }>`
  padding: 4px 4px !important;
  ${({ theme }) => {
    if (theme === 'light') {
      return css`
        color: ${namiNavy};
      `;
    }
    return css`
      color: ${namiPureWhite};
    `;
  }}
`;
const ListPropCheck = styled(CheckOutlined)<{ color: string }>`
  padding-right: 6px;
  color: ${({ color }) => color};
`;

const PurchaseButton = styled(Button)<{ theme: 'dark' | 'light' }>`
  border-radius: 50px;
  ${({ theme }) => {
    if (theme === 'light') {
      return css`
        background-color: ${namiYellow};
        color: ${namiBlack};
        :hover {
          background-color: ${namiLightOrange};
          color: ${namiBlack};
        }
      `;
    }
    return css`
      background-color: ${namiPrimaryBlue};
      color: ${namiPureWhite};
    `;
  }}
  border: unset;
  font-weight: 700;
  font-size: 15px;
  padding: 10px 32px !important;
  height: auto;
`;

export default function PlanCard({ product, badge = false }: PlanCardProps) {
  const context = useAppContext();
  const meta = PlanMeta[product];
  const theme = meta.product === 'team' ? 'light' : 'dark';
  const cardBackgroundColor =
    meta.product === 'enterprise'
      ? namiBlack
      : meta.product === 'business'
      ? namiNavy
      : namiPureWhite;
  const listIconColor =
    meta.product === 'enterprise'
      ? namiYellow
      : meta.product === 'business'
      ? namiAqua
      : namiPrimaryBlue;
  const listHeaderText =
    meta.product === 'enterprise'
      ? 'Complete Nami ML Solution'
      : meta.product === 'business'
      ? 'Everything in Team plan PLUS:'
      : '';

  return (
    <>
      {badge && <PopularBadge>Most Popular</PopularBadge>}
      <CardWrapper
        backgroundColor={cardBackgroundColor}
        className={`intercom-pick-plan${product}`}
      >
        <Space direction="vertical" size={12} style={{ width: '100%' }}>
          <Space direction="vertical" size={4} style={{ width: '100%' }}>
            <CardProductTitle theme={theme}>
              {meta.shortPlanName}
            </CardProductTitle>
            <CardProductSubtitle theme={theme}>
              {meta.marketingTagLine}
            </CardProductSubtitle>
            <CardPrice
              theme={theme}
              className={`intercom-pick-plan-price${product}`}
            >
              {meta.product === 'enterprise' ? (
                'Contact for pricing'
              ) : (
                <span>${meta.basePricePerMonth}/month</span>
              )}
            </CardPrice>
          </Space>
          <CardDivider theme={theme} />
          <Space direction="vertical" size={4} style={{ height: 160 }}>
            {listHeaderText && (
              <CardListHeader>{listHeaderText}</CardListHeader>
            )}
            <List
              size="small"
              split={false}
              dataSource={MarketingValueProps[product]}
              renderItem={(item, index) => (
                <ListProp key={index} theme={theme}>
                  <ListPropCheck color={listIconColor} />
                  {item}
                </ListProp>
              )}
            />
          </Space>
          <PurchaseButton
            type="primary"
            block
            theme={meta.product === 'enterprise' ? 'light' : 'dark'}
            onClick={(_e) => getButtonOnClick()}
            className={`intercom-pick-plan-buy${product}`}
          >
            {getButtonLink() ? (
              <a href={getButtonLink()}>Start your free trial</a>
            ) : (
              <span>Have Nami contact me</span>
            )}
          </PurchaseButton>
        </Space>
      </CardWrapper>
    </>
  );

  function getButtonLink() {
    if (meta.product === 'enterprise') return undefined;
    return buildStripeLink(getPurchaseLinkForProduct()); //TODO - build Stripe link
  }

  function getButtonOnClick() {
    if (meta.product !== 'enterprise') return;
    api.requestUpgrade(product).then(() => {
      notification.success({
        message: 'Thanks',
        description: 'Our team will be in touch to schedule a demo!',
      });
    });
  }

  function buildStripeLink(purchaseUrl?: string): string | undefined {
    if (!purchaseUrl) return undefined;

    const orgId = context.selectedOrg?.id;
    const username = context.user?.username;

    const params = new URLSearchParams({
      client_reference_id: orgId || '',
      prefilled_email: username || '',
    }).toString();
    return purchaseUrl + '?' + params;
  }

  function getPurchaseLinkForProduct() {
    if (DEV_MODE) return meta.purchaseLinkStaging;
    return meta.purchaseLinkProd;
  }
}
