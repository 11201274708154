import React from 'react';

import { connect } from 'react-redux';
import { Dropdown, Form, List, Segment } from 'semantic-ui-react';

import { useAppContext } from '../../../../hooks';
import { handlePaywallField } from '../../../../redux/actions/paywallActions';
import NamiIcon from '../../../NamiIcon/NamiIcon';

const Overview = ({ paywall, handlePaywallField, languages, currentApp }) => {
  const context = useAppContext();

  const rename = (name) => {
    handlePaywallField({ ...paywall, name: name });
  };

  const updatePaywallID = (Paywall) => {
    handlePaywallField({
      ...paywall,
      developer_paywall_id: Paywall,
    });
  };

  const languagesOptions = languages.map((language) => {
    return {
      value: language.code,
      text:
        currentApp.default_language === language.code
          ? `${language.language} - (${language.code.toUpperCase()}) - Default`
          : `${language.language} - (${language.code.toUpperCase()})`,
    };
  });
  const renderForm = () => {
    return (
      <>
        <Form.Input
          label="Name"
          disabled={!context.userHasEntitlement('app.paywall.update')}
          onChange={(e) => rename(e.target.value)}
          value={paywall.name}
        />
        <Form.Field>
          <Form.Input
            label="Developer Paywall ID"
            disabled={!context.userHasEntitlement('app.paywall.update')}
            onChange={(e) => updatePaywallID(e.target.value)}
            value={paywall.developer_paywall_id || ''}
          />
          <small>
            The Developer Paywall ID is the unique identifier used in your app
            to reference this paywall. This is created by your developers in
            your app.
          </small>
        </Form.Field>
        <Form.Field>
          <label>Type</label>
          <List
            horizontal
            verticalAlign="middle"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <List.Item>
              {paywall.type === 'paywall_creator' ? (
                <NamiIcon color="primary" icon="paywall nami" />
              ) : (
                <NamiIcon color="primary" icon="paywall link" />
              )}
            </List.Item>
            <List.Item style={{ marginLeft: 0, padding: '10px' }}>
              {paywall.type === 'paywall_creator'
                ? 'Created with Nami'
                : 'Linked Paywall'}
            </List.Item>
          </List>
        </Form.Field>
        <Form.Field style={{ marginBottom: 25 }}>
          <Form.Field>
            <Dropdown
              placeholder="Select Language"
              fluid
              search
              selection
              style={{ border: '1px solid black !important' }}
              onChange={(e, data) => {
                handlePaywallField({ ...paywall, language: data.value });
              }}
              value={paywall.language}
              options={languagesOptions}
            />
          </Form.Field>
        </Form.Field>
      </>
    );
  };

  return <Segment basic>{renderForm()}</Segment>;
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlePaywallField: (data) => dispatch(handlePaywallField(data)),
  };
};

const mapStateToProps = ({ paywall, root }) => {
  return {
    paywall: paywall.paywall,
    languages: root.languages,
    currentApp: root.currentApp,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
