import React, { useState } from 'react';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { DataObjectOutlined } from '@mui/icons-material';
import { Button, Collapse, Form, Input, Modal, Space, Tooltip } from 'antd';
import { createSelector } from 'reselect';
import { useBooleanState } from 'src/hooks';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

const selector = createSelector(
  [
    ({ paywallBuilder: { launch } }: RootState) => launch.customAttributes,
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.launchCustomAttributes'],
  ],
  (customAttributes, customAttributeOptions) => ({
    customAttributesSet:
      customAttributes && Object.keys(customAttributes).length > 0,
    customAttributeOptions: customAttributeOptions || [],
  })
);

export default function PaywallCustomAttributeDictionary() {
  const actions = useActions(PaywallBuilderSlice.actions);
  const [form] = Form.useForm<object>();
  const [addForm] = Form.useForm<{ name: string; placeholder: string }>();

  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const { customAttributesSet, customAttributeOptions } =
    useAppSelector(selector);
  const [isCollapseOpen, setCollapseOpen] = useState(false);

  return (
    <>
      <Tooltip
        title="Preview paywall with custom variables"
        mouseEnterDelay={0.8}
      >
        <Button
          type={customAttributesSet ? 'primary' : 'ghost'}
          icon={
            <DataObjectOutlined style={{ transform: 'translateY(1.5px)' }} />
          }
          onClick={openModal}
          size="small"
        />
      </Tooltip>
      <Modal
        title="Preview Custom Variables"
        open={isModalOpen}
        centered
        zIndex={1005}
        footer={null}
        onCancel={closeModal}
      >
        <Space direction="vertical" style={{ width: '100%' }} size="large">
          {Object.keys(customAttributeOptions).length > 0 && (
            <Form form={form} labelWrap layout="vertical">
              {Object.entries(customAttributeOptions).map(
                ([variable, value]) => {
                  return (
                    <Form.Item name={variable} label={variable} key={variable}>
                      <Input placeholder={value} />
                    </Form.Item>
                  );
                }
              )}
              <Space direction="horizontal">
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    form
                      .validateFields()
                      .then(() => updateLaunchCustomAttributes());
                  }}
                >
                  Apply
                </Button>
                <Button onClick={resetLaunchCustomAttributes}>Clear</Button>
              </Space>
            </Form>
          )}
          <Collapse
            bordered={false}
            onChange={(key) => {
              if (Array.isArray(key) && key.length > 0) {
                setCollapseOpen(key[0] === '1');
              } else {
                setCollapseOpen(key === '1');
              }
            }}
            activeKey={isCollapseOpen ? ['1'] : []}
            style={{ marginBottom: 16 }}
          >
            <Collapse.Panel
              key="1"
              header={
                <Button
                  icon={isCollapseOpen ? <MinusOutlined /> : <PlusOutlined />}
                >
                  Add custom variable
                </Button>
              }
              showArrow={false}
            >
              <Form
                form={addForm}
                labelCol={{ span: 6 }}
                labelWrap
                requiredMark={false}
              >
                <Form.Item
                  name={'add'}
                  label={'Variable name'}
                  required
                  rules={[
                    () => {
                      return {
                        message:
                          'Please enter an alphanumeric value with no spaces or special characters',
                        validator: (_, value) => {
                          const isValid = /^[a-zA-Z0-9-_]+$/g.test(value);
                          return isValid ? Promise.resolve() : Promise.reject();
                        },
                      };
                    },
                  ]}
                >
                  <Input placeholder="newVariableIdentifier" />
                </Form.Item>
                <Form.Item
                  name={'addPlaceholder'}
                  label={'Sample value'}
                  required
                  rules={[
                    {
                      required: true,
                      message:
                        'Please provide an example value for this variable',
                    },
                  ]}
                >
                  <Input placeholder="Sample value" />
                </Form.Item>
                <Button
                  size="small"
                  onClick={() => {
                    addForm
                      .validateFields()
                      .then(() => addLaunchCustomAttributeOption());
                  }}
                >
                  Add
                </Button>
              </Form>
            </Collapse.Panel>
          </Collapse>
        </Space>
      </Modal>
    </>
  );

  function addLaunchCustomAttributeOption() {
    actions.updateLaunchCustomAttributes({
      componentAttributes: {
        [addForm.getFieldValue('add')]: addForm.getFieldValue('addPlaceholder'),
      },
    });
    addForm.resetFields();
    setCollapseOpen(false);
  }

  function updateLaunchCustomAttributes() {
    const newDictionary = Object.entries(form.getFieldsValue()).reduce(
      (output, [key, value]) => {
        if (key === 'add' || key === 'addPlaceholder') return output;
        return {
          ...output,
          [key]: value,
        };
      },
      {}
    );
    actions.setLaunchCustomContext(newDictionary);
    closeModal();
  }

  function resetLaunchCustomAttributes() {
    form.resetFields();
    actions.resetLaunchCustomContext();
    closeModal();
  }
}
