import React, { useMemo } from 'react';

import countries from '../../../../../countries';
import FilterSelect, { BaseFilterSelectProps } from './FilterSelect';
import { identifierLabel } from './mapsAndOptions';

export default function DeviceMarketplaceSelect(props: BaseFilterSelectProps) {
  const options = useMemo(() => {
    return Object.entries(countries).map(([code, name]) => ({
      label: name,
      text: name,
      value: code,
      key: code,
    }));
  }, []);

  return (
    <FilterSelect
      {...props}
      label={identifierLabel['Device.marketplace_country']}
      options={options}
      operators={['i_contains', 'not_i_contains']}
      variant="select"
    />
  );
}
