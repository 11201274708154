import React from 'react';

import { connect } from 'react-redux';
import { Form, Segment, Checkbox, Dropdown } from 'semantic-ui-react';

import ColorPickerLegacy from '../../../../components/ColorPickerLegacy/ColorPickerLegacy';
import {
  handlePaywallColor,
  handlePaywallSize,
} from '../../../../redux/actions/paywallActions';

const Products = ({
  paywall,
  paywallStyles,
  handlePaywallColor,
  handlePaywallSize,
}) => {
  const {
    sku_button_color,
    sku_button_text_color,
    featured_skus_button_color,
    featured_skus_button_text_color,
    sku_sub_display_text_color,
    sku_sub_display_text_shadow_color,
    sku_sub_display_text_shadow_radius,
  } = paywallStyles;

  const radiusOptions = [
    { value: 1, text: '1px' },
    { value: 2, text: '2px' },
    { value: 3, text: '3px' },
    { value: 4, text: '4px' },
    { value: 5, text: '5px' },
    { value: 6, text: '6px' },
    { value: 7, text: '7px' },
    { value: 8, text: '8px' },
  ];

  return (
    <Segment basic>
      <Form.Field>
        <label>Button Color</label>
        <ColorPickerLegacy
          paywall={paywall}
          color={sku_button_color}
          onChange={(color) => handlePaywallColor('sku_button_color', color)}
        />
      </Form.Field>
      <Form.Field>
        <label>Button Text Color</label>
        <ColorPickerLegacy
          paywall={paywall}
          color={sku_button_text_color}
          onChange={(color) =>
            handlePaywallColor('sku_button_text_color', color)
          }
        />
      </Form.Field>
      <Form.Field>
        <label>Featured Button Color</label>
        <ColorPickerLegacy
          paywall={paywall}
          color={featured_skus_button_color}
          onChange={(color) =>
            handlePaywallColor('featured_skus_button_color', color)
          }
        />
      </Form.Field>
      <Form.Field>
        <label>Featured Button Text Color</label>
        <ColorPickerLegacy
          paywall={paywall}
          color={featured_skus_button_text_color}
          onChange={(color) =>
            handlePaywallColor('featured_skus_button_text_color', color)
          }
        />
      </Form.Field>
      <Form.Field>
        <label>Sub Display Text</label>
      </Form.Field>
      <Form.Group inline>
        <Form.Field>
          <ColorPickerLegacy
            paywall={paywall}
            color={sku_sub_display_text_color}
            onChange={(color) =>
              handlePaywallColor('sku_sub_display_text_color', color)
            }
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <Checkbox
          label="Shadow"
          slider
          checked={sku_sub_display_text_shadow_radius === 0 ? false : true}
          onChange={(e) =>
            handlePaywallSize(
              'sku_sub_display_text_shadow_radius',
              sku_sub_display_text_shadow_radius === 0 ? 1 : 0
            )
          }
        />
      </Form.Field>
      {!!sku_sub_display_text_shadow_radius && (
        <Form.Group inline style={{ paddingBottom: 20 }}>
          <Form.Field>
            <Dropdown
              compact
              selection
              options={radiusOptions}
              value={sku_sub_display_text_shadow_radius}
              onChange={(e, { value }) =>
                handlePaywallSize('sku_sub_display_text_shadow_radius', value)
              }
            />
            <div
              style={{
                position: 'absolute',
                marginTop: 5,
                fontSize: 12,
              }}
            >
              Radius
            </div>
          </Form.Field>
          <Form.Field>
            <ColorPickerLegacy
              paywall={paywall}
              color={sku_sub_display_text_shadow_color}
              onChange={(color) =>
                handlePaywallColor('sku_sub_display_text_shadow_color', color)
              }
            />
          </Form.Field>
        </Form.Group>
      )}
    </Segment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    handlePaywallColor: (key, color) =>
      dispatch(handlePaywallColor(key, color)),
    handlePaywallSize: (key, value) => dispatch(handlePaywallSize(key, value)),
  };
};

const mapStateToProps = ({ paywall }) => {
  return {
    paywall: paywall.paywall,
    paywallStyles: paywall.paywallStyles,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
