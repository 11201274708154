import { useEffect, useRef, useState } from 'react';

import { useBooleanState } from './boolean.hooks';

/**
 * @typedef PromiseState
 * @property {boolean} isLoading
 * @property {() => void} trigger
 * @property {any} output
 * @property {any} error
 */

/**
 * @param fetchPromise {function(): Promise<any>}
 * @returns {PromiseState}
 */
export default function usePromiseState(fetchPromise) {
  const [isLoading, startLoading, stopLoading] = useBooleanState(false);
  const [output, setOutput] = useState(undefined);
  const [error, setError] = useState(undefined);
  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return { isLoading, output, error, trigger };

  function trigger() {
    startLoading();
    setError(undefined);
    return fetchPromise()
      .then((response) => mounted.current && setOutput(response))
      .catch((error) => mounted.current && setError(error))
      .finally(() => mounted.current && stopLoading());
  }
}
