import React from 'react';

import { PlanMeta, TDisplayProduct } from 'src/api/types/main.types';

import UpgradeWebPaywall, { WebPaywallProps } from './UpgradeWebPaywall';

const BuilderTreeWebPaywall = ({ onCancel, visible }: WebPaywallProps) => {
  const paywallsOnlyProducts: TDisplayProduct[] = [
    {
      product_value_props: [
        'Add, Reorder, and Remove Components',
        'Add Text, List, Images & More',
        'Google Fonts',
        'Featured and Selected Styling',
      ],
      ...PlanMeta.paywalls_only_starter_v1,
    },
    {
      product_value_props: [
        'Add Carousel Component',
        'Trial Eligibility',
        'A/B Testing',
        'Deeplink Button',
      ],
      ...PlanMeta.paywalls_only_team_v1,
    },
    {
      product_value_props: [
        'Show/Hide Components',
        'Upload Your Brand Fonts',
        'Offer Eligibility & Purchasing',
        'Login Check',
        'Product Groups',
      ],
      ...PlanMeta.paywalls_only_business_v1,
    },
  ];

  const paywallsAndSubsProducts: TDisplayProduct[] = [
    {
      product_value_props: [
        'Add, Reorder, and Remove Components',
        'Add Text, List, Images & More',
        'Google Fonts',
        'Featured and Selected Styling',
      ],
      ...PlanMeta.paywalls_and_subs_starter_v1,
    },
    {
      product_value_props: [
        'Add Carousel Component',
        'Trial Eligibility',
        'A/B Testing',
        'Deeplink Button',
      ],
      ...PlanMeta.paywalls_and_subs_team_v1,
    },
    {
      product_value_props: [
        'Show/Hide Components',
        'Upload Your Brand Fonts',
        'Offer Eligibility',
        'Login Check',
        'Product Groups',
      ],
      ...PlanMeta.paywalls_and_subs_business_v1,
    },
  ];

  return (
    <UpgradeWebPaywall
      modalTitle="Unlock Template Builder"
      productsPaywallsOnly={paywallsOnlyProducts}
      productsPaywallsAndSubs={paywallsAndSubsProducts}
      highlightedProductPaywallsOnly="paywalls_only_starter_v1"
      highlightedProductPaywallsAndSubs="paywalls_and_subs_starter_v1"
      entitlement="app.paywall.component.create"
      onCancel={onCancel}
      visible={visible}
    />
  );
};

export default BuilderTreeWebPaywall;
