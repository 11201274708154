import React, { useMemo } from 'react';

import styled from 'styled-components';

import { FilterSelectProps, TOption, operatorLabels } from '.';
import FilterSelect from './FilterSelect';

const ClearWrapper = styled.span`
  position: absolute !important;
  right: 0px;
`;

export const empty = 'not selected';

type MultiSelectProps = FilterSelectProps & {
  label: string;
  options: TOption[];
};

export default function MultiSelect({
  onValueChange,
  onRemove,
  values,
  operator,
  options,
  identifier,
  ...props
}: MultiSelectProps) {
  const optionValues = useMemo(
    () => options.filter(({ value }) => values.includes(value)),
    [values, options]
  );

  return (
    <FilterSelect
      allowClear
      mode="multiple"
      showSearch
      showArrow={false}
      optionFilterProp="text"
      status={optionValues.length === 0 ? 'error' : undefined}
      options={options}
      value={optionValues.length > 0 ? optionValues : [empty]}
      operator={operatorLabels[operator]}
      onChange={handleChange}
      clearIcon={<ClearButton onClick={onRemove} />}
      {...props}
    />
  );

  function handleChange(values: unknown): void {
    onValueChange((values as string[]).filter((value) => value !== empty));
  }
}

function ClearButton({ onClick }: { onClick: () => void }) {
  return (
    <ClearWrapper
      className="ant-select-clear"
      unselectable="on"
      style={{ userSelect: 'none' }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <span role="img" className="anticon anticon-close-circle">
        <svg
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="close-circle"
          width="1em"
          height="1em"
          fill="currentColor"
        >
          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
        </svg>
      </span>
    </ClearWrapper>
  );
}
