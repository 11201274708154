import React from 'react';

import { TSemverObj } from 'src/utils/parsing';
import styled, { css } from 'styled-components';

import { TSegmentPicker } from '../../../api/types/paywallTemplate.types';
import TemplateComponent from '../TemplateComponent';
import {
  applyStyles,
  flexDirection,
  pickAndApplyBackgroundColor,
} from '../css';
import HoverTag from './HoverTag';

type ComponentProps<T> = {
  component: T;
  groupId: string | null;
  minSDKVersion: TSemverObj;
};

const SegmentPickerWrapper = styled.div<{
  component: TSegmentPicker;
}>`
  display: flex;
  ${({ component }) => {
    const isVertical = component.direction === 'vertical';
    const sizeKey = isVertical ? 'width' : 'height';
    const spaceKey = isVertical ? 'margin-top' : 'margin-left';
    const background = pickAndApplyBackgroundColor(component, false);
    return css`
      ${background};
      ${flexDirection(component)}
      ${applyStyles(component, false)}

      > * {
        ${sizeKey}: 100%;

        &:not(:first-child) {
          ${spaceKey}: ${component.spacing || 0}px;
        }
      }
    `;
  }}
`;

export function SegmentPicker({
  component,
  groupId,
  minSDKVersion,
}: ComponentProps<TSegmentPicker>) {
  return (
    <HoverTag
      title={component.namiComponentType ? component.title || component.id : ''}
      namiComponentType={component.namiComponentType}
      id={component.id}
    >
      <SegmentPickerWrapper component={component}>
        {(component.components || []).map((child, i) => (
          <TemplateComponent
            key={i}
            component={child}
            groupId={groupId}
            minSDKVersion={minSDKVersion}
          />
        ))}
      </SegmentPickerWrapper>
    </HoverTag>
  );
}
