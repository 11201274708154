import React, { useEffect, useMemo, useState } from 'react';

import { Tree, Typography } from 'antd';
import { Form } from 'semantic-ui-react';

import api from '../../../../api';
import { SchemaPartnerType } from '../../../../api/types/main.types';
import ConfirmModal from '../../../../components/ConfirmModal/ConfirmModal';
import LegacySchemaField from '../../../../components/LegacySchemaField';
import {
  useAppContext,
  useBooleanState,
  useFormState,
} from '../../../../hooks';
import {
  buildSchemaFields,
  formatDynamicError,
} from '../../../../utils/schema';
import { buildTree, TTreeItem } from './utils';

type SchemaFormProps = {
  type: string;
  error: { config: string[] };
  objectId?: string | null;
  initialConfig: Record<string, any>;
  initialTopics: string[];
  onFormChange(form: Record<string, any>): void;
  onEventsChange(events: string[]): void;
};

export default function SchemaForm({
  type,
  error,
  objectId,
  initialConfig,
  initialTopics,
  onFormChange,
  onEventsChange,
}: SchemaFormProps) {
  // TODO: Cannot update a component `StreamForm` while rendering `SchemaForm`.
  //  Locate the bad state update.
  const context = useAppContext();
  const [schema, setSchema] = useState<SchemaPartnerType | null>(null);
  const [fields, setFields] = useState<ReturnType<typeof buildSchemaFields>>(
    []
  );
  const [events, setEvents] = useState<TTreeItem[]>([]);
  const [requiredFields, setRequiredFields] = useState<Set<string>>(new Set());
  const [selectedEvents, setSelectedEvents] = useState<string[]>([]);
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const { form, setForm } = useFormState({});
  const configErrors = formatDynamicError(error?.config);
  const isEditing = !!objectId;
  const canEdit = context.userHasEntitlement('app.event_stream.update');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const actionMap = useMemo(() => ({ rotate_signing_secret: openModal }), []);

  useEffect(() => {
    api.getIntegrationSchema(type).then((serverSchema) => {
      setRequiredFields(new Set(serverSchema.config.required));
      setFields(buildSchemaFields(serverSchema.config));
      setEvents(
        serverSchema.catch_all_events ? [] : buildTree(serverSchema.events)
      );
      setSchema(serverSchema);
    });
  }, [type]);

  useEffect(() => {
    setForm({ ...form, ...initialConfig });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialConfig]);

  useEffect(() => {
    setSelectedEvents(initialTopics);
  }, [initialTopics]);

  return (
    <>
      {!!fields.length &&
        fields
          .filter((field) => isEditing || (field.title && !field.readOnly))
          .map((field) => (
            <LegacySchemaField
              key={field.key}
              field={field}
              value={form[field.key] || ''}
              error={configErrors[field.key]}
              required={requiredFields.has(field.key)}
              onChange={onFieldChange}
              disabled={!canEdit}
              actionMap={actionMap}
            />
          ))}
      {!schema?.catch_all_events && (
        <Form.Field>
          <Typography.Paragraph strong>
            Event Subscriptions
          </Typography.Paragraph>
          <Typography.Paragraph>
            Select types of events to send to this integration
          </Typography.Paragraph>
          <Tree
            checkable
            onCheck={(param) => {
              const events = param as string[];
              const filteredEvents = events.filter((e) => !!e.includes('.'));
              onEventsChange(filteredEvents);
              setSelectedEvents(filteredEvents);
            }}
            selectable={false}
            checkedKeys={selectedEvents}
            treeData={events}
            disabled={!canEdit}
          />
        </Form.Field>
      )}
      <ConfirmModal
        open={isModalOpen}
        onClose={closeModal}
        action={() => {
          rotate();
          closeModal();
        }}
        title="Confirm Signing Secret Rotation"
        body="Rotating your signing secret may also require a change to your webhook destination. Otherwise, it may fail to process events from Nami. Are you sure you want to make this change?"
        buttonText="Yes, Rotate Signing Secret"
        buttonClass="nami-primary-button"
      />
    </>
  );

  function rotate() {
    if (!objectId) return;
    api.rotateSigningSecret(objectId).then((signing_secret) => {
      setForm((formState: {}) => ({ ...formState, signing_secret }));
    });
  }

  function onFieldChange(e: React.ChangeEvent<HTMLInputElement>) {
    setForm((formState: {}) => {
      const newForm = { ...formState, [e.target.name]: e.target.value };
      onFormChange(newForm);
      return newForm;
    });
  }
}
