import React from 'react';

import { NavLink } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';

import AvatarMenu from '../../AvatarMenu/AvatarMenu';
import NamiIcon from '../../NamiIcon/NamiIcon';
import styles from './DesktopHeader.module.less';

const DesktopHeader = () => {
  return (
    <div className={styles.header}>
      <div className="main-container">
        <Grid verticalAlign="middle" columns={3}>
          <Grid.Row>
            <Grid.Column /> {/* Offset */}
            <Grid.Column textAlign="center" className={styles.logo}>
              <NavLink to="/overview/">
                <NamiIcon icon="logo" color="primary" size="large" />
              </NavLink>
            </Grid.Column>
            <Grid.Column>
              <AvatarMenu />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </div>
  );
};

export default DesktopHeader;
