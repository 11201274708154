import { client } from './clients';
import {
  SchemaPartnersType,
  TCDPIntegration,
  TCDPIntegrationIdentityScope,
  TCDPIntegrationLinks,
  TIntegrationMetadata,
  TNewAppCDPIntegrationPayload,
} from './types/main.types';
import {
  extractErrorData,
  extractListResponseData,
  extractResponseData,
} from './utils';

const integrations = {
  getIntegrationsMetaData: (): Promise<TIntegrationMetadata[]> => {
    return client
      .get('/private/integration_metadata/')
      .then(extractListResponseData)
      .catch(extractErrorData);
  },

  getIntegrationSchemas,
  getIntegrationSchema: (type: string) =>
    getIntegrationSchemas().then((partners) => partners[type]),
  getAppCDPIntegration: (integrationId: string): Promise<TCDPIntegration> => {
    return client
      .get(`/private/cdp/app-cdp-integrations/${integrationId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getAppCDPIntegrations: (appId: string): Promise<TCDPIntegration[]> => {
    return client
      .get(`/private/cdp/app-cdp-integrations/?app_id=${appId}`)
      .then(extractListResponseData)
      .catch(extractErrorData);
  },
  createAppCDPIntegration: (
    data: TNewAppCDPIntegrationPayload
  ): Promise<TCDPIntegration> => {
    return client
      .post(`/private/cdp/app-cdp-integrations/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  updateAppCDPIntegration: (
    integrationId: string,
    data: Partial<TCDPIntegration>
  ): Promise<TCDPIntegration> => {
    return client
      .patch(`/private/cdp/app-cdp-integrations/${integrationId}/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getCDPIdentityScope: (): Promise<TCDPIntegrationIdentityScope[]> => {
    return client
      .get(`/private/cdp/cdp-identity-scope/`)
      .then(extractListResponseData)
      .catch(extractErrorData);
  },
  getCDPIntegrationLinks: (
    integrationId: string
  ): Promise<TCDPIntegrationLinks[]> => {
    return client
      .get(`/private/cdp/link-cdp-integrations/?activation_id=${integrationId}`)
      .then(extractListResponseData)
      .catch(extractErrorData);
  },
  createCDPIntegrationLink: (
    data: Partial<TCDPIntegrationLinks>
  ): Promise<TCDPIntegrationLinks[]> => {
    return client
      .post(`/private/cdp/link-cdp-integrations/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  updateCDPIntegrationLink: (
    linkId: string,
    data: Partial<TCDPIntegrationLinks>
  ): Promise<TCDPIntegrationLinks[]> => {
    return client
      .patch(`/private/cdp/link-cdp-integrations/${linkId}/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
};

export default integrations;

function getIntegrationSchemas(): Promise<SchemaPartnersType> {
  return client
    .get('/api/v1/events/schema/')
    .then((response) => response?.data?.partners)
    .catch(extractErrorData);
}
