import React, { useEffect, useState } from 'react';

import { Col, Row } from 'antd';
import Select, { DefaultOptionType } from 'antd/lib/select';
import {
  TComponent,
  TComponentLocation,
} from 'src/api/types/paywallTemplate.types';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { toReadable } from 'src/utils/string';

import {
  TNonProtectedComponentProperty,
  generateComponentAttributeOptions,
} from '../../../utils/componentGeneration';

type OptionalPropertySelectorProps = {
  component: TComponent;
  componentLocation: TComponentLocation | null;
};

export default function OptionalPropertySelector({
  component,
  componentLocation,
}: OptionalPropertySelectorProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const { capabilities, formFactor } = useAppSelector(({ paywallBuilder }) => {
    return {
      capabilities: paywallBuilder.paywall?.template['ui.capabilities'],
      formFactor: paywallBuilder.formFactor,
    };
  });

  const [selectedProperty, setSelectedProperty] =
    useState<TNonProtectedComponentProperty>();

  let availablePropertySelectOptions: DefaultOptionType[] = [];

  const availableProperties = generateComponentAttributeOptions(
    component,
    capabilities || [],
    formFactor
  );

  availablePropertySelectOptions = availableProperties.reduce(
    (output, property) => {
      if (component.hasOwnProperty(property.propertyType)) return output;
      if (!property.idSuffix) return output;
      return [
        ...output,
        {
          value: property.propertyType,
          label: toReadable(property.propertyType),
        },
      ];
    },
    [] as DefaultOptionType[]
  );

  useEffect(() => {
    if (selectedProperty) {
      onAddAttribute(selectedProperty);
      setSelectedProperty(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProperty]);

  return (
    <Col
      xs={24}
      md={24}
      key="optionalPropertiesFooter"
      style={{ marginBottom: 6 }}
    >
      <Row gutter={[0, 10]}>
        {availablePropertySelectOptions.length > 0 && (
          <Row gutter={[0, 10]} style={{ width: '100%', marginTop: 12 }}>
            <Select
              placeholder="Add new property"
              style={{ width: '100%' }}
              options={availablePropertySelectOptions}
              onChange={(value) => {
                setSelectedProperty(value);
              }}
              value={selectedProperty}
            />
          </Row>
        )}
      </Row>
    </Col>
  );

  function onAddAttribute(propertyType: TNonProtectedComponentProperty) {
    if (!component.id || !componentLocation || !component.namiComponentType) {
      console.warn(
        `Unable to add ${propertyType} to component ${component.id}`
      );
      return;
    }

    actions.addNewProperty({
      location: componentLocation,
      id: component.id,
      propertyType: propertyType,
      componentType: component.namiComponentType,
    });
  }
}
