import { apiRequest } from '../../services/apiService';
import * as TYPES from '../types';

export const getApps = () => {
  return apiRequest({
    type: TYPES.GET_APPS,
    url: `/private/apps/`,
    method: 'GET',
  });
};

export const getLanguages = () => {
  return apiRequest({
    type: TYPES.GET_LANGUAGES,
    url: '/api/v1/languages/',
    method: 'GET',
  });
};

export const updateCurrentApp = (appID, data) => {
  return apiRequest({
    type: TYPES.UPDATE_CURRENT_APP,
    url: `/private/apps/${appID}/`,
    method: 'PUT',
    data,
  });
};

export const handleCurrentAppField = (key, value) => {
  return {
    type: TYPES.HANDLE_CURRENT_APP_FIELD,
    payload: {
      key,
      value,
    },
  };
};

export const currentAppUploadFinish = (response) => {
  return {
    type: TYPES.CURRENT_APP_UPLOAD_FINISH,
    payload: {
      response,
    },
  };
};

export const clearRoot = () => {
  return {
    type: TYPES.CLEAR_ROOT,
  };
};
