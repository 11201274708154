import { toast } from 'react-toastify';

import * as TYPES from '../types';

const initialState = {
  loading: true,
  changes: false,
  product: {},
  productError: null,
  products: [],
  languages: [],
  translations: [],
  translation: {
    language: '',
  },
  translationError: null,
};

export const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_PRODUCTS:
    case TYPES.GET_PRODUCTS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload.data.results,
        product: {},
        loading: false,
      };
    case TYPES.GET_PRODUCTS_FAIL:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_PRODUCT:
    case TYPES.GET_PRODUCT_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        product: action.payload.data,
        productError: null,
        changes: false,
        loading: false,
      };
    case TYPES.GET_PRODUCT_FAIL:
      return {
        ...state,
        product: null,
        productError: action.error.response.data,
        loading: false,
      };

    case TYPES.UPDATE_PRODUCT:
    case TYPES.UPDATE_PRODUCT_LOAD:
      return {
        ...state,
        loading: true,
      };

    case TYPES.UPDATE_PRODUCT_SUCCESS:
      toast.success('Product SKU updated successfully.');
      return {
        ...state,
        loading: false,
        changes: false,
        productError: null,
      };

    case TYPES.UPDATE_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        productError: action.error.response.data,
      };
    case TYPES.DELETE_PRODUCT:
    case TYPES.DELETE_PRODUCT_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.DELETE_PRODUCT_SUCCESS:
      toast.success('Deleted product successfully.');
      return {
        ...state,
        loading: false,
        changes: false,
      };
    case TYPES.DELETE_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_PRODUCT_TRANSLATIONS:
    case TYPES.GET_PRODUCT_TRANSLATIONS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_PRODUCT_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        translations: action.payload.data.results,
        loading: false,
      };
    case TYPES.GET_PRODUCT_TRANSLATIONS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.ADD_PRODUCT_TRANSLATION:
    case TYPES.ADD_PRODUCT_TRANSLATION_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.ADD_PRODUCT_TRANSLATION_SUCCESS:
      toast.success('Successfully added language.');
      return {
        ...state,
        loading: false,
        translation: {
          ...initialState.translation,
        },
        translationError: null,
      };
    case TYPES.ADD_PRODUCT_TRANSLATION_FAIL:
      return {
        ...state,
        translationError: action.error.response.data,
        loading: false,
      };

    case TYPES.UPDATE_PRODUCT_TRANSLATION:
    case TYPES.UPDATE_PRODUCT_TRANSLATION_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.UPDATE_PRODUCT_TRANSLATION_SUCCESS:
      toast.success('Successfully updated language.');
      return {
        ...state,
        loading: false,
        changes: false,
        translationError: null,
      };
    case TYPES.UPDATE_PRODUCT_TRANSLATION_FAIL:
      return {
        ...state,
        translationError: action.error.response.data,
        loading: false,
      };
    case TYPES.DELETE_PRODUCT_TRANSLATION:
    case TYPES.DELETE_PRODUCT_TRANSLATION_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.DELETE_PRODUCT_TRANSLATION_SUCCESS:
      toast.success('Successfully deleted language.');
      return {
        ...state,
        loading: false,
      };
    case TYPES.DELETE_PRODUCT_TRANSLATION_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.HANDLE_PRODUCT_TRANSLATION_FIELD:
      return {
        ...state,
        translation: {
          ...state.translation,
          [action.payload.key]: action.payload.value,
        },
      };
    case TYPES.HANDLE_PRODUCT_TRANSLATIONS_FIELD:
      return {
        ...state,
        translations: state.translations.map((translation) =>
          translation.id === action.payload.id
            ? { ...translation, [action.payload.key]: action.payload.value }
            : translation
        ),
        changes: true,
      };

    case TYPES.HANDLE_PRODUCT_FIELD:
      return {
        ...state,
        product: {
          ...state.product,
          [action.payload.field]: action.payload.value,
        },
        changes: true,
      };
    case TYPES.CLEAR_PRODUCT_ERRORS:
      return {
        ...state,
        productError: null,
        translationError: null,
      };
    case TYPES.CLEAR_PRODUCT_FIELDS:
      return {
        ...state,
        product: {},
      };
    default:
      return state;
  }
};
