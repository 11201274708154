import React, { useEffect, useState } from 'react';

import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  notification,
} from 'antd';
import {
  RcFile,
  UploadChangeParam,
  UploadFile,
} from 'antd/lib/upload/interface';
import { UploadRequestOption } from 'node_modules/rc-upload/lib/interface';
import api from 'src/api';
import { AppType } from 'src/api/types/app.types';
import ImageCroppingModal from 'src/components/ImageCroppingModal';
import { useAppContext, useBooleanState } from 'src/hooks';
import {
  useDeleteAppMutation,
  useUpdateAppMutation,
} from 'src/hooks/queries/app.hooks';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { copyTextToClipBoard } from 'src/services/helpers';
import Token from 'src/services/token';
import styled from 'styled-components';

import { StyledUpload } from '../../paywalls/PaywallBuilder/editor/inputs/ImageButton';

const CopyButton = styled(Button).attrs({ children: 'Copy' })`
  border: none;
  height: 100%;
  width: 100%;
  border-radius: 0;
  border-end-end-radius: 1px;
  border-start-end-radius: 1px;

  .ant-input-group-addon:has(&) {
    padding: 0;
  }
`;

export const WhiteText = styled.span<{ as?: any }>`
  color: white;
  transition: all 0.3s !important;

  &:hover {
    transform: scale(1.2);
  }
`;

export default function GeneralTab() {
  const context = useAppContext();
  const currentApp = useAppSelector(({ root }) => root.currentApp);
  const { languages, defaultLanguage } = useAppSelector(({ root }) => ({
    languages: root.languages as [{ code: string; language: string }],
    defaultLanguage: root.currentApp.default_language as string,
  }));
  const canSetLanguage = context.planHasEntitlement('app.locale_config.get');
  const [isCropperOpen, showCropper, hideCropper] = useBooleanState(false);
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<UploadFile | null>(
    currentApp.logo ? buildFile(currentApp.logo) : null
  );
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [form] = Form.useForm<AppType>();
  const updateAppMutation = useUpdateAppMutation(currentApp.id);
  const deleteAppMutation = useDeleteAppMutation(currentApp.id);

  const languagesOptions = languages.map(({ code: value, language }) => {
    const suffix = defaultLanguage === value ? ' - Default' : '';
    const label = `${language} - (${value.toUpperCase()})` + suffix;
    return { value, label };
  });

  useEffect(() => {
    setImageUrl(currentApp.logo);
  }, [currentApp]);

  useEffect(() => {
    form.setFieldsValue(currentApp);
  }, [form, currentApp]);

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: RcFile) => {
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      notification.error({ message: 'App icon must be smaller than 1MB' });
    }
    return isLt1M;
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const warning = () => {
    Modal.confirm({
      title: 'Delete App Confirmation',
      content:
        "Are you sure you want to delete? You won't be able to recover your lost app data.",
      cancelText: 'No',
      centered: true,
      okText: 'Yes, delete this Nami app',
      width: 550,
      onOk: () => deleteAppMutation.mutate(),
    });
  };

  if (!currentApp) return null;

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={() => {
          const data = { ...form.getFieldsValue(), logo: imageUrl };
          updateAppMutation.mutate(data);
        }}
      >
        <Row gutter={[16, 16]}>
          <Col md={18} xs={24}>
            <Form.Item label="Icon">
              <StyledUpload
                name="logo"
                listType="picture-card"
                className="avatar-uploader"
                accept=".jpg,.jpeg,.png"
                showUploadList={{
                  previewIcon: <WhiteText as={EditOutlined} />,
                  removeIcon: <WhiteText as={DeleteOutlined} />,
                }}
                customRequest={({ file }: UploadRequestOption) =>
                  handleNewFile(file)
                }
                beforeUpload={beforeUpload}
                fileList={file ? [file] : []}
                onPreview={showCropper}
                onChange={handleChange}
                maxCount={1}
                disabled={loading || !context.userHasEntitlement('app.update')}
              >
                {!imageUrl && !loading && uploadButton}
              </StyledUpload>
              {!!imageUrl && (
                <ImageCroppingModal
                  imageUrl={imageUrl}
                  visible={isCropperOpen && !loading}
                  onCancel={hideCropper}
                  onCrop={handleNewFile}
                  aspectRatio={1}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col md={18} xs={24}>
            <Form.Item label="App Name" name="name">
              <Input disabled={!context.userHasEntitlement('app.update')} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col md={18} xs={24}>
            <Form.Item label="App ID" name="id">
              <Input
                readOnly={true}
                addonAfter={
                  <CopyButton disabled={!currentApp.id} onClick={copyValue} />
                }
              />
            </Form.Item>
          </Col>
        </Row>
        {canSetLanguage && (
          <Row gutter={[16, 16]}>
            <Col md={18} xs={24}>
              <Form.Item label="Default Language" name="default_language">
                <Select
                  options={languagesOptions}
                  filterOption={(
                    input: string,
                    option?: {
                      value: string;
                      label: string;
                    }
                  ) =>
                    (option?.value || '')
                      .toLowerCase()
                      .includes(input.toLowerCase()) ||
                    (option?.label || '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  showSearch={true}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        <Button
          type="primary"
          loading={updateAppMutation.isLoading}
          htmlType="submit"
          className="intercom-updateApp"
          disabled={!context.userHasEntitlement('app.update')}
        >
          Update App
        </Button>
      </Form>
      <Collapse ghost style={{ marginBottom: 15, marginTop: 20 }}>
        <Collapse.Panel
          header={<h4>Danger Zone</h4>}
          key="1"
          className="intercom-appDelete"
        >
          <Space direction="vertical">
            <Typography.Text>
              Deleting this app will remove all associated assets from the
              Control Center including products, paywalls, campaigns, users, and
              analytics.
            </Typography.Text>
            <Typography.Text strong>
              A deleted app cannot be restored.
            </Typography.Text>
            <Button
              type="primary"
              danger
              disabled={!context.userHasEntitlement('app.delete')}
              onClick={warning}
            >
              Delete App
            </Button>
          </Space>
        </Collapse.Panel>
      </Collapse>
    </>
  );

  function copyValue() {
    const message = `Copied App ID to clipboard`;
    copyTextToClipBoard(currentApp.id).then(() =>
      notification.success({ message })
    );
  }

  function handleChange(info: UploadChangeParam): void {
    if (info.file.status === 'removed') {
      deleteImage();
    } else if (info.file.status === 'uploading') {
      setLoading(true);
      // return;
    } else if (info.file.status === 'done') {
      setLoading(false);
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        setFile(info.file);
      });
    }
  }

  function deleteImage() {
    setImageUrl(null);
    setFile(null);
  }

  function handleNewFile(file: string | Blob | RcFile): void {
    if (file && typeof file !== 'string') {
      setLoading(true);
      api.uploadFile(file, { orgId: Token.getOrgId()! }).then((url) => {
        setImageUrl(url);
        setFile(buildFile(url));
        setLoading(false);
      });
    } else {
      setFile(buildFile(file));
    }
  }
}

export function buildFile(file: string | Blob | RcFile): UploadFile {
  const url = typeof file === 'string' ? file : URL.createObjectURL(file);
  const name = url.split('/').reverse()[0];
  return { uid: '-1', name, status: 'done', url };
}
