import React, { useMemo } from 'react';

import {
  ApiFilled,
  CheckCircleOutlined,
  ExclamationCircleFilled,
  LockFilled,
  ReadOutlined,
} from '@ant-design/icons';
import { Card, Col, Row, Space, Tag } from 'antd';
import { TagProps } from 'antd/lib/tag';
import DisabledBadgeDot from 'src/components/StatusDots/DisabledBadgeDot';
import LiveBadgeDot from 'src/components/StatusDots/LiveBadgeDot';
import {
  namiAliceGray,
  namiBrightBlue,
  namiBrightGreen,
  namiDarkGray,
  namiDarkOrange,
  namiGreen,
  namiLightGray,
  namiLightOrange,
  namiPrimaryBlue,
  namiPureWhite,
} from 'src/variables';
import styled, { css } from 'styled-components';

type IntegrationCardProps = {
  logo?: string;
  icon?: React.ReactNode;
  title?: string | null;
  subtitle?: React.ReactNode;
  $overflow?: boolean;
  status?: string;
  resources?: { title: string; url: string }[];
  onClick?: () => void;
  className?: string;
  loading?: boolean;
};

const StyledCard = styled(Card)<{ hoverable?: boolean }>`
  min-height: 314px !important;
  border: 2px solid ${namiPureWhite} !important;
  padding: 8px !important;

  ${({ hoverable }) => {
    if (hoverable) {
      return css`
        cursor: pointer;
        &:hover {
          border: 2px solid ${namiPrimaryBlue} !important;
        }
      `;
    }
    return css``;
  }}
`;

const Image = styled.div<{ $src: string }>`
  height: 70px;
  width: 200px;
  margin-top: 20px;
  margin-bottom: 60px;
  background-image: url(${({ $src }) => $src});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const StyledIcon = styled.div`
  left: 50%;
  transform: translateX(-53%);
  margin: 20px auto 60px;
  font-size: 70px;
  text-align: center;
  position: relative;
  color: ${namiDarkGray};
`;

const CardTitle = styled.div`
  font-weight: 700;
  font-size: 1.25em;
`;
const CardSubtitle = styled.div<{ $overflow?: boolean }>`
  margin-top: 0.5em;
  clear: both;
  color: ${namiDarkGray};
  font-size: 18px;
  ${({ $overflow }) => {
    if ($overflow) {
      return css`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      `;
    }
    return css``;
  }};
`;

const TagWrapper = styled.div`
  margin-top: 10px;
`;

const STATUS_LABELS: Record<string, Omit<TagProps, 'className'>> = {
  configured: {
    color: 'success',
    icon: <CheckCircleOutlined />,
    style: { color: namiBrightGreen, borderColor: namiGreen },
  },
  finish_configuring: {
    color: 'warning',
    icon: <ExclamationCircleFilled />,
    style: { color: namiDarkOrange, borderColor: namiLightOrange },
  },
  enabled: {
    color: 'success',
    icon: <LiveBadgeDot style={{ marginRight: 6 }} />,
    style: { color: namiBrightGreen, borderColor: namiGreen },
  },
  disabled: {
    color: namiAliceGray,
    icon: <DisabledBadgeDot style={{ marginRight: 6 }} />,
    style: { color: namiDarkGray, borderColor: namiLightGray },
  },
  add_store_credentials: {
    color: 'warning',
    icon: <ExclamationCircleFilled />,
    style: { color: namiDarkOrange, borderColor: namiLightOrange },
  },
  upgrade_for_access: {
    icon: <LockFilled />,
    color: namiAliceGray,
    style: { color: namiBrightBlue, borderColor: namiBrightBlue },
  },
  request_access: {
    icon: <ApiFilled />,
    color: namiAliceGray,
    style: { color: namiBrightBlue, borderColor: namiBrightBlue },
  },
};

const ResourceTitle = styled.div`
  font-weight: 500;
  margin-top: 1em;
  margin-bottom: 0.5em;
`;
const ResourceLink = styled.div`
  color: ${namiPrimaryBlue};
`;

export default function IntegrationCard({
  logo,
  icon,
  title,
  subtitle,
  $overflow = true,
  status,
  resources,
  onClick,
  className,
  loading,
}: IntegrationCardProps) {
  const labelProps = useMemo(() => {
    const statusKey = status?.toLowerCase().replace(/\s/g, '_');
    return (statusKey && STATUS_LABELS[statusKey]) || {};
  }, [status]);

  return (
    <StyledCard
      onClick={onClick}
      className={className}
      hoverable={!!onClick}
      loading={loading}
    >
      <Row justify="center">
        <Col>{logo && <Image $src={logo} />}</Col>
        <Col>{icon && <StyledIcon>{icon}</StyledIcon>}</Col>
      </Row>
      <CardTitle>{title}</CardTitle>
      <CardSubtitle $overflow={$overflow}>{subtitle}</CardSubtitle>
      {status && (
        <TagWrapper>
          <Tag className="labelTextColor" {...labelProps}>
            {status}
          </Tag>
        </TagWrapper>
      )}
      {!!resources?.length && (
        <>
          <ResourceTitle>Resources</ResourceTitle>
          {resources.map((resource, index) => (
            <ResourceLink
              key={index}
              as="a"
              href={resource.url}
              target="_blank"
            >
              <Space direction="horizontal" size="small">
                <ReadOutlined />
                {resource.title}
              </Space>
            </ResourceLink>
          ))}
        </>
      )}
    </StyledCard>
  );
}
