import React from 'react';

import { Form, Input, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import { PlatformAllowedOriginType } from 'src/api/types/main.types';
import { useCreatePlatformAllowedOriginMutation } from 'src/hooks/queries/platform.hooks';
import { isValidURL } from 'src/utils/string';

import { TIntegrationParams } from '../params.types';

type AddCORSModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function AddLabelModal({ isOpen, onClose }: AddCORSModalProps) {
  const { integrationId } = useParams<TIntegrationParams>();
  const addCorsMutation = useCreatePlatformAllowedOriginMutation();
  const [form] = Form.useForm<Omit<PlatformAllowedOriginType, 'id'>>();

  return (
    <>
      <Modal
        title="Add a New CORS Allowed Origin"
        open={isOpen}
        centered
        onOk={() => {
          form
            .validateFields()
            .then(() => addCorsMutation.mutate(form.getFieldsValue()));
          onClose();
        }}
        onCancel={onClose}
        confirmLoading={addCorsMutation.isLoading}
        okText="Add"
        afterClose={() => {
          form.resetFields();
        }}
      >
        <Form
          layout="vertical"
          form={form}
          requiredMark={false}
          initialValues={{ platform: integrationId }}
        >
          <Form.Item name="platform" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label="URL"
            name="url"
            className="intercom-addCORSUrl"
            required
            rules={[
              () => {
                return {
                  message: 'Please enter a valid URL',
                  validator: (_, value) => {
                    const isValid = isValidURL(value);
                    return isValid ? Promise.resolve() : Promise.reject();
                  },
                };
              },
              () => {
                return {
                  message: 'URL cannot contain wildcard values',
                  validator: (_, value) => {
                    const invalid = String(value).includes('*');
                    return invalid ? Promise.reject() : Promise.resolve();
                  },
                };
              },
            ]}
          >
            <Input placeholder="e.g. http://yourwebsite.com" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
