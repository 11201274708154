import React from 'react';

import { connect } from 'react-redux';
import { Form, Button, Modal, Dimmer, Loader } from 'semantic-ui-react';

import { createUserAuthenticators } from '../../../../../redux/actions/userActions';

const CreateRecoveryCodesModal = ({
  open,
  onClose,
  onSuccess,
  //Redux
  loading,
  createUserAuthenticators,
}) => {
  const submit = () => {
    createUserAuthenticators('recovery').then((action) => {
      if (action.type.endsWith('SUCCESS')) {
        onSuccess();
      }
    });
  };
  return (
    <Modal
      dimmer="blurring"
      closeOnDimmerClick={false}
      onClose={onClose}
      size="mini"
      open={open}
    >
      <Modal.Content>
        <Modal.Header as="h3">2FA Configured</Modal.Header>
        <Modal.Description>
          <Dimmer active={loading} inverted>
            <Loader content="Generating Codes ..." />
          </Dimmer>
          <p>
            Two-factor authentication has been successfully configured for your
            account.
          </p>
          <p>
            To avoid permanently losing access to your account, generate your
            recovery codes now and keep them in a safe place.
          </p>
          <Form onSubmit={submit}>
            <Button fluid className="nami-primary-button" type="submit">
              Generate recovery codes
            </Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    createUserAuthenticators: (type) =>
      dispatch(createUserAuthenticators(type)),
  };
};

const mapStateToProps = ({ user, auth }) => {
  return {
    loading: user.loading,
    authenticator: user.authenticator,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRecoveryCodesModal);
