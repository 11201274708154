import React from 'react';

import { Image, Select, Space, Tooltip } from 'antd';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { platformLogo } from 'src/services/helpers';
import { platformCompatibleWithFormFactor } from 'src/utils/paywall';

export default function PaywallPlatformPicker() {
  const actions = useActions(PaywallBuilderSlice.actions);
  const { allPlatforms, currentPlatformId, formFactor } = useAppSelector(
    ({ paywallBuilder: { platforms, platformId, formFactor } }: RootState) => ({
      allPlatforms: platforms,
      currentPlatformId: platformId,
      formFactor: formFactor,
    })
  );

  return (
    <Tooltip
      title="Pick a platform to preview"
      mouseEnterDelay={0.8}
      placement="top"
    >
      <Select
        value={currentPlatformId}
        onChange={changePlatformId}
        optionFilterProp="children"
        size="small"
        style={{ width: 148 }}
        dropdownMatchSelectWidth={false}
        notFoundContent={'No Payment Platforms Configured'}
        placeholder={'Platform'}
      >
        {allPlatforms.map((platform) => (
          <Select.Option
            key={platform.id}
            value={platform.id}
            disabled={
              !platformCompatibleWithFormFactor(platform.type, formFactor)
            }
          >
            <Space>
              <Image
                height={15}
                preview={false}
                width={15}
                src={platformLogo(platform.type)}
              />
              <div style={{ height: 22 }}>{platform.name}</div>
            </Space>
          </Select.Option>
        ))}
      </Select>
    </Tooltip>
  );

  function changePlatformId(platform: string) {
    actions.setPlatformId(platform);
    actions.setEditingProductId(null);
  }
}
