export function readFile(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

export function downloadContent(
  filename: string,
  content: string
): Promise<void> {
  return new Promise((resolve) => {
    const element = document.createElement('a');
    const href = `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`;
    element.setAttribute('href', href);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    resolve();
  });
}
