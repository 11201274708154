import { toast } from 'react-toastify';

import * as TYPES from '../types';

const initialState = {
  loading: true,
  changes: false,
  displayUnsavedChanges: false,
  paywallKeys: {
    key: '',
    value: '',
    error: '',
  },
  paywalls: [],
  paywall: {},
  paywallStyles: {},
  paywallMenusRoot: {},
  paywallMenus: [],
  paywallMenusAvailable: [],
  paywallError: null,
  paywallUpdateError: null,
};

export const paywallReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_PAYWALLS:
    case TYPES.GET_PAYWALLS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_PAYWALLS_SUCCESS:
      return {
        ...state,
        paywalls: action.payload.data.results,
        loading: false,
      };
    case TYPES.GET_PAYWALLS_FAIL:
      return {
        ...state,
        paywalls: [],
        loading: false,
      };

    case TYPES.GET_PAYWALL:
    case TYPES.GET_PAYWALL_LOAD:
      return {
        ...state,
        loading: true,
        paywall: {},
        paywallStyles: {},
        paywallMenusRoot: {},
        paywallMenus: [],
        paywallMenusAvailable: [],
      };
    case TYPES.GET_PAYWALL_SUCCESS:
      return {
        ...state,
        paywall: action.payload.data,
        loading: false,
        changes: false,
        displayUnsavedChanges: false,
      };
    case TYPES.GET_PAYWALL_FAIL:
      return {
        ...state,
        loading: false,
        paywall: null,
      };

    case TYPES.UPDATE_PAYWALL:
    case TYPES.UPDATE_PAYWALL_LOAD:
      return {
        ...state,
        loading: true,
      };

    case TYPES.UPDATE_PAYWALL_SUCCESS:
      toast.success('Successfully updated paywall.');
      return {
        ...state,
        changes: false,
        displayUnsavedChanges: false,
        loading: false,
        paywallUpdateError: null,
      };

    case TYPES.UPDATE_PAYWALL_FAIL:
      return {
        ...state,
        loading: false,
        paywallUpdateError: action.error.response.data,
      };

    case TYPES.UPDATE_PAYWALL_STYLES:
    case TYPES.UPDATE_PAYWALL_STYLES_LOAD:
      return {
        ...state,
      };

    case TYPES.UPDATE_PAYWALL_STYLES_SUCCESS:
      toast.success('Successfully updated paywall styles.');
      return {
        ...state,
        changes: false,
        displayUnsavedChanges: false,
      };

    case TYPES.UPDATE_PAYWALL_STYLES_FAIL:
      return {
        ...state,
        changes: false,
        displayUnsavedChanges: false,
      };

    case TYPES.GET_PAYWALL_MENUS:
    case TYPES.GET_PAYWALL_MENUS_LOAD:
      return {
        ...state,
      };
    case TYPES.GET_PAYWALL_MENUS_SUCCESS:
      return {
        ...state,
        paywallMenusRoot: action.payload.data?.results?.length
          ? action.payload.data.results[0]
          : {},
        paywallMenus: action.payload.data?.results?.length
          ? action.payload.data.results[0].ordered_items?.length
            ? action.payload.data.results[0].ordered_items
            : []
          : [],
      };
    case TYPES.GET_PAYWALL_MENUS_FAIL:
      return {
        ...state,
      };

    case TYPES.UPDATE_PAYWALL_MENUS:
    case TYPES.UPDATE_PAYWALL_MENUS_LOAD:
      return {
        ...state,
      };
    case TYPES.UPDATE_PAYWALL_MENUS_SUCCESS:
      toast.success('Successfully updated products on paywall.');
      return {
        ...state,
        changes: false,
        displayUnsavedChanges: false,
      };
    case TYPES.UPDATE_PAYWALL_MENUS_FAIL:
      return {
        ...state,
        changes: false,
        displayUnsavedChanges: false,
      };

    case TYPES.GET_PAYWALL_MENUS_AVAILABLE:
    case TYPES.GET_PAYWALL_MENUS_AVAILABLE_LOAD:
      return {
        ...state,
      };
    case TYPES.GET_PAYWALL_MENUS_AVAILABLE_SUCCESS:
      return {
        ...state,
        paywallMenusAvailable: action.payload?.data?.results?.length
          ? action.payload.data.results.map((item) => {
              return {
                sku_id: item.id,
                name: item.name,
                sku_ref_id: item.sku_ref_id,
                featured: false,
                display_text:
                  item.translations[state.paywall.language].display_text,
                sub_display_text:
                  item.translations[state.paywall.language].sub_display_text,
              };
            })
          : [],
      };
    case TYPES.GET_PAYWALL_MENUS_AVAILABLE_FAIL:
      return {
        ...state,
      };

    case TYPES.GET_PAYWALL_STYLES:
    case TYPES.GET_PAYWALL_STYLES_LOAD:
      return {
        ...state,
      };
    case TYPES.GET_PAYWALL_STYLES_SUCCESS:
      return {
        ...state,
        paywallStyles: action.payload.data,
      };
    case TYPES.GET_PAYWALL_STYLES_FAIL:
      return {
        ...state,
        paywallStyles: {},
      };

    case TYPES.HANDLE_TOGGLE_FEATURED:
      return {
        ...state,
        paywallMenus: state.paywallMenus.map((paywallMenu) =>
          paywallMenu.sku_id === action.payload.skuId
            ? { ...paywallMenu, featured: action.payload.featured }
            : paywallMenu
        ),
        changes: true,
        displayUnsavedChanges: true,
      };

    case TYPES.HANDLE_PAYWALL_MENUS_CHANGES:
      return {
        ...state,
        changes: true,
        displayUnsavedChanges: true,
      };

    case TYPES.HANDLE_PAYWALL_MENUS:
      return {
        ...state,
        paywallMenus: action.payload.data,
      };

    case TYPES.HANDLE_PAYWALL_MENUS_AVAILABLE:
      return {
        ...state,
        paywallMenusAvailable: action.payload.data,
      };

    case TYPES.HANDLE_PAYWALL_COLOR:
      return {
        ...state,
        paywallStyles: {
          ...state.paywallStyles,
          [action.payload.key]: action.payload.color.hex,
        },
        changes: true,
        displayUnsavedChanges: true,
      };

    case TYPES.HANDLE_PAYWALL_SIZE:
      return {
        ...state,
        paywallStyles: {
          ...state.paywallStyles,
          [action.payload.key]: action.payload.value,
        },
        changes: true,
        displayUnsavedChanges: true,
      };
    case TYPES.HANDLE_PAYWALL_STYLES_FIELD:
      return {
        ...state,
        paywallStyles: action.payload.paywallStyles,
        changes: true,
        displayUnsavedChanges: true,
      };
    case TYPES.HANDLE_PAYWALL_FIELD:
      return {
        ...state,
        paywall: action.payload.paywall,
        changes: true,
        displayUnsavedChanges: true,
      };

    case TYPES.HANDLE_PAYWALL_KEY_FIELD:
      return {
        ...state,
        paywallKeys: {
          ...state.paywallKeys,
          [action.payload.key]: action.payload.value,
        },
        changes: true,
        displayUnsavedChanges: true,
      };
    case TYPES.RESET_PAYWALL_KEYS:
      return {
        ...state,
        paywallKeys: {
          ...initialState.paywallKeys,
        },
      };
    case TYPES.TOGGLE_DISPLAY_UNSAVED_CHANGES:
      return {
        ...state,
        displayUnsavedChanges: false,
      };
    case TYPES.RESET_PAYWALL_STATE:
      return {
        ...state,
        paywall: {},
        paywallStyles: {},
        paywallMenusRoot: {},
        paywallMenus: [],
        paywallMenusAvailable: [],
        changes: false,
        displayUnsavedChanges: false,
        loading: false,
      };
    default:
      return state;
  }
};
