import React, { useEffect, useState } from 'react';

import { breakpointMd } from '../../variables';

type ResponsiveProps<T extends React.FC<any>> = {
  children: React.ReactNode;
  size: 'mdUp' | 'mdDown';
  as?: T;
} & React.ComponentProps<T>;

export default function Responsive<T extends React.FC<any>>({
  as: Component = React.Fragment,
  size,
  ...props
}: ResponsiveProps<T>) {
  const [flag, setFlag] = useState(shouldShow());

  useEffect(() => {
    const listener = () => setFlag(shouldShow());
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!flag) return null;
  return <Component {...props} />;

  function shouldShow(): boolean {
    const key = size === 'mdUp' ? 'min' : 'max';
    return window.matchMedia(`(${key}-width: ${breakpointMd})`).matches;
  }
}
