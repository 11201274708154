import React from 'react';

import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb as AntBreadcrumb, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { breakpointMd, breakpointSm } from 'src/variables';
import styled from 'styled-components';

type BreadcrumbProps = {
  items: { url?: string; name: string }[];
};

const SpecialText = styled(Typography.Text)`
  max-width: 500px !important;
  overflow-x: hidden;

  @media only screen and (max-width: ${breakpointMd}) {
    max-width: 300px !important;
  }

  @media only screen and (max-width: ${breakpointSm}) {
    max-width: 200px !important;
  }
`;

export default function Breadcrumb({ items }: BreadcrumbProps) {
  const history = useHistory();
  return (
    <AntBreadcrumb style={{ cursor: 'pointer' }}>
      <AntBreadcrumb.Item onClick={() => history.push('/overview/')}>
        <HomeOutlined />
      </AntBreadcrumb.Item>
      {items.map(({ url, name }) => (
        <AntBreadcrumb.Item onClick={() => url && history.push(url)} key={name}>
          <SpecialText ellipsis={true}>{name}</SpecialText>
        </AntBreadcrumb.Item>
      ))}
    </AntBreadcrumb>
  );
}
