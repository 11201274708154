import { client } from './clients';
import { TEntitlement, TEntitlementPayload } from './types/entitlement.types';
import { TApiOptions, TListResult } from './types/main.types';
import { buildURLParams, extractErrorData, extractResponseData } from './utils';

const entitlements = {
  getEntitlements: (
    appId: string,
    apiOptions: TApiOptions = {}
  ): Promise<TListResult<TEntitlement>> => {
    const params = buildURLParams({ pageSize: 50, ...apiOptions, appId });
    return client
      .get(`/private/entitlements/?${params}`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getEntitlement: (entitlementId: string): Promise<TEntitlement> => {
    return client
      .get(`/private/entitlements/${entitlementId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  createEntitlement: (data: TEntitlementPayload): Promise<TEntitlement> => {
    return client
      .post('/private/entitlements/', data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  updateEntitlement: (
    entitlementId: string,
    data: Pick<TEntitlementPayload, 'name'>
  ): Promise<TEntitlement> => {
    return client
      .patch(`/private/entitlements/${entitlementId}/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  deleteEntitlement: (entitlementId: string): Promise<void> => {
    return client
      .delete(`/private/entitlements/${entitlementId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
};
export default entitlements;
