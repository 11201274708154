import React from 'react';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Typography } from 'antd';
import NavigationPrompt from 'react-router-navigation-prompt';
import { namiLightOrange } from 'src/variables';
import styled from 'styled-components';

const WarningIcon = styled(ExclamationCircleOutlined)`
  color: ${namiLightOrange};
  padding-right: 5px;
`;

const ActionsFooter = styled(Space)`
  padding-top: 20px;
  display: flex;
  flex-direction: row-reverse;
`;

type UnsavedChangesModalProps = {
  title: string;
  description: string;
  hasChanges: boolean;
  onSave: () => void;
};

export default function UnsavedChangesModal({
  title,
  description,
  hasChanges,
  onSave,
}: UnsavedChangesModalProps) {
  return (
    <NavigationPrompt when={hasChanges}>
      {({ onConfirm, onCancel }) => {
        return (
          <Modal
            open={true}
            centered
            onCancel={onCancel}
            footer={null}
            zIndex={1005}
          >
            <Space direction="vertical">
              <Typography.Title level={5}>
                <WarningIcon /> {title}
              </Typography.Title>
              <Typography.Text>{description}</Typography.Text>
            </Space>
            <ActionsFooter>
              <Button type="primary" onClick={handleSave}>
                Save Changes
              </Button>
              <Button onClick={onConfirm}>Leave Anyway</Button>
            </ActionsFooter>
          </Modal>
        );

        function handleSave() {
          onSave();
          onCancel();
        }
      }}
    </NavigationPrompt>
  );
}
