import React, { useEffect, useState } from 'react';

import { EyeOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tooltip } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  Dropdown,
  Form,
  Icon,
  Image,
  Label,
  List,
  Pagination,
  Segment,
  Statistic,
  Table,
} from 'semantic-ui-react';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import CRMFilteringWebPaywall from 'src/components/WebPaywalls/CRMFilteringWebPaywall';
import { namiPrimaryBlue } from 'src/variables';

import EmptyStateSegment from '../../../components/EmptyStateSegment/EmptyStateSegment';
import Loading from '../../../components/Loading/Loading';
import MobileTable from '../../../components/MobileTable/MobileTable';
import Page from '../../../components/Page/Page';
import Responsive from '../../../components/Responsive/Responsive';
import { useAppContext, useBooleanState } from '../../../hooks';
import AppleAppStoreIcon from '../../../images/apple.svg';
import GooglePlayStoreIcon from '../../../images/google.svg';
import { getCrmUsers } from '../../../redux/actions/crmActions';
import history from '../../../services/history';

const CustomersPage = ({
  loading,
  currentApp,
  crmUsers,
  count,
  totalPages,
  getCrmUsers,
}) => {
  const context = useAppContext();
  const [filters, setFilters] = useState([]);
  const [filtersFinish, setFiltersFinish] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [isUpgradeOpen, openUpgrade, closeUpgrade] = useBooleanState(false);
  const [isMenuOpen, openMenu, closeMenu] = useBooleanState(false);

  useEffect(() => {
    if (currentApp && currentApp.id) {
      getCrmUsers(currentApp.id, pageNumber, filtersFinish);
    }
  }, [getCrmUsers, currentApp, pageNumber, filtersFinish]);

  const condition = (label) => {
    return !filters.reduce(
      (accumulator, filter) => accumulator || filter.label === label,
      false
    );
  };

  const onChange = (e) => {
    setFilters(
      filters.map((filter) => {
        if (filter.name !== e.target.name) return filter;
        return { ...filter, value: e.target.value };
      })
    );
  };

  const onClosePopupAll = () => {
    setFilters(
      filters.map((filter) => {
        return { ...filter, open: false };
      })
    );
  };

  const onClosePopup = (name, toogle) => {
    setFilters(
      filters.map((filter) => {
        if (filter.name !== name) {
          return { ...filter, open: false };
        }
        return { ...filter, open: toogle };
      })
    );
  };

  const handleFilterClick = () => {
    if (!context.userHasEntitlement('app.user.list.filtered')) {
      openUpgrade();
      closeMenu();
    } else {
      if (!isMenuOpen) openMenu();
    }
  };

  const platformOptions = [
    {
      key: 1,
      text: (
        <List horizontal>
          <List.Item>
            <Image src={AppleAppStoreIcon} size="mini" />
          </List.Item>
          <List.Item>Apple App Store</List.Item>
        </List>
      ),
      value: 'apple',
      content: (
        <List horizontal>
          <List.Item>
            <Image src={AppleAppStoreIcon} size="mini" />
          </List.Item>
          <List.Item>Apple App Store</List.Item>
        </List>
      ),
    },
    {
      key: 2,
      text: (
        <List horizontal>
          <List.Item>
            <Image src={GooglePlayStoreIcon} size="mini" />
          </List.Item>
          <List.Item>Google Play Store</List.Item>
        </List>
      ),
      value: 'google',
      content: (
        <List horizontal>
          <List.Item>
            <Image src={GooglePlayStoreIcon} size="mini" />
          </List.Item>
          <List.Item>Google Play Store</List.Item>
        </List>
      ),
    },
  ];

  const options = [
    {
      name: 'External ID',
      icon: 'users',
      onClick: () => {
        onClosePopupAll();
        if (condition('External ID')) {
          setFilters((array) => [
            ...array,
            {
              icon: 'users',
              label: 'External ID',
              name: 'external_id',
              value: '',
              open: true,
            },
          ]);
        }
      },
    },
    {
      name: 'Nami Device ID',
      icon: 'mobile alternate',
      onClick: () => {
        onClosePopupAll();
        if (condition('Nami Device ID')) {
          setFilters((array) => [
            ...array,
            {
              icon: 'mobile alternate',
              label: 'Nami Device ID',
              name: 'device_id',
              value: '',
              open: true,
            },
          ]);
        }
      },
    },
    {
      name: 'Nami User ID',
      icon: 'users',
      onClick: () => {
        onClosePopupAll();
        if (condition('Nami User ID')) {
          setFilters((array) => [
            ...array,
            {
              icon: 'users',
              label: 'Nami User ID',
              name: 'namiml_user_id',
              value: '',
              open: true,
            },
          ]);
        }
      },
    },
    {
      name: 'Platform',
      icon: 'server',
      onClick: () => {
        onClosePopupAll();
        if (condition('Platform')) {
          setFilters((array) => [
            ...array,
            {
              icon: 'server',
              label: 'Platform',
              name: 'platform',
              value: '',
              open: true,
            },
          ]);
        }
      },
    },
  ];

  const onClose = (label) => {
    setFilters(filters.filter((filter) => filter.label !== label));
    setFiltersFinish(filtersFinish.filter((filter) => filter.label !== label));
  };

  const clear = () => {
    setFilters([]);
    setFiltersFinish([]);
  };

  const renderRightForm = (filter) => {
    return (
      <Form
        onSubmit={() => {
          onClosePopup(filter.name, false);
          setFiltersFinish(filters);
        }}
        style={{ padding: 20, width: 350 }}
      >
        {filter.label === 'Platform' ? (
          <Form.Field>
            <Dropdown
              fluid
              floating
              placeholder="Select Platform"
              selection
              options={platformOptions}
              onChange={(e, data) =>
                onChange({ target: { name: filter.name, value: data.value } })
              }
            />
          </Form.Field>
        ) : (
          <Form.Input
            label={filter.label}
            name={filter.name}
            value={filter.value}
            type="text"
            onChange={(e) => onChange(e)}
            className={`intercom-crmFilterBy${filter.label.replace(/\s/g, '')}`}
          />
        )}

        <Form.Field>
          <Button type="primary" disabled={!filter.value} htmlType="submit">
            Done
          </Button>
        </Form.Field>
      </Form>
    );
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <div onClick={closeMenu}>
      <Page title="Customers">
        <Row justify="start" gutter={[0, 36]}>
          <Col xs={24} style={{ paddingBottom: 12 }}>
            <Breadcrumb items={[{ name: 'Customers' }]} />
          </Col>
        </Row>
        <Statistic size="mini" horizontal>
          <Statistic.Value
            style={{ color: namiPrimaryBlue, fontWeight: 'bold' }}
          >
            {count.toLocaleString()}
          </Statistic.Value>
          <label style={{ padding: '4px 10px 0 5px' }}>
            Matching Customer Records
          </label>
        </Statistic>
        <div>
          <Dropdown
            className="nami-primary-button icon intercom-crmFilter"
            text="Filter"
            icon="plus"
            floating
            labeled
            button
            style={{ marginRight: 25 }}
            onClick={handleFilterClick}
            open={isMenuOpen}
          >
            <Dropdown.Menu style={{ zIndex: 99 }} onClick={closeMenu}>
              {options.map((option, index) => (
                <Dropdown.Item
                  key={index}
                  icon={option.icon}
                  text={option.name}
                  onClick={option.onClick}
                  className={`intercom-crmFilter${option.name.replace(
                    /\s/g,
                    ''
                  )}`}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {filters.map((filter, index) => (
            <Dropdown
              key={index}
              icon={null}
              open={filter.open}
              trigger={
                <Label
                  basic
                  style={{ margin: '5px 5px 5px 0' }}
                  onClick={() => onClosePopup(filter.name, !filter.open)}
                >
                  <Icon name={filter.icon} />
                  {filter.label}:<Label.Detail>{filter.value}</Label.Detail>
                  <Icon
                    name="delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClose(filter.label);
                    }}
                  />
                </Label>
              }
            >
              <Dropdown.Menu>{renderRightForm(filter)}</Dropdown.Menu>
            </Dropdown>
          ))}
          {filters && !!filters.length && (
            <Label basic onClick={clear} style={{ cursor: 'pointer' }}>
              Clear All
            </Label>
          )}
        </div>
        {!crmUsers?.length && (
          <EmptyStateSegment
            title="No Results Found"
            subtitle="Try adjusting your filters to find matching customer records."
            outBoundLink="https://learn.namiml.com/public-docs/subscription-management/customer-screen"
            icon="crm"
          >
            <Button
              type="primary"
              onClick={() => {
                setFilters([]);
                setFiltersFinish([]);
              }}
              icon={<LeftCircleOutlined />}
            >
              Go Back
            </Button>
          </EmptyStateSegment>
        )}
        {!!crmUsers?.length && (
          <Responsive size="mdUp">
            <Table style={{ marginTop: 15 }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell className="table-header intercom-crmListPsuedonym">
                    Pseudonym
                  </Table.HeaderCell>
                  <Table.HeaderCell className="table-header intercom-crmListUpdated">
                    Last Updated
                  </Table.HeaderCell>
                  <Table.HeaderCell className="table-header intercom-crmListActions">
                    Actions
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {crmUsers.map((user, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{user.pseudonym}</Table.Cell>
                    <Table.Cell>
                      <Tooltip
                        title={moment
                          .utc(user.updated_date)
                          .format('YYYY-MM-DD k:mm z')}
                      >
                        <span>{moment.utc(user.updated_date).fromNow()}</span>
                      </Tooltip>
                    </Table.Cell>

                    <Table.Cell>
                      <Button
                        ghost
                        type="primary"
                        icon={<EyeOutlined />}
                        onClick={() => history.push(`/customers/${user.id}/`)}
                      />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            {count > 20 && (
              <Pagination
                defaultActivePage={pageNumber}
                onPageChange={(e, { activePage }) => setPageNumber(activePage)}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={
                  pageNumber !== 1
                    ? { content: <Icon name="angle double left" />, icon: true }
                    : null
                }
                lastItem={
                  totalPages !== pageNumber
                    ? {
                        content: <Icon name="angle double right" />,
                        icon: true,
                      }
                    : null
                }
                prevItem={
                  pageNumber !== 1
                    ? { content: <Icon name="angle left" />, icon: true }
                    : null
                }
                nextItem={
                  totalPages !== pageNumber
                    ? { content: <Icon name="angle right" />, icon: true }
                    : null
                }
                totalPages={totalPages}
              />
            )}
          </Responsive>
        )}
        {!!crmUsers?.length && (
          <Responsive size="mdDown">
            <Segment style={{ marginTop: 15 }}>
              <MobileTable
                collection={crmUsers.map((user) => ({
                  name: user.pseudonym,
                  metaLabel: user.last_session
                    ? moment(user.last_session.updated_date).format(
                        'MMM DD, YYYY - hh:mm:ss'
                      )
                    : 'N/A',
                  onClick: () => history.push(`/customers/${user.id}/`),
                  graphicContent: () => {},
                }))}
              />
            </Segment>
            {count > 20 && (
              <Pagination
                defaultActivePage={pageNumber}
                onPageChange={(e, { activePage }) => setPageNumber(activePage)}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                prevItem={
                  pageNumber !== 1
                    ? { content: <Icon name="angle left" />, icon: true }
                    : null
                }
                nextItem={
                  totalPages !== pageNumber
                    ? { content: <Icon name="angle right" />, icon: true }
                    : null
                }
                totalPages={totalPages}
              />
            )}
          </Responsive>
        )}
        <CRMFilteringWebPaywall
          visible={isUpgradeOpen}
          onCancel={closeUpgrade}
        />
      </Page>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCrmUsers: (id, pageNumber, array) =>
      dispatch(getCrmUsers(id, pageNumber, array)),
  };
};

const mapStateToProps = ({ root, crm }) => {
  return {
    currentApp: root.currentApp,
    crmUsers: crm.crmUsers,
    count: crm.count,
    totalPages: crm.totalPages,
    loading: crm.loading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomersPage);
